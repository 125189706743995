import React, { useEffect, useState } from 'react';
import { TCurrencyAccount } from '../../../../../models/currencyAccounts';
import { TPayment } from '../../../../../models/payments';
import { UserRole } from '../../../../../models/user';
import BatchSubmissionFooter from '../../../BatchSummary/FooterContainer/BatchSubmissionFooter';
import useFooter from './useFooter';

export interface IFooterProps {
    currentPayment: TPayment,
    currentPayments: TPayment[],
    selectedDebitingAccount: TCurrencyAccount | undefined,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    accountId?: string;
}

const Footer: React.FC<IFooterProps> = ({
  currentPayment,
  currentPayments,
  selectedDebitingAccount,
  setLoading,
  accountId,
}: IFooterProps) => {
  const [inputter, setInputter] = useState<boolean>(false);
  const { handleExitClick, handleSubmitPayment, getRolesForAccount } = useFooter({
    currentPayment,
    selectedDebitingAccount,
    currentPayments,
    setLoading,
    accountId,
  });

  const checkUserInputter = async (_accountId: string) => {
    const roles = await getRolesForAccount(_accountId);
    setInputter(roles.includes(UserRole.PAYMENTS_INPUTTER));
  };
  useEffect(() => {
    if (accountId) {
      checkUserInputter(accountId);
    }
  }, [accountId]);

  const buttonText = `Submit payments (${currentPayments.length})`;
  const buttonDisabled = !accountId || currentPayments.length === 0;

  return (
    <BatchSubmissionFooter
      inputter={inputter}
      handleExitClick={handleExitClick}
      handleOnClick={handleSubmitPayment}
      buttonText={buttonText}
      disabled={buttonDisabled}
    />
  );
};

export default Footer;
