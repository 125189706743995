import React, { FC } from 'react';
import { TabProps } from '@mui/material';

import StyledTab from './Tab.styles';

export const getAttrProps = (index: number, name = 'tab') => ({
  id: `${name}-${index}`,
  'aria-controls': `${name}-panel-${index}`,
});

interface ITab extends TabProps {
    rebrand?: boolean;
}

export const Tab: FC<ITab> = (props) => {
  const { ...restProps } = props;

  return <StyledTab disableRipple {...restProps} />;
};
