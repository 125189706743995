import { Box, Typography } from '@mui/material';
import { TKeyValue } from 'components/Table/Table.interfaces';
import React from 'react';
import KeyValueTable from 'components/Table/KeyValueTable/KeyValueTable';
import useStyles from './PaymentDetails.styles';

interface IProps {
  paymentDetails: TKeyValue[];
  paymentTimeline: TKeyValue[];
  paymentId: string | undefined;
}

const PaymentDetails: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const {
    paymentDetails,
    paymentTimeline,
    paymentId,
  } = props;
  return (
    <>
      {
        paymentId
        && <Typography className={classes.paymentDetailsHeader}>Payment Details</Typography>
      }
      <Box>
        <KeyValueTable
          testId="ledger-payment-details"
          data={paymentDetails}
          obscure
        />
      </Box>
      {
        paymentId
        && <Typography className={classes.paymentDetailsHeader}>Payment Timeline</Typography>
      }
      <Box>
        <KeyValueTable
          testId="ledger-payment-details"
          data={paymentTimeline}
          obscure
        />
      </Box>
    </>
  );
};

export default PaymentDetails;
