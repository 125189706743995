import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface userManagementState {
    isEntityManager?: boolean,
    isAuthorizedToViewEntityManagement?: boolean,
}

const initialState = {
  isAuthorizedToViewEntityManagement: undefined,
  isEntityManager: undefined,
} as userManagementState;

export const userManagementSlice = createSlice({
  name: 'userManagementPage',
  initialState,
  reducers: {
    updateIsAuthorizedToViewEntityManagement: (state, action: PayloadAction<boolean>) :
      userManagementState => ({
      ...state,
      isAuthorizedToViewEntityManagement: action.payload,
    }),
    updateIsEntityManager: (state, action: PayloadAction<boolean>) :
      userManagementState => ({
      ...state,
      isEntityManager: action.payload,
    }),
  },
});

export const { actions } = userManagementSlice;
