import theme, { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderLeft: '4px solid #098375',
    border: '2px solid rgba(112, 112, 112, 0.1)',
    height: '120px',
    width: '80%',
    margin: 'auto',
    padding: '24px 8px 24px 12px',
    '&:hover, &focus': {
      backgroundColor: '#F6F6F6',
      cursor: 'pointer',
    },
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 15px 0 15px',
  },
  headerLabel: {
    padding: '0 15px 0 15px',
  },

  entityGroupNameLabel: {
    fontSize: 14,
    color: theme.palette?.primary?.main,
    fontWeight: 'bold',
  },

  entityGroupNoOfEntitiesLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: '#999999',
  },

  actionButtons: {
    height: '30px',
    width: '48px',
    backgroundColor: 'white',
    margin: '2px',
    '&:hover, &focus': {
      backgroundColor: 'rgba(131, 9, 9, 0.2)',
      color: 'rgb(131, 9, 9)',
    },
  },
  icon: {
    color: 'rgb(255, 199, 0)',
    marginRight: 10,
    alignSelf: 'center',
  },
}),
  { name: 'EntityGroupCard' });

export default useStyles;
