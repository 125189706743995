import { useEffect, useState } from 'react';
import DynamoService, {
  TDynamoTableQueryParams,
  TDynamoTableResponse,
} from '../services/DatabaseServices/DynamoService';
import useAlphaSnackbar from './useAlphaSnackbar';

interface TDynamoTableState extends TDynamoTableResponse { }

export interface TUseDynamoTable {
  data: any[] | undefined;
  hasMore: boolean | undefined;
  hasPrevious: boolean;
  handleNextPage: () => Promise<void>;
  handlePreviousPage: () => Promise<void>;
  loading: boolean;
  startFromStack: string[];
}
const useDynamoTable = (initialQuery: TDynamoTableQueryParams, accountId?: string) => {
  const [tableState, setTableState] = useState<TDynamoTableState>();
  const [startFromStack, setStartFromStack] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const sb = useAlphaSnackbar();

  const getTableData = async (_query: TDynamoTableQueryParams) => {
    try {
      return await DynamoService.getTableDataAsync(_query, accountId);
    } catch (e) {
      throw Error(e.response?.data?.error || e.message || 'There was an error retrieving table data');
    }
  };

  const updateTableState = async (
    _query: TDynamoTableQueryParams,
  ): Promise<void> => {
    setLoading(true);
    try {
      const response = await getTableData(_query);
      if (response) {
        if (_query.startFrom) {
          const newStack = [...startFromStack];
          newStack.push(_query.startFrom as string);
          setStartFromStack(newStack);
        }
        // Update the date to a human readable format
        setTableState(response);
      } else {
        throw Error('There was an error getting table data');
      }
    } catch (e) {
      sb.trigger(e.message);
    } finally {
      setLoading(false);
    }
  };

  const rerenderTable = async () => updateTableState(initialQuery);

  useEffect(() => {
    (async () => {
      if (initialQuery.url) await updateTableState(initialQuery);
    })();
  }, [initialQuery.url, initialQuery.rerenderTable, initialQuery.startDate, initialQuery.endDate]);

  const handleNextPage = async () => {
    const query: TDynamoTableQueryParams = {
      url: initialQuery.url,
      pageSize: initialQuery.pageSize,
      startFrom: tableState?.nextStartFrom,
      startDate: initialQuery.startDate,
      endDate: initialQuery.endDate,
    };
    await updateTableState(query);
  };

  const handlePreviousPage = async () => {
    try {
      setLoading(true);
      const clonedStartFromStack = [...startFromStack];
      clonedStartFromStack.pop();

      const query: TDynamoTableQueryParams = {
        url: initialQuery.url,
        pageSize: initialQuery.pageSize,
        startFrom: clonedStartFromStack.length > 0
          ? clonedStartFromStack[clonedStartFromStack.length - 1]
          : undefined,
        startDate: initialQuery.startDate,
        endDate: initialQuery.endDate,
      };

      const response = await getTableData(query);
      setStartFromStack(clonedStartFromStack);
      setTableState(response);
    } catch (e) {
      sb.trigger(e.message || 'Something went wrong when going to the previous page');
    } finally {
      setLoading(false);
    }
  };

  return {
    data: tableState && tableState?.items && tableState.items,
    hasMore: tableState?.hasMore,
    hasPrevious: startFromStack.length > 0,
    rerenderTable,
    handleNextPage,
    handlePreviousPage,
    loading,
    startFromStack,
  };
};

export default useDynamoTable;
