import { Link } from 'react-router-dom';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import ActionButton from 'components/Buttons/ActionButton';
import React, { useState } from 'react';
import environmentVars from 'env.variables';
import Typography from '@mui/material/Typography';
import { faPlay } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStyles, { StyledIconButton } from './index.styles';

interface IProps {
  handleClose: () => void;
}

const UserGuidesCard: React.FC<IProps> = ({
  handleClose,
}: IProps): JSX.Element => {
  const styles = useStyles();
  return (
      <div className={styles.root}>
        <div className={styles.img} />
        <div className={styles.content}>
          <div>
            <Typography className={styles.title}>Guides</Typography>
            <Typography className={styles.header}>How to: Entity Management</Typography>
            <p className={styles.subHeader}>
              Use our handy guide and learn everything there is to know about user management within the Alpha platform
            </p>
          </div>
          <div className={styles.buttonGroup}>
            <Link to={{ pathname: `${environmentVars.CLOUDFRONT_DISTRIBUTION_DOMAIN}ViewingAndEditing.mp4` }} target="_blank">
              <ActionButton
                className={styles.button}
                rounded
              >
                <FontAwesomeIcon className={styles.playIcon} icon={faPlay} />
                Viewing and Editing
              </ActionButton>
            </Link>
            <Link to={{ pathname: `${environmentVars.CLOUDFRONT_DISTRIBUTION_DOMAIN}CreatingNewUser.mp4` }} target="_blank">
              <ActionButton
                className={styles.button}
                rounded
              >
                <FontAwesomeIcon className={styles.playIcon} icon={faPlay} />
                Creating a new user
              </ActionButton>
            </Link>
            <Link to={{ pathname: `${environmentVars.CLOUDFRONT_DISTRIBUTION_DOMAIN}Approvals.mp4` }} target="_blank">
              <ActionButton
                className={styles.button}
                rounded
              >
                <FontAwesomeIcon className={styles.playIcon} icon={faPlay} />
                Approvals
              </ActionButton>
            </Link>
          </div>
        </div>
        <StyledIconButton
          data-testid="closeIcon"
          onClick={handleClose}
          icon={faTimes}
        />
      </div>
  );
};

export default UserGuidesCard;
