import React, { useState } from 'react';
import {
  faCheckCircle, faTimesCircle, faEye, faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { useGridApiContext } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import ActionDropDown from '../../../../components/ActionDropDown';
import useActionDropdown from '../../../../../../hooks/useActionDropDown';
import useAlphaSnackbar from '../../../../../../hooks/useAlphaSnackbar';
import saveCurrentPageRoute from '../../../../../../utils/saveCurrentPageRoute';
import routes from '../../../../../../routes.path';
import history from '../../../../../../services/history/browserHistory';
import UserManagementService from '../../../../../../services/UserManagement/userManagement.service';
import { initiateUserRoleRequestApproval } from '../../../../../../store/authy/actions';
import AcknowledgeModal from '../../../../../../components/Modals/AcknowledgeModal';

interface IProps {
  userId: string;
  roleRequestId: string;
  onSubmitAction: () => void;
  isApprovable: boolean;
  isRejectable: boolean;
  isNewUser: boolean;
}

const PendingApprovalDropDown: React.FC<IProps> = (props: IProps) => {
  const {
    userId, onSubmitAction, isApprovable, isRejectable, roleRequestId, isNewUser,
  } = props;
  const {
    handleRenderConfirmationModal,
    setOpenModal,
    openModal,
  } = useActionDropdown();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();

  const handleRedirectToUser = () => {
    saveCurrentPageRoute();
    history.push(
      `${routes.userManagement.userRoute(userId)}`,
    );
  };

  const apiRef = useGridApiContext();

  const decisionModal = (
    <AcknowledgeModal
      open={openModal}
      title="Are you sure you wish to reject this request?"
      actionButtonText="Reject"
      handleActionButton={() => {
        handleRejectPermRequest(roleRequestId);
      }}
      icon={faExclamationTriangle}
      cancelButtonText="No, go back"
      actionButtonColour="error"
      handleClose={() => setOpenModal(false)}
      loading={loading}
    />
  );

  const handleRejectPermRequest = async (
    requestId: string,
  ): Promise<void> => {
    setLoading(true);
    try {
      setOpenModal(false);
      await UserManagementService.rejectUserRoleRequest({ requestId });
      onSubmitAction();
      apiRef.current.forceUpdate(); // TODO confirm this works with non mocked data
      sb.trigger('Successfully rejected permission change request', 'success');
    } catch {
      sb.trigger('There was an error rejecting this request', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleApproveUserRoleRequest = async () => {
    try {
      dispatch(
        initiateUserRoleRequestApproval({
          type: 'USER_ROLE_REQUEST',
          requestId: roleRequestId,
        }),
      );
    } catch {
      throw Error('There was an error approving your request');
    }
  };

  const pendingTableDropdownItems = [];

  if (!isNewUser) {
    pendingTableDropdownItems.push(
      {
        id: 'action-permission-view',
        content: (
          <Typography>
            View User
          </Typography>
        ),
        icon: faEye,
        onClick: () => { handleRedirectToUser(); },
        underline: (isRejectable || isApprovable),
      },
    );
  }

  if (isApprovable) {
    pendingTableDropdownItems.push(
      {
        id: 'action-pending-permission-approve',
        content: (
          <Typography>
            Approve
          </Typography>
        ),
        icon: faCheckCircle,
        onClick: handleApproveUserRoleRequest,
        underline: isRejectable,
      },
    );
  }

  if (isRejectable) {
    pendingTableDropdownItems.push(
      {
        id: 'action-pending-permission-reject',
        content: (
          <Typography>
            Reject
          </Typography>
        ),
        icon: faTimesCircle,
        onClick: handleRenderConfirmationModal,
        underline: false,
      },
    );
  }

  return (
    (pendingTableDropdownItems.length !== 0
      ? <ActionDropDown menuItems={pendingTableDropdownItems} decisionModal={decisionModal} />
      : <></>)
  );
};

export default PendingApprovalDropDown;
