import { ActionButton } from 'components/Buttons';
import { Box, Typography } from '@mui/material';
import React, { memo } from 'react';
import useStyles from './DrawerHeader.styles';

interface IProps {
  displayHeaderTitle: string;
  numberOfGroups: number;
  toggleCreateGroupDrawer: (open:boolean) => void;
  displayButton?: boolean
  canEditOrDeleteGroup?: boolean;
}

const DrawerHeader = (props: IProps) => {
  const classes = useStyles();
  const {
    displayHeaderTitle,
    numberOfGroups,
    toggleCreateGroupDrawer,
    displayButton,
    canEditOrDeleteGroup,
  } = props;

  const handleClickCreateNewGroup = () => toggleCreateGroupDrawer(true);
  return (
    <div className={classes.header}>
      <div className={classes.headerContent}>
        <Typography>{displayHeaderTitle}</Typography>
      </div>
      <div className={classes.title}>
        <span>Groups</span>
      </div>
      <div className={classes.subHeaderContent}>
        <Box>{numberOfGroups}</Box>
        {
            displayButton && (
              <Box className={classes.btn}>
                <ActionButton
                  size="large"
                  testId="create-new-group-button"
                  onClick={handleClickCreateNewGroup}
                >
                  Create New Group
                </ActionButton>
              </Box>
            )
          }
      </div>
    </div>
  );
};

export default memo(DrawerHeader);
