import React, { FC, memo } from 'react';

import { IStatusProps } from './Status.interfaces';
import StyledStatusSpan from './Status.styles';

const Status: FC<IStatusProps> = memo((props) => {
  const {
    children, testId, className, onClick, variant = 'default', size = 'default',
  } = props;

  return (
    <StyledStatusSpan
      variant={variant}
      size={size}
      data-testid={testId}
      onClick={onClick}
      className={className}
    >
      {children}
    </StyledStatusSpan>
  );
});

export default Status;
