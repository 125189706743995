import { ITheme } from 'theme';
import { TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@mui/styled-engine';
import { motion } from 'framer-motion';
import { IconButton } from 'components/Buttons';

const StyledSearchBarWrapper = styled('div')(({ theme }: {theme: ITheme}) => ({
  display: 'flex',
  alignItems: 'end',
  fontWeight: 'bold',
  '& > span': {
    flexGrow: 1,
    '& > span': {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledTextField = styled(TextField)({
  width: '25%',
  background: 'white',
  fontWeight: 'bold',

  '& > div:hover fieldset': {
    borderColor: '#E5E5E5 !important',
  },

  '& > .Mui-focused fieldset': {
    borderColor: '#E5E5E5 !important',
    borderWidth: '1px !important',
  },

  '& fieldset': {
    borderColor: '#E5E5E5',
  },
});

const StyledSearchIcon = styled(FontAwesomeIcon)({
  color: '#B3B3B3',
  fontSize: '1.2rem',
  marginRight: 12,
});

const StyledIconButton = styled(IconButton)({
  height: 25,
  width: 25,
  fontSize: '1.2rem',
});

const StyledNoSearchResult = styled(motion.div)(({ theme }: {theme: ITheme}) => ({
  fontWeight: 'bold',
  padding: '2rem',
  background: 'white',
  borderRadius: 10,
  '& span': {
    color: theme.palette.primary.main,
  },
}));

export {
  StyledSearchBarWrapper, StyledTextField, StyledSearchIcon, StyledIconButton, StyledNoSearchResult,
};
