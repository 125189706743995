import theme, { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  headerWrapper: {
    top: 0,
    left: 0,
    width: '100%',
    padding: '60px 30px 30px 50px',
    borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
  },
  entityGroupNameInput: {
    '& > .MuiInputBase-input': {
      height: '30px',
      lineHeight: '30px',
      fontSize: '22px',
      fontWeight: '400',
      letterSpacing: '-0.5px',
      padding: '0 0 0 0',
    },
    display: 'flex',
  },
  headerSubtitle: {
    width: '120px',
    padding: '30px 0 12px 0',
    borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    '& > h6': {
      fontSize: 13,
      fontWeight: 600,
      color: '#999999',
    },
  },
  headerSubTitle2: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    paddingTop: '12px',
    '& > p': {
      fontSize: 39,
      fontWeight: 'lighter',
      color: theme.palette?.primary?.main,
    },
    '& > button': {
      marginLeft: 'auto',
    },
  },
  noOfEntities: {
    fontSize: 39,
    fontWeight: 'lighter',
    color: theme.palette?.primary?.main,
  },
  groupColourIcon: {
    height: '30px',
    fontSize: '23px',
    marginRight: '14px',
    color: ({ groupColour }: { groupColour?: string }) => (
      groupColour || theme.palette?.primary?.main
    ),
  },
  accountsDropdownContainer: {
    '& > .MuiInputBase-root': {
      paddingTop: '0px',
    },
  },
  accountsDropdown: {
    width: '250px',
    height: '44px',
    borderRadius: '5px',
  },

  drawerGoBackButton: {
    height: '30px',
    width: '60px',
    marginRight: '14px',
    color: '#4B4A4A',
    opacity: 0.3,
  },
  formErrors: {
    color: '#b95656',
  },
  bodyWrapper: {
    overflowX: 'hidden',
    paddingTop: '20px',
    backgroundColor: '#FAFAFA',
    height: '100%',
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 15px 0 15px',
  },
  accountName: {
    fontSize: '19px',
    textAlign: 'left',
    fontWeight: 600,
    color: theme.palette?.primary?.main,
    flexGrow: 1,
  },

  addAccountCardTitle: {
    fontSize: '16px',
    margin: ' 0px 0px 4px 2px',
    textAlign: 'left',
    fontWeight: 'lighter',
    color: 'black',
  },

  actionButtons: {
    display: 'flex',
    '& > button': {
      marginLeft: '8px',
      height: '30px',
      width: '48px',
      backgroundColor: 'white',
    },
  },

  addToGroupButton: {
    marginLeft: 'auto',
    '& > button': {
      height: '50px',
      width: '150px',
    },
  },

  noAccountsCard: {
    height: '100%',
    width: '100%',
    textAlign: 'center',
    padding: '150px 0 0 0',
    '& > h5': {
      padding: '16px 0px 16px 0px',
      fontWeight: 'bold',
      letterSpacing: '-0.5px',
    },
  },
  cardWrapper: {
    padding: '20px 36px',
    boxShadow: '0px 3px 6px #00000008',
    display: 'flex',
    height: '111px',
    backgroundColor: 'white',
    width: '84%',
    margin: 'auto',
    alignItems: 'center',
  },
  addAccountCardWrapper: {
    padding: '16px 0px 0px 0px',
    display: 'flex',
    height: '111px',
    flexDirection: 'column',
  },
  iconWrapper: {
    padding: '30px',
    width: 'max-content',
    margin: 'auto',
    background: '#D6D6D630',
    borderRadius: '100%',
  },
  folderIcon: {
    color: '#c2c2c2',
    fontSize: '6em',
  },
  deleteEntityButton: {
    '&:hover, &focus': {
      backgroundColor: 'rgba(131, 9, 9, 0.2)',
      color: 'rgb(131, 9, 9)',
    },
  },
}), { name: 'CreateEntityGroup' });

export default useStyles;
