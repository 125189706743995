import {
  Field, FieldProps,
} from 'formik';
import _default from 'theme';
import clsx from 'clsx';
import React from 'react';
import useStyles from './index.styles';
import PhoneInputField from '../PhoneInputField';

export const { typography } = _default;

export interface IInputFormFieldProps {
  name: string,
  type: string,
}

export const InputFormField = (props: IInputFormFieldProps) => {
  const {
    type, name,
  } = props;

  const {
    inputWrapper, errorMessage, textBox,
  } = useStyles();

  return (
    <div className={inputWrapper} data-testid={`input-field-${name}`}>
      <Field
        name={name}
      >
        {({
          field,
          meta,
          form,
        }: FieldProps) => (
          <>
            {type === 'mobile' || type === 'direct' ? (
              <PhoneInputField
                field={field}
                meta={meta}
                form={form}
              />
            ) : (
              <input
                className={clsx(textBox, {
                  error: meta.touched && meta.error,
                })}
                type={type}
                {...field}
              />
            )}
            {meta.touched && meta.error && (
              <div className={errorMessage}>{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  );
};
export default InputFormField;
