import { Box } from '@mui/material';
import React, { memo } from 'react';
import { TCurrencyAccounts } from '../../../../models/currencyAccounts';
import { TIATAccountTypes } from '../../../../models/interAccountTransfer';
import { useStyles } from './ActionAccounts.styles';
import AmountAndBalanceContainer from '../../AmountAndBalanceContainer/AmountAndBalanceContainer';
import DropdownContainer from './DropdownContainer/DropdownContainer';

interface IProps {
  type: TIATAccountTypes;
  currencyAccounts: TCurrencyAccounts;
  disabled: boolean;
}

const ActionAccounts: React.FC<IProps> = (props: IProps) => {
  const {
    type,
    currencyAccounts,
    disabled,
  } = props;
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <DropdownContainer disabled={disabled} type={type} currencyAccounts={currencyAccounts} />
      <AmountAndBalanceContainer
        type={type}
        disabled={disabled}
      />
    </Box>
  );
};

export default memo(ActionAccounts);
