import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { originalUseSnackbar } from 'components/Snackbar';
import routes from '../routes.path';
import { TSignIn } from '../models/auth';
import history from '../services/history/browserHistory';
import { actions } from '../store/user/user.reducer';
import AuthService from '../services/Auth/auth.service';

const initialValue = {
  mfa: '',
};

const useCustomMultifactorAuth = (newUserObject: TSignIn, onboarding?: boolean) => {
  const snackbar = originalUseSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleMfa = async (userObject: TSignIn, code: string) => {
    try {
      setLoading(true);
      await AuthService.sendCustomChallengeAnswer(userObject, code);
      const response = await AuthService.currentUserInfo();
      await AuthService.saveSessionUUIDToLocalStorage();

      if (response) dispatch(actions.updateUserDetails(response));
      localStorage.setItem('acknowledged-user-guides', 'true');
      history.push(onboarding ? routes.auth.onboarding.welcome : routes.auth.welcome);
    } catch (e) {
      const snackbarMessage = e?.message === 'Invalid session for the user.'
        ? 'Your session has expired, please sign in again'
        : 'There was an error confirming your token';

      snackbar.enqueueSnackbar(snackbarMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
        className: 'dd-privacy-allow',
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      try {
        await handleMfa(newUserObject, values.mfa);
      } catch (e) {
        snackbar.enqueueSnackbar(e.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
          className: 'dd-privacy-allow',
        });
      }
    },
  });

  return {
    formik,
    loading,
  };
};

export default useCustomMultifactorAuth;
