import { BatchApprover } from '@alpha/payments-dtos';
import { ActionButton } from 'components/Buttons';
import { Box, Typography } from '@mui/material';
import React from 'react';
import MultiSelectDropDown from '../../../../../../../../components/MultiSelectDropDown/MultiSelectDropDown';

interface IProps {
  currentOptions: BatchApprover[],
  loading: boolean,
  awaitingSubmit: boolean,
  menuCloseCount: number,
  setMenuCloseCount: React.Dispatch<React.SetStateAction<number>>
  handleSubmit: () => void,
  setCurrentOptions: React.Dispatch<React.SetStateAction<BatchApprover[]>>,
  setTextValue: React.Dispatch<React.SetStateAction<string>>,
  handleApprovers: () => BatchApprover[]
}

const DropDownWrapper = ({
  loading,
  currentOptions,
  awaitingSubmit,
  menuCloseCount,
  setMenuCloseCount,
  handleSubmit,
  handleApprovers,
  setTextValue,
  setCurrentOptions,
}: IProps) => (
  <Box display="flex" flexDirection="column">
    <Typography>
      Select from list of available approvers
    </Typography>
    <Box paddingTop="7px" paddingBottom="16px">
      <MultiSelectDropDown
        onClose={() => setMenuCloseCount(menuCloseCount + 1)}
        onOpen={() => setMenuCloseCount(0)}
        loading={loading}
        options={handleApprovers()}
        currentOptions={currentOptions}
        setCurrentOptions={setCurrentOptions}
        placeholder="Select"
        setTextValue={setTextValue}
      />
    </Box>
    <ActionButton
      disabled={currentOptions?.length === 0 || awaitingSubmit}
      loading={awaitingSubmit}
      fullWidth
      size="large"
      testId="send-email"
      onClick={handleSubmit}
    >
      send email
    </ActionButton>
  </Box>
);

export default DropDownWrapper;
