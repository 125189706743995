import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { Typography, CircularProgress, Alert, Box } from '@mui/material';
import { Formik, Field, FieldProps } from 'formik';
import { ActionButton } from 'components/Buttons';
import { CentredDiv, ChoiceDiv, StyledAuthHeader, StyledDivider, StyledFieldError, StyledForm, StyledFormControlLabel, StyledInputField, StyledLabel, UnAuthorisedPage } from '../../Authentication/index.styles';
import AlphaLogoWhite from '../../../../../assets/logo.svg';
import { handleSubmit } from '../api';

export default function UnauthorizedPage({
  loading,
}: {
  loading: boolean
}) {
  const [email, setEmail] = useState('');
  const [loginState, setLoginState] = useState('');

  const showLoading = loading || loginState === 'loading';
  return (
    <UnAuthorisedPage>
      <CentredDiv>
        <StyledAuthHeader>
          <img src={AlphaLogoWhite} alt="Alpha FX Logo" />
          <Typography>Onboarding Form</Typography>
          <StyledDivider orientation="vertical" light flexItem />

        </StyledAuthHeader>
        {showLoading && (
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        )}

        {!showLoading && loginState === '' && (
          <ChoiceDiv>
            <Formik
              onSubmit={() => { }}
              initialValues={{ email: '' }}
            >
              <StyledForm>
                <StyledFormControlLabel
                  key="email"
                  data-testid="email-input"
                  label={<StyledLabel>Enter your Email</StyledLabel>}
                  labelPlacement="top"
                  control={(
                    <Field name="email">
                      {({ meta }: FieldProps) => (
                        <>
                          {meta.touched && meta.error && (
                            <StyledFieldError>
                              <FontAwesomeIcon color="#C64545" icon={faExclamationCircle} />
                              {meta.error}
                            </StyledFieldError>
                          )}
                          <StyledInputField
                            placeholder="Enter your email"
                            type="email"
                            disabled={loading}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </>
                      )}
                    </Field>
                  )}
                />
                <ActionButton
                  size="large"
                  type="submit"
                  testId="submit-Onboarding"
                  fullWidth
                  loading={loading}
                  disabled={false}
                  onClick={() => handleSubmit(email, setLoginState)}
                >
                  Login
                </ActionButton>
              </StyledForm>
            </Formik>
          </ChoiceDiv>
        )}

        {loginState === 'success' && <Alert severity="success">A secure access link has been sent to your email</Alert>}

      </CentredDiv>

    </UnAuthorisedPage>

  );
}
