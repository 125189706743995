import { ActionButton } from 'components/Buttons';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface IProps {
    disabled: boolean;
    editable: boolean;
    handleAddPaymentToBatch: () => void;
}

export const SubmitButton = ({
  disabled,
  editable,
  handleAddPaymentToBatch,
}: IProps) => {
  const location = useLocation();
  const isBatch = location.pathname.startsWith('/app/payments/manual/batch');

  return (
    <div className="column buttonColumn">
      {isBatch && (
        <div className="addToBatch">
          <ActionButton
            disabled={disabled}
            type="submit"
            testId="submit-login"
            fullWidth
            onClick={handleAddPaymentToBatch}
          >
            {!editable ? 'ADD TO BATCH' : 'UPDATE PAYMENT'}
          </ActionButton>
        </div>
      )}
    </div>
  );
};

export default SubmitButton;
