import { FxResponse, SameTradeResponse } from '@alpha/fx-dtos';
import { BatchQuoteDto } from '@alpha/payments-dtos';
import { AxiosResponse } from 'axios';
import { TTransactionData } from '../../models/transactions';
import instance, { instanceAccountId } from '../Axios/instance';

class FXTransferService {
  public static async getFxData(
    fxId: string,
    friendlyId?: string,
    accountId?: string,
  ): Promise<TTransactionData> {
    const fxResponse: AxiosResponse<FxResponse> = accountId
      ? await instanceAccountId(accountId).get(`/fx/${fxId}`)
      : await instance.get(`/fx/${fxId}`);
    return this.mapFxToTransactionData(fxId, fxResponse.data, friendlyId, accountId);
  }

  public static async checkSameTrade(
    batchId: string, accountId?: string,
  ): Promise<SameTradeResponse[]> {
    const response = accountId
      ? await instanceAccountId(accountId).get(`/payments/batches/${batchId}/check-duplicate-trades`)
      : await instance.get(`/payments/batches/${batchId}/check-duplicate-trades`);
    return response.data;
  }

  public static async getLiveRates(
    batchId: string,
    accountId?: string,
  ): Promise<BatchQuoteDto[]> {
    const response: AxiosResponse<BatchQuoteDto[]> = accountId
      ? await instanceAccountId(accountId).get(`/payments/batches/${batchId}/quotes`)
      : await instance.get(`/payments/batches/${batchId}/quotes`);
    return response.data;
  }

  public static async postTrades(
    batchId: string,
    instructionIds: string[],
    accountId?: string,
  ): Promise<any> {
    const response = accountId
      ? await instanceAccountId(accountId).post(`/payments/batches/${batchId}/trades`, { instructionIds })
      : await instance.post(`/payments/batches/${batchId}/trades`, { instructionIds });
    return response;
  }

  private static mapFxToTransactionData = (
    fxId: string,
    fxResponse: FxResponse,
    trade?: string,
    accountId?: string,
  ): TTransactionData => ({
    clientFxTradeId: fxId,
    debitFriendlyName: fxResponse.debitCurrencyAccount.friendlyName,
    creditFriendlyName: fxResponse.creditCurrencyAccount.friendlyName,
    arrivalDate: fxResponse.arrivalDate,
    reference: fxResponse.reference,
    creditAmount: fxResponse.creditAmount,
    debitAmount: fxResponse.debitAmount,
    debitReference: fxResponse.debitCurrencyAccount.reference,
    creditReference: fxResponse.creditCurrencyAccount.reference,
    debitIban: fxResponse.debitCurrencyAccount.iban,
    creditIban: fxResponse.creditCurrencyAccount.iban,
    debitType: fxResponse.debitCurrencyAccount.type,
    creditType: fxResponse.creditCurrencyAccount.type,
    debitCurrencyCode: fxResponse.debitCurrencyAccount.currencyCode,
    creditCurrencyCode: fxResponse.creditCurrencyAccount.currencyCode,
    valueDate: fxResponse.valueDate,
    fxRate: fxResponse.fxRate,
    tradeId: trade,
    accountId,
  });
}

export default FXTransferService;
