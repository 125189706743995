import { Guid } from 'guid-typescript';
import {
  call, put, putResolve, select, takeLatest,
} from 'redux-saga/effects';
import { TStore } from '..';
import { EntityGroup } from '../../models/entityGroups';
import { TUserAccount } from '../../models/user';
import EntityGroupsService from '../../services/EntityGroups/entityGroups.service';
import { actions as notificationsActions } from '../notifications/notifications.reducer';
import { actions } from './reducer';

async function getEntityGroups(): Promise<EntityGroup[]> {
  const result = await EntityGroupsService.getEntityGroups();
  return result;
}

async function getUserAccountsWithEntityManagerPerm(): Promise<TUserAccount[]> {
  const result = await EntityGroupsService.getAccountsWithEntityManagerPerm();
  return result;
}

async function getUserAccountsWithEntityViewerPerm(): Promise<TUserAccount[]> {
  const result = await EntityGroupsService.getAccountsWithEntityViewerPerm();
  return result;
}

export function* watchGetEUserAccountsSaga() {
  yield takeLatest('GET_USER_ACCOUNTS_E_REQUEST', getUserAccountsSaga);
}

export function* getUserAccountsSaga() {
  try {
    const responseManager:TUserAccount[] = yield call(getUserAccountsWithEntityManagerPerm);
    const responseViewer:TUserAccount[] = yield call(getUserAccountsWithEntityViewerPerm);
    const responseManagerMap = new Map(responseManager.map(
      (entity) => [entity.id, entity],
    ));
    const filteredResponseViewer = responseViewer.filter(
      (entity) => !responseManagerMap.has(entity.id),
    );
    const response:TUserAccount[] = [...responseManager, ...filteredResponseViewer];
    yield putResolve(actions.getUserAccountsWithEPerms({ accounts: response }));
  } catch (error) {
    yield put(
      notificationsActions.enqueueSnackbar({
        variant: 'error',
        className: 'dd-privacy-allow',
        key: Guid.create().toString(),
        message: error.response?.data?.error || 'Could not get user accounts',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }),
    );
  }
}

const getSelectedEntityGroup = (state: TStore) => state.entityGroups.selectedEntityGroup;

function getLatestEntityGroupDetails(
  selectedEntityGroupId: string, entityGroups: EntityGroup[],
) : EntityGroup {
  const updatedGroup = entityGroups.find((entityGroup) => entityGroup.id === selectedEntityGroupId);
  if (updatedGroup) {
    return updatedGroup;
  }

  throw new Error('could not get latest details for this entity group');
}

export function* watchUpdateEntityGroups() {
  yield takeLatest('UPDATE_ENTITY_GROUPS_REQUEST', updateEntityGroups);
}
export function* updateEntityGroups() {
  try {
    yield put(actions.updateLoadingState({ loading: true }));
    const response:EntityGroup[] = yield call(getEntityGroups);
    yield putResolve(actions.updateEntityGroupsForUserSuccess({ updatedGroups: response }));
    const selectedEntityGroup:EntityGroup = yield select(getSelectedEntityGroup);
    if (selectedEntityGroup) {
      const updatedEntityGroupDetails = getLatestEntityGroupDetails(
        selectedEntityGroup.id,
        response,
      );
      yield putResolve(actions.setSelectedEntityGroup(updatedEntityGroupDetails));
    }
  } catch (error) {
    yield put(
      notificationsActions.enqueueSnackbar({
        variant: 'error',
        className: 'dd-privacy-allow',
        key: Guid.create().toString(),
        message: error.response?.data?.error || 'Could not update entity group details at this time',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }),
    );
  } finally {
    yield put(actions.updateLoadingState({ loading: false }));
  }
}
