import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IAuthProps } from '../..';
import routes from '../../../../routes.path';
import NewPassword from '../../NewPassword';
import SetupMFA from '../../SetupMFA';
import VerifyMfa from '../../VerifyMfa';
import Welcome from '../Welcome';

interface IPostOnboardingLogin extends IAuthProps {
    mfaImage?: 'authy' | 'sms',
    setMfaImage: React.Dispatch<React.SetStateAction<'authy' | 'sms' | undefined>>,
  }

const PostOnboardingLogin: React.FC<IPostOnboardingLogin> = (
  {
    userObject, userInfo, mfaImage, setMfaImage,
  }: IPostOnboardingLogin,
) => {
  if (!userObject) return <Redirect to={routes.auth.onboarding.login} />;
  return (
    <>
      <Route exact path={routes.auth.onboarding.newPassword}>
        <NewPassword onboarding userObject={userObject} />
      </Route>
      <Route exact path={routes.auth.onboarding.setupMfa}>
        <SetupMFA userObject={userObject} onboarding setup setMfaImage={setMfaImage} />
      </Route>
      <Route exact path={routes.auth.onboarding.mfaOptions}>
        <SetupMFA userObject={userObject} onboarding setMfaImage={setMfaImage} />
      </Route>
      <Route exact path={routes.auth.onboarding.verifyMfa}>
        <VerifyMfa userObject={userObject} onboarding mfaImage={mfaImage} />
      </Route>
      <Route exact path={routes.auth.onboarding.welcome}>
        <Welcome name={userInfo?.Name || ''} />
      </Route>
    </>
  );
};

export default PostOnboardingLogin;
