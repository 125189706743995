import styled from '@mui/styled-engine';
import _default from 'theme';

const { typography } = _default;
const StyledAPDatePicker = styled('div')(({fullWidth}) => ({
  '.MuiPickersBasePicker-container': {
    minHeight: 330,
    '& > .MuiPickersToolbar-toolbar': {
      backgroundColor: '#F7F7F7',
      '&.MuiPickersToolbar-toolbarLandscape': {
        maxWidth: 130,
      },
      '& > button > span > h6': {
        color: 'rgba(34,34,34,0.5)',
        fontSize: 14,
      },
      '& > button > span > h4': {
        fontWeight: typography.fontWeightLight,
        fontSize: 22,
      },
    },
  },
  boxShadow: 'none',
  height: '50px',
  '& .MuiFormLabel-root': {
    transform: 'none !important',
    marginTop: -20,
    fontSize: 10,
  },
  rebrandIcon: {
    right: 20,
  },
  '& .icon': {
    width: '20px !important',
    color: 'rgba(128, 128, 128, 0.5)',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 12,
  },
  display: 'inline-block',
  width: fullWidth ? '100%' : 'auto',
  position: 'relative',
  '& > div': {
    width: 275,
    '& > div.MuiInput-underline': {
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
  },
  '& input': {
    maxHeight: 50,
    backgroundColor: '#F7F7F7',
    borderRadius: 5,
    padding: '16px 40px',
    border: 'none',
  },
}));
export default StyledAPDatePicker;
