import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  bodyWrapper: {
    height: '100%',
    paddingTop: '20px',
  },
}),
{ name: 'DrawerBody' });

export default useStyles;
