import styled from '@mui/styled-engine';
import Switch from '@mui/material/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, Select, Typography } from '@mui/material';
import APDatePicker from 'components/APDatePicker/APDatePicker';

export const StandingOrderStyled = styled('div')({
  margin: '20px 0',
});

export const StandingOrderSwitchBoxStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const SwitchStyled = styled(Switch)({
  width: 36,
  height: 22,
  padding: 0,
  marginTop: 10,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1E8777',
        opacity: 1,
        border: 0,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9E9',
    opacity: 1,
    transition: 'background-color, ease, 500',
  },
});

export const SelectStyled = styled(Select)(({ isSelected }: {isSelected: boolean}) => ({
  boxShadow: 'none',
  height: '50px',
  backgroundColor: '#f7f7f7',
  color: isSelected ? '#000' : '#737373',
  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiInputBase-input': {
    height: '100%',
    borderRadius: '5px',
    padding: '0 16px',
    paddingRight: '40px',
    marginBottom: '0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '& .MuiSvgIcon-root': {
    right: '7px',
  },
}));

export const MenuItemStyled = styled(MenuItem)({
  '& span': {
    display: 'none',
  },
});

export const SelectPlaceholderStyled = styled(MenuItem)({
  display: 'none',
});

export const SelectOptionNoteStyled = styled('span')({
  color: '#737373',
});

export const APDatePickerStyled = styled(APDatePicker)({
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
});

export const NoteStyled = styled(Typography)({
  color: '#737373',
  marginTop: '10px',
  fontSize: '14px',
  paddingLeft: 33,
  display: 'flex',

  '& span': {
    marginRight: 5,
  },
});

export const NoteIconStyled = styled(FontAwesomeIcon)({
  marginRight: '10px',
  fontSize: 20,
});

export const DividerStyled = styled('div')({
  margin: '20px 0',
  height: '1px',
  backgroundColor: '#707070',
  opacity: '.1',
});

export const FormErrorStyled = styled('p')({
  color: '#b95656',
  fontSize: '0.75rem',
  lineHeight: '1.66',
  marginLeft: '14px',
  marginTop: '3px',
  fontWeight: '400',
});

export const NotesHeadingStyled = styled(Typography)({
  marginTop: '0 !important',
  display: 'flex',
  alignItems: 'center',
});

export const NotesStyled = styled('div')({
  background: '#EFF6FF',
  padding: 20,
  marginTop: 20,
  borderRadius: 8,
});
