import {
  Box, Button, Card,
  Typography,
} from '@mui/material';
import { Loader } from 'components/Loader';
import { useSnackbar } from 'components/Snackbar';
import {
  faFileExcel,
  faFilePdf,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, {
  memo,
  ReactText, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from 'components/Buttons';
import { FileType } from '../../hooks/useReportsPolling';
import { TStore } from '../../store';
import { TDownload, TDownloadQueue } from '../../store/generateReport/reducer';
import { useStyles } from './SnackMessage.styles';

interface ISnackMessageProps {
  reportType: FileType;
  reportMessage: string;
  id: ReactText;
  error?: boolean;
  takeCompleteReportMessage?: boolean;
  completedMessage?: string;
  subHeader?: string;
}

const SnackMessage: React.FC<ISnackMessageProps> = React.forwardRef(
  (props: ISnackMessageProps, ref: any) => {
    const styles = useStyles();
    const downloadRef = useRef(null);
    const {
      reportType, id, error, reportMessage, subHeader, takeCompleteReportMessage, completedMessage,
    } = props;
    const { closeSnackbar } = useSnackbar();
    const downloadQueue = useSelector<TStore, TDownloadQueue>(
      (store: TStore) => store.generatePdf.downloadQueue,
    );
    const [downloadState, setDownloadState] = useState<Omit<TDownload, 'id' | 'downloadTime'>>({
      status: 'INITIATE',
    });

    const handleDownload = () => {
      closeSnackbar(id);
    };
    const handleDismiss = () => {
      closeSnackbar(id);
    };

    const handlePageTypeDownload = (downloadPageQueue: TDownloadQueue) => {
      downloadPageQueue.forEach((value: TDownload) => {
        if (value.id === id) {
          setDownloadState(
            { status: value.status, downloadLink: value.downloadLink },
          );
        }
      });
    };
    useEffect(() => {
      handlePageTypeDownload(downloadQueue);
    }, [downloadQueue]);

    const renderRight = () => {
      if (error || downloadState.status === 'ERROR') {
        return (
          <>
            <IconButton icon={faTimes} onClick={handleDismiss} className={styles.close} />
          </>
        );
      }
      if (downloadState.status === 'INITIATE') {
        return (
          <>
            <Loader testId="loader" size={20} className={styles.loader} />
          </>
        );
      }
      if (downloadState.status === 'COMPLETED' && downloadState.downloadLink) {
        return (
          <>
            <Button
              download
              href={downloadState.downloadLink}
              target="_blank"
              ref={downloadRef}
              className={styles.download}
              onClick={handleDownload}
            >
              DOWNLOAD
            </Button>
            <IconButton icon={faTimes} onClick={handleDismiss} className={styles.close} />
          </>
        );
      }
      return (
        <>
          <IconButton icon={faTimes} onClick={handleDismiss} className={styles.close} />
        </>
      );
    };

    const handleVariables = () => {
      if (downloadState.status === 'ERROR' || error) {
        return {
          text: takeCompleteReportMessage ? 'Zip download error' : `${reportMessage} download error`,
          subHeader: 'There was an error generating your report',
          backgroundColor: styles.error,
        };
      }
      if (downloadState.status === 'INITIATE') {
        return {
          text: takeCompleteReportMessage ? reportMessage : `${reportMessage} download in progress`,
          subHeader: subHeader || 'We will let you know when it\'s complete',
          backgroundColor: styles.initiate,
        };
      }
      return {
        text: completedMessage || `${reportMessage} download completed`,
        subHeader: 'Click download to receive your file',
        backgroundColor: styles.completed,
      };
    };

    const variables = handleVariables();
    return (
      <Card
        className={clsx([styles.card, variables.backgroundColor])}
        ref={ref}
      >
        <Box className={styles.wrapper}>
          <Box className={styles.left}>
            <Box className={styles.icon}>
              <FontAwesomeIcon
                icon={reportType === FileType.PDF ? faFilePdf : faFileExcel}
              />
            </Box>
            <Box className={styles.typography}>
              <Typography variant="h3" className="header">
                {variables.text}
              </Typography>
              <Typography variant="subtitle1" className="subHeader">
                {variables.subHeader}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.right}>{renderRight()}</Box>
        </Box>
      </Card>
    );
  },
);

export default memo(SnackMessage);
