import React, { useEffect, useLayoutEffect } from 'react';
import { APMainLayout } from 'components/Layout/MainLayout';
import {
  Typography, Card, CardContent, Grid,
} from '@mui/material';
import { StyledTabsWrapper, TabContent } from 'components/Tabs';
import KYCBannerComponent from 'components/KYCBannerComponent';
import { BaseButton } from 'components/Buttons';
import routes from '../../routes.path';
import history from '../../services/history/browserHistory';
import useStyles from './index.styles';
import Dropdown from '../../components/Dashboard/Dropdown';
import Beneficiaries from './Beneficiaries';
import useAuth from '../../hooks/useAuth';
import EntryPointWidget from './EntryPointWidget/EntryPointWidget';
import useEntityGroupDrawer from '../EntityGroups/EntityGroupManagerDrawer/useEntityGroupDrawer';
import Payments from './Payments';
import BalanceTransfer from './BalanceTransfer';
import AwaitingFx from './AwaitingFx';
import useBody from './OutstandingActions/Body/useBody';
import CurrencyAccountsWidget from './CurrencyAccountsWidget/CurrencyAccountsWidget';
import EntityGroupDrawer from '../EntityGroups/EntityGroupManagerDrawer';

const OutstandingActionsDashboard: React.FC = () => {
  const { userInfo } = useAuth();

  const classes = useStyles();
  const {
    requestUpdateEntityGroupDetails,
    toggleViewGroupsDrawer,
  } = useEntityGroupDrawer();

  const {
    location,
    calculateIndexFromUrl,
    calculateUrlFromIndex,
    getDashboardTablesCount,
    tabIndex,
    setTabIndex,
    tabTitles,
    setTablesTabsTitles,
    tableSizeRedux,
    selectedAccounts,
    allowedAccounts,
    userAccountsLoading,
  } = useBody();

  const handleTabChange = (index: number) => {
    setTabIndex(index);
    history.push(`${routes.dashboard.base}?tab=${calculateUrlFromIndex(index)}`);
  };

  const handleOpenViewGroupsDrawer = () => {
    requestUpdateEntityGroupDetails();
    toggleViewGroupsDrawer(true);
  };

  useLayoutEffect(() => {
    setTabIndex(calculateIndexFromUrl());
  }, [calculateIndexFromUrl, location.search, setTabIndex]);

  useEffect(() => {
    setTablesTabsTitles();
  }, [tableSizeRedux, tabIndex]);

  useEffect(() => {
    if (!userAccountsLoading && (
      selectedAccounts.length > 0 || allowedAccounts.length > 0
    )) {
      getDashboardTablesCount();
    }
  }, [selectedAccounts, allowedAccounts, userAccountsLoading]);

  return (
    <APMainLayout
      title={(
        <div style={{ display: 'flex' }}>
          <span>
            {' '}
            <span className="dd-privacy-allow">Welcome Back, </span>
            <span>
              {userInfo?.Name ? userInfo.Name : ''}
              {' '}
            </span>
            {' '}
            |
            {' '}
          </span>
          <Dropdown />
        </div>
      )}
      bodyBackgroundColor="#FAFAFA"
    >
      <div className={classes.backgroundColouringClass}>
        <Grid container justifyContent="space-evenly" className={classes.root}>
          <KYCBannerComponent />
          <Grid className={classes.outstandingDashboardContainer} item lg={8} md={6} sm={12}>
            <Card className={classes.boxShadow}>
              <CardContent className={classes.cardContent}>
                <Typography data-dd-privacy="allow" className={classes.tableHeader}>Outstanding Actions</Typography>
                <div className={classes.viewAllButton}>
                  <BaseButton
                    onClick={() => {
                      history.push(`${routes.dashboard.outstanding}?tab=${calculateUrlFromIndex(tabIndex)}`);
                    }}
                    testId="view-all-button"
                    variant="outlined"
                    rounded
                    color="secondary"
                    data-dd-privacy="allow"
                  >
                    View All
                  </BaseButton>
                </div>
                <StyledTabsWrapper
                  className="dd-privacy-allow"
                  testId="tabs-wrapper"
                  tabTitles={tabTitles}
                  tabIndex={tabIndex}
                  handleChange={handleTabChange}
                />
                <div className={classes.tabContainer}>
                  <TabContent index={0} value={tabIndex}>
                    <Payments
                      enablePagination
                      enableSearch={false}
                      entityClassName={classes.entity}
                    />
                  </TabContent>
                  <TabContent index={1} value={tabIndex}>
                    <Beneficiaries
                      enablePagination
                      enableSearch={false}
                      entityClassName={classes.entity}
                    />
                  </TabContent>
                  <TabContent index={2} value={tabIndex}>
                    <AwaitingFx
                      enablePagination
                      enableSearch={false}
                      entityClassName={classes.entity}
                    />
                  </TabContent>
                  <TabContent index={3} value={tabIndex}>
                    <BalanceTransfer
                      enablePagination
                      enableSearch={false}
                      entityClassName={classes.entity}
                    />
                  </TabContent>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            container
            direction="column"
            item
            lg={4}
            md={6}
            sm={12}
            spacing={3}
          >
            <Grid item>
              <EntryPointWidget
                emGroupManagerClick={handleOpenViewGroupsDrawer}
              />
            </Grid>
            <Grid item>
              <CurrencyAccountsWidget />
            </Grid>
          </Grid>

        </Grid>
        <EntityGroupDrawer />
      </div>
    </APMainLayout>
  );
};

export default OutstandingActionsDashboard;
