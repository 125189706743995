import React, { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { useActivateUser } from 'hooks/useActivateUser';
import browserHistory from '../../../../services/history/browserHistory';
import routes from '../../../../routes.path';
import WelcomeLoader from '../../../../components/Organisms/Onboarding/WelcomeLoader';

interface IProps {
  name: string,
}

const Welcome: React.FC<IProps> = ({ name }: IProps) => {
  const { userInfo } = useAuth();
  const { activateUser } = useActivateUser();

  const redirectUserToDashboard = () => setInterval(() => {
    browserHistory.push(routes.base);
  }, 4000);

  useEffect(() => {
    activateUser(userInfo);
    const interval = redirectUserToDashboard();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <WelcomeLoader completedOnboarding name={name} />
  );
};

export default Welcome;
