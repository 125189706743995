import { AxiosResponse } from 'axios';
import { DashboardCountsResponse } from '../../models/dashboardCounts';
import instance from '../Axios/multiEntityInstance';

export class DashboardService {
  public static async getDashboardTablesCounts(): Promise<DashboardCountsResponse> {
    return this.getDashboardTablesCountsAsync();
  }

  private static async getDashboardTablesCountsAsync(): Promise<DashboardCountsResponse> {
    const response: AxiosResponse<DashboardCountsResponse> = await instance(
      {
        method: 'get',
        url: 'payments/dashboard-tables/count/search',
      },
    );
    return response.data;
  }
}

export default DashboardService;
