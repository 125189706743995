import { PaymentBatchDraftDto, PaymentBatchDto } from '@alpha/payments-dtos';

export const isPaymentBatchDraftDto = (
  params: PaymentBatchDraftDto | PaymentBatchDto | undefined,
): params is PaymentBatchDraftDto => {
  if ((params as PaymentBatchDraftDto).noOfValidatedPayments >= 0) {
    return true;
  }
  return false;
};

export default isPaymentBatchDraftDto;
