import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import React from 'react';
import clsx from 'clsx';
import { StyledDivider } from 'components/StyledDivider';
import { ClassNameMap } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoNotEnter } from '@fortawesome/pro-solid-svg-icons';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import useApprovedTableStyles from './ApprovedTable.styles';
import {
  CurrencyPair,
  NationalityPair,
} from '../../../../components/International';
import SearchTable from '../../../../components/Table/SearchTable';
import { TextEllipsis } from '../../../../components/TextEllipsis';
import { TSearchBaseResponse, TSearchParams } from '../../../../hooks/useSearch';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../utils/formatIsoDate';
import { setDateTime } from '../../../../utils/setDateTime';
import useStyles from '../Body.styles';

interface IApprovedTable {
  handleTableRowClick: (e: BeneficiaryDto) => void;
  hasNext: boolean;
  hasPrevious: boolean;
  loading: boolean
  handleNextPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handlePreviousPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  approvedTableData: TSearchBaseResponse | undefined;
}

const generateTableData = (
  tableData: TSearchBaseResponse | undefined, styles: ClassNameMap<string>,
) => (
  tableData?.records as BeneficiaryDto[]
)?.map((beneficiary) => ({
  ...beneficiary,
  entity: (
    <div className={styles.entityDiv}>
      {!beneficiary.beneIsPayable && (
      <Tooltip
        placement="bottom-start"
        title={(
          <div>
            <span className={styles.tooltipTitle}>Unable to pay beneficiary</span>
            <StyledDivider className={styles.divider} />
            Please contact Client Services to activate your currency account in order
            to pay this beneficiary
          </div>
      )}
      >
        <span className={styles.faDoNotEnter}>
          <FontAwesomeIcon icon={faDoNotEnter} style={{ color: '#e57757' }} />
        </span>
      </Tooltip>
      )}
      <TextEllipsis text={beneficiary.accountName ? beneficiary.accountName : '-'} />
    </div>
  ),
  name: <TextEllipsis maxChars={30} text={beneficiary.name || '-'} />,
  friendlyName: <div style={{ textTransform: 'capitalize' }}>{beneficiary.friendlyName || '-'}</div>,
  iban: <div>{(beneficiary.iban && beneficiary.iban.toUpperCase()) || '-'}</div>,
  accountNumber: <div>{beneficiary.accountNumber || '-'}</div>,
  swift: <div>{beneficiary.swift || '-'}</div>,

  bankCountryCode:
    (beneficiary.bankCountryCode && (
      <NationalityPair countryCode={beneficiary.bankCountryCode} />
    ))
    || '-',
  currencyCode:
    (beneficiary.currencyCode && (
      <CurrencyPair currencyCode={beneficiary.currencyCode} />
    ))
    || '-',
  createdDate: formatIsoDate(beneficiary.createdDate),
  uploadedDateTime: setDateTime(beneficiary.createdDate),
  approvedDateTime: setDateTime(beneficiary.approvedByDateTime),
}));

const ApprovedTable: React.FC<IApprovedTable> = (props: IApprovedTable) => {
  const {
    approvedTableData,
    hasNext,
    hasPrevious,
    handleTableRowClick,
    handleNextPage,
    handlePreviousPage,
    loading,
  } = props;

  const styles = useStyles();
  const approvedTableStyles = useApprovedTableStyles();

  const columns: ITableColumn[] = [
    { header: 'Entity', accessor: 'entity', className: clsx(styles.entity, 'dd-privacy-allow') },
    { header: 'Account Holder', accessor: 'name' },
    { header: 'Friendly Name', accessor: 'friendlyName' },
    { header: 'Country', accessor: 'bankCountryCode', className: 'dd-privacy-allow' },
    { header: 'Currency', accessor: 'currencyCode', className: 'dd-privacy-allow' },
    { header: 'IBAN', accessor: 'iban' },
    { header: 'Account Number', accessor: 'accountNumber' },
    { header: 'Swift', accessor: 'swift' },
    { header: 'Created Date', accessor: 'createdDate', className: 'dd-privacy-allow' },
  ];

  return (
    <SearchTable
      table={{
        columns,
        data: generateTableData(approvedTableData, approvedTableStyles),
        clickable: true,
        handleTableRowClick,
      }}
      loading={loading}
      pagination={{
        handleNext: handleNextPage as any,
        handlePrevious: handlePreviousPage as any,
        hasNext,
        hasPrevious,
      }}
      emptyTable={{
        title: 'No Approved Beneficiaries',
        subtitle: 'You currently do not have any approved beneficiaries',
      }}
    />
  );
};

export default ApprovedTable;
