import { ManualPaymentRequest } from '@alpha/payments-dtos';
import { useDispatch } from 'react-redux';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useAuthorization from '../../../../../hooks/useAuthorization';
import { TCurrencyAccount } from '../../../../../models/currencyAccounts';
import { TPayment } from '../../../../../models/payments';
import { UserRole } from '../../../../../models/user';
import routes from '../../../../../routes.path';
import browserHistory from '../../../../../services/history/browserHistory';
import PaymentsService from '../../../../../services/Payments/payments.service';
import { actions } from '../../../../../store/payments/payments.reducer';
import { IFooterProps } from './Footer';
import formatIsoDate from '../../../../../utils/formatIsoDate';

type TempManualPaymentRequest = ManualPaymentRequest & {
  beneficiaryFriendlyName?: string,
}

const mapManualPaymentToValidateManualPayment = (payments:
    TPayment, selectedDebitingAccount: TCurrencyAccount): TempManualPaymentRequest => ({
  purposeOfPayment: payments.purposeOfPaymentCode,
  reference: payments.reference,
  fundingAccount: selectedDebitingAccount.id,
  fundingCurrency: selectedDebitingAccount.currencyCode,
  debitingAccount: payments.debitingAccountId || selectedDebitingAccount.id,
  debitingCurrency: payments.debitingAccountCurrencyCode || selectedDebitingAccount.currencyCode!,
  fixedSide: payments.fixedSide,
  amount: payments.paymentAmount,
  paymentDate: formatIsoDate(payments.date),
  beneficiaryName: payments.beneficiary!.name!,
  beneficiaryAddress: payments.beneficiary!.residentAddress!,
  beneficiaryBankCountry: payments.beneficiary!.bankCountryCode!,
  beneficiaryFriendlyName: payments.beneficiary!.friendlyName || undefined,
  beneficiaryType: payments.beneficiary?.type,
  iban: payments.beneficiary?.iban || '',
  accountNumber: payments.beneficiary?.accountNumber,
  swift: payments.beneficiary?.swift || '',
  nationalBankCode: payments.beneficiary?.nationalBankCode || '',
  beneficiaryCountry: payments.beneficiary?.beneficiaryCountryCode,
  correspondentAccount: payments.beneficiary?.correspondentAccountNumber || undefined,
  correspondentSwift: payments.beneficiary?.correspondentSwift || undefined,
  furtherToAccountNumber: payments.beneficiary?.furtherToAccountNumber || undefined,
  furtherToSwift: payments.beneficiary?.furtherToSwift || undefined,
  beneficiaryIsExisting: payments.beneficiary?.isExisting,
  userIgnoresApplyFinancialErrors: payments.beneficiary?.userIgnoresApplyFinancialErrors || false,
  channel: 'portal',
});

const useFooter = ({
  currentPayment,
  singleValidPayment,
  selectedDebitingAccount,
  currentPayments,
  setLoading,
}: IFooterProps) => {
  const dispatch = useDispatch();
  const { authorized } = useAuthorization([[UserRole.PAYMENTS_INPUTTER]]);
  const sb = useAlphaSnackbar();

  const handleExitClick = () => {
    browserHistory.push(routes.payments.base);
  };

  const generateBatchId = async (): Promise<string | void> => {
    try {
      const { batchId } = await PaymentsService.postGenerateBatchId();
      return batchId;
    } catch {
      sb.trigger('There was an error generating your batch id');
    }
  };

  const createPayloadObject = (): TempManualPaymentRequest[] => {
    try {
      const payments = singleValidPayment ? [currentPayment] : [...currentPayments];
      return payments.map((payments: TPayment) => {
        if (!payments.beneficiary || !selectedDebitingAccount) throw Error();
        return mapManualPaymentToValidateManualPayment(
          payments,
          selectedDebitingAccount,
        );
      });
    } catch {
      sb.trigger('There was an error generating your payment payload');
    }

    return [];
  };

  const submitPaymentForValidation = async (
    batchId: string, flattenedPayments: TempManualPaymentRequest[],
  ) => {
    const submitManualPaymentStatus = await PaymentsService.postValidateManualPayment(
      batchId, { payments: [...flattenedPayments] },
    );
    if (submitManualPaymentStatus !== 200) throw Error();
  };

  const handleSubmitPayment = async () => {
    try {
      setLoading(true);
      dispatch(actions.clear());

      const batchId = await generateBatchId();
      if (!batchId) return;
      dispatch(actions.updateBatchId(batchId));

      const flattenedPayments = createPayloadObject();
      await submitPaymentForValidation(batchId, flattenedPayments);
      dispatch(actions.triggerBatchStatus());
    } catch {
      sb.trigger('There was an issue submitting your payment for validation');
      dispatch(actions.clear());
      setLoading(false);
    }
  };

  return {
    authorized,
    handleExitClick,
    handleSubmitPayment,
  };
};

export default useFooter;
