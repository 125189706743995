import { DateTime } from 'luxon';

export const setDateTime = (date: string | undefined) => {
  if (date) {
    return DateTime.fromISO(date, { zone: 'Europe/London' }).toFormat('dd/MM/yyyy HH:mm');
  }
  return 'Date not available';
};

export default setDateTime;
