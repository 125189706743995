import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import React from 'react';
import { EntityGroup } from '../../../../models/entityGroups';
import useEntityGroupDrawer from '../useEntityGroupDrawer';
import EntityGroupInfoEditBody from './EntityGroupInfoEditBody';
import EntityGroupInfoEditHeader from './EntityGroupInfoEditHeader';
import useStyles from '../ViewEntityGroups/ViewEntityGroupsDrawer.styles';
import DrawerBackdropLoader from '../../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';

interface IProps {
  open: boolean;
  entityGroup: EntityGroup;
  canEditOrDeleteGroup: boolean;
}

const EntityGroupInfoEdit: React.FC<IProps> = (props: IProps) => {
  const {
    open,
    entityGroup,
    canEditOrDeleteGroup,
  } = props;

  const {
    toggleAccountsDropdown,
    setSelectedEntityGroup,
    closeAllGroupManagerDrawers,
  } = useEntityGroupDrawer();

  const handleClose = () => {
    closeAllGroupManagerDrawers();
  };

  const handleClickBack = () => {
    setSelectedEntityGroup(undefined);
    toggleAccountsDropdown(false);
  };

  const classes = useStyles();

  const { loading } = useEntityGroupDrawer();
  return (
    <BaseDrawer.Drawer
      className={classes.drawerWrapper}
      data-testid="entity-group-info-drawer"
      open={open}
      onClose={handleClose}
      anchor="right"
    >
      <>
        <EntityGroupInfoEditHeader
          groupId={entityGroup?.id}
          canEditOrDeleteGroup={canEditOrDeleteGroup}
          groupName={entityGroup?.name}
          groupEntityIds={entityGroup?.accounts.map((entity) => entity.id)}
          groupColour={entityGroup?.colour}
          numberOfEntities={entityGroup?.accounts?.length}
          handleClickBack={handleClickBack}
        />
        <EntityGroupInfoEditBody
          entityGroupId={entityGroup?.id}
          accounts={entityGroup?.accounts}
          canEditOrDeleteGroup={canEditOrDeleteGroup}
        />
        <DrawerBackdropLoader width="610px" display={loading || false} text="Loading group..." />
      </>
    </BaseDrawer.Drawer>
  );
};

export default EntityGroupInfoEdit;
