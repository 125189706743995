import React, { memo } from 'react';
import { useStyles } from './CurrencyPair.styles';
import { Flag } from 'components/Flag';

interface IProps {
  currencyCode: string;
  suffix?: string;
  displayCode?: boolean;
}

const CurrencyPair: React.FC<IProps> = (props: IProps) => {
  const { currencyCode, suffix, displayCode = true } = props;
  const classes = useStyles();

  return (
    <span className={classes.root}>
      <Flag size="sm" rounded code={currencyCode} className={classes.tableIcon} />
      <span className={classes.tableCode}>
        {displayCode && currencyCode}
        {suffix && ` ${suffix}`}
      </span>
    </span>
  );
};

export default memo(CurrencyPair);
