import React, { memo } from 'react';
import { Box, Button } from '@mui/material';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import { ActionButton, GhostButton } from 'components/Buttons';
import FooterWrapper from '../../../../../../components/Payments/FooterWrapper/FooterWrapper';
import history from '../../../../../../services/history/browserHistory';
import useStyles from './ApproveRejectFooter.styles';

interface IProps {
  buttonText: string;
  batchName: string;
  modalOpen: boolean;
  handleClose: () => void;
  handleRejectBatchButton: () => void;
  handleApprovePayment: () => Promise<void>;
  handleRejectPayment: () => Promise<void>;
}

const ApproveRejectFooter: React.FC<IProps> = (props: IProps) => {
  const {
    buttonText,
    batchName,
    modalOpen,
    handleClose,
    handleApprovePayment,
    handleRejectBatchButton,
    handleRejectPayment,
  } = props;

  const handleClick = () => {
    history.goBack();
  };

  const styles = useStyles();
  return (
    <>
      <FooterWrapper>
        <Button
          onClick={handleClick}
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          Exit
        </Button>
        <Box>
          <GhostButton
            className={styles.errorButton}
            onClick={handleRejectBatchButton}
            colorVariant="warning"
            data-testid="reject-batch-button"
          >
            Reject Batch
          </GhostButton>
          <ActionButton
            data-testid="approve-batch-button"
            size="large"
            onClick={handleApprovePayment}
          >
            {buttonText}
          </ActionButton>
        </Box>
      </FooterWrapper>
      <DecisionModal
        title={`Reject ${batchName.length > 35 ? ' Batch' : `${batchName}`}`}
        open={modalOpen}
        subtitle="You&apos;re about to reject this batch.
        The payment will not be processed."
        closeButtonText="CANCEL"
        actionButtonText="YES, REJECT BATCH"
        handleActionButton={handleRejectPayment}
        handleClose={handleClose}
        icon={faExclamationTriangle}
      />
    </>
  );
};

export default memo(ApproveRejectFooter);
