import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiFormLabel-root, .MuiTypography-subtitle2': {
        fontSize: '14px',
        color: '#333333',
        marginBottom: '8px',
      },
    },
    selectAccount: {
      marginBottom: '40px',
    },
  }),
  { name: 'InterAccountTransferActionAccounts' },
);

export default useStyles;
