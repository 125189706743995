import { StyledDivider } from 'components/StyledDivider';
import React, { useMemo, useState } from 'react';
import ProfileStatusAlert from 'domain/OutstandingActionsDashboard/ProfileStatusModal/ProfileStatusAlert';
import useBannerRefresh from 'hooks/useBannerRefresh';
import ProfileStatusModal from 'domain/OutstandingActionsDashboard/ProfileStatusModal/ProfileStatusModal';
import {
  TCurrencyAccount,
  TCurrencyAccounts,
} from '../../../../../models/currencyAccounts';
import { TUserAccount } from '../../../../../models/user';
import usePaymentsContext from '../ManualPayment/usePaymentsContext';
import useSinglePaymentContext from '../ManualPayment/useSinglePaymentContext';
import EntityDropdown from './Dropdowns/EntityDropdown';
import FundingAccountDropdown from './Dropdowns/FundingAccountDropdown';
import useStyles from './FundingAccountContainer.styles';
import FundingAccountSummary from './FundingAccountSummary/FundingAccountSummary';

export interface IFundingAccountContainerProps {
  selectedFundingAccount: TCurrencyAccount | undefined;
  selectedEntity?: TUserAccount;
  setSelectedFundingAccount: React.Dispatch<
  React.SetStateAction<TCurrencyAccount | undefined>
>;
}

interface IProps extends IFundingAccountContainerProps {
  currencyAccounts: TCurrencyAccounts;
  inputterEntities: TUserAccount[];
  handleSetSelectedEntity: (event: any)=>void
  currencyAccountsLoading: boolean;
}

const FundingAccountContainer: React.FC<IProps> = ({
  selectedFundingAccount,
  setSelectedFundingAccount,
  currencyAccounts,
  inputterEntities,
  selectedEntity,
  handleSetSelectedEntity,
  currencyAccountsLoading,
}: IProps) => {
  const [clearModalOpen, setClearModelOpen] = useState<boolean>(false);
  const { paymentsContext } = usePaymentsContext();
  const { singlePaymentContext } = useSinglePaymentContext();
  const classes = useStyles();
  const [profileModalOpen, setProfileModalOpen] = useState<boolean>(false);
  const { incomplete } = useBannerRefresh();

  const selectedIncompleteProfile = useMemo(() => (
    incomplete.find((entity) => entity.id === selectedEntity?.id)
  ), [selectedEntity, incomplete]);

  const findCurrencyAccountById = (caId: string) => currencyAccounts
    .find((ca: TCurrencyAccount) => ca.id === caId);

  const handleSetSelectedDebitingAccount = (event: any): void => {
    const newSelectedCurrencyAccountId: string | undefined = event.target?.value;

    if (newSelectedCurrencyAccountId) {
      const newSelectedCurrencyAccount:
        | TCurrencyAccount
        | undefined = findCurrencyAccountById(newSelectedCurrencyAccountId);
      setSelectedFundingAccount(newSelectedCurrencyAccount);
    }
  };

  const handleSubmitButton = () => {
    setClearModelOpen(false);
    singlePaymentContext?.setCurrentPayment({
      reference: '',
      date: '',
      paymentAmount: 0,
    });
    paymentsContext.handleSetCurrentPayments([]);
    setSelectedFundingAccount(undefined);
  };

  const handleChangeDebitingAccount = () => {
    if (paymentsContext.currentPayments.length > 0) {
      setClearModelOpen(true);
    } else {
      setSelectedFundingAccount(undefined);
      singlePaymentContext?.setCurrentPayment({
        reference: '',
        date: '',
        paymentAmount: 0,
      });
    }
  };
  if (selectedEntity && selectedFundingAccount) {
    return (
      <>
        {
          selectedIncompleteProfile && (
            <>
              <ProfileStatusAlert
                setProfileModalOpen={setProfileModalOpen}
                style={{ marginTop: 0, marginBottom: 40 }}
              />
              <ProfileStatusModal
                entities={incomplete}
                modalOpen={profileModalOpen}
                onClose={setProfileModalOpen}
              />
            </>
          )
        }
        <div>
          <FundingAccountSummary
            open={clearModalOpen}
            selectedFundingAccount={selectedFundingAccount}
            selectedEntity={selectedEntity}
            handleModalOpen={setClearModelOpen}
            handleSubmitButton={handleSubmitButton}
            handleChangeFundingAccount={handleChangeDebitingAccount}
          />
          <StyledDivider />
        </div>
      </>
    );
  }

  return (
    <div className={classes.dropdownContainer}>
      <EntityDropdown
        selectedEntity={selectedEntity}
        inputterEntities={inputterEntities}
        handleSetSelectedEntity={handleSetSelectedEntity}
      />
      <FundingAccountDropdown
        dropdownDisabled={!selectedEntity}
        selectedDebitingAccount={selectedFundingAccount}
        handleSetSelectedDebitingAccount={handleSetSelectedDebitingAccount}
        currencyAccounts={currencyAccounts}
        loading={currencyAccountsLoading}
      />
    </div>
  );
};

export default FundingAccountContainer;
