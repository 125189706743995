import { useDispatch } from 'react-redux';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import EntityGroupsService from '../../../../services/EntityGroups/entityGroups.service';
import { actions as entityGroupActions } from '../../../../store/entityGroups/reducer';
import useEntityGroupDrawer from '../useEntityGroupDrawer';

const useEditEntityGroup = () => {
  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();

  const { updateSelectedGroupDetails, requestUpdateEntityGroupDetails } = useEntityGroupDrawer();

  const editGroupName = async (
    entityGroupId: string,
    groupEntityIds: string[],
    updatedGroupName: string,
  ) => {
    let error = false;
    dispatch(entityGroupActions.updateLoadingState({ loading: true }));
    try {
      await EntityGroupsService.updateGroupName(
        entityGroupId,
        groupEntityIds,
        updatedGroupName,
      );
      await updateSelectedGroupDetails(entityGroupId);
      sb.trigger('Group Name Changed', 'success');
    } catch (e) {
      sb.trigger('Could not update group name', 'error');
      error = true;
    } finally {
      dispatch(entityGroupActions.updateLoadingState({ loading: false }));
    }
    return error;
  };

  const deleteEntitiesFromGroup = async (
    entityAccountIds: string[],
    entityGroupId: string,
  ) => {
    dispatch(entityGroupActions.updateLoadingState({ loading: true }));
    try {
      await EntityGroupsService.deleteEntityFromGroup(entityAccountIds, entityGroupId);
      sb.trigger('Entity Removed', 'success');
      requestUpdateEntityGroupDetails();
    } catch (error) {
      sb.trigger('Could not delete entity from group', 'error');
    } finally {
      dispatch(entityGroupActions.updateLoadingState({ loading: false }));
    }
  };

  return {
    editGroupName,
    deleteEntitiesFromGroup,
  };
};

export default useEditEntityGroup;
