import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    referenceDetails: {
      borderTop: '1px solid rgba(112, 112, 112, 0.1)',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    paymentDetailsHeader: {
      marginBottom: 31.5,
      fontWeight: typography.fontWeightSemiBold,
      letterSpacing: -0.5,
    },
    paymentAmountWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 18.5,
      borderTop: '1px solid rgba(112, 112, 112, 0.1)',
      fontWeight: typography.fontWeightBold,
    },
  }), { name: 'PaymentDetails' },
);

export default useStyles;
