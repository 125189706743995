import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import React, { useState, useEffect } from 'react';
import useSwitchAccount from '../../../../hooks/useSwitchAccount';
import Body from './Body/Body';
import Header from './Header/Header';
import useStyles from './SwitchAccountDrawer.styles';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import DrawerBackdropLoader from '../../../Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import { TUserAccounts } from '../../../../models/user';

export interface IAccounts {
  id: string;
  name: string
}

interface IProps {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const SwitchAccountDrawer: React.FC<IProps> = (props: IProps) => {
  const { getUserAccounts } = useSwitchAccount();
  const [accounts, setAccounts] = useState<TUserAccounts>([]);
  const sb = useAlphaSnackbar();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { open, onClose } = props;
  const [filterAccounts, setFilterAccounts] = useState<string>('');
  const handleKeyUp = (e: any): void => {
    setFilterAccounts(e.target.value);
  };

  const getAllAccounts = async (): Promise<void> => {
    try {
      setLoading(true);
      setAccounts(await getUserAccounts());
    } catch {
      sb.trigger('There was an error retrieving your accounts for your user');
    } finally {
      setTimeout(() => setLoading(false), 1000);
    }
  };

  useEffect(() => {
    if (open) getAllAccounts();
  }, [open]);

  const handleOnClose = (): void => {
    onClose(false);
    setFilterAccounts('');
  };

  return (
    <BaseDrawer.Drawer
      className={classes.drawerWrapper}
      open={open}
      onClose={() => handleOnClose()}
      anchor="right"
    >
      <Header handleKeyUp={handleKeyUp} />
      {
        accounts.length > 0
        && (
          <Body
            onClose={onClose}
            setFilterAccounts={setFilterAccounts}
            accounts={accounts}
            filterAccounts={filterAccounts}
          />
        )
      }
      <DrawerBackdropLoader display={loading} text="Retrieving yours accounts for your user" />
    </BaseDrawer.Drawer>
  );
};

export default SwitchAccountDrawer;
