import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    body: {
      '& td': {
        fontFamily: typography.fontFamilySecondary,
      },
      padding: '80px 40px',
    },
    tradeDetails: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    referenceDetails: {
      borderTop: '1px solid rgba(112, 112, 112, 0.1)',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    paymentAmountWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '1px solid rgba(112, 112, 112, 0.1)',
      paddingTop: 18.5,
      fontWeight: typography.fontWeightBold,
    },
    paymentDetailsHeader: {
      marginBottom: 31.5,
    },
    paymentStatusWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      paddingBottom: 11.5,
      marginBottom: 36.5,
    },
    text: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'BodyDetails' },
);

export default useStyles;
