import { useLocation } from 'react-router-dom';
import queryString from 'querystring';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { iAllowedAccounts, iSelectedAccounts } from 'store/accounts/accounts.reducer';
import { DashboardTabs } from '../../Common/helpers';
import DashboardService from '../../../../services/Dashboard/dashboard.service';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import { actions } from '../../../../store/outstandingTables/outstandingTables.reducer';
import { TStore } from '../../../../store';

const useBody = () => {
  const location = useLocation();
  const sb = useAlphaSnackbar();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = React.useState(-1);
  const [tabTitles, setTabTitles] = React.useState<string[]>([]);
  const tableSizeRedux = useSelector<TStore, Record<string, number>>(
    (state) => state.outstandingDashboardTables.tablesSize,
  );
  const selectedAccounts = useSelector<TStore, iSelectedAccounts[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const userAccountsLoading = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.userAccountsLoading,
  );
  const open = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.KYCOpen,
  );

  const calculateIndexFromUrl = () => {
    const parsedQueryString = queryString.decode(
      location.search.replace('?', ''),
    );
    switch (parsedQueryString.tab) {
      case DashboardTabs.PAYMENT_APPROVALS:
        return 0;
      case DashboardTabs.BENE_APPROVALS:
        return 1;
      case DashboardTabs.AWAITING_FX:
        return 2;
      case DashboardTabs.BALANCE_TRANSFER:
        return 3;
      default:
        return 0;
    }
  };

  const calculateUrlFromIndex = (index: number): string => {
    switch (index) {
      case 1:
        return DashboardTabs.BENE_APPROVALS;
      case 2:
        return DashboardTabs.AWAITING_FX;
      case 3:
        return DashboardTabs.BALANCE_TRANSFER;
      default:
        return DashboardTabs.PAYMENT_APPROVALS;
    }
  };

  const getDashboardTablesCount = async () => {
    try {
      const response = await DashboardService.getDashboardTablesCounts();
      const tabsIndexArray = Object.values(response);
      if (tabIndex === -1) {
        calculateFirstTabWithEntry(tabsIndexArray);
      }
      dispatch(
        actions.updateOutstandingTablesSize({
          ...response,
        }),
      );
    } catch (error) {
      sb.trigger('Could not get tables count', 'error');
    }
  };

  const calculateFirstTabWithEntry = (responseArray: Array<number>) => {
    if (responseArray[0] > 0) {
      setTabIndex(0);
    } else if (responseArray[1] > 0) {
      setTabIndex(1);
    } else if (responseArray[1] > 0) {
      setTabIndex(2);
    } else if (responseArray[1] > 0) {
      setTabIndex(3);
    } else {
      setTabIndex(0);
    }
  };

  const setTablesTabsTitles = () => {
    setTabTitles(Object.values(DashboardTabs).map((tabTitle: string) => `${tabTitle} (${tableSizeRedux[tabTitle]})`));
  };

  return {
    location,
    calculateIndexFromUrl,
    calculateUrlFromIndex,
    getDashboardTablesCount,
    tabIndex,
    setTabIndex,
    tabTitles,
    setTablesTabsTitles,
    tableSizeRedux,
    selectedAccounts,
    allowedAccounts,
    userAccountsLoading,
    open,
  };
};

export default useBody;
