import { CurrencyAccountSearchDto } from '@alpha/currency-accounts-dtos';
import { ChangeEvent, useState } from 'react';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import { TSearchParams } from '../../../hooks/useSearch';
import { TCurrencyResponse } from '../../../models/currencyAccounts';
import useCurrencyAccountsSearch from './useCurrencyAccountsSearch';

export type TCaSearchResponse = {
  total: number;
  records: CurrencyAccountSearchDto[]
}

const createSearchParams = (
  _currencyCode: string,
  skip = 0,
  _searchText?: string,

): TSearchParams => {
  const newSearchParams: TSearchParams = {
    baseUrl: '/ca/currency-accounts/search',
    queryParams: {
      skip,
      take: 10,
      sortorder: 'asc',
    },
  };
  if (_currencyCode !== 'ALL') {
    newSearchParams.queryParams.currencyCode = _currencyCode;
    newSearchParams.queryParams.totals = 'true';
  }
  if (_searchText) newSearchParams.queryParams.searchtext = _searchText;
  return newSearchParams;
};

const useCurrencyStats = (currencyCode: string, multiEntity?: boolean) => {
  const [stats, setStats] = useState<TCurrencyResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const caSearch = useCurrencyAccountsSearch(multiEntity);
  const searchParams = createSearchParams(currencyCode,
    caSearch.skip);
  // use elastic search table
  const handleNewSearch = async () => {
    await caSearch.handleNewSearch(searchParams);
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    caSearch.setSearchText(e.target.value);
    const { ...newSearchParams } = searchParams;
    if (e.target.value) {
      newSearchParams.queryParams.searchtext = e.target.value;
    }

    await caSearch.handleNewSearch(newSearchParams);
  };

  return {
    caSearch,
    searchParams,
    handleNewSearch,
    handleInputChange,
    stats,
    loading,
  };
};

export default useCurrencyStats;
