import {
  Box,
  Checkbox,
  FormControlLabel,
  Popover,
} from '@mui/material';
import { Loader } from 'components/Loader';
import { faCaretCircleDown, faCaretCircleUp } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AccountsDropdown from './AccountsDropdown';
import GroupsDropdown from './GroupsDropdown';
import { useStyles } from './index.styles';
import { Footer, Header, SearchField } from './Menu';
import useDashboardDropdown from './useDropdown';

const Dropdown: React.FC = () => {
  const {
    entityGroups,
    groupsLoading,
    menuOptions,
    isAllSelected,
    handleCheckboxClick,
    allowedUserAccounts,
    userAccountsLoading,
    accountMenuOptions,
    handleSelectAllAccountsClick,
    handleSelectGroupCheckbox,
    getAccountsViewMenuOptions,
    getGroupViewMenuOptions,
    handleClickClear,
    submitSelectedOptions,
    placeholderText,
    handleSearchInput,
    selectedOptions,
    menuOpen,
    handleClick,
    handleClose,
  } = useDashboardDropdown();
  const dispatch = useDispatch();
  const classes = useStyles({ textLength: placeholderText.length });

  const [groupView, setGroupedView] = useState(false);

  const titleRef = useRef(null);

  useEffect(() => {
    if (menuOpen) {
      dispatch({ type: 'GET_USER_ACCOUNTS_REQUEST' });
      dispatch({ type: 'UPDATE_ENTITY_GROUPS_REQUEST' });
    }
  }, [menuOpen]);

  useEffect(() => {
    if (!userAccountsLoading && allowedUserAccounts.length > 0 && menuOpen) {
      getAccountsViewMenuOptions();
    }
  }, [userAccountsLoading, allowedUserAccounts, menuOpen]);

  useEffect(() => {
    if (!groupsLoading && entityGroups.length > 0 && menuOpen) {
      getGroupViewMenuOptions(entityGroups);
    }
  }, [groupsLoading, entityGroups, menuOpen]);

  return (
    <div className="dd-privacy-allow">
      <div className={classes.dropdownTitle} ref={titleRef} onClick={handleClick} role="button" tabIndex={0} aria-hidden="true" data-testid="entity-dropdown">
        <h2>{placeholderText}</h2>
        <FontAwesomeIcon size="xs" icon={menuOpen ? faCaretCircleUp : faCaretCircleDown} />
      </div>
      <Popover
        data-testid="entity-dropdown-modal"
        anchorEl={titleRef.current}
        open={menuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={classes.selectorDropdown}>
          <Header
            key="header"
            groupView={groupView}
            setGroupView={setGroupedView}
          />
          <SearchField
            key="searchField"
            handleSearchInput={handleSearchInput}
          />

          {
          userAccountsLoading ? (
            <Loader title="Loading your entities..." testId="entitySelectorLoader" />
          )
            : (
              <>
                <div className={classes.accountMenuOption}>
                  <FormControlLabel
                    label="All Entities"
                    control={(<Checkbox onChange={handleSelectAllAccountsClick} color="primary" checked={isAllSelected} />)}
                  />
                </div>
                <hr className={classes.accountMenuDivider} />
                {
            groupView ? (
              <GroupsDropdown
                selectedOptions={selectedOptions}
                groupMenuOptions={menuOptions}
                handleChange={handleCheckboxClick}
                accountMenuOptions={accountMenuOptions}
                handleClickGroup={handleSelectGroupCheckbox}
              />
            )
              : (
                <AccountsDropdown
                  handleChange={handleCheckboxClick}
                  accountMenuOptions={accountMenuOptions}
                />
              )
          }
              </>
            )
        }
          <Footer
            handleClickClear={handleClickClear}
            submitSelectedOptions={submitSelectedOptions}
            key="Footer"
          />
        </Box>
      </Popover>
    </div>
  );
};

export default Dropdown;
