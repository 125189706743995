import React, { FC, forwardRef, memo } from 'react';
import StyledActionButton from './ActionButton.styles';
import { IBaseButtonProps } from '../BaseButton';

const ActionButton: FC<IBaseButtonProps> = memo(
  forwardRef((props, ref) => (
    <StyledActionButton
      ref={ref}
      {...props}
      color="primary"
      variant="contained"
    />
  )),
);

export default ActionButton;
