import React from 'react';
import Typography from '@mui/material/Typography';

import { ITabPanelProps } from './TabContent.interfaces';

const TabContent = ({
  children, value, index, ...other
}: ITabPanelProps) => (
  <Typography
    component="div"
    // eslint-disable-next-line jsx-a11y/aria-role
    role="tabcontent"
    hidden={value !== index}
    id={`tabcontent-${index}`}
    aria-labelledby={`tabcontent-${index}`}
    data-testid={`tabcontent-${index}`}
    {...other}
  >
    {value === index && children}
  </Typography>
);

export default TabContent;
