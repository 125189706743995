import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    tabContainer: {
      minHeight: 'calc(100vh - 357px)',
      height: '100%',
      position: 'relative',
    },
    exportButton: {
      fontSize: '12px',
      fontWeight: typography.fontWeightSemiBold,
      '& svg': {
        margin: '0 10px 0 0',
      },
    },
  }),
  { name: 'BatchSummary' },
);

export default useStyles;
