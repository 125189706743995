import React, { memo } from 'react';
import { ActionButton, GhostButton } from 'components/Buttons';
import useStyles from './styles';

interface IProps {
    isFormValid: boolean;
    handleDrawerClose: () => void;
}

const Footer: React.FC<IProps> = ({ isFormValid, handleDrawerClose }: IProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <GhostButton
        onClick={handleDrawerClose}
        colorVariant="default"
        className="dd-privacy-allow"
        testId="cancelCreateBeneficiaryButton"
        size="large"
      >
        Cancel
      </GhostButton>
      <ActionButton
        disabled={!isFormValid}
        type="submit"
        size="large"
        className="dd-privacy-allow"
        testId="submitCreateBeneficiaryButton"
      >
        Submit Beneficiary for Approval
      </ActionButton>
    </div>
  );
};

export default memo(Footer);
