/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import { EntityGroup } from '../../../models/entityGroups';
import { TUserAccount } from '../../../models/user';
import { TStore } from '../../../store';
import { actions } from '../../../store/accounts/accounts.reducer';
import { useTextEllipsis } from '../../TextEllipsis';
import {
  AccountsViewDropdownOptions, GroupViewDropdownOptions,
} from '../helpers';

const useDashboardDropdown = () => {
  const [placeholderText, setPlaceholderText] = useState<string>('Viewing All Entities');
  const [menuOptions, setMenuOptions] = useState<GroupViewDropdownOptions[]>([]);
  const [
    accountMenuOptions,
    setAccountMenuOptions,
  ] = useState<AccountsViewDropdownOptions[]>([]);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isAllSelected, setisAllSelected] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();

  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const allowedUserAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const userAccountsLoading = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.userAccountsLoading,
  );
  const selectedRedux = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const entityGroups = useSelector<TStore, EntityGroup[] | undefined>(
    (state: TStore) => state.entityGroups.entityGroupsForUser,
  ) || [];
  const groupsLoading = useSelector<TStore, boolean>(
    (state: TStore) => state?.entityGroups.entityGroupsLoading,
  );

  const submitSelectedOptions = () => {
    const selectedAccounts = accountMenuOptions
      .filter((account) => account.selected)
      .map((account) => ({ id: account.id, name: account.name }));
    if (selectedAccounts.length === 0) {
      sb.trigger('Must select at least one entity');
    } else {
      dispatch(actions.updateSelectedAccounts(selectedAccounts));
      handleClose();
    }
  };

  useEffect(() => {
    setisAllSelected(allowedUserAccounts.length === selectedOptions.length);
  }, [selectedOptions]);

  const entityName = useTextEllipsis({ text: selectedRedux[0]?.name || '', maxChars: 32 });

  useEffect(() => {
    const getPlaceholderText = (): string => {
      if (selectedRedux.length === allowedUserAccounts.length || selectedRedux.length === 0) {
        return 'Viewing All Entities';
      }
      if (selectedRedux.length === 1) {
        return `Viewing ${entityName}`;
      }
      return 'Viewing Multiple Entities';
    };
    setPlaceholderText(getPlaceholderText());
  }, [selectedRedux, entityName]);

  useEffect(() => {
    if (selectedRedux.length === 0) {
      updateSelectedOptions(allowedUserAccounts);
    } else {
      updateSelectedOptions(selectedRedux);
    }
  }, [allowedUserAccounts, selectedRedux, menuOpen]);

  const getAccountsViewMenuOptions = () => {
    if (selectedRedux.length === 0) {
      setAccountMenuOptions(allowedUserAccounts.map((account) => ({
        id: account.id,
        name: account.name,
        selected: true,
        frozen: Boolean(account.accountFrozen),
      })));
    } else {
      setAccountMenuOptions(allowedUserAccounts.map((account) => {
        const selected = selectedRedux
          .find((selectedAccount) => account.id === selectedAccount.id);
        return {
          id: account.id,
          name: account.name,
          selected: Boolean(selected),
          frozen: Boolean(account.accountFrozen),
        };
      }));
    }
  };

  const getGroupViewMenuOptions = (
    entitiesGroupedByGroups: EntityGroup[],
  ) => {
    const options = entitiesGroupedByGroups.map((group: EntityGroup) => {
      const entities = group.accounts.map((entity: TUserAccount) => ({
        id: entity.id,
        name: entity.name,
        selected: false,
      }));
      return {
        entities,
        id: group.id,
        selected: false,
        groupName: group.name,
        colour: group.colour,
      };
    });
    setMenuOptions(options);
  };

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>, accountId: string) => {
    const { checked } = event.target;
    const updatedSelection = accountMenuOptions
      .map((accountOption) => (accountOption.id === accountId
        ? { ...accountOption, selected: checked }
        : accountOption));
    setAccountMenuOptions(updatedSelection);

    const selected = updatedSelection
      .filter((account) => account.selected).map((account) => ({ id: account.id }));
    updateSelectedOptions(selected);
  };

  const handleSelectAllAccountsClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const updatedSelection = accountMenuOptions
      .map((accountOption) => ({ ...accountOption, selected: checked }));
    setAccountMenuOptions(updatedSelection);
    updateSelectedOptions(checked ? allowedUserAccounts : []);
  };

  const handleSelectGroupCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>, group: GroupViewDropdownOptions,
  ) => {
    const accountIds = group.entities.map((account) => account.id);
    const { checked } = event.target;
    const updatedSelection = accountMenuOptions
      .map((accountOption) => (accountIds.includes(accountOption.id)
        ? { ...accountOption, selected: checked }
        : accountOption));
    setAccountMenuOptions(updatedSelection);

    if (checked) {
      const newSelectedOptions = selectedOptions
        .concat(accountIds.filter((id) => selectedOptions.indexOf(id) < 0));
      setSelectedOptions(newSelectedOptions);
    } else {
      setSelectedOptions(selectedOptions.filter((id) => !accountIds.includes(id)));
    }
  };

  const handleClickClear = () => {
    setAccountMenuOptions(accountMenuOptions.map((option) => ({
      ...option,
      selected: false,
    })));
    setSelectedOptions([]);
  };

  const handleSearchInput = ((e: React.ChangeEvent<HTMLInputElement>) => {
    const options: AccountsViewDropdownOptions[] = allowedUserAccounts
      .map((account) => (selectedOptions.includes(account.id)
        ? {
          id: account.id,
          name: account.name,
          selected: true,
          frozen: Boolean(account.accountFrozen),
        } : {
          id: account.id,
          name: account.name,
          selected: false,
          frozen: Boolean(account.accountFrozen),
        }));
    if (e.target.value) {
      const filteredAccounts = options
        .filter((account) => account.name.toLowerCase().includes(e.target.value.toLowerCase()));
      setAccountMenuOptions(filteredAccounts);
    } else {
      setAccountMenuOptions(options);
    }
  });

  const updateSelectedOptions = (accounts: Record<string, string>[]) => {
    setSelectedOptions(accounts.map((account) => account.id));
  };

  return {
    menuOptions,
    isAllSelected,
    setPlaceholderText,
    handleCheckboxClick,
    menuOpen,
    handleClick,
    handleClose,
    allowedUserAccounts,
    userAccountsLoading,
    accountMenuOptions,
    handleSelectAllAccountsClick,
    handleSelectGroupCheckbox,
    getAccountsViewMenuOptions,
    entityGroups,
    groupsLoading,
    getGroupViewMenuOptions,
    handleClickClear,
    submitSelectedOptions,
    placeholderText,
    handleSearchInput,
    selectedOptions,
  };
};

export default useDashboardDropdown;
