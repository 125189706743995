import { Loader } from 'components/Loader';
import { Typography } from '@mui/material';
import React from 'react';
import DrawerBackdrop from '../../../Drawer/DrawerBackdrop/DrawerBackdrop';

interface IProps {
  display: boolean;
  text: string;
  width?: string
  className?: string
}
const DrawerBackdropLoader: React.FC<IProps> = (props: IProps) => {
  const {
    display, text, width, className,
  } = props;
  return (
    <DrawerBackdrop display={display} width={width} className={className}>
      <Loader testId="loader" />
      <Typography variant="subtitle1">{ text }</Typography>
    </DrawerBackdrop>
  );
};

export default DrawerBackdropLoader;
