import React, { memo } from 'react';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaymentBatchDraftStatus } from '@alpha/payments-dtos';
import { StyledDivider } from 'components/StyledDivider';
import Status from 'components/Status/Status';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useStyles } from './Title.styles';
import { getClientFriendlyBatchStatus, getBatchStatusColorVariant } from '../../utils/batchStatus.helpers';

interface IProps {
  title: string;
  batchFileName: string;
  check?: boolean;
  batchStatus?: PaymentBatchDraftStatus | string;
}

const Title: React.FC<IProps> = (props: IProps) => {
  const {
    title, batchFileName, check = false, batchStatus,
  } = props;
  const classes = useStyles();

  return (
    <span className={classes.wrapper}>
      {check && <FontAwesomeIcon icon={faCheckCircle as IconDefinition} data-testid="check-circle" />}
      <span className="bold">
        {title}
      </span>
      {' '}
      -
      {' '}
      {batchFileName}
      {batchStatus
      && <StyledDivider vertical />}
      {batchStatus
      && (
      <Status
        variant={getBatchStatusColorVariant(batchStatus)}
      >
        {getClientFriendlyBatchStatus(batchStatus)}
      </Status>
      )}
    </span>
  );
};

Title.defaultProps = {
  check: false,
  batchStatus: undefined,
};

export default memo(Title);
