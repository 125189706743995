import { PaymentBatchDraftStatus } from '@alpha/payments-dtos';
import { Box } from '@mui/material';
import { TStatusVariants } from 'components/Status';
import Status from 'components/Status/Status';
import React from 'react';
import useStyles from '../../PendingTable.styles';

interface IProps {
  requiresFx: boolean;
  batchStatus: string;
}

const ApprovalStatus: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    batchStatus, requiresFx,
  } = props;
  const classes = useStyles();

  const handleApprovalStatus = (): string => {
    if (batchStatus === PaymentBatchDraftStatus.APPROVED && requiresFx) {
      return 'pending fx';
    }
    return 'pending approval';
  };

  const handleVariant = (): TStatusVariants => {
    if (batchStatus === PaymentBatchDraftStatus.APPROVED && requiresFx) {
      return 'orange';
    }
    return 'info';
  };

  return (
    <Box data-dd-privacy="allow" className={classes.approval}>
      <Status variant={handleVariant()}>
        {handleApprovalStatus()}
      </Status>
    </Box>
  );
};

export default ApprovalStatus;
