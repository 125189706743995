import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import { Box } from '@mui/material';
import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { OutlinedButton } from 'components/Buttons';
import DrawerBackdropLoader from '../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import { TTransactionData } from '../../../models/transactions';
import Body from './Body/Body';
import Header from './Header/Header';
import useStyles from './TransactionsInfoDrawer.styles';

interface ITransactionInfoDrawer {
  transaction?: TTransactionData;
  paymentId?: string;
  loading?: boolean;
  displayFinishButton?: boolean;
  onClose: (event: any) => void;
}

const TransactionInfoDrawer: React.FC<ITransactionInfoDrawer> = (props: ITransactionInfoDrawer) => {
  const {
    onClose, transaction, displayFinishButton, paymentId, loading,
  } = props;
  const classes = useStyles();
  const handleClose = (event: any) => {
    datadogRum.addAction('FINISH BALANCE TRANSFER');
    onClose(event);
  };
  return (
    <BaseDrawer.Drawer
      className={classes.drawer}
      open={loading || Boolean(transaction)}
      onClose={onClose}
      anchor="right"
    >
      {
        transaction && (
          <>
            <Header transaction={transaction} />
            <Body paymentId={paymentId} transaction={transaction} />
          </>
        )
      }
      {displayFinishButton
        && (
          <Box className={classes.finishButton}>
            <OutlinedButton
              fullWidth
              onClick={handleClose}
              testId="cancelButton"
              size="large"
            >
              Finish
            </OutlinedButton>
          </Box>
        )}
      <DrawerBackdropLoader display={Boolean(loading)} text="Retrieving your transaction data" />
    </BaseDrawer.Drawer>
  );
};

export default TransactionInfoDrawer;
