import { Typography } from '@mui/material';
import React from 'react';
import Dropdown from '../../../../../../../../components/InterAccountTransfer/Dropdown/Dropdown';
import {
  TCurrencyAccounts,
} from '../../../../../../../../models/currencyAccounts';
import { TPayment } from '../../../../../../../../models/payments';

interface IProps {
  setCurrentPayment: React.Dispatch<React.SetStateAction<TPayment>>;
  handleInputChange: (event: any) => void;
  currentPayment: TPayment;
  currencyAccounts: TCurrencyAccounts;
}

const PaymentFx: React.FC<IProps> = ({
  currentPayment,
  currencyAccounts,
  setCurrentPayment,
}: IProps) => {
  const dropdownOptions = (): TCurrencyAccounts => currencyAccounts.filter((currencyAccount) => (
    currencyAccount.currencyCode
    === currentPayment.beneficiary?.currencyCode
  ));

  const handleSetSelectedDebitingAccount = (event: any): void => {
    const currencyCode = currencyAccounts.find((ca) => ca.id === event.target.value);
    if (currencyCode) {
      setCurrentPayment({
        ...currentPayment,
        debitingAccountId: currencyCode.id,
        debitingAccountCurrencyCode: currencyCode.currencyCode,
      });
    }
  };

  return (
    <div>
      <Typography variant="subtitle1" className="label">
        Debiting Account
      </Typography>
      <Dropdown
        currencyAccounts={dropdownOptions()}
        handleBlur={() => { }}
        handleChange={handleSetSelectedDebitingAccount}
        helperText=""
        placeholderText="Please select debiting account"
        data-testid="debiting-account"
        selectName="debiting-account"
        selectValue={
          currentPayment.debitingAccountId || 'placeholder'
        }
      />
    </div>
  );
};

export default PaymentFx;
