import React from 'react';
import {
  Modal as MuiModal, Fade, Box, styled,
} from '@mui/material';
import { IModalProps } from './Modal.interfaces';

const StyledBox = styled(Box)({
  backgroundColor: '#fff',
  boxShadow: '0 3px 6px #00000029',
  padding: '24px 50px',
  maxWidth: 620,
  border: 'none',
  borderRadius: 7,
  outline: 'none',
  position: 'relative',
});

const StyledModal = styled(MuiModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledSeparator = styled('hr')({
  position: 'relative',
  left: -50,
  width: 'calc(100% + 100px)',
  margin: '20px 0px',
  opacity: '25%',
});

const Modal = ({
  open,
  onClose,
  header,
  separator,
  children,
  footer,
  ...rest
}: IModalProps) => (
  <StyledModal
    {...rest}
    open={open}
    onClose={onClose}
  >
    <Fade in={open}>
      <StyledBox>
        {header}
        {separator && <StyledSeparator />}
        {children}
        {footer}
      </StyledBox>
    </Fade>
  </StyledModal>
);

export default Modal;
