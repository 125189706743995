import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    container: {
      paddingTop: '25px',
      paddingBottom: '30px',
    },
    pending: {
      paddingBottom: '16px',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      '& > h6': {
        fontWeight: 600,
      },
    },
  }),
  { name: 'AccountsTable' },
);

export default useStyles;
