import { Box, Typography } from '@mui/material';
import React from 'react';
import { Flag } from 'components/Flag';
import useStyles from './AccountInformation.styles';

interface IAccount {
  type?: string;
  iban?: string;
  reference?: string;
  friendlyName?: string;
  header: string;
  currencyCode?: string;
}

const AccountInformation: React.FC<IAccount> = (props: IAccount) => {
  const {
    type, iban, friendlyName, reference, header, currencyCode,
  } = props;
  const classes = useStyles();
  const displayInformation = () => {
    if (type) {
      if (type === 'Wallet' && reference) return reference;
      if (iban) return iban;
    }
    return '';
  };

  return (
    <Box className={classes.details} data-testid="transactions-drawer-account-info">
      <Typography className={classes.header}>{`${header} Account`}</Typography>
      <Flag code={currencyCode || 'ALL'} size={20} />
      <Typography className={classes.friendlyName}>
        {friendlyName || '-'}
        <span className={classes.information}>{friendlyName ? `- ${displayInformation()}` : ''}</span>
      </Typography>
    </Box>
  );
};

export default AccountInformation;
