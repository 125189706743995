import { User } from '@alpha/profile-dtos';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TAuthyStatus, TUser, TUserAccounts, TUserRoute,
} from '../../models/user';

export type TUserStore = {
  profileDetails?: User,
  user?: TUser,
  accounts?: TUserAccounts,
  routes: TUserRoute[]
  authyStatus?: TAuthyStatus,
  key: number,
};

const initialState: TUserStore = {
  routes: [],
  key: Math.random(),
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserDetails: (state, action: PayloadAction<TUser>): TUserStore => ({
      ...state,
      user: action.payload,
    }),
    updateKey: (state): TUserStore => ({
      ...state,
      key: Math.random(),
    }),
    updateProfileDetails: (state, action: PayloadAction<User>): TUserStore => ({
      ...state,
      profileDetails: action.payload,
    }),
    updateMfaStatus: (state, action: PayloadAction<TAuthyStatus>): TUserStore => ({
      ...state,
      authyStatus: action.payload,
    }),
    clearUserDetails: () => initialState,
  },
});

export const { actions } = userSlice;
