import _default, { makeStyles } from 'theme';

const {
  palette,
} = _default;

const useStyles = makeStyles(
  () => ({
    row: {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    icon: {
      marginLeft: 8,
      fontSize: 16,
      color: palette.primary.light,
    },
  }),
  { name: 'ErrorRow' },
);

export default useStyles;
