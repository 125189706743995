import React from 'react';
import { GetAllUsersByStatusResponseDataItem } from '@portal/user-types';
import { faEye, faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Typography } from '@mui/material';
import ActionDropDown from '../../../../components/ActionDropDown';
import saveCurrentPageRoute from '../../../../../../utils/saveCurrentPageRoute';
import routes from '../../../../../../routes.path';
import history from '../../../../../../services/history/browserHistory';
import { useUserManagementContext } from '../../../../context';
import { ModalTypeEnum, UserTables } from '../../../../context/index.interfaces';

interface IProps {
  user: GetAllUsersByStatusResponseDataItem;
  setRefreshDataTable: React.Dispatch<React.SetStateAction<UserTables | null>>
}

const ApprovedUsersDropDown: React.FC<IProps> = (props: IProps) => {
  const { user, setRefreshDataTable } = props;
  const { openModal } = useUserManagementContext();

  const handleRedirectToUser = () => {
    saveCurrentPageRoute();
    history.push(
      `${routes.userManagement.userRoute(user.id)}`,
    );
  };

  const submitCallback = async (callback: () => Promise<any>) => {
    await callback();
    setRefreshDataTable(UserTables.PENDING_APPROVAL);
  };

  const approvedTableDropdownItems = [{
    id: 'action-permission-view',
    content: (
      <Typography>
        View User
      </Typography>
    ),
    icon: faEye,
    onClick: () => { handleRedirectToUser(); },
    underline: user.isManageable,
  }];

  if (user.isManageable) {
    approvedTableDropdownItems.push({
      id: 'action-permission-edit',
      content: (
        <Typography>
          Edit permissions
        </Typography>
      ),
      icon: faPencil,
      onClick: () => openModal({ user, submitCallback, modalType: ModalTypeEnum?.ENTITY_ACCESS }),
      underline: true,
    });
  }

  return (
    <ActionDropDown menuItems={approvedTableDropdownItems} />
  );
};

export default ApprovedUsersDropDown;
