import { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { originalUseSnackbar } from 'components/Snackbar';
import { TStore } from '../../store';
import { TNotification, actions } from '../../store/notifications/notifications.reducer';

type TKey = string | number;

let displayed: TKey[] = [];

export const Notifier: FC = memo(() => {
  const dispatch = useDispatch();
  const notifications = useSelector<TStore, TNotification[]>((store) => store.notifications.data);
  const { enqueueSnackbar, closeSnackbar } = originalUseSnackbar();

  const storeDisplayed = (id: TKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: TKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };
  useEffect(() => {
    // @ts-ignore
    notifications.forEach(({
      key, message, ...options
    }) => {
      // do nothing if snackbar is already displayed
      if (key && displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        className: 'dd-privacy-allow',
        autoHideDuration: 4000,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(actions.removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      if (key) {
        storeDisplayed(key);
      }
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
});

export default Notifier;
