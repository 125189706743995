import { Box, Typography } from '@mui/material';
import { Input } from 'components/Inputs/Input';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import React from 'react';
import { ActionButton, IconButton } from 'components/Buttons';
import { TUserAccount } from '../../../../models/user';
import AddAccountDropdownCard from './AddAccountDropdownCard';
import useStyles from './CreateEntityGroup.style';
import { CreateEntityGroupRequest } from './CreateGroupForm';

interface IProps {
    handleClickBack: React.Dispatch<React.SetStateAction<void>>;
    form: FormikProps<CreateEntityGroupRequest>,
    userAccounts: TUserAccount[];
    selectedAccounts?: TUserAccount[];
    addAccountToGroup: (account:TUserAccount) => void;
    handleSubmitForm: () => void;
}

const CreateEntityGroupHeader: React.FC<IProps> = (props: IProps) => {
  const {
    handleClickBack,
    form,
    handleSubmitForm,
    userAccounts,
    selectedAccounts,
    addAccountToGroup,
  } = props;

  const clickBack = () => handleClickBack();
  const clickCreateGroup = () => handleSubmitForm();
  const createGroupButtonDisabled = !form.isValid;
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.headerWrapper}>
        <div className={classes.entityGroupNameInput}>
          <IconButton
            data-testid="go-back-button"
            onClick={clickBack}
            className={classes.drawerGoBackButton}
            icon={faArrowLeft}
          />
          <FontAwesomeIcon className={classes.groupColourIcon} icon={faSquare} />
          <Input
            InputProps={{ className: classes.entityGroupNameInput }}
            id="entityGroupName"
            testId="input-entity-group-name"
            fullWidth
            variant="standard"
            onChange={form.handleChange}
            value={form.values.entityGroupName}
            placeholder="Enter group name"
            error={Boolean(form.errors.entityGroupName)}
            helperText={form.errors.entityGroupName}
            disableUnderline
          />
        </div>
        <div>
          <Box className={classes.headerSubtitle}>
            <Typography variant="subtitle1">Entities</Typography>
          </Box>
        </div>
        <div className={classes.headerSubTitle2}>
          <Typography>{selectedAccounts?.length || 0}</Typography>
          <ActionButton
            disabled={createGroupButtonDisabled}
            onClick={clickCreateGroup}
            rounded
            testId="create-group-button"
          >
            Create Group
          </ActionButton>
        </div>
        <div>
          <AddAccountDropdownCard
            addAccountToGroup={addAccountToGroup}
            selectedAccounts={selectedAccounts || []}
            accounts={userAccounts}
          />
        </div>
      </Box>
    </div>
  );
};
export default CreateEntityGroupHeader;
