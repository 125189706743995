import { FormControlLabel } from '@mui/material';
import styled from '@mui/styled-engine';
import { IFormControlLabelProps } from './Checkbox.interfaces';

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<IFormControlLabelProps>(({ fullWidth }) => (
  fullWidth && {
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
  }));

export default StyledFormControlLabel;
