import React from 'react';
import { TCurrencyAccount } from '../../../../../../models/currencyAccounts';
import ManualPayment from '../ManualPayment/ManualPayment';
import PaymentsList from './PaymentsList/PaymentsList';

export interface IBatchPaymentProps {
  selectedDebitingAccount: TCurrencyAccount | undefined;
}
const BatchPayment: React.FC<IBatchPaymentProps> = (
  { selectedDebitingAccount }: IBatchPaymentProps,
) => (
  <>
    <ManualPayment selectedDebitingAccount={selectedDebitingAccount} />
    <PaymentsList />
  </>
);

export default BatchPayment;
