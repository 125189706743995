import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import environmentVars from 'env.variables';
import { Redirect } from 'react-router-dom';
import routes from 'routes.path';
import { authedReqOpts } from '../api';

const refreshToken = async (retryCount: number, setRedirect: Dispatch<SetStateAction<boolean>>) => {
  let userId = '';
  try {
    const formIoUser = JSON.parse(
      localStorage.getItem('formioUser') || JSON.stringify({}),
    );

    if (!formIoUser.data) {
      throw new Error('No session');
    }

    userId = formIoUser._id;

    // nb FORMIO_FORM_URL is a stage URL, not a form.
    const authUrl = new URL(environmentVars.FORMIO_FORM_URL);
    authUrl.pathname += `user/submission/${userId}`;
    const { headers } = await axios.get(authUrl.toString(), authedReqOpts());

    localStorage.setItem('formioToken', headers['x-jwt-token']);
  } catch (_error) {
    if (retryCount < 3 && !!userId) {
      await refreshToken(retryCount + 1, setRedirect);
      return;
    }

    localStorage.removeItem('formioToken');
    localStorage.removeItem('formioUser');

    setRedirect(true);
  }
};

export default function TokenRefresher() {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    const oneMinute = 60 * 1000;
    const tokenPing = setInterval(() => refreshToken(0, setRedirect), oneMinute);

    refreshToken(0, setRedirect);
    return (() => {
      clearInterval(tokenPing);
    });
  }, []);

  return (
    <>
      {
        redirect
        && <Redirect to={{ pathname: routes.onboardingForm.auth }} />
      }
    </>
  );
}
