export interface ICountryMapping {
  [countryCode: string]: { seperatorInsertionIndex: number, separator: string}
}

export const countryMapping: ICountryMapping = {
  GB: { seperatorInsertionIndex: 2, separator: '-' },
  AU: { seperatorInsertionIndex: 3, separator: '-' },
  ZA: { seperatorInsertionIndex: 3, separator: ' ' },
  CA: { seperatorInsertionIndex: 5, separator: ' ' },
};

const insertSeperator = (bankCode: string, index: number, seperator: string) => {
  const newBankCode = bankCode.split('');
  let seperatorsAdded = 0;
  let i = index;
  while (seperatorsAdded < 2 && i < bankCode.length) {
    newBankCode.splice(i, 0, seperator);
    seperatorsAdded += 1;
    i += (index + 1);
  }

  return newBankCode.join('');
};

export const formatBankCode = (bankCode: string, countryCode: keyof ICountryMapping): string => {
  if (countryCode in countryMapping) {
    return insertSeperator(bankCode, countryMapping[countryCode].seperatorInsertionIndex,
      countryMapping[countryCode].separator);
  }
  return bankCode;
};
export default formatBankCode;
