import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { iAllowedAccounts } from 'store/accounts/accounts.reducer';
import { AccountStatus, IncompleteProfileReason } from 'services/Authorization/constants';
import useSwitchAccount from './useSwitchAccount';
import useGetFeatureFlags from './useGetFeatureFlags';

const useBannerRefresh = () => {
  const [overdue, setOverDue] = useState<iAllowedAccounts[]>([]);
  const [due, setDue] = useState<iAllowedAccounts[]>([]);
  const [frozen, setFrozen] = useState<iAllowedAccounts[]>([]);
  const [incomplete, setIncomplete] = useState<iAllowedAccounts[]>([]);
  const [variant, setVariant] = useState<any>('');
  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const { getUserAccounts, userAccounts } = useSwitchAccount();
  const { flags } = useGetFeatureFlags();
  const incompleteProfileFFEnabled = useMemo(() => (
    flags?.find((flag) => flag.name === 'incompleteProfile' && flag.isActive)
  ), [flags]);

  useEffect(() => {
    if (!userAccounts) {
      getUserAccounts();
    }
  }, []);

  useEffect(() => {
    if (allowedAccounts.length) {
      const frozenAccounts = allowedAccounts.filter((acc) => acc.accountFrozen);
      const reviewOverdueAccounts = allowedAccounts.filter(
        (acc) => !acc.accountFrozen && acc.reviewOverDue,
      );
      const reviewDueAccounts = allowedAccounts.filter(
        (acc) => !acc.accountFrozen && !acc.reviewOverDue && acc.reviewDue,
      );
      const incompleteAccounts = userAccounts?.filter(
        (acc) => acc.status === AccountStatus.INCOMPLETE
          && acc.incompleteProfileReason === IncompleteProfileReason.OUTSTANDING_PAYMENT_PROFILE,
      ) || [];

      if (frozenAccounts.length) {
        setFrozen(frozenAccounts);
      }
      if (reviewOverdueAccounts.length) {
        setOverDue(reviewOverdueAccounts);
      }
      if (reviewDueAccounts.length) {
        setDue(reviewDueAccounts);
      }
      if (incompleteProfileFFEnabled && incompleteAccounts.length) {
        setIncomplete(
          incompleteAccounts
            .map((acc) => ({
              id: acc.id,
              name: acc.name,
              kycReviewDate: acc.kycRefreshDate || '',
              reviewDue: acc.reviewDue,
              reviewOverDue: acc.reviewOverDue,
              accountFrozen: acc.accountFrozen,
            })),
        );
      }
    }
  }, [allowedAccounts, userAccounts, flags, incompleteProfileFFEnabled]);

  const getBannerVariant = () => {
    if (frozen.length > 0) {
      setVariant('error');
    } else if (overdue.length > 0) {
      setVariant('warning');
    } else if (due.length > 0) {
      setVariant('info');
    }
  };

  return {
    getBannerVariant,
    frozen,
    overdue,
    due,
    variant,
    incomplete,
    userAccounts,
  };
};

export default useBannerRefresh;
