import { APMainLayout } from 'components/Layout/MainLayout';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import Dropdown from '../../components/Dashboard/Dropdown';
import BackdropLoader from '../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import routes from '../../routes.path';
import TransactionInfoDrawer from './TransactionsInfoDrawer/TransactionsInfoDrawer';
import { TransactionContext } from './TransactionsContext';
import TransactionsCtas from './TransactionsCtas/TransactionsCtas';
import TransactionsSelect from './TransactionsSelect/TransactionsSelect';
import TransactionsSummary from './TransactionsSummary/TransactionsSummary';
import TransactionsTable from './TransactionsTable/TransactionsTable';
import useTransactionsTable from './TransactionsTable/useTransactionsTable';
import useTransactions from './useTransactions';
import { actions } from '../../store/transactions/transactionsPage.reducer';

export const componentTestIds = {
  TransactionsCtas: 'transactions-ctas-component',
  TransactionsSummary: 'transactions-summary-component',
  TransactionsTable: 'transactions-table-component',
};

export enum DateFields {
  START = 'startDate',
  END = 'endDate',
}

export const TransactionsMultiEntity: React.FC = () => {
  const breadcrumbs = [{ text: 'Home', url: routes.home }];
  const isMounted = useRef(false);
  const {
    loading,
    transaction,
    setTransaction,
    handleSetTransaction,
    selectedDates,
    setSelectedDates,
    transactionContext,
    currencyAccountIdParam,
    setCurrencyAccountIdParam,
    userAccountsLoading,
    disabled,
  } = useTransactions();

  const {
    selectedAccounts,
    allowedAccounts,
    currencyAccountIds,
    setCurrencyAccountIds,
    currencyAccountsOptions,
  } = transactionContext;

  const startDateIso = useMemo(() => selectedDates.startDate.format('yyyy-MM-DD'), [selectedDates.startDate]);
  const endDateIso = useMemo(() => selectedDates.endDate.format('yyyy-MM-DD'), [selectedDates.endDate]);

  const [applySearch, setApplySearch] = useState<boolean>(false);

  const dispatch = useDispatch();

  const {
    searchParams,
    tableSearch,
    handleInputChange,
    mapResponseData,
    handleNewSearchMultiEntity,
  } = useTransactionsTable(startDateIso, endDateIso);

  const handleSearchTransactions = () => {
    const currencyAccounts: string[] = currencyAccountIdParam
      ? [currencyAccountIdParam] : [];
    tableSearch.handleInitialSearch(
      {
        ...searchParams,
        body: {
          ...searchParams.body,
          currencyAccountIds: currencyAccounts,
        },
      }, true,
    );
    dispatch(actions.updateSelectedCurrencyAccounts(currencyAccounts));
    setCurrencyAccountIds(currencyAccounts);
    setCurrencyAccountIdParam(undefined);
  };

  // fetch data on mount & when entities change
  // reset the selected currency accounts since it's a new selection of entities
  useEffect(() => {
    if (!userAccountsLoading) {
      handleSearchTransactions();
    }
  }, [selectedAccounts]);

  // fetch new data when dates change only disable the call on mount
  useEffect(() => {
    if (isMounted.current) {
      handleNewSearchMultiEntity();
    } else {
      isMounted.current = true;
    }
  }, [startDateIso, endDateIso]);

  return (
    <TransactionContext.Provider value={transactionContext}>
      <APMainLayout
        title={(
          <div style={{ display: 'flex' }}>
            <span className="dd-privacy-allow">
              {' '}
              Transactions
              {' '}
              |
              {' '}
            </span>
            <Dropdown />
          </div>

      )}
        breadCrumbs={breadcrumbs}
        pageTabs={(
          <TransactionsSelect
            disabled={disabled}
            setApplySearch={setApplySearch}
          />
      )}
        rightAlignedCta={(
          <TransactionsCtas
            componentTestId={componentTestIds.TransactionsCtas}
            setSelectedDates={setSelectedDates}
            selectedDates={selectedDates}
            startDateIso={startDateIso}
            endDateIso={endDateIso}
            currencyAccounts={currencyAccountIds || []}
            testId="transactions-ctas-component"
          />
      )}
      >
        {currencyAccountsOptions
          ? (
            <TransactionsSummary
              componentTestId={componentTestIds.TransactionsSummary}
              startDate={startDateIso}
              endDate={endDateIso}
              currencyAccountIds={currencyAccountIds}
              testId="transactions-summary-component"
            />
          ) : <BackdropLoader testId="loader-transactions" />}

        {(selectedAccounts.length > 0 || allowedAccounts.length > 0)
          ? (
            <TransactionsTable
              key={`${startDateIso}-${endDateIso}`}
              componentTestId={componentTestIds.TransactionsTable}
              handleSetTransaction={handleSetTransaction}
              applySearch={applySearch}
              setApplySearch={setApplySearch}
              testId="transactions-table-component"
              handleInputChange={handleInputChange}
              mapResponseData={mapResponseData}
              searchParams={searchParams}
              tableSearch={tableSearch}
            />
          ) : <BackdropLoader testId="loader-transactions" />}

        <TransactionInfoDrawer
          loading={loading}
          transaction={transaction}
          paymentId={transaction && transaction.paymentId}
          onClose={() => {
            setTransaction(undefined);
          }}
        />
      </APMainLayout>
    </TransactionContext.Provider>
  );
};
