import { PaymentBatchSummaryDto } from "@alpha/payments-dtos";
import { PaymentUploadType } from "@alpha/payments-dtos/lib/batch/PaymentUploadType";
import formatIsoDate from "../../../../utils/formatIsoDate";


const getApprovedDate = (item: PaymentBatchSummaryDto | undefined): string => {

    if(item?.approvals?.length){
        return formatIsoDate(item.approvals[item.approvals?.length - 1].date);
    }

    if(item?.approvedDate){
        return formatIsoDate(item.approvedDate);
    }

    return 'N/A';
}

const getCompletePaymentType = (data: PaymentBatchSummaryDto): string => {

    if(data?.uploadType === PaymentUploadType.SWIFT){
        return  PaymentUploadType.SWIFT;
    }

    return 'Batch';
}

const getUploadedBy = (data: PaymentBatchSummaryDto): string => {

    if(data?.uploadType == PaymentUploadType.SWIFT){
        return "SYSTEM";
    }

    return data.uploadedBy || '';
}

export { getApprovedDate, getCompletePaymentType, getUploadedBy }