import { ChangeEvent } from 'react';
import { FeatureFlag } from 'models/featureFlag';
import useSearch, { TSearchBaseResponse, TSearchParams } from '../../hooks/useSearch';
import { PaymentFeatureFlags } from './ManualPayments/constants';
import { scheduledBaseUrl } from './Dashboard/ScheduledTableContainer/constants';

export interface SearchPaymentsTabProps {
  items:TSearchBaseResponse| undefined;
  hasNext: boolean;
  hasPrevious: boolean;
  loading: boolean;
  searchParams?: TSearchParams
  handleNextPage: () => Promise<void>;
  handlePreviousPage: () => Promise<void>;
  handleNewSearch: () => void;
}
const createDraftBatchSearchParams = (
  status: string,
  skip: number,
): TSearchParams => {
  const searchParams: TSearchParams = {
    baseUrl: status === 'SCHEDULE' ? scheduledBaseUrl : `/payments/batches/payments-batches/${status}/search`,
    queryParams: {
      skip,
      take: 10,
      sortby: status === 'SCHEDULE' ? 'nextFireDate' : 'uploadedDate',
      sortorder: status === 'SCHEDULE' ? 'asc' : 'desc',
    },
  };

  return searchParams;
};

const createReleasedBatchSearchParams = (
  batchStatus:string,
  skip: number,
  awaitingClientInput: boolean,
): TSearchParams => {
  const searchParams: TSearchParams = {
    baseUrl: '/payments/batches/released/search',
    queryParams: {
      skip,
      take: 10,
      batchStatus,
      sortby: 'uploadedDate',
      sortorder: 'desc',
      awaitingClientInput: `${awaitingClientInput}`,
    },
  };
  return searchParams;
};

const getStatus = (tab: number, flags: FeatureFlag[] | undefined): string => {
  const standingOrderFFEnabled = flags?.find((flag) => (
    flag.name === PaymentFeatureFlags.STANDING_ORDER && flag.isActive
  ))?.isActive;

  switch (tab) {
    case 0:
      return 'PENDING';
    case 1:
      return 'INPROGRESS';
    case 2:
      return 'COMPLETE';
    case 3:
      return standingOrderFFEnabled ? 'SCHEDULE' : 'DRAFTED';
    case 4:
      return standingOrderFFEnabled ? 'DRAFTED' : 'ACTIONNEEDED';
    case 5:
      return standingOrderFFEnabled ? 'ACTIONNEEDED' : 'FUNDED';
    case 6:
      return standingOrderFFEnabled ? 'FUNDED' : 'PENDING';
    default:
      return 'PENDING';
  }
};

const usePaymentBatchSearch = (tab: number, flags: FeatureFlag[] | undefined) => {
  const search = useSearch();
  const status = getStatus(tab, flags);
  const released = status === 'COMPLETE' || status === 'INPROGRESS' || status === 'ACTIONNEEDED';
  const searchParams = () => {
    if (released) {
      if (status === 'ACTIONNEEDED') {
        return createReleasedBatchSearchParams('INPROGRESS', search.skip, true);
      }
      return createReleasedBatchSearchParams(status, search.skip, false);
    }
    return createDraftBatchSearchParams(status, search.skip);
  };

  const handleNewSearch = async () => {
    await search.handleNewSearch(searchParams(), true);
  };
  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    search.setSearchText(e.target.value);
    const { ...newSearchParams } = searchParams();
    if (e.target.value) {
      newSearchParams.queryParams.searchtext = e.target.value;
    }

    await search.handleNewSearch(newSearchParams, true);
  };

  return {
    search,
    handleNewSearch,
    handleInputChange,
    searchParams: searchParams(),
  };
};

export default usePaymentBatchSearch;
