import React, { FC, memo } from 'react';
import { ButtonProps, CircularProgress } from '@mui/material';
import StyledBaseButton from './BaseButton.styles';

export interface IBaseButtonProps extends ButtonProps {
  testId?: string;
  loading?: boolean;
  rounded?: boolean;
}

const BaseButton: FC<IBaseButtonProps> = memo((props) => {
  const {
    testId, loading, rounded, ...rest
  } = props;

  return (
    <StyledBaseButton data-testid={testId} rounded={rounded} {...rest}>
      <div>
        {loading && (
          <div className="loader">
            <CircularProgress
              data-testid={`${testId}-loader`}
              color={rest.disabled ? 'primary' : 'inherit'}
              size={18}
            />
          </div>
        )}
        {rest.children}
      </div>
    </StyledBaseButton>
  );
});

export default BaseButton;
