import React from 'react';
import { StyledBox } from './StickyFooter.styles';

interface IProps {
    children: JSX.Element | JSX.Element[]
}

const StickyFooterContainer: React.FC<IProps> = ({ children }: IProps) => (
  <StyledBox>
    {children }
  </StyledBox>
);

export default StickyFooterContainer;
