import React from 'react';
import { CircularProgress } from '@mui/material';
import { useStyles } from './LoadingButton.styles';
import { IBaseButtonProps } from '../BaseButton';
import ActionButton from '../ActionButton';

type TLoadingButtonProps = {
  loading: boolean;
} & IBaseButtonProps;

const LoadingButton: React.FC<TLoadingButtonProps> = (props: TLoadingButtonProps) => {
  const classes = useStyles();
  const { children, loading, ...restProps } = props;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <ActionButton testId="loading-button" {...restProps}>
          {children}
        </ActionButton>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
};

export default LoadingButton;
