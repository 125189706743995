import React from 'react';
import { Box } from '@mui/material';
import APPagination from 'components/APPagination';
import StyledGenericTable from 'components/Table/StyledGenericTable';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import { PaginationIProps } from '..';
import { useStyles } from '../index.styles';

interface IProps {
    columns: ITableColumn[];
    data: any[];
    paginationProps?: PaginationIProps;
    handlePendingTableRowClick?: (e: any) => void;
  }

const TableWithPagination = (props: IProps) => {
  const classes = useStyles();
  const {
    columns, data, handlePendingTableRowClick, paginationProps,
  } = props;
  return (
    <>
      <Box className={classes.dashboardHeaderLine}>
        <StyledGenericTable
          clickable={false}
          handleTableRowClick={handlePendingTableRowClick}
          testId="dashboard-table-with-pagination"
          columns={columns}
          data={data}
        />
      </Box>
      {paginationProps && paginationProps.paginationEnabled && (
      <Box display="block" textAlign="right">
        <APPagination
          {...paginationProps.pagination}
        />
      </Box>
      )}
    </>
  );
};

export default TableWithPagination;
