import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faReply } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Divider, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import environmentVars from 'env.variables';
import { StyledButton } from '../../Form/index.styles';
import { createSubmission } from '../api';
// import { StyledButton } from '../../Form/index.styles';

const NEW_ENTITY_ONBOARDING_FORM_ID = environmentVars.FORMIO_SECURE_REDUCED_FORM_ID;
const REUSABLE_KYC_ONBOARDING_FORM_ID = environmentVars.FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID;

export const SubmissionButton = ({
  setNewSubmissionState,
  newSubmissionState,
  setPage,
  setTab,
  init,
  getPage,
  hasReusableKYCAccess,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewFormSubmission = async (formId: string) => {
    setPage(null);
    setPage(0);
    setTab(formId === REUSABLE_KYC_ONBOARDING_FORM_ID ? 1 : 0);
    await createSubmission(setNewSubmissionState, newSubmissionState, init, formId);
    handleClose();
    init();
  };

  const handleNewEntitySubmission = () => handleNewFormSubmission(NEW_ENTITY_ONBOARDING_FORM_ID);
  const handleReuseKYCSubmission = () => handleNewFormSubmission(REUSABLE_KYC_ONBOARDING_FORM_ID);

  return (
    <>
      {hasReusableKYCAccess ? (
        <div style={{ marginTop: '6em', padding: '10px', textAlign: 'right' }}>
          <StyledButton
            disabled={newSubmissionState.loading}
            onClick={handleClick}
            variant="contained"
            aria-haspopup="true"
          >
            {newSubmissionState.loading ? <CircularProgress /> : 'New Submission'}
          </StyledButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            autofocus={false}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            setOpen={handleClose}
          >
            <Box>
              <Typography variant="h4" style={{ padding: '10px', paddingLeft: '20px', fontSize: '1.5em' }}>
                New Submission
              </Typography>
            </Box>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleNewEntitySubmission}>
              <Box
                sx={{
                  // border: '1px solid black',
                  padding: '4px',
                  // marginleft: '200px',
                  width: 'max-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  style={{ paddingRight: '20px' }}
                  icon={faPlus as IconDefinition}
                  className="searchIcon"
                />
                <Box>
                  <Typography variant="h5" sx={{ fontSize: '16px' }}>
                    New Entity Onboarding
                  </Typography>
                  <Typography variant="p">Onboarding Submission for a new Entity</Typography>
                </Box>
              </Box>
            </MenuItem>
            <MenuItem onClick={handleReuseKYCSubmission}>
              <Box
                sx={{
                  // border: '1px solid black',
                  padding: '4px',
                  // marginleft: '200px',
                  width: 'max-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  style={{ paddingLeft: '20px', rotate: '180deg' }}
                  icon={faReply as IconDefinition}
                  className="searchIcon"
                />
                <Box>
                  <Typography variant="h5" sx={{ fontSize: '16px' }}>
                    Reuse KYC Onboarding
                  </Typography>
                  <Typography variant="p">
                    Onboarding Submission for an Entity <br />
                    related to a previously onboarded Entity
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <div style={{ marginTop: '58px', padding: '10px', textAlign: 'right' }}>
          <StyledButton
            disabled={newSubmissionState.loading}
            onClick={async () => {
              await handleNewFormSubmission(NEW_ENTITY_ONBOARDING_FORM_ID);
            }}
            variant="contained"
          >
            {newSubmissionState.loading ? <CircularProgress /> : 'New Submission'}
          </StyledButton>
        </div>
      )}
    </>
  );
};
