import { createContext } from 'react';
import { TSignIn } from '../models/auth';

export type TAuthContext = {
  userObject: TSignIn | undefined;
  handleSetUserObject: (userObject: TSignIn) => void;
  temporaryPassword?: string,
  setTemporaryPassword?: (tempPassword: string) => void,
};

export const AuthContext = createContext<TAuthContext>({
  userObject: undefined,
  handleSetUserObject: () => {},
  temporaryPassword: undefined,
  setTemporaryPassword: () => {},
});
