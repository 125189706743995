import { Box } from '@mui/material';
import React from 'react';
import { ITableColumn, ITableData } from '../../services/DatabaseServices/marker.interface';
import useStyles from './Table.styles';
import StyledGenericTable from './StyledGenericTable';

export type TTableProps = {
  columns: ITableColumn[],
  data: ITableData[],
  clickable?: boolean;
  handleTableRowClick?: (e: any) => void;
  shortRows?: boolean;
  testId?: string;
}

export const Table: React.FC<TTableProps> = (props: TTableProps) => {
  const {
    columns, data, handleTableRowClick, shortRows, clickable, testId = 'beneficiaries-table',
  } = props;
  const styling = useStyles({ shortRows });

  return (
    <Box className={styling.root}>
      <StyledGenericTable
        clickable={!!clickable}
        testId={testId}
        columns={columns}
        data={data}
        handleTableRowClick={handleTableRowClick ? handleTableRowClick! : () => { }}
      />
    </Box>
  );
};

export default Table;
