import { CircularProgress } from '@mui/material';
import React, { memo } from 'react';
import { ActionButton, GhostButton } from 'components/Buttons';
import { testIds } from '../Funding';
import { useStyles } from './BookFxFooter.styles';

interface IProps {
  fxApprover: boolean;
  disableBookFxBtn: boolean;
  handleLiveRateReset: () => void;
  handleFxBooking: () => void;
}

const BookFxFooter: React.FC<IProps> = (props: IProps) => {
  const {
    handleLiveRateReset, handleFxBooking, disableBookFxBtn, fxApprover,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.bookFooter}>
      <GhostButton
        disabled={disableBookFxBtn}
        colorVariant="warning"
        testId={testIds.cancelBookingBtn}
        size="large"
        onClick={() => handleLiveRateReset()}
      >
        Cancel
      </GhostButton>
      {fxApprover && (
      <ActionButton
        testId={testIds.bookFxBtn}
        size="large"
        onClick={() => handleFxBooking()}
        disabled={disableBookFxBtn}
      >
        {disableBookFxBtn
          ? <CircularProgress size={20} />
          : 'Book FX'}
      </ActionButton>
      )}

    </div>
  );
};

export default memo(BookFxFooter);
