import { CurrencyAccountSearchDto } from '@alpha/currency-accounts-dtos';
import { useContext, useState } from 'react';
import { AccountSelectionEnum } from '../../../components/Transactions/Dropdown/Dropdown';
import TransactionsService from '../../../services/Transactions/transactions.service';
import { TransactionContext } from '../TransactionsContext';

const useTransactionsSelect = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const transactionContext = useContext(TransactionContext);

  const handleClickOnGroupedCurrencyHeader = (
    onChangeArray: string[],
    indexOfCurrentChangeItem: number,
  ) => {
    if (
      transactionContext?.currencyAccountsOptions
      && transactionContext?.currencyAccountIds
    ) {
      const selectedEntities = transactionContext.entitiesSelected;
      updateSelectedEntities(selectedEntities, onChangeArray[indexOfCurrentChangeItem].split('|')[1],
        onChangeArray[indexOfCurrentChangeItem].split('|')[2]);

      const headerCurrencyAccounts: string[] = [];
      transactionContext?.currencyAccountsOptions.forEach((currencyAccount) => {
        if (
          currencyAccount.accountName
          === onChangeArray[indexOfCurrentChangeItem].split('|')[1]
        ) {
          headerCurrencyAccounts.push(currencyAccount.id);
        }
      });
      const updatedCurrencyAccounts = [
        ...transactionContext?.currencyAccountIds,
      ];
      const allGroupSelected = onChangeArray[indexOfCurrentChangeItem].split('|')[2];
      if (allGroupSelected === 'true') {
        const newCurrencyAccounts = transactionContext?.currencyAccountIds.filter(
          (curr) => !headerCurrencyAccounts.includes(curr),
        );
        transactionContext?.setCurrencyAccountIds(newCurrencyAccounts);
      } else {
        headerCurrencyAccounts.forEach((currencyAccount) => {
          if (
            transactionContext?.currencyAccountIds
            && !transactionContext.currencyAccountIds.includes(currencyAccount)
          ) {
            updatedCurrencyAccounts.push(currencyAccount);
          }
        });
        transactionContext?.setCurrencyAccountIds(updatedCurrencyAccounts);
      }
    }
  };

  const handleAllAccountsClicked = (onChangeArray: string[]) => {
    if (transactionContext?.currencyAccountsOptions) {
      const selectedEntities = transactionContext.entitiesSelected;
      if (
        onChangeArray?.length
        >= transactionContext?.currencyAccountsOptions?.length
      ) {
        updateSelectedEntities(selectedEntities, AccountSelectionEnum.ALL_ACCOUNTS, 'true');
        transactionContext?.setCurrencyAccountIds([]);
      } else {
        transactionContext?.setCurrencyAccountIds(
          transactionContext?.currencyAccountsOptions?.map(
            (currencyAccount) => currencyAccount.id,
          ),
        );
        updateSelectedEntities(selectedEntities, AccountSelectionEnum.ALL_ACCOUNTS, 'false');
      }
    }
  };

  const getTransactionAccountsOnScroll = async () => {
    if (
      transactionContext?.currencyAccountsOptions?.length
    ) {
      if (
        transactionContext?.currencyAccountTotal
        && transactionContext?.currencyAccountsOptions?.length
          < transactionContext?.currencyAccountTotal
      ) {
        const response = await TransactionsService.getTransactionAccountsMultiEntity(
          transactionContext?.currencyAccountsOptions
              && transactionContext?.currencyAccountsOptions.length,
        );
        transactionContext?.setCurrencyAccountsOptions([
          ...transactionContext?.currencyAccountsOptions,
          ...(response.currencyAccounts as CurrencyAccountSearchDto[]),
        ]);
        transactionContext?.setCurrencyAccountTotal(
          response.totals.totalAccounts,
        );
        const updatedCurrencyAccounts = transactionContext?.currencyAccountIds ? [
          ...transactionContext?.currencyAccountIds,
        ] : [];
        response.currencyAccounts.forEach((currencyAccount) => {
          if (
            (
              currencyAccount?.accountName
              && transactionContext.entitiesSelected.has(
                currencyAccount?.accountName,
              ))
            || transactionContext.entitiesSelected.has(AccountSelectionEnum.ALL_ACCOUNTS)
          ) {
            updatedCurrencyAccounts.push(currencyAccount.id);
          }
        });
        transactionContext?.setCurrencyAccountIds(updatedCurrencyAccounts);
      }
    }
  };

  const updateSelectedEntities = async (selectedEntities: Set<string>, entityToChange: string,
    remove: string) => {
    if (remove === 'false') {
      selectedEntities.add(
        entityToChange,
      );
    } else {
      selectedEntities.delete(
        entityToChange,
      );
    }
    transactionContext?.setEntitiesSelected(selectedEntities);
  };

  return {
    disabled,
    setDisabled,
    transactionContext,
    getTransactionAccountsOnScroll,
    handleAllAccountsClicked,
    handleClickOnGroupedCurrencyHeader,
  };
};
export default useTransactionsSelect;
