import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IDualFlagProps } from './DualFlag.interface';
import { Flag } from './Flag';
import { DualFlagStyled } from './DualFlag.styles';

enum ArrowColor {
    WHITE = 'rgba(255, 255, 255, 0.5)',
    LIGHT = 'rgba(72, 72, 72, 0.5)',
    DARK = '#484848',
}

export const DualFlag: React.FC<IDualFlagProps> = (props: IDualFlagProps) => {
  const {
    arrowColor, arrowHeight, arrowMargin, arrowWidth, endFlag, rebrand, startFlag,
  } = props;
  const arrowStyling: React.CSSProperties = {
    margin: arrowMargin
      ? `0 ${arrowMargin}px ${startFlag.showCodeLabel && !rebrand ? '20px' : '0'}`
      : `0 11px ${startFlag.showCodeLabel && !rebrand ? '20px' : '0'}`,
    width: arrowWidth ?? 16,
    height: arrowHeight ?? 16,
    color: arrowColor ? ArrowColor[arrowColor] : ArrowColor.LIGHT,
  };

  return (
    <DualFlagStyled>
      <div className="flagsWrapper">
        <Flag {...startFlag} />
        <FontAwesomeIcon icon={faArrowRight as IconDefinition} style={arrowStyling} />
        <Flag {...endFlag} />
      </div>
    </DualFlagStyled>
  );
};
