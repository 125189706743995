import theme, { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    container: {
      position: 'relative',
      display: 'flex',
      marginBottom: '1.5em',
    },
    step: {
      flexBasis: 0,
      flexGrow: 1,
      transition: '0.2s',
      fontWeight: 200,
      color: theme.palette.grey[500],
      '& h3': {
        fontSize: '1rem !important',
        fontWeight: 500,
        position: 'relative',
        width: 'max-content',
      },
    },
    active: {
      color: theme.palette.primary.main,
      '& h3': {
        fontSize: '1rem !important',
        fontWeight: '900',
      },
    },
    pastStep: {
      color: theme.palette.primary.main,
    },
    progressContainer: {
      height: 2,
      background: theme.palette.grey[200],
      marginTop: '2em',
      marginBottom: '1.5em',
      margin: 'auto',
      display: 'flex',
    },
    progressBar: {
      position: 'relative',
      background: theme.palette.primary.main,
    },
    circle: {
      background: theme.palette.primary.main,
      width: 10,
      height: 10,
      top: -3,
      right: -5,
      borderRadius: '100%',
      position: 'absolute',
    },
    errorBackground: {
      background: '#d32f2f !important',
    },
    errorIcon: {
      width: 14,
      height: 14,
      position: 'absolute',
      right: '-1.5em',
      top: '0.2em',
      color: '#d32f2f',
    },
  }),
  { name: 'WizardModal' },
);

export default useStyles;
