import theme, { makeStyles } from 'theme';

const { typography, palette } = theme;

const useStyles = makeStyles(
  () => ({
    mobileField: {
      '--PhoneInputCountryFlag-aspectRatio': 1.6,
      backgroundColor: '#F7F7F7',
      outline: 'none',
      borderRadius: 5,
      fontSize: 17,
      paddingLeft: 15,
      width: '100%',
      marginTop: 5,
      '&.error': {
        transition: 'all 0.3s',
        color: palette.error.main,
        backgroundColor: `${palette.error.main}20`,
        border: `1px solid ${palette.error.main}`,
        '& input': {
          background: 'none',
          color: palette.error.main,
          paddingLeft: 10,
        },
      },
      '& div.PhoneInputCountry': {
        margin: 0,
      },
      '& input.PhoneInputInput': {
        borderRadius: 5,
        width: '100%',
        height: 45,
        backgroundColor: '#F7F7F7',
        border: 'none',
        outline: 'none',
        fontSize: 13,
        fontFamily: typography.fontFamilySecondary,
        paddingLeft: 10,
        '&:-internal-autofill-selected': {
          backgroundColor: `${palette.error.main}20`,
        },
      },
      '&.PhoneInput--focus': {
        '& div.PhoneInputCountrySelectArrow': {
          color: 'inherit !important',
        },
      },
      '& div.PhoneInputCountryIcon--border': {
        boxShadow: 'none !important',
        backgroundColor: 'transparent',
        fontSize: 16,
        marginRight: 10,
        '& svg': {
          borderRadius: 2,
        },
      },
      '& div.PhoneInputCountrySelectArrow': {
        marginLeft: 0,
      },
    },
  }),
  { name: 'PhoneInputField' },
);

export default useStyles;
