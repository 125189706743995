import { useState } from 'react';
import { originalUseSnackbar } from 'components/Snackbar';
import AuthService from '../services/Auth/auth.service';
import useAuth from './useAuth';

const useHandleLogin = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const snackbar = originalUseSnackbar();

  const handleLogin = async (
    username: string,
    password: string,
  ) => {
    try {
      setLoading(true);
      const response = await AuthService.signIn(username, password);
      if (!response.challengeName) {
        //  If MFA is disabled
        await auth.checkAuth();
      } else {
        await auth.challengeRedirect(response, snackbar.enqueueSnackbar);
      }
    } catch (e) {
      const validMessages = [
        'Incorrect username or password.',
        'Password attempts exceeded',
      ];
      const errorMessage = validMessages.includes(e.message)
        ? e.message
        : 'There was an error logging you in';
      snackbar.enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
        className: 'dd-privacy-allow',
      });
      setLoading(false);
    }
  };

  return {
    auth,
    loading,
    setLoading,
    handleLogin,
  };
};

export default useHandleLogin;
