import axios, { AxiosRequestConfig } from 'axios';
import environmentVars from '../../env.variables';
import AuthService from '../Auth/auth.service';

let store: any;

export const multiEntityInstance = axios.create({
  baseURL: environmentVars.REACT_APP_API_URL,
});

export const injectStore = (_store: any) => {
  store = _store;
};

multiEntityInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  newConfig.headers.Authorization = await AuthService.getAccessToken();
  newConfig.headers['Session-Id'] = AuthService.getSessionUUIDFromLocalStorage();
  switch (newConfig.url) {
    case '/auth/entity-group/read-groups':
      newConfig.headers['account-id'] = null;
      break;
    default:
      newConfig.headers['account-id'] = store.getState().accounts.selectedAccounts.length
        && !config.params?.includeAllAllowedAccounts
        ? getAccountIds(store.getState().accounts.selectedAccounts)
        : getAccountIds(store.getState().accounts.allowedAccounts);
      break;
  }
  return newConfig;
});

multiEntityInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const isInvalidSession = error?.response?.data?.invalidSession || false;
    if (isInvalidSession) {
      await AuthService.handleInvalidSession();
    }
    return Promise.reject(error);
  },
);

const getAccountIds = (accountRecordsArray: Record<string,
  string>[]): string[] => accountRecordsArray.map((account: Record<string, string>) => account.id);

export default multiEntityInstance;
