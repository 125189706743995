import { ReleasedPaymentBatchesResponse } from '@alpha/payments-types';
import { Box } from '@mui/material';
import Status from 'components/Status/Status';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import SearchTable from '../../../../components/Table/SearchTable';
import { TextEllipsis } from '../../../../components/TextEllipsis';
import { PaymentRouteEnum } from '../../../../models/payments';
import routes from '../../../../routes.path';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../utils/formatIsoDate';
import { SearchPaymentsTabProps } from '../../usePaymentBatchSearch';
import { getApprovedDate, getUploadedBy } from '../DashboardDataHelper/DashboardDataHelper';
import PaymentsType from '../PaymentsType/PaymentsType';
import useStyles from '../SearchPaymentsTable/SearchPaymentsTable.styles';

const CompleteTableContainer: React.FC<SearchPaymentsTabProps> = (
  props: SearchPaymentsTabProps,
) => {
  const {
    items,
    hasNext,
    hasPrevious,
    handleNextPage,
    handlePreviousPage,
    loading,
  } = props;
  const classes = useStyles();

  const columns: ITableColumn[] = [
    {
      header: <Box paddingRight="80px">ID</Box>,
      accessor: 'id',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Entity</Box>,
      accessor: 'entity',
      className: clsx(classes.textAlignLeft, 'dd-privacy-allow'),
    },
    {
      header: <Box className={classes.textAlignLeft}>Type</Box>,
      accessor: 'type',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Uploaded by</Box>,
      accessor: 'uploadedBy',
    },
    {
      header: <Box className={classes.textAlignLeft}>uploaded date</Box>,
      accessor: 'uploadedDate',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Approved Date</Box>,
      accessor: 'approvedDate',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Payment Status</Box>,
      accessor: 'paymentStatus',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Batch Status</Box>,
      accessor: 'batchStatus',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.action}>Action</Box>,
      accessor: 'action',
      className: 'dd-privacy-allow',
    },
  ];

  const mappedData = items?.records
    && (items.records as ReleasedPaymentBatchesResponse[]).map(
      (item) => {
        const container: any = {};
        container.id = <b>{item.friendlyId || ''}</b>;
        container.entity = (
          <TextEllipsis text={item.accountName} className={classes.spaceRight} />
        );
        container.type = <PaymentsType type={item.uploadType} />;
        container.uploadedBy = (
          <Box className={classes.textAlignLeft}>
            {getUploadedBy(item)}
          </Box>
        );
        container.uploadedDate = (
          <Box className={classes.textAlignLeft}>
            {
              formatIsoDate(item.uploadedDate)
            }
          </Box>
        );
        container.approvedDate = (
          <Box>{getApprovedDate(item)}</Box>
        );

        container.paymentStatus = (
          <Box>
            <Status variant="default">
              {`${item.totalProcessedPayments}/${item.totalPayments}`}
            </Status>

          </Box>
        );
        container.batchStatus = (
          <Box>
            <Status variant="success">{item.status}</Status>
          </Box>
        );
        container.action = (
          <Box data-testid={`action-${item.id}`} className={classes.action}>
            <Link
              to={routes.payments.multientity.batchSummaryRouteFunc(
                item.accountId, item.id, PaymentRouteEnum.COMPLETE,
              )}
              className="actionLink"
            >
              View
            </Link>
          </Box>
        );
        return container;
      },
    );

  return (
    <SearchTable
      table={{
        columns,
        data: mappedData || [],

      }}
      loading={loading}
      pagination={{
        handleNext: () => handleNextPage(),
        handlePrevious: () => handlePreviousPage(),
        hasNext: hasNext || false,
        hasPrevious: hasPrevious || false,
      }}
      emptyTable={{
        title: 'No Complete Payments',
        subtitle: 'You currently do not have any completed payment batches',
      }}
    />
  );
};

export default memo(CompleteTableContainer);
