export enum DashaboardTablesSearchUrls {
    BENEFICIARIES = '/bene/account-bene/search',
    BALANCE_TRANSFERS = '/iat/search/iat-drafts',
    AWAITING_FX = '/payments/batches/payments-batches/APPROVED/search',
    PAYMENT_PENDING = '/payments/batches/payments-batches/SUBMITTED/search'
}

export interface IPendingTableProps {
    enableSearch: boolean;
    enablePagination: boolean;
    searchPlaceholder?: string;
    entityClassName?: string;
}

export enum DashboardTabs {
    PAYMENT_APPROVALS = 'Payment Approvals',
    BENE_APPROVALS = 'Beneficiary Approvals',
    AWAITING_FX = 'Awaiting FX booking',
    BALANCE_TRANSFER = 'Balance Transfer'
}
