import { makeStyles } from 'theme';

const useStyles = makeStyles(
  {
    root: {
      '& .MuiTableCell-head, .MuiTableCell-body': {
        'padding-right': '6px !important',
      },
      '& .MuiTableCell-head:last-of-type, .MuiTableCell-body:last-of-type': {
        'text-align': 'right',
        'padding-right': '0 !important',
      },
    },

  },
  { name: 'Dashboard' },
);

export default useStyles;
