import { APMainLayout } from 'components/Layout/MainLayout';
import { StyledTabsWrapper } from 'components/Tabs';
import React from 'react';
import KYCBannerComponent from 'components/KYCBannerComponent';
import Alert from '../../components/Alert/Alert';
import { CurrencyPair } from '../../components/International';
import { TCurrencyTab } from '../../models/currencyAccounts';
import Body from './Body/Body';
import useCurrencyAccount from './useCurrencyAccount';
import useAuthorization from '../../hooks/useAuthorization';
import { UserRole } from '../../models/user';
import BannerContent from './BannerContent/BannerContent';
import useSwitchAccount from '../../hooks/useSwitchAccount';
import CurrencyAccountHeader from './CurrencyAccountsHeader';
import routes from '../../routes.path';
import useStyles from './CurrencyAccounts.styles';

export const testIds = {
  screenTabs: 'screen-tabs',
};
interface IProps {
  multiEntity?: boolean;
}
const CurrencyAccounts: React.FC<IProps> = (props: IProps) => {
  const { multiEntity } = props;
  const {
    allowedUserAccounts,
    selectedTabIndex,
    tabs,
    loading,
    oldestOverdueInvoice,
    handleSetSelectedTabIndex,
    selectedUserAccounts,
    userInputterAccounts,
  } = useCurrencyAccount(multiEntity);

  const classes = useStyles();

  const invoiceAuthorization = useAuthorization([[UserRole.INVOICEMANAGER]]);
  const { currentAccount } = useSwitchAccount();
  const breadcrumbs = [{ text: 'Home', url: routes.home }];

  const bannerContent = () => (
    <BannerContent oldestOverdueInvoice={oldestOverdueInvoice} />
  );
  const header = () => <CurrencyAccountHeader />;

  const bannerHiddenState = localStorage.getItem('overdue-invoices-banner-hidden') === 'true';
  return (
    <APMainLayout
      data-id="currencyAccounts"
      title={multiEntity ? header() : 'Accounts'}
      pageTabs={(
        <StyledTabsWrapper
          className="dd-privacy-allow"
          testId={testIds.screenTabs}
          tabTitles={
            tabs.length === 0 || !tabs.length
              ? []
              : tabs.map((tab: TCurrencyTab) => (
                <CurrencyPair currencyCode={tab.currencyCode} />
              ))
          }
          tabIndex={selectedTabIndex}
          handleChange={handleSetSelectedTabIndex}
          iconTitles={false}
        />
      )}
      breadCrumbs={breadcrumbs}
    >
      <div className={classes.alertBanner}>
        <KYCBannerComponent />
        {invoiceAuthorization.authorized
        && oldestOverdueInvoice
        && currentAccount.invoicesEnabled
        && !bannerHiddenState && (
          <Alert data-dd-privacy="allow" variant="info" text={bannerContent()} cancel />
        )}
      </div>
      <Body
        multiEntity={multiEntity}
        selectedUserAccounts={selectedUserAccounts}
        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        loading={loading}
        inputterAccounts={userInputterAccounts}
      />
    </APMainLayout>
  );
};

export default CurrencyAccounts;
