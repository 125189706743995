import { Box } from '@mui/material';
import React from 'react';
import APPagination, { IAPPagination } from 'components/APPagination';
import clipboardCheck from '../../../assets/clipboardCheck.svg';
import BackdropLoader from '../../Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable, { IEmptyTable } from '../EmptyTable/EmptyTable';
import Table, { TTableProps } from '../Table';

interface IProps {
    table: TTableProps;
    pagination: IAPPagination;
    loading: boolean;
    emptyTable: IEmptyTable;
}

const SearchTable: React.FC<IProps> = ({
  table, pagination, loading, emptyTable,
}: IProps) => {
  if (table?.data?.length) {
    return (
      <>
        <Table
          {...table}
        />
        <Box display="block" textAlign="right">
          <APPagination
            {...pagination}
          />
        </Box>
        {loading && <BackdropLoader testId="backdrop-loader" />}
      </>
    );
  }

  if (!table?.data?.length && loading) {
    return <BackdropLoader testId="backdrop-loader" />;
  }

  return (
    <EmptyTable
      {...emptyTable}
      icon={clipboardCheck}
    />
  );
};

export default SearchTable;
