import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  root: (props: any) => ({
    marginBottom: '32px',
    '& td': {
      padding: '10px 0 !important',
    },
    '& td.MuiTableCell-root': {
      height: props.shortRows && '32px !important',
      padding: props.shortRows && '0px !important',
    },
  }),
}), { name: 'Table' });

export default useStyles;
