import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    noOfApprovers: {
      margin: 'auto',
      maxWidth: 150,
      display: 'flex',
      justifyContent: 'center',
    },
  }), { name: 'NoOfApprovers' },
);

export default useStyles;
