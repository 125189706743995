import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    headerText: {
      letterSpacing: -0.5,
    },
    drawerWrapper: {
      '& .MuiDrawer-paper': {
        '& th': {
          borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
        },
        '& td': {
          borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
          '&:last-child': {
            padding: 0,
          },
        },
      },
    },
    header: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      padding: '60px 25px 15px 50px',
    },
    headerContent: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > span:first-child': {
        fontSize: 22,
        fontWeight: typography.fontWeightLight,
      },
    },
    downloadButton: {
      minHeight: 'none',
      minWidth: 'none',
      height: 40,
      width: 40,
    },
    icon: {
      color: '#9F9F9F',
      height: 20,
      width: 20,
    },
  }),
  { name: 'CreatePayments' },
);

export default useStyles;
