import styled from '@mui/styled-engine';
import MTabs from '@mui/material/Tabs';

const StyledTab = styled(MTabs)({
  minHeight: 0,
  '& .MuiTabs-indicator': {
    height: 3,
  },
  '& .MuiTabs-scroller': {
    overflow: 'inherit',
  },
});

export default StyledTab;
