import { Box, Button, styled } from '@mui/material';
import APModal from '../APModal/APModal';

const StyledContainer = styled(APModal.Container)({
  maxWidth: 610,
  border: 'none',
  borderRadius: '7px',
  outline: 'none',
  padding: '40px !important',
  '& .MuiBox-root': {
    padding: '0 !important',
  },
});

const StyledHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  '& h2': {
    fontWeight: '400',
    fontSize: '22px',
    paddingBottom: '8px',
    alignItems: 'end',
  },
  '& img': {
    width: '25px',
    marginRight: '8px',
    alignItems: 'flex-start',
  },
  '& span': {
    display: 'flex',
  },
});

const StyledBody = styled(Box)({
  fontSize: '16px',
  fontWeight: 'normal',
  color: '#737373',
  '& h6': {
    color: '#222222',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
  },
  '& .errorMessage': {
    fontWeight: 'bold',
    paddingBottom: '20px',
  },
});

const StyledButtonsContainer = styled(Box)({
  textAlign: 'right',
  '& .closeButton': {
    fontWeight: 'bold',
  },
  '& .rejectButton': {
    marginLeft: '16px',
  },
  display: 'flex',
  justifyContent: 'end',
});

const StyledActionButton = styled(Button)({
  borderRadius: 5,
  marginTop: '40px',
  fontWeight: 600,
});

export {
  StyledContainer,
  StyledHeader,
  StyledBody,
  StyledButtonsContainer,
  StyledActionButton,
};
