import theme, { makeStyles } from 'theme';

const { typography, palette } = theme;

const useStyles = makeStyles(
  () => ({
    errorMessage: {
      color: palette.error.main,
      fontSize: 10,
      paddingBottom: 0,
      '& ~ span ': {
        color: palette.error.main,
      },
    },
    textBox: {
      fontFamily: typography.fontFamilySecondary,
      fontSize: 13,
      padding: 20,
      width: '100%',
      height: 45,
      borderRadius: 5,
      backgroundColor: '#F7F7F7',
      border: 1,
      marginTop: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '&:focus': {
        outline: 'none',
      },
      '&.error': {
        transition: 'all 0.3s',
        color: palette.error.main,
        backgroundColor: `${palette.error.main}20`,
        border: `1px solid ${palette.error.main}`,
        '& > div': {
          width: '48%',
        },
      },
    },
    inputWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 5,
      '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
        {
          '-webkit-box-shadow': '0 0 0 30px #F7F7F7 inset !important',
        },
    },
  }),
  { name: 'InputFormField' },
);

export default useStyles;
