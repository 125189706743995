import React, { useEffect, useState } from 'react';
import {
  Autocomplete, Box, TextField, Typography, Alert,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { AccountWithApprovalConfig } from '@portal/user-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import useStyles from './index.styles';
import UserManagementService from '../../../../services/UserManagement/userManagement.service';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';

const EntitiesSelector = () => {
  const { values, setFieldValue } = useFormikContext<{ ids: AccountWithApprovalConfig[] }>();
  const [entities, setEntities] = useState<AccountWithApprovalConfig[]>([]);

  const classes = useStyles();
  const sb = useAlphaSnackbar();

  useEffect(() => {
    async function fetchEntities() {
      let data: AccountWithApprovalConfig[] = [];

      try {
        data = (await UserManagementService.getAllEntities({
          filterByIsManageable: true,
          approvalConfig: 'true',
        })).data;

        setEntities(data);
      } catch (e) {
        sb.trigger('Error loading entities');
      }

      // checking the selected entities in formik against the is manageable entities
      const entitiesList = values.ids.filter(
        (selectedEntity) => data.some((entity) => selectedEntity.id === entity.id),
      );
      setFieldValue('ids', entitiesList);
    }

    fetchEntities();
  }, []);

  return (
    <Box className={classes.root}>
      {(values.ids.length === 20)
           && (
           <Alert icon={<FontAwesomeIcon color="#665216" icon={faExclamationTriangle} size="xs" className={classes.icon} />} severity="warning" className={classes.alert}>
             Please be aware, a maximum of 20 entities can be selected at once.
           </Alert>
           )}
      <Typography className={classes.typography}>
        {`Select Entities (${values.ids.length}/20)`}
      </Typography>
      <Autocomplete
        getOptionDisabled={() => values.ids.length === 20}
        className={classes.dropdown}
        multiple
        loading={entities.length === 0}
        loadingText="Loading entities..."
        id="entities-selector"
        options={entities}
        value={values.ids}
        getOptionLabel={(option: AccountWithApprovalConfig) => option.name}
        isOptionEqualToValue={(
          option: AccountWithApprovalConfig,
          value: AccountWithApprovalConfig,
        ) => option.id === value.id}
        onChange={(e, value: AccountWithApprovalConfig[]) => {
          setFieldValue('ids', value);
        }}
        renderInput={(params) => (
          <TextField
            className={classes.textInput}
            {...params}
          />
        )}
      />
    </Box>
  );
};

export default EntitiesSelector;
