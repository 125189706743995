import {
  PaymentBatchDraftDto,
} from '@alpha/payments-dtos';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import clsx from 'clsx';
import { ITableColumn } from '../../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import PaymentsType from '../../PaymentsType/PaymentsType';
import NoOfApprovers from '../../PendingTable/DisplayTable/NoOfApprovers/NoOfApprovers';
import TableContainer from '../../TableContainer/TableContainer';
import ActionDropDown from '../ActionDropDown/ActionDropDown';
import useStyles from '../FundedTable.styles';

  interface IProps {
    table: ITable;
    columns: ITableColumn[];
  }

export interface ITable {
    data: PaymentBatchDraftDto[] | undefined;
    hasMore: boolean | undefined;
    hasPrevious: boolean;
    handleNextPage: () => Promise<void>;
    handlePreviousPage: () => Promise<void>;
    loading: boolean;
    rerenderTable: () => Promise<void>
  }

const DisplayTable: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const { table, columns } = props;
  const [displayMultiSelect, setDisplayMultiSelect] = useState(false);

  const mappedData = table.data
      && table.data.map((item: PaymentBatchDraftDto) => {
        const container: any = {};
        container.id = <b>{item.friendlyBatchId || ''}</b>;
        container.noOfPayments = (
          <Box className={clsx(classes.payments, 'dd-privacy-allow')}>
            {item.noOfPayments || ''}

          </Box>
        );
        container.uploadedBy = (
          <Box className={classes.textAlignLeft} maxWidth="100px">
            {item.uploadedBy || ''}
          </Box>
        );
        container.uploadedDate = (
          <Box className={clsx(classes.textAlignLeft, 'dd-privacy-allow')}>
            {item.uploadedDate ? formatIsoDate(item.uploadedDate) : ''}
          </Box>
        );
        container.approvedDate = (
          <Box className={clsx(classes.textAlignLeft, 'dd-privacy-allow')}>

            {
                item.approverInfo ? formatIsoDate(item.approverInfo[item.approverInfo.length - 1].date) : 'N/A'
              }
          </Box>
        );
        container.noOfApprovers = (
          <NoOfApprovers
            uploadedBy={item.uploadedBy}
            uploadedDate={item.uploadedDate}
            noOfApprovals={item.noOfApprovals}
            approvedBy={item.approverInfo}
            approvalsRequired={item.approvalsRequired}
          />
        );
        container.type = (
          <PaymentsType
            type={item.type}
          />
        );
        container.action = (
          <ActionDropDown
            rerenderTable={table.rerenderTable}
            id={item.batchId}
            setDisplayMultiSelect={setDisplayMultiSelect}
            displayMultiSelect={displayMultiSelect}
            batchId={item.batchId}
          />
        );
        return container;
      });

  return (
    <TableContainer
      message="Funded"
      table={table}
      mappedData={mappedData || []}
      columns={columns}
    />
  );
};

export default DisplayTable;
