import { styled, Button } from '@mui/material';
import theme from 'theme';

const StyledLoadingForm = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  gap: 10,
  fontSize: '1.5rem',
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  background: '#FAFAFA',
});

const StyledButton = styled(Button)({
  gap: 5,
  padding: '8px 23px',
  height: 'fit-content',
  textTransform: 'capitalize',
  fontSize: '1rem',
  borderRadius: 5,
  '&:focus': {
    outline: 'none',
    border: 'none',
  },
  '& svg': {
    fontSize: 11,
  },
});

const StyledHeaderLink = styled('a')({
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.light,
  },
});

const StyledShareButton = styled(StyledButton)({
  background: '#dedede',
  color: 'black',
  '&:hover': {
    background: '#E5E5E5',
  },
});

const StyledLink = styled('button')({
  color: '#098375',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  textDecoration: 'underline',
  display: 'inline',
  margin: 0,
  padding: 0,
  ':focus': {
    outline: 'none',
  },
});

const StyledHeader = styled('div')(({ marginTop }: { marginTop: string }) => ({
  background: '#f3f3f3',
  fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  marginTop,
  fontSize: '1.2rem',

  '&.fixed': {
    background: 'transparent',

    '& > div': {
      position: 'fixed',
      top: 58,
      background: '#f3f3f3',
      left: 0,
      right: 0,
      width: '100%',
      height: 65,
      zIndex: 1000,
      padding: '10px 24px',
      transition: 'all 0.5s',
      borderBottom: '1px solid #dcdcdc40',

      '& h1': {
        fontSize: '1.5rem',
      },
    },
  },

  '> div': {
    display: 'flex',
    marginBottom: '2rem',
    alignItems: 'center',
    gap: 10,
    transition: 'all 0.5s',
    padding: '10px 10%',
    borderBottom: 'none',

    '& h1': {
      fontWeight: 'bold',
      flexGrow: 1,
      marginBottom: 0,
    },
  },

  '& p': {
    marginBottom: 0,
    color: '#707070',
    padding: '0 10% 50px',
  },

  '@media only screen and (min-width: 1300px)': {
    '> div': {
      padding: '10px 15%',
    },
    '& p': {
      padding: '0 15% 50px',
    },
  },

  '@media only screen and (min-width: 1700px)': {
    '> div': {
      padding: '10px 20%',
    },
    '& p': {
      padding: '0 20% 50px',
    },
  },
}));

const StyledNavBar = styled('header')({
  display: 'flex',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 1000,
  height: '58px',
  backgroundColor: theme.palette.secondary.main,
  color: '#efefef',
  padding: '0 0 0 24px',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.26)',
  fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",

  '& div:first-of-type': {
    flexGrow: 1,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },

  '& a': {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 24px',
    marginLeft: '26px',
    width: '150px',

    '& img': {
      display: 'block',
      maxHeight: '16px',
    },
  },
});

const StyledSubmittedPage = styled('div')({
  background: '#FAFAFA',
  padding: '0 10%',
  fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  width: '100%',
  fontSize: '1.1rem',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,

  '& div': {
    paddingTop: 10,
    paddingBottom: 10,
    background: '#f8f9fa',
    border: '1px solid #dee2e6',
    borderRadius: 4,
  },

  '@media only screen and (min-width: 1300px)': {
    padding: '0 15%',
  },

  '@media only screen and (min-width: 1700px)': {
    padding: '0 20%',
  },
});

const StyledBody = styled('div')({
  background: '#FAFAFA',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledForm = styled('div')({
  background: '#FAFAFA',
  padding: '4rem 10%',
  fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  width: '100%',
  fontSize: '1.1rem',

  '@media only screen and (min-width: 1300px)': {
    padding: '4rem 15%',
  },

  '@media only screen and (min-width: 1700px)': {
    padding: '4rem 20%',
  },

  '& hr': {
    marginBottom: 25,
  },

  '& .choices__input': {
    backgroundColor: '#fdfdfd',
    marginBottom: 0,
  },

  '& input[type="radio"], & input[type="checkbox"]': {
    accentColor: '#098375',
  },

  '& div:not(.form-rating) > div[ref="radioGroup"] div[ref="wrapper"]': {
    padding: '0',

    '& input': {
      width: 17,
      height: 17,
      marginTop: 1.5,
    },

    '& label': {
      cursor: 'pointer',
      padding: '12px 35px',
      borderRadius: 3,
      border: '1px solid #D4D4D4',
      '& span': {
        lineHeight: '17px',
      },
    },

    '&.radio-selected label': {
      border: '1px solid #098375',
      background: 'rgba(30, 135, 119, 0.05)',
    },
  },

  '& .yesNo > div[ref="radioGroup"]': {
    flexDirection: 'row !important',

    '& input': {
      display: 'none',
    },

    '& label': {
      padding: '10px 35px !important',
    },
  },

  '& div:not(.yesNo) > div[ref="radioGroup"]': {
    gap: '10px !important',

    '& label': {
      width: '100%',
      padding: '15px 2rem',
      '& span': {
        marginLeft: 8,
      },
    },
  },

  '& .directorTabs': {
    '& .card-header': {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
    },
    '& .card': {
      borderColor: '#ffffff',
    },
  },

  '& .card-header-tabs': {
    backgroundColor: '#ffffff',
    '& a': {
      color: '#000000',
      borderStyle: 'solid',
      borderWidth: 1.5,
      borderColor: '#ffffff',
      borderRadius: 5,
      backgroundColor: '#ececec',
      padding: '0.75rem 0.75rem',
      margin: '0.25rem 0.5rem',
    },
    '& .nav-link.active': {
      backgroundColor: '#edf4f3',
      borderColor: '#45988a',
    },
  },

  '& .form-rating > div[ref="radioGroup"]': {
    flexDirection: 'row !important',

    '& label': {
      padding: '0px !important',
    },
  },

  '& div[data-type="select-multiple"]::after': {
    content: '""',
    height: '0',
    width: '0',
    borderStyle: 'solid',
    borderColor: '#333 transparent transparent',
    borderWidth: '5px',
    position: 'absolute',
    right: '11.5px',
    top: '50%',
    marginTop: '-2.5px',
    pointerEvents: 'none',
  },

  '& #customDraftSave, & #saveDraft, & #submitForm': {
    display: 'none',
  },

  '& .formio-component-file .fileSelector': {
    background: '#f8f9fa',
    padding: '3rem 15px',
  },

  '& .editgrid-row-error': {
    color: '#dc3545',
    fontSize: 12,
  },

  '& .custom-required label:after': {
    content: '"*"',
    color: '#eb0000',
  },

  '& .custom-survey': {
    '& .card-body': {
      paddingTop: 10,
      paddingBottom: 10,
      background: '#f8f9fa',
      border: '1px solid #dee2e6',
      borderRadius: 4,
    },
    '& .formio-component-panel .row': {
      alignItems: 'center',
      '& .formio-component-htmlelement': {
        margin: 0,
      },
      '& .form-group': {
        marginBottom: 0,

        '&.formio-component-radio.formio-component-label-hidden.required .label-position-right.form-check-label:before':
        {
          right: 0,
        },
      },
    },
  },

  '& .col-form-label, & .custom-label': {
    fontSize: '1.15rem',
    fontWeight: 'bold',
  },

  '& .formio-component-survey': {
    marginTop: '1.4rem',
  },

  '& h4': {
    padding: '20px 0',
    borderBottom: '1px solid rgb(0 0 0 / 10%)',
    fontWeight: 'bold',
  },

  '& div[ref="radioGroup"]:not(.inline-radio > div[ref="radioGroup"])': {
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
  },

  '& table.table-bordered': {
    border: 'none',
    '& thead > tr > th:first-of-type': {
      border: 'none',
    },

    '& thead > tr > th': {
      borderBottom: 'none',
    },

    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: '#f8f9fa',
    },
  },

  '& .formio-component-editgrid': {
    '> .btn': {
      width: '20%',
      minHeight: 40,
      marginBottom: 10,
    },
  },

  '& .entities-grid ': {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,

    '> .list-group': {
      gap: 10,
      marginBottom: 0,

      '> .list-group-item > .editgrid-actions': {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,

        '> .btn': {
          width: '50%',
          minHeight: 40,
        },

        '> .btn-danger': {
          backgroundColor: '#dedede',
          color: 'black',
          border: 'none',
        },
      },
    },

    '& button[ref="editgrid-entities-addRow"]': {
      width: '100%',
      minHeight: 50,
      fontSize: '1.2rem',
    },
  },

  '& .formio-select-autocomplete-input': {
    display: 'none',
  },

  '& li[ref="editgrid-entities-row"]': {
    border: 'none',
    padding: '3rem',
    borderRadius: 4,
    boxShadow: '0px 0px 11px 0px #e9e9e9',

    '& div[ref="component"]:not(:last-child)': {
      marginBottom: 15,
      '& .formio-component-htmlelement': {
        marginBottom: 0,
      },
    },

    '& .editgrid-title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'bold',

      '& .btn-group': {
        display: 'flex',
        gap: 10,

        '& .btn': {
          borderRadius: 5,
          fontSize: '1rem',
          minWidth: 65,
        },
      },
    },
  },

  '& .formio-component-panel > .card': {
    border: 'none !important',
    '& div[ref="header"]': {
      border: 'none',
      borderRadius: 4,
      padding: 20,
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
  },

  '& .btn-primary, & .btn-primary:hover, & .btn-primary:focus, & .btn-primary:active, & .choices__list--multiple .choices__item':
  {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    boxShadow: 'none !important',
  },

  '& .choices__list--dropdown': {
    borderRadius: '0.25rem',
  },

  '& .submissionButtons > div': {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
    '& button': {
      fontSize: 14,
      padding: '0.5rem 1.3rem',
    },
  },

  '& input[type="text"]:not(.choices__input), & .form-control': {
    padding: 12,
    height: 'auto',
    border: '1px solid #D4D4D4',
    background: '#FDFDFD',
    '&:focus': {
      boxShadow: 'none',
      border: '1px solid #D4D4D4',
    },
    '&.is-invalid': {
      borderColor: '#dc3545',
    },
  },

  '& .warningAlert': {
    background: '#FFF3CD',
    border: '1px solid #FFC107',
    borderRadius: 5,
    padding: 12,

    '& *': {
      marginBottom: 0,
    },

    '&.formio-hidden': {
      display: 'none',
    },
  },

  '& .uploadMessage': {
    padding: 12,
    border: '1px solid #E5E5E5',
    borderRadius: 5,
    background: '#FDFDFD',
  },
  '& .structureChartLink': {
    color: '#098375',
  },
});

export {
  StyledHeader,
  StyledHeaderLink,
  StyledLink,
  StyledForm,
  StyledNavBar,
  StyledButton,
  StyledBody,
  StyledShareButton,
  StyledSubmittedPage,
  StyledLoadingForm,
};