import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureFlag } from '../../models/featureFlag';

export type TFeatureFlagsStore = {
    flags: FeatureFlag[];
}

const initialState:TFeatureFlagsStore = {
  flags: [],
};

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlags: (state, action: PayloadAction<{
        flags: FeatureFlag[]
    }>): TFeatureFlagsStore => ({
      ...state,
      flags: action.payload.flags,
    }),
  },
});

export const { actions } = featureFlagsSlice;
