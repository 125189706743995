import _default, { makeStyles } from 'theme';

const {
  palette,
} = _default;

const useStyles = makeStyles(
  () => ({
    cutOffTime: {
      display: 'flex',
      alignItems: 'center',
    },
    crossIcon: {
      fontSize: 12,
      color: 'rgb(185, 86, 86)',
      marginRight: 10,
    },
    tickIcon: {
      fontSize: 12,
      color: palette.primary.main,
      marginRight: 10,
    },
    timeZone: {
      fontSize: 11,
      textTransform: 'uppercase',
    },
  }), { name: 'CutOffDetails' },
);

export default useStyles;
