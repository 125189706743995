import { Box } from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import { useStyles } from './DrawerBackdrop.styles';

interface IProps {
  display: boolean;
  children: JSX.Element[] | JSX.Element,
  width?: string,
  backgroundColor?: string,
  className?: string,
}
const DrawerBackdrop: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();
  const {
    display, children, width, backgroundColor, className,
  } = props;
  if (!display) return null;
  return (
    <Box className={clsx(styles.drawerOverlay, className)} style={{ width: width || '35%', backgroundColor: backgroundColor || 'rgba(255,255,255,0.7)' }}>
      <Box className={styles.content}>
        { children }
      </Box>
    </Box>
  );
};

export default DrawerBackdrop;
