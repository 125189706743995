import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    entity: {
      maxWidth: '130px!important',
    },
    reference: {
      maxWidth: '250px !important',
    },
  }),
  { name: 'TransactionsDisplayTable' },
);

export default useStyles;
