import React from 'react';
import { SameTradeResponse } from '@alpha/fx-dtos';
import Table from '../../Table/Table';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';

interface IProps{
    paymentDetails: SameTradeResponse[]
}

const PaymentDetailsTable:React.FC<IProps> = (props:IProps) => {
  const { paymentDetails } = props;
  const columns: ITableColumn[] = [
    {
      header: 'Trade ID',
      accessor: 'tradeId',
    },
    {
      header: 'Buy Currency',
      accessor: 'buyCurrency',
    },
    {
      header: 'Buy Amount',
      accessor: 'buyAmount',
    },
    {
      header: 'Sell Currency',
      accessor: 'sellCurrency',
    },
    {
      header: 'Sell Amount',
      accessor: 'sellAmount',
    },
    {
      header: 'Fixed Side',
      accessor: 'fixedSide',
    },
  ];
  return (
    <Table columns={columns} data={paymentDetails} />
  );
};

export default PaymentDetailsTable;
