const role = [
  'READ_ONLY_REPORTING',
  'BENEFICIARY_INPUTTER',
  'BENEFICIARY_APPROVER',
  'BENEFICIARY_APPROVER_OWN',
  'PAYMENTS_INPUTTER',
  'PAYMENTS_APPROVER',
  'PAYMENTS_APPROVER_OWN',
  'IAT_INPUTTER',
  'IAT_APPROVER',
  'IAT_APPROVER_OWN',
  'FX_IAT',
  'ONBOARDER',
  'INVOICE_MANAGER',
  'ENTITY_VIEWER',
  'ENTITY_MANAGER_ADD_ALL_PERMS',
];

const getRoles = (total: number) => {
  const totalToReturn = total > Object.keys(role).length ? Object.keys(role).length % total : total;
  const roles = [];
  for (let i = 0; i < totalToReturn; i += 1) {
    roles.push(
      role[i],
    );
  }
  return roles;
};

export default getRoles;
