import { User } from '@alpha/profile-dtos';
import { faAt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import useStyles from './UserDetails.styles';

interface IProps {
  userProfile?: User | undefined,
  loading?: boolean,
}

const UserDetails: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();
  const { userProfile, loading } = props;
  const handleNames = () => userProfile?.firstName.substr(0, 1).concat(userProfile?.lastName.substr(0, 1));
  return (
    <>
      <div className={styles.container}>
        {
          userProfile?.firstName && userProfile?.lastName
          && (
            <div className={styles.circle}>
              <p><b >{handleNames()}</b></p>
            </div>
          )
        }
        <div className={styles.infoList}>
          <div  className={styles.name}>
            {`${loading ? '' : userProfile?.firstName} ${loading ? '' : userProfile?.lastName}`}
          </div>
          <div className={styles.info}>
            {
              !loading
              && (
                <FontAwesomeIcon className={styles.icons} icon={faAt} />
              )
            }
            <span>
            {`${loading ? '' : userProfile?.email}`}
            </span> 
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
