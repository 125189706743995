import {
  Button,
  styled,
} from '@mui/material';

const StyledModalContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  paddingBottom: 30,

  '& .input-field': {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    gap: 10,
  },
});

const StyledButton = styled(Button)({
  fontSize: 12,
  letterSpacing: 1.6,
  alignSelf: 'center',
  padding: '10px 18px',
});

const StyledFooter = styled('div')({
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  '.MuiTypography-root': {
    marginTop: 20,
  },
});

const StyledCloseButton = styled(Button)({
  right: 10,
  top: 10,
  justifyContent: 'flex-end',
  position: 'absolute',
  color: '#5c5c5c',
  '.MuiTouchRipple-root': {
    display: 'none',
  },
  '&:hover': {
    backgroundColor: 'inherit',
  },
  ':focus': {
    outline: 'none',
  },
});

const StyledChipContainer = styled('div')({
  display: 'flex',
  gap: 10,
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  border: '1px solid #d7d7d7',
  borderRadius: 4,
  minHeight: 50,
  padding: '5px 5px',

  '>.MuiChip-root': {
    fontSize: 12,
    fontWeight: 600,
  },
});

export {
  StyledButton,
  StyledHeader,
  StyledFooter,
  StyledChipContainer,
  StyledModalContent,
  StyledCloseButton,
};
