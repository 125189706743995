import React, { useState } from 'react';
import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@mui/material';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import { datadogRum } from '@datadog/browser-rum';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { faEye, faFileCheck, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import Menu from 'components/Menu/Menu';
import useActionDropdown from '../../../../../hooks/useActionDropDown';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useProfile from '../../../../../hooks/useProfile';
import BeneficiariesService from '../../../../../services/Beneficiaries/beneficiaries.service';
import { useStyles } from './ActionDropDown.styles';

interface IProps {
  beneficiary: BeneficiaryDto;
  setSelectedBeneficiary: React.Dispatch<
    React.SetStateAction<BeneficiaryDto | undefined>
  >;
  canDelete: boolean;
}

const ActionDropDown: React.FC<IProps> = ({
  beneficiary,
  setSelectedBeneficiary,
  canDelete,
}: IProps) => {
  let approveRejectViewText: string;
  let approveRejectViewIcon: IconDefinition;

  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyles();

  const {
    open,
    menuCloseCount,
    displayMultiSelect,
    openModal,
    setMenuCloseCount,
    setOpen,
    setDisplayMultiSelect,
    setOpenModal,
    handleRenderConfirmationModal,
  } = useActionDropdown();

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    setDisplayMultiSelect(false);
    setMenuCloseCount(0);
  };

  const sb = useAlphaSnackbar();

  const handleDeleteDraftBene = async (
    beneId: string,
    accountId?: string,
  ): Promise<void> => {
    setLoading(true);
    try {
      await BeneficiariesService.deleteDraftBeneficiary(beneId, accountId);
      setOpenModal(false);
      window.location.reload();
      sb.trigger('Successfully deleted beneficiary', 'success');
    } catch {
      sb.trigger('There was an error deleting this beneficiary', 'error');
    } finally {
      setLoading(false);
    }
  };

  const { userProfile } = useProfile();
  let authorizedView: boolean;

  if (userProfile?.id === beneficiary.createdById) {
    authorizedView = beneficiary.userCanApproveOwn || false;
  } else {
    authorizedView = beneficiary.userCanApprove || false;
  }

  if (authorizedView) {
    approveRejectViewText = 'Approve / Reject';
    approveRejectViewIcon = faFileCheck;
  } else {
    approveRejectViewText = 'View';
    approveRejectViewIcon = faEye;
  }

  const menuItems = [
    {
      id: 'action-bene-pending-approve-reject',
      content: <Typography>{approveRejectViewText}</Typography>,
      onClick: () => {
        if (authorizedView) {
          datadogRum.addAction('APPROVE/REJECT CLICKED');
        }
        setSelectedBeneficiary(beneficiary);
      },
      icon: approveRejectViewIcon,
      underline: true,
    },
  ];

  if (canDelete) {
    menuItems.push({
      id: 'action-bene-pending-delete',
      content: <Typography>Delete</Typography>,
      onClick: handleRenderConfirmationModal,
      icon: faTrash,
      underline: false,
    });
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box data-testid={`action-${beneficiary.id}`} className={classes.wrapper}>
        <Menu
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={menuCloseCount === 1}
          disableHoverEffect={displayMultiSelect}
          onClose={handleClose}
          buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
          buttonTitle=""
          icon={faEllipsisH}
        >
          {menuItems.map((item) => (
            <MenuDropdownItem key={item.id} setOpen={setOpen}>
              {item}
            </MenuDropdownItem>
          ))}
        </Menu>
        <DecisionModal
          open={openModal}
          title="Are you sure you want to delete this beneficiary?"
          subtitle="This is irreversible and the beneficiary will be permanently deleted."
          actionButtonText="DELETE"
          handleActionButton={() => handleDeleteDraftBene(
            beneficiary.accountBeneId,
            beneficiary?.accountId,
          )}
          closeButtonText="No, go back"
          handleClose={() => setOpenModal(false)}
          icon={faExclamationTriangle}
          loading={loading}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default ActionDropDown;
