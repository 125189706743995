import { datadogRum } from '@datadog/browser-rum';
import { useState } from 'react';

const useBody = () => {
  const [drawer, setDrawer] = useState<'IAT' | 'AccountDrawer' | false>(false);

  const handleOpenDrawer = (_drawer: 'IAT' | 'AccountDrawer' | false) => {
    switch (_drawer) {
      case 'IAT':
        datadogRum.addAction('IAT CLICKED');
        setDrawer('IAT');
        return;
      case 'AccountDrawer':
        setDrawer('AccountDrawer');
        return;
      case false:
      default:
        setDrawer(false);
    }
  };

  return {
    drawer,
    handleOpenDrawer,
  };
};

export default useBody;
