import _default, { makeStyles } from 'theme';

const {
  palette, typography,
} = _default;

const useStyles = makeStyles(
  () => ({
    pageRow: {
      display: 'flex',
      width: '100%',
      margin: 'auto',
      justifyContent: 'space-between',
      background: '#FAFAFA',
      paddingTop: '3rem',
      paddingBottom: '3rem',
      flexWrap: 'wrap',
      flexDirection: 'row-reverse',
      gap: 25,
    },
    tableHolder: {
      display: 'block',
      flexGrow: 1,
      flexBasis: '55%',
    },
    detailsHolder: {
      flexGrow: 1,
      display: 'block',
      minWidth: '300px',
      height: 'max-content',
      padding: 35,
    },
    tableTitle: {
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold,
      fontSize: '1.3rem',
      marginBottom: '1.5rem',
    },
    header: {
      fontSize: '13px',
    },
    entity: {
      fontSize: '1.1rem',
    },
    cell: {
      fontWeight: typography.fontWeightRegular,
      fontSize: '1.1rem',
      paddingTop: '12px !important',
      paddingBottom: '12px !important',
    },
    permissionButton: {
      all: 'unset',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& svg': {
        marginLeft: 5,
      },
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2em',
    },
    status: {
      textAlign: 'center',
    },
  }),
  { name: 'UserPage' },
);

export default useStyles;
