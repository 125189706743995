import {
  call, putResolve, takeLatest,
} from 'redux-saga/effects';
import { FeatureFlag } from '../../models/featureFlag';
import AuthorizationService from '../../services/Authorization/authorization.service';
import { actions } from './reducer';

async function getFeatureFlags(): Promise<FeatureFlag[]> {
  const result = await AuthorizationService.getFeatureFlagsConfig();
  return result;
}

export function* watchGetFeatureFlagsSaga() {
  yield takeLatest('GET_FEATURE_FLAGS_REQUEST', getFeatureFlagsSaga);
}

export function* getFeatureFlagsSaga() {
  const response:FeatureFlag[] = yield call(getFeatureFlags);
  if (response && response?.length > 0) {
    yield putResolve(actions.setFeatureFlags({ flags: response }));
  }
}
