import { CurrencyAccountSearchDto } from '@alpha/currency-accounts-dtos';
import { createContext, Dispatch, SetStateAction } from 'react';

export type TTransactionContextType =
  | {
      currencyAccountTotal: number | undefined;
      setCurrencyAccountTotal: Dispatch<SetStateAction<number | undefined>>;
      currencyAccountsOptions: CurrencyAccountSearchDto[] | undefined;
      setCurrencyAccountsOptions: Dispatch<
        SetStateAction<CurrencyAccountSearchDto[] | undefined>
      >;
      allowedAccounts: Record<string, string>[];
      selectedAccounts: Record<string, string>[];
      currencyAccountIds: string[] | undefined;
      setCurrencyAccountIds: Dispatch<SetStateAction<string[]>>;
      entitiesSelected: Set<string>;
      setEntitiesSelected: Dispatch<SetStateAction<Set<string>>>;
    }
  | undefined;

export const TransactionContext = createContext<TTransactionContextType>(undefined);
