import {
  Card, CardActionArea, CardContent, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import clsx from 'clsx';
import useStyles from './EntryPointWidget.styles';

interface IProps {
    onClick: () => void;
    page: string;
    icon:any
    testId?: string;
}

const EntryPointCard: React.FC<IProps> = (props: IProps) => {
  const {
    page,
    onClick,
    icon,
    testId = 'entry-point-card-',
  } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.cardRoot, 'dd-privacy-allow')} elevation={0}>
      <CardActionArea onClick={onClick} className={classes.card}>
        <CardContent className={classes.cardContent}>
          <FontAwesomeIcon className={classes.cardIcon} icon={icon} />
          <Typography className={classes.cardText} data-testid={testId + page}>
            {page}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
export default EntryPointCard;
