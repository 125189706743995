import React, { FC, memo } from 'react';
import { IModalProps } from './Modal.interfaces';
import { StyledDiv, StyledModal } from './BaseModal.styles';

const BaseModal: FC<IModalProps> = memo((props) => {
  const { className = '', ...rest } = props;

  return (
    <StyledModal className={className} {...rest}>
      <StyledDiv>{props.children}</StyledDiv>
    </StyledModal>
  );
});

export default BaseModal;
