import { makeStyles } from 'theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '40px',
    '& .currencyHeader': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > div': {
        borderRight: 'none',
        marginRight: 0,
      },
      '& > button': {
        height: '50px',
      },
      '& svg': {
        color: '#808080',
        fontSize: '18px',
      },
    },
    '& .stats': {
      display: 'flex',
      '& > div:first-of-type': {
        marginRight: '64px',
      },
      '& .boldStat': {
        '& p': {
          fontWeight: 'bold',
        },
      },
    },
  },
}, { name: 'fundingAccountSummary' });

export default useStyles;
