import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    beneficiaryWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '12px 24px 12px 24px',
      borderRadius: 5,
      border: '1px solid rgb(227, 227, 227)',
      marginBottom: 16,
      cursor: 'default',
    },
    currentbeneficiarytWrapper: {
      backgroundColor: 'rgb(244, 244, 244)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '12px 24px 12px 24px',
      borderRadius: 5,
      border: '1px solid rgb(227, 227, 227)',
      marginBottom: 16,
      cursor: 'default',
    },
    beneficiaryNameAndCurrencyWrapper: {
      boxShadow: '0px',
    },
    beneficiaryName: {
      fontSize: 16,
      fontWeight: typography.fontWeightSemiBold,
      textAlign: 'justify',
    },
    selectBeneButton: {
      width: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'block',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    detailsIcons: {
      color: '#c1c1c1',
      marginRight: '12px',
    },
    beneficiaryCurrencyCode: {
      color: '#222222',
      letterSpacing: '0.7px',
      fontSize: 14,
      fontWeight: typography.fontWeightLight,
      paddingLeft: '6px',
    },
    beneficiaryFriendlyName: {
      fontSize: '12px',
      fontWeight: typography.fontWeightRegular,
      color: '#7B7B7B',
      paddingLeft: '0px',
      width: 'fit-content',
    },
    beneficiaryFurtherDetails: {
      fontSize: 14,
      fontWeight: 400,
      color: '#333333',
      opacity: '0.8',
      paddingBottom: '4px',
    },
    button: {
      fontSize: 12,
      letterSpacing: 1.5,
      fontWeight: typography.fontWeightSemiBold,
      color: 'rgb(105, 105, 105)',
    },
    buttonIcon: {
      marginRight: 13,
    },
    beneficiaryAccount: {
      marginRight: 18,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        fontSize: 14,
        marginRight: 18,
        color: 'rgb(51, 51, 51)',
      },
    },
    currencyDivider: {
      minHeight: '25px',
      height: '100%',
      width: '0px',
      borderBottom: 'none',
      borderLeft: '1px solid rgba(112, 112, 112, 0.12)',
      margin: '0 12px',
    },
    currencyBox: {
      cursor: 'default',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    names: {
      display: 'block',
      width: '60%',
      paddingBottom: '5px',
    },
    lowerBox: {
      display: 'flex',
      paddingTop: '16px',
    },
    accountDetailsBox: {
      display: 'inline',
      width: '100%',
      textAlign: 'left',
      whiteSpace: 'pre-wrap',

    },
    imageHolder: {
      display: 'inline-block',
      margin: '0 8px 0 0',
      width: '16px',
      height: '16px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '100%',
    },
    moreInfoButton: {
      backgroundColor: 'transparent',
      border: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      display: 'block',
      paddingLeft: '0px',
      paddingRight: '0px',
    },

    moreInfoBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      cursor: 'default',
    },

    moreInfoText: {
      color: '#098375',
      textDecoration: 'underline',
      font: 'normal normal 600 12px/24px',
      opacity: '100%',
    },
    '@global': {
      '.MuiAccordion-root': {
        boxShadow: 'none',
        width: '500px',
        backgroundColor: 'rgba(255, 0, 0, 0)',
        '&::before': {
          visibility: 'hidden',
        },
      },
      '.MuiAccordionPanelSummary-root': {
        padding: '0px 24px 0px 0px',
      },
      '.MuiAccordionSummary-content': {
        display: 'block',
      },
      '.MuiAccordionDetails-root': {
        padding: 'inherit',
      },
    },
    detailsBox: {
      width: '100%',
    },
  }), { name: 'SelectBeneficiaryDrawer' },
);

export default useStyles;
