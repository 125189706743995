import { Box, Drawer } from '@mui/material';
import styled from '@mui/styled-engine';
import theme from 'theme';

const { typography } = theme;

const StyledDrawer = styled(Drawer)({
  '& > .MuiDrawer-paper': {
    paddingTop: 58,
    minWidth: 600,
    width: '35%',
  },
});

const StyledHeader = styled(Box)({
  padding: '116px 53px 0 53px',
  '& h2': {
    lineHeight: '30px',
    fontSize: 22,
    fontWeight: typography.fontWeightLight,
  },
  '& span': {
    color: '#34343480',
    letterSpacing: 2,
    fontSize: 12,
    fontWeight: typography.fontWeightRegular,
  },
});

const StyledCloseButton = styled('button')({
  position: 'absolute',
  top: 0,
  right: 0,
  margin: '88px 24px',
  display: 'inline-block',
  border: 0,
  backgroundColor: 'inherit',
  '&:hover': {
    cursor: 'pointer',
  },
});

const StyledLineBreak = styled('hr')({
  backgroundColor: '#70707033',
  width: '100%',
  height: 1,
  border: 'none',
});

export {
  StyledDrawer, StyledHeader, StyledCloseButton, StyledLineBreak,
};
