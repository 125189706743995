import React, { useEffect } from 'react';
import { GridValidRowModel, GridColumns } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import {
  GetAllUserRoleRequestsByStatusRequest,
  UserRoleRequestResponse,
  Response,
  RequestedUserRolesStatus,
} from '@portal/user-types';
import { CSSTransition } from 'react-transition-group';
import CollapsibleCard from '../../../../../components/CollapsibleCard';
import DataTable from '../../../../../components/Table/DataTable';
import useDataTable from '../../../../../hooks/useDataTable';
import routes from '../../../../../routes.path';
import useStyles from '../index.styles';
import RequestedRoleDescription from '../RequestedRoleDescription';
import getStatusVariant from '../../../utils/getStatusVariant';
import UserManagementService from '../../../../../services/UserManagement/userManagement.service';
import InReviewDropdown from './InReviewDropdown';
import { IUserTableParams, UserTables } from '../../../context/index.interfaces';

const InReviewTable = (
  { disableVirtualization = false, search, setTablesCount }: IUserTableParams,
) => {
  const { tableColumn, tableColumnDisabled } = useStyles();

  const {
    rows, page, setPage, isLoading, totalRowCount,
  } = useDataTable<
  GetAllUserRoleRequestsByStatusRequest,
  Response<UserRoleRequestResponse[]>
  >(
    UserManagementService.getAllUserRoleRequestsByStatus, {
      status: [RequestedUserRolesStatus.PENDING_REVIEW],
    },
    search,
  );

  const pendingApprovalColumns = React.useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: 'userName',
        headerName: 'Username',
        sortable: false,
        flex: 0.25,
        renderCell: ({ row: { userId, isNewUser }, value }) => (isNewUser
          ? <p className={tableColumnDisabled}>{value}</p>
          : <Link className={tableColumn} to={`${routes.userManagement.userRoute(userId)}`}>{value}</Link>
        ),
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 0.65,
        sortable: false,
        renderCell: ({
          row: {
            isNewUser,
            userName,
            accountName,
            requestedRoles,
            currentRoles,
            currentApprovalLevel,
            requestedApprovalLevel,
          },
        }) => (
          <RequestedRoleDescription
            isNewUser={isNewUser}
            userName={userName}
            accountName={accountName}
            requestedRoles={requestedRoles}
            currentRoles={currentRoles}
            currentApprovalLevel={currentApprovalLevel}
            requestedApprovalLevel={requestedApprovalLevel}
          />
        )
        ,
      },
      {
        field: 'requestDate',
        headerName: 'Request Date',
        flex: 0.2,
        sortable: false,
        renderCell: ({ row: { created } }) => (
          <p>{moment(created).format('DD/MM/YYYY h:mma')}</p>
        ),
      },
      {
        field: 'status',
        headerName: 'User Status',
        flex: 0.2,
        sortable: false,
        renderCell: ({ value }) => getStatusVariant(value || 'default'),
      },
      {
        field: 'userId',
        headerName: 'Action',
        type: 'actions',
        flex: 0.1,
        sortable: false,
        getActions: ({ row: { userId, isNewUser } }) => (!isNewUser ? [] : [
          <InReviewDropdown user={userId} />,
        ]),
      },
    ], [tableColumn, tableColumnDisabled],
  );

  useEffect(() => {
    setTablesCount((tablesCount) => ({
      ...tablesCount,
      [UserTables.IN_REVIEW]: totalRowCount,
    }));
  }, [setTablesCount, totalRowCount]);

  if (totalRowCount === 0 && search.length > 0) return null;

  return (
    <CSSTransition
      appear
      in
      timeout={1000}
      classNames="fade-up"
    >
      <CollapsibleCard
        title={`In Review (${totalRowCount})`}
        subHeader="All requests that have been approved and are currently being reviewed by us"
        icon={faSpinner}
      >
        <DataTable
          columns={pendingApprovalColumns}
          data={(rows?.data || [])}
          page={page}
          pageSize={10}
          setPage={setPage}
          isLoading={isLoading}
          totalRowCount={totalRowCount}
          getRowId={(row: any) => row.id}
          disableVirtualization={disableVirtualization}
          noRowsLabel="You currently do not have any users in review"
        />
      </CollapsibleCard>
    </CSSTransition>
  );
};

export default InReviewTable;
