import _default, { makeStyles } from 'theme';
import shade from 'theme/utils';

const { palette, typography } = _default;

export const useStyles = makeStyles(
  () => {
    const outlinedButtonColour = palette.background.default;
    const primaryButtonColor = palette.primary.main;

    const hoverOutlinedButtonColour = shade(palette.secondary.light, 1);
    const hoverPrimaryButtonColour = palette.primary.dark;

    return {
      entitiesButton: {
        borderRadius: '5px 0 0 5px',
        fontSize: '11px',
        alignSelf: 'end',
        border: ({ groupView }: { groupView?: boolean }) => `1px solid ${groupView ? '#D8D8D8' : 'rgb(9 ,131, 117)'}`,
        color: ({ groupView }: { groupView?: boolean }) => (groupView ? palette.text.secondary : 'white'),
        backgroundColor: ({ groupView }: { groupView?: boolean }) =>
          groupView ? outlinedButtonColour : primaryButtonColor,
        boxShadow: 'none',
        fontWeight: ({ groupView }: { groupView?: boolean }) =>
          groupView ? typography.fontWeightRegular : typography.fontWeightSemiBold,
        '&:hover': {
          backgroundColor: ({ groupView }: { groupView?: boolean }) =>
            groupView ? hoverOutlinedButtonColour : hoverPrimaryButtonColour,
          boxShadow: 'none',
        },
      },
      groupsButton: {
        borderRadius: '0 5px 5px 0',
        fontSize: 11,
        alignSelf: 'end',
        border: ({ groupView }: { groupView?: boolean }) => `1px solid ${groupView ? 'rgb(9 ,131, 117)' : '#D8D8D8'}`,
        backgroundColor: ({ groupView }: { groupView?: boolean }) =>
          groupView ? primaryButtonColor : outlinedButtonColour,
        color: ({ groupView }: { groupView?: boolean }) => (groupView ? 'white' : palette.text.secondary),
        boxShadow: 'none',
        fontWeight: ({ groupView }: { groupView?: boolean }) =>
          groupView ? typography.fontWeightSemiBold : typography.fontWeightRegular,
        '&:hover': {
          backgroundColor: ({ groupView }: { groupView?: boolean }) =>
            groupView ? hoverPrimaryButtonColour : hoverOutlinedButtonColour,
          boxShadow: 'none',
        },
      },

      stickyHeader: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        alignItems: 'center',
        padding: '16px 10px 16px 16px',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        borderBottom: '1px solid #ececec',
      },
      stickyFooter: {
        display: 'flex',
        background: 'white',
        justifyContent: 'flex-end',
        gap: 5,
        borderTop: '1px solid #ececec',
        padding: '16px 10px 16px 5px',
        width: '100%',
        zIndex: 2,
        position: 'sticky',
        bottom: 0,
        flexShrink: 0,
        top: '300px',
      },
      headerText: {
        flexGrow: 1,
        margin: '3px',
        color: '#098375',
        fontSize: '14px',
        fontWeight: 'bold',
      },
      footerBtns: {
        fontSize: '11px',
        marginTop: '5px',
        alignSelf: 'end',
        borderRadius: '5px',
      },
      icon: {
        marginRight: '6px',
        color: palette.secondary.light,
      },
      searchContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '10px 10px 5px 10px',
        maxHeight: '49px',
        '& .MuiMenuItem-root': {
          width: '100%',
        },
      },
      searchField: {
        '& > .MuiBox-root': {
          backgroundColor: 'transparent',
        },
      },
      searchIcon: {
        color: `${shade(palette.secondary.light, 4)} !important`,
      },
    };
  },
  { name: 'Menu' },
);

export default useStyles;
