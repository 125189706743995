import React, {
  FC,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Typography } from '@mui/material';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseModal from 'components/Modals/BaseModal';
import useStyles from './styles';
import DocumentsUploadModal from './DocumentsUploadModal';
import FilesList from './FilesList';
import useSinglePaymentContext from '../../useSinglePaymentContext';
import usePaymentsContext from '../../usePaymentsContext';

const DocumentsUpload: FC = () => {
  const styles = useStyles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { singlePaymentContext } = useSinglePaymentContext();
  const { paymentsContext } = usePaymentsContext();
  const maxFileSize = 20971520;
  let sizeUsedBytes = 0;
  paymentsContext.currentPayments.forEach((payment) => {
    if (payment?.files?.length) {
      sizeUsedBytes += payment?.files?.reduce((acc, file) => acc + file.size, 0);
    }
  });
  
  const filesToUpload = useMemo(() => (
    singlePaymentContext?.currentPayment.files || []
  ), [singlePaymentContext]);

  const handleSetFiles = useCallback((files: File[]) => {
    if (singlePaymentContext) {
      singlePaymentContext.setCurrentPayment({
        ...singlePaymentContext.currentPayment,
        files: [
          ...filesToUpload,
          ...Array.from(files),
        ],
      });
    }
  }, [singlePaymentContext, filesToUpload]);

  const handleToggleModal = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);

  const onRemoveFile = useCallback((indexToRemove) => {
    if (singlePaymentContext) {
      singlePaymentContext.setCurrentPayment({
        ...singlePaymentContext.currentPayment,
        files: filesToUpload.filter((file, index) => index !== indexToRemove),
      });
    }
  }, [singlePaymentContext, filesToUpload]);

  return (
    <div className={styles.documentsUploadBox}>
      <div className={styles.documentsUploadInfo}>
        <Typography className={styles.text}>
          Supporting documents (Optional).&nbsp;
          <button type="button" className={styles.link} onClick={handleToggleModal}>
            Learn more.
          </button>
        </Typography>
        <button type="button" className={styles.link} onClick={handleToggleModal}>
          <FontAwesomeIcon
            size="lg"
            icon={faPlusCircle}
            className={styles.addIcon}
          />
          <span>Add document</span>
        </button>
      </div>
      {
        !!filesToUpload.length && (
          <FilesList filesList={filesToUpload} removeFile={onRemoveFile} />
        )
      }
      <BaseModal open={modalOpen}>
        <DocumentsUploadModal
          onClose={handleToggleModal}
          handleSetFiles={handleSetFiles}
          totalFileSizeLimit={maxFileSize}
        />
      </BaseModal>
    </div>
  );
};

export default memo(DocumentsUpload);
