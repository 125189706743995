import React, {
  FC,
  memo,
  useCallback,
  useState,
} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from '@mui/material';
import { ActionButton } from 'components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import useStyles from './styles';
import DragAndDropArea from './DragAndDropArea';
import FilesList from './FilesList';

type IProps = {
  onClose: () => void;
  handleSetFiles: (files: File[]) => void;
  totalFileSizeLimit: number;
}

const DocumentsUploadModal: FC<IProps> = ({
  onClose, handleSetFiles, totalFileSizeLimit, 
}) => {
  const [localFilesToUpload, setLocalFilesToUpload] = useState<File[]>([]);
  const [showHint, setShowHint] = useState<boolean>(false);
  const styles = useStyles();

  const onAccordionToggle = useCallback(() => {
    setShowHint(!showHint);
  }, [showHint]);

  const onSubmit = useCallback(() => {
    handleSetFiles(localFilesToUpload);
    onClose();
  }, [handleSetFiles, onClose, localFilesToUpload]);


  
  const onRemoveFile = (i: number) => {
    const newFiles = localFilesToUpload.filter((file, index) => index !== i);
    setLocalFilesToUpload(newFiles);
  }
  const onSetFiles = (files: File[]) => {
    setLocalFilesToUpload(files);
  }

  return (
    <div className={styles.modalBox}>
      <Typography className={styles.modalTitle}>
        Add supporting document
      </Typography>
      <Typography className={styles.modalSubtitle}>
        Depending on your payment, we might need further
        supporting information and may ask to see some documents.
      </Typography>
      <Accordion expanded={showHint}>
        <AccordionSummary className={styles.accordionSummary}>
          <button type="button" className={styles.link} onClick={onAccordionToggle}>
            <span className={styles.accordionIcon}>
              <FontAwesomeIcon
                size="lg"
                icon={showHint ? faCaretDown : faCaretRight}
              />
            </span>
            Why should I provide documents?
          </button>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={styles.modalSubtitle}>
            It&apos;s a good idea to provide your documents when you create a payment.
            This will help to speed up the process if we do need to see them.
            To learn more about these requested documents, contact client services
            at clientservices-abs@alphagroup.com
          </Typography>
        </AccordionDetails>
      </Accordion>
      {
        !!localFilesToUpload.length && (
          <FilesList
            filesList={localFilesToUpload}
            removeFile={onRemoveFile}
          />
        )
      }
      <DragAndDropArea
        setLocalFilesToUpload={onSetFiles}
        localFilesToUpload={localFilesToUpload}
        maxFileSize={totalFileSizeLimit}
      />
      <div className={styles.modalFooter}>
        <div className={styles.footerButton}>
          <ActionButton
            style={{ backgroundColor: '#000000' }}
            rounded
            size="large"
            onClick={onClose}
          >
            Cancel
          </ActionButton>
        </div>
        <div className={styles.footerButton}>
          <ActionButton
            rounded
            size="large"
            disabled={!localFilesToUpload.length}
            onClick={onSubmit}
          >
            Submit documents
          </ActionButton>
        </div>
      </div>
      <Button className={styles.closeButton} onClick={onClose}>
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </Button>
    </div>
  );
};

export default memo(DocumentsUploadModal);
