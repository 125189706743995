import { LedgerDto } from '@alpha/currency-accounts-dtos';
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import useSearch, { TSearchBaseResponse, TSearchParams } from '../../../hooks/useSearch';
import { TStore } from '../../../store';
import { formatNumber } from '../../../utils/currency.helpers';

const createSearchParams = (
  skip: number,
  datefrom: string,
  dateto: string,
  currencyAccountIds?: string[],
): TSearchParams => {
  const searchParams: TSearchParams = {
    baseUrl: '/ca/transactions/search',
    queryParams: {
      skip,
      datefrom,
      dateto,
      take: 10,
      sortby: 'ledgerTimestamp',
      sortorder: 'desc',
    },
    body: {
      currencyAccountIds: currencyAccountIds || [],
    },
    method: 'POST',
  };
  return searchParams;
};

export type TUseTransactions = ReturnType<typeof useTransactionsTable>;

const useTransactionsTable = (startDate: string, endDate: string) => {
  const tableSearch = useSearch();
  const selectedCurrencyAccounts = useSelector<TStore, string[]>(
    (state) => state.transactionsPage.selectedCurrencyAccounts,
  );

  const searchParams = createSearchParams(
    tableSearch.skip,
    startDate,
    endDate,
    selectedCurrencyAccounts,
  );

  const handleNewSearch = async () => {
    await tableSearch.handleNewSearch(searchParams);
  };

  const handleNewSearchMultiEntity = async () => {
    const queryParams = { ...searchParams.queryParams };
    if (tableSearch.searchText.length > 0) {
      queryParams.searchtext = tableSearch.searchText;
    }

    await tableSearch.handleNewSearch(
      {
        ...searchParams,
        queryParams,
      },
      true,
    );
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    tableSearch.setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.searchtext = e.target.value.trim();
    }
    await tableSearch.handleNewSearch(searchParams, true);
  };

  const mapResponseData = (tableData: TSearchBaseResponse) => (
    tableData?.records as LedgerDto[]
  )?.map((ledger) => ({
    ...ledger,
    friendlyId: ledger.friendlyId || '-',
    creditAmount: ledger.creditAmount || 0,
    debitAmount: ledger.debitAmount
      ? formatNumber(ledger.debitAmount, 2)
      : '-',
    runningBalance: ledger.runningBalance
      ? formatNumber(ledger.runningBalance, 2)
      : '-',
    date: ledger.date.split('-').reverse().join('/'),
    currencyAccountName: ledger.currencyAccountName || '-',
    currencyCode: ledger.currencyCode || '-',
    accountName: ledger.accountName || '-',
  }));

  return {
    handleNewSearch,
    searchParams,
    tableSearch,
    handleInputChange,
    mapResponseData,
    handleNewSearchMultiEntity,
  };
};
export default useTransactionsTable;
