import { Button } from '@mui/material';
import styled from '@mui/styled-engine';

const StyledButton = styled(Button)({
  fontSize: 12,
  letterSpacing: 1.6,
  alignSelf: 'center',
  padding: '10px 18px',
});

export default StyledButton;
