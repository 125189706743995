import React, {
  FC,
  memo,
  useCallback,
} from 'react';
import { Typography } from '@mui/material';
import FormNote from '../../../components/FormNote';
import {
  FormErrorStyled,
  MenuItemStyled,
  SelectPlaceholderStyled,
  SelectStyled,
} from './styles';
import { repeatOptions } from './constants';
import useSinglePaymentContext from '../../useSinglePaymentContext';
import FormattedRepeatLabel from './FormattedRepeatLabel';

type IProps = {
  selectedDate: string;
  selectedRepeat: string | null;
  setSelectedRepeat: (value: string) => void;
  repeatError: boolean;
  setRepeatError: (value: boolean) => void;
};

const RepeatDropdown: FC<IProps> = ({
  selectedDate,
  selectedRepeat,
  setSelectedRepeat,
  repeatError,
  setRepeatError,
}) => {
  const { singlePaymentContext } = useSinglePaymentContext();
  const showValueOrPlaceHolder = selectedRepeat || 'placeholder';

  const handleDropdownChange = useCallback((event: any) => {
    setSelectedRepeat(event.target.value);
    setRepeatError(false);
    singlePaymentContext?.setCurrentPayment({
      ...singlePaymentContext.currentPayment,
      frequency: event.target.value,
    });
  }, [setSelectedRepeat, setRepeatError, singlePaymentContext]);

  return (
    <>
      <Typography variant="subtitle1" className="label" fontSize={14}>
        Repeat
      </Typography>
      <SelectStyled
        data-testid="standingOrder-repeatDropdown"
        onChange={handleDropdownChange}
        value={showValueOrPlaceHolder}
        fullWidth
        isSelected={!!selectedRepeat}
      >
        <SelectPlaceholderStyled key="iat-placeholder" value="placeholder">
          <Typography variant="subtitle1">
            Please select
          </Typography>
        </SelectPlaceholderStyled>
        {
          repeatOptions.map((opt) => (
            <MenuItemStyled value={opt.name} key={opt.name} data-testid={`standingOrder-repeatDropdown-${opt.name}`}>
              <Typography variant="subtitle1">
                <FormattedRepeatLabel opt={opt} date={selectedDate} />
              </Typography>
            </MenuItemStyled>
          ))
        }
      </SelectStyled>
      {
        repeatError && (
          <FormErrorStyled>Please select an option</FormErrorStyled>
        )
      }
      <FormNote text="How often should this payment repeat?" />
    </>
  );
};

export default memo(RepeatDropdown);
