import { LedgerDto } from '@alpha/currency-accounts-dtos';
import { Button } from '@mui/material';
import { isNaN } from 'lodash';
import React, { useEffect } from 'react';
import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import Search from '../../../components/Organisms/Search';
import TextEllipsis from '../../../components/TextEllipsis/TextEllipsis';
import { TSearchBaseResponse } from '../../../hooks/useSearch';
import { formatNumber } from '../../../utils/currency.helpers';
import DisplayTable from './DisplayTable/DisplayTable';
import { IData } from './DisplayTable/DisplayTable.interfaces';
import useStyles from './TransactionsTable.styles';
import { TUseTransactions } from './useTransactionsTable';

export const componentTestIds = {
  DisplayTable: 'display-loader-component',
};
export interface ITransactionTable {
  testId?: string,
  componentTestId?: string,
  handleSetTransaction: (clickedTableRow: LedgerDto) => Promise<void>,
  applySearch: boolean,
  setApplySearch: React.Dispatch<React.SetStateAction<boolean>>,
}

type TTransactionsTable = Pick<TUseTransactions, 'handleInputChange' | 'searchParams' | 'tableSearch' | 'mapResponseData'>;

const TransactionsTable: React.FC<ITransactionTable & TTransactionsTable> = (
  props: ITransactionTable & TTransactionsTable,
) => {
  const {
    componentTestId,
    handleSetTransaction,
    applySearch,
    setApplySearch,
    searchParams,
    tableSearch,
    handleInputChange,
    mapResponseData,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (applySearch && tableSearch && searchParams) {
      tableSearch?.handleInitialSearch(searchParams, true);
    }
    setApplySearch(false);
  }, [applySearch]);

  const handleIdRendering = (friendlyId: string): JSX.Element | string => {
    if (friendlyId.match('L0')) {
      return friendlyId;
    } return (
      <Button
        disableRipple
        className={classes.button}
      >
        {friendlyId}
      </Button>
    );
  };

  const generateTableData = (tableData: IData[]) => (
    tableData)?.map((ledger) => ({
    ...ledger,
    friendlyId: ledger.friendlyId ? (
      handleIdRendering(ledger.friendlyId)
    ) : '-',
    creditAmount: ledger.creditAmount && !isNaN(ledger.creditAmount) ? (
      <span className={classes.creditAmount}>
        {formatNumber(ledger.creditAmount, 2)}
      </span>
    ) : (
      '-'
    ),
    debitAmount: ledger.debitAmount ? (
      <span className={classes.debitAmount}>
        {ledger.debitAmount}
      </span>
    ) : (
      '-'
    ),
    reference: ledger.reference ? (<TextEllipsis text={ledger.reference} />) : '-',
    date: ledger.date,
    entity: (<TextEllipsis text={ledger.accountName} />),
    currency: ledger.currencyCode,
    account: ledger.currencyAccountName,
    runningBalance: ledger.runningBalance,
  }));

  if (tableSearch?.loading) {
    return (
      <>
        <div className={classes.searchBar}>
          <Search
            testId="search-input"
            value={tableSearch?.searchText}
            totalItems={tableSearch?.items?.items.total || 0}
            placeholder="Search Transactions"
            onChange={handleInputChange}
          />
        </div>
        <BackdropLoader testId="loader" />
      </>
    );
  }

  return (
    <div data-testid={componentTestId} className={classes.tableWrapper}>
      <div className={classes.searchBar}>
        <Search
          testId="search-input"
          value={tableSearch?.searchText}
          totalItems={tableSearch?.items?.items.total || 0}
          placeholder="Search Transactions"
          onChange={handleInputChange}
        />
      </div>
      { tableSearch?.items?.items.records
      && (
      <DisplayTable
        componentTestId={componentTestIds.DisplayTable}
        table={{
          data: mapResponseData(tableSearch?.items?.items as TSearchBaseResponse),
          hasMore: tableSearch?.items?.hasNext,
          hasPrevious: tableSearch?.items?.hasPrevious || false,
          loading: tableSearch?.loading,
          handlePreviousPage: () => tableSearch?.handlePreviousPage(searchParams, 10, true),
          handleNextPage: () => tableSearch?.handleNextPage(searchParams, 10, true),
        }}
        tableData={generateTableData}
        handleSetTransaction={handleSetTransaction}
      />
      )}
    </div>
  );
};

export default TransactionsTable;
