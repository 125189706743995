import { StyledTabsWrapper } from 'components/Tabs';
import queryString from 'querystring';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { APMainLayout } from 'components/Layout/MainLayout';
import Dropdown from '../../components/Dashboard/Dropdown';
import useAlphaSnackbar from '../../hooks/useAlphaSnackbar';
import routes from '../../routes.path';
import BeneficiariesService from '../../services/Beneficiaries/beneficiaries.service';
import history from '../../services/history/browserHistory';
import Body from './Body/Body';
import RightAlignedCta from './RightAlignedCTA/RightAlignedCTA';

interface IProps {
  multiEntity?: boolean;
}

const Beneficiaries: React.FC<IProps> = (props: IProps) => {
  const { multiEntity } = props;
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<0 | 1 | 2>(0);
  const [createBeneficiaryOpen, setCreateBeneficiaryOpen] = useState<boolean>(false);
  const tabs: string[] = ['Approved', 'Pending', 'Rejected'];
  const breadcrumbs = [{ text: 'Home', url: routes.home }];
  const sb = useAlphaSnackbar();
  const [accounts, setAccounts] = useState<{
    value: string;
    label: string;}[]
>([]);

  useEffect(() => {
    if (multiEntity) {
      const promises = [];
      promises.push(getAccountsUserHasBeneInputterPermFor());
      Promise.all(promises);
    }
  }, []);

  useEffect(() => {
    setTabIndex(calculateTabFromUrl());
  }, [location]);

  const getAccountsUserHasBeneInputterPermFor = async (): Promise<void> => {
    try {
      const accountOptions = await BeneficiariesService.getBeneficiaryInputterEntities();
      setAccounts(
        accountOptions.map((accountOption) => ({
          value: accountOption.id,
          label: accountOption.name,
        })),
      );
    } catch {
      sb.trigger('There was an error retrieving your accounts for your user');
    }
  };

  const calculateTabFromUrl = () => {
    const parsedQueryString = queryString.decode(location.search.replace('?', ''));
    switch (parsedQueryString.tab) {
      case 'approved':
        return 0;
      case 'pending':
        return 1;
      case 'rejected':
        return 2;
      default:
        return 0;
    }
  };

  const handleTabClick = (value: number) => {
    let qs = 'approved';
    if (value === 1) {
      qs = 'pending';
    } else if (value === 2) {
      qs = 'rejected';
    }
    history.push(`${routes.beneficiaries}?tab=${qs}`);
  };

  return (
    <APMainLayout
      data-id="beneficiaries"
      title={multiEntity ? (
        <div style={{ display: 'flex' }}>
          <span className="dd-privacy-allow">
            {' '}
            Beneficiaries
            {' '}
            |
            {' '}
          </span>
          <Dropdown />
        </div>
      )
        : 'Beneficiaries'}
      breadCrumbs={breadcrumbs}
      pageTabs={(
        <StyledTabsWrapper
          testId="beneficiary-tabs"
          tabTitles={tabs}
          className="dd-privacy-allow"
          tabIndex={tabIndex}
          handleChange={handleTabClick}
        />
      )}
      rightAlignedCta={(
        <RightAlignedCta
          handleOpenCreateBeneficiaryDrawer={() => setCreateBeneficiaryOpen(true)}
          accounts={accounts}
          multiEntity={multiEntity}
        />
      )}
    >
      <>
        {
          tabIndex !== undefined
          && (
          <Body
            createBeneficiaryOpen={createBeneficiaryOpen}
            tabIndex={tabIndex}
            setCreateBeneficiaryOpen={setCreateBeneficiaryOpen}
            multiEntity={multiEntity}
            accounts={accounts}
          />
          )
        }
      </>
    </APMainLayout>
  );
};

export default Beneficiaries;
