import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    tabContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      paddingBottom: 30,
      minHeight: 'calc(100vh - 310px)',
      '& > div': {
        width: '100%',
      },
    },
    searchBar: {
      display: 'flex',
      width: '400px',
    },
    label: {
      margin: 'auto',
    },
    entity: {
      maxWidth: 120,
      wordBreak: 'break-word',
      '& p': {
        paddingRight: 30,
      },
    },
    parent: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    text: {
      fontFamily: '\'Source Sans Pro\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
      fontWeight: 600,
      letterSpacing: '1.3px',
      fontSize: '11px',
      textTransform: 'uppercase',
    },
    multiLine: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > p': {
        fontSize: '14px',
        margin: 0,
        '&:first-of-type': {
          fontWeight: 100,
          fontSize: '21px',
          marginBottom: '11px',
        },
      },
    },
    image: {
      height: '168px',
      width: '168px',
      backgroundColor: 'rgba(225, 225, 225, 0.2)',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '23px',
      '& > img': {
        height: '90px',
        width: '90px',
        padding: '6px 0 0 6px',
      },
    },
  }),
  { name: 'Body' },
);

export default useStyles;
