import {
  PaymentBatchDraftStatus,
  PaymentBatchStatus,
} from '@alpha/payments-dtos';
import { Box } from '@mui/material';
import Status from 'components/Status/Status';
import React from 'react';
import { TStatusVariants } from 'components/Status';
import { flowToBatchStatusMapping } from '../../../../../utils/batchStatus.helpers';
import useStyles from './TitleStatus.styles';

interface IProps {
  noOfApprovals: number | undefined;
  approvalsRequired: number | undefined;
  batchStatus: PaymentBatchDraftStatus | PaymentBatchStatus;
  requiresFx: boolean;
}

const TitleStatus: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const {
    noOfApprovals, approvalsRequired, batchStatus, requiresFx,
  } = props;

  const getClientFriendlyBatchStatus = (
    backendBatchStatus: PaymentBatchDraftStatus | PaymentBatchStatus,
  ) => {
    switch (true) {
      case backendBatchStatus === PaymentBatchDraftStatus.INPROGRESS:
      case backendBatchStatus === PaymentBatchStatus.PROCESSING:
        return 'In Progress';
      case backendBatchStatus === PaymentBatchDraftStatus.COMPLETE:
        return 'complete';
      case backendBatchStatus === PaymentBatchDraftStatus.APPROVED && requiresFx:
        return 'pending fx';
      case backendBatchStatus === PaymentBatchDraftStatus.REJECTED:
        return 'rejected';
      case flowToBatchStatusMapping.uploader.includes(backendBatchStatus):
        return 'Draft';
      case flowToBatchStatusMapping.approver.includes(backendBatchStatus):
        if (backendBatchStatus === PaymentBatchDraftStatus.REJECTED) {
          return 'rejected';
        }
        if (
          requiresFx
          && approvalsRequired
          && noOfApprovals === approvalsRequired
        ) {
          return 'awaiting funding';
        }
        if (approvalsRequired && noOfApprovals === approvalsRequired) {
          return 'awaiting release';
        }
        return 'pending approval';
      default:
        return backendBatchStatus;
    }
  };

  const handleApprovalStatusVariant = (): TStatusVariants => {
    if (approvalsRequired && noOfApprovals === approvalsRequired) {
      return 'success';
    }
    return 'default';
  };

  const handleBatchStatusVariant = (
    backendBatchStatus: PaymentBatchDraftStatus | PaymentBatchStatus,
  ) => {
    switch (true) {
      case backendBatchStatus === PaymentBatchDraftStatus.APPROVED && requiresFx:
        return 'orange';
      case backendBatchStatus === PaymentBatchDraftStatus.REJECTED:
        return 'error';
      case backendBatchStatus === PaymentBatchDraftStatus.INPROGRESS:
      case backendBatchStatus === PaymentBatchStatus.PROCESSING:
        return 'warning';
      case backendBatchStatus === PaymentBatchDraftStatus.COMPLETE
        || backendBatchStatus === PaymentBatchDraftStatus.RELEASED:
        return 'success';
      case flowToBatchStatusMapping.approver.includes(backendBatchStatus):
        return 'info';
      default:
        return 'default';
    }
  };

  return (
    <Box className={classes.titleStatusWrapper}>
      {flowToBatchStatusMapping.approver.includes(batchStatus) && (
        <Status
          className={classes.titleStatus}
          variant={handleApprovalStatusVariant()}
        >
          {`${noOfApprovals}/${approvalsRequired}`}
        </Status>
      )}
      <Status
        className={classes.titleStatus}
        variant={handleBatchStatusVariant(batchStatus)}
      >
        {getClientFriendlyBatchStatus(batchStatus)}
      </Status>
    </Box>
  );
};

export default TitleStatus;
