import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import theme from 'theme';

const { palette } = theme;

export const StyledAppMenuList = styled('ul')({
  margin: 0,
  padding: 0,
});

export const StyledNavLink = styled(NavLink)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  textDecoration: 'none',
  padding: '0 16px',
  transition: 'background-color .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out',
  height: 48,
  letterSpacing: 1,
  textTransform: 'uppercase',
  color: '#b8c7ce',
  fontSize: 12,
  marginBottom: -1,
  border: '1px solid rgba(0, 0, 0, 0.125)',
  borderLeft: '3px solid transparent',

  '&.active': {
    color: '#fff',
    backgroundColor: 'inherit',
    borderLeftColor: '#00a49a',
    boxShadow: 'inset 0px 1px 7px -2px rgba(0, 0, 0, 0.14)',
  },

  '&:hover:not(.active)': {
    color: '#fff',
    backgroundColor: palette.primary.main,
  },
});
