import { APMainLayout } from 'components/Layout/MainLayout';
import { StyledTabsWrapper } from 'components/Tabs';
import React, { useEffect, useState } from 'react';
import Dropdown from '../../../components/Dashboard/Dropdown';
import routes from '../../../routes.path';
import history from '../../../services/history/browserHistory';
import Body from './Body/Body';
import useBody from './Body/useBody';
import useStyles from './index.styles';

const OutstandingActions: React.FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const breadcrumbs = [{ text: 'Home', url: routes.home }];
  const styles = useStyles();
  const {
    calculateUrlFromIndex,
    setTablesTabsTitles,
    tabTitles,
    userAccountsLoading,
    selectedAccounts,
    allowedAccounts,
    getDashboardTablesCount,
    tableSizeRedux,
  } = useBody();

  const handleTabClick = (value: number) => {
    setSelectedTabIndex(value);
    history.push(`${routes.dashboard.outstanding}?tab=${calculateUrlFromIndex(value)}`);
  };

  useEffect(() => {
    function onKeyup(e: any) {
      if (e.key === 'Escape') {
        history.push(`${routes.dashboard.base}?tab=${history.location.search.split('=')[1]}`);
      }
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, []);

  useEffect(() => {
    if (!userAccountsLoading && (
      selectedAccounts.length > 0 || allowedAccounts.length > 0
    )) {
      getDashboardTablesCount();
    }
  }, [selectedAccounts, allowedAccounts, userAccountsLoading]);

  useEffect(() => {
    setTablesTabsTitles();
  }, [tableSizeRedux]);

  return (
    <div className={styles.mainLayout}>
      <APMainLayout
        data-id="outstanding"
        title={(
          <div style={{ display: 'flex' }}>
            <span>
              {' '}
              Outstanding actions
              {' '}
              |
              {' '}
            </span>
            <Dropdown />
          </div>
      )}
        breadCrumbs={breadcrumbs}
        pageTabs={(
          <StyledTabsWrapper
            testId="outstanding-dashboard-tabs"
            tabTitles={tabTitles}
            tabIndex={selectedTabIndex}
            handleChange={handleTabClick}
          />
      )}
      >
        <Body
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
      </APMainLayout>
    </div>
  );
};

export default OutstandingActions;
