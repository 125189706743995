const getErrorJsonFromCode = (code: number) => {
  const error = [];
  switch (code) {
    case 10:
      error.push(
        {
          code: 10,
          message: 'request invalid',
        },
      );
      break;
    case 20:
      error.push(
        {
          fieldName: ['user', 'firstname'],
          code: 20,
          message: 'firstName is empty',
        },
      );
      break;
    case 30:
      error.push(
        {
          code: 30,
          message: 'couldn`t find entity ABC',
        },
      );
      break;
    case 40:
      error.push(
        {
          code: 40,
          message: 'couldn`t find user 1',
        },
      );
      break;
    case 50:
      error.push(
        {
          code: 50,
          message: 'Invalid Authy Code',
        },
      );
      break;
    case 60:
      error.push(
        {
          fieldName: ['roles', 0, 'roles', 1],
          code: 60,
          message: 'role not found',
        },
      );
      break;
    default:
      break;
  }
  return { data: null, error };
};

export default getErrorJsonFromCode;
