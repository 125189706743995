import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    button: {
      fontSize: 14,
      padding: 0,
      color: 'rgb(9, 131, 117)',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'transparent',
      },
    },
    creditAmount: {
      color: '#098375',
      fontWeight: typography.fontWeightBold,
    },
    debitAmount: {
      color: '#B95656',
      fontWeight: typography.fontWeightBold,
    },
    runningBalance: {
      fontWeight: typography.fontWeightBold,
    },
    tableWrapper: {
      position: 'relative',
      minHeight: 'calc(100vh - 420px)',
      '& table td': {
        wordWrap: 'break-word',
        maxWidth: 200,
        '&:not(:last-child)': {
          paddingRight: '20px !important',
        },
      },
    },
    searchBar: {
      display: 'flex',
      width: '400px',
    },
  }),
  { name: 'TransactionsTable' },
);

export default useStyles;
