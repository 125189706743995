import { CountryPaymentPurposesDto } from '@alpha/payments-dtos';
import React, { useEffect, useState } from 'react';
import { Flags } from 'react-feature-flags';
import { PaymentFeatureFlags } from 'domain/PaymentsMultiEntity/ManualPayments/constants';
import { Typography } from '@mui/material';
import BackdropLoader from '../../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import useAlphaSnackbar from '../../../../../../hooks/useAlphaSnackbar';
import usePaymentsContext from '../usePaymentsContext';
import useSinglePaymentContext from '../useSinglePaymentContext';
import PaymentAmount from './PaymentAmount';
import PaymentDate from './PaymentDate';
import PaymentFx from './PaymentFx';
import PaymentPurpose from './PaymentPurpose';
import SubmitButton from './SubmitButton';
import DocumentsUpload from './DocumentsUpload';
import PaymentOptionsDrawer from '../PaymentOptionsDrawer/PaymentOptionsDrawer';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import StandingOrder from './StandingOrder';
import { checkCurrencyDecimals } from 'utils/currency.helpers';

interface IProps {
  allowSepaPaymentRail: boolean;
}

const PaymentFields: React.FC<IProps> = ({
  allowSepaPaymentRail,
}: IProps) => {
  const sb = useAlphaSnackbar();
  const [datesLoaded, setDatesLoaded] = useState<boolean>(false);
  const { singlePaymentContext } = useSinglePaymentContext();
  const [showStandingOrder, setShowStandingOrder] = useState<boolean>(
    !!singlePaymentContext?.currentPayment.frequency || false,
  );
  const [selectedRepeat, setSelectedRepeat] = useState<string | null>(
    singlePaymentContext?.currentPayment.frequency || null,
  );
  const [repeatError, setRepeatError] = useState<boolean>(false);
  const [
    paymentPurposes,
    setPaymentPurposes,
  ] = useState<CountryPaymentPurposesDto>();
  const [purposesLoaded, setPurposesLoaded] = useState<boolean>(false);
  const [paymentFieldErrors, setPaymentFieldErrors] = useState<
    Record<string, string | ReactJSXElement>
  >({});
  const { paymentsContext } = usePaymentsContext();
  const [openPaymentOptionDrawer, setOpenPaymentOptionDrawer] = useState(false);

  const isFormValid = () => {
    if (singlePaymentContext && paymentPurposes) {
      if (
        singlePaymentContext.currentPayment.beneficiary
        && singlePaymentContext.currentPayment.date
        && singlePaymentContext.currentPayment.paymentAmount
        && singlePaymentContext.currentPayment.reference
        && Object.keys(paymentFieldErrors).length === 0
      ) {
        if (
          paymentPurposes?.required
          && !singlePaymentContext.currentPayment.purposeOfPaymentCode) {
          singlePaymentContext.setIsValid(false);
          return;
        }
        if (
          requiresFx
          && (!singlePaymentContext.currentPayment.fixedSide
            || !singlePaymentContext.currentPayment.debitingAccountId)
        ) {
          singlePaymentContext.setIsValid(false);
          return;
        }
        singlePaymentContext.setIsValid(true);
      } else {
        singlePaymentContext.setIsValid(false);
      }
    }
  };

  useEffect(() => {
    isFormValid();
  }, [singlePaymentContext!.currentPayment]);

  if (
    !singlePaymentContext?.currentPayment.beneficiary
    || !paymentsContext.fundingAccount) {
    return null;
  }

  const requiresFx: boolean = paymentsContext.fundingAccount.currencyCode
    !== singlePaymentContext.currentPayment.beneficiary?.currencyCode;

  const handleAddPaymentToBatch = (): void => {
    const newCurrentPayments = [...paymentsContext.currentPayments];
    if (showStandingOrder && !selectedRepeat) {
      setRepeatError(true);
      return;
    }
    if (repeatError) {
      setRepeatError(false);
    }
    if (singlePaymentContext.editRow !== undefined) {
      newCurrentPayments[singlePaymentContext.editRow] = singlePaymentContext.currentPayment;
    } else {
      newCurrentPayments.push(singlePaymentContext.currentPayment);
    }
    paymentsContext.handleSetCurrentPayments(newCurrentPayments);
    singlePaymentContext.setCurrentPayment({
      reference: '',
      date: '',
      paymentAmount: 0,
    });
    if (singlePaymentContext.editRow !== undefined) {
      sb.trigger('Updated payment row', 'success');
      singlePaymentContext.setEditRow(undefined);
    }
    setShowStandingOrder(false);
    setSelectedRepeat(null);
  };

  const handleInputChange = (event: any) => {
    const fieldName = event.target.id;
    const newCurrentPayment: any = { ...singlePaymentContext.currentPayment };
    newCurrentPayment[fieldName] = event.target.value;
    singlePaymentContext.setCurrentPayment(newCurrentPayment);

    const clonedFieldErrors = { ...paymentFieldErrors };

    if (event.target.id === 'paymentAmount') {
      const currencyCode = singlePaymentContext.currentPayment.fixedSide
      || singlePaymentContext.currentPayment.beneficiary?.currencyCode;

      const newValue = event.target.value.replaceAll(',', '');
      if (!checkCurrencyDecimals(currencyCode || '', newValue)) {
        clonedFieldErrors[fieldName] = <div>Please enter a correct amount</div>;
        setPaymentFieldErrors(clonedFieldErrors);
        return;
      }
    } else {
      const specialCharRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      const referenceRegex = new RegExp(/^(\d|[a-z]|[A-Z]|-| |'|\/|\?|:|\(|\)|\.|,|\+)+$/);

      if (event.target.id === 'reference') {
        if (!referenceRegex.test(event.target.value) && event.target.value !== '') {
          clonedFieldErrors[fieldName] = 'Please remove any special characters except /-?:().,\'+';
          setPaymentFieldErrors(clonedFieldErrors);
          return;
        }
        if (event.target.value.length > 250) {
          clonedFieldErrors[fieldName] = 'Reference must be 250 characters or less';
          setPaymentFieldErrors(clonedFieldErrors);
          return;
        }
      } else if (specialCharRegex.test(event.target.value)) {
        clonedFieldErrors[fieldName] = 'Please remove any special characters';
        setPaymentFieldErrors(clonedFieldErrors);
        return;
      }

      if (event.target.id === 'purposeOfPaymentCode' && event.target.value.length > 250) {
        clonedFieldErrors[fieldName] = 'Purpose of payment must be 250 characters or less';
        setPaymentFieldErrors(clonedFieldErrors);
        return;
      }

      if (
        event.target.value.length > 0
        && event.target.value.trim().length <= 0
      ) {
        clonedFieldErrors[fieldName] = 'Please remove any whitespace';
        setPaymentFieldErrors(clonedFieldErrors);
        return;
      }

      if (event.target.value.length === 0 && (paymentPurposes?.required && event.target.id === 'purposeOfPaymentCode')) {
        clonedFieldErrors[fieldName] = 'This field is required';
        setPaymentFieldErrors(clonedFieldErrors);
        return;
      }
    }

    delete clonedFieldErrors[fieldName];
    setPaymentFieldErrors(clonedFieldErrors);
  };

  const canShowStandingOrder = !paymentsContext.currentPayments.length || (
    paymentsContext.currentPayments.length === 1 && singlePaymentContext.editRow !== undefined
  );

  return (
    <div className="paymentFields">
      {(!datesLoaded || !purposesLoaded) && <BackdropLoader className="backdropLoader" testId="payment-fields-loader" withOverlay />}
      {
        requiresFx
        && (
          <PaymentFx
            currencyAccounts={paymentsContext.currencyAccounts}
            currentPayment={singlePaymentContext.currentPayment}
            setCurrentPayment={singlePaymentContext.setCurrentPayment}
          />
        )
      }
      <PaymentAmount
        currencyCode={singlePaymentContext.currentPayment.fixedSide
          || singlePaymentContext.currentPayment.beneficiary?.currencyCode}
        handleInputChange={handleInputChange}
        value={singlePaymentContext.currentPayment.paymentAmount || undefined}
        validation={paymentFieldErrors.paymentAmount}
        selectedDebitingAccount={paymentsContext.fundingAccount}
        requiresFx={requiresFx}
        currentPayment={singlePaymentContext.currentPayment}
        setCurrentPayment={singlePaymentContext.setCurrentPayment}
      />
      <PaymentPurpose
        paymentPurposes={paymentPurposes}
        setPaymentPurposes={setPaymentPurposes}
        setPurposesLoaded={setPurposesLoaded}
        countryCode={
          singlePaymentContext.currentPayment.beneficiary?.bankCountryCode!
        }
        accountId={
          singlePaymentContext.currentPayment.beneficiary?.accountId!
        }
        currentPayment={singlePaymentContext.currentPayment}
        value={singlePaymentContext.currentPayment.purposeOfPaymentCode}
        handleInputChange={handleInputChange}
        setCurrentPayment={singlePaymentContext.setCurrentPayment}
        validation={paymentFieldErrors.purposeOfPaymentCode}
        referenceValidation={paymentFieldErrors.reference}
      />
      <PaymentDate
        setDatesLoaded={setDatesLoaded}
        currentPayment={singlePaymentContext.currentPayment}
        debitingCurrencyCode={
          paymentsContext.fundingAccount.currencyCode!
        }
        setCurrentPayment={singlePaymentContext.setCurrentPayment}
        standingOrderChecked={showStandingOrder}
      />
      {
        canShowStandingOrder && (
          <Flags authorizedFlags={[PaymentFeatureFlags.STANDING_ORDER]}>
            <StandingOrder
              selectedDate={singlePaymentContext.currentPayment.date}
              showStandingOrder={showStandingOrder}
              setShowStandingOrder={setShowStandingOrder}
              selectedRepeat={selectedRepeat}
              setSelectedRepeat={setSelectedRepeat}
              repeatError={repeatError}
              setRepeatError={setRepeatError}
            />
          </Flags>
        )
      }
      <Flags authorizedFlags={[PaymentFeatureFlags.SUPPORTING_DOCUMENTS]}>
        {!showStandingOrder && <DocumentsUpload />}
      </Flags>
      {singlePaymentContext.currentPayment.beneficiary?.currencyCode === 'EUR' && allowSepaPaymentRail && (
        <div className="paymentOptionLink">
          <button
            onClick={() => setOpenPaymentOptionDrawer(true)}
            type="button"
            className="paymentOptionDrawerTriggerButton"
          >
            <Typography className="paymentOptionDrawerTrigger">
              Additional payment options
            </Typography>
          </button>
        </div>
      )}
      <SubmitButton
        disabled={!singlePaymentContext.isValid}
        handleAddPaymentToBatch={handleAddPaymentToBatch}
        editable={singlePaymentContext.editRow !== undefined}
      />
      <PaymentOptionsDrawer
        isOpen={openPaymentOptionDrawer}
        closeDrawer={() => setOpenPaymentOptionDrawer(false)}
        currentPayment={singlePaymentContext.currentPayment}
      />
    </div>
  );
};

export default PaymentFields;
