import { LedgerDto } from '@alpha/currency-accounts-dtos';
import { Box } from '@mui/material';
import React from 'react';
import APPagination from 'components/APPagination';
import browserSearchSVG from '../../../../assets/browserSearch.svg';
import noNotesSVG from '../../../../assets/noNotes.svg';
import BackdropLoader from '../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable, { IEmptyTable } from '../../../../components/Table/EmptyTable/EmptyTable';
import Table from '../../../../components/Table/Table';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import { IDisplayTable } from './DisplayTable.interfaces';

const DisplayTable: React.FC<IDisplayTable> = (props: IDisplayTable) => {
  const {
    table, tableData, currencyAccountId, handleSetTransaction,
  } = props;

  const columns: ITableColumn[] = [
    { header: 'Date', accessor: 'date' },
    { header: 'ID', accessor: 'friendlyId' },
    { header: 'Details', accessor: 'details' },
    { header: 'Reference', accessor: 'reference' },
    { header: 'Credit', accessor: 'creditAmount' },
    { header: 'Debit', accessor: 'debitAmount' },
    { header: 'Balance', accessor: 'runningBalance', align: 'right' },
  ];

  const emptyTableTransactions: IEmptyTable = {
    title: 'Please select an account to view associated transactions',
    subtitle: 'Account selector is located in the page header',
    icon: browserSearchSVG,
  };

  const emptyTableNoTransactions: IEmptyTable = {
    title: 'No transactions recorded between these dates',
    icon: noNotesSVG,
  };

  const emptyTableNoAccounts: IEmptyTable = {
    title: 'No currency accounts found',
    icon: noNotesSVG,
  };

  if (table.loading) {
    return (
      <BackdropLoader testId="backdrop-loader" />
    );
  } if (table.data && !table.loading) {
    if (table.data.length === 0) {
      return (
        <EmptyTable
          title={emptyTableNoTransactions.title}
          icon={emptyTableNoTransactions.icon}
        />
      );
    }
    if (!currencyAccountId) {
      return (
        <EmptyTable
          title={emptyTableNoAccounts.title}
          icon={emptyTableNoAccounts.icon}
        />
      );
    }
    return (
      <>
        <Table
          columns={columns}
          data={tableData()}
          shortRows
          handleTableRowClick={(data: LedgerDto) => { handleSetTransaction(data); }}
        />
        <Box display="block" textAlign="right">
          <APPagination
            hasPrevious={table.hasPrevious}
            hasNext={table.hasMore === true}
            handleNext={table.handleNextPage}
            handlePrevious={table.handlePreviousPage}
          />
        </Box>
      </>
    );
  }
  return (
    <EmptyTable
      title={emptyTableTransactions.title}
      subtitle={emptyTableTransactions.subtitle}
      icon={emptyTableTransactions.icon}
    />
  );
};

export default DisplayTable;
