import { Box } from '@mui/material';
import React from 'react';
import { ITableColumn } from '../../../../../services/DatabaseServices/marker.interface';

export const preProcessingColumns: ITableColumn[] = [
  {
    header: 'Beneficiary',
    accessor: 'beneficiaryName',
  },
  {
    header: 'Beneficiary IBAN',
    accessor: 'beneficiaryIban',
  },
  {
    header: 'Funding Account',
    accessor: 'fundingAccount',
  },
  {
    header: 'Debit Account',
    accessor: 'debitAccount',
  },
  {
    header: 'Purpose Of Payment',
    accessor: 'purposeOfPayment',
    className: 'dd-privacy-allow',
  },
  {
    header: 'Payment Ref',
    accessor: 'paymentRef',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box textAlign="left">Payment Date</Box>,
    accessor: 'paymentDate',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box textAlign="left">Payment Currency</Box>,
    accessor: 'paymentCurrency',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box textAlign="right">Amount</Box>,
    accessor: 'amount',
    className: 'dd-privacy-allow',
  },
];

export const actionNeededColumns: ITableColumn[] = [
  {
    header: 'Payment ID',
    accessor: 'paymentID',
  },
  {
    header: 'Payment status',
    accessor: 'paymentStatus',
  },
  {
    header: 'Beneficiary',
    accessor: 'beneficiaryName',
  },
  {
    header: 'Payment Reference',
    accessor: 'paymentRef',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box textAlign="left">Payment Date</Box>,
    accessor: 'paymentDate',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box textAlign="left">Payment Currency</Box>,
    accessor: 'paymentCurrency',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box textAlign="right">Amount</Box>,
    accessor: 'amount',
    className: 'dd-privacy-allow',
  },
];

export const postProcessingColumns: ITableColumn[] = [
  {
    header: 'Beneficiary',
    accessor: 'beneficiaryName',
  },
  {
    header: 'Purpose Of Payment',
    accessor: 'purposeOfPayment',
    className: 'dd-privacy-allow',
  },
  {
    header: 'Payment Ref',
    accessor: 'paymentRef',
    className: 'dd-privacy-allow',
  },
  {
    header: 'Payment Date',
    accessor: 'paymentDate',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box textAlign="right" style={{ width: 'max-content' }}>Fixed Amount</Box>,
    accessor: 'fixedAmount',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box textAlign="right" style={{ width: 'max-content' }}>Payment Amount</Box>,
    accessor: 'paymentAmount',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box textAlign="right" style={{ width: 'max-content' }}>Payment Status</Box>,
    accessor: 'paymentStatus',
    className: 'dd-privacy-allow',
  },
  {
    header: '',
    accessor: 'downloadButton',
  },
];

export const getColumns = (columnsType: string, isStandingOrder?: boolean) => {
  let columns = [];
  switch (columnsType) {
    case 'actionNeeded':
      columns = actionNeededColumns;
      break;
    case 'postApproval':
      columns = postProcessingColumns;
      break;
    default:
      columns = preProcessingColumns;
  }

  const hasActionsCol = columns.find((col) => col.accessor === 'actions');

  if (isStandingOrder && !hasActionsCol) {
    columns.push({
      header: <Box textAlign="right" />,
      accessor: 'actions',
    });
  }

  return columns;
};
