import React, { FC, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import {
  ActionButton,
  SecondaryButton,
} from 'components/Buttons';
import { ITimeoutProps } from './TimeoutModal.interfaces';
import { StyledTimeoutModal } from './TimeoutModal.styles';

export const TimeoutModal: FC<ITimeoutProps> = (props) => {
  const {
    testId, open, timeRemainingInSeconds, maxTimeInSeconds, logoutOnClick, continueOnClick,
  } = props;

  const timePercentage = (timeRemainingInSeconds / maxTimeInSeconds) * 100;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    <StyledTimeoutModal data-testid={testId} isOpen={open}>
      <Typography variant="h4" className="title">
        Session Timeout
      </Typography>
      <Box className="progressWrapper">
        <CircularProgress variant="determinate" value={timePercentage} className="loader" />
        <Box className="timeWrapper">
          <Typography variant="h4" className="time">
            {timeRemainingInSeconds}
          </Typography>
        </Box>
      </Box>
      <Typography variant="h6" className="detail">
        Your session will expire in
        {' '}
        <span className="strong">
          {timeRemainingInSeconds}
          {' '}
          seconds
        </span>
      </Typography>
      <Typography variant="h6" className="detail">
        Click continue to extend your session
      </Typography>
      <Box>
        <SecondaryButton size="large" className="logoutBtn" data-testid="logoutBtn" onClick={logoutOnClick}>
          Logout
        </SecondaryButton>
        <ActionButton size="large" data-testid="continueBtn" onClick={continueOnClick}>
          Continue
        </ActionButton>
      </Box>
    </StyledTimeoutModal>
  );
};
