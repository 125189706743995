import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    indicative: {
      width: '142px',
      margin: '0px 30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& > p': {
        margin: 0,
      },
      '& > p:nth-of-type(1)': {
        fontSize: '14px',
        lineHeight: '19px',
      },
      '& > p:nth-of-type(2)': {
        marginTop: '2px',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: typography.fontWeightSemiBold,
      },
    },
    greyBackground: {
      backgroundColor: '#FAFAFA',
    },
    greenBackground: {
      backgroundColor: '#C9E5E2',
    },
    liveRateLoader: {
      height: '22px',
      width: '22px',
      marginTop: '2px',
      '& > span.MuiCircularProgress-root': {
        animation: 'none',
        height: 'unset !important',
      },
    },
    rates: {
      display: 'flex',
      alignItems: 'center',
      '& p:first-child': {
        fontWeight: typography.fontWeightBold,
        fontSize: 14,
      },
      '& > p:nth-of-type(1)': {
        paddingRight: 10,
        marginRight: 10,
        borderRight: '1px solid rgba(112, 112, 112, 0.3)',
      },
    },
    icon: {
      color: '#197FAB',
      marginLeft: 5,
    },
    tooltipText: {
      width: 160,
    },
  }),
  { name: 'Indicative' },
);

export default useStyles;
