import React, { memo } from 'react';
import { useStyles } from './TextBoldLight.styles';

interface IProps {
  boldText: string | JSX.Element;
  lightText: string | JSX.Element;
}

const TextBoldLight: React.FC<IProps> = (props: IProps) => {
  const { boldText, lightText } = props;
  const classes = useStyles();

  return (
    <span className={classes.wrapper}>
      <span className="bold">
        {boldText}
      </span>
      {lightText}
    </span>
  );
};

export default memo(TextBoldLight);
