import {
  AccountWithApprovalConfig, GetAllUsersByStatusResponseDataItem,
  Role, User, UserRoleRequestResponse,
} from '@portal/user-types';
import { RolesEnum } from '@portal/user-types/dist/schema';
import React, { Dispatch, SetStateAction } from 'react';
import { Country } from 'react-phone-number-input';

export type TRole = Role & {
    isDisabled: boolean;
  };

export type TRoles = {
    [key in RolesEnum]?: TRole
  };

export enum ModalTypeEnum {
    CREATE_USER = 'CREATE_USER',
    ENTITY_ACCESS = 'ENTITY_ACCESS',
  }

export type TUser = GetAllUsersByStatusResponseDataItem | User;

export type IWizardParams = {
    user?: TUser,
    entity?: AccountWithApprovalConfig,
    submitCallback?: (callback: () => Promise<any>) => Promise<void>,
    modalType?: ModalTypeEnum
  };

type TOpenModalParams = Pick<IWizardParams, 'user' | 'entity' | 'submitCallback'> & { modalType: ModalTypeEnum }

export type UserManagementContextType = {
    title: string,
    setTitle: Dispatch<SetStateAction<string>>
    openModal: (
      {
        user, entity, submitCallback, modalType,
      } : TOpenModalParams
    ) => void,
    rolesMap: TRoles,
    roles: TRole[],
    refreshDataTable: UserTables | null,
    setRefreshDataTable: Dispatch<SetStateAction<UserTables | null>>
    countryCode?: Country
}

export enum UserTables {
    APPROVED_USERS = 'APPROVED_USERS',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    IN_REVIEW = 'IN_REVIEW'
}

export interface IUserTableParams {
  disableVirtualization?: boolean,
  setTablesCount: React.Dispatch<React.SetStateAction<{
    [key: string]: number;
  }>>;
  search: string,
  setSelectedRequests?: React.Dispatch<React.SetStateAction<UserRoleRequestResponse[]>>,
}
