import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    rateContainer: {
      marginRight: 40,
      height: '100%',
    },
    rateIcon: {
      color: '#197FAB',
      marginLeft: 5,
    },
    rateWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      '& > span': {
        fontSize: 11,
        fontWeight: typography.fontWeightSemiBold,
      },
    },
    tooltipText: {
      width: 160,
    },
    rateValues: {
      fontSize: 16,
    },
    counterRate: {
      paddingLeft: 12,
      marginLeft: 12,
      borderLeft: '1px solid rgba(112, 112, 112, 0.3)',
    },
  }),
  { name: 'BatchDetailsRate' },
);
export default useStyles;
