/* eslint-disable no-underscore-dangle */
import React, {
  Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  Typography, CircularProgress, TextField, Chip,
} from '@mui/material';
import { datadogRum } from '@datadog/browser-rum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  StyledButton,
  StyledChipContainer,
  StyledCloseButton,
  StyledFooter,
  StyledHeader,
  StyledModalContent,
} from './index.styles';
import Modal from '../../../../components/Modals/Modal';
import OnboardingService from '../../../../services/Onboarding/onboarding.service';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';

interface IShareModalProps {
    openModal: boolean;
    submissionId: string | undefined;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
  }

const ShareAccessModal = ({
  openModal = false,
  submissionId,
  setOpenModal,
}: IShareModalProps) => {
  const [loading, setIsLoading] = useState(false);

  const [emails, setEmails] = useState<string[]>([]);
  const [ownerEmail, setOwnerEmail] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState('');

  const sb = useRef(useAlphaSnackbar());

  useEffect(() => {
    async function fetchSubmission() {
      try {
        if (submissionId && openModal) {
          const { data } = await OnboardingService.getSubmission(submissionId);
          setEmails(data.data.editors);
          setOwnerEmail(data.data.owner);
        }
      } catch (e) {
        datadogRum.addError(e, {
          message: 'ERROR LOADING SUBMISSION',
        });
        sb.current.trigger('There has been an error getting your data. Please try again.');
      }
    }
    fetchSubmission();
  }, [submissionId, openModal]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emails.some(
      (existEmail) => existEmail.toLowerCase() === email.toLowerCase(),
    ) || email.toLowerCase() === ownerEmail.toLowerCase()) {
      setEmailError('This draft is already shared with this email address.');
      return false;
    }
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value.trim());
    setEmailError('');
    validateEmail(emailInput);
  };

  const handleRemove = (emailToRemove: string) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleAddEmail = () => {
    if (!validateEmail(emailInput)) {
      return;
    }
    setEmails((prevEmails) => [...prevEmails, emailInput]);
    setEmailInput('');
    setEmailError('');
  };

  const handleSendEmails = async () => {
    datadogRum.addAction('ONBOARDING FORM SEND EMAILS');
    setIsLoading(true);
    try {
      if (submissionId) {
        await OnboardingService.updateSubmission({
          formIoSubmissionId: submissionId,
          isDraft: true,
          editors: emails.map((email) => email.toLowerCase()),
          submissions: [],
        });
      }
    } catch (e) {
      datadogRum.addError(e, {
        message: 'ERROR SAVING EDITORS',
      });
      sb.current.trigger('Error Sharing Form');
    }
    setIsLoading(false);
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      separator
      header={(
        <StyledHeader>
          <Typography variant="h5">Share Access to Draft</Typography>
          <StyledCloseButton
            disabled={loading}
            onClick={() => setOpenModal(false)}
          >
            <FontAwesomeIcon data-testid="modal-close-button" size="lg" icon={faTimes} />
          </StyledCloseButton>
        </StyledHeader>
    )}
      footer={(
        <StyledFooter>
          <StyledButton
            variant="contained"
            data-testid="modal-action-button"
            disableElevation
            disabled={loading}
            type="submit"
            onClick={handleSendEmails}
            {...(loading && { endIcon: <CircularProgress data-testid="circular-loader" color="inherit" size={12} /> })}
          >
            Send Emails
          </StyledButton>
        </StyledFooter>
    )}
    >
      <StyledModalContent>
        Give access to the users you want to edit this draft.
        Please input the emails on which you will receive a link to continue this draft.
        <div className="input-field">
          <TextField
            label="Email"
            value={emailInput}
            onChange={handleEmailInput}
            variant="outlined"
            fullWidth
            error={Boolean(emailError)}
            helperText={emailError}
          />
          <StyledButton
            variant="contained"
            data-testid="modal-action-button"
            disableElevation
            disabled={loading}
            type="submit"
            onClick={handleAddEmail}
            {...(loading && { endIcon: <CircularProgress data-testid="circular-loader" color="inherit" size={12} /> })}
          >
            Add
          </StyledButton>
        </div>
        <StyledChipContainer>
          {ownerEmail && (
            <Chip
              key={ownerEmail}
              size="medium"
              label={`${ownerEmail} (Owner)`}
              variant="outlined"
            />
          )}
          {emails?.map((email) => (
            <Chip
              key={email}
              label={email}
              onDelete={() => handleRemove(email)}
            />
          ))}
        </StyledChipContainer>
      </StyledModalContent>
    </Modal>
  );
};

export default ShareAccessModal;
