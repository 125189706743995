import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { User, ApproverLevel } from '@portal/user-types';
import { RolesEnum, UserStatus } from '@portal/user-types/dist/schema';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import PermissionList from '../../components/PermissionList';
import UserActionDropdown from './UserActionDropdown';
import getStatusVariant from '../../utils/getStatusVariant';
import { UserStatusEnum } from '../../helpers/permissions';

const columns = (props: {
  classes: {[key: string]: string}
  handleDrawerToggle: () => void,
  status: UserStatus,
  user: User,
}) => ([
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    flex: 0.25,
    headerClassName: props.classes.header,
    cellClassName: props.classes.entity,
  },
  {
    field: 'roles',
    headerName: 'Permissions',
    flex: 0.25,
    sortable: false,
    headerClassName: props.classes.header,
    cellClassName: props.classes.cell,
    renderHeader: () => (
      <button data-testid="permission-toggle" className={props.classes.permissionButton} type="submit" onClick={props.handleDrawerToggle}>
        Permissions
        <FontAwesomeIcon color="#098375" icon={faInfoCircle as IconDefinition} size="lg" />
      </button>
    ),
    renderCell: ({ value, row: { id } }: { value?: RolesEnum[], row: {id: string}}) => (
      <PermissionList
        id={id}
        roles={value || []}
      />
    ),
  },
  {
    field: 'approverLevel',
    headerName: 'Approval Level',
    flex: 0.1,
    headerClassName: props.classes.header,
    cellClassName: props.classes.cell,
    sortable: false,
    valueGetter: ({ row: { approverLevel } }: {row: {approverLevel: ApproverLevel}}) => (approverLevel ?? 'N/A'),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.15,
    headerClassName: props.classes.header,
    sortable: false,
    renderCell: () => getStatusVariant(UserStatusEnum[props.status], props.classes.status),
  },
  {
    field: 'requestId',
    headerName: 'Action',
    type: 'actions',
    flex: 0.1,
    sortable: false,
    getActions: ({ row }: any) => (row.isManageable ? [
      <UserActionDropdown
        user={props.user}
        entity={row}
      />,
    ] : []),
  },
]);

export default columns;
