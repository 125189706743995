import { Box } from '@mui/material';
import { Stat } from 'components/Stat';
import React from 'react';
import useStyles from './Headers.styles';

interface IProps {
  totalInvalidPayments: number | undefined;
  totalPayments: number | undefined;
  batchErrors: string[] | undefined;
}

const Headers: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { totalInvalidPayments, totalPayments, batchErrors } = props;
  const classes = useStyles();

  const setErrors = (): string => {
    if (batchErrors && batchErrors.length > 0) {
      return batchErrors.length.toString();
    }
    if (totalInvalidPayments) {
      return totalInvalidPayments.toString();
    }
    return '-';
  };

  return (
    <Box className={classes.headers}>
      <Stat
        testId="total-payments"
        data-testid="total-payments"
        title="Total Payments"
        value={totalPayments ? totalPayments.toString() : '-'}
      />
      <Stat
        testId="invalid-payments"
        data-testid="invalid-payments"
        title={batchErrors && batchErrors.length > 0 ? 'batch errors' : 'invalid payments'}
        value={setErrors()}
      />
    </Box>
  );
};

export default Headers;
