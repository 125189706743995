import React from 'react';
import { Typography } from '@mui/material';
import useStyles from './index.styles';

interface IProps {
  number?: number,
  title: string,
  description?: string | JSX.Element,
}
const FormTitle: React.FC<IProps> = ({ number, title, description }: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="title">
          <Typography variant="h2">
            {
              number
              && (
                <span className="green">
                  {`Step ${number} `}
                </span>
              )
            }
            <span className="white">
              {title}
            </span>
          </Typography>
        </div>
        {
          description
          && (
            <div className="description white">
              <Typography variant="subtitle1">
                {description}
              </Typography>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default FormTitle;
