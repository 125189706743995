import { Beneficiary } from '@alpha/bene-dtos';
import { Box } from '@mui/material';
import React from 'react';
import APPagination, { IAPPagination } from 'components/APPagination';
import BeneficiaryItem from './BeneficiaryItem/BeneficiaryItem';
import useStyles from './SelectBeneficiaryDrawerBody.styles';

interface IProps {
  beneficiaries: Beneficiary[];
  pagination: IAPPagination;
  onClose: (event: boolean) => void;
}

const SelectBeneficiaryDrawerBody: React.FC<IProps> = (props: IProps) => {
  const {
    beneficiaries,
    onClose,
    pagination,
  } = props;
  const classes = useStyles();
  return (
    <Box data-testid="bene-drawer-body" className={classes.bodyWrapper}>
      {beneficiaries.map((beneficiary: Beneficiary) => (
        <BeneficiaryItem
          onClose={onClose}
          key={beneficiary.accountBeneId}
          beneficiary={beneficiary}
        />
      ))}
      <Box className={classes.paginationWrapper}>
        <APPagination
          data-testid="bene-drawer-pagination"
          {...pagination}
        />
      </Box>
    </Box>
  );
};

export default SelectBeneficiaryDrawerBody;
