import Alert from 'components/Alert/Alert';
import React, { FC, useMemo } from 'react';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import { FrequencyIconStyled } from 'domain/PaymentsMultiEntity/Dashboard/ScheduledTableContainer/styles';
import { faRedoAlt } from '@fortawesome/pro-light-svg-icons';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import FormattedRepeatLabel from 'domain/PaymentsMultiEntity/ManualPayments/Body/ManualPayment/PaymentFields/StandingOrder/FormattedRepeatLabel';
import { repeatOptions } from 'domain/PaymentsMultiEntity/ManualPayments/Body/ManualPayment/PaymentFields/StandingOrder/constants';
import { Stat } from 'components/Stat';
import { TStandingOrder } from 'models/payments';
import {
  DocumentsBoxStyled,
  DocumentsIconStyled,
  StandingOrdersHeaderStyled,
  StandingOrdersStyled,
  TableStyled,
  TextStyled,
  TitleStyled,
} from './styles';

type IProps = {
  standingOrders: TStandingOrder;
}

const StandingOrdersDetails: FC<IProps> = ({ standingOrders }) => {
  const title = 'Scheduled Payments';
  const infoText = 'Please review the scheduled payments below before continuing to the next screen';
  const alertText = 'The FX rate for these payments will be automatically accepted when the payment is scheduled to be sent.';
  const columns: ITableColumn[] = [
    { header: 'Beneficiary', accessor: 'beneficiary' },
    { header: 'Reference', accessor: 'reference' },
    { header: 'Documents', accessor: 'documents' },
    { header: 'Payment date', accessor: 'paymentDate' },
    { header: 'Currency', accessor: 'currency' },
    { header: 'Amount', accessor: 'amount', align: 'right' },
  ];

  const tableData = useMemo(() => {
    const opt = repeatOptions.find((item) => item.name === standingOrders.frequency);

    return [{
      beneficiary: standingOrders.beneficiaryDraft.name,
      reference: standingOrders.reference,
      documents: (
        <DocumentsBoxStyled>
          <DocumentsIconStyled icon={faFileAlt} />
          { standingOrders.documents ? standingOrders.documents.length : 0 }
        </DocumentsBoxStyled>
      ),
      paymentDate: (
        <>
          <FrequencyIconStyled icon={faRedoAlt} />
          <FormattedRepeatLabel opt={opt} date={standingOrders.startDate} />
        </>
      ),
      currency: standingOrders.fixedSide,
      amount: `${Number(standingOrders.amount).toFixed(2)} ${standingOrders.fixedSide}`,
    }];
  }, [standingOrders]);

  return (
    <StandingOrdersStyled>
      <StandingOrdersHeaderStyled>
        <div>
          <TitleStyled variant="subtitle1">{ title }</TitleStyled>
          <TextStyled variant="body1">{ infoText }</TextStyled>
        </div>
        <Stat
          title="No of payments"
          value={(
            <b>
              { [standingOrders].length }
            </b>
          )}
          alignRight
        />
      </StandingOrdersHeaderStyled>
      <Alert variant="info" text={alertText} />
      <TableStyled
        columns={columns}
        data={tableData}
        testId="scheduledPaymentsFX-table"
      />
    </StandingOrdersStyled>
  );
};

export default StandingOrdersDetails;
