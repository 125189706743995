/* eslint-disable @typescript-eslint/no-shadow */
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@mui/material';
import { faExchange } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { PaymentBatchesResponse } from '@alpha/payments-types';
import Menu from 'components/Menu/Menu';
import useActionDropdown from '../../../../hooks/useActionDropDown';
import { PaymentRouteEnum } from '../../../../models/payments';
import useStyles from '../../../Payments/Dashboard/PendingTable/DisplayTable/ActionDropDown/ActionDropDown.styles';
import routes from '../../../../routes.path';
import history from '../../../../services/history/browserHistory';
import saveCurrentPageRoute from '../../../../utils/saveCurrentPageRoute';

interface IProps {
  payment: PaymentBatchesResponse,
}

const ActionDropDown: React.FC<IProps> = (props: IProps) => {
  const { payment } = props;
  const classes = useStyles();
  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    setOpen,
  } = useActionDropdown({});

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    setMenuCloseCount(0);
  };

  const handleRedirectToBatchSummary = (): void => {
    saveCurrentPageRoute();
    history.push(
      `${routes.payments.multientity.batchSummaryRouteFunc(
        payment.accountId,
        payment.batchId,
        PaymentRouteEnum.PENDING,
      )}`,
    );
  };

  const menuItems = [
    {
      id: 'action-awaiting-fx-book',
      content: (
        <Typography data-testid={`payment-action-option-${payment.batchId}`}>
          Book Fx
        </Typography>
      ),
      icon: faExchange,
      onClick: handleRedirectToBatchSummary,
      underline: false,
    },
  ];

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box data-testid={`action-${payment.batchId}`} className={classes.wrapper}>
        <Menu
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableHoverEffect={false}
          onClose={handleClose}
          buttonProps={{ className: classes.actionButton }}
          buttonTitle=""
          icon={faEllipsisH}
        >
          {menuItems.map((item) => (
            <MenuDropdownItem key={item.id} setOpen={setOpen}>{item}</MenuDropdownItem>
          ))}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
};

export default ActionDropDown;
