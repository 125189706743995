import { ValidateBeneficiaryRequest } from '@alpha/bene-dtos';
import { BeneficiaryType } from '@alpha/bene-types';
import * as yup from 'yup';

export const initialValues: ValidateBeneficiaryRequest = {
  name: '',
  emailAddress: '',
  accountNumber: '',
  addressLine1: '',
  addressLine2: '',
  bankCountryCode: '',
  city: '',
  correspondentAccountNumber: '',
  correspondentSwift: '',
  currencyCode: '',
  friendlyName: '',
  furtherToAccountNumber: '',
  furtherToSwift: '',
  iban: '',
  nationalBankCode: '',
  nationalBankCodeType: '',
  state: '',
  swift: '',
  zip: '',
  type: BeneficiaryType.ENTITY,
  userIgnoresApplyFinancialErrors: false,
  channel: 'portal',
};

export const validation = {
  name: yup
    .string()
    .matches(RegExp('^[/A-Za-z0-9.,/?:()+‘ -]*$'), 'Special characters are not allowed')
    .min(2, 'Must be 2 characters or more')
    .max(80, 'Must be 80 characters or less')
    .required('Account Holder is required'),
  friendlyName: yup
    .string()
    .matches(RegExp('^[/A-Za-z0-9.,/?:()+‘ -]*$'), 'Special characters are not allowed'),
  addressLine1: yup
    .string()
    .matches(RegExp('^[/A-Za-z0-9.,/?:()+‘ -]*$'), 'Special characters are not allowed')
    .min(3, 'Must be 3 characters or more')
    .max(100, 'Must be 100 characters or less'),
  addressLine2: yup.string().matches(RegExp('^[/A-Za-z0-9.,/?:()+‘ -]*$'), 'Special characters are not allowed'),
  city: yup
    .string()
    .matches(RegExp('^[/A-Za-z0-9.,/?:()+‘ -]*$'), 'Special characters are not allowed')
    .min(3, 'Must be 3 characters or more')
    .max(85, 'Must be 85 characters or less'),
  zip: yup.string().matches(RegExp('^[/A-Za-z0-9 -]*$'), 'Special characters are not allowed'),
  countryCode: yup
    .string()
    .matches(/^[A-Za-z0-9 ]*$/, 'Special characters are not allowed')
    .max(80, 'Must be 80 characters or less')
    .when(['state', 'city', 'addressLine1', 'addressLine2', 'zip'], {
      is: (state, city, addressLine1, addressLine2, zip) => Boolean(state
      || city
      || addressLine1
      || addressLine2
      || zip),
      then: () => yup.string().required('Country code is required when an address is provided'),
    }),
  state: yup
    .string()
    .matches(RegExp('^[/A-Za-z0-9.,/?:()+‘ -]*$'), 'Special characters are not allowed')
    .max(80, 'Must be 80 characters or less'),
  bankCountryCode: yup
    .string()
    .required('Please select bank country'),
  currencyCode: yup
    .string()
    .required('Please select currency code'),
  swift: yup
    .string()
    .test({
      message: 'Beneficiary SWIFT cannot match correspondent SWIFT',
      test(value) {
        if (value && value.toLowerCase() === this.parent.correspondentSwift?.toLowerCase()) {
          return false;
        }
        return true;
      },
    })
    .matches(/^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/, 'Please enter a valid SWIFT / BIC.'),
  correspondentSwift: yup
    .string()
    .matches(/^\w{8}(\w{3})?$/, 'Correspondent SWIFT must be 8 or 11 digits')
    .test({
      message: 'Correspondent SWIFT cannot match beneficiary SWIFT',
      test(value) {
        if (value && value.toLowerCase() === this.parent.swift?.toLowerCase()) {
          return false;
        }
        return true;
      },
    }),
  furtherToSwift: yup
    .string()
    .matches(/^\w{8}(\w{3})?$/, 'Further To SWIFT must be 8 or 11 digits'),
};

export default validation;
