import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import styled from '@mui/styled-engine';

export const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  fontSize: 12,
  padding: '16px 20px',
  minWidth: 200,
  color: '#333333',
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  '&.menuItemHover': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '&.underline': {
    borderBottom: '1px solid rgba(112, 112, 112, 0.2)',
  },
});

export const StyledBox = styled(Box)({
  width: 80,
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: 'auto',
});
