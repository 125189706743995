import {
  createContext, Dispatch, SetStateAction,
} from 'react';
import { TCurrencyAccount, TCurrencyAccounts } from '../../models/currencyAccounts';
import { TPayment } from '../../models/payments';
import { TUserAccount } from '../../models/user';

export type TPaymentContextType = {
  selectedEntity?: TUserAccount;
  setSelectedEntity?: Dispatch<SetStateAction<TUserAccount | undefined>>;
};

export const PaymentContext = createContext<TPaymentContextType>({
  selectedEntity: undefined,
  setSelectedEntity: undefined,
});

export type TSinglePaymentsContextType =
  | {
    currentPayment: TPayment;
    isValid: boolean;
    open: boolean;
    editRow?: number;
    setEditRow: Dispatch<SetStateAction<number | undefined>>;
    setIsValid: Dispatch<SetStateAction<boolean>>;
    setCurrentPayment: Dispatch<SetStateAction<TPayment>>;
    handleBeneficiaryDrawerToggle: (state: boolean) => void;
  }
  | undefined;

export const SinglePaymentContext = createContext<TSinglePaymentsContextType>(undefined);

type TManualPaymentsContextType = {
  currentPayments: TPayment[];
  currencyAccounts: TCurrencyAccounts;
  fundingAccount: TCurrencyAccount | undefined;
  handleSetCurrentPayments: (_currentPayments: TPayment[]) => void;
  fxBatch?: boolean;
  isStandingOrder: boolean;
  setIsStandingOrder: (val: boolean) => void;
};

export const ManualPaymentsContext = createContext<TManualPaymentsContextType>({
  currentPayments: [],
  currencyAccounts: [],
  fundingAccount: undefined,
  handleSetCurrentPayments: (_currentPayments: TPayment[]) => { },
  isStandingOrder: false,
  setIsStandingOrder: (val: boolean) => { },
});
