import { Role } from '@alpha/auth-types';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import AuthorizationService from '../services/Authorization/authorization.service';
import { actions } from '../store/userManagement/userManagement.reducer';

export const useEntityManagementPermissions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const retrieveUserRolesForEntityManagement = async () => {
      const [userEntityViewerAccounts, userEntityManagerAccounts] = await Promise.all(
        [
          AuthorizationService.getEntitiesForRole(Role.ENTITY_VIEWER),
          AuthorizationService.getEntitiesForRole(Role.ENTITY_MANAGER_ADD_ALL_PERMS),
        ],
      );

      dispatch(
        actions.updateIsAuthorizedToViewEntityManagement(
          userEntityViewerAccounts.length > 0 || userEntityManagerAccounts.length > 0,
        ),
      );

      dispatch(
        actions.updateIsEntityManager(userEntityManagerAccounts.length > 0),
      );
    };
    retrieveUserRolesForEntityManagement();
  }, [dispatch]);
};

export default useEntityManagementPermissions;
