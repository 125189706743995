/* eslint-disable max-lines-per-function */
import { CurrencyList, ValidateBeneficiaryRequest } from '@alpha/bene-dtos';
import { BeneficiaryType } from '@alpha/bene-types/lib/enums/BeneficiaryType';
import {
  FormControlLabel, Radio, RadioGroup, Typography,
} from '@mui/material';
import { FormikProps } from 'formik';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import Alert from 'components/Alert/Alert';
import CurrencyAccountsService from 'services/CurrencyAccounts/currencyAccounts.service';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import AutocompleteDropDown, {
  IOption,
} from '../../../../../components/AutocompleteDropDown/AutocompleteDropDown';
import EntitiesMultiSelectDropDown from '../../../../../components/EntitiesMultiSelectDropDown/EntitiesMultiSelectDropDown';
import InputAndLabel from '../../../../../components/Inputs/Beneficiary/InputAndLabel';
import { TextEllipsis } from '../../../../../components/TextEllipsis';
import { TCountries } from '../../../../../models/countries';
import { EntityGroup } from '../../../../../models/entityGroups';
import { useStyles } from './styles';

interface IProps {
  form: FormikProps<ValidateBeneficiaryRequest>;
  countries: TCountries;
  currencyList: CurrencyList;
  multiEntity: boolean;
  accounts?: {
    value: string;
    label: string;
  }[];
  entityGroups?: EntityGroup[];
  disabled?: boolean;
}

const PersonalDetails: React.FC<IProps> = ({
  form,
  countries,
  currencyList,
  accounts,
  multiEntity,
  entityGroups,
  disabled,
}: IProps) => {
  const styles = useStyles();

  const sb = useAlphaSnackbar();
  const sbRef = useRef(sb);

  const [currentBankCountryOption, setCurrentBankCountryOption] = useState<IOption>({ name: '', code: '' });

  const [currentBankCurrencyOption, setCurrentBankCurrencyOption] = useState<IOption>({ name: '', code: '' });

  const [currentBeneficiaryCountryCode, setCurrentBeneficiaryCountryCode] = useState<IOption>({ name: '', code: '' });
  const [currencies, setCurrencies] = useState<IOption[]>([]);

  const [bankCountryTextFieldValue, setBankCountryTextFieldValue] = useState<string>('');
  const [bankCurrencyTextFieldValue, setBankCurrencyTextFieldValue] = useState<string>('');
  const [
    currentBeneficiaryCountryCodeTextFieldValue,
    setCurrentBeneficiaryCountryCodeTextFieldValue,
  ] = useState<string>('');

  const [groupSelected, setGroupSelected] = useState<boolean>(false);

  useEffect(() => {
    const divider: IOption = {
      code: 'divider',
      disabled: true,
      name: '',
    };

    setCurrencies([
      ...currencyList.topCurrencies,
      divider,
      ...currencyList.currencies,
    ]);
  }, [currencyList.currencies, currencyList.topCurrencies]);

  useEffect(() => {
    if (form.values.accountIds) {
      const foundGroup = form.values.accountIds?.some(({ value }) => value?.includes('#Group'));
      setGroupSelected(foundGroup);
    }
  }, [form.values.accountIds]);

  const entityGroupOptions = useMemo(
    () => entityGroups?.map((entityGroup) => ({
      value: `${entityGroup.id}#Group`,
      label: entityGroup.name,
    })) || [],
    [entityGroups],
  );

  const renderEntityOption = () => {
    if (accounts && disabled) {
      return (
        <>
          <Typography variant="subtitle1"> Entity </Typography>
          <div data-testid="lockedEntity" className={styles.entityElement}>
            <TextEllipsis text={accounts[0].label} maxChars={60} />
          </div>
        </>
      );
    }
    if (multiEntity) {
      return (
        <EntitiesMultiSelectDropDown
          placeholder="Choose Entities /Groups"
          label="Entity/ Group"
          name="accountIds"
          testId="accountIdsDropdown"
          options={[
            ...(accounts || []),
            ...entityGroupOptions,
          ]}
          setFieldValue={form.setFieldValue}
          value={form.values.accountIds}
          setTouched={() => form.setFieldTouched('accountIds')}
          touched={form.touched.accountIds}
          errors={form.errors.accountIds}
          groupSelected={groupSelected}
        />
      );
    }
    return <></>;
  };

  const entityGroupsMap = useMemo(
    () => new Map(entityGroups?.map((entityGroup) => [
      entityGroup.id, entityGroup.accounts,
    ])), [entityGroups],
  );

  const [entityHasCurrencyAccount, setEntityHasCurrencyAccount] = useState(true);

  useEffect(() => {
    const fetchAccountCurrencies = async (selectedAccounts: string[]) => {
      try {
        const accountCurrencies = await CurrencyAccountsService.getAccountsCurrenciesByAccountId(
          selectedAccounts.toString(),
        );

        const currency = accountCurrencies.find(
          (value) => value.currencyCode === form.values.currencyCode,
        );

        const entityIds = currency?.entityIds || [];

        setEntityHasCurrencyAccount(
          selectedAccounts.every((item) => entityIds.includes(item)),
        );
      } catch (error) {
        sbRef.current.trigger('Error fetching account currencies.');
        setEntityHasCurrencyAccount(false);
      }
    };

    if (
      form.values?.currencyCode
      && form.values?.accountIds
      && form.values?.accountIds.length > 0
    ) {
      const selectedAccounts = new Set(form.values.accountIds?.flatMap(({ value }) => {
        const group = value.slice(0, value.indexOf('#Group'));

        if (entityGroupsMap.has(group)) {
          return entityGroupsMap.get(group)?.map(({ id }) => id);
        }

        return value;
      })) as Set<string>;

      fetchAccountCurrencies(Array.from(selectedAccounts));
    }
  }, [form.values.currencyCode, form.values.accountIds, entityGroupsMap]);

  return (
    <div className={styles.container}>
      {renderEntityOption()}
      <div className={styles.inputWrapper} style={{ alignItems: 'center' }}>
        <div className="dd-privacy-allow">
          <Typography variant="subtitle1">Who are you paying?</Typography>
          <RadioGroup
            row
            value={form.values.type}
            onChange={(e) => form.setFieldValue('type', e.target.value)}
          >
            <FormControlLabel
              value={BeneficiaryType.ENTITY}
              control={<Radio color="primary" />}
              label="Company"
            />
            <FormControlLabel
              value={BeneficiaryType.INDIVIDUAL}
              control={<Radio color="primary" />}
              label="Individual"
            />
          </RadioGroup>
        </div>
      </div>
      <div className={styles.inputWrapper}>
        <InputAndLabel
          label="Account Holder"
          name="name"
          id="name"
          testId="accountHolder"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.name}
          error={form.touched.name && Boolean(form.errors.name)}
          helperText={form.touched.name && form.errors.name}
          warning="Please note the payment could be rejected by the receiving
                    bank if the beneficiary name does not match
                    the name on the account you are sending funds to."
        />
        <InputAndLabel
          label="Friendly Name"
          name="friendlyName"
          id="friendlyName"
          testId="friendlyName"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.friendlyName}
          error={form.touched.friendlyName && Boolean(form.errors.friendlyName)}
          helperText={form.touched.friendlyName && form.errors.friendlyName}
        />
      </div>
      <div data-dd-privacy="allow" className={styles.inputWrapper}>
        <div>
          <Typography className={styles.fieldLabel} variant="subtitle1">
            Bank Country
          </Typography>
          <AutocompleteDropDown
            name="bankCountryCode"
            testId="bankCountryDropdown"
            options={countries}
            setFieldValue={form.setFieldValue}
            value={form.values.bankCountryCode}
            touched={form.touched.bankCountryCode}
            errors={form.errors?.bankCountryCode}
            currentOption={currentBankCountryOption}
            setCurrentOption={setCurrentBankCountryOption}
            textFieldValue={bankCountryTextFieldValue}
            setTextFieldValue={setBankCountryTextFieldValue}
          />
        </div>
        <div>
          <Typography className={styles.fieldLabel} variant="subtitle1">
            Currency
          </Typography>
          <AutocompleteDropDown
            name="currencyCode"
            testId="bankCurrencyDropdown"
            type="CODE-NAME"
            options={
              (!bankCurrencyTextFieldValue.length
                && (!currentBankCurrencyOption
                  || !currentBankCurrencyOption?.code.length))
                || bankCurrencyTextFieldValue
                === `${currentBankCurrencyOption?.code} (${currentBankCurrencyOption?.name})`
                ? currencies
                : currencyList.currencies
            }
            setFieldValue={form.setFieldValue}
            value={form.values.currencyCode}
            touched={form.touched.currencyCode}
            errors={form.errors.currencyCode}
            currentOption={currentBankCurrencyOption}
            setCurrentOption={setCurrentBankCurrencyOption}
            textFieldValue={bankCurrencyTextFieldValue}
            setTextFieldValue={setBankCurrencyTextFieldValue}
          />
        </div>
      </div>
      {!entityHasCurrencyAccount
        && <Alert variant="info" text="Please call us to activate the currency account required to make payments to this beneficiary" />}
      <InputAndLabel
        label="Address Line 1"
        name="addressLine1"
        id="addressLine1"
        testId="addressLine1"
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        value={form.values.addressLine1}
        error={Boolean(form.errors.addressLine1)}
        helperText={form.errors.addressLine1}
      />
      <InputAndLabel
        label="Address Line 2 (Optional)"
        name="addressLine2"
        id="addressLine2"
        testId="addressLine2"
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        value={form.values.addressLine2}
        error={form.touched.addressLine2 && Boolean(form.errors.addressLine2)}
        helperText={form.touched.addressLine2 && form.errors.addressLine2}
      />
      <div className={styles.inputWrapper}>
        <div>
          <InputAndLabel
            label="City/Town (Optional)"
            name="city"
            id="city"
            testId="city"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.city}
            error={form.touched.city && Boolean(form.errors.city)}
            helperText={form.touched.city && form.errors.city}
          />
        </div>
        <div>
          <InputAndLabel
            label="County/State/Province (Optional)"
            name="state"
            id="state"
            testId="state"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.state}
            error={form.touched.state && Boolean(form.errors.state)}
            helperText={form.touched.state && form.errors.state}
          />
        </div>
      </div>
      <div className={styles.inputWrapper}>
        <div>
          <InputAndLabel
            label="Zip/Postal Code (Optional)"
            name="zip"
            id="zip"
            testId="zip"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.zip}
            error={form.touched.zip && Boolean(form.errors.zip)}
            helperText={form.touched.zip && form.errors.zip}
          />
        </div>
        <div className="dd-privacy-hidden">
          {form.errors.countryCode ? (
            <Typography
              className={styles.requiredFieldLabel}
              variant="subtitle1"
            >
              Country *
              {' '}
            </Typography>
          ) : (
            <Typography className={styles.fieldLabel} variant="subtitle1">
              Country
            </Typography>
          )}
          <AutocompleteDropDown
            name="countryCode"
            testId="beneficiaryCountryCodeDropdown"
            options={countries}
            setFieldValue={form.setFieldValue}
            value={form.values.countryCode}
            setTouched={() => form.setFieldTouched('countryCode')}
            touched={form.touched.countryCode}
            errors={form.errors.countryCode}
            currentOption={currentBeneficiaryCountryCode}
            setCurrentOption={setCurrentBeneficiaryCountryCode}
            textFieldValue={currentBeneficiaryCountryCodeTextFieldValue}
            setTextFieldValue={setCurrentBeneficiaryCountryCodeTextFieldValue}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
