import { Box } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ActionButton, GhostButton } from 'components/Buttons';
import { PaymentRouteEnum } from '../../../../../../models/payments';
import routes from '../../../../../../routes.path';
import history from '../../../../../../services/history/browserHistory';
import { actions } from '../../../../../../store/payments/payments.reducer';
import useStyles from './Buttons.styles';

interface IProps {
  handleClose: () => void;
  batchId: string;
  batchErrors: string[] | undefined;
  disableSubmitButton: boolean;
}

const Buttons: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    batchId, disableSubmitButton, batchErrors, handleClose,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Box className={classes.buttons}>
      <GhostButton
        onClick={handleClose}
        colorVariant="warning"
        testId="cancel-button"
      >
        {batchErrors && batchErrors.length > 0 ? 'exit' : 'reject'}
      </GhostButton>
      {batchErrors && batchErrors.length === 0 && !disableSubmitButton && (
        <ActionButton
          size="large"
          testId="continue-button"
          onClick={() => {
            history.push(
              routes.payments.batchSummaryRouteFunc(
                batchId,
                PaymentRouteEnum.DRAFTED,
              ),
            );
            dispatch(actions.clearBatchDetails());
          }}
        >
          continue and remove invalid payments
        </ActionButton>
      )}
    </Box>
  );
};

export default Buttons;
