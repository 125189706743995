import { CurrencyAccountSearchDto } from '@alpha/currency-accounts-dtos';
import { Stat } from 'components/Stat';
import { StyledDivider } from 'components/StyledDivider';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { CurrencyHeader } from '../../../components/International';
import { AccountSelectionEnum } from '../../../components/Transactions/Dropdown/Dropdown';
import CurrencyAccountsService from '../../../services/CurrencyAccounts/currencyAccounts.service';
import { TStore } from '../../../store';
import formatIsoDate from '../../../utils/formatIsoDate';
import { TransactionContext } from '../TransactionsContext';
import useStyles from './TransactionsSummary.styles';

export interface IProps {
  testId?: string;
  componentTestId?: string;
  startDate: string,
  endDate: string,
  currencyAccountIds: string[] | undefined,
}

type TransactionSummary = {
  svg: string,
  headerText: string,
  subHeaderText: string,
}

export const TransactionsSummary: React.FC<IProps> = (props: IProps) => {
  const [transactionsSummary, setTransactionsSummary] = useState<TransactionSummary>();
  const placeHolderText = useSelector<TStore, string>((state) => state.accounts.placeholderText);

  const classes = useStyles();
  const {
    componentTestId,
    startDate,
    endDate,
    currencyAccountIds,
  } = props;
  const transactionContext = useContext(TransactionContext);

  useEffect(() => {
    if (currencyAccountIds && currencyAccountIds.length === 1) {
      const currencyAccount = transactionContext?.currencyAccountsOptions?.find(
        (option: CurrencyAccountSearchDto) => option.id === currencyAccountIds[0],
      );
      getTransactionSummary(
        currencyAccountIds[0],
        currencyAccount?.accountId ? currencyAccount?.accountId : '',
      );
    } else if (currencyAccountIds?.length === 0 || (currencyAccountIds
      && currencyAccountIds.length === transactionContext?.currencyAccountTotal)) {
      setTransactionsSummary({
        svg: 'ALL',
        headerText: AccountSelectionEnum.ALL_ACCOUNTS,
        subHeaderText: placeHolderText,
      });
    } else {
      setTransactionsSummary({
        svg: 'ALL',
        headerText: 'Multiple Accounts',
        subHeaderText: placeHolderText,
      });
    }
  }, [
    startDate, endDate, currencyAccountIds, placeHolderText,
  ]);

  const getTransactionSummary = async (currencyAccountId: string, accountId: string) => {
    try {
      if (currencyAccountId && startDate && endDate) {
        const summary = await CurrencyAccountsService.getTransactionsSummary(
          currencyAccountId,
          startDate,
          endDate,
          accountId,
        );
        setTransactionsSummary({
          svg: summary.currencyCode,
          headerText: summary.friendlyName,
          subHeaderText: placeHolderText,
        });
      }
    } catch (error) {
      setTransactionsSummary({
        svg: 'ALL',
        headerText: 'Multiple Accounts',
        subHeaderText: placeHolderText,
      });
    }
  };

  const dateString = useMemo(() => (
    `${formatIsoDate(startDate)} - ${formatIsoDate(endDate)}`
  ), [startDate, endDate]);

  if (!transactionsSummary) {
    return <div data-testid={componentTestId} />;
  }

  return (
    <div className="dd-privacy-allow" data-testid={componentTestId}>
      <div className={classes.transactionCurrencySummary}>
        <div className={classes.transactionCurrencySummaryLeft}>
          <CurrencyHeader
            currencyCode={transactionsSummary.svg}
            currency={transactionsSummary.subHeaderText}
            account={transactionsSummary.headerText}
          />
        </div>
        <div className={classes.transactionCurrencySummaryRight}>
          <Stat title="Date" value={dateString} />
        </div>
      </div>
      <StyledDivider />
    </div>
  );
};

export default TransactionsSummary;
