import React, { useEffect } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import MFAOptionsCard from '../../../../../../components/AuthyComponent/MFAOptionsCard';
import useAlphaSnackbar from '../../../../../../hooks/useAlphaSnackbar';
import useAuthy from '../../../../../../hooks/useAuthy';
import PushNotification from '../../../../../../components/AuthyComponent/PushNotification';
import { AuthyPageStates, TAuthyPaymentsType } from '../../../../../../store/authy/reducer';

interface IProps {
  classes: Record<'header' | 'mfaInputFields' | 'authContainer' | 'info' | 'cta', string>;
  handleComplete: (code: number) => void;
}

const FormBody: React.FC<IProps> = ({
  classes,
  handleComplete,
}: IProps) => {
  const sb = useAlphaSnackbar();
  const { triggerMfa, reduxState, phoneNumber } = useAuthy();
  const { pageState, approvalRequestId, type } = reduxState;

  const handleCardClick = async (mfaOptionChosen: 'authy' | 'sms' | 'call'): Promise<void> => {
    await triggerMfa(mfaOptionChosen === 'authy' ? undefined : mfaOptionChosen);
  };

  useEffect(() => {
    if (pageState !== AuthyPageStates.OPTIONS) {
      sb.trigger(
        `Verification code sent to: ${phoneNumber}`,
        'info',
      );
    }
  }, [pageState]);

  if (approvalRequestId
    && (type?.type === 'PAYMENTS' || type?.type === 'PAYMENT_APPROVE')
    && pageState === AuthyPageStates.AUTHY) {
    const { accountId } = type as TAuthyPaymentsType;
    return (
      <PushNotification
        authyType={type.type}
        batchId={type.batchId}
        approvalRequestId={approvalRequestId}
        approveOwn={Boolean((type as TAuthyPaymentsType).approverOwn)}
        accountId={accountId}
        fraudWarningAcknowledged={type?.fraudWarningAcknowledged}
      />
    );
  }

  switch (pageState) {
    case AuthyPageStates.OPTIONS:
      return (
        <MFAOptionsCard
          handleCardClick={handleCardClick}
        />
      );
    default:
      return (
        <div className={classes.mfaInputFields} data-testid="authyInput">
          <ReactCodeInput
            onComplete={handleComplete}
            autoFocus
            className={classes.authContainer}
            fieldWidth={50}
            fieldHeight={50}
            type="number"
          />
        </div>
      );
  }
};

export default FormBody;
