import { Box, Typography } from '@mui/material';
import { TKeyValue } from 'components/Table/Table.interfaces';
import React from 'react';
import KeyValueTable from 'components/Table/KeyValueTable/KeyValueTable';
import useStyles from './BeneficiaryDetails.styles';

interface IProps {
  beneficiaryDetails: TKeyValue[];
  paymentId: string | undefined;
}

const BeneficiaryDetails: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const {
    beneficiaryDetails,
    paymentId,
  } = props;
  return (
    <>
      {
        paymentId
        && (
          <Box className={classes.beneficiaryWrapper}>
            <Typography className={classes.beneficiaryHeader}>Beneficiary Details</Typography>
            <KeyValueTable
              testId="transactions-drawer-beneficiary"
              data={beneficiaryDetails}
              obscure
            />
          </Box>
        )
      }
    </>
  );
};

export default BeneficiaryDetails;
