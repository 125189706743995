import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityGroup } from '../../models/entityGroups';
import { TUserAccount } from '../../models/user';

export type TEntityGroupsStore = {
    entityGroupsForUser?: EntityGroup[];
    entityGroupsLoading: boolean;
    selectedEntityGroup?: EntityGroup;
    showAccountDropdown:boolean;
    viewGroupsDrawerOpen: boolean;
    createGroupDrawerOpen: boolean;
    accountsInNewGroup?: TUserAccount[];
    userAccountsWithEPerms: TUserAccount[];
}

const initialState:TEntityGroupsStore = {
  showAccountDropdown: false,
  entityGroupsLoading: false,
  viewGroupsDrawerOpen: false,
  createGroupDrawerOpen: false,
  userAccountsWithEPerms: [],
};

export const entityGroupsSlice = createSlice({
  name: 'entityGroups',
  initialState,
  reducers: {
    setSelectedEntityGroup: (state, action: PayloadAction<{
        id: string,
        name: string,
        colour:string,
        accounts: TUserAccount[]
    } | undefined>) : TEntityGroupsStore => ({
      ...state,
      selectedEntityGroup: action.payload,
    }),
    updateSelectedEntityGroup: (state): TEntityGroupsStore => ({
      ...state,
    }),
    updateLoadingState: (state, action :PayloadAction<{
      loading: boolean
    }>) :TEntityGroupsStore => ({
      ...state,
      entityGroupsLoading: action.payload.loading,
    }),
    updateEntityGroupsForUserSuccess: (state, action: PayloadAction<{
      updatedGroups: EntityGroup[]
    }>) :TEntityGroupsStore => ({
      ...state,
      entityGroupsForUser: action.payload.updatedGroups,
    }),
    reset: (): TEntityGroupsStore => initialState,
    toggleOpenAccountDropdown: (state, action :PayloadAction<{
      open: boolean
    }>) : TEntityGroupsStore => ({
      ...state,
      showAccountDropdown: action.payload.open,
    }),
    toggleOpenViewGroupsDrawer: (state, action :PayloadAction<{
      open: boolean
    }>) : TEntityGroupsStore => ({
      ...state,
      viewGroupsDrawerOpen: action.payload.open,
    }),

    toggleOpenCreateEntityGroupDrawer: (state, action :PayloadAction<{
      open: boolean
    }>) : TEntityGroupsStore => ({
      ...state,
      createGroupDrawerOpen: action.payload.open,
    }),
    addAccountToNewGroupForm: (state, action: PayloadAction<{
      account:TUserAccount
    }>) => {
      if (!state.accountsInNewGroup) {
        state.accountsInNewGroup = [];
      }
      state.accountsInNewGroup.push(action.payload.account);
    },
    removeAccountFromNewGroupForm: (state, action: PayloadAction<{
      account:TUserAccount
    }>) => {
      if (state.accountsInNewGroup) {
        const accountToRemoveIndex = state.accountsInNewGroup
          .findIndex((account) => (account.id === action.payload.account.id));
        state.accountsInNewGroup.splice(accountToRemoveIndex, 1);
      }
    },
    getUserAccountsWithEPerms: (state, action:PayloadAction<{
      accounts:TUserAccount[]
    }>) => ({
      ...state,
      userAccountsWithEPerms: action.payload.accounts,
    }),
    resetAccountsInNewGroupForm: (state) : TEntityGroupsStore => ({
      ...state,
      accountsInNewGroup: undefined,
    }),
  },
});

export const { actions } = entityGroupsSlice;
