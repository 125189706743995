import { IconButton } from '@mui/material';
import styled from '@mui/styled-engine';

const StyledIconButton = styled(IconButton)({
  padding: 12,
  fontSize: 15,
  '& svg': {
    width: '1em',
  },
});

export default StyledIconButton;
