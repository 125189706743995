import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    details: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 0px',
      marginBottom: '0px !important',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    header: {
      fontSize: 13,
      fontFamily: typography.fontFamilySecondary,
      marginRight: 'auto',
      textTransform: 'capitalize',
    },
    friendlyName: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 13,
      marginLeft: 15,
      fontFamily: typography.fontFamilySecondary,
      '& > p': {
        marginLeft: 5,
      },
    },
    imageWrapper: {
      display: 'flex',
      overflow: 'hidden',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'relative',
      alignItems: 'center',
      padding: '5px',
    },
    image: {
      width: 30,
      height: 30,
      position: 'absolute',
      display: 'block',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    information: {
      margin: 0,
      color: 'rgba(51, 51, 51, 0.5)',
    },
  }),
  { name: 'Account' },
);

export default useStyles;
