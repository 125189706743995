import { Guid } from 'guid-typescript';
import {
  call, delay, put, select, takeLatest,
} from 'redux-saga/effects';
import { TStore } from '..';
import env from '../../env.variables';
import { TPayment, TPendingPayment } from '../../models/payments';
import { actions as notificationsActions } from '../notifications/notifications.reducer';
import { apiRequest, TAPIRequestsResult } from '../requestSaga';
import { actions } from './payments.reducer';

export const loadPaymentsRequestName = 'payments';
export function* loadPaymentsSaga(action: ReturnType<typeof actions.loadPayments>) {
  try {
    const result: TAPIRequestsResult<TPayment[]> = yield call(apiRequest, {
      config: {
        method: 'GET',
        url: `${env.REACT_APP_API_URL}payments/batches`,
        params: {
          ...action.payload,
        },
      },
      name: loadPaymentsRequestName,
    });

    yield put(actions.loadedPayments({
      data: result.data,
      totalRecords: result?.headers?.['total-items'] || 0,
    }));
  } catch (err) {
    throw Error(`Error loading payment: ${err}`);
  }
}

export function* pollBatchStatus(): any {
  try {
    const batchIdFromState = yield select(
      (state: TStore): string | undefined => state.payments.batchDetails?.batchId,
    );
    const accountIdFromState = yield select(
      (state: TStore): string | undefined => state.payments.batchDetails?.accountId,
    );
    if (batchIdFromState && !accountIdFromState) {
      const response = yield call(apiRequest, {
        config: {
          method: 'GET',
          url: `${env.REACT_APP_API_URL}payments/batches/${batchIdFromState}/status`,
        },
      });
      const { batchStatus } = response.data;
      const batchDetails = { ...response.data };
      yield put(actions.updateBatchDetails(batchDetails));
      if (batchStatus === 'REQUESTED' || batchStatus === 'UPLOADED') {
        yield delay(1000);
        yield put(actions.triggerBatchStatus());
      }
    }
    if (batchIdFromState && accountIdFromState) {
      const response = yield call(apiRequest, {
        config: {
          headers: {
            'account-id': accountIdFromState,
          },
          method: 'GET',
          url: `${env.REACT_APP_API_URL}payments/batches/${batchIdFromState}/status`,
        },
      });
      const { batchStatus } = response.data;
      const batchDetails = { ...response.data };
      yield put(actions.updateBatchDetails(batchDetails));
      if (batchStatus === 'REQUESTED' || batchStatus === 'UPLOADED') {
        yield delay(1000);
        yield put(actions.triggerBatchStatus());
      }
    }
  } catch (e) {
    yield put(
      notificationsActions.enqueueSnackbar({
        variant: 'error',
        className: 'dd-privacy-allow',
        key: Guid.create().toString(),
        message: 'An error occurred uploading your file',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }),
    );
    yield put(actions.clear());
  }
}

export const loadPendingPaymentsRequestName = 'pendingPayments';
export const loadPendingPaymentsSaga = function* (
  action: ReturnType<typeof actions.loadPendingPayments>,
) {
  try {
    const result: TAPIRequestsResult<
    { hasMore: boolean,
      items: TPendingPayment[]
    }> = yield call(apiRequest, {
      config: {
        method: 'GET',
        url: `${env.REACT_APP_API_URL}payments/batches/${action.payload.batchId}/payments`,
        params: {
          sellcurrencycode: action.payload.currencyPair?.sellCurrency,
          buycurrencycode: action.payload.currencyPair?.buyCurrency,
          fixedside: action.payload.currencyPair?.fixedSide,
        },
      },
      name: loadPendingPaymentsRequestName,
    });
    const response = {
      totalItems: result.headers['content-length'], // TODO: fix this, target correct total-items header, currently not avail
      data: result.data.items,
    };
    yield put(actions.loadedPendingPayments(response));
  } catch (err) {
    // TODO: handle error
  }
};

export function* paymentsSaga() {
  yield takeLatest(actions.loadPayments, loadPaymentsSaga);
  yield takeLatest(actions.triggerBatchStatus, pollBatchStatus);
  yield takeLatest(actions.loadPendingPayments, loadPendingPaymentsSaga);
}
