import React, { useEffect } from 'react';
import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import { useSelector } from 'react-redux';
import { AccountDto } from '@alpha/auth-dtos';
import { Flags } from 'react-feature-flags';
import BeneFraudAcknowledgeModal from 'domain/Beneficiaries/BeneFraudAcknowledgeModal';
import Header from './Header/index';
import Body from './Body/index';
import { TStore } from '../../../../store';
import { TAuthyState } from '../../../../store/authy/reducer';
import useShareBeneficiary from './useShareBeneficiary';

export interface IBeneShareDrawer {
  accountBeneId: string;
  availableAccounts: AccountDto[];
  handleBeneficiaryShareButtonClick: () => void;
  accountId: string;
}

export const BeneShareDrawer: React.FC<IBeneShareDrawer> = (
  props: IBeneShareDrawer,
) => {
  const {
    accountBeneId,
    availableAccounts,
    handleBeneficiaryShareButtonClick,
    accountId,
  } = props;

  const shareBeneficiary = useShareBeneficiary(accountBeneId, availableAccounts, accountId);

  const authy = useSelector<TStore, TAuthyState>((store) => store.authy);

  useEffect(() => {
    if (authy.status === 'SUCCESS' && authy.type?.type === 'BENEFICIARY_BATCH') {
      handleBeneficiaryShareButtonClick();
    }
  }, [authy.status]);

  return (
    <>
      <BaseDrawer.Drawer
        data-testid="beneficiary-share-drawer"
        open={Boolean(accountBeneId)}
        onClose={handleBeneficiaryShareButtonClick}
        anchor="right"
      >
        <Header
          handleShareKeyUp={shareBeneficiary.handleShareKeyUp}
          totalAcccounts={shareBeneficiary.availableAccounts.length}
        />
        <Body
          {...shareBeneficiary}
          availableAccounts={shareBeneficiary.availableAccounts}
          searchTerm={shareBeneficiary.searchTerm.current}
        />
      </BaseDrawer.Drawer>
      {
        shareBeneficiary.acknowledgeModalOpen
        && (
          <Flags authorizedFlags={['fraudModalEnabled']}>
            <BeneFraudAcknowledgeModal
              handleConfirmation={shareBeneficiary.handleFraudAcknowledge}
              handleClose={() => shareBeneficiary.setAcknowledgeModalOpen(false)}
              acknowledgeModalOpen={shareBeneficiary.acknowledgeModalOpen}
            />
          </Flags>
        )
      }
    </>
  );
};
