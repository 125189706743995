import React from 'react';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { Typography } from '@mui/material';
import ActionDropDown from '../../../components/ActionDropDown';
import saveCurrentPageRoute from '../../../../../utils/saveCurrentPageRoute';
import routes from '../../../../../routes.path';
import history from '../../../../../services/history/browserHistory';

interface IProps {
  user: string;
}

const InReviewDropdown: React.FC<IProps> = (props: IProps) => {
  const {
    user,
  } = props;

  const handleRedirectToUser = () => {
    saveCurrentPageRoute();
    history.push(
      `${routes.userManagement.userRoute(user)}`,
    );
  };

  const inReviewTableDropdownItems = [{
    id: 'action-user-view',
    content: (
      <Typography>
        View User
      </Typography>
    ),
    icon: faEye,
    onClick: () => { handleRedirectToUser(); },
    underline: false,
    userCanApprove: false,
  }];

  return (
    <ActionDropDown menuItems={inReviewTableDropdownItems} />
  );
};

export default InReviewDropdown;
