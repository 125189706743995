import { Button } from '@mui/material';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OutlinedButton } from 'components/Buttons';
import FooterWrapper from '../../../../../components/Payments/FooterWrapper/FooterWrapper';
import navigateToStartOfJourney from '../../../../../utils/navigateToStartOfJourney';

const BatchSubmittedFooter = () => {
  const handleClick = () => navigateToStartOfJourney();
  return (
    <FooterWrapper>
      <Button
        onClick={handleClick}
        color="secondary"
        startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
      >
        Exit
      </Button>
      <OutlinedButton onClick={handleClick}>Close</OutlinedButton>
    </FooterWrapper>
  );
};

export default BatchSubmittedFooter;
