import _default, { makeStyles } from 'theme';

import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material';

const { typography } = _default;

export const StyledDataGrid = styled(DataGrid)({
  border: 'none',
  fontFamily: `${typography.fontFamily}`,
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell': {
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 14,
    color: '#222222',
  },
  '& .MuiDataGrid-columnHeader': {
    color: '#999999',
    fontSize: 12,
    '& button:hover': {
      color: '#098375',
    },
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'inherit',
  },
  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'unset',
  },
  '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-row--lastVisible': {
    borderBottom: 'none',
  },
  '.MuiDataGrid-footerContainer': {
    borderTop: 'none',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: '4px',
  },
  'MuiButtonBase-root-MuiButton-root': {
    backgroundColor: '#CDE5E2',
  },
  '& .MuiButton-root': {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    color: '#212529',
  },
  '& .Mui-selected': {
    backgroundColor: '#CDE5E2',
    color: '#098375',
  },
  '& .Mui-selected:hover': {
    backgroundColor: '#CDE5E2',
    color: '#098375',
  },
  '& .MuiListItemIcon-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .MuiDataGrid-row': {
    borderTop: '0px',
    borderBottom: '1px solid #EAEAEA',
    margin: 0,

    '&:nth-of-type(1)': {
      borderTop: '1px solid #EAEAEA',
    },
  },
  '& .MuiDataGrid-virtualScroller': {
    overflowX: 'hidden',
  },
  '& .MuiCheckbox-root': {
    color: '#e1d9d9',
  },
});

const useStyles = makeStyles(
  () => ({
    noDataLabel: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.9rem',
    },
  }),
  { name: 'DataTable' },
);

export default useStyles;
