import styled from '@mui/styled-engine';
import { Button } from '@mui/material';
import _default from 'theme';
import { IBaseButtonProps } from '../BaseButton';

const { typography } = _default;

export const SecondaryButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'rounded',
})<IBaseButtonProps>(({ rounded }) => ({
  backgroundColor: '#FDFDFD',
  borderRadius: rounded ? 4 : 0,
  color: '#696969',
  boxShadow: 'none',
  fontWeight: typography.fontWeightSemiBold,
  '&.MuiButton-sizeLarge': {
    minHeight: 50,
  },
  '&:hover': {
    backgroundColor: '#FDFDFD',
    boxShadow: 'none',
  },
}));
