import { PaymentBatchDraftStatus } from '@alpha/payments-dtos';

export const flowToBatchStatusMapping: any = {
  uploader: [
    PaymentBatchDraftStatus.REQUESTED,
    PaymentBatchDraftStatus.UPLOADED,
    PaymentBatchDraftStatus.VALIDATED,
    PaymentBatchDraftStatus.INVALID,
  ],
  approver: [
    PaymentBatchDraftStatus.SUBMITTED,
    PaymentBatchDraftStatus.APPROVED,
    PaymentBatchDraftStatus.REJECTED,
  ],
  postCompletion: [PaymentBatchDraftStatus.INPROGRESS, PaymentBatchDraftStatus.COMPLETE],
};

export const getClientFriendlyBatchStatus = (
  backendBatchStatus: PaymentBatchDraftStatus | string,
) => {
  switch (true) {
    case flowToBatchStatusMapping.uploader.includes(backendBatchStatus):
      return 'Saved';
    case flowToBatchStatusMapping.approver.includes(backendBatchStatus):
      return 'Pending Approval';
    case backendBatchStatus === PaymentBatchDraftStatus.INPROGRESS:
      return 'In Progress';
    default:
      return backendBatchStatus;
  }
};

export const getBatchStatusColorVariant = (
  backendBatchStatus: PaymentBatchDraftStatus | string,
) => {
  switch (true) {
    case backendBatchStatus === 'PENDING FX':
      return 'orange';
    case backendBatchStatus === 'RELEASED':
      return 'success';
    default:
      return 'default';
  }
};

export default {
  flowToBatchStatusMapping,
  getClientFriendlyBatchStatus,
};
