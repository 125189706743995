import { Button } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledAPPaginationButton = styled(Button)({
  fontSize: 12,
  letterSpacing: 1.8,
  color: '#333333',
  margin: '0 2px',
  padding: 3,
  textTransform: 'uppercase',
  opacity: 'inherit',

  '&:disabled *': {
    color: 'rgba(0, 0, 0, 0.26) !important',
  },

  '& .MuiButton-iconSizeMedium > *:first-child': {
    fontSize: 8,
    color: '#333333',
  },

  '& .MuiButton-endIcon': {
    marginLeft: 9,
  },
  '& .MuiButton-startIcon': {
    marginRight: 11,
  },
});
