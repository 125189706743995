/* eslint-disable no-unreachable */
import { SameTradeResponse } from '@alpha/fx-dtos';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import InterAccountTransferService from '../../../services/InterAccountTransfer/interAccountTransfer.service';
import { TInterAccountTransferForm } from '../FormValues';

export type TDrawerState =
  | 'initiated'
  | 'loading'
  | 'failed'
  | 'timeout'
  | 'success'
  | 'initiatedBookTrade'
  | 'failedBookTrade'
  | 'duplicateTrade';

export const useBookFx = (
  form: FormikProps<TInterAccountTransferForm>,
  handleOpenTransactionDataDrawer: () => void,
  accountId?: string,
) => {
  const snackbar = useAlphaSnackbar();
  const [liveRateStatus, setLiveRateStatus] = useState<TDrawerState>(
    'initiated',
  );
  const [duplicateTrade, setDuplicateTrade] = useState<SameTradeResponse[]>([]);
  const [duplicateTradeAcknowledged, setDuplicateTradeAcknowledged] = useState<boolean>(false);

  const { creditingAccount, debitingAccount, iatDraftId } = form.values;
  const { liveRate } = form.values;

  useEffect(() => {
    let isCancelled = false;
    if (liveRateStatus === 'initiated') {
      updateLiveRateStatus(isCancelled);
    }

    return () => {
      isCancelled = true;
    };
  }, [liveRateStatus]);

  const updateLiveRateStatus = async (isCancelled: boolean) => {
    try {
      setLiveRateStatus('loading');
      if (!iatDraftId) throw Error('Unable to find current IAT');
      const liveRateResponse = accountId
        ? await InterAccountTransferService.getQuoteRequest(iatDraftId, accountId)
        : await InterAccountTransferService.getQuoteRequest(iatDraftId);
      if (isCancelled) return;

      form.setFieldValue('liveRate', liveRateResponse);
      form.setFieldValue('debitingAccount.amount', liveRateResponse.sellAmount);
      form.setFieldValue('creditingAccount.amount', liveRateResponse.buyAmount);
      setLiveRateStatus('success');
    } catch (error) {
      const errorMessage = error?.response?.data?.error || error.message || 'There was an error retrieving your live rate';
      snackbar.trigger(errorMessage);
      setLiveRateStatus('failed');
    }
  };

  const handleCheckDuplicateTrades = async () => {
    try {
      if (!iatDraftId) throw Error('Unable to find current IAT');
      const response = accountId
        ? await InterAccountTransferService.checkSameTrade(iatDraftId, accountId)
        : await InterAccountTransferService.checkSameTrade(iatDraftId);
      if (response && response.length > 0) {
        setDuplicateTrade(response);
        return setLiveRateStatus('duplicateTrade');
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error || error.message || 'There was an error checking for duplicate trades';
      snackbar.trigger(errorMessage);
    }
    return handleBookFx();
  };

  const handleAcknowledgeDuplicateTrade = () => {
    setDuplicateTradeAcknowledged(true);
    setLiveRateStatus('initiated');
  };

  const handleBookFx = async () => {
    try {
      if (!liveRate) throw Error('Unable to obtain live rate');
      const { tradeFriendlyId, tradeId } = accountId
        ? await InterAccountTransferService.executeQuoteRequest(
          liveRate.instructionId,
          accountId,
        )
        : await InterAccountTransferService.executeQuoteRequest(
          liveRate.instructionId,
        );

      form.setFieldValue('tradeId', tradeId);
      form.setFieldValue('tradeFriendlyId', tradeFriendlyId);
      // Display transactions drawer
      handleOpenTransactionDataDrawer();
    } catch (error) {
      const errorMessage = error?.response?.data?.error || error.message || 'There was an error with your booking, please request a new live rate';
      snackbar.trigger(errorMessage);
      setLiveRateStatus('failedBookTrade');
    }
  };

  const handleClickBookFx = async () => {
    setLiveRateStatus('initiatedBookTrade');
    if (!duplicateTradeAcknowledged) {
      return handleCheckDuplicateTrades();
    }
    return handleBookFx();
  };

  return {
    liveRateStatus,
    liveRate,
    debitingAccount,
    creditingAccount,
    setLiveRateStatus,
    handleBookFx,
    handleAcknowledgeDuplicateTrade,
    handleCheckDuplicateTrades,
    duplicateTradeAcknowledged,
    duplicateTrade,
    handleClickBookFx,
    form,
  };
};

export default useBookFx;
