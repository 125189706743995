import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useStyles } from './ChoosePayment.styles';
import { FileUpload } from './FileUpload/FileUpload';
import routes from '../../../../routes.path';

export const CHOOSE_PAYMENT_TEST_IDS = {
  choosePayment: 'ChoosePayment',
  fileUploadSection: 'FileUploadSection',
  manualSection: 'ManualSection',
};

export const ChoosePayment: React.FC = () => {
  const classes = useStyles();
  return (
    <div
      data-testid={CHOOSE_PAYMENT_TEST_IDS.choosePayment}
      className={classes.root}
    >
      <div
        className={classes.manual}
        data-testid={CHOOSE_PAYMENT_TEST_IDS.manualSection}
      >
        <div className="manualContent">
          <div className="text">
            <Link to={routes.payments.manual.base}>
              <Typography variant="h6" className={classes.title}>
                Manual Input
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Input new payments one by one or transfer funds between currency
                accounts
              </Typography>
            </Link>
          </div>
          <div className="caretRight">
            <Link to={routes.payments.manual.base} className={classes.button}>
              <FontAwesomeIcon size="lg" icon={faAngleRight} className={classes.buttonIcon} />
            </Link>
          </div>
        </div>
        <div className="orDivider">
          <span>Or</span>
        </div>
      </div>
      <FileUpload />
    </div>
  );
};

export default ChoosePayment;
