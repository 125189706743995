import { Beneficiary } from '@alpha/bene-dtos';
import {
  BatchUploadLinkDto,
  CreateBatchRequest,
  PaymentDateOptionsDto,
  PaymentDraftDto,
} from '@alpha/payments-dtos';
import { PaymentPurposeOption } from '@alpha/payments-dtos/lib/reference/PaymentPurposeDto';
import { AxiosRequestConfig } from 'axios';
import { Frequency } from 'domain/PaymentsMultiEntity/ManualPayments/Body/ManualPayment/PaymentFields/StandingOrder/constants';
import { StandingOrderStatus } from 'domain/PaymentsMultiEntity/Dashboard/ScheduledTableContainer/constants';
import { TCurrencyPair } from './currencies';

export type TDate = PaymentDateOptionsDto;

export type TChosenPaymentPurpose = {
  required: boolean;
  option: PaymentPurposeOption;
};

export type TPayment = {
  reference: string;
  date: string;
  debitingAccountId?: string;
  debitingAccountCurrencyCode?: string;
  debitingAccountIban?: string;
  debitingAccountFriendlyName?: string;
  fixedSide?: string;
  purposeOfPaymentCode?: string;
  paymentAmount: number;
  beneficiary?: Beneficiary;
  fundingAccountId?: string;
  fundingAccountCurrencyCode?: string;
  fundingAccountIban?: string;
  fundingAccountFriendlyName?: string;
  files?: File[];
  paymentRail?: string;
  startDate?: string;
  endDate?: string;
  frequency?: string;
  numberOfPayments?: string;
  isStandingOrder?: boolean;
};

export type TStandingOrder = TPayment & {
  beneficiaryDraft: TBeneficiaryDraft;
  accountId: string;
  accountName: string;
  amount: number;
  batchId: string;
  batchLineNumber: number;
  channel: string;
  debitingAccount: string;
  debitingAccountId: string;
  debitingCurrency: string;
  documents: any[];
  endDate: string | null;
  errors: any[];
  errorsWithCodes: any[];
  frequency: Frequency;
  fundingAccount: string;
  fundingAccountId: string;
  gsiAccountIdPaymentIdPk: string;
  id: string;
  isStandingOrder: boolean;
  lsiCurrencyLinePaymentId: string;
  nextFireDate: string;
  paymentDate: string;
  paymentStatus: string;
  pk: string;
  purpose: string;
  reference: string;
  scheduleId: string;
  sk: string;
  startDate: string;
  uploadedBy: string;
  uploadedDate: string;
  userIdOnBehalf: string;
  fixedSide?: string;
  status: StandingOrderStatus;
};

export type TBeneficiaryDraft = {
  batchLineNumber: number;
  batchId: string;
  uploadedDate: string;
  accountId: string;
  bankCountryCode: string;
  iban: string;
  name: string;
  id: string;
  uploadedBy: string;
  errors: [];
  thirdPartyValidationRecommendations: [];
  status: PaymentStatus;
  swift: string;
};

export enum PaymentStatus {
  UPLOADED = 'UPLOADED',
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  INVALID = 'INVALID',
  APPROVED = 'APPROVED',
}
export type TPaymentAction =
  | 'View'
  | 'Export'
  | 'Approve'
  | 'Discard'
  | 'Remove';

export type TPurposeOfPayment = string;

export type TBankDetailsField = {
  MinLength: number;
  MaxLength: number;
  RegEx: string;
  Example: string;
  FriendlyName: string;
  Name: string;
};

export type TBankDetails = TBankDetailsField[];

export type TBankDetailsValidationField = {
  Name: string;
  Error: string | null;
  Value: string | null;
};

export type TBankDetailsData = {
  [key: string]: string;
};

export type TBankDetailsValidation = TBankDetailsValidationField[];

// anything below this point is part of the refactor

export type TBatchId = {
  batchId: string;
};

export type TPendingPaymentParams = {
  batchId: string;
  currencyPair?: TCurrencyPair | null;
};

export type TPendingPayment = {
  paymentId: string;
  batchId: string;
  accountId: string;
  beneficiaryId?: string;
  beneficiaryClientName: string;
  beneficiaryClientBankCountry: string;
  beneficiaryClientIban?: string;
  beneficiaryAlphaIban?: string;
  beneficiaryClientAccountNumber?: number | null;
  beneficiaryAlphaAccountNumber?: number;
  beneficiaryClientSwift?: string;
  beneficiaryAlphaSwift?: string;
  beneficiaryClientNationalBankCode?: number | null;
  beneficiaryAlphaNationalBankCode?: number;
  beneficiaryClientCorrespondentAccountNumber?: number | null;
  beneficiaryClientCorrespondentSwift?: string;
  beneficiaryClientEmailAddress?: string;
  sellCurrencyCode: string;
  sellAmount?: number;
  buyCurrencyCode: string;
  buyAmount: number;
  purpose?: string;
  reference?: string;
  date?: string;
  settlementRequiredBy?: string;
  paymentStatus: PaymentStatus;
  batchLineNumber?: number;
  afBankName?: string;
};

export interface IGetPresignedUrlInstanceParams extends AxiosRequestConfig {
  data: CreateBatchRequest;
}

export type TBatchUploadLinkDto = BatchUploadLinkDto;
export type TPaymentDraftDto = PaymentDraftDto;

export enum PaymentRouteEnum {
  DRAFTED = 'drafted',
  PENDING = 'pending',
  COMPLETE = 'complete',
  INPROGRESS = 'inprogress',
  FUNDED = 'funded',
  FUNDING = 'funding',
  ACTIONNEEDED = 'actionneeded',
  RELEASED = 'released',
  SCHEDULED = 'scheduled',
}
