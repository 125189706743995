import { Button, styled } from '@mui/material';

export const StyledModalContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 40,
  '.MuiTypography-subtitle1': {
    fontSize: 14,
    paddingBottom: 20,
  },
  '.MuiTypography-subtitle2': {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: 20,
  },
});

export const StyledButton = styled(Button)({
  borderRadius: 5,
  fontWeight: 600,
  fontSize: 13,
  textTransform: 'capitalize',
});

export const StyledRemoveButton = styled(StyledButton)({
  border: '1px solid',
  backgroundColor: '#C64545',
  '&:hover': {
    backgroundColor: '#A03737',
  },
});

export const StyledBackButton = styled(StyledButton)({
  color: '#5c5c5c',
  backgroundColor: '#F0F0F0',
});

export const StyledCloseButton = styled(Button)({
  right: 10,
  top: 10,
  justifyContent: 'flex-end',
  position: 'absolute',
  color: '#5c5c5c',
  '.MuiTouchRipple-root': {
    display: 'none',
  },
  '&:hover': {
    backgroundColor: 'inherit',
  },
  outline: 'none',
});

export const StyledLi = styled('li')({
  listStyleType: "'- '",
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: 1.8,
});

export const StyledUl = styled('ul')({
  margin: 'inherit',
  paddingLeft: 15,
  maxHeight: 215,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.4em',
    borderRadius: 5,
    background: 'white',
    position: 'relative',
    border: '1px solid rgba(0, 0, 0, .2)',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 5,
    backgroundColor: 'rgba(0, 0, 0, .3)',
    boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
  },
});

export const StyledFooter = styled('div')({
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  '.MuiTypography-root': {
    marginTop: 20,
  },
});
