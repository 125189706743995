/* eslint-disable @typescript-eslint/no-shadow */
import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@mui/material';
import { faCheckCircle, faEye, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import React, { useMemo, useState } from 'react';
import Menu from 'components/Menu/Menu';
import BeneFraudAcknowledgeModal from 'domain/Beneficiaries/BeneFraudAcknowledgeModal';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { iAllowedAccounts } from 'store/accounts/accounts.reducer';
import { Flags } from 'react-feature-flags';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import useActionDropdown from '../../../../hooks/useActionDropDown';
import { TAuthyBeneficiaryType } from '../../../../store/authy/reducer';
import useStyles from '../../../Payments/Dashboard/PendingTable/DisplayTable/ActionDropDown/ActionDropDown.styles';
import useAuth from '../../../../hooks/useAuth';
import saveCurrentPageRoute from '../../../../utils/saveCurrentPageRoute';

interface IProps {
  setSelectedBeneficiary: React.Dispatch<React.SetStateAction<BeneficiaryDto | undefined>>;
  beneficiary: BeneficiaryDto;
  approveBeneficiary: (payload: TAuthyBeneficiaryType) => void;
  handleOnRejectionClick: any;
}

const ActionDropDown: React.FC<IProps> = (props: IProps) => {
  const {
    setSelectedBeneficiary,
    beneficiary,
    approveBeneficiary,
    handleOnRejectionClick,
  } = props;
  const classes = useStyles();
  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    setOpen,
  } = useActionDropdown({});
  const auth = useAuth();
  const [acknowledgeModalOpen, setAcknowledgeModalOpen] = useState(false);
  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const { flags } = useGetFeatureFlags();
  const isFraudModalEnabled = useMemo(() => (
    flags?.find((flag) => flag.name === 'fraudModalEnabled' && flag.isActive)
  ), [flags]);

  const handleModalConfirmation = () => {
    approveBeneficiary({
      batchId: beneficiary.beneficiaryId,
      accountBeneficiaryIds: [beneficiary.accountBeneId],
      type: 'BENEFICIARY',
      accountId: beneficiary?.accountId,
      fraudWarningAcknowledged: true,
    });
    setAcknowledgeModalOpen(false);
  };

  const handleApproveBene = async () => {
    const accountIsMicroEnterprise = allowedAccounts
      .some((acc) => acc.id === beneficiary?.accountId && acc.isMicroEnterprise === true);

    if (accountIsMicroEnterprise && isFraudModalEnabled) {
      setAcknowledgeModalOpen(true);
    } else {
      approveBeneficiary({
        batchId: beneficiary.beneficiaryId,
        accountBeneficiaryIds: [beneficiary.accountBeneId],
        type: 'BENEFICIARY',
        accountId: beneficiary?.accountId,
      });
    }
  };

  const handleViewBene = async () => {
    saveCurrentPageRoute();
    setSelectedBeneficiary(beneficiary);
  };

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    setMenuCloseCount(0);
  };

  const canCurrentUserApprove = (): boolean => {
    if ((beneficiary.uploadedBy !== auth.userInfo?.Name
      && beneficiary.userCanApprove)
      || (beneficiary.userCanApproveOwn
        && beneficiary.uploadedBy === auth.userInfo?.Name)
    ) {
      return true;
    }
    return false;
  };

  const viewBeneficiary = {
    id: 'action-bene-view',
    content: (
      <Typography data-testid="bene-action-view">
        View beneficiary
      </Typography>
    ),
    icon: faEye,
    onClick: () => { handleViewBene(); },
    underline: false,
  };

  const menuItems = [
    {
      ...viewBeneficiary,
      underline: true,
    },
    {
      id: 'action-bene-approve',
      content: (
        <Typography data-testid="bene-action-approve">
          Approve beneficiary
        </Typography>
      ),
      icon: faCheckCircle,
      onClick: () => { handleApproveBene(); },
      underline: true,
    },
    {
      id: 'action-bene-reject',
      content: (
        <Typography data-testid="bene-action-reject">
          Reject beneficiary
        </Typography>
      ),
      icon: faTimesCircle,
      onClick: () => { handleOnRejectionClick(beneficiary); },
      underline: false,
    },

  ];

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box className={classes.wrapper}>
          <Menu
            open={Boolean(open)}
            setOpen={setOpen}
            anchorEl={open}
            disableBackdropClick={menuCloseCount === 1}
            disableHoverEffect={false}
            onClose={handleClose}
            buttonProps={{
              style: {
                height: 30,
                width: 30,
                minWidth: 0,
              },
            }}
            buttonTitle=""
            icon={faEllipsisH}
          >
            {
              canCurrentUserApprove()
                ? menuItems.map((item) => (
                  <MenuDropdownItem key={item.id} setOpen={setOpen}>{item}</MenuDropdownItem>
                )) : (
                  <MenuDropdownItem setOpen={setOpen}>
                    {viewBeneficiary}
                  </MenuDropdownItem>
                )
            }
          </Menu>
        </Box>
      </ClickAwayListener>
      {
        acknowledgeModalOpen
        && (
          <Flags authorizedFlags={['fraudModalEnabled']}>
            <BeneFraudAcknowledgeModal
              handleConfirmation={handleModalConfirmation}
              handleClose={() => setAcknowledgeModalOpen(false)}
              acknowledgeModalOpen={acknowledgeModalOpen}
            />
          </Flags>
        )
      }
    </>
  );
};

export default ActionDropDown;
