import { Box } from '@mui/material';
import React from 'react';
import useStyles from './EmptyTable.styles';

export interface IEmptyTable {
  text?: string,
  title?: string,
  subtitle?: string,
  icon?: string,
  width?: string,
}

const EmptyTable: React.FC<IEmptyTable> = (props: IEmptyTable) => {
  const {
    text, title, subtitle, icon, width,
  } = props;
  const styles = useStyles();

  return (
    <Box className={styles.parent} width={width} textAlign="center">
      {text && <p className={styles.text}>{text}</p>}
      <Box className={styles.multiLine} data-testid="empty-table">
        {icon && (
          <Box className={styles.image}>
            <img src={icon} alt="empty-table" />
          </Box>
        )}
        {title && <p>{title}</p>}
        {subtitle && <p>{subtitle}</p>}
      </Box>
    </Box>
  );
};

export default EmptyTable;
