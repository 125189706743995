import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    borderBottom: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    padding: {
      padding: '70px 55px 25px 55px',
    },
    SemiBold: {
      fontWeight: typography.fontWeightSemiBold,
    },
    title: {
      fontSize: 22,
      letterSpacing: -0.5,
    },
    subtitle: {
      fontSize: 11,
      textTransform: 'uppercase',
      letterSpacing: 1.5,
      color: '#818181',
    },
    image: {
      overflow: 'hidden',
      borderRadius: '50%',
      marginRight: 27,
      width: 47,
      height: 47,
      border: '2px solid rgb(224,218,218)',
      position: 'relative',
      '& img': {
        width: 60,
        height: 60,
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
    },
  }),
  { name: 'HeaderText' },
);

export default useStyles;
