import React, { memo } from 'react';
import useStyles from './CurrencyHeader.styles';
import { Flag } from 'components/Flag';

interface IProps {
  currencyCode: string;
  currency: string;
  account?: string;
}

const CurrencyHeader: React.FC<IProps> = (props: IProps) => {
  const { currencyCode, currency, account } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Flag size="lg" code={currencyCode} rounded className={classes.tableIcon} />
      <div className={classes.info}>
        <p className={classes.currencyCode}>
          {!account ? <span className={classes.uppercase}>{currencyCode}</span> : <span>{account}</span>}
        </p>
        <p style={{ color: '#098375' }} className={classes.currencyName}>
          {currency}
        </p>
      </div>
    </div>
  );
};

export default memo(CurrencyHeader);
