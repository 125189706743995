import { Fade, Modal as MuiModal } from '@mui/material';
import React, { useEffect } from 'react';
import { TAuthyStatus } from '../../../store/authy/reducer';
import Body from './Body/Body';
import { useStyles } from './Modal.styles';

interface IModalProps {
  open: boolean;
  status: TAuthyStatus | undefined;
  onClose: () => void;
  handleComplete: (code: number) => void;
}
const Modal: React.FC<IModalProps> = ({
  open,
  status,
  onClose,
  handleComplete,
}: IModalProps) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MuiModal
      open={open}
      className={classes.container}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <div>
          <Body
            handleComplete={handleComplete}
            status={status}
          />
        </div>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
