import React from 'react';
import { Box, Button } from '@mui/material';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import { ActionButton, GhostButton } from 'components/Buttons';
import FooterWrapper from '../../../../../../components/Payments/FooterWrapper/FooterWrapper';
import navigateToStartOfJourney from '../../../../../../utils/navigateToStartOfJourney';
import useStyles from './BookFxFooter.styles';

interface IProps {
  batchName: string;
  modalOpen: boolean;
  handleClose: () => void;
  handleBookFxClick: () => void;
  handleRejectPayment: () => Promise<void>;
  handleRejectBatchButton: () => void;
  userCanReject: boolean;
  loading: boolean;
}

const BookFxFooter: React.FC<IProps> = ({
  batchName,
  modalOpen,
  handleClose,
  handleBookFxClick,
  handleRejectPayment,
  handleRejectBatchButton,
  userCanReject,
  loading,
}: IProps) => {
  const styles = useStyles();

  const handleExitClick = () => {
    navigateToStartOfJourney();
  };

  return (
    <>
      <FooterWrapper>
        <Button
          onClick={handleExitClick}
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          Exit
        </Button>
        <Box>
          <>
            {userCanReject && (
              <GhostButton
                className={styles.errorButton}
                onClick={handleRejectBatchButton}
                colorVariant="warning"
                data-testid="reject-batch-button"
              >
                REJECT BATCH
              </GhostButton>
            )}
          </>
          <ActionButton size="large" onClick={handleBookFxClick}>
            BOOK FX
          </ActionButton>
        </Box>
      </FooterWrapper>

      <DecisionModal
        open={modalOpen}
        title={`Reject ${batchName.length > 35 ? ' Batch' : `${batchName}`}`}
        subtitle="You're about to reject this batch.
        The payment will not be processed."
        actionButtonText="YES, REJECT BATCH"
        handleActionButton={handleRejectPayment}
        closeButtonText="CANCEL"
        handleClose={handleClose}
        icon={faExclamationTriangle}
        loading={loading}
      />
    </>
  );
};

export default BookFxFooter;
