import { makeStyles } from 'theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '40px',
    '& h1': {
      margin: 0,
      marginRight: '16px',
    },
    '& span': {
      height: 'fit-content',
      position: 'relative',
      top: '2px',
    },
  },
}, { name: 'Title' });

export default useStyles;
