import { Typography } from '@mui/material';
import React from 'react';
import Dropdown from '../../../../../../components/InterAccountTransfer/Dropdown/Dropdown';
import { TCurrencyAccount } from '../../../../../../models/currencyAccounts';
import useStyles from './Dropdown.styles';

interface IProps {
  selectedDebitingAccount?: TCurrencyAccount;
  handleSetSelectedDebitingAccount: (event: any) => void;
  currencyAccounts: TCurrencyAccount[];
  dropdownDisabled: boolean;
  loading: boolean;

}
const FundingAccountDropdown: React.FC<IProps> = ({
  selectedDebitingAccount,
  handleSetSelectedDebitingAccount,
  currencyAccounts,
  dropdownDisabled,
  loading,
}: IProps) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div>
        <Typography variant="subtitle1" className="label">Funding Account</Typography>
        <Dropdown
          disabled={dropdownDisabled}
          currencyAccounts={currencyAccounts}
          handleBlur={() => { }}
          handleChange={handleSetSelectedDebitingAccount}
          helperText=""
          placeholderText="Please select funding account"
          selectName="funding-account"
          data-testid="funding-account"
          selectValue={selectedDebitingAccount?.id}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FundingAccountDropdown;
