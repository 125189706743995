import { Box, Typography } from '@mui/material';
import Status from 'components/Status/Status';
import React from 'react';
import clsx from 'clsx';
import KeyValueTable from 'components/Table/KeyValueTable/KeyValueTable';
import { TKeyValue } from 'components/Table/Table.interfaces';
import { TTransactionData } from '../../../../../models/transactions';
import BeneficiaryDetails from './BeneficiaryDetails/BeneficiaryDetails';
import useStyles from './BodyDetails.styles';
import CreditingDetails from './CreditingDetails/CreditingDetails';
import DebitingDetails from './DebitingDetails/DebitingDetails';
import PaymentDetails from './PaymentDetails/PaymentDetails';

interface IProps {
  transaction: TTransactionData;
  entityAccountDetails: TKeyValue[];
  tradeDetails: TKeyValue[];
  debitingDetails: TKeyValue[];
  creditingDetails: TKeyValue[];
  referenceDetails: TKeyValue[];
  beneficiaryDetails: TKeyValue[];
  paymentDetails: TKeyValue[];
  paymentTimeline: TKeyValue[];
  paymentId: string | undefined;
}

const BodyDetails = (props: IProps) => {
  const classes = useStyles();
  const {
    transaction,
    entityAccountDetails,
    tradeDetails,
    debitingDetails,
    creditingDetails,
    referenceDetails,
    beneficiaryDetails,
    paymentDetails,
    paymentTimeline,
    paymentId,
  } = props;

  return (
    <Box className={classes.body}>
      <Box className={clsx(classes.paymentStatusWrapper, 'dd-privacy-allow')}>
        <Typography className={classes.text}>Payment Status</Typography>
        <Status variant="success">Complete</Status>
      </Box>
      {paymentId
        && (
          <Box>
            <KeyValueTable
              testId="transaction-drawer-entityAccount"
              data={entityAccountDetails}
            />
          </Box>
        )}
      <BeneficiaryDetails
        paymentId={paymentId}
        beneficiaryDetails={beneficiaryDetails}
      />
      {transaction.tradeId && !paymentId && (
        <KeyValueTable
          className={classes.tradeDetails}
          testId="transactions-drawer-tradeId"
          data={tradeDetails}
          obscure
        />
      )}
      {
        !paymentId
          ? (
            <>
              <DebitingDetails
                transaction={transaction}
                debitingDetails={debitingDetails}
              />
              <CreditingDetails
                transaction={transaction}
                creditingDetails={creditingDetails}
              />
              <KeyValueTable
                testId="iat-reference"
                data={referenceDetails}
                obscure
              />
            </>
          )
          : (
            <PaymentDetails
              paymentId={paymentId}
              paymentDetails={paymentDetails}
              paymentTimeline={paymentTimeline}
            />
          )
      }
    </Box>
  );
};

export default BodyDetails;
