import { ITableColumn } from '../../../../../services/DatabaseServices/marker.interface';
import { BatchQuoteDtoDecorated } from '../../Funding.interface';

export const columns: ITableColumn[] = [
  {
    header: 'Beneficiary',
    accessor: 'beneficiaryName',
  },
  {
    header: 'Payment Ref',
    accessor: 'paymentRef',
    className: 'dd-privacy-allow',
  },
  {
    header: 'Value Date',
    accessor: 'valueDate',
  },
  {
    header: 'Sell Currency',
    accessor: 'sellCurrency',
  },
  {
    header: 'Buy Currency',
    accessor: 'buyCurrency',
  },
  {
    header: 'Fixed Side',
    accessor: 'fixedSide',
  },
  {
    header: 'Fund Amount',
    accessor: 'amount',
  },
  {
    header: 'Indicative Rate',
    accessor: 'indicativeRate',
  },
  {
    header: 'Send Amount',
    accessor: 'sendAmount',
  },
];

export const liveColumns: ITableColumn[] = [
  {
    header: 'Beneficiary',
    accessor: 'beneficiaryName',
  },
  {
    header: 'Payment Ref',
    accessor: 'paymentRef',
    className: 'dd-privacy-allow',
  },
  {
    header: 'Value Date',
    accessor: 'valueDate',
  },
  {
    header: 'Sell Currency',
    accessor: 'sellCurrency',
  },
  {
    header: 'Buy Currency',
    accessor: 'buyCurrency',
  },
  {
    header: 'Fixed Side',
    accessor: 'fixedSide',
  },
  {
    header: 'Fund Amount',
    accessor: 'amount',
  },
  {
    header: 'Live Rate',
    accessor: 'liveRate',
  },
  {
    header: 'Send Amount',
    accessor: 'sendAmount',
  },
];

export const getColumns = (liveRates: BatchQuoteDtoDecorated[]) => (liveRates.length > 0
  ? liveColumns : columns);
