import styled from '@mui/styled-engine';
import theme from 'theme';
import BaseButton from '../BaseButton';

const { palette } = theme;

const StyledOutlinedButton = styled(BaseButton)({
  borderColor: palette.secondary.light,
  width: 'max-content',
  '&:hover': {
    borderColor: palette.secondary.dark,
    backgroundColor: palette.grey.A200,
  },
});

export default StyledOutlinedButton;
