import { AccountDto } from '@alpha/auth-dtos';
import {
  useRef, useState, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { iAllowedAccounts } from 'store/accounts/accounts.reducer';
import { TStore } from 'store';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import { initiateBeneficiaryBatch } from '../../../../store/authy/actions';

const useShareBeneficiary = (accountBeneId: string, availableAccounts: AccountDto[],
  beneAccountId: string) => {
  const searchTerm = useRef<string>('');
  const [
    displayingAccounts,
    setDisplayingAccounts,
  ] = useState<AccountDto[]>(availableAccounts || []);
  const [selectedAccounts, setSelectedAccounts] = useState<AccountDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [acknowledgeModalOpen, setAcknowledgeModalOpen] = useState(false);
  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );

  const { flags } = useGetFeatureFlags();
  const isFraudModalEnabled = useMemo(() => (
    flags?.some((flag) => flag.name === 'fraudModalEnabled' && flag.isActive)
  ), [flags]);

  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();

  useEffect(() => {
    setDisplayingAccounts(availableAccounts);
    setSelectedAccounts([]);
  }, [availableAccounts]);

  const handleShareBeneficiarySubmit = async () => {
    const accountIds = selectedAccounts.map((sa) => sa.id);
    try {
      const showFraudModal = shouldShowFraudModal(accountIds);
      if (showFraudModal) {
        setAcknowledgeModalOpen(true);
      } else {
        setLoading(true);
        dispatch(initiateBeneficiaryBatch({
          batchId: accountBeneId,
          accountBeneId,
          accountIds,
          type: 'BENEFICIARY_BATCH',
          accountId: beneAccountId,
        }));
      }
    } catch (e) {
      sb.trigger(e.message || e.toString() || 'Something went wrong sharing your accounts');
    } finally {
      setLoading(false);
    }
  };

  const handleFraudAcknowledge = async () => {
    const accountIds = selectedAccounts.map((sa) => sa.id);
    dispatch(initiateBeneficiaryBatch({
      batchId: accountBeneId,
      accountBeneId,
      accountIds,
      type: 'BENEFICIARY_BATCH',
      accountId: beneAccountId,
      fraudWarningAcknowledged: true,
    }));
    setAcknowledgeModalOpen(false);
  };

  const shouldShowFraudModal = (accountIds: string[]) => {
    if (!isFraudModalEnabled) return false;

    let accountsContainMicroEnterprise = false;

    accountIds.forEach((accId) => {
      const accountIsMicroEnterprise = allowedAccounts
        .some((acc) => acc.id === accId && acc.isMicroEnterprise === true);

      if (accountIsMicroEnterprise) {
        accountsContainMicroEnterprise = true;
      }
    });

    return accountsContainMicroEnterprise;
  };

  const getFilterSearch = (input: string, arr: AccountDto[]) => arr.filter(
    (accountToShare: AccountDto) => accountToShare.name.toLowerCase()
      .includes(input.toLowerCase()),
  );

  const handleShareKeyUp = (e: any): void => {
    const { value } = e.target;
    searchTerm.current = value;
    setDisplayingAccounts(getFilterSearch(value, availableAccounts));
  };

  const handleSelectAccount = (accountId: string) => {
    const selectedAccountIndex = selectedAccounts.findIndex((sa) => sa.id === accountId);

    if (selectedAccountIndex > -1) {
      setSelectedAccounts(selectedAccounts.filter((sa) => sa.id !== accountId));
      return;
    }

    const findAccount = availableAccounts.find(
      (accountDto: AccountDto) => accountDto.id === accountId,
    );

    if (findAccount) {
      setSelectedAccounts([...selectedAccounts, findAccount]);
    }
  };
  const handleAllAccounts = () => {
    if (selectedAccounts.length === availableAccounts.length) {
      setSelectedAccounts([]);
      return;
    }
    setSelectedAccounts(availableAccounts);
  };

  return {
    selectedAccounts,
    availableAccounts,
    displayingAccounts,
    searchTerm,
    loading,
    handleSelectAccount,
    handleAllAccounts,
    handleShareKeyUp,
    handleShareBeneficiarySubmit,
    handleFraudAcknowledge,
    acknowledgeModalOpen,
    setAcknowledgeModalOpen,
  };
};

export default useShareBeneficiary;
