import { MenuItem, Select, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import APDatePicker from 'components/APDatePicker/APDatePicker';
import useStyles from '../../../../../../../../components/InterAccountTransfer/Dropdown/Dropdown.styles';
import useAlphaSnackbar from '../../../../../../../../hooks/useAlphaSnackbar';
import { TCurrencyAccount } from '../../../../../../../../models/currencyAccounts';
import { TDate, TPayment } from '../../../../../../../../models/payments';
import PaymentsService from '../../../../../../../../services/Payments/payments.service';

interface IProps {
  requiresFx: boolean;
  setCurrentPayment: React.Dispatch<React.SetStateAction<TPayment>>;
  setDatesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDebitingAccount: TCurrencyAccount;
  currentPayment: TPayment;
  debitingCurrencyCode: string;
}

const PaymentDate: React.FC<IProps> = ({
  requiresFx,
  currentPayment,
  debitingCurrencyCode,
  selectedDebitingAccount,
  setDatesLoaded,
  setCurrentPayment,
}: IProps) => {
  const styles = useStyles();
  const sb = useAlphaSnackbar();
  const [dates, setDates] = useState<TDate>();

  useEffect(() => {
    let isCancelled = false;
    getAvailableDates(isCancelled);

    return () => {
      isCancelled = true;
    };
  }, [currentPayment.beneficiary?.currencyCode]);

  const getAvailableDates = async (isCancelled: boolean) => {
    try {
      setDatesLoaded(false);
      if (!currentPayment.beneficiary) return;

      const newDates = await PaymentsService.getAvailablePaymentDate(
        currentPayment.beneficiary?.currencyCode,
        debitingCurrencyCode,
        currentPayment.beneficiary?.accountId,
      );

      if (!isCancelled) {
        handleDateChange(moment(newDates.earliestDate));
        setDates(newDates);
        setDatesLoaded(true);
      }
    } catch {
      sb.trigger(
        'Something went wrong when retrieving all available dates for your payment',
      );
    }
  };

  const calculateDisableDates = (_date: Moment) => {
    if (dates) {
      const formattedDate = _date.format('YYYY-MM-DD');

      return (
        moment(formattedDate).isBefore(dates.earliestDate)
        || moment(formattedDate).isAfter(dates.latestDate)
        || dates.holidays.some((holiday) => moment(holiday.date).format('YYYY-MM-DD') === formattedDate)
      );
    }
    return false;
  };

  const handleDateChange = (_date: Moment) => {
    if (_date) {
      setCurrentPayment({
        ...currentPayment,
        date: _date.format('YYYY-MM-DD'),
      });
    }
  };

  const handleFxSideChange = (event: any) => {
    setCurrentPayment({ ...currentPayment, fixedSide: event.target.value });
  };

  if (!dates) {
    return <></>;
  }

  return (
    <div className="column">
      <APDatePicker
        label="Date"
        className="datePicker"
        onChange={handleDateChange}
        value={currentPayment.date || dates.earliestDate}
        placeholder="Payment date"
        shouldDisableDate={calculateDisableDates}
      />
      {
        requiresFx
        && (
          <div data-testid="fixedSide">
            <Typography variant="subtitle1" className="label">
              Fixed Side
            </Typography>
            <Select
              onChange={handleFxSideChange}
              value={
                currentPayment.fixedSide || 'placeholder'
              }
              fullWidth
              className={styles.dropdown}
            >
              {[
                currentPayment.beneficiary!.currencyCode,
                selectedDebitingAccount.currencyCode!,
              ].map((currencyCode: string) => (
                <MenuItem value={currencyCode}>
                  <Typography variant="subtitle1">{currencyCode}</Typography>
                </MenuItem>
              ))}
              <MenuItem disabled value="placeholder">
                <Typography variant="subtitle1" className="placeholder">Fixed side</Typography>
              </MenuItem>
            </Select>
          </div>
        )
      }
    </div>
  );
};

export default PaymentDate;
