import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, CircularProgress } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { UserAccount, UserRoleRequest } from '@portal/user-types';
import Modal from '../../../../components/Modals/Modal';
import {
  StyledHeader,
  StyledCloseButton,
  StyledFooter,
  StyledBackButton,
  StyledRemoveButton,
  StyledModalContent,
  StyledLi,
  StyledUl,
} from './index.styles';
import UserManagementService from '../../../../services/UserManagement/userManagement.service';
import { TUser } from '../../context/index.interfaces';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import timer from '../../../../utils/timer';

export type IRemoveAccessModal = {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    user: TUser;
    manageableEntities: UserAccount[];
    removingAccessToAll?: boolean;
}

const RemoveAccessModal = (
  {
    openModal, user, manageableEntities, setOpenModal, removingAccessToAll = false,
  }:IRemoveAccessModal,
) => {
  const sb = useAlphaSnackbar();
  const sbRef = useRef(sb);

  const [loading, setIsLoading] = useState(false);

  const removeAccess = useCallback(async (
    currentUser: TUser,
    entitiesList: UserAccount[],
  ) => {
    if (currentUser) {
      const userRoleRequests: UserRoleRequest[] = [];
      entitiesList.forEach((selectedEntity) => {
        userRoleRequests.push({
          userId: currentUser?.id,
          entityId: selectedEntity.id,
          roles: [],
          approvalLevel: null,
        });
      });
      const splitUserRoleRequests = splitArrayIntoMaxChunks(userRoleRequests, 25);

      splitUserRoleRequests.forEach(async (userRoleRequest) => {
        if (userRoleRequest.length > 0) {
          await UserManagementService.createUserRoleRequest({
            userRoleRequest,
          });
        }
      });

    }
    return { message: 'No changes detected', type: 'info' };
  }, []);

  function splitArrayIntoMaxChunks<T>(array: T[], maxLength: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += maxLength) {
        chunks.push(array.slice(i, i + maxLength));
    }
    return chunks;
  }

  const handleRemoveAccess = useCallback(async () => {
    try {
      setIsLoading(true);
      await removeAccess(user as TUser, manageableEntities);
      await timer(1000);
      setOpenModal(false);
      setIsLoading(false);
      sbRef.current.trigger('Successfully submitted a remove access request', 'success');
    } catch (e) {
      sbRef.current.trigger('Something went wrong when sending the request');
      setIsLoading(false);
    }
  }, [manageableEntities, removeAccess, setOpenModal, user]);

  return (
    <Modal
      open={openModal}
      separator
      header={(
        <StyledHeader>
          <Typography variant="h5">
            Are you sure you want to remove
            {' '}
            {removingAccessToAll ? ('access to all entities?') : ("this user's access?")}
          </Typography>
          <StyledCloseButton
            disabled={loading}
            onClick={() => setOpenModal(false)}
          >
            <FontAwesomeIcon data-testid="modal-close-button" size="lg" icon={faTimes} />
          </StyledCloseButton>
        </StyledHeader>
    )}
      footer={(
        <StyledFooter>
          <StyledBackButton
            disabled={loading}
            data-testid="modal-back-button"
            onClick={() => setOpenModal(false)}
          >
            Back
          </StyledBackButton>
          <StyledRemoveButton
            variant="contained"
            data-testid="modal-action-button"
            disableElevation
            disabled={loading}
            type="submit"
            onClick={() => {
              handleRemoveAccess();
            }}
            {...(loading && { endIcon: <CircularProgress data-testid="circular-loader" color="inherit" size={12} /> })}
          >
            Remove Access
          </StyledRemoveButton>
        </StyledFooter>
        )}
    >
      <StyledModalContent>
        {removingAccessToAll && (
          <Typography variant="subtitle1">
            Please note if this user has access to any other entities not managed by you,
            then they will still have access to those entities.
          </Typography>
        )}
        <Typography variant="subtitle2">
          {manageableEntities.length > 1 ? 'Entities' : 'Entity'}
          {' '}
          that access will be removed for:
        </Typography>
        <StyledUl>
          {manageableEntities.map((entity) => (
            <StyledLi key={entity?.id}>{entity?.name}</StyledLi>
          ))}
        </StyledUl>
      </StyledModalContent>
    </Modal>
  );
};

export default RemoveAccessModal;
