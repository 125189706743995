import {
  Box,
} from '@mui/material';
import { Input } from 'components/Inputs/Input';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useStyles } from './index.styles';

interface ISearchProps {
    handleSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }

const SearchField: React.FC<ISearchProps> = (props: ISearchProps) => {
  const {
    handleSearchInput,
  } = props;
  const classes = useStyles();

  return (
    <Box key="search-container" className={classes.searchContainer}>
      <Input
        onChange={handleSearchInput}
        fullWidth
        testId="search-inputs-test"
        onKeyDown={(e) => e.stopPropagation()}
        InputProps={
             {
               className: classes.searchField,
               endAdornment: <FontAwesomeIcon className={classes.searchIcon} icon={faSearch} />,
             }
           }
        variant="filled"
        placeholder="Search entities"
      />
    </Box>
  );
};

export default SearchField;
