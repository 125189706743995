import {
  Box, Input, InputLabel, Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { memo } from 'react';
import Status from 'components/Status/Status';
import { getCurrencyDecimalPlaces, getCurrencyPlaceholder } from 'utils/currency.helpers';
import { TIATAccountTypes } from '../../../models/interAccountTransfer';
import useStyles from './AmountAndBalance.styles';
import Tooltip from '../../Tooltip/Tooltip';
import FlagImage from 'components/Flag/FlagImage';

interface IProps {
  fixedSideIndicator: string;
  type: TIATAccountTypes;
  actionBalanceId: string;
  actionBalanceLabel: string;
  amount: number | undefined;
  startAdornment: React.ReactNode;
  currencySymbol: string;
  formattedBalance: string;
  disabled: boolean | undefined;
  disableTooltip: boolean;
  tooltipText: string;
  currencyCode: string | undefined;
  friendlyName: string | undefined;
  handleChange?: (event: any) => void;
  handleBlur?: (event: any) => void;
  handleOnKeyDown?: (event: any) => void;
}

const AmountAndBalance: React.FC<IProps> = (props: IProps) => {
  const {
    fixedSideIndicator,
    type,
    actionBalanceId,
    actionBalanceLabel,
    amount,
    startAdornment,
    currencySymbol,
    formattedBalance,
    currencyCode,
    friendlyName,
    disabled,
    disableTooltip,
    tooltipText,
    handleChange,
    handleBlur,
    handleOnKeyDown,
  } = props;

  const currencyDecimals = getCurrencyDecimalPlaces(currencyCode);
  const placeholder = getCurrencyPlaceholder(currencyCode);

  const handleInputBlur = (event: any) => {
    try {
      if (event.target.value) {
        event.target.value = parseFloat(event.target.value).toFixed(currencyDecimals);
        if (handleChange) handleChange(event);
        if (handleBlur) handleBlur(event);
      }
    } catch {
      if (handleChange) handleChange(event);
      if (handleBlur) handleBlur(event);
    }
  };

  const actionLabel = (): JSX.Element | string => {
    if (disabled && currencyCode && friendlyName) {
      return (
        <Box className={styles.liveRateLabel}>
          <Typography className="dd-privacy-allow" variant="subtitle1">
            I will
            {' '}
            {actionBalanceLabel}
          </Typography>
          <Box className={styles.imageHolder}>
            <FlagImage currencyCode={currencyCode} />
          </Box>
          <Typography variant="subtitle1" className={styles.bold}>
            {friendlyName}
          </Typography>
        </Box>
      );
    }
    return (
      <Typography className="dd-privacy-allow" variant="subtitle1">
        I will
        {' '}
        <b>{actionBalanceLabel}</b>
      </Typography>
    );
  };

  const styles = useStyles();
  return (
    <Box className={clsx(styles.inputAmount, styles.root)}>
      <Box className={styles.willDebit}>
        <InputLabel htmlFor={actionBalanceId}>{actionLabel()}</InputLabel>
        <Box className={clsx(styles.amountInput, 'dd-privacy-allow')}>
          <Tooltip
            placement="bottom-start"
            title={disableTooltip ? tooltipText : ''}
          >
            <Input
              className={clsx(
                actionBalanceLabel === 'debit' && styles.red,
                actionBalanceLabel === 'credit' && styles.green,
              )}
              id={actionBalanceId}
              name={actionBalanceId}
              value={disabled && amount ? parseFloat(`${amount}`).toFixed(2) : amount}
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleInputBlur}
              autoComplete="off"
              placeholder={placeholder}
              startAdornment={startAdornment}
              type="number"
              onKeyDown={handleOnKeyDown}
              inputProps={{
                'data-testid': actionBalanceLabel === 'debit' ? 'debitAmount' : 'creditAmount',
              }}
            />
          </Tooltip>
          <Status
            className={fixedSideIndicator === 'inactive' ? styles.fixedIndicatorInactive
              : fixedSideIndicator === 'active' ? styles.fixedIndicatorActive : styles.fixedIndicatorHidden}
          >
            Fixed
          </Status>
        </Box>
        <Box className={clsx(styles.balanceAfter, 'dd-privacy-allow')}>
          <Typography variant="subtitle2">
            Balance after:
            <span className={clsx(type === 'DEBITING' ? styles.red : styles.green)}>
              {`${currencySymbol}${formattedBalance}`}
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(AmountAndBalance);
