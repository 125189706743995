import {
  FormControlLabel, Checkbox, Box, Collapse,
} from '@mui/material';
import React, { useState } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from 'components/Buttons';
import { GroupViewDropdownOptions, AccountsViewDropdownOptions } from '../helpers';
import AccountsDropdown from './AccountsDropdown';
import useStyles from './index.styles';

interface IGroupsCheckboxesProps {
    groupMenuOptions: GroupViewDropdownOptions;
    accountMenuOptions: AccountsViewDropdownOptions[];
    handleChange: (
      event: React.ChangeEvent<HTMLInputElement>,
      accountId: string
    ) => void;
    handleClickGroup: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedOptions: string[];
  }

const GroupsCheckboxes: React.FC<IGroupsCheckboxesProps> = (
  props: IGroupsCheckboxesProps,
) => {
  const {
    groupMenuOptions,
    accountMenuOptions,
    handleChange,
    handleClickGroup,
    selectedOptions,
  } = props;
  const classes = useStyles();
  const [showEntitiesInGroup, setShowEntitiesInGroup] = useState(false);

  const getAccountsInGroup = accountMenuOptions
    .filter((account1) => groupMenuOptions.entities
      .find((account2) => account1.id === account2.id));

  const selected = groupMenuOptions.entities
    .every((accountId) => selectedOptions
      .includes(accountId.id));

  const selectedAccountsInGroup = getAccountsInGroup.filter((account) => account.selected).length;

  const toggleEntitiesInGroup = () => setShowEntitiesInGroup(!showEntitiesInGroup);

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleClickGroup(e);

    const { checked } = e.target;
    if (checked) { setShowEntitiesInGroup(true); }
  };

  if (getAccountsInGroup?.length > 0) {
    return (
      <div>
        <div className={classes.groupMenuOption} role="button" tabIndex={0} aria-hidden="false">
          <FormControlLabel
            label={`${groupMenuOptions.groupName} (${selectedAccountsInGroup}/${getAccountsInGroup?.length})`}
            control={(
              <Checkbox
                onChange={handleCheckboxClick}
                color="primary"
                checked={selected}
              />
            )}
          />
          <IconButton
            icon={showEntitiesInGroup ? faAngleUp : faAngleDown}
            aria-label={`Expand ${groupMenuOptions.groupName}`}
            onClick={toggleEntitiesInGroup}
            className={classes.groupMenuChevronBtn}
          />
        </div>
        <Collapse in={showEntitiesInGroup} timeout="auto" unmountOnExit>
          <Box>
            <AccountsDropdown
              handleChange={handleChange}
              accountMenuOptions={getAccountsInGroup}
              nested
            />
          </Box>
        </Collapse>
      </div>
    );
  }
  return null;
};
export default GroupsCheckboxes;
