import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { TCurrencyAccount } from '../../../../models/currencyAccounts';
import { useStyles } from './Item.styles';
import FlagImage from 'components/Flag/FlagImage';

interface IProps {
  currencyAccount: TCurrencyAccount;
}
const Data: React.FC<IProps> = (props: IProps) => {
  const { currencyAccount } = props;
  const styles = useStyles();

  return (
    <Box className={clsx(styles.root, styles.item)}>
      <Typography variant="subtitle1">
        {currencyAccount.friendlyName}
      </Typography>
      <Typography variant="subtitle1" className={styles.bankInfo}>
        {currencyAccount.iban
          || currencyAccount.swift
          || currencyAccount.accountNumber
          || currencyAccount.sortCode
          || currencyAccount.reference}
      </Typography>
    </Box>
  );
};

interface IStickyProps {
  currencyCode: string;
}
const Sticky: React.FC<IStickyProps> = (props: IStickyProps) => {
  const { currencyCode } = props;
  const styles = useStyles();

  return (
    <Box className={clsx(styles.root, styles.sticky)}>
      <Box className={styles.imageHolder}>
        <FlagImage currencyCode={currencyCode} />
      </Box>
      <Typography variant="subtitle1">{currencyCode}</Typography>
    </Box>
  );
};

export const Item = {
  Data,
  Sticky,
};

export default Data;
