import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    fxRate: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      marginBottom: 50,
      paddingBottom: 12,
      display: 'flex',
      justifyContent: 'space-between',
    },
    divider: {
      borderRight: '1px solid rgba(112, 112, 112, 0.4)',
      paddingRight: 12,
      marginRight: 12,
    },
    counterRate: {
      fontWeight: typography.fontWeightRegular,
      color: '#212529',
    },
  }),
  { name: 'FxRate' },
);

export default useStyles;
