import { Typography, Box, Button } from '@mui/material';
import { faCheckCircle, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Status from 'components/Status/Status';
import KycModal from 'domain/OutstandingActionsDashboard/KYCModal/KycModal';
import useBannerRefresh from 'hooks/useBannerRefresh';
import { Flags } from 'react-feature-flags';
import { AccountStatus, IncompleteProfileReason } from 'services/Authorization/constants';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useProfile from '../../../../../hooks/useProfile';
import useSwitchAccount from '../../../../../hooks/useSwitchAccount';
import { TUserAccount } from '../../../../../models/user';
import browserHistory from '../../../../../services/history/browserHistory';
import { actions } from '../../../../../store/user/user.reducer';
import useStyles from './Account.styles';

interface IProps {
  account: TUserAccount;
  filterAccounts: string;
  setFilterAccounts: (val: string) => void;
  onClose: (event: any) => void;
}

const Account: React.FC<IProps> = (props: IProps) => {
  const {
    account, filterAccounts, onClose, setFilterAccounts,
  } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const { updateUserProfile } = useProfile();
  const { name, id } = account;
  const { updateCurrentAccount, currentAccount } = useSwitchAccount();
  const sb = useAlphaSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [isProfileIncomplete, setIsProfileIncomplete] = useState<boolean>(false);

  const classes = useStyles();

  const {
    frozen,
    overdue,
    due,
  } = useBannerRefresh();

  const getBasePath = (fullPath: string): string => {
    try {
      const newPath = fullPath.split('/', 3);
      return newPath.join('/');
    } catch {
      return '/app';
    }
  };

  const handleButtonClick = (): void => {
    sb.trigger(`Switched accounts to ${account.name}`, 'success');
    updateCurrentAccount(account);
    updateUserProfile();
    browserHistory.push(getBasePath(location.pathname));
    dispatch(actions.updateKey());
    onClose(false);
    setFilterAccounts('');
  };

  useEffect(() => {
    setIsProfileIncomplete(account.status === AccountStatus.INCOMPLETE
      && account.incompleteProfileReason === IncompleteProfileReason.OUTSTANDING_PAYMENT_PROFILE);
  }, [account.status, account.incompleteProfileReason]);

  return (
    <>
      {
        name.toLowerCase().includes(filterAccounts.toLowerCase())
          && currentAccount
          ? (
            <Box className={id === currentAccount.id
              ? classes.currentAccountWrapper : classes.accountWrapper}
            >
              <Box className={classes.accountDetails}>
                <Typography className={classes.accountName}>{name}</Typography>
                <Typography className={classes.accountDescription}>Admin</Typography>
                { account.accountFrozen && (
                  <div className={classes.frozenStatus}>
                    <Status className={classes.frozenStatus} onClick={setOpen} size="small" variant="error">
                      Frozen
                      <FontAwesomeIcon
                        size="sm"
                        className={classes.questionIcon}
                        icon={faQuestionCircle}
                      />
                    </Status>
                  </div>
                )}
                { isProfileIncomplete && (
                  <Flags authorizedFlags={['incompleteProfile']}>
                    <div className={classes.incompleteStatus}>
                      <Status className={classes.incompleteStatus} size="small" variant="incomplete">
                        Incomplete
                      </Status>
                    </div>
                  </Flags>
                )}
                <KycModal
                  due={due}
                  overdue={overdue}
                  frozen={frozen}
                  modalOpen={open}
                  onClose={setOpen}
                />
              </Box>
              {
                currentAccount
                  && id === currentAccount.id
                  ? (
                    <Box className={classes.selectedAccount}>
                      <span>Selected</span>
                      <FontAwesomeIcon className={classes.tickIcon} icon={faCheckCircle} />
                    </Box>
                  )
                  : (
                    <Button data-testid={`test-account-${id}`} onClick={() => handleButtonClick()} className={classes.button}>
                      <span>View</span>
                    </Button>
                  )
              }
            </Box>
          )
          : <></>
      }
    </>
  );
};

export default Account;
