import { PaymentBatchDraftStatus, PaymentBatchStatus, PaymentDraftDto, PaymentReleasedDto } from '@alpha/payments-dtos';
import { PaymentStatus } from '@alpha/payments-dtos/lib/paymentsReleased/PaymentStatus';
import { Box, Button } from '@mui/material';
import { faDownload, faRedoAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AcknowledgeModal from 'components/Modals/AcknowledgeModal';
import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { TStatusVariants } from 'components/Status';
import Status from 'components/Status/Status';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import APPagination from 'components/APPagination';
import { scheduledBaseUrl } from 'domain/PaymentsMultiEntity/Dashboard/ScheduledTableContainer/constants';
import usePaymentBatchSearch from 'domain/PaymentsMultiEntity/usePaymentBatchSearch';
import { Drawer } from 'components/Drawer';
import PaymentsDrawerHead from 'domain/PaymentsMultiEntity/ManualPayments/Body/BatchPayments/PaymentsTable/PaymentsDrawer/PaymentsDrawerHead';
import PaymentsDrawer from 'domain/PaymentsMultiEntity/ManualPayments/Body/BatchPayments/PaymentsTable/PaymentsDrawer/PaymentsDrawer';
import { TStandingOrder } from 'models/payments';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { Table as GenericTable } from '../../../../../components/Table/Table';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../hooks/useReportsPolling';
import { TSearchParams } from '../../../../../hooks/useSearch';
import { formatNumber } from '../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import { PaymentSummaryBeneficiary } from '../PaymentSummary';
import { getColumns } from './columns';
import useStyles from './Table.styles';
import { ISelectedAccount } from '../../../../CurrencyAccounts/AccountInfoDrawer/AccountInfoDrawer';
import { formatFrequency } from 'domain/PaymentsMultiEntity/ManualPayments/Body/BatchPayments/PaymentsTable/formatFrequency';
import { IconSOStyled } from 'domain/PaymentsMultiEntity/ManualPayments/Body/BatchPayments/PaymentsTable/PaymentsTable.styles';
import { PaymentFeatureFlags } from 'domain/PaymentsMultiEntity/ManualPayments/constants';

interface IProps {
  data: Array<PaymentDraftDto | PaymentReleasedDto>;
  isSameCurrency: boolean;
  postApproval: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  handleNextPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handlePreviousPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  setBeneficiary: React.Dispatch<
    React.SetStateAction<PaymentSummaryBeneficiary>
  >;
  setSelectedCurrencyAccount: React.Dispatch<React.SetStateAction<ISelectedAccount | undefined>>
  actionNeeded: boolean;
  batchStatus: PaymentBatchDraftStatus | PaymentBatchStatus;
}

const isPaymentDraftDto = (
  param: PaymentDraftDto | PaymentReleasedDto,
): param is PaymentDraftDto => {
  if ((param as PaymentDraftDto).beneficiaryDraft) {
    return true;
  }
  return false;
};

const getPaymentStatusVariant = (status: PaymentStatus): TStatusVariants | undefined => {
  switch (status) {
    case PaymentStatus.RETURNED:
    case PaymentStatus.CANCELLED:
    case PaymentStatus.REJECTED:
      return 'error';
    case PaymentStatus.RELEASED:
      return 'success';
    case PaymentStatus.PENDING:
      return 'orange';
    default:
      return 'info';
  }
};

const Table: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();
  const {
    data,
    hasNext,
    hasPrevious,
    postApproval,
    isSameCurrency,
    handleNextPage,
    handlePreviousPage,
    setBeneficiary,
    setSelectedCurrencyAccount,
    actionNeeded,
    batchStatus,
  } = props;
  const batchId = data[0]?.batchId;
  const { flags } = useGetFeatureFlags();
  const standingOrderFFEnabled = flags?.find((flag) => (
    flag.name === PaymentFeatureFlags.STANDING_ORDER && flag.isActive
  ))?.isActive;

  // 3 is SCHEDULED, and we need to retrieve Standing Order for the batch
  // It can be either StandingOrder batch or further batches that created base on it
  const { search, searchParams } = usePaymentBatchSearch(3, flags);
  const [standingOrder, setStandingOrder] = useState<TStandingOrder | null>(null);
  const [viewPaymentDetails, setViewPaymentDetails] = useState<boolean>(false);

  const [missingDocsPayment, setMissingDocsPayment] = useState<any>(null);

  const { executeReportGeneration } = useReportsPolling(PageType.Payments);

  const handleReportGeneration = async (id: string, accountId: string) => {
    await executeReportGeneration(
      FileType.PDF, id, undefined, undefined, DownloadType.Single, accountId,
    );
  };

  useEffect(() => {
    if (standingOrderFFEnabled && batchId) {
      search.handleNewSearch({
        ...searchParams,
        baseUrl: `${scheduledBaseUrl}/search/${batchId}`,
      }, true);
    }
  }, []);

  useEffect(() => {
    const items = search?.items?.items as unknown as TStandingOrder[];

    if (items && items.length !== 0) {
      setStandingOrder(items[0] as TStandingOrder);
    } else {
      setStandingOrder(null);
    }
  }, [search, data]);

  const tableData = data.map(
    (item: PaymentDraftDto | PaymentReleasedDto) => {
      if (actionNeeded) {
        const hasMissingDocuments = item.awaitingClientInput;
        return {
          paymentID: item.id,
          paymentStatus: hasMissingDocuments ? (
            <Button style={{ padding: 0 }} onClick={() => setMissingDocsPayment(item)}>
              <Status variant="overdue">
                <>
                  Documents
                  &nbsp;
                  <FontAwesomeIcon icon={faInfoCircle} />
                </>
              </Status>
            </Button>
          ) : (
            <Status variant={getPaymentStatusVariant(item.status)}>
              { item.status }
            </Status>
          ),
          beneficiaryName: (
            <Button
              disableRipple
              className={styles.drawerButton}
              onClick={() => {
                setBeneficiary(item.beneficiary);
              }}
            >
              <span>{item.beneficiary.name}</span>
            </Button>
          ),
          paymentRef: item.reference,
          paymentDate: formatIsoDate(item.paymentDate),
          paymentCurrency: item.currencyCode,
          amount: (
            <Box textAlign="right" fontWeight="bold">
              {formatNumber(item.amount, 2)}
              {' '}
              {item.fixedSide}
            </Box>
          ),
        };
      }
      if (isPaymentDraftDto(item)) {
        return {
          beneficiaryName:
  <Button
    disableRipple
    className={styles.drawerButton}
    onClick={() => {
      setBeneficiary(item.beneficiaryDraft);
    }}
  >
    <span>{item.beneficiaryDraft.name}</span>
  </Button>,
          beneficiaryIban: <span data-dd-privacy="mask">{item.beneficiaryDraft.iban}</span> || '-',
          fundingAccount:
  <Button
    disableRipple
    className={styles.drawerButton}
    onClick={() => {
      setSelectedCurrencyAccount({
        account: item.fundingCurrencyAccount as CurrencyAccountDto,
        tabs: {
          currency: item.fundingCurrencyAccount!.currency,
          currencyCode: item.fundingCurrencyAccount!.currencyCode,
        },
      });
    }}
  >
    <span>{item.fundingAccount}</span>
  </Button>,
          debitAccount:
  <Button
    disableRipple
    className={styles.drawerButton}
    onClick={() => {
      setSelectedCurrencyAccount({
        account: item.debitingCurrencyAccount as CurrencyAccountDto,
        tabs: {
          currency: item.debitingCurrencyAccount!.currency,
          currencyCode: item.debitingCurrencyAccount!.currencyCode,
        },
      });
    }}
  >
    <span>{item.debitingAccount}</span>
  </Button>,
          purposeOfPayment: <span className="dd-privacy allow">{item.purpose}</span>,
          paymentRef: item.reference,
          paymentDate: (
            standingOrder
              ? (
                <>
                  <IconSOStyled icon={faRedoAlt} className="icon" />
                  { formatFrequency(standingOrder.frequency, standingOrder.paymentDate) }
                </>
              )
              : formatIsoDate(item.paymentDate)
          ),
          paymentCurrency: item.debitingCurrency,
          amount: (
            <Box textAlign="right" fontWeight="bold">
              {formatNumber(item.amount, 2)}
              {' '}
              {item.fixedSide}
            </Box>
          ),
          actions: (
            <Box
              textAlign="center"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button color="secondary" onClick={() => setViewPaymentDetails(true)} className={styles.viewButton}>
                View
              </Button>
            </Box>
          ),
        };
      }

      return {
        beneficiaryName:
  <Button
    disableRipple
    className={styles.drawerButton}
    onClick={() => {
      setBeneficiary(item.beneficiary);
    }}
  >
    <span>{item.beneficiary.name}</span>
  </Button>,
        purposeOfPayment: <span className="dd-privacy-allow">{item.purpose}</span>,
        paymentRef: <span className="dd-privacy-allow">{item.reference}</span>,
        paymentDate: (
          standingOrder
            ? (
              <>
                <IconSOStyled icon={faRedoAlt} className="icon" />
                { formatFrequency(standingOrder.frequency, standingOrder.paymentDate) }
              </>
            )
            : formatIsoDate(item.paymentDate)
        ),
        paymentAmount: (
          <Box className="dd-privacy-allow" textAlign="left" fontWeight="bold">
            {formatNumber(item.amount, 2)}
            {' '}
            {item.currencyCode}
          </Box>
        ),
        fixedAmount: (
          <Box className="dd-privacy-allow" textAlign="left" fontWeight="bold">
            {formatNumber(item.instructedAmount, 2)}
            {' '}
            {item.instructedCurrency}
          </Box>
        ),
        paymentStatus: (
          <Box className="dd-privacy-allow" textAlign="left">
            <Status
              variant={getPaymentStatusVariant(item.status)}
            >
              {item.status}
            </Status>
          </Box>
        ),
        downloadButton: (
          <Box className={styles.columnDownloadButton}>
            <Tooltip title={`Download ${!isSameCurrency ? 'FX' : ''} ticket`}>
              <Button
                className={styles.downloadButton}
                size="large"
                color="secondary"
                onClick={() => handleReportGeneration(item.id, item.accountId)}
              >
                <FontAwesomeIcon className={styles.downloadIcon} icon={faDownload} />
              </Button>
            </Tooltip>
          </Box>
        ),
        actions: (
          <Box
            textAlign="center"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button color="secondary" onClick={() => setViewPaymentDetails(true)} className={styles.viewButton}>
              View
            </Button>
          </Box>
        ),
      };
    },
  );

  const columnsType = useMemo(() => {
    if (actionNeeded) return 'actionNeeded';
    if (postApproval) return 'postApproval';
    return 'default';
  }, [actionNeeded, postApproval]);

  const missingDocsModalContent = useMemo(() => {
    const contactText = 'We may have already contacted you; please reply to this email or send the documents to clientservices-abs@alphagroup.com';
    const documentsArr = missingDocsPayment?.documentsRequired?.split(';');

    if (documentsArr) {
      return (
        <>
          To process your payment, please provide the following document(s) to client services:
          <br />
          <br />
          {documentsArr.map((doc: string) => (
            <>
              &bull;&nbsp;
              { doc }
              <br />
            </>
          ))}
          <br />
          { contactText }
        </>
      );
    }

    return contactText;
  }, [missingDocsPayment]);

  return (
    <>
      <div className={styles.genericTableWrapper}>
        <GenericTable
          columns={getColumns(columnsType, !!standingOrder)}
          data={tableData}
        />
      </div>
      <Box className={styles.paginationWrapper}>
        <APPagination
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          handleNext={handleNextPage as any} // need to update props here on sb
          handlePrevious={handlePreviousPage as any} // need to update props here on sb
        />
      </Box>
      <AcknowledgeModal
        open={!!missingDocsPayment}
        title="Documents needed"
        content={missingDocsModalContent}
        actionButtonText="Close"
        handleClose={() => setMissingDocsPayment(null)}
        handleActionButton={() => setMissingDocsPayment(null)}
      />
      {
        !!standingOrder && viewPaymentDetails && (
          <Drawer
            headerTitle={(
              <PaymentsDrawerHead item={standingOrder} />
            )}
            open={!!viewPaymentDetails}
            anchor="right"
            onClose={() => setViewPaymentDetails(false)}
          >
            <PaymentsDrawer
              item={standingOrder}
              formatFrequency={formatFrequency}
              fundingAccount={standingOrder.fundingAccount}
              debitingAccount={
                standingOrder.fixedSide
                  ? standingOrder.debitingAccount
                  : standingOrder.fundingAccount
              }
              status={batchStatus}
            />
          </Drawer>
        )
      }
    </>
  );
};
export default memo(Table);
