import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    batchDetails: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '43px 0',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      '& > div': {
        '& > h4.title': {
          margin: 0,
          fontWeight: typography.fontWeightSemiBold,
          fontSize: '22px',
          lineHeight: '46px',
          letterSpacing: '-1.1px',
        },
        '& > p.subtitle': {
          fontSize: '12px',
          lineHeight: '17px',
          margin: 0,
        },
      },
    },
    semiBold: {
      fontWeight: typography.fontWeightSemiBold,
    },
  }),
  { name: 'BatchDetails' },
);

export default useStyles;
