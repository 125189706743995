import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  hide: {
    display: 'none',
  },
  root: {
    display: 'grid',
    padding: '6px 16px',
    borderRadius: 5,
    marginBottom: 18,
    gridTemplateColumns: 'auto auto 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: '"icon text cancel"',
    '& .cancel': {
      gridArea: 'cancel',
      justifySelf: 'end',
      color: 'rgb(161 160 165)',
      alignSelf: 'end',
      marginRight: 12,
      cursor: 'pointer',
    },
    '& .icon': {
      gridArea: 'icon',
    },
    '& .text': {
      gridArea: 'text',
    },
    '&.error': {
      backgroundColor: 'rgba(253,237,237)',
      justifySelf: 'center',
      width: '100%',
      padding: '20px 16px',
      '& .text': {
        color: 'rgba(95, 33, 32)',
        fontSize: 14,
        alignSelf: 'center',
      },
      '& .icon': {
        color: 'rgba(239, 83, 80)',
        alignSelf: 'center',
        marginRight: 12,
      },
    },
    '&.warning': {
      backgroundColor: 'rgba(255,244,229)',
      justifySelf: 'center',
      width: '100%',
      padding: '20px 16px',
      '& .text': {
        color: 'rgba(102, 60, 0)',
        fontSize: 14,
        alignSelf: 'center',
      },
      '& .icon': {
        color: 'rgba(255, 152, 0)',
        alignSelf: 'center',
        marginRight: 12,
      },
    },
    '&.incomplete': {
      backgroundColor: '#FFF3CD',
      justifySelf: 'center',
      width: '100%',
      padding: '20px 16px',
      '& .text': {
        color: '#FFC107',
        fontSize: '14px',
        alignSelf: 'center',
      },
      '& .icon': {
        color: '#FFC107',
        alignSelf: 'center',
        marginRight: 12,
      },
      '& .cancel': {
        color: 'rgba(255, 152, 0)',
      },
    },
    '&.success': {
      backgroundColor: 'rgba(237, 247, 237)',
      '& .text': {
        color: 'rgba(30, 70, 32)',
        fontSize: 14,
        alignSelf: 'center',
      },
      '& .icon': {
        color: 'rgba(76, 175, 80)',
        alignSelf: 'center',
        marginRight: 12,
      },
    },
    '&.info': {
      backgroundColor: 'rgb(223 236 245)',
      justifySelf: 'center',
      width: '100%',
      padding: '20px 16px',
      '& .text': {
        color: '#2680EB',
        fontSize: 14,
        alignSelf: 'center',
      },
      '& .icon': {
        color: 'rgb(102 181 233)',
        alignSelf: 'start',
        justifySelf: 'start',
        margin: '5px 12px 0 0',
      },
    },
  },
}), { name: 'Alert' });

export default useStyles;
