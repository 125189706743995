import _default, { makeStyles } from 'theme';

const {
  palette, typography,
} = _default;

export const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '36px 0 23px 0',
      '& .creditAmount, .debitAmount': {
        fontSize: '22px',
        fontWeight: '300',
      },
      '& .creditAmount': {
        fontSize: 35,
        color: palette.primary.main,
      },
      '& .debitAmount': {
        fontSize: 35,
        color: '#707070',
      },
      '& .bold': {
        fontWeight: 'bold',
      },
      '& .account': {
        fontSize: '16px',
      },
      '& div[class*="Stat-root"]': {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '58px',
      },
      paddingBottom: 40,
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    bold: {
      fontWeight: typography.fontWeightBold,
    },
    buttonWrapper: {
      width: 110,
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      minWidth: 0,
      width: 50,
      height: 50,
      '&:hover': {
        '& svg': {
          color: '#535353',
        },
        backgroundColor: 'rgba(250, 250, 250, 0.9)',
      },
    },
    icon: {
      fontSize: 14,
      color: '#848484',
    },
    rateIcon: {
      color: '#197FAB',
      marginLeft: 5,
    },
    rateWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    tooltipText: {
      width: 160,
    },
  }),
  { name: 'BatchDetails' },
);

export default useStyles;
