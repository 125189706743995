import React, { useState } from 'react';
import { faFilePdf, faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import Menu from 'components/Menu/Menu';
import useReportsPolling, { FileType, PageType } from '../../../hooks/useReportsPolling';

interface IExportMenuProps {
  startDate: string;
  endDate: string;
  currencyAccountId: string;
  disabled?: boolean;
}
const ExportMenu: React.FC<IExportMenuProps> = (props: IExportMenuProps) => {
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const {
    startDate, endDate, currencyAccountId, disabled,
  } = props;
  const { executeReportGeneration } = useReportsPolling(PageType.Transactions);
  const handleReportGeneration = async (fileType: FileType) => {
    await executeReportGeneration(
      fileType,
      currencyAccountId,
      startDate,
      endDate,
    );
  };
  const menuItems = [
    {
      id: 'action-transactions-export-pdf',
      content: 'Export PDF',
      icon: faFilePdf,
      onClick: () => handleReportGeneration(FileType.PDF),
      underline: true,
    },
    {
      id: 'action-transactions-export-excel',
      content: 'Export Excel',
      icon: faFileExcel,
      onClick: () => handleReportGeneration(FileType.EXCEL),
    },
  ];
  return (
    <Menu
      open={Boolean(open)}
      setOpen={setOpen}
      anchorEl={open}
      buttonProps={{
        disabled,
        style: {
          height: '100%',
        },
      }}
      menuIconProps={{ icon: faAngleDown, style: { fontSize: 16, height: '100%', marginLeft: 18 } }}
      buttonTitle="Export"
    >
      {menuItems.map((item) => (
        <MenuDropdownItem key={item.id} setOpen={setOpen}>{item}</MenuDropdownItem>
      ))}
    </Menu>
  );
};

export default ExportMenu;
