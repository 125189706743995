import {
  MenuItem, Select, Typography,
} from '@mui/material';
import React from 'react';
import { TPayment } from 'models/payments';

interface IProps {
  currentPayment: TPayment;
  handlePaymentRailChange: (event: any) => void;
}

const PaymentRailDropdown: React.FC<IProps> = ({
  currentPayment,
  handlePaymentRailChange,
}) => {
  const paymentRailOptions = ['sepa'];
  return (
    <div data-testid="paymentRail">
      <Typography variant="subtitle1" className="label" fontSize={14}>
        Payment Rail
      </Typography>
      <Select
        onChange={handlePaymentRailChange}
        value={currentPayment?.paymentRail || ''}
        fullWidth
        displayEmpty
      >
        <MenuItem key="default" value="">
          <Typography variant="subtitle1" fontSize={14}>
            Default
          </Typography>
        </MenuItem>
        {paymentRailOptions.map((paymentRail: string) => (
          <MenuItem key={paymentRail} value={paymentRail}>
            <Typography variant="subtitle1" fontSize={14}>
              {paymentRail.toUpperCase()}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default PaymentRailDropdown;
