import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { InvoiceDto } from '@alpha/invoice-dtos';
import { useSelector } from 'react-redux';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import InvoiceService from '../../../services/Invoices/invoices.service';
import { TStore } from '../../../store';

const useBody = (multiEntity?: boolean) => {
  const [initialPageLoad, setInitialPageLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<InvoiceDto []>([]);
  const location = useLocation();
  const sb = useAlphaSnackbar();

  const allowedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.allowedAccounts,
  );

  const selectedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.selectedAccounts,
  );

  const getInvoices = async (accountIds?:string[]) => {
    try {
      setLoading(true);
      const response = accountIds
        ? await InvoiceService.getInvoicesAsync(accountIds.join(','))
        : await InvoiceService.getInvoicesAsync();
      setInvoiceData(response);
    } catch {
      sb.trigger('We could not retrieve any invoices');
    } finally {
      setLoading(false);
    }
  };

  const getUserInvoiceEntities = async () => {
    const response = await InvoiceService.getInvoiceManagerEntities();
    return response;
  };

  const getAllowedInvoiceAccountsFromSelected = (
    _invoiceEntities: string[],
    selectedEntities: string[],
  ): string[] => selectedEntities.filter((accountId) => _invoiceEntities.includes(accountId));

  const getUserAuthorizedInvoicesAsync = async (multiEntityAccounts: Record<string, string>[]) => {
    const accounts = await getUserInvoiceEntities();
    const authorizedAccounts = accounts.map((account) => account.id);
    const accountIds = getAllowedInvoiceAccountsFromSelected(
      authorizedAccounts,
      multiEntityAccounts.map((account) => account.id),
    );
    if (accountIds.length === 0) {
      setInvoiceData([]);
      setLoading(false);
      setInitialPageLoad(false);
    } else {
      getInvoices(accountIds);
      setInitialPageLoad(false);
    }
  };

  useEffect(() => {
    if (multiEntity && (allowedAccounts || selectedAccounts)) {
      const multiEntityAccounts = selectedAccounts.length > 0
        ? selectedAccounts
        : allowedAccounts;
      if (multiEntityAccounts) {
        getUserAuthorizedInvoicesAsync(multiEntityAccounts);
      }
    }
  }, [allowedAccounts, selectedAccounts]);

  useEffect(() => {
    if (!multiEntity) {
      getInvoices();
      setInitialPageLoad(false);
    }
  }, []);

  return {
    loading,
    location,
    invoiceData,
    getInvoices,
    initialPageLoad,
  };
};

export default useBody;
