import { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import SearchService from '../services/Search/SearchService';
import SearchServiceMultiEntity from '../services/Search/SearchServiceMultiEntity';
import useAlphaSnackbar from './useAlphaSnackbar';

export type TSearchParams = {
  baseUrl: string,
  queryParams: {
    searchtext?: string,
    take: number,
    skip: number,
  } & Record<string, string | number>,
  body?: Record<string, unknown>,
  method?: 'GET' | 'POST',
}

export type TSearchBaseResponse = {
  total: number;
  records: unknown[];
}

export type TSearchItems<T> = {
  items: T,
  hasPrevious: boolean,
  hasNext: boolean,
}
export type TErrorResponseWrapper = {
  response: {
    data: {error :string,
    errors : errorType[] | string[]}
  }
}

export type errorType = {
  key: string,
  errors :string[]
}

function createErrorMessage(e : TErrorResponseWrapper) : string {
  try {
    const errorData = e.response.data;
    const errorArray = errorData.errors as errorType[];
    let errorMessage = '';
    if (errorArray[0].errors) {
      errorArray.forEach((element) => {
        element.errors.forEach((error) => {
          errorMessage = errorMessage.concat(`${errorData?.error} : ${error}\n`);
        });
      });
    } else {
      errorArray.forEach((error) => {
        errorMessage = errorMessage.concat(`${errorData?.error} : ${error}\n`);
      });
    }
    return errorMessage;
  } catch {
    return 'Something went wrong when retrieving your data';
  }
}

export type TUseSearch = ReturnType<typeof useSearch>;

const useSearch = <T extends TSearchBaseResponse>(accountId?: string) => {
  const [searchText, setSearchText] = useState<string>('');
  const [items, setItems] = useState<TSearchItems<T>>();
  const [skip, setSkip] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();

  async function fetchItemsFromUrl(searchParams: TSearchParams):
    Promise<T | null> {
    try {
      setLoading(true);
      return await SearchService.GetTableData(searchParams);
    } catch (e) {
      sb.trigger(createErrorMessage(e));
    } finally {
      setLoading(false);
    }
    return null;
  }

  async function fetchItemsFromUrlMultiEntity(searchParams: TSearchParams):
  Promise<T | null> {
    try {
      setLoading(true);
      return await SearchServiceMultiEntity.GetTableData(searchParams, accountId);
    } catch (e) {
      sb.trigger(createErrorMessage(e));
    } finally {
      setLoading(false);
    }
    return null;
  }

  const createSearchItemsObj = (responseItems: T, searchParams: TSearchParams): TSearchItems<T> => {
    const hasPrevious = Boolean(searchParams.queryParams.skip > 0);
    const hasNext = Boolean(
      responseItems.total - (searchParams.queryParams.skip + searchParams.queryParams.take) > 0,
    );
    return {
      items: responseItems,
      hasPrevious,
      hasNext,
    };
  };

  const handleUpdateTableItems = async (searchParams: TSearchParams,
    multiEntity? : boolean): Promise<void> => {
    const responseItems: T | null = multiEntity ? await fetchItemsFromUrlMultiEntity(searchParams)
      : await fetchItemsFromUrl(searchParams);
    if (responseItems) {
      const searchItems = createSearchItemsObj(responseItems, searchParams);
      setItems(searchItems);
    }
  };

  const handleNewSearch = useCallback(debounce(
    async (searchParams: TSearchParams, multiEntity?: boolean): Promise<void> => {
      const newSearchParams = { ...searchParams };
      newSearchParams.queryParams.skip = 0;
      setSkip(0);
      await handleUpdateTableItems(newSearchParams, multiEntity);
    }, 300,
  ), []);

  const handleInitialSearch = async (searchParams: TSearchParams,
    multiEntity?: boolean): Promise<void> => {
    const newSearchParams = { ...searchParams };
    newSearchParams.queryParams.skip = 0;
    newSearchParams.queryParams.searchtext = undefined;

    setSearchText('');
    setSkip(0);

    await handleUpdateTableItems(newSearchParams, multiEntity);
  };

  const handlePaginationRequest = async (searchParams: TSearchParams, newSkip: number,
    multiEntity?: boolean) => {
    setSkip(newSkip < 0 ? 0 : newSkip);

    const newSearchParams = { ...searchParams };
    newSearchParams.queryParams.skip = newSkip;

    if (searchText) newSearchParams.queryParams.searchtext = searchText;

    await handleUpdateTableItems(newSearchParams, multiEntity);
  };

  const handleNextPage = async (searchParams: TSearchParams,
    skipAmount: number = 10, multiEntity?: boolean): Promise<void> => {
    const newSkip = skip + skipAmount;
    await handlePaginationRequest(searchParams, newSkip, multiEntity);
  };

  const handlePreviousPage = async (searchParams: TSearchParams,
    skipAmount: number = 10, multiEntity?: boolean): Promise<void> => {
    const newSkip = skip - skipAmount;

    await handlePaginationRequest(searchParams, newSkip, multiEntity);
  };

  return {
    items,
    skip,
    loading,
    searchText,
    handleNewSearch,
    setItems,
    setSearchText,
    handleInitialSearch,
    handlePreviousPage,
    handleNextPage,
  };
};

export default useSearch;
