import { User } from '@alpha/profile-dtos';
import { useEffect, useState } from 'react';
import { UserRole, UserRoles } from '../models/user';
import ProfileService from '../services/Profile/profile.service';
import useAlphaSnackbar from './useAlphaSnackbar';

export const useAuthorizationMultiEntity = (
  accountId?: string,
  requiredRoles?: UserRoles[],
  resourceCreatorUserId?: string,
) => {
  const [userProfile, setUserProfile] = useState<User>();
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const updateUserProfile = async () => {
    try {
      setLoading(true);
      const response = await ProfileService.getUserProfile(accountId);
      setUserProfile(response);
    } catch (e) {
      sb.trigger(e.response?.data?.error || e.message || 'Error getting user profile');
    } finally {
      setLoading(false);
    }
  };

  const isAuthorized = () => {
    // loop through requiredRoles
    requiredRoles![0].forEach((requiredRole) => {
      // if authorised === false, check to see if
      // loggedIn userRoles contains the requiredRole & set authorization to true if true
      if (!authorized) {
        if (userProfile?.roles.includes(requiredRole)) {
          switch (requiredRole) {
            case UserRole.BENEFICIARY_APPROVER:
              if (userProfile?.id !== resourceCreatorUserId) {
                // only allow authorization if loggedin user =/= resource creator
                setAuthorized(true);
              }
              break;
            default:
              // case of BENEFICAIRY_APPROVER_OWN fits here, regardless of what the userId
              // or resourceCreatorUserId if user has that role they are able to approve all.
              setAuthorized(true);
              break;
          }
        }
      }
    });
  };

  const getRolesForAccount = async (_accountId: string):Promise<string[]> => {
    const response = await ProfileService.getUserProfile(_accountId);
    return response.roles;
  };

  const hasRole = (role:UserRole): boolean => {
    if (userProfile) {
      return userProfile.roles.includes(role);
    }
    return false;
  };

  useEffect(() => {
    if (accountId) {
      updateUserProfile();
    }
  }, [accountId]);

  useEffect(() => {
    if (requiredRoles && accountId) {
      isAuthorized();
    }
  }, [userProfile?.roles, accountId, loading]);

  return {
    loading,
    hasRole,
    authorized,
    roles: userProfile?.roles || [],
    getRolesForAccount,
  };
};

export default useAuthorizationMultiEntity;
