import { DateTime } from 'luxon';

const formatIsoDate = (date: string | undefined, format: string = 'dd/MM/yyyy', zone: string = 'Europe/London') => {
  if (!date) return '-';

  const dateTime = DateTime.fromISO(date, { zone });
  if (dateTime.isValid) {
    return dateTime.toFormat(format);
  }

  return '-';
};

export default formatIsoDate;
