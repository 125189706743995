import styled from '@mui/styled-engine';
import { Box } from '@mui/material';
import theme from 'theme';

type TStyleProps = {
    alignRight?: boolean;
    secondary?: boolean;
};

const { typography, palette } = theme;

const StyledStat = styled(Box)({
  display: 'inline-block',
});

const StyledSecondaryTitle = styled(Box)({
  color: '#343434',
  textTransform: 'capitalize',
  marginBottom: 10,
  lineHeight: '15px',
});

const StyledValue = styled(Box, {
  shouldForwardProp: (props) => props !== 'alignRight' && props !== 'secondary',
})(({ alignRight, secondary }: TStyleProps) => ({
  margin: 0,
  textAlign: alignRight ? 'right' : 'left',
  fontSize: secondary ? 16 : 22,
  fontWeight: secondary ? typography.fontWeightBold : typography.fontWeightLight,
}));

const StyledStatHeader = styled('p')((props: TStyleProps) => ({
  color: palette.primary.main,
  fontWeight: typography.fontWeightSemiBold,
  textTransform: 'uppercase',
  letterSpacing: 1.65,
  lineHeight: '15px',
  margin: '0 0 10px 0',
  fontSize: 11,
  textAlign: props.alignRight ? 'right' : undefined,
}));

export {
  StyledStat, StyledSecondaryTitle, StyledValue, StyledStatHeader,
};
