import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import {
  StyledLink, StyledSubmittedPage,
} from '../index.styles';

interface ISubmittedPage {
    setShowSubmittedPage: React.Dispatch<React.SetStateAction<boolean>>,
}

const SubmittedPage = ({
  setShowSubmittedPage,
}: ISubmittedPage) => {
  const clientServicesEmail = 'clientservices-abs@alphagroup.com';

  return (
    <StyledSubmittedPage>
      <FontAwesomeIcon size="7x" color="#098375" icon={faCheckCircle} />
      <h1>Thank You</h1>
      <h4 style={{ textAlign: 'center' }}>
        The form was submitted successfully.
        A member from our client services team will review
        your submission and get back to you shortly.
      </h4>
      <h5 style={{ textAlign: 'center' }}>
        In the meantime, you can
        {' '}
        <StyledLink
          type="button"
          onClick={() => setShowSubmittedPage(false)}
        >
          view your completed submission here.
        </StyledLink>
      </h5>
      <h6 style={{ textAlign: 'center' }}>
        If you have any other questions, do not hesitate to
        get in touch on
        <Link
          style={{ color: '#098375' }}
          to={clientServicesEmail}
          onClick={(e) => {
            window.location.href = `mailto:${clientServicesEmail}`;
            e.preventDefault();
          }}
        >
          {' '}
          {clientServicesEmail}
        </Link>
      </h6>
    </StyledSubmittedPage>
  );
};

export default SubmittedPage;
