import React from 'react';
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Box } from '@mui/material';
import { faEdit, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import Menu from 'components/Menu/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useActionDropdown from 'hooks/useActionDropDown';

interface IProps {
  onEditPayment: () => void;
  onDeletePayment: () => void;
}

const ActionDropDown: React.FC<IProps> = ({ onEditPayment, onDeletePayment }) => {
  const {
    open,
    setOpen,
  } = useActionDropdown();

  const editButton = {
    id: 'edit',
    content: (
      <Box>
        <FontAwesomeIcon icon={faEdit} />
        &nbsp;
        Edit payment
      </Box>
    ),
    onClick: onEditPayment,
  };

  const deleteButton = {
    id: 'edit',
    content: (
      <Box>
        <FontAwesomeIcon icon={faTrash} />
        &nbsp;
        Delete payment
      </Box>
    ),
    onClick: onDeletePayment,
  };

  return (
    <Box>
      <Menu
        open={Boolean(open)}
        setOpen={setOpen}
        anchorEl={open}
        disableBackdropClick={false}
        disableHoverEffect={false}
        buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
        buttonTitle=""
        icon={faEllipsisH}
      >
        <MenuDropdownItem setOpen={setOpen}>
          { editButton }
        </MenuDropdownItem>
        <MenuDropdownItem setOpen={setOpen}>
          { deleteButton }
        </MenuDropdownItem>
      </Menu>
    </Box>
  );
};

export default ActionDropDown;
