import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    tabContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      minHeight: 'calc(100vh - 310px)',
      '& > div': {
        width: '100%',
      },
    },
    searchBar: {
      display: 'flex',
      width: '400px',
    },
  }),
  { name: 'Body' },
);

export default useStyles;
