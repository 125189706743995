import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { ActionButton, GhostButton } from 'components/Buttons';
import { PaymentRouteEnum } from '../../../../models/payments';
import routes from '../../../../routes.path';
import history from '../../../../services/history/browserHistory';
import { testIds } from '../Funding';
import { useStyles } from './RequestLiveRateFooter.styles';

interface IProps {
  disableLiveRateBtn: boolean;
  handleCheckSameTrade: () => void;
}

const RequestLiveRateFooter: React.FC<IProps> = (props: IProps) => {
  const { disableLiveRateBtn, handleCheckSameTrade } = props;
  const classes = useStyles();
  const { batchId } = useParams<{
    batchId: string;
  }>();
  return (
    <div className={classes.requestFooter}>
      <GhostButton
        colorVariant="default"
        testId={testIds.backToSummaryBtn}
        size="large"
        startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={() => {
          history.push(routes.payments.batchSummaryRouteFunc(
            batchId,
            PaymentRouteEnum.PENDING,
          ));
        }}
      >
        Back to summary
      </GhostButton>
      <p>
        <span className="bold">Disclaimer</span>
        {' '}
        Once you request a rate, if you click &apos;Book&apos; on the next screen,
        you will enter into a legal contract with Alpha to buy or sell
        the currencies you have selected.
      </p>
      <ActionButton
        testId={testIds.requestLiveRateBtn}
        size="large"
        onClick={handleCheckSameTrade}
        disabled={disableLiveRateBtn}
      >
        Request live rates
      </ActionButton>
    </div>
  );
};

export default memo(RequestLiveRateFooter);
