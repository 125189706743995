import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '32px 0',
  },
}), { name: 'CreateBeneficiaryDrawer' });

export default useStyles;
