import { PaymentBatchSummaryDto } from '@alpha/payments-dtos';
import { Box } from '@mui/material';
import Status from 'components/Status/Status';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import useDynamoTable from '../../../../hooks/useDynamoTable';
import { PaymentRouteEnum } from '../../../../models/payments';
import routes from '../../../../routes.path';
import { TDynamoTableQueryParams } from '../../../../services/DatabaseServices/DynamoService';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../utils/formatIsoDate';
import { getApprovedDate, getCompletePaymentType, getUploadedBy } from '../DashboardDataHelper/DashboardDataHelper';
import NoOfApprovers from '../PendingTable/DisplayTable/NoOfApprovers/NoOfApprovers';
import CompleteTable from './CompleteTable/CompleteTable';

const initialTableQuery: TDynamoTableQueryParams = {
  url: '/payments/batches/completed',
};

const CompleteTableContainer: React.FC = () => {
  const columns: ITableColumn[] = [
    {
      header: <Box paddingRight="80px">ID</Box>,
      accessor: 'id',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box textAlign="left">Type</Box>,
      accessor: 'type',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box textAlign="left">Uploaded by</Box>,
      accessor: 'uploadedBy',
    },
    {
      header: <Box textAlign="left">uploaded date</Box>,
      accessor: 'uploadedDate',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box textAlign="left">Approved Date</Box>,
      accessor: 'approvedDate',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box textAlign="right">Payment Status</Box>,
      accessor: 'paymentStatus',
      className: 'dd-privacy-allow',

    },
    {
      header: <Box textAlign="right">Batch Status</Box>,
      accessor: 'batchStatus',
      className: 'dd-privacy-allow',

    },
    {
      header: <Box textAlign="center">Action</Box>,
      accessor: 'action',
      className: 'dd-privacy-allow',

    },
  ];

  const table = useDynamoTable(initialTableQuery);

  const createTableData = (datas: PaymentBatchSummaryDto[] | undefined) => datas?.map(
    (data: PaymentBatchSummaryDto) => ({
      id: <b>{data.friendlyId}</b>,
      uploadedBy: getUploadedBy(data),
      uploadedDate: formatIsoDate(data.uploadedDate),
      approvedDate: (
        <Box margin="auto" textAlign="left">
          {
            getApprovedDate(data)
          }
        </Box>
      ),
      noOfApprovers: (
        <NoOfApprovers
          uploadedBy={data.uploadedBy}
          uploadedDate={data.uploadedDate}
          noOfApprovals={data.approvals?.length}
          approvedBy={data.approvals}
          approvalsRequired={data.approvals?.length}
        />
      ),
      paymentStatus: (
        <Box textAlign="right" margin="auto">
          <Status variant="success">
            <>
              {data.noOfProcessedPayments}
              /
              {data.noOfPayments}
            </>
          </Status>
        </Box>
      ),
      batchStatus: (
        <Box textAlign="right" margin="auto">
          <Status variant="success">{data.status}</Status>
        </Box>
      ),
      type: (
        <Box className="type">
          <FontAwesomeIcon icon={faLayerGroup} />
          {getCompletePaymentType(data)}
        </Box>
      ),
      createdDate: formatIsoDate(data.uploadedDate),
      action: (
        <Box data-testid={`action-${data.id}`} textAlign="center" margin="auto">
          <Link
            to={routes.payments.batchSummaryRouteFunc(data.id, PaymentRouteEnum.COMPLETE)}
            className="actionLink"
          >
            View
          </Link>
        </Box>
      ),
    }),
  );

  return (
    <CompleteTable
      columns={columns}
      tableData={createTableData(table.data || [])}
      table={table}
    />
  );
};

export default memo(CompleteTableContainer);
