import { ActionButton } from 'components/Buttons';
import { APMainLayout } from 'components/Layout/MainLayout';
import { StyledTabsWrapper } from 'components/Tabs';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import AuthorizationService from 'services/Authorization/authorization.service';
import { Role } from '@alpha/auth-types';
import { iAllowedAccounts, iSelectedAccounts } from 'store/accounts/accounts.reducer';
import Dropdown from '../../../components/Dashboard/Dropdown';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import { TSearchBaseResponse } from '../../../hooks/useSearch';
import { TUserAccount } from '../../../models/user';
import routes from '../../../routes.path';
import history from '../../../services/history/browserHistory';
import SearchService from '../../../services/Search/SearchServiceMultiEntity';
import { TStore } from '../../../store';
import CreatePayment from '../CreatePayment/CreatePayment';
import { PaymentContext, TPaymentContextType } from '../PaymentContext';
import Classes from './Dashboard.module.scss';
import useStyles from './Dashboard.styles';
import SearchPaymentsTable from './SearchPaymentsTable/SearchPaymentsTable';
import { PaymentFeatureFlags } from '../ManualPayments/constants';

const MultiEntityPaymentsDashboard: React.FC = () => {
  const [createPaymentsDrawerOpen, setCreatePaymentsDrawerOpen] = useState(false);
  const [fundedPaymentsExist, setFundedPaymentsExist] = useState<boolean>();
  const [isPaymentsInputter, setIsPaymentsInputter] = useState<boolean>();
  const [selectedEntity, setSelectedEntity] = useState<TUserAccount>();
  const [actionNeededQty, setActionNeededQty] = useState<number | null>(null);
  const { flags } = useGetFeatureFlags();
  const standingOrderFFEnabled = flags?.find((flag) => (
    flag.name === PaymentFeatureFlags.STANDING_ORDER && flag.isActive
  ))?.isActive;

  const paymentContext: TPaymentContextType = {
    selectedEntity,
    setSelectedEntity,
  };

  const [tabIndex, setTabIndex] = useState<0|1|2|3|4|5|6>(0);
  const location = useLocation();
  const classes = useStyles();

  const selectedAccounts = useSelector<TStore, iSelectedAccounts[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );

  const userAccountsLoading = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.userAccountsLoading,
  );

  const actionNeededTitle = `ACTION REQUIRED (${actionNeededQty || 0})`;

  type TTabTitles = 'PENDING' | 'IN PROGRESS' | 'COMPLETE' | 'SCHEDULED' | 'DRAFT' | typeof actionNeededTitle | 'FUNDED';
  const tabTitles: TTabTitles[] = ['PENDING', 'IN PROGRESS', 'COMPLETE', 'DRAFT', actionNeededTitle];

  const tabTitlesFunded : TTabTitles[] = ['PENDING', 'IN PROGRESS', 'COMPLETE', 'DRAFT', actionNeededTitle, 'FUNDED'];

  if (standingOrderFFEnabled) {
    tabTitles.splice(3, 0, 'SCHEDULED');
    tabTitlesFunded.splice(3, 0, 'SCHEDULED');
  }

  const sb = useAlphaSnackbar();

  const getActionNeededQty = useCallback(async () => {
    try {
      const data: TSearchBaseResponse = await SearchService.GetTableData({
        baseUrl: '/payments/batches/released/search',
        queryParams: {
          skip: 0,
          take: 1,
          batchStatus: 'INPROGRESS',
          sortby: 'uploadedDate',
          sortorder: 'desc',
          awaitingClientInput: 'true',
        },
      });
      setActionNeededQty(data.total);
    } catch (e) {
      sb.trigger('Could not check for payments that require further action');
    }
  }, [sb]);

  useEffect(() => {
    if (!userAccountsLoading && allowedAccounts.length > 0) {
      const checkIfPaymentsInputter = async () => {
        const userAccounts = allowedAccounts.map(
          ({ id }) => id,
        );
        const paymentInputterAccounts = await AuthorizationService.getEntitiesForRole(
          Role.PAYMENTS_INPUTTER,
        );
        const paymentInputterAccountIds = new Set(paymentInputterAccounts.map(({ id }) => id));
        setIsPaymentsInputter(userAccounts.some(
          (element) => paymentInputterAccountIds.has(element),
        ));
      };
      const checkIfFundedPaymentsExist = async () => {
        try {
          const data: TSearchBaseResponse = await SearchService.GetTableData({
            baseUrl: '/payments/batches/payments-batches/FUNDED/search',
            queryParams: {
              skip: 0,
              take: 10,
            },
          });
          if (data.total > 0) {
            setFundedPaymentsExist(true);
          } else {
            setFundedPaymentsExist(false);
          }
        } catch (e) {
          sb.trigger('Could not check for payments that require further action');
          setFundedPaymentsExist(false);
        }
      };

      checkIfPaymentsInputter();
      checkIfFundedPaymentsExist();
    }
  }, [userAccountsLoading, allowedAccounts, selectedAccounts, sb]);

  useEffect(() => {
    if (!userAccountsLoading && allowedAccounts.length > 0 && actionNeededQty === null) {
      getActionNeededQty();
    }
  }, [getActionNeededQty, userAccountsLoading, allowedAccounts, selectedAccounts, actionNeededQty]);

  useEffect(() => {
    if (fundedPaymentsExist) {
      history.push(routes.payments.multientity.funded);
    }
  }, [fundedPaymentsExist]);

  useEffect(() => {
    if (location.pathname.includes(routes.payments.multientity.pending)) {
      setTabIndex(0);
    } else if (location.pathname.includes(routes.payments.multientity.inProgress)) {
      setTabIndex(1);
    } else if (location.pathname.includes(routes.payments.multientity.complete)) {
      setTabIndex(2);
    } else if (location.pathname.includes(routes.payments.multientity.scheduled)) {
      setTabIndex(3);
    } else if (location.pathname.includes(routes.payments.multientity.draft)) {
      setTabIndex(standingOrderFFEnabled ? 4 : 3);
    } else if (location.pathname.includes(routes.payments.multientity.actionNeeded)) {
      setTabIndex(standingOrderFFEnabled ? 5 : 4);
    } else if (location.pathname.includes(routes.payments.multientity.funded)) {
      setTabIndex(standingOrderFFEnabled ? 6 : 5);
    }
  }, [location.pathname, standingOrderFFEnabled]);

  const handleSetSelectedTabIndex = (index: number): void => {
    const condIndex = index > 2 && !standingOrderFFEnabled ? index + 1 : index;

    switch (condIndex) {
      case 0:
        return history.push(routes.payments.multientity.pending);
      case 1:
        return history.push(routes.payments.multientity.inProgress);
      case 2:
        return history.push(routes.payments.multientity.complete);
      case 3:
        return history.push(routes.payments.multientity.scheduled);
      case 4:
        return history.push(routes.payments.multientity.draft);
      case 5:
        return history.push(routes.payments.multientity.actionNeeded);
      case 6:
        return history.push(routes.payments.multientity.funded);
      default:
        return history.push(routes.payments.multientity.pending);
    }
  };

  if (fundedPaymentsExist !== undefined) {
    return (
      <APMainLayout
        title={(
          <div className={classes.header}>
            <span className="dd-privacy-allow">Payments |</span>
            {' '}
            <Dropdown />
          </div>
        )}
        breadCrumbs={[{ text: 'Home', url: routes.home }]}
        pageTabs={(
          <StyledTabsWrapper
            testId="payments-tabs"
            tabTitles={fundedPaymentsExist ? tabTitlesFunded : tabTitles}
            tabIndex={tabIndex}
            className="dd-privacy-allow"
            handleChange={handleSetSelectedTabIndex}
          />
        )}
        rightAlignedCta={(
          isPaymentsInputter ? (
            <ActionButton
              size="large"
              data-dd-privacy="allow"
              testId="create-payment"
              disabled={false}
              onClick={() => setCreatePaymentsDrawerOpen(true)}
            >
              <span className="dd-privacy-allow">Create payments</span>
            </ActionButton>
          ) : undefined
        )}
      >
        <PaymentContext.Provider value={paymentContext}>
          <div className={clsx(Classes.container, classes.container)}>
            <SearchPaymentsTable tabIndex={tabIndex} fundedPaymentsExist={fundedPaymentsExist} />
            <CreatePayment
              open={createPaymentsDrawerOpen}
              onClose={setCreatePaymentsDrawerOpen}
              account={selectedEntity}
            />
          </div>
        </PaymentContext.Provider>
      </APMainLayout>
    );
  }
  return <FullPageLoader testId="payments-dashboard-loader" />;
};

export default MultiEntityPaymentsDashboard;
