import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  root: (props?: any) => ({
    boxShadow: '0px 3px 6px #00000005',
    borderRadius: 10,
    height: '100%',
    padding: props.padding,
    background: 'white',
  }),
}),
{ name: 'Card' });

export default useStyles;
