import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import environmentVars from '../../env.variables';
import AuthService from '../Auth/auth.service';

export const instance = axios.create({
  baseURL: environmentVars.REACT_APP_API_URL,
});

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  newConfig.headers.Authorization = await AuthService.getAccessToken();
  newConfig.headers['Session-Id'] = AuthService.getSessionUUIDFromLocalStorage();
  newConfig.headers['account-id'] = config.headers['account-id'] ? config.headers['account-id']
    : localStorage.getItem('account-id');
  return newConfig;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const isInvalidSession = error?.response?.data?.invalidSession || false;
    if (isInvalidSession) {
      await AuthService.handleInvalidSession();
    }
    return Promise.reject(error);
  },
);

export const instanceNoAccountId = axios.create({
  baseURL: environmentVars.REACT_APP_API_URL,
});

instanceNoAccountId.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  newConfig.headers.Authorization = await AuthService.getAccessToken();
  newConfig.headers['Session-Id'] = AuthService.getSessionUUIDFromLocalStorage();

  return newConfig;
});

instanceNoAccountId.interceptors.response.use(
  (response) => response,
  async (error) => {
    const isInvalidSession = error?.response?.data?.invalidSession || false;
    if (isInvalidSession) {
      await AuthService.handleInvalidSession();
    }
    return Promise.reject(error);
  },
);

export const instanceNoInterceptors = axios.create({
  baseURL: environmentVars.REACT_APP_API_URL,
});

export const instanceAccountId = (accountId: string): AxiosInstance => {
  const newInstance = axios.create({
    baseURL: environmentVars.REACT_APP_API_URL,
    headers: {
      'account-id': accountId,
    },
  });

  newInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const newConfig = { ...config };
    newConfig.headers.Authorization = await AuthService.getAccessToken();
    newConfig.headers['Session-Id'] = AuthService.getSessionUUIDFromLocalStorage();
    return newConfig;
  });

  newInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const isInvalidSession = error?.response?.data?.invalidSession || false;
      if (isInvalidSession) {
        await AuthService.handleInvalidSession();
      }
      return Promise.reject(error);
    },
  );

  return newInstance;
};
export default instance;
