import React, { ChangeEvent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../../routes.path';
import { BatchSummary } from './BatchSummary';
import MultiEntityPaymentsDashboard from './Dashboard/Dashboard';
import Funding from './Funding/Funding';
import ManualPayments from './ManualPayments/ManualPayments';

// Autocomplete doesn't seem to expose the same type for inputProps.onChange as InputBase
// https://github.com/mui/material-ui/issues/32645
export type AutocompleteEvent = ChangeEvent<{}>;

const MultiEntityPayments: React.FC = () => (
  <Switch>
    <Route
      exact
      path={
          [
            routes.payments.multientity.base,
            routes.payments.multientity.pending,
            routes.payments.multientity.inProgress,
            routes.payments.multientity.complete,
            routes.payments.multientity.scheduled,
            routes.payments.multientity.draft,
            routes.payments.multientity.funded,
            routes.payments.multientity.actionNeeded,
          ]
        }
    >
      <MultiEntityPaymentsDashboard />
    </Route>
    <Route
      exact
      path={routes.payments.multientity.batchSummary}
      component={BatchSummary}
    />
    <Route
      exact
      path={routes.payments.multientity.batchSummaryActionNeeded}
      component={BatchSummary}
    />
    <Route path={[routes.payments.multientity.manual.base]}>
      <ManualPayments />
    </Route>
    <Route exact path={routes.payments.multientity.funding} component={Funding} />
    <Redirect to={routes.payments.multientity.base} />
  </Switch>
);

export default MultiEntityPayments;
