import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import UserManagementProvider from './context';
import EntityGroupDrawer from '../EntityGroups/EntityGroupManagerDrawer';
import StyledUserManagementContainer from './index.styles';
import userManagementConfig from './configs/userManagement.config';
import UserViewingAcknowledgeModal from './components/UserViewingAcknowledgeModal';

const UserManagement = () => {
  const { path } = useRouteMatch();
  const { component } = userManagementConfig[path];
  return (
    <UserManagementProvider>
      <StyledUserManagementContainer>
        {component}
        <EntityGroupDrawer />
        <UserViewingAcknowledgeModal />
      </StyledUserManagementContainer>
    </UserManagementProvider>
  );
};

export default UserManagement;
