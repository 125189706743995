import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { IDrawerProps } from 'components/Drawer/BaseDrawer/BaseDrawer.interfaces';
import { useStyles } from './Drawer.styles';

interface IIATDrawerProps extends IDrawerProps {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>
}

const Drawer: React.FC<IIATDrawerProps> = ({ open, onClose, children }: IIATDrawerProps) => {
  const styles = useStyles();

  return (
    <BaseDrawer.Drawer open={open} onClose={() => onClose(false)} anchor="right" className={styles.drawer}>
      { children }
    </BaseDrawer.Drawer>
  );
};

interface IChildrenProps {
  children: ReactNode | ReactNode[],
  section: 'top' | 'bottom',
}
const Child: React.FC<IChildrenProps> = ({ children, section }: IChildrenProps) => {
  const styles = useStyles();

  return (
    <Box className={clsx(styles.child, section === 'top' ? styles.topSection : styles.bottomSection)}>
      {children }
    </Box>
  );
};

interface IFooterProps {
  children: ReactNode | ReactNode[]
}
const Footer: React.FC<IFooterProps> = ({ children }: IFooterProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.footer}>
      { children }
    </Box>
  );
};

export const InterAccountTransferDrawer = {
  Drawer,
  Child,
  Footer,
};

export default Drawer;
