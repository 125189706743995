import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';
import useStyles from './Tooltip.styles';

const TableTooltip = (props: TooltipProps) => {
  const classes = useStyles();

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Tooltip arrow classes={classes} {...props} />;
};

export default TableTooltip;
