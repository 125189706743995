import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    headerContent: {
      display: 'flex',
      margin: '0px 50px',
      padding: '30px 0px',
      zIndex: 1,
      height: '150px',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    headerTitle: {
      fontSize: 22,
      color: 'rgb(9, 131, 117)',
      fontFamily: typography.fontFamilySecondary,
      fontWeight: 200,
    },
    headerSubtitle: {
      fontSize: 12,
      fontFamily: typography.fontFamilySecondary,
      fontWeight: 500,
    },
    button: {
      marginLeft: 'auto',
      alignSelf: 'flex-end',
      backgroundColor: 'rgba(240, 240, 240, 1)',
      minWidth: 0,
      width: 50,
      height: 50,
      '&:hover': {
        backgroundColor: 'rgba(250, 250, 250, 0.5)',
      },
    },
    icon: {
      color: '#848484',
    },
  }),
  { name: 'Header' },
);

export default useStyles;
