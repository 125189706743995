import _default, { makeStyles } from 'theme';
import shade from 'theme/utils';

const {
  palette, typography,
} = _default;

const useStyles = makeStyles(
  () => ({
    widgetContainer: {
      height: '6vw',
      marginLeft: '8px',
    },
    boxShadow: {
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.03)',
    },
    cardRoot: {
      height: '100%',
    },
    card: {
      backgroundColor: 'rgba(9,131,117,0.1)',
      height: '100%',
    },
    tableContent: {
      '& tbody > tr:first-of-type > .MuiTableCell-root': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
      },
      '& tbody > tr:last-of-type > .MuiTableCell-root': {
        borderBottom: 'none',
      },
    },
    emptyTable: {
      textAlign: 'center',
      padding: '24px',
      '& > div': {
        padding: '40px',
      },
    },
    emptyTableText: {
      marginBottom: '16px',
    },
    emptyTableIcon: {
      margin: 'auto',
      height: '168px',
      width: '168px',
      backgroundColor: 'rgba(225, 225, 225, 0.2)',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        height: '90px',
        width: '90px',
        padding: '6px 0 0 6px',
      },
    },
    cardContent: {
      padding: '0px 32px 0px 32px',
      maxHeight: '660px',
      overflowY: 'auto',
    },
    cardHeader: {
      padding: '8px 16px 0px 16px',
    },
    cardTitle: {
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold,
      fontSize: '16px',
      paddingLeft: '16px',
    },
    cardActionButton: {
      margin: '20px 24px 16px 0px',
      fontWeight: typography.fontWeightBold,
      color: palette.secondary.dark,
      border: '1px solid rgba(0,0,0,0.3)',
    },

    currencyCell: {
      display: 'flex',
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold,
      alignItems: 'center',
      borderRight: 'solid',
      borderRightWidth: 'thin',
      borderRightColor: shade(palette.secondary.light, 1),
      '& > .divider': {
        minHeight: '3vh',
        margin: '0px 0px 0px 24px',
      },
    },

    imageHolder: {
      display: 'flex',
      overflow: 'hidden',
      borderRadius: '50%',
      marginLeft: '8px',
      marginRight: '4px',
      width: '20px',
      height: '20px',
      border: '2px solid rgb(224,218,218)',
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'relative',
      alignItems: 'center',
      padding: '5px',
      '& img': {
        width: '22px',
        height: '30px',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
    },
    totals: {
      textAlign: 'left',
      color: shade(palette.secondary.light, 6),
      fontWeight: typography.fontWeightBold,
      fontSize: '12px',
      '& svg': {
        marginRight: '4px',
      },
      margin: '0px 2px 0px 8px',
    },
    balance: {
      fontWeight: typography.fontWeightBold,
      textAlign: 'end',
    },
  }),
  { name: 'CurrencyAccountsWidget' },
);
export default useStyles;
