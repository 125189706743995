import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    list: {
      '& li:not(:last-child)': {
        marginBottom: 15,
      },
    },

    confirmContainer: {
      display: 'flex',
      paddingRight: 10,
      '& .confirmCheckbox': {
        padding: 0,
      },
      '& .confirmText': {
        paddingLeft: 10,
      },
    },
  }),
  { name: 'BeneFraudAcknowledgeModal' },
);

export default useStyles;
