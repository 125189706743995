import { ActionButton } from 'components/Buttons';
import React from 'react';

interface IProps {
    disabled: boolean;
    editable: boolean;
    handleAddPaymentToBatch: () => void;
}
export const SubmitButton = ({
  disabled,
  editable,
  handleAddPaymentToBatch,
}: IProps) => (
  <div className="submitButton-holder">
    <ActionButton
      disabled={disabled}
      type="submit"
      testId="submit-login"
      fullWidth
      rounded
      size="large"
      onClick={handleAddPaymentToBatch}
    >
      {!editable ? 'Add payment to batch' : 'Update payment'}
    </ActionButton>
  </div>
);

export default SubmitButton;
