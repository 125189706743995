import { CurrencyAccountSearchDto } from '@alpha/currency-accounts-dtos';

import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { groupBy } from 'lodash';
import React,
{
  memo, useContext, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ActionButton, OutlinedButton } from 'components/Buttons';
import { TransactionContext } from '../../../domain/TransactionsMultiEntity/TransactionsContext';
import { actions } from '../../../store/transactions/transactionsPage.reducer';
import { useStyles } from './Dropdown.styles';
import { Item } from './Item/Item';
import RenderValue from './RenderValue/RenderValue';

export enum AccountSelectionEnum {
  ALL_ACCOUNTS = 'All Accounts',
  HEADER = 'Header',
}

interface IProps {
  currencyAccounts: CurrencyAccountSearchDto[];
  selectName?: string;
  disabled?: boolean;
  testId?: string;
  handleChange: (
    event: SelectChangeEvent<{
      name?: string | undefined;
      value: string[] | unknown;
    }>
  ) => void;
  handleBlur?: (event: any) => void;
  setApplySearch: React.Dispatch<React.SetStateAction<boolean>>;
  total: number;
}
const TransactionDropDown: React.FC<IProps> = (props: IProps) => {
  const {
    currencyAccounts,
    selectName,
    disabled,
    testId,
    handleChange,
    handleBlur,
    setApplySearch,
    total,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const styles = useStyles();
  const dispatch = useDispatch();
  const transactionContext = useContext(TransactionContext);

  const currencyAccountsGroupedByAccountId = groupBy(
    currencyAccounts,
    (ca: CurrencyAccountSearchDto) => ca.accountName,
  );

  const calculateIfDropDownItemIsSelected = (
    currencyAccountId: string,
  ): boolean => {
    if (
      transactionContext?.currencyAccountIds
      && transactionContext?.currencyAccountIds?.length > 0
    ) {
      return transactionContext?.currencyAccountIds?.includes(
        currencyAccountId,
      );
    }
    return false;
  };

  const calculateIfAllSubItemsOfAccountIsSelected = (
    accountId: string,
  ): boolean => {
    let allAccountsSelected = true;
    currencyAccountsGroupedByAccountId[accountId].forEach(
      (curr: CurrencyAccountSearchDto) => {
        if (
          transactionContext?.currencyAccountIds
          && !transactionContext?.currencyAccountIds.includes(curr.id)
        ) {
          allAccountsSelected = false;
        }
      },
    );
    return allAccountsSelected;
  };

  const dropdownItems = Object.keys(currencyAccountsGroupedByAccountId).map(
    (accountId: string) => {
      const elements: JSX.Element[] = [
        <MenuItem
          key={accountId}
          value={`${AccountSelectionEnum.HEADER}|${accountId}|${calculateIfAllSubItemsOfAccountIsSelected(
            accountId,
          )}`}
          style={{ padding: 0, backgroundColor: 'transparent' }}
        >
          <Item.Sticky
            currencyCode={accountId}
            checked={calculateIfAllSubItemsOfAccountIsSelected(accountId)}
          />
        </MenuItem>,
      ];
      const currencyAccountRows = (
        currencyAccountsGroupedByAccountId[
          accountId
        ] as CurrencyAccountSearchDto[]
      ).map((currencyAccount: CurrencyAccountSearchDto) => (
        <MenuItem
          key={currencyAccount.id}
          value={currencyAccount.id}
          style={{ padding: 0, backgroundColor: 'transparent' }}
        >
          <Item.Data
            currencyAccount={currencyAccount}
            checked={calculateIfDropDownItemIsSelected(currencyAccount.id)}
          />
        </MenuItem>
      ));

      return elements.concat(currencyAccountRows);
    },
  );

  const checkAllSelected = (): boolean => {
    if (transactionContext?.currencyAccountIds && transactionContext?.currencyAccountsOptions) {
      return (
        transactionContext?.currencyAccountIds.length
        === transactionContext?.currencyAccountsOptions.length
      );
    }
    return false;
  };

  const dropdownItemsWithAllAccounts = [
    [
      <MenuItem
        key={`${AccountSelectionEnum.ALL_ACCOUNTS}`}
        value={`${AccountSelectionEnum.ALL_ACCOUNTS}`}
        style={{ padding: 0, backgroundColor: 'transparent' }}
      >
        <Box className={clsx(styles.root)}>
          <Checkbox color="primary" checked={checkAllSelected()} />
          <Typography variant="subtitle1">
            {`${AccountSelectionEnum.ALL_ACCOUNTS} (${transactionContext?.currencyAccountIds?.length}/${total})`}
          </Typography>
        </Box>
      </MenuItem>,
    ],
    [...dropdownItems],
  ];

  const renderValue = (selectValues: string[] | undefined) => {
    if (selectValues?.length === 1) {
      const selectedCurrencyAccount = currencyAccounts.find(
        (ca) => ca.id === selectValues[0],
      );
      if (!selectedCurrencyAccount) return undefined;
      return () => (
        <RenderValue selectedCurrencyAccount={selectedCurrencyAccount} />
      );
    }
    if (selectValues?.length === 0
      || (selectValues && selectValues.length === total)) {
      return () => <Typography variant="subtitle1">All Accounts</Typography>;
    }
    return () => <Typography variant="subtitle1">Multiple Accounts</Typography>;
  };

  return (
    <div className={styles.dropdownContainer}>
      <Select
        multiple
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        value={(
          transactionContext?.currencyAccountIds
          && transactionContext?.currencyAccountIds?.length > 0)
          ? transactionContext?.currencyAccountIds : ['placeholder']}
        name={selectName}
        id={testId || 'dropdown'}
        placeholder="All Accounts"
        renderValue={renderValue(transactionContext?.currencyAccountIds)}
        fullWidth
        className={styles.dropdown}
        MenuProps={{
          className: styles.dropdownList,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        style={{
          overflowY: 'auto',
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        {[
          <MenuItem key="transactions-menu-select-account" value="placeholder">
            <Typography variant="subtitle1" className={styles.placeholder}>
              Please Select an account
            </Typography>
          </MenuItem>,
          ...dropdownItemsWithAllAccounts,
          <Box key="transactions-menu-footer" className={styles.stickyFooter}>
            <OutlinedButton
              onClick={() => {
                transactionContext?.setCurrencyAccountIds([]);
                dispatch(
                  actions.updateSelectedCurrencyAccounts([]),
                );
                setApplySearch(true);
              }}
              rounded
              testId="footer-clear-button"
              size="small"
            >
              Clear
            </OutlinedButton>
            <ActionButton
              onClick={() => {
                dispatch(
                  actions.updateSelectedCurrencyAccounts(
                    transactionContext?.currencyAccountIds || [],
                  ),
                );
                setApplySearch(true);
                setOpen(false);
              }}
              rounded
              testId="footer-apply-button"
              size="small"
            >
              Apply
            </ActionButton>
          </Box>,
        ]}
      </Select>
    </div>
  );
};

export default memo(TransactionDropDown);
