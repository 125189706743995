import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    root: ({ chars } : { chars?: number }) => ({
      maxWidth: chars ? `${chars}ch` : '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: 0,
    }),
    tooltipText: {
      fontSize: 12,
      padding: '5px',
    },
  }),
  { name: 'TextEllipsis' },
);

export default useStyles;
