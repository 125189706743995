import { StyledTabsWrapper } from 'components/Tabs';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import routes from '../../../../routes.path';
import history from '../../../../services/history/browserHistory';
import { authySlice } from '../../../../store/authy/reducer';
import { actions } from '../../../../store/payments/payments.reducer';

const Tabs: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const location = useLocation();
  const dispatch = useDispatch();

  const setTabIndexBasedOnLocation = (): void => {
    if (location.pathname.includes(routes.payments.manual.single)) {
      setTabIndex(0);
    } else if (location.pathname.includes(routes.payments.manual.batch)) {
      setTabIndex(1);
    } else {
      history.push(routes.payments.manual.single);
    }
  };

  useEffect(() => setTabIndexBasedOnLocation(), [
    location.pathname,
    setTabIndex,
  ]);

  const handleSetSelectedTabIndex = (selectedTabIndex: number): void => {
    dispatch(actions.clear());
    dispatch(authySlice.actions.reset());
    history.push(
      (selectedTabIndex && routes.payments.manual.batch)
      || routes.payments.manual.single,
    );
  };

  return (
    <StyledTabsWrapper
      testId="tabs"
      tabTitles={['Single Payment', 'Batch Payment']}
      tabIndex={tabIndex}
      handleChange={handleSetSelectedTabIndex}
    />
  );
};

export default Tabs;
