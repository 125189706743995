import _default, { makeStyles } from 'theme';

const { palette, typography } = _default;

const useStyles = makeStyles(
  () => ({
    headerWrapper: {
      position: 'sticky',
      zIndex: 100,
      top: 0,
      left: 0,
      width: '100%',
      padding: '60px 30px 20px 30px',
      backgroundColor: 'white',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    breadcrumbs: {
      textTransform: 'uppercase',
      color: palette.primary.light,
      fontSize: 12,
      fontWeight: typography.fontWeightSemiBold,
      letterSpacing: 1.5,
      paddingBottom: 10,
    },
    title: {
      fontSize: 22,
      fontWeight: typography.fontWeightBold,
      letterSpacing: -0.5,
    },
    inputWrapper: {
      paddingTop: 30,
      '& .MuiInputBase-root': {
        '& div': {
          backgroundColor: '#F7F7F7',
          paddingRight: 0,
        },
        '& input': {
          paddingLeft: 0,
        },
      },
    },
    icon: {
      color: '#098375 !important',
      fontSize: '14px !important',
    },
  }),
  { name: 'SwitchAccountDrawerHeader' },
);

export default useStyles;
