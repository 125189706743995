/* eslint-disable no-await-in-loop */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { makeStyles } from 'theme';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ApproveBatchDto } from '@alpha/payments-dtos/lib/batches/ApproveBatchDto';
import PaymentsService from '../../../services/Payments/payments.service';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import { actions, TAuthyType } from '../../../store/authy/reducer';

const useStyles = makeStyles(
  () => ({
    container: {
      marginBottom: '32px',
      '& > img': {
        marginBottom: '60px',
        width: '70px',
        height: 'auto',
      },
      '& br': {
        lineHeight: '24px',
      },
    },
    footer: {},
  }),
  { name: 'Success' },
);
interface IProps {
  authyType: TAuthyType;
  approvalRequestId: string;
  batchId: string;
  approveOwn: boolean;
  accountId?: string;
  fraudWarningAcknowledged?: boolean;
}

const PushNotification: React.FC<IProps> = ({
  authyType,
  batchId,
  approvalRequestId,
  approveOwn,
  accountId,
  fraudWarningAcknowledged,
}: IProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();

  const approveEndpoint = async (): Promise<ApproveBatchDto> => {
    if (authyType === 'PAYMENT_APPROVE') {
      return PaymentsService.approvePayment(
        { batchId, approvalRequestId, fraudWarningAcknowledged },
        approveOwn,
        accountId,
      );
    }
    const response = await PaymentsService.postSubmitPayment(
      { batchId, approvalRequestId },
      accountId,
    );
    if (response.success) {
      if (approveOwn) {
        await PaymentsService.postApproveOwnPaymentAsync(
          { batchId, approvalRequestId, fraudWarningAcknowledged },
          accountId,
        );
      }
    }
    return response;
  };

  const getApproveEndpointStatus = async () => {
    let errorCount = 0;
    let loopCount;
    const maxErrorCount = 3;
    const maxLoopCount = 50;

    for (loopCount = 1; loopCount < maxLoopCount; loopCount += 1) {
      try {
        const { pending, success, message } = await approveEndpoint();
        if (success) {
          sb.trigger('Success', 'success');
          dispatch(actions.updateStatus('SUCCESS'));
          setTimeout(() => {
            dispatch(actions.reset());
          }, 5000);
          break;
        } else if (!pending) {
          sb.trigger(
            message || 'Something went wrong with approving your payment',
          );
          dispatch(actions.updateStatus('ERROR'));
          setTimeout(() => dispatch(actions.reset()), 5000);
          break;
        }
      } catch (error) {
        errorCount += 1;
        sb.trigger(
          error.response?.data?.error ||
            'Something went wrong with approving your payment',
        );
      }
      if (errorCount === maxErrorCount) {
        dispatch(actions.reset());
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 3500));
    }

    if (loopCount === maxLoopCount - 1) {
      sb.trigger('Approval timed out');
      dispatch(actions.updateStatus('ERROR'));
      setTimeout(() => dispatch(actions.reset()), 5000);
    }
  };
  useEffect(() => {
    getApproveEndpointStatus();
  }, [approvalRequestId]);

  return (
    <div className={styles.container}>
      <img
        src="/static/images/pushNotification.png"
        alt="Push Notification Icon"
      />
      <Typography variant="subtitle1">
        A push notification has been sent to your device
        <br />
        Approve the push on your device to continue
      </Typography>
    </div>
  );
};

export default PushNotification;
