import getRoles from './RolesMock';
import { approvalLevel } from './UserMock';

const getUserRoleRequests = (page: number, total: number, status: string) => {
  const users = [];
  users.push({
    requestId: '12345678-1234-1234-1234-RRRRRRRR0000',
    userId: '12345678-1234-1234-1234-UUUUUUUU0000',
    userName: 'Test Username',
    isNewUser: true,
    accountId: '12345678-1234-1234-1234-EEEEEEEE00000',
    accountName: 'Test Account',
    currentRoles: [{ id: 'ENTITY_MANAGER_ADD_ALL_PERMS', label: 'Entity Manager' }],
    requestedRoles: [{ id: '01', label: 'Payment approver own' }, { id: '02', label: 'Beneficiary approver own' }, { id: '03', label: 'FX IAT approver' }],
    status,
    created: new Date('03 February 2023 14:48 UTC').toISOString(),
    currentApprovalLevel: null,
    requestedApprovalLevel: 'A',
  });
  users.push({
    requestId: '12345678-1234-1234-1234-RRRRRRRR0001',
    userId: '12345678-1234-1234-1234-UUUUUUUU0001',
    userName: 'Test Mocky User',
    isNewUser: false,
    accountId: '12345678-1234-1234-1234-EEEEEEEE00001',
    accountName: 'Test Mocky Account',
    currentRoles: [{ id: '01', label: 'View Only' }],
    requestedRoles: [{ id: '01', label: 'View Only' }],
    status,
    created: new Date('03 February 2023 14:48 UTC').toISOString(),
    currentApprovalLevel: 'C',
    requestedApprovalLevel: 'B',
  });
  users.push({
    requestId: '12345678-1234-1234-1234-RRRRRRRR0002',
    userId: '12345678-1234-1234-1234-UUUUUUUU0002',
    userName: 'Test Alpha User',
    isNewUser: false,
    accountId: '12345678-1234-1234-1234-EEEEEEEE00002',
    accountName: 'Alpha Test Account',
    currentRoles: [{ id: '01', label: 'View Only' }, { id: '02', label: 'Beneficiary Approver Own' }, { id: '02', label: 'Beneficiary Approver Own' }, { id: 'ENTITY_MANAGER_ADD_ALL_PERMS', label: 'Entity Manager' }],
    requestedRoles: [],
    status,
    created: new Date('03 February 2023 14:48 UTC').toISOString(),
    currentApprovalLevel: 'A',
    requestedApprovalLevel: 'A',
  });
  for (let i = 3; i < total; i += 1) {
    const roles = getRoles(i);
    const approvalLevelkey = i % Object.keys(approvalLevel).length;
    if (status === 'pending') {
      users.push({
        requestId: `12345678-1234-1234-1234-RRRRRRRR${(`${page}${i}`).toString().padStart(4, '0')}`,
        userId: `12345678-1234-1234-1234-UUUUUUUU${((`${page}${i}`)).toString().padStart(4, '0')}`,
        userName: `User Name ${page}${i + 1}`,
        isNewUser: false,
        accountId: `12345678-1234-1234-1234-EEEEEEEE${(`${page}${i}`).toString().padStart(4, '0')}`,
        accountName: `accountName ${page}${i + 1}`,
        currentRoles: getRoles(i),
        requestedRoles: roles.slice(roles.length / 2, roles.length),
        status,
        created: new Date('05 October 2011 14:48 UTC').toISOString(),
        currentApprovalLevel: approvalLevel[approvalLevelkey],
        requestedApprovalLevel: approvalLevel[approvalLevelkey],
      });
    } else {
      users.push({
        requestId: `12345678-1234-1234-1234-RRRRRRRR${(`${page}${i}`).toString().padStart(4, '0')}`,
        userId: `12345678-1234-1234-1234-UUUUUUUU${((`${page}${i}`)).toString().padStart(4, '0')}`,
        userName: `User Name ${page}${i + 1}`,
        isNewUser: false,
        accountId: `12345678-1234-1234-1234-EEEEEEEE${(`${page}${i}`).toString().padStart(4, '0')}`,
        accountName: `accountName ${page}${i + 1}`,
        currentRoles: getRoles(i),
        requestedRoles: roles.slice(roles.length / 2, roles.length),
        status,
        created: new Date('05 October 2011 14:48 UTC').toISOString(),
        currentApprovalLevel: approvalLevel[approvalLevelkey],
        requestedApprovalLevel: approvalLevel[approvalLevelkey],
      });
    }
  }
  return users;
};

export default getUserRoleRequests;
