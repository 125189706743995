import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    container: {
      paddingBottom: '85px',
    },
    header: {
      letterSpacing: -0.5,
    },
  }),
  { name: 'Profiles' },
);

export default useStyles;
