import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IWizardModalHeader } from '../../../Modal.interfaces';
import useStyles from '../../index.styles';
import CustomStepper from './CustomStepper/CustomStepper';

const WizardModalHeader = ({
  title, steps, activeStep, handleCloseModal, error,
}: IWizardModalHeader) => {
  const {
    closeButton, header,
  } = useStyles();

  return (
    <div className={header}>
      <h3>{title}</h3>
      <FontAwesomeIcon data-testid="close-button" icon={faTimes} className={closeButton} onClick={handleCloseModal} />
      <CustomStepper error={error} steps={steps} activeStep={activeStep} />
    </div>
  );
};

export default WizardModalHeader;
