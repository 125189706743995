import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import cls from 'classnames';
import { Box } from '@mui/material';
import { useStyles } from '../ChoosePayment.styles';

let dragCounter = 0; // avoid blinking on draging

interface IDragAndDrop {
  onDropHandler: (files: File | FileList) => void;
  onClick?: () => void;
  className?: string;
  'data-testid': string;
  children?: any;
  multiple: boolean;
}

const DragAndDrop: FC<IDragAndDrop> = (props: IDragAndDrop) => {
  const classes = useStyles();
  const [isDragEnter, setDragEnter] = useState(false);
  const {
    onDropHandler,
    onClick,
    className,
    children,
    'data-testid': dataTestId,
    multiple = false,
  } = props;

  useEffect(() => {
    dragCounter = 0;
  }, []);

  const onDragEnter = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragCounter += 1;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDragEnter(true);
      }
    },
    [setDragEnter],
  );

  const onDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onDragLeave = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragCounter -= 1;
      if (dragCounter > 0) return;
      setDragEnter(false);
    },
    [setDragEnter],
  );

  const onDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragEnter(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const files = multiple ? e.dataTransfer.files : e.dataTransfer.files[0];
        onDropHandler(files);

        e.dataTransfer.clearData();
        dragCounter = 0;
      }
    },
    [onDropHandler, multiple],
  );

  return (
    <Box
      className={cls(className, { [classes.chooseFileIsDragEnter]: isDragEnter }, 'dd-privacy-allow')}
      data-testid={dataTestId}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default DragAndDrop;
