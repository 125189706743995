import React from 'react';

type TCallback = () => void;

export const ElasticTableContext = React.createContext<TCallback | undefined>(undefined);

interface IProps {
  children: (JSX.Element | undefined)[],
  callback: TCallback;
}

export const ElasticTableContextContainer: React.FC<IProps> = ({ callback, children }) => (
  <ElasticTableContext.Provider value={callback}>
    {children}
  </ElasticTableContext.Provider>
);

export default ElasticTableContextContainer;
