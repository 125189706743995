import {
  BatchCurrencyPairDto,
  PaymentBatchStatus,
  PaymentDraftDto,
} from '@alpha/payments-dtos';
import { Box } from '@mui/material';
import { Loader } from 'components/Loader';
import { APMainLayout } from 'components/Layout/MainLayout';
import { StyledTabsWrapper, TabContent } from 'components/Tabs';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { GhostButton } from 'components/Buttons';
import DrawerBackdrop from '../../../components/Drawer/DrawerBackdrop/DrawerBackdrop';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import { CurrencyPair } from '../../../components/International';
import { DownloadType, FileType } from '../../../hooks/useReportsPolling';
import { flowToBatchStatusMapping } from '../../../utils/batchStatus.helpers';
import BatchDetails from './BatchDetails/BatchDetails';
import { IBatchCurrencyPair } from './BatchSummary.interface';
import { useStyles } from './BatchSummary.styles';
import FooterContainer from './FooterContainer/FooterContainer';
import PaymentSummaryContainer from './PaymentSummary/PaymentSummary';
import TitleContainer from './TitleContainer/TitleContainer';
import useBatchSummary from './useBatchSummary';

export const componentTestIds = {
  BatchSummary: 'batch-summary-screen',
  BatchSummaryTabs: 'batch-summary-tabs',
};

export const BatchSummary: React.FC = () => {
  const classes = useStyles();
  const {
    accountId,
    batchId,
    batchDetails,
    authyStatus,
    selectedTabIndex,
    routeParameterStats,
    getBreadcrumbs,
    handleSuccessfulAuthy,
    updateBatchDetails,
    handleReportGeneration,
    handleSetSelectedTabIndex,
    loadingScreen,
    actionNeeded,
  } = useBatchSummary();

  useEffect(() => {
    if (authyStatus === 'SUCCESS') {
      handleSuccessfulAuthy(
        authyStatus,
        batchId,
        accountId,
        routeParameterStats,
      );
    }
  }, [batchId, authyStatus, routeParameterStats]);

  useEffect(() => {
    updateBatchDetails(batchId, routeParameterStats, accountId);
  }, []);

  const [paymentDrafts, setPaymentDrafts] = useState<PaymentDraftDto[]>();
  const [paymentDraftsLoading, setPaymentDraftsLoading] =
    useState<boolean>(true);
  if (!batchDetails) {
    return <FullPageLoader testId="batchsummary-fullpageloader" />;
  }

  const getCurrencyPairTabs = (): any[] => {
    if (batchDetails) {
      return (batchDetails.batchCurrencyPairs as any).map(
        (currencyPair: IBatchCurrencyPair | BatchCurrencyPairDto) => {
          const { sellCurrency, buyCurrency } = currencyPair;
          if (sellCurrency === buyCurrency) {
            return {
              ...currencyPair,
              display: <CurrencyPair currencyCode={sellCurrency} />,
            };
          }
          return {
            ...currencyPair,
            display: `${sellCurrency}/${buyCurrency}`,
          };
        },
      );
    }

    return [];
  };
  const currencyPairTabs: Array<any> = getCurrencyPairTabs();

  return (
    <APMainLayout
      data-testid={componentTestIds.BatchSummary}
      title={<TitleContainer batchDetails={batchDetails} isCrossCurrency />}
      breadCrumbs={getBreadcrumbs(batchDetails && batchDetails.batchStatus)}
      pageTabs={
        <StyledTabsWrapper
          testId={componentTestIds.BatchSummaryTabs}
          tabTitles={currencyPairTabs.map((tab) => tab.display)}
          tabIndex={selectedTabIndex}
          handleChange={handleSetSelectedTabIndex}
          className="dd-privacy-allow"
        />
      }
      rightAlignedCta={
        !flowToBatchStatusMapping.uploader.includes(
          batchDetails && batchDetails.batchStatus,
        ) ? (
          <GhostButton
            className={classes.exportButton}
            colorVariant="default"
            size="large"
            onClick={() =>
              handleReportGeneration(FileType.PDF, DownloadType.Batch)
            }
          >
            <FontAwesomeIcon icon={faDownload} />
            <span className="dd-privacy-allow"> payment summary </span>
          </GhostButton>
        ) : undefined
      }
    >
      {currencyPairTabs.map((currencyPairTab, i) => (
        <TabContent
          key={`${currencyPairTab.sellCurrency}/${currencyPairTab.buyCurrency}`}
          index={i}
          value={selectedTabIndex}
        >
          <Box className={classes.tabContainer}>
            {batchDetails && (
              <BatchDetails
                accountId={accountId}
                batchDetails={batchDetails}
                currencyPairTab={currencyPairTab}
              />
            )}
            {batchId && // put batchId here
              currencyPairTab.sellCurrency &&
              currencyPairTab.buyCurrency &&
              currencyPairTab.fixedSide &&
              currencyPairTab.fixedSide && (
                <PaymentSummaryContainer
                  accountId={accountId}
                  postApproval={Boolean(
                    batchDetails.batchStatus === PaymentBatchStatus.COMPLETE ||
                      batchDetails.batchStatus ===
                        PaymentBatchStatus.PROCESSING,
                  )}
                  currencyPairId={currencyPairTab.id}
                  sellCurrencyCode={currencyPairTab.sellCurrency}
                  buyCurrencyCode={currencyPairTab.buyCurrency}
                  fixedSide={currencyPairTab.fixedSide}
                  batchId={batchId}
                  actionNeeded={!!actionNeeded}
                  batchStatus={batchDetails.batchStatus}
                  setPaymentDrafts={setPaymentDrafts}
                  setPaymentDraftsLoading={setPaymentDraftsLoading}
                />
              )}
          </Box>
        </TabContent>
      ))}
      <FooterContainer
        accountId={accountId}
        batchDetails={batchDetails}
        paymentDrafts={paymentDrafts}
        paymentDraftsLoading={paymentDraftsLoading}
      />
      <DrawerBackdrop
        display={loadingScreen}
        width="100%"
        backgroundColor="rgba(255, 255, 255, 0.95)"
      >
        <Loader testId="payment-complete-loading" />
      </DrawerBackdrop>
    </APMainLayout>
  );
};
