import { ActionButton } from 'components/Buttons';
import { Typography } from '@mui/material';
import React from 'react';
import WarningIcon from '../../../assets/warning.svg';

const TransactionError: React.FC<{ onClick: Function }> = ({ onClick }) => (
  <div style={{
    textAlign: 'center', color: '#b95656', width: '450px', fontWeight: 400,
  }}
  >
    <img src={WarningIcon} alt="warning" style={{ marginBottom: '24px', width: '48px' }} />
    <Typography variant="h4" style={{ color: '#b95656' }}>Transaction Error</Typography>
    <Typography
      variant="h3"
      style={{
        fontWeight: 100, fontSize: '19px', lineHeight: 1.5, margin: '32px 0 56px 0',
      }}
    >
      There has been an error with this transaction, please contact us.
      Please do not re-attempt the same transaction.
    </Typography>
    <ActionButton size="large" onClick={() => onClick()} fullWidth>acknowledge</ActionButton>
  </div>
);

export default TransactionError;
