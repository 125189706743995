import clsx from 'clsx';
import React from 'react';
import useStyles from './Card.styles';

export type TCardProps = {
    children: JSX.Element | JSX.Element[],
    padding?: number;
    className?: string;
}

const Card: React.FC<TCardProps> = (props) => {
  const {
    children, className, padding = 40,
  } = props;

  const styles = useStyles({ padding });

  return (
    <div className={clsx(styles.root, className)}>
      {children}
    </div>
  );
};

export default Card;
