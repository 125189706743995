import _default, { makeStyles } from 'theme';

const { typography } = _default;

export const useStyles = makeStyles(
  () => ({
    headerText: {
      letterSpacing: -0.5,
    },
    drawerWrapper: {
      '& .MuiDrawer-paper': {
        width: '610px',
        '& th': {
          borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
        },
        '& td': {
          borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
          '&:last-child': {
            padding: 0,
          },
        },
      },
    },
    loader: {
      width: '610px',
    },
    header: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      padding: '60px 25px 15px 50px',
    },
    headerContent: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > span:first-child': {
        fontSize: 22,
        fontWeight: typography.fontWeightLight,
      },
    },
    downloadButton: {
      minHeight: 'none',
      minWidth: 'none',
      height: 40,
      width: 40,
    },
    icon: {
      color: '#9F9F9F',
      height: 20,
      width: 20,
    },
    modalButtons: {
      padding: '8px 0 0 0',
      textAlign: 'right',
      '& .cancelButton': {
        fontWeight: 'bold',
      },
      '& .deleteButton': {
        marginLeft: '16px',
      },
    },
    modalContainer: {
      maxWidth: '600px',
    },
    modalHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      '& h2': {
        fontWeight: '400',
      },
      '& img': {
        width: '25px',
        marginRight: '8px',
      },
    },
  }),
  { name: 'CreateEntityGroups' },
);

export default useStyles;
