import React, { useEffect } from 'react';
import { GridValidRowModel, GridColumns } from '@mui/x-data-grid';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { GetAllUsersByStatusRequest, GetAllUsersByStatusResponse, GetAllUsersByStatusResponseDataItem } from '@portal/user-types';
import { CSSTransition } from 'react-transition-group';
import CollapsibleCard from '../../../../../components/CollapsibleCard';
import DataTable from '../../../../../components/Table/DataTable';
import useDataTable from '../../../../../hooks/useDataTable';
import routes from '../../../../../routes.path';
import useStyles from '../index.styles';
import getStatusVariant from '../../../utils/getStatusVariant';
import UserManagementService from '../../../../../services/UserManagement/userManagement.service';
import ApprovedUsersDropDown from './ApprovedUsersTableActionDropdown';
import { useUserManagementContext } from '../../../context';
import { IUserTableParams, UserTables } from '../../../context/index.interfaces';

const ApprovedUsersTable = (
  { disableVirtualization = false, search, setTablesCount }: IUserTableParams,
) => {
  const { tableColumn } = useStyles();
  const { setRefreshDataTable } = useUserManagementContext();
  const columns = React.useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: 'fullName',
        headerName: 'Name',
        sortable: false,
        flex: 0.4,
        valueGetter: ({ row: { firstName, lastName } }) => `${firstName} ${lastName}`,
        renderCell: ({ row: { id }, value }) => (
          <Link className={tableColumn} to={`${routes.userManagement.userRoute(id)}`}>{value}</Link>
        ),
      },
      {
        field: 'username',
        headerName: 'Username',
        flex: 0.7,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'User Status',
        flex: 0.2,
        sortable: false,
        renderCell: ({ value }) => getStatusVariant(value || 'default'),
      },
      {
        field: 'id',
        headerName: 'Action',
        type: 'actions',
        flex: 0.3,
        sortable: false,
        getActions: ({ row }) => [
          <ApprovedUsersDropDown
            setRefreshDataTable={setRefreshDataTable}
            user={row as GetAllUsersByStatusResponseDataItem}
          />,
        ],
      },
    ], [tableColumn, setRefreshDataTable],
  );

  const {
    rows, page, setPage, isLoading, totalRowCount,
  } = useDataTable<GetAllUsersByStatusRequest, GetAllUsersByStatusResponse>(
    UserManagementService.getAllUsersByStatus, { statusIds: 'active+inactive' },
    search,
  );

  useEffect(() => {
    setTablesCount((tablesCount) => ({
      ...tablesCount,
      [UserTables.APPROVED_USERS]: totalRowCount,
    }));
  }, [setTablesCount, totalRowCount]);

  return (
    <CSSTransition
      appear
      in={!(totalRowCount === 0 && search.length > 0)}
      timeout={1000}
      classNames="fade-up"
    >
      <CollapsibleCard
        title={`Approved ${totalRowCount ? `(${totalRowCount})` : ''}`}
        subHeader="All users who have been approved and are either active or inactive on the platform"
        icon={faCheckCircle}
      >
        <DataTable
          columns={columns}
          data={rows?.users || []}
          pageSize={10}
          page={page}
          setPage={setPage}
          isLoading={isLoading}
          totalRowCount={totalRowCount}
          getRowId={(row: any) => row.id}
          disableVirtualization={disableVirtualization}
          noRowsLabel="You currently do not have any approved users"
        />
      </CollapsibleCard>
    </CSSTransition>
  );
};

export default ApprovedUsersTable;
