import React from 'react';
import { TPayment } from '../../../../models/payments';
import Body from './Body/Body';

export const initialState: IErrorTableState = {
  tableData: [],
  hasMore: false,
  paginationUrls: [],
  displayLoader: false,
  itemLoadSize: 10,
};

interface IErrorPaymentProps {
  handleClose: () => void;
  batchId: string
  totalPayments: number | undefined;
  totalInvalidPayments: number | undefined;
  batchErrors: string[] | undefined;
}

export interface IErrorTableState {
  tableData: TPayment[],
  hasMore: string | false,
  paginationUrls: string[],
  displayLoader: boolean,
  itemLoadSize: number,
}

const ErrorPayment: React.FC<IErrorPaymentProps> = (props: IErrorPaymentProps) => {
  const {
    batchId, totalPayments, totalInvalidPayments, batchErrors, handleClose,
  } = props;

  return (
    <Body
      handleClose={handleClose}
      batchErrors={batchErrors}
      totalInvalidPayments={totalInvalidPayments}
      totalPayments={totalPayments}
      batchId={batchId}
    />
  );
};

export default ErrorPayment;
