import React, { FC, forwardRef, memo } from 'react';
import { IBaseButtonProps } from '../BaseButton';
import StyledOutlinedButton from './OutlinedButton.styles';

const OutlinedButton: FC<IBaseButtonProps> = memo(
  forwardRef(({ color = 'secondary', ...props }, ref) => (
    <StyledOutlinedButton
      ref={ref}
      {...props}
      color={color}
      variant="outlined"
    />
  )),
);

export default OutlinedButton;
