import { AxiosResponse } from 'axios';
import { CurrencyAccountSearchTotalDto } from '@alpha/currency-accounts-dtos';
import { TFormAccount } from '../../domain/InterAccountTransfer/FormValues';
import {
  TCurrencyAccount,
  TCurrencyAccountOptionGroup,
  TCurrencyResponse,
} from '../../models/currencyAccounts';
import { TTransactionData } from '../../models/transactions';
import multiEntityInstance from '../Axios/multiEntityInstance';
import instance, { instanceAccountId, instanceNoAccountId } from '../Axios/instance';

type TransactionLimitResponse = {
  message: 'success' | string,
  currencyAccounts: string[];
};

export default class TransactionsService {
  public static createTransactionData = (
    debitingAccount: Omit<TFormAccount, 'reference'>,
    creditingAccount: TFormAccount,
    valueDate: string | undefined,
    fxRate: number | undefined,
    tradeId: string | undefined,
    tradeFriendlyId: string | undefined,
  ): TTransactionData => ({
    debitFriendlyName: debitingAccount.currencyAccount!.friendlyName,
    creditFriendlyName: creditingAccount.currencyAccount!.friendlyName,
    reference: creditingAccount.reference || '-',
    creditAmount: creditingAccount.amount!,
    debitAmount: debitingAccount.amount!,
    debitReference: debitingAccount.currencyAccount?.reference,
    creditReference: creditingAccount.currencyAccount?.reference,
    debitIban: debitingAccount.currencyAccount?.iban,
    creditIban: creditingAccount.currencyAccount?.iban,
    debitType: debitingAccount.currencyAccount?.type,
    creditType: creditingAccount.currencyAccount?.type,
    debitCurrencyCode: debitingAccount.currencyAccount?.currencyCode!,
    creditCurrencyCode: creditingAccount.currencyAccount?.currencyCode!,
    arrivalDate: new Date().toISOString(),
    valueDate,
    fxRate,
    tradeId,
    tradeFriendlyId,
  });

  public static async getTransactionAccounts(
    accountId?: string,
  ): Promise<TCurrencyResponse> {
    const response: AxiosResponse<TCurrencyResponse> = accountId ? await instanceNoAccountId.get(
      '/ca/currency-accounts',
      {
        headers: {
          'account-id': accountId,
        },
      },
    )
      : await instance.get(
        '/ca/currency-accounts',
      );
    return response.data;
  }

  public static async getTransactionAccountsMultiEntity(skip = 0, accountId?: string)
  : Promise<CurrencyAccountSearchTotalDto> {
    const response:
    AxiosResponse<CurrencyAccountSearchTotalDto> = accountId
      ? await instanceAccountId(accountId).get(
        `/ca/currency-accounts/search?take=all&skip=${skip}&sortby=accountId&sortorder=asc`,
      ) : await multiEntityInstance.get(
        `/ca/currency-accounts/search?take=all&skip=${skip}&sortby=accountId&sortorder=asc`,
      );
    return response.data;
  }

  public static async validateTransactionsLimit(dataFrom: string, dataTo: string,
    currencyAccountIds: string[])
  : Promise<TransactionLimitResponse> {
    const response: AxiosResponse<TransactionLimitResponse> = await multiEntityInstance.post(
      `/ca/currency-accounts/transactions/limit?datefrom=${dataFrom}&dateto=${dataTo}`,
      {
        currencyAccountIds,
      },
    );
    return response.data;
  }

  public static groupResponse(
    data: TCurrencyResponse,
  ): (TCurrencyAccountOptionGroup | TCurrencyAccount)[] {
    if (!data) {
      return [];
    }

    const groups: { [key: string]: TCurrencyAccount[] } = {};

    const output = data.currencyAccounts.filter(
      (account) => account.currencyCode,
    );

    output.forEach((account) => {
      groups[account.currencyCode!] = [];
    });
    output.forEach((account) => {
      groups[account.currencyCode!].push(account);
    });

    const grouped = Object.keys(groups).map((currencyCode) => ({
      currencyCode,
      currencyAccounts: groups[currencyCode],
    }));

    let flattened: (TCurrencyAccountOptionGroup | TCurrencyAccount)[] = [];

    grouped.forEach((group) => {
      flattened.push({ type: 'category', currencyCode: group.currencyCode });
      flattened = flattened.concat(group.currencyAccounts);
    });

    return flattened;
  }
}
