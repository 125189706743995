import { BatchApprover } from '@alpha/payments-dtos';
import {
  Autocomplete, Chip, TextField, Typography,
} from '@mui/material';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent } from 'react';
import useStyles from './MultiSelectDropDown.styles';
import useListStyles from '../AutocompleteDropDown/Listbox.styles';

interface IProps {
  currentOptions: BatchApprover[];
  placeholder?: string;
  options: BatchApprover[];
  disabled?: boolean;
  loading?: boolean;
  onClose?: (event: ChangeEvent<{}>) => void;
  onOpen?: (event: ChangeEvent<{}>) => void;
  setCurrentOptions: React.Dispatch<React.SetStateAction<BatchApprover[]>>;
  setTextValue: React.Dispatch<React.SetStateAction<string>>;
}

const MultiSelectDropDown: React.FC<IProps> = ({
  currentOptions,
  placeholder,
  options,
  disabled,
  loading,
  onClose,
  onOpen,
  setCurrentOptions,
  setTextValue,
}: IProps) => {
  const classes = useStyles();
  const { listBox } = useListStyles();
  return (
    <Autocomplete
      onOpen={onOpen}
      onClose={onClose}
      loading={loading}
      disabled={disabled || false}
      popupIcon={<FontAwesomeIcon icon={faAngleDown} />}
      classes={{ listbox: listBox }}
      className={classes.dropdown}
      onInputChange={(event, value) => setTextValue(value)}
      onChange={(event: ChangeEvent<{}>, value: any | null) => setCurrentOptions(value)}
      disableCloseOnSelect
      getOptionLabel={(option) => option.fullName}
      multiple
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder || 'Select'}
          variant="filled"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
            form: {
              autoComplete: 'new-off',
            },
            'data-testid': 'multi-select',
          }}
        />
      )}
      renderOption={(autocompleteProps, option: BatchApprover, { selected }: any) => (
        <div className={classes.labelWrapper} {...autocompleteProps}>
          <Typography className={classes.outputText}>{option.fullName}</Typography>
          {
            selected
            && <FontAwesomeIcon className={classes.selectedCheck} icon={faCheckCircle} />
          }
        </div>
      )}
      renderTags={(_value: BatchApprover[], getTagProps) => _value.map(
        (value: BatchApprover, index: number) => (
          <div className={classes.chip}>
            <Chip label={value.fullName} {...getTagProps({ index })} />
          </div>
        ),
      )}
    />
  );
};

export default MultiSelectDropDown;
