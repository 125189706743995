import { useState } from 'react';
import { TUserAccount, TUserAccounts } from '../models/user';
import AuthorizationService from '../services/Authorization/authorization.service';
import useAlphaSnackbar from './useAlphaSnackbar';

const useSwitchAccount = () => {
  const [userAccounts, setUserAccounts] = useState<TUserAccounts | null>(null);
  const sb = useAlphaSnackbar();
  const [isValidAccountId, setIsValidAccountId] = useState<boolean>(false);

  const getUserAccounts = async (): Promise<TUserAccounts> => {
    const newUserAccounts = await AuthorizationService.getUserAccounts();
    setUserAccounts(newUserAccounts);
    return newUserAccounts;
  };

  const getAccountDetailsFromLocalStorage = (): TUserAccount => ({
    id: localStorage.getItem('account-id') || '',
    name: localStorage.getItem('account-id-name') || '',
    onboardingEnabled: JSON.parse(localStorage.getItem('account-onboarding-enabled') || 'false'),
    invoicesEnabled: JSON.parse(localStorage.getItem('account-invoice-enabled') || 'false'),
    dynamicFxSpreadEnabled: JSON.parse(localStorage.getItem('account-dynamic-fx-spread-enabled') || 'false'),
    accountFrozen: JSON.parse(localStorage.getItem('account-frozen') || 'false'),
  });

  const updateCurrentAccount = (account: TUserAccount): void => {
    localStorage.setItem('account-id', account.id);
    localStorage.setItem('account-id-name', account.name);
    localStorage.setItem('account-onboarding-enabled', account.onboardingEnabled !== undefined ? JSON.stringify(account.onboardingEnabled) : 'false');
    localStorage.setItem('account-invoice-enabled', account.invoicesEnabled !== undefined ? JSON.stringify(account.invoicesEnabled) : 'false');
    localStorage.setItem('overdue-invoices-banner-hidden', 'false');
    localStorage.setItem('account-dynamic-fx-spread-enabled', account.dynamicFxSpreadEnabled !== undefined ? JSON.stringify(account.dynamicFxSpreadEnabled) : 'false');
    localStorage.setItem('account-frozen', account.accountFrozen !== undefined ? JSON.stringify(account.accountFrozen) : 'false');
  };

  const clearUserAccountStorage = (): void => {
    const featureFlagsInStorage = localStorage.getItem('persist:root') || {};
    localStorage.setItem('persist:root', JSON.stringify(featureFlagsInStorage));
  };

  const setUserAccount = async (): Promise<void> => {
    try {
      const newUserAccounts = await getUserAccounts();
      if (!newUserAccounts.length) throw Error();

      const { id } = getAccountDetailsFromLocalStorage();
      const index = id ? newUserAccounts.findIndex((n) => n.id === id) : 0;

      const getAccountDetailsIndex = index > 0
        ? index
        : 0;

      const {
        id: accountId,
        name,
        onboardingEnabled,
        invoicesEnabled,
        dynamicFxSpreadEnabled,
        accountFrozen,
      } = newUserAccounts[getAccountDetailsIndex];

      updateCurrentAccount({
        id: accountId,
        name,
        onboardingEnabled,
        invoicesEnabled,
        dynamicFxSpreadEnabled,
        accountFrozen,
      });
      setIsValidAccountId(true);
    } catch {
      sb.trigger('There was an error retrieving your accounts for your user');
      setIsValidAccountId(false);
      // Redirect to 404 page
    }
  };

  return {
    isValidAccountId,
    currentAccount: getAccountDetailsFromLocalStorage(),
    updateCurrentAccount,
    getUserAccounts,
    setUserAccount,
    clearUserAccountStorage,
    userAccounts,
  };
};

export default useSwitchAccount;
