import * as React from 'react';
import { IATDraftResponse } from '@alpha/iat-dtos';
import { IATDraftStatus } from '@alpha/iat-types';
import { GhostButton, ActionButton } from 'components/Buttons';
import useAuthorization from '../../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../../models/user';
import DeleteButton from './DeleteButton/DeleteButton';

interface IButtonsProps {
  iatDraftResponse: IATDraftResponse;
  handleOpenIATDrawer: (iatDraftResponse: IATDraftResponse) => void;
  handleOpenIATRejectModal: (iatDraftResponse: IATDraftResponse) => void;
  handleDeleteIatClick: (iatId: string) => Promise<void>;
  multiEntity?: boolean;
  inputterAccounts: string[],
  getAllPendingTransfers?: () => Promise<void>;
}

const Buttons: React.FC<IButtonsProps> = ({
  iatDraftResponse,
  handleOpenIATDrawer,
  handleOpenIATRejectModal,
  handleDeleteIatClick,
  getAllPendingTransfers,
  multiEntity,
  inputterAccounts,
}: IButtonsProps) => {
  const canBookFx = multiEntity
    ? iatDraftResponse.currentUserCanBookFx
    : useAuthorization([[UserRole.FX_IAT]]).authorized;
  const canApproveIat = multiEntity
    ? iatDraftResponse.currentUserCanApprove
    : useAuthorization([[UserRole.IAT_APPROVER]]).authorized;

  const isIatInputter = multiEntity
    ? inputterAccounts.includes(iatDraftResponse.accountId)
    : useAuthorization([[UserRole.IAT_INPUTTER]]).authorized;
  const hasNoApprovals = !(iatDraftResponse.approvals.length > 0);
  const hasStatusSubmitted = iatDraftResponse.status === IATDraftStatus.SUBMITTED;
  const isDeletable = (isIatInputter && hasNoApprovals && hasStatusSubmitted);

  if (iatDraftResponse.status === 'APPROVED') {
    if (iatDraftResponse.requiresFx && (canApproveIat || canBookFx)) {
      return (
        <>
          {canApproveIat && (
            <GhostButton
              id="rejectButton"
              colorVariant="warning"
              className="rejectButton"
              onClick={() => handleOpenIATRejectModal(iatDraftResponse)}
            >
              REJECT
            </GhostButton>
          )}

          {canBookFx && (
            <ActionButton
              id="requestLiveRatesButton"
              onClick={() => handleOpenIATDrawer(iatDraftResponse)}
              className="requestLiveRatesButton"
            >
              Request Live Rates
            </ActionButton>
          )}

          {!canBookFx && (
            <GhostButton
              id="viewButton"
              variant="outlined"
              colorVariant="default"
              color="secondary"
              onClick={() => handleOpenIATDrawer(iatDraftResponse)}
            >
              View
            </GhostButton>
          )}
        </>
      );
    }
    if (!iatDraftResponse.requiresFx) {
      return (
        <ActionButton
          id="releasePaymentsButton"
          onClick={() => handleOpenIATDrawer(iatDraftResponse)}
        >
          Release Payments
        </ActionButton>
      );
    }
  }

  if (
    iatDraftResponse.currentUserCanApprove
    && iatDraftResponse?.approvals?.length < 2
  ) {
    return (
      <>
        {isDeletable && (
          <DeleteButton
            getAllPendingTransfers={getAllPendingTransfers}
            handleClick={() => handleDeleteIatClick(iatDraftResponse.id)}
          />
        )}
        <GhostButton
          id="rejectButton"
          colorVariant="warning"
          className="rejectButton"
          onClick={() => handleOpenIATRejectModal(iatDraftResponse)}
        >
          REJECT
        </GhostButton>
        <ActionButton
          className="approveButton"
          id="approveButton"
          onClick={() => handleOpenIATDrawer(iatDraftResponse)}
        >
          APPROVE
        </ActionButton>
      </>
    );
  }

  return (
    <>
      {isDeletable && (
        <DeleteButton
          getAllPendingTransfers={getAllPendingTransfers}
          handleClick={() => handleDeleteIatClick(iatDraftResponse.id)}
        />
      )}
      <GhostButton
        id="viewButton"
        color="secondary"
        colorVariant="default"
        variant="outlined"
        onClick={() => handleOpenIATDrawer(iatDraftResponse)}
      >
        View
      </GhostButton>
    </>
  );
};

export default Buttons;
