import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.9)',
      position: 'absolute',
      top: 0, 
      left: 0,
      right: 0,
      bottom :0,
      '& .MuiCircularProgress-root': {
        color: 'white',
      },
    },
    wrapper: {
      margin: '5  0px 0',
      textAlign: 'center',
      color: '#EAEAEA',
    },
    noOutline: {
      outline: 'none',
    },
  }),
  { name: 'Authy' },
);

export default useStyles;
