import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0',
    margin: '0',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  item: {
    paddingLeft: '63px',
    paddingRight: '40px',
  },
  bankInfo: {
    marginLeft: '16px',
    fontWeight: 300,
    color: '#34343473',
    fontStyle: 'italic',
  },
  sticky: {
    paddingLeft: '34px',
    backgroundColor: '#F8F8F8',
    '& h6': {
      color: '#A0A0A0',
    },
  },
  imageHolder: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '50%',
    marginRight: '8px',
    width: '20px',
    height: '20px',
    border: '2px solid rgb(224,218,218)',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    padding: '5px',
    '& img': {
      width: '22px',
      height: '30px',
      position: 'absolute',
      display: 'block',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
}), { name: 'TransactionDropDownItem' });
export default useStyles;
