import { CurrencyAccountSearchDto } from '@alpha/currency-accounts-dtos';
import { datadogRum } from '@datadog/browser-rum';
import { useState } from 'react';
import { TCurrencyTab } from '../../../models/currencyAccounts';

const useBody = (selectedTabIndex: number, tabs: TCurrencyTab[]) => {
  const [drawer, setDrawer] = useState<'IAT' | 'AccountDrawer' | 'PendingTransfers' | false>(false);
  const [selectedAccount, setSelectedAccount] = useState<{
    tabs: TCurrencyTab,
    account: CurrencyAccountSearchDto
  }>();

  const handleOpenDrawer = (_drawer: 'IAT' | 'AccountDrawer' | 'PendingTransfers'| false) => {
    switch (_drawer) {
      case 'IAT':
        datadogRum.addAction('IAT CLICKED');
        setDrawer('IAT');
        return;
      case 'AccountDrawer':
        setDrawer('AccountDrawer');
        return;
      case 'PendingTransfers':
        setDrawer('PendingTransfers');
        return;
      case false:
      default:
        setDrawer(false);
    }
  };

  return {
    drawer,
    selectedAccount,
    handleAccountTableRowClick: (account: CurrencyAccountSearchDto) => {
      setSelectedAccount({ tabs: tabs[selectedTabIndex], account });
    },
    handleOpenDrawer,
  };
};

export default useBody;
