import { useContext } from 'react';
import { SinglePaymentContext } from '../../../PaymentContext';

const useSinglePaymentContext = () => {
  const singlePaymentContext = useContext(SinglePaymentContext);
  return {
    singlePaymentContext,
  };
};
export default useSinglePaymentContext;
