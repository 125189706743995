import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    input: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .Mui-disabled:before': {
        border: 'none',
      },
      '& > label': {
        display: 'flex',
        alignItems: 'center',
        '& > span': {
          fontSize: '14px',
          marginRight: '5px',
        },
        '& > div': {
          marginRight: '5px',
          '& > div': {
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
      '& > div > input::-webkit-inner-spin-button': {
        display: 'none',
      },
      '& svg.lock-icon': {
        height: '19px',
        width: '19px',
        backgroundColor: 'white',
        border: '1px solid #C1C1C1',
        borderRadius: '50%',
        color: '#101010',
        fontSize: '10px',
        padding: '0px 4px',
      },
      '& .MuiInput-root': {
        marginTop: '7px',
      },
      '& .MuiInputBase-input': {
        fontSize: '40px',
        fontWeight: 300,
        maxWidth: '250px',
        width: '100%',
        padding: 0,
      },
    },
    credit: {
      '& .Mui-disabled': {
        textFillColor: '#098375',
      },
    },
  }),
  { name: 'FundingInput' },
);

export default useStyles;
