import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
    },
    tableCode: {
      display: 'inline-block',
      lineHeight: '15px',
    },
    tableIcon: {
      margin: '0 8px 0 0',
    },
  }),
  { name: 'CurrencyPair' },
);
