import React, { memo } from 'react';
import { Button } from '@mui/material';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import useStyles from './DrawerHeader.styles';
import Breadcrumbs from '../../../../components/Breadcrumbs';

interface IProps {
  displayHeaderTitle: () => JSX.Element | string;
  handleDownload: () => void;
  downloadLink: string | undefined;
  totalInvalidPayments: number;
}

const DrawerHeader = (props: IProps) => {
  const classes = useStyles();
  const {
    displayHeaderTitle,
    handleDownload,
    totalInvalidPayments,
    downloadLink,
  } = props;
  return (
    <div className={classes.header}>
      <Breadcrumbs items={[{ text: 'file upload' }]} />
      <div className={classes.headerContent}>
        <span>{displayHeaderTitle()}</span>
        {
          totalInvalidPayments > 0
          && (
            <Tooltip title="Download error report" placement="bottom-end">
              <Button
                href={downloadLink || ''}
                download={Boolean(downloadLink)}
                onClick={!downloadLink ? () => handleDownload() : undefined}
                className={classes.downloadButton}
              >
                <FontAwesomeIcon className={classes.icon} size="lg" icon={faDownload} />
              </Button>
            </Tooltip>
          )
        }
      </div>
    </div>
  );
};

export default memo(DrawerHeader, (prevProps, nextProps) => (
  prevProps.downloadLink === nextProps.downloadLink
  && prevProps.totalInvalidPayments === nextProps.totalInvalidPayments
));
