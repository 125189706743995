import { faCommentAltDots, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import AuthBackupMethodCard from '../../AuthBackupMethodCard';
import { AuthyPageStates } from '../../../store/authy/reducer';

import _default, { makeStyles } from 'theme';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const {
  palette: { primary },
} = _default;

interface IProps {
  handleCardClick: (card: 'authy' | 'sms' | 'call') => void;
}

const useStyles = makeStyles(
  () => ({
    container: {
      '& > button': {
        marginBottom: '24px',
      },
    },
  }),
  { name: 'mfaOptionsCard' },
);

const MFAOptionsCard: React.FC<IProps> = ({ handleCardClick }: IProps) => {
  const styles = useStyles();
  const [disabled, setDisabled] = useState(false);

  const setAuthMethod = (method: 'authy' | 'sms' | 'call') => {
    setDisabled(true);
    handleCardClick(method);
  };
  return (
    <div className={styles.container}>
      <AuthBackupMethodCard
        icon={{
          backgroundColor: '#EC1C24',
          element: <img src="/static/images/authyIcon.png" alt="Authy icon" />,
        }}
        content={{
          title: 'Authy',
          body: 'Download the Authy app for fast, secure, offline authentication.',
        }}
        disabled={disabled}
        loading={disabled}
        handleOnClick={() => setAuthMethod(AuthyPageStates.AUTHY)}
        displaySecureBadge
      />
      <AuthBackupMethodCard
        icon={{
          backgroundColor: primary?.main as string,
          element: <FontAwesomeIcon icon={faCommentAltDots as IconDefinition} />,
        }}
        content={{
          title: 'Send SMS',
          body: 'Receive an SMS to your registered device. Available Network Required.',
        }}
        disabled={disabled}
        loading={disabled}
        handleOnClick={() => setAuthMethod(AuthyPageStates.SMS)}
      />
      <AuthBackupMethodCard
        icon={{
          backgroundColor: primary?.main as string,
          element: <FontAwesomeIcon icon={faPhone as IconDefinition} />,
        }}
        content={{
          title: 'Call',
          body: 'Receive a phone call to your registered device. Available Network Required.',
        }}
        disabled={disabled}
        loading={disabled}
        handleOnClick={() => setAuthMethod(AuthyPageStates.CALL)}
      />
    </div>
  );
};

export default MFAOptionsCard;
