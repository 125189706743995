import React from 'react';
import { InvoiceDto } from '@alpha/invoice-dtos';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../utils/formatIsoDate';
import Table from '../../../../components/Table/Table';
import ActionDropDown from '../ActionDropDown/ActionDropDown';
import InvoiceStatus from '../InvoiceStatus/InvoiceStatus';
import { formatNumber } from '../../../../utils/currency.helpers';
import { TextEllipsis } from '../../../../components/TextEllipsis';
import useStyles from '../Body.styles';
import clipboardCheck from '../../../../assets/clipboardCheck.svg';
import BackdropLoader from '../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';

interface IInvoiceTable {
  invoiceTableData: InvoiceDto[];
  multiEntity?: boolean;
  initialPageLoad?: boolean;
}

const generateTableData = (tableData: InvoiceDto[]) => (
  tableData as InvoiceDto[]
)?.sort((a, b) => new Date(b.inputDate).getTime() - new Date(a.inputDate).getTime())
  .map((invoice: InvoiceDto) => ({
    ...invoice,
    accountName: (
      <div style={{ textTransform: 'capitalize' }}>
        <TextEllipsis text={invoice.accountName || '-'} />
      </div>
    ),
    type: invoice.type || '-',
    inputDate: formatIsoDate(invoice.inputDate) || '-',
    valueDate: formatIsoDate(invoice.valueDate) || '-',
    debitAccountName: <div style={{ textTransform: 'capitalize', color: 'rgb(9, 131, 117)' }}>{invoice.debitAccountName || '-'}</div>,
    status: (invoice.status && (
    <InvoiceStatus
      status={invoice.status}
    />
    )) || `${invoice.status}`,
    amount: `${formatNumber(invoice.amount, 2)} ${invoice.currencyCode || ''}`,
    action: (
      <ActionDropDown
        invoice={invoice}
      />
    ),
  }));

const InvoiceTable: React.FC<IInvoiceTable> = (props: IInvoiceTable) => {
  const {
    invoiceTableData,
    multiEntity,
    initialPageLoad,
  } = props;

  const styles = useStyles();

  const columns: ITableColumn[] = [
    { header: 'Entity', accessor: 'accountName', className: clsx(styles.entity, 'dd-privacy-allow') },
    { header: 'Type', accessor: 'type', className: 'dd-privacy-allow' },
    { header: 'Created Date', accessor: 'inputDate', className: 'dd-privacy-allow' },
    { header: 'Due Date', accessor: 'valueDate', className: 'dd-privacy-allow' },
    { header: 'Assigned Debit Account', accessor: 'debitAccountName' },
    { header: 'Amount', accessor: 'amount', className: 'dd-privacy-allow' },
    { header: 'Status', accessor: 'status', className: 'dd-privacy-allow' },
    { header: 'Action', accessor: 'action', className: 'dd-privacy-allow' },
  ];

  if (multiEntity && initialPageLoad) {
    return (<BackdropLoader testId="loading" positionFixed />);
  }

  if (multiEntity && invoiceTableData.length === 0) {
    return (
      <Box className={styles.parent} textAlign="center">
        <Box className={styles.multiLine}>
          <Box className={styles.image}>
            <img src={clipboardCheck} alt="empty-table" />
          </Box>
          <p>No Invoices</p>
          <p>
            {`There are no invoices to display, 
          this may be because you do not have the relevant permissions`}
          </p>
        </Box>
      </Box>
    );
  }

  return (
    <Table
      columns={columns}
      data={generateTableData(invoiceTableData)}
    />
  );
};

export default InvoiceTable;
