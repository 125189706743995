import React from 'react';
import jwtDecode from 'jwt-decode';
import axios, { AxiosError } from 'axios';
import environmentVars from 'env.variables';
import { authedReqOpts } from '../../api';

export const getCurrentUser = async (token: string) => {
  localStorage.setItem('formioToken', token);

  const {
    user: { _id: userId },
  } = jwtDecode<{ user: { _id: string } }>(token);

  // nb FORMIO_FORM_URL is a stage URL, not a form.
  const authUrl = new URL(environmentVars.FORMIO_FORM_URL);
  authUrl.pathname += `user/submission/${userId}`;
  return axios.get(authUrl.toString(), authedReqOpts());
};

const MAGIC_LINK_LOGIN = `${environmentVars.FORMIO_FORM_URL}form/${environmentVars.FORMIO_MAGIC_LINK_LOGIN_ID}/submission`;
const MAGIC_LINK_REGISTER = `${environmentVars.FORMIO_FORM_URL}form/${environmentVars.FORMIO_MAGIC_LINK_REGISTER_ID}/submission`;
export const handleSubmit = async (
  email: string,
  setLoginState: React.Dispatch<React.SetStateAction<string>>,
) => {
  try {
    const payload = JSON.stringify({
      data: {
        email,
        submit: true,
      },
      submitted: true,
    });

    // register if not exists, else login
    try {
      await axios.post(MAGIC_LINK_REGISTER, payload, { headers: { 'Content-Type': 'application/json' } });
    } catch (e) {
      if (
        e instanceof AxiosError
        && e.response?.data.details[0].context.validator === 'unique'
        && e.response?.data.details[0].context.key === 'email'
      ) {
        await axios.post(MAGIC_LINK_LOGIN, payload, { headers: { 'Content-Type': 'application/json' } });
      }
    }
    setLoginState('success');
  } catch (e) {
    setLoginState('error');
  }
};
