import { IATDraftResponse } from '@alpha/iat-dtos';
import {
  Dispatch,
  SetStateAction, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import useAlphaSnackbar from '../../hooks/useAlphaSnackbar';
import { TCurrencyAccounts } from '../../models/currencyAccounts';
import { TTransactionData } from '../../models/transactions';
import InterAccountTransferService from '../../services/InterAccountTransfer/interAccountTransfer.service';
import TransactionsService from '../../services/Transactions/transactions.service';
import { actions } from '../../store/outstandingTables/outstandingTables.reducer';
import { ISelectedAccount } from '../CurrencyAccounts/AccountInfoDrawer/AccountInfoDrawer';
import { initialValues } from './FormValues';
import useInterAccountTransferForm from './useInterAccountTransferForm';

export const useInterAccountTransfer = (
  handleCloseIATDrawer: () => void,
  setSelectedDraftIAT: Dispatch<SetStateAction<IATDraftResponse | undefined>>,
  open: boolean,
  caDrawer: 'IAT' | 'AccountDrawer' | 'PendingTransfers' | false,
  selectedAccount?: ISelectedAccount,
  selectedDraftIAT?: IATDraftResponse,
  accountId?: string,
) => {
  const [currencyAccounts, setCurrencyAccounts] = useState<TCurrencyAccounts>([]);
  const [transactionData, setTransactionData] = useState<TTransactionData>();
  const snackbar = useAlphaSnackbar();

  const dispatch = useDispatch();

  const iatForm = useInterAccountTransferForm(
    setSelectedDraftIAT,
    handleOpenTransactionDataDrawer,
    handleCloseIATDrawer,
    selectedDraftIAT
      ? InterAccountTransferService.mapiatDraftResponseToTInterAccountTransferForm(selectedDraftIAT)
      : initialValues,
    selectedDraftIAT,
    accountId,
  );

  useEffect(() => {
    if (!selectedDraftIAT) {
      getCurrencyAccounts();
    }
  }, [selectedDraftIAT]);

  useEffect(() => {
    if (selectedAccount) {
      iatForm.form.setFieldValue('debitingAccount.currencyAccount', selectedAccount.account);
      getCurrencyAccounts();
    }
  }, [selectedAccount?.account.id, caDrawer]);

  const getCurrencyAccounts = async (): Promise<void> => {
    try {
      const currencyResponse = accountId
        ? await TransactionsService.getTransactionAccounts(accountId)
        : await TransactionsService.getTransactionAccounts();
      setCurrencyAccounts(currencyResponse.currencyAccounts);
    } catch (e) {
      snackbar.trigger('There was an error loading your currency accounts');
    }
  };

  const handleResetFormStateAndCloseIATDrawer = () => {
    setSelectedDraftIAT(undefined);
    iatForm.setDrawerState('DebitAndCredit');
    iatForm.form.resetForm();
    handleCloseIATDrawer();
    dispatch(actions.updateDashboardAction(`IAT_DRAWER_CLOSE ${selectedAccount?.account.id}`));
  };

  function handleOpenTransactionDataDrawer(): void {
    setTransactionData(TransactionsService.createTransactionData(
      iatForm.form.values.debitingAccount,
      iatForm.form.values.creditingAccount,
      iatForm.form.values.liveRate?.valueDate,
      iatForm.form.values.liveRate?.rate,
      iatForm.form.values.tradeId,
      iatForm.form.values.tradeFriendlyId,
    ));
  }

  return {
    currencyAccounts,
    transactionData,
    setTransactionData,
    handleResetFormStateAndCloseIATDrawer,
    handleOpenTransactionDataDrawer,
    ...iatForm,
  };
};

export default useInterAccountTransfer;
