import { Stat } from 'components/Stat';
import { Typography } from '@mui/material';
import React from 'react';

interface IProps {
  paymentTotal: number;
}

const PaymentTotalStat: React.FC<IProps> = ({ paymentTotal }: IProps) => (
  <div className="paymentStat">
    <Typography variant="h5" fontWeight="bold">Your Batch</Typography>
    <Stat title="No of payments" value={<b>{paymentTotal.toString()}</b>} />
  </div>
);

export default PaymentTotalStat;
