import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  section: {
    '& > ol': {
      fontSize: '12px',
      marginBottom: '10px',
    },
    '& h3': {
      color: '#098375',
      fontWeight: '600',
      fontSize: 12,
      margin: '0 0 4px 0',
      letterSpacing: '1.8px',
      textTransform: 'uppercase',
    },
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h2': {
      fontSize: 22,
      fontWeight: 300,
      color: '#222222',
      margin: '0',
    },
    '& svg': {
      marginLeft: 22,
      color: '#797979',
      width: 28,
      height: 22,
      top: -1,
      position: 'relative',
    },
    '& .infoPage': {
      '& span': {
        fontSize: 13,
        color: '#535353',
        fontWeight: 300,
        position: 'relative',
      },
    },
  },
  headerImage: {
    marginRight: 20,
    width: 47,
    height: 47,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '100%',
    border: '1px solid #eee',
  },
  status: {
    marginTop: 20,
  },
  subtitle: {
    '& > h4': {
      margin: '3px 0 0 0',
      fontSize: 11,
      color: 'rgba(52, 52, 52, 0.5)',
      fontWeight: 600,
      letterSpacing: '1.65px',
      textTransform: 'uppercase',
    },
  },
  stats: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      marginRight: 5,
      '& > div:not(:first-child)': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& > div > p:nth-of-type(2)': {
        fontSize: '22px',
      },
      '& > div:not(:last-child)': {
        marginRight: 34,
      },
    },

  },
  statusAndButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttons: {
    marginLeft: 20,
    flexGrow: 1,
    alignSelf: 'flex-end',
    display: 'flex',
    height: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      alignSelf: 'flex-end',
      margin: 0,
      marginRight: 10,
    },
  },
  statsStatus: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '0px !important',
    '& > div:nth-of-type(1) > p': {
      textAlign: 'left',
    },
  },
}),
{ name: 'Drawer' });

export default useStyles;
