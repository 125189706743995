import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    drawer: {
      position: 'relative',
      minHeight: '100vh',
      '& .MuiDrawer-paper': {
        '& > button:first-of-type': {
          zIndex: 1,
        },
      },
    },
    topSection: {
      backgroundColor: 'white',
    },
    bottomSection: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    child: {
      padding: 48,
      position: 'relative',
    },
    footer: {
      marginTop: 'auto',
      padding: '0px 48px 40px 48px',
    },
  }),
  { name: 'InterAccountTransferDrawer' },
);

export default useStyles;
