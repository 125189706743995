import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import { datadogRum } from '@datadog/browser-rum';
import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useSearch, { TSearchParams } from '../../../hooks/useSearch';
import BeneficiariesService from '../../../services/Beneficiaries/beneficiaries.service';
import { TStore } from '../../../store';
import { TAuthyState } from '../../../store/authy/reducer';
import { ModalType } from './Body';

export enum BeneStatusIndex {
  approve,
  pending,
  rejected
}

const createSearchParams = (
  tabIndex: 0 | 1 | 2,
  skip: number,
): TSearchParams => {
  const searchParams: TSearchParams = {
    baseUrl: '/bene/account-bene/search',
    queryParams: {
      skip,
      take: 10,
      sortby: 'name',
      sortorder: 'asc',
      status: 'Approved',
    },
  };

  if (tabIndex === 1) {
    searchParams.queryParams.status = 'Pending';
  } else if (tabIndex === 2) {
    searchParams.queryParams.status = 'Rejected';
  }

  return searchParams;
};

const useBody = (tabIndex: 0 | 1 | 2, multiEntity: boolean) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<ModalType | false>(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<BeneficiaryDto>();

  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);

  const location = useLocation();
  const sb = useAlphaSnackbar();
  const tableSearch = useSearch();

  const searchParams = createSearchParams(
    tabIndex,
    tableSearch.skip,
  );

  const getBeneficiary = (
    beneficiary: BeneficiaryDto,
  ): BeneficiaryDto | undefined => (tableSearch.items?.items.records as BeneficiaryDto[])
    .find(
      (record) => record.accountBeneId === beneficiary.accountBeneId,
    );

  const handleTableRowClick = (
    beneficiary: BeneficiaryDto,
  ) => {
    try {
      const newSelectedBene = getBeneficiary(beneficiary);
      if (!newSelectedBene) {
        throw new Error('Unable to retrieve selected beneficiary details');
      }
      setSelectedBeneficiary(
        newSelectedBene,
      );
    } catch (e) {
      sb.trigger(e.message);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleNewSearch = async () => {
    await tableSearch.handleNewSearch(searchParams, multiEntity);
  };

  const handleOnRejectionClick = async (Beneficiary?: BeneficiaryDto) => {
    setLoading(true);

    const benecifiaryDto = Beneficiary?.accountBeneId
      ? Beneficiary
      : selectedBeneficiary;

    try {
      if (typeof benecifiaryDto !== 'undefined') {
        if (multiEntity && benecifiaryDto.accountId) {
          await BeneficiariesService.rejectBeneficiary(
            benecifiaryDto,
            benecifiaryDto.accountId,
          );
        } else {
          await BeneficiariesService.rejectBeneficiary(
            benecifiaryDto,
          );
        }
      }
      await tableSearch.handleInitialSearch(searchParams);
      setModalOpen(false);
      setSelectedBeneficiary(undefined);
      datadogRum.addAction('BENEFICIARY REJECTED');
      sb.trigger('Beneficiary successfully rejected', 'success');
      handleNewSearch();
    } catch (e) {
      sb.trigger(e.message || 'Something went wrong rejecting your beneficiary');
    } finally {
      setLoading(false);
    }
  };

  const handleOnRejectionClickFromDashboard = async (Beneficiary?: BeneficiaryDto) => {
    setLoading(true);

    const benecifiaryDto = Beneficiary?.accountBeneId
      ? Beneficiary
      : selectedBeneficiary;

    try {
      if (typeof benecifiaryDto !== 'undefined') {
        await BeneficiariesService.rejectBeneficiary(
          benecifiaryDto,
          benecifiaryDto.accountId,
        );
      }
      setModalOpen(false);
      setSelectedBeneficiary(undefined);
      setTimeout(async () => {
        await tableSearch.handleInitialSearch(searchParams, true);
      }, 4000);
      sb.trigger('Beneficiary successfully rejected', 'success');
    } catch (e) {
      sb.trigger(e.message || 'Something went wrong rejecting your beneficiary');
    } finally {
      setLoading(false);
    }
  };

  const handleOnDeleteClick = async () => {
    setLoading(true);
    try {
      if (!selectedBeneficiary) throw new Error('No beneficiary was selected');

      await BeneficiariesService.deleteBeneficiary(
        selectedBeneficiary,
        selectedBeneficiary?.accountId,
      );

      if (tableSearch.items) {
        const clonedTableItems = [
          ...tableSearch.items.items.records,
        ];
        const filteredBeneficiaries = (clonedTableItems as BeneficiaryDto[]).filter(
          (records) => records.id !== selectedBeneficiary.accountBeneId,
        );
        const newTableSearch = { ...tableSearch.items };
        newTableSearch.items.records = filteredBeneficiaries;
        tableSearch.setItems(newTableSearch);
      }

      setModalOpen(false);
      setSelectedBeneficiary(undefined);
      sb.trigger('Beneficiary successfully deleted', 'success');
    } catch (e) {
      sb.trigger(e.message || 'Something went wrong deleting your beneficiary');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    tableSearch.setSearchText(e.target.value);
    const { ...newSearchParams } = searchParams;
    if (e.target.value) {
      newSearchParams.queryParams.searchtext = e.target.value;
    }
    if (multiEntity) {
      await tableSearch.handleNewSearch(newSearchParams, true);
    } else {
      await tableSearch.handleNewSearch(newSearchParams);
    }
  };

  return {
    loading,
    modalOpen,
    authyState,
    location,
    searchParams,
    tableSearch,
    selectedBeneficiary,
    setSelectedBeneficiary,
    setModalOpen,
    handleOnDeleteClick,
    handleTableRowClick,
    handleOnRejectionClick,
    handleCloseModal,
    handleInputChange,
    handleNewSearch,
    handleOnRejectionClickFromDashboard,
    setLoading,
  };
};

export default useBody;
