import React, {
  useCallback, useRef, useState,
} from 'react';
import AcknowledgeModal from '../../../../components/Modals/AcknowledgeModal';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import UserManagementService from '../../../../services/UserManagement/userManagement.service';

const UserViewingAcknowledgeModal = () => {
  const sb = useAlphaSnackbar();
  const sbRef = useRef(sb);

  const [isLoading, setIsLoading] = useState(false);
  const [acknowledgeModalOpen, setAcknowledgeModalOpen] = useState(
    () => localStorage.getItem('acknowledgedUserManagementModal') === null,
  );

  const handleClose = useCallback(async () => {
    try {
      setIsLoading(true);
      await UserManagementService.acknowledgeModal();
      localStorage.setItem('acknowledgedUserManagementModal', 'true');
      setAcknowledgeModalOpen(false);
      setIsLoading(false);
    } catch (e) {
      sbRef.current.trigger('Something went wrong when sending the request');
      setIsLoading(false);
    }
  }, [setAcknowledgeModalOpen]);

  const modalContent = (
    <span>
      <p>Dear user,</p>
      {' \n '}
      <p>
        Welcome to our new Entity Management page.
        Here you will be able to view users that share the same entities as you,
        as well as their permissions.
      </p>
      {' \n '}
      <p>
        If anything isn&apos;t as you&apos;d expect it,
        please contact us, and we&apos;ll be happy to help.
      </p>
    </span>
  );

  return (
    <>
      {
        acknowledgeModalOpen
        && (
          <AcknowledgeModal
            open={acknowledgeModalOpen}
            title="Entity Management"
            content={modalContent}
            actionButtonText="Acknowledge"
            handleActionButton={handleClose}
            loading={isLoading}
            bodyStyle={{ minHeight: 'auto' }}
          />
        )
      }
    </>
  );
};
export default UserViewingAcknowledgeModal;
