import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import useAuthorization from '../../hooks/useAuthorization';
import { UserRole } from '../../models/user';
import routes from '../../routes.path';
import { BatchSummary } from './BatchSummary';
import Dashboard from './Dashboard/Dashboard';
import Funding from './Funding/Funding';
import ManualPayments from './ManualPayments/ManualPayments';

const Payments: React.FC = () => {
  const fxAuthorized = useAuthorization([[UserRole.FX_IAT]]);

  return (
    <Switch>
      <Route
        exact
        path={
          [
            routes.payments.base,
            routes.payments.pending,
            routes.payments.inProgress,
            routes.payments.complete,
            routes.payments.draft,
            routes.payments.funded,
          ]
        }
      >
        <Dashboard />
      </Route>
      <Route
        exact
        path={routes.payments.batchSummary}
        component={BatchSummary}
      />
      <Route path={[routes.payments.manual.base]}>
        <ManualPayments />
      </Route>
      { fxAuthorized.authorized
        && <Route exact path={routes.payments.funding} component={Funding} />}
      <Redirect to={routes.payments.base} />
    </Switch>
  );
};

export default Payments;
