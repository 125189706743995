import React, { FC } from 'react';
import Alert from 'components/Alert/Alert';
import { Flags } from 'react-feature-flags';
import {
  AlertSubtitleStyled,
  AlertTextStyled,
  AlertLearnMoreButtonStyled,
  AlertLearnMoreStyled,
} from './ProfileStatusModal.styles';

type IProps = {
  setProfileModalOpen: (value: boolean) => void;
  style?: any;
}

const ProfileStatusAlert: FC<IProps> = ({ style, setProfileModalOpen }) => {
  const incompleteBannerContent = (
    <AlertTextStyled>
      <AlertSubtitleStyled>
        One or more of your entities' profiles are incomplete.
        Payments may take longer than usual from these entities. Please contact Client Services.
      </AlertSubtitleStyled>
      <AlertLearnMoreButtonStyled
        type="button"
        data-testid="learn-more-button"
        onClick={() => setProfileModalOpen(true)}
      >
        <AlertLearnMoreStyled>
          Learn more
        </AlertLearnMoreStyled>
      </AlertLearnMoreButtonStyled>
    </AlertTextStyled>
  );

  return (
    <Flags authorizedFlags={['incompleteProfile']}>
      <Alert data-dd-privacy="allow" variant="incomplete" text={incompleteBannerContent} style={style} cancel />
    </Flags>
  );
};

export default ProfileStatusAlert;
