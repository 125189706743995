import _default, { makeStyles } from 'theme';

const { palette } = _default;

const useStyles = makeStyles(
  () => ({
    header: {
      '& > .left': {
        minWidth: '400px',
      },
      '& .stageText': {
        color: 'white',
        marginBottom: '16px',
        fontWeight: 100,
        '& .name': {
          color: palette.primary.main,
          fontWeight: 600,
        },
      },
    },
    linearProgress: {
      width: '100%',
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
    leftContent: {
      width: '70% !important',
    },
    rightContent: {
      width: '30% !important',
    },
  }),
  { name: 'OnboardingStyles' },
);

export default useStyles;
