import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    footer: {
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 40,
    },
  }), { name: 'ErrorPaymentsFooter' },
);

export default useStyles;
