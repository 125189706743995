import React from 'react';

import { ActionButton } from 'components/Buttons';
import { Button } from '@mui/material';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flags } from 'react-feature-flags';
import BeneFraudAcknowledgeModal from 'domain/Beneficiaries/BeneFraudAcknowledgeModal';
import FooterWrapper from '../../../../components/Payments/FooterWrapper/FooterWrapper';
import BatchSubmittedFooter from './BatchSubmittedFooter/BatchSubmittedFooter';

interface IProps {
  buttonText: string;
  handleExitClick: ()=> void;
  handleOnClick: ()=> void;
  inputter: boolean;
  disabled?: boolean;
  handleModalConfirmation?: ()=> void;
  acknowledgeModalOpen?: boolean;
  setAcknowledgeModalOpen: (acknowledgeModalOpen: boolean)=> void;
}

const BatchSubmissionFooter: React.FC<IProps> = (props: IProps) => {
  const {
    buttonText,
    handleExitClick,
    handleOnClick,
    disabled,
    inputter,
    handleModalConfirmation,
    acknowledgeModalOpen,
    setAcknowledgeModalOpen,
  } = props;

  if (inputter) {
    return (
      <FooterWrapper>
        <Button
          onClick={handleExitClick}
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          Exit
        </Button>
        <ActionButton style={{ width: 'max-content' }} disabled={disabled} testId="submitPaymentButton" size="large" onClick={handleOnClick}>{buttonText}</ActionButton>
        {
        acknowledgeModalOpen
        && (
          <Flags authorizedFlags={['fraudModalEnabled']}>
            <BeneFraudAcknowledgeModal
              handleConfirmation={handleModalConfirmation}
              handleClose={() => setAcknowledgeModalOpen(false)}
              acknowledgeModalOpen={acknowledgeModalOpen}
            />
          </Flags>
        )
      }
      </FooterWrapper>
    );
  }
  return (
    <BatchSubmittedFooter />
  );
};

export default BatchSubmissionFooter;
