import { LedgerDto } from '@alpha/currency-accounts-dtos';
import moment, { Moment as MomentType } from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useAlphaSnackbar from '../../hooks/useAlphaSnackbar';
import { TTransactionData } from '../../models/transactions';
import FXTransferService from '../../services/FXTransfer/fxTransfer.service';
import history from '../../services/history/browserHistory';
import InterAccountTransferService from '../../services/InterAccountTransfer/interAccountTransfer.service';
import PaymentsService from '../../services/Payments/payments.service';
import TransactionsService from '../../services/Transactions/transactions.service';

const useTransactions = () => {
  const { currencyAccountId }: { currencyAccountId: string } = useParams();

  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState<TTransactionData>();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<{
    startDate: MomentType;
    endDate: MomentType;
  }>({
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
  });

  const snackbar = useAlphaSnackbar();

  const handleSetTransaction = async (clickedTableRowTransaction: LedgerDto): Promise<void> => {
    try {
      setLoading(true);
      if (clickedTableRowTransaction.iatId) {
        setTransaction(
          await InterAccountTransferService.getTransactionData(
            clickedTableRowTransaction.iatId,
          ),
        );
      } else if (clickedTableRowTransaction.clientFxTradeId) {
        setTransaction(
          await FXTransferService.getFxData(
            clickedTableRowTransaction.clientFxTradeId,
            clickedTableRowTransaction.friendlyId,
          ),
        );
      } else if (clickedTableRowTransaction.paymentId) {
        setTransaction(
          await PaymentsService.getPaymentData(clickedTableRowTransaction.paymentId),
        );
      }
    } catch (e) {
      snackbar.trigger('There was an error retrieving your transaction data');
      setTransaction(undefined);
    } finally {
      setLoading(false);
    }
  };

  const handlePageLoad = async (): Promise<void> => {
    setTableLoading(true);
    if (currencyAccountId) {
      setTimeout(() => setTableLoading(false), 2000);
      return;
    }
    try {
      const { currencyAccounts } = await TransactionsService.getTransactionAccounts();

      if (currencyAccounts.length) {
        history.push(`/app/transactions/${currencyAccounts[0].id}`);
      }
    } catch (e) {
      snackbar.trigger(e.message || e.response?.data?.error || 'Error retrieving currency accounts');
    } finally {
      setTimeout(() => setTableLoading(false), 2000);
    }
  };

  return {
    loading,
    tableLoading,
    transaction,
    selectedDates,
    currencyAccountId,
    setSelectedDates,
    setTableLoading,
    setTransaction,
    handlePageLoad,
    handleSetTransaction,
  };
};
export default useTransactions;
