import { styled } from '@mui/material';
import theme from 'theme';

const { palette, typography } = theme;

export const StyledHeader = styled('p')({
  color: palette.primary.main,
  fontWeight: typography.fontWeightSemiBold,
  textTransform: 'uppercase',
  letterSpacing: 1.65,
  lineHeight: '15px',
  margin: '0 0 10px 0',
  fontSize: 11,
});
