import { TabContent } from 'components/Tabs';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import KYCBannerComponent from 'components/KYCBannerComponent';
import { iAllowedAccounts, iSelectedAccounts } from 'store/accounts/accounts.reducer';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { TStandingOrder } from 'models/payments';
import { PaymentFeatureFlags } from 'domain/PaymentsMultiEntity/ManualPayments/constants';
import Alert from '../../../../components/Alert/Alert';
import FullPageLoader from '../../../../components/FullPageLoader/FullPageLoader';
import Search from '../../../../components/Organisms/Search';
import ElasticTableContextContainer from '../../../../context/ElasticTableContext';
import { TStore } from '../../../../store';
import usePaymentBatchSearch from '../../usePaymentBatchSearch';
import CompleteTableContainer from '../CompleteTableContainer/CompleteTableContainer';
import DraftedTableContainer from '../DraftedTableContainer/DraftedTableContainer';
import FundedTable from '../FundedTable/FundedTable';
import InProgressTable from '../InProgressTable/InProgressTable';
import PendingTable from '../PendingTable/PendingTable';
import useStyles from './SearchPaymentsTable.styles';
import ActionRequiredTable from '../ActionRequiredTable/ActionRequiredTable';
import ScheduledTableContainer from '../ScheduledTableContainer/ScheduledTableContainer';
import { scheduledBaseUrl } from '../ScheduledTableContainer/constants';

interface IProps {
  tabIndex: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  fundedPaymentsExist: boolean;
}

const SearchPaymentsTable: React.FC<IProps> = (props: IProps) => {
  const { flags } = useGetFeatureFlags();
  const standingOrderFFEnabled = flags?.find((flag) => (
    flag.name === PaymentFeatureFlags.STANDING_ORDER && flag.isActive
  ))?.isActive;
  const { tabIndex, fundedPaymentsExist } = props;
  const classes = useStyles();
  const {
    search, searchParams, handleInputChange, handleNewSearch,
  } = usePaymentBatchSearch(tabIndex, flags);

  const handleNextPage = () => search.handleNextPage(searchParams, 10, true);
  const handlePreviousPage = () => search.handlePreviousPage(searchParams, 10, true);

  const selectedAccounts = useSelector<TStore, iSelectedAccounts[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );

  const text = 'Please note payments may take a short time to appear in pending payments. If you believe something has gone wrong, contact us.';
  useEffect(() => {
    // Different search request for scheduled tab
    if (tabIndex === 3 && standingOrderFFEnabled) {
      search.handleInitialSearch({
        ...searchParams,
        baseUrl: scheduledBaseUrl,
      }, true);
    } else {
      search.handleInitialSearch(searchParams, true);
    }
  }, [tabIndex, selectedAccounts, allowedAccounts, standingOrderFFEnabled]);

  const totalItems = search.items?.items.total || 0;

  return (
    <ElasticTableContextContainer callback={handleNewSearch}>
      <div className={classes.alertWrapper}>
        <KYCBannerComponent />
        <Alert variant="info" text={text} cancel />
      </div>
      <div className={classes.searchBarContainer}>
        <Search
          fullWidth={false}
          testId="search-payment-batches"
          value={search.searchText}
          totalItems={totalItems}
          placeholder="Search payment batches"
          onChange={handleInputChange}
        />
      </div>
      {search.loading === true ? (
        <FullPageLoader testId="payments-table-loader" />
      ) : (
        <div>
          <TabContent index={0} value={tabIndex}>
            <PendingTable
              searchParams={searchParams}
              items={search.items?.items}
              hasNext={search.items?.hasNext || false}
              hasPrevious={search.items?.hasPrevious || false}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              loading={search.loading}
              handleNewSearch={handleNewSearch}
            />
          </TabContent>
          <TabContent index={1} value={tabIndex}>
            <InProgressTable
              items={search.items?.items}
              hasNext={search.items?.hasNext || false}
              hasPrevious={search.items?.hasPrevious || false}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              loading={search.loading}
              handleNewSearch={handleNewSearch}
            />
          </TabContent>
          <TabContent index={2} value={tabIndex}>
            <CompleteTableContainer
              items={search.items?.items}
              hasNext={search.items?.hasNext || false}
              hasPrevious={search.items?.hasPrevious || false}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              loading={search.loading}
              handleNewSearch={handleNewSearch}
            />
          </TabContent>
          {
            standingOrderFFEnabled && (
              <TabContent index={3} value={tabIndex}>
                <ScheduledTableContainer
                  searchParams={searchParams}
                  items={search.items?.items}
                  hasNext={search.items?.hasNext || false}
                  hasPrevious={search.items?.hasPrevious || false}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                  loading={search.loading}
                  handleNewSearch={handleNewSearch}
                />
              </TabContent>
            )
          }
          <TabContent index={standingOrderFFEnabled ? 4 : 3} value={tabIndex}>
            <DraftedTableContainer
              searchParams={searchParams}
              items={search.items?.items}
              hasNext={search.items?.hasNext || false}
              hasPrevious={search.items?.hasPrevious || false}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              loading={search.loading}
              handleNewSearch={handleNewSearch}
            />
          </TabContent>
          <TabContent index={standingOrderFFEnabled ? 5 : 4} value={tabIndex}>
            <ActionRequiredTable
              searchParams={searchParams}
              items={search.items?.items}
              hasNext={search.items?.hasNext || false}
              hasPrevious={search.items?.hasPrevious || false}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              loading={search.loading}
              handleNewSearch={handleNewSearch}
            />
          </TabContent>
          {
            fundedPaymentsExist && (
              <TabContent index={standingOrderFFEnabled ? 6 : 5} value={tabIndex}>
                <FundedTable
                  searchParams={searchParams}
                  items={search.items?.items}
                  hasNext={search.items?.hasNext || false}
                  hasPrevious={search.items?.hasPrevious || false}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                  loading={search.loading}
                  handleNewSearch={handleNewSearch}
                />
              </TabContent>
            )
          }
        </div>
      )}
    </ElasticTableContextContainer>
  );
};
export default SearchPaymentsTable;
