import React from 'react';
import { Stat } from 'components/Stat';
import { Flags } from 'react-feature-flags';
import { formatCurrency } from '../../../utils/currency.helpers';
import useStyles from '../CurrencyAccounts.styles';
import { TCurrencyStats } from '../../../models/currencyAccounts';

export const testIds = {
  totalAccounts: 'total-accounts',
  totalEntities: 'total-entities',
  pendingTransactions: 'pending-transactions',
  clearedBalance: 'cleared-balance',
  availableBalance: 'available-balance',
};
interface Props {
  totals: TCurrencyStats;
  currencyCode: string;
}
const CurrencyTotals: React.FC<Props> = ({ totals, currencyCode }: Props) => {
  const classes = useStyles();
  let pendTransValue = '';
  let pendTransTitle = '';
  let clearBalTitle = '';
  let clearBalTotal = '';
  let availableBalTitle = '';
  let availableBalTotal = '';
  if (currencyCode !== 'ALL') {
    pendTransValue = formatCurrency(currencyCode, totals.pendingTransactions);
    pendTransTitle = 'Pending Transactions';
    clearBalTitle = 'Cleared Balance';
    clearBalTotal = formatCurrency(currencyCode, totals.clearedBalance);
    availableBalTitle = 'Available Balance';
    availableBalTotal = formatCurrency(currencyCode, totals.availableBalance);
  }
  return (
    <>
      <Stat
        testId={testIds.totalAccounts}
        data-testid="total-accounts"
        title="Total Accounts"
        value={totals.totalAccounts.toString()}
      />
      <Flags authorizedFlags={['currencyAccountsPage']}>
        <Stat
          testId={testIds.totalEntities}
          data-testid="total-entities"
          title="Entities"
          value="1"
          style={{ marginLeft: '2em' }}
        />
      </Flags>
      <div className={classes.pullRight}>
        <Stat
          testId={testIds.pendingTransactions}
          data-testid="pending-transactions"
          title={pendTransTitle}
          value={pendTransValue}
          alignRight
        />
        <Stat
          testId={testIds.clearedBalance}
          data-testid="cleared-balance"
          title={clearBalTitle}
          value={clearBalTotal}
          alignRight
        />
        <Stat
          testId={testIds.availableBalance}
          data-testid="available-balance"
          title={availableBalTitle}
          value={(
            <strong>
              {availableBalTotal}
            </strong>
          )}
          alignRight
        />
      </div>
    </>
  );
};
export default CurrencyTotals;
