import { ActionButton } from 'components/Buttons';
import React from 'react';
import Authorization from '../../../hocs/Authorization';
import { UserRole } from '../../../models/user';

interface IProps {
  handleOpenCreateBeneficiaryDrawer: () => void;
  multiEntity?: boolean,
  accounts?: {
    value: string;
    label: string;}[];
}

const RightAlignedCta: React.FC<IProps> = ({
  handleOpenCreateBeneficiaryDrawer,
  multiEntity, accounts,
}: IProps) => {
  if (multiEntity) {
    if (accounts && accounts?.length > 0) {
      return (
        <ActionButton
          testId="create-beneficiary-btn"
          size="large"
          data-dd-privacy="allow"
          onClick={handleOpenCreateBeneficiaryDrawer}
        >
          Create Beneficiary
        </ActionButton>
      );
    }
    return <></>;
  }
  return (
    <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
      <ActionButton
        testId="create-beneficiary-btn"
        size="large"
        data-dd-privacy="allow"
        onClick={handleOpenCreateBeneficiaryDrawer}
      >
        Create Beneficiary
      </ActionButton>
    </Authorization>
  );
};

export default RightAlignedCta;
