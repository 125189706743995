import { PayloadAction } from '@reduxjs/toolkit';
import { TAuthyBeneficiaryType, TAuthyPaymentsType, TAuthyBeneficiaryBatchType, TAuthyUserManagementType } from './reducer';

export enum Actions {
  INITIATE = 'INITIATE',
  SUBMIT = 'SUBMIT',
}
export type TAuthyType = 'PAYMENTS' | 'BENEFICIARIES';

const initiateBeneficiary = (
  payload: TAuthyBeneficiaryType,
): PayloadAction<TAuthyBeneficiaryType> => ({
  type: Actions.INITIATE,
  payload,
});

const submit = (mfaCode: number): PayloadAction<number> => ({
  type: Actions.SUBMIT,
  payload: mfaCode,
});

const initiatePayment = (
  payload: TAuthyPaymentsType,
): PayloadAction<TAuthyPaymentsType> => ({
  type: Actions.INITIATE,
  payload,
});

const initiateUserRoleRequestApproval = (
  payload: TAuthyUserManagementType,
): PayloadAction<TAuthyUserManagementType> => ({
  type: Actions.INITIATE,
  payload,
});

const initiateBeneficiaryBatch = (
  payload: TAuthyBeneficiaryBatchType,
): PayloadAction<TAuthyBeneficiaryBatchType> => ({
  type: Actions.INITIATE,
  payload,
});

export {
  initiateBeneficiary,
  submit,
  initiatePayment,
  initiateBeneficiaryBatch,
  initiateUserRoleRequestApproval,
};
