import { useContext } from 'react';
import { ManualPaymentsContext } from '../../../PaymentContext';

const usePaymentsContext = () => {
  const paymentsContext = useContext(ManualPaymentsContext);
  return {
    paymentsContext,
  };
};
export default usePaymentsContext;
