import React, { useState, useEffect } from 'react';
import { CountryBankFields, BankingFieldWithValidation, ValidateBeneficiaryRequest } from '@alpha/bene-dtos';
import { camelCase } from 'lodash';
import * as yup from 'yup';
import { FormikProps } from 'formik';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import validation from '../formData';
import BeneficiariesService from '../../../../../services/Beneficiaries/beneficiaries.service';
import useSwitchAccount from '../../../../../hooks/useSwitchAccount';

const usePaymentMethods = (
  form: FormikProps<ValidateBeneficiaryRequest>,
  setFormValidation: React.Dispatch<React.SetStateAction<any>>,
) => {
  const [tab, setTab] = useState<'international' | 'local'>('international');
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<CountryBankFields>();
  const [bothBankFieldsRequired, setBothBankFieldsRequired] = useState<boolean>();
  const [requiresAddress, setRequiresAddress] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const { bankCountryCode, currencyCode, accountIds } = form.values;
  const { currentAccount } = useSwitchAccount();

  let tabTitles = generateTabTitles(availablePaymentMethods);

  useEffect(() => {
    resetExtraBankInformation();
    if (bankCountryCode && currencyCode) {
      if (accountIds) {
        const accountIdarray = accountIds.map((obj) => obj.value);
        getAvailablePaymentMethods(bankCountryCode, currencyCode, accountIdarray);
      } else {
        getAvailablePaymentMethods(bankCountryCode, currencyCode);
      }
    }
  }, [bankCountryCode, currencyCode, accountIds]);

  useEffect(() => {
    tabTitles = generateTabTitles(availablePaymentMethods);
    setTab(tabTitles[0]);
  }, [availablePaymentMethods]);

  useEffect(() => {
    if (bothBankFieldsRequired === false || bothBankFieldsRequired === undefined) {
      resetBankFieldValues();
      if (availablePaymentMethods?.international.length && tab === 'international') {
        updateFormValidation(availablePaymentMethods.international, validation);
      } else if (availablePaymentMethods?.local.length && tab === 'local') {
        updateFormValidation(availablePaymentMethods.local, validation);
      }
    }
  }, [tab, availablePaymentMethods, bothBankFieldsRequired]);

  useEffect(() => {
    if (bothBankFieldsRequired === true) {
      const requiredFieldsForValidation = availablePaymentMethods?.local.concat(availablePaymentMethods?.international);

      if (requiredFieldsForValidation !== undefined) {
        updateFormValidation(requiredFieldsForValidation, validation);
        resetBankFieldValues();
      }
    }
  }, [availablePaymentMethods, bothBankFieldsRequired]);

  useEffect(() => {
    updateFormValidationRequiresAddress(requiresAddress, validation);
  }, [requiresAddress]);

  const handleDynamicValidationTabChange = (newTabIndex: Readonly<number>) => {
    if (newTabIndex === 0) {
      setTab('international');
    } else {
      setTab('local');
    }
  };

  const getAvailablePaymentMethods = async (_bankCountryCode: Readonly<string>, currencyCode: Readonly<string>, accountIds?: Readonly<string>[]) => {
    try {
      setLoading(true);
      const response = accountIds ? await BeneficiariesService.getCountryRequirements(_bankCountryCode, currencyCode, accountIds) : await BeneficiariesService.getCountryRequirements(_bankCountryCode, currencyCode, [currentAccount.id]);
      setBothBankFieldsRequired(response.bothBankFieldsRequired);
      setAvailablePaymentMethods(response.bankFields);
    } catch (e) {
      sb.trigger(e.response?.data?.error || e.message || 'There was an error retrieving the available payment methods for the selected bank country');
    } finally {
      setLoading(false);
    }
  };

  const updateFormValidation = (bankingFieldsWithValidation: BankingFieldWithValidation[],
    formValidation: Record<any, any>) => {
    const newValidation = { ...formValidation };
    for (let i = 0; i <= bankingFieldsWithValidation.length - 1; i += 1) {
      const fieldNameToCamelCase = camelCase(bankingFieldsWithValidation[i].field);
      const newSchema = yup.string().matches(
        new RegExp(bankingFieldsWithValidation[i].pattern),
        bankingFieldsWithValidation[i].errorMessage,
      ).required(`${bankingFieldsWithValidation[i].field} is a required field`);
      newValidation[fieldNameToCamelCase] = newValidation[fieldNameToCamelCase]
        ? newValidation[fieldNameToCamelCase].concat(newSchema) : newSchema;
    }

    setFormValidation(newValidation);
  };

  const updateFormValidationRequiresAddress = (
    required: boolean,
    formValidation: Record<any, any>,
  ) => {
    const newValidation = { ...formValidation };

    if (required) {
      newValidation.addressLine1 = yup
        .string()
        .matches(RegExp('^[/A-Za-z0-9 -]*$'), 'Special characters are not allowed')
        .min(3, 'Must be 3 characters or more')
        .max(100, 'Must be 100 characters or less')
        .required('Address Line 1 is required for this Bank Country');
      newValidation.countryCode = yup
        .string()
        .matches(/^[A-Za-z0-9 ]*$/, 'Special characters are not allowed')
        .max(80, 'Must be 80 characters or less')
        .required('Country is required for this Bank Country');
    }

    setFormValidation(newValidation);
  };

  function resetBankFieldValues() {
    form.setFieldValue('iban', '');
    form.setFieldValue('accountNumber', '');
    form.setFieldValue('swift', '');
  }

  function generateTabTitles(_availablePaymentMethods: CountryBankFields | undefined): Array<'international' | 'local'> {
    const newTabTitles: Array<'international' | 'local'> = [];

    if (!_availablePaymentMethods) return newTabTitles;

    if (_availablePaymentMethods.international.length) {
      newTabTitles.push('international');
    }
    if (_availablePaymentMethods.local.length) {
      newTabTitles.push('local');
    }

    return newTabTitles;
  }

  function resetExtraBankInformation() {
    form.setValues({
      ...form.values,
      correspondentAccountNumber: undefined,
      correspondentSwift: undefined,
      furtherToAccountNumber: undefined,
      furtherToSwift: undefined,
    });
  }

  return {
    loading,
    availablePaymentMethods,
    tabTitles,
    tab,
    bankCountryCode,
    currencyCode,
    handleDynamicValidationTabChange,
    requiresAddress,
    bothBankFieldsRequired,
  };
};

export default usePaymentMethods;
