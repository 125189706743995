import {
  GetAllUsersByStatusResponse,
  GetAllUsersByStatusRequest,
  GetAllUserRoleRequestsByStatusRequest,
  GetAllEntitiesRequest,
  GetAllEntitiesResponse,
  GetUserRequest,
  GetUserResponse,
  GetAllRolesResponseData,
  Response,
  AccountWithApprovalConfig,
  UserRoleRequestResponse,
  CreateRoleRequest,
  UserAccount,
} from '@portal/user-types';
import { instanceNoAccountId as instance } from '../Axios/instance';

export class UserManagementService {
  public static async getAllUsersByStatus({
    statusIds,
    page,
    items = '10',
    searchQuery,
  }: GetAllUsersByStatusRequest): Promise<GetAllUsersByStatusResponse> {
    const { data } = await instance.get(`/user/api/v1/user/all/${statusIds}`, {
      params: { page, items, ...(searchQuery && { searchQuery }) },
    });

    return data;
  }

  public static async getAllEntities({
    approvalConfig,
    page,
    items,
    filterByIsManageable,
  }: GetAllEntitiesRequest & {
    filterByIsManageable?: boolean;
  }): Promise<GetAllEntitiesResponse> {
    const { data }: { data: GetAllEntitiesResponse } = await instance.get(
      '/user/api/v1/entity/all',
      {
        params: {
          approvalConfig,
          page,
          items,
        },
      },
    );
    if (filterByIsManageable) {
      const filteredEntities = data.data.filter(
        (entity: AccountWithApprovalConfig) => entity.isManageable,
      );

      return {
        ...data,
        data: filteredEntities,
      };
    }
    return data;
  }

  public static async createUserRoleRequest({
    user,
    userRoleRequest,
  }: CreateRoleRequest): Promise<Response<any>> {
    const { data } = await instance.post('/user/api/v1/user-roles-request', {
      user,
      userRoleRequest,
    });
    return data;
  }

  public static async getEntity(
    entityId: string,
    params: any,
  ): Promise<Response<any>> {
    const { data } = await instance.get(`api/v1/entity/${entityId}`, {
      params: { ...params },
    });
    return data;
  }

  public static async getAllRoles(): Promise<
    Response<GetAllRolesResponseData>
    > {
    const { data } = await instance.get('/user/api/v1/roles/all');
    return data;
  }

  public static async rejectUserRoleRequest({
    requestId,
  }: {
    requestId: string;
  }): Promise<Response<any>> {
    const { data } = await instance.put(
      '/user/api/v1/user-roles-request/reject',
      { requestId },
    );
    return data;
  }

  public static async getAllUserRoleRequestsByStatus({
    status,
    page = 0,
    items = 10,
    searchQuery,
  }: GetAllUserRoleRequestsByStatusRequest): Promise<
    Response<UserRoleRequestResponse[]>
  > {
    const statuses = status.join('+');
    const { data } = await instance.get(
      `/user/api/v1/user-roles-request/all/${statuses}`,
      {
        params: {
          page,
          items,
          ...(searchQuery && { searchQuery }),
        },
      },
    );

    const sortedCurrentRoles = data.data.map(
      ({ currentRoles }: UserRoleRequestResponse) => currentRoles.sort(),
    );

    const sortedCRequestedRoles = data.data.map(
      ({ requestedRoles }: UserRoleRequestResponse) => requestedRoles.sort(),
    );

    return {
      ...data,
      currentRoles: sortedCurrentRoles,
      requestedRoles: sortedCRequestedRoles,
    };
  }

  public static async getUser({
    id,
    page,
    items,
    approvalConfig = 'false',
    contactDetails = 'false',
  }: GetUserRequest & {
    filterByIsManageable?: boolean;
  }): Promise<GetUserResponse> {
    const { data } = await instance.get(`/user/api/v1/user/${id}`, {
      params: {
        approvalConfig,
        contactDetails,
        page,
        items,
      },
    });

    const sortedEntities = data.entities.map((entity: UserAccount) => {
      const requirements = entity?.approvalConfig?.requirements || [];
      if (requirements) {
        requirements.sort((a, b) => a.limitAmount - b.limitAmount);
        requirements.map(
          (requirement) => requirement.approvalsRequired.sort(
            (a, b) => a.level.localeCompare(b.level),
          ),
        );
      }
      return entity;
    });

    return {
      ...data,
      entities: sortedEntities,
    };
  }

  public static async approveUserRequest({
    requestId,
    totp,
    softToken,
  }: {
    requestId: string;
    totp: number;
    softToken?: boolean;
  }): Promise<{}> {
    const { data } = await instance.put(
      '/user/api/v1/user-roles-request/approve',
      { totpCode: totp, requestId, softToken },
    );
    return data;
  }

  public static async acknowledgeModal(): Promise<any> {
    const { data } = await instance.post('/user/api/v1/user/acknowledge');

    return data;
  }

  public static async rejectUserRequestsBatch(
    requestIds: string[],
  ): Promise<{}> {
    const { data } = await instance.put(
      '/user/api/v1/user-roles-request/batch/reject',
      { requestIds },
    );
    return data;
  }

  public static async approveUserRequestsBatch({
    requestIds,
    totp,
    softToken,
  }: {
    requestIds: string[];
    totp: number;
    softToken?: boolean;
  }): Promise<{}> {
    const { data } = await instance.put(
      '/user/api/v1/user-roles-request/batch/approve',
      { totpCode: totp, requestIds, softToken },
    );
    return data;
  }
}

export default UserManagementService;
