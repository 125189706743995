import React from 'react';
import { Dialog } from '@mui/material';
import {
  Formik, Form, FormikValues, FormikHelpers, yupToFormErrors, validateYupSchema,
} from 'formik';
import useMeasure from 'react-use-measure';
import { motion } from 'framer-motion';
import useStyles from './index.styles';
import { WizardModalFooter, WizardModalHeader } from './components';
import { useWizardModal, WizardModalProvider } from './context';
import { IWizardModalConfig } from '../Modal.interfaces';

const WizardModalContent = <T extends FormikValues, >({ modalConfig, handleCloseModal }: {
  modalConfig: IWizardModalConfig<T>,
  handleCloseModal: () => void,
}) => {
  const { content } = useStyles();
  const { state, handleSubmit } = useWizardModal();
  const {
    step, nestedSteps, error, loading,
  } = state;
  const {
    title, steps, initialValues, validate,
  } = modalConfig;
  const currentNestedSteps = nestedSteps[step];
  const currentValidationSchema = steps[step].validationSchema;
  const [ref, { height }] = useMeasure();

  const validateFormik = (values: FormikValues) => {
    const additionalErrors = validate?.(values, step, nestedSteps) || {};

    if (currentValidationSchema) {
      try {
        validateYupSchema(values, currentValidationSchema, true, values);
      } catch (e) {
        return {
          ...yupToFormErrors(e),
          ...additionalErrors,
        };
      }
    }
    return additionalErrors;
  };

  return (
    <>
      <WizardModalHeader
        title={title}
        steps={steps.map((s, idx) => (currentNestedSteps && idx === step
          ? s.title(currentNestedSteps.current, currentNestedSteps.total)
          : s.title()))}
        activeStep={step}
        error={error}
        handleCloseModal={handleCloseModal}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, actions) => handleSubmit(values, actions as FormikHelpers<unknown>)}
        validate={validateFormik}
        validateOnMount
      >
        <Form id="wizard-modal">
          <motion.div animate={{ height }} transition={{ duration: 0.35 }}>
            <div ref={ref}>
              <motion.div key={step} initial={{ opacity: 0, filter: 'blur(2px)' }} animate={{ opacity: 1, filter: 'blur(0px)' }} className={content}>
                {steps[step].component}
              </motion.div>
            </div>
          </motion.div>
          <WizardModalFooter
            loading={loading}
            submitText={modalConfig.submitButtonText}
            nextText={steps[step].nextButtonText && steps[step].nextButtonText?.()}
          />
        </Form>
      </Formik>
    </>
  );
};

const WizardModal = <T extends FormikValues >({ modalConfig, open, handleCloseModal }: {
  modalConfig: IWizardModalConfig<T>,
  open: boolean,
  handleCloseModal: () => void,
}) => {
  const { dialog } = useStyles();
  return (
    <Dialog
      className={dialog}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleCloseModal();
        }
      }}
      aria-labelledby="wizard-dialog"
      open={open}
    >
      <WizardModalProvider config={modalConfig} handleCloseModal={handleCloseModal}>
        <WizardModalContent modalConfig={modalConfig} handleCloseModal={handleCloseModal} />
      </WizardModalProvider>
    </Dialog>
  );
};

export default WizardModal;
