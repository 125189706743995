import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { TText } from 'types/index.interfaces';
import { useStyles } from './index.styles';

export interface IForcedSignOutPageProps {
    onLogout?: () => void;
    errorType?: TText,
    title?: TText;
    content?: TText;
    logoutLink?: TText;
}

export const ForcedSignOutPage: FC<IForcedSignOutPageProps> = (props) => {
  const classes = useStyles();
  const {
    onLogout,
    title = 'You have been logged out.',
    content = 'This is because your account has been logged into by another device or browser. Please contact us if this was not you.',
    logoutLink = 'Back to log in',
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.holder}>
        <h3>{title}</h3>
        <p>{content}</p>
        <div>
          <Link to="/auth/login">{logoutLink}</Link>
          {onLogout && (
            <Button
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onLogout();
              }}
            >
              {logoutLink}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForcedSignOutPage;
