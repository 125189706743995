import { TabContent } from 'components/Tabs';
import React, { useLayoutEffect } from 'react';
import useStyles from './Body.styles';
import useBody from './useBody';
import BenePendingPayments from '../../Beneficiaries';
import Payments from '../../Payments';
import AwaitingFx from '../../AwaitingFx';
import BalanceTransfer from '../../BalanceTransfer';

interface IBodyProps {
  selectedTabIndex: number;
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

export type ModalType = 'reject' | 'delete';

const Body: React.FC<IBodyProps> = ({
  selectedTabIndex,
  setSelectedTabIndex,
}: IBodyProps) => {
  const styles = useStyles();
  const {
    location,
    calculateIndexFromUrl,
  } = useBody();

  useLayoutEffect(() => {
    setSelectedTabIndex(calculateIndexFromUrl());
  }, [location.search]);

  return (
    <>
      <div className={styles.tabContainer}>
        <TabContent index={0} value={selectedTabIndex} data-testid="pending-tab">
          <Payments enableSearch enablePagination searchPlaceholder="Search Payments" />
        </TabContent>
        <TabContent index={1} value={selectedTabIndex} data-testid="pending-tab">
          <BenePendingPayments enableSearch enablePagination searchPlaceholder="Search Beneficiaries" />
        </TabContent>
        <TabContent index={2} value={selectedTabIndex} data-testid="pending-tab">
          <AwaitingFx enableSearch enablePagination searchPlaceholder="Search Payments" />
        </TabContent>
        <TabContent index={3} value={selectedTabIndex} data-testid="pending-tab">
          <BalanceTransfer enableSearch enablePagination searchPlaceholder="Search Transfers" />
        </TabContent>
      </div>
    </>
  );
};

export default Body;
