import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    textAlignLeft: {
      margin: 'auto',
      maxWidth: 150,
      textAlign: 'left',
    },
    textAlignCenter: {
      margin: 'auto',
      maxWidth: 150,
      textAlign: 'center',
    },
    approval: {
      maxWidth: 200,
      margin: 'auto',
      textAlign: 'left',
    },
    action: {
      textAlign: 'center',
      paddingRight: 20,
    },
    noOfApprovers: {
      margin: 'auto',
      maxWidth: 150,
      display: 'flex',
      justifyContent: 'flex-start',
    },
  }), { name: 'Payments' },
);

export default useStyles;
