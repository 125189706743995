import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import React from 'react';
import clsx from 'clsx';
import { CurrencyPair, NationalityPair } from '../../../../components/International';
import SearchTable from '../../../../components/Table/SearchTable';
import { TextEllipsis } from '../../../../components/TextEllipsis';
import { TSearchBaseResponse, TSearchParams } from '../../../../hooks/useSearch';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../utils/formatIsoDate';
import { setDateTime } from '../../../../utils/setDateTime';
import useStyles from '../Body.styles';

interface IPendingTable {
  pendingTableData: TSearchBaseResponse | undefined;
  hasNext: boolean;
  hasPrevious: boolean;
  loading: boolean;
  handleNextPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handlePreviousPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handleTableRowClick: (e: BeneficiaryDto) => void;
}

const RejectedTable: React.FC<IPendingTable> = (props: IPendingTable) => {
  const {
    pendingTableData,
    hasPrevious,
    hasNext,
    loading,
    handleNextPage,
    handlePreviousPage,
    handleTableRowClick,
  } = props;

  const styles = useStyles();

  const columns: ITableColumn[] = [
    { header: 'Entity', accessor: 'entity', className: clsx(styles.entity, 'dd-privacy-allow') },
    { header: 'Account Holder', accessor: 'name' },
    { header: 'Friendly Name', accessor: 'friendlyName' },
    { header: 'Country', accessor: 'bankCountryCode', className: 'dd-privacy-allow' },
    { header: 'Currency', accessor: 'currencyCode', className: 'dd-privacy-allow' },
    { header: 'IBAN', accessor: 'iban' },
    { header: 'Account Number', accessor: 'accountNumber' },
    { header: 'Swift', accessor: 'swift' },
    { header: 'Rejected Date', accessor: 'rejectedDate', className: 'dd-privacy-allow' },
  ];

  const generateTableData = (tableData: TSearchBaseResponse | undefined) => (
    tableData?.records as BeneficiaryDto[]
  )?.map((beneficiary) => ({
    ...beneficiary,
    entity: (
      <div>
        <TextEllipsis text={beneficiary.accountName ? beneficiary.accountName : '-'} maxChars={50} />
      </div>
    ),
    name: <TextEllipsis maxChars={30} text={beneficiary.name || '-'} />,
    friendlyName: <div className={styles.capitalise}>{beneficiary.friendlyName || '-'}</div>,
    iban: <div>{(beneficiary.iban && beneficiary.iban.toUpperCase()) || '-'}</div>,
    accountNumber: <div>{beneficiary.accountNumber || '-'}</div>,
    swift: <div>{beneficiary.swift || '-'}</div>,
    bankCountryCode: (beneficiary.bankCountryCode && <NationalityPair countryCode={beneficiary.bankCountryCode} />) || '-',
    currencyCode: (beneficiary.currencyCode && <CurrencyPair currencyCode={beneficiary.currencyCode} />) || '-',
    rejectedBy: beneficiary.rejectedBy || '-',
    rejectedDate: formatIsoDate(beneficiary.rejectedByDateTime || '-'),
    uploadedDateTime: setDateTime(beneficiary.createdDate),
  }));

  return (
    <SearchTable
      table={{
        columns,
        data: generateTableData(pendingTableData),
        clickable: true,
        handleTableRowClick,
      }}
      loading={loading}
      pagination={{
        handleNext: handleNextPage as any,
        handlePrevious: handlePreviousPage as any,
        hasNext,
        hasPrevious,
      }}
      emptyTable={{
        title: 'No Rejected Beneficiaries',
        subtitle: 'You currently do not have any rejected beneficiaries',
      }}
    />
  );
};

export default RejectedTable;
