import Status from 'components/Status/Status';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TextEllipsis } from '../../../components/TextEllipsis';
import useStyles from './CurrencyAccountsTable.styles';

interface IProps {
    testId: string;
    friendlyName: string,
    bankInformation: string | undefined,
    pendingIATTransfers?: number,
    handleOpenDrawer: (_drawer: 'PendingTransfers') => void
  }

const FriendlyName: React.FC<IProps> = ({
  testId,
  friendlyName,
  bankInformation,
  pendingIATTransfers,
  handleOpenDrawer,

}: IProps) => {
  const classes = useStyles();
  if (!pendingIATTransfers) {
    return (
      <div className={classes.friendlyNameNoButton}>
        <span className={classes.infoPrimary}><TextEllipsis text={friendlyName} /></span>
        <span className={classes.infoSecondary}><TextEllipsis text={bankInformation || ''} /></span>
      </div>
    );
  }

  return (
    <div className={classes.friendlyName}>
      <div className="information">
        <span className={classes.infoPrimary}><TextEllipsis text={friendlyName} /></span>
        <span className={classes.infoSecondary}><TextEllipsis text={bankInformation || ''} /></span>
      </div>
      <button
        type="button"
        id={`bellContainer-${testId}`}
        className="bellContainer dd-privacy-allow"
        onClick={() => {
          handleOpenDrawer('PendingTransfers');
        }}
      >
        <Status variant="success" className="bellStatus">
          <>
            <FontAwesomeIcon icon={faBell} />
            {`${pendingIATTransfers} PENDING`}
          </>
        </Status>
      </button>
    </div>
  );
};

export default FriendlyName;
