import React from 'react';
import { Beneficiary } from '@alpha/bene-dtos';
import BackdropLoader from '../../Molecules/Loaders/BackdropLoader/BackdropLoader';
import Drawer from '../Drawer';
import DrawerHeaderFlag from '../DrawerHeaderFlag/DrawerHeaderFlag';
import Body from './Body/Body';

export type TBeneficiaryDrawerBeneficiary = Omit<Beneficiary, 'id' | 'createdBy' | 'createdDate'>;
interface IProps {
  beneficiary: TBeneficiaryDrawerBeneficiary | undefined,
  open: boolean;
  loading?: boolean;
  handleClose: () => void;
}

const BeneficiaryDrawer: React.FC<IProps> = ({
  beneficiary,
  open,
  handleClose,
  loading,
}: IProps) => (
  <Drawer
    anchor="right"
    open={open}
    onClose={handleClose}
    headerTitle={(
      <b>
        {(beneficiary && beneficiary?.friendlyName)
            || beneficiary?.name
            || ''}
      </b>
      )}
    headerSubtitle={(<div>{beneficiary && beneficiary?.friendlyName ? beneficiary.friendlyName : ''}</div>)}
    headerStats={{
      country: {
        value:
            (beneficiary && (
              <DrawerHeaderFlag
                countryCode={beneficiary.bankCountryCode}
              />
            ))
            || '-',
      },
      currency: {
        value:
            (beneficiary && (
              <DrawerHeaderFlag countryCode={beneficiary.currencyCode} />
            ))
            || '-',
      },
    }}
  >
    {
        loading
          ? <BackdropLoader testId="beneficiary-info-loader" />
          : <Body beneficiary={beneficiary} />
      }
  </Drawer>
);

export default BeneficiaryDrawer;
