import { createContext, Dispatch, SetStateAction } from 'react';
import { TCurrencyAccounts, TCurrencyAccount } from '../models/currencyAccounts';
import { TPayment } from '../models/payments';

export type TSinglePaymentsContextType =
  | {
    currentPayment: TPayment;
    isValid: boolean;
    open: boolean;
    editRow?: number;
    setEditRow: Dispatch<SetStateAction<number | undefined>>;
    setIsValid: Dispatch<SetStateAction<boolean>>;
    setCurrentPayment: Dispatch<SetStateAction<TPayment>>;
    handleBeneficiaryDrawerToggle: (state: boolean) => void;
  }
  | undefined;

export const SinglePaymentContext = createContext<TSinglePaymentsContextType>(undefined);

type TContextType = {
  currentPayments: TPayment[];
  currencyAccounts: TCurrencyAccounts;
  selectedDebitingAccount: TCurrencyAccount | undefined;
  handleSetCurrentPayments: (_currentPayments: TPayment[]) => void;
};

export const PaymentsContext = createContext<TContextType>({
  currentPayments: [],
  currencyAccounts: [],
  selectedDebitingAccount: undefined,
  handleSetCurrentPayments: () => { },
});
