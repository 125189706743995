import { Box, Typography } from '@mui/material';
import { faCogs } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction } from 'react';
import { BaseButton } from 'components/Buttons';
import { useStyles } from './index.styles';

interface IHeaderProps {
  groupView: boolean;
  setGroupView: Dispatch<SetStateAction<boolean>>;
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const { groupView, setGroupView } = props;

  const classes = useStyles({ groupView });

  return (
    <Box className={classes.stickyHeader}>
      <Box className={classes.icon}>
        <FontAwesomeIcon icon={faCogs} />
      </Box>
      <Typography className={classes.headerText}>Entity Selector</Typography>
      <Box>
        <BaseButton
          disableRipple
          onClick={() => setGroupView(false)}
          className={classes.entitiesButton}
          testId="outlined-entities-btn"
          size="medium"
        >
          Entities
        </BaseButton>
        <BaseButton
          disableRipple
          onClick={() => setGroupView(true)}
          className={classes.groupsButton}
          testId="outlined-groups-btn"
          size="medium"
        >
          Groups
        </BaseButton>
      </Box>
    </Box>
  );
};

export default Header;
