import axios, { AxiosResponse } from 'axios';
import { authedReqOpts } from 'domain/Onboarding/api';
import environmentVars from 'env.variables';
import React, { SetStateAction } from 'react';
import { Submission } from '.';

export const initPayload = {
  data: {
    customDraftSave: true,
    global: {
      submissionId: '',
      amlLetters: [],
      directors: [],
      proofOfAddressDocuments: [],
      structureCharts: [],
      ubos: [],
    },
    entities: [
      {
        generalQuestions: {
          entityName: '',
          registrationNumber: '',
          jurisdictionOfIncorporation: '',
          entityType: '',
        },
        isTrustOrFoundationOrNomineeWithMoreOwnership: {
          trust: false,
          foundationStichting: false,
          nomieeShareholders: false,
        },
        isRegulatedEntityPerformingAmlOnEntity: '',
        businessRegistryExtract: [],
        financialStatements: [],
        doesOperationalTransactions: '',
        doesInternalStructureTransactions: '',
        doesExternalTransactions: '',
        thereAreLenders: '',
        doesInvestorUBOTransactions: '',
        typologyAndCountriesOfInvestors: [],
        jurisdictionPaymentsToAndFrom: [],
        approximateAmountToBeFundedIsKnown: '',
        reusedDirectors: [],
        directorsNonEurope: [],
        directorsEurope: [],
        structureInformation: {
          structureName: '',
          ReusedCertifiedStructureChart: [],
          certifiedStructureCharts: [],
        },
        targetUnderlyingInvestmentsOfEntity: [],
        sanctionedIndividualOrEntityWithinTheStructure: [],
        stateOwnedEntity: [],
        russiaBelarusOrUkraineInvolvement: [],
        areThereAnyNaturalPersonUbOsWith25ControlOfTheEntity: '',
        UKServiceAgreement: [],
        annualTurnoverBanding: '',
        majorityShareholderAnnualTurnoverBanding: '',
      },
    ],
    submittedByDetails: {
      firstNameOfPersonSubmitting: '',
      lastNameOfPersonSubmitting: '',
      positionOfPersonSubmitting: '',
      signatureOfPersonSubmitting: '',
      firstNameOfContactPersonForPostOnboarding: '',
      lastNameOfContactPersonForPostOnboarding: '',
      emailOfContactPersonForPostOnboarding: '',
      currencyOfInvoicesPaid: {},
    },
    formRating: '',
    formRatingReason: '',
    saveDraft: true,
    submit: false,
  },
  state: 'draft',
  _vnote: '',
};

export const listSubmissions = async (
  userId: string,
  skip: string,
  state: string,
  formId: string,
): Promise<AxiosResponse | undefined> => {
  try {
    const url = new URL(`${environmentVars.FORMIO_FORM_URL}form/${formId}/submission?limit=10&skip=${skip}`);
    url.searchParams.set('owner', userId);
    url.searchParams.set('state', state);
    url.searchParams.set('sort', '-modified');
    const response = await axios.get<Submission[]>(url.toString(), authedReqOpts());
    return response;
  } catch (e) {
    console.log(e);
  }
};

type NewSubmissionState = Record<'success' | 'loading' | 'error', boolean> & Record<'id', string | null>;
export const createSubmission = async (
  setNewSubmissionState: React.Dispatch<SetStateAction<NewSubmissionState>>,
  newSubmissionState: NewSubmissionState,
  init: () => Promise<any>,
  formId: string,
) => {
  try {
    setNewSubmissionState({
      loading: true,
      success: false,
      error: false,
      id: null,
    });
    const url = new URL(`${environmentVars.FORMIO_FORM_URL}form/${formId}/submission`);

    const res = await axios.post(url.toString(), { ...initPayload }, authedReqOpts());

    await init();

    setNewSubmissionState({
      ...newSubmissionState,
      loading: false,
      success: true,
      // eslint-disable-next-line no-underscore-dangle
      id: res.data._id as string,
    });
  } catch (e) {
    setNewSubmissionState({
      ...newSubmissionState,
      loading: false,
      error: true,
    });
  }
};

export const verifyFormAccess = async (userId: string, formId: string) => {
  const res = await listSubmissions(userId, '0', '', formId);
  return !!res?.status && res.status === 200;
};
