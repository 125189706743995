import React, { FC } from 'react';
import { FormTitleStyled } from './styles';

type IProps = {
  text: string;
}

const FormTitle: FC<IProps> = ({ text }) => <FormTitleStyled>{ text }</FormTitleStyled>;

export default FormTitle;
