import _default, { makeStyles } from 'theme';

const { palette } = _default;

export const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '460px',
      '& h2': {
        letterSpacing: '-0.5px',
      },
    },
    imageWrapper: {},
    content: {
      width: '100%',
      '& .typography': {
        marginBottom: '40px',
        width: '100%',
        textAlign: 'center',
        '& h2': {
          fontWeight: 'bold',
        },
        '& .totalRecords': {
          '& h6': {
            fontWeight: 300,
            '& b': {
              marginLeft: '16px',
            },
          },
        },
      },
      '& .loader': {
        height: 'auto',
      },
      '& .badges': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: '40px',
        '& .wrapper': {
          width: '150px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          '& svg': {
            fontSize: '24px',
          },
          '& > div:first-of-type': {
            '& svg': {
              color: palette.primary.main,
            },
          },
          '& > div:nth-of-type(2) svg': {
            color: '#FFC107',
          },
          '& > div:nth-of-type(3) svg': {
            color: palette.error.main,
          },
        },
      },
    },
  }),
  { name: 'UploadingPayment' },
);

export default useStyles;
