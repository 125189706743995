import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useForm from '../../../../hooks/useForm';
import { TUserAccount } from '../../../../models/user';
import EntityGroupsService from '../../../../services/EntityGroups/entityGroups.service';
import { TStore } from '../../../../store';
import { actions as entityGroupActions } from '../../../../store/entityGroups/reducer';
import useEntityGroupDrawer from '../useEntityGroupDrawer';
import { CreateEntityGroupRequest, initialValues, validation } from './CreateGroupForm';

const useCreateEntityGroup = () => {
  const [loading, setLoading] = useState<boolean>();
  const dispatch = useDispatch();
  const accountsInNewGroup = useSelector<TStore, TUserAccount[] | undefined>(
    (state: TStore) => state?.entityGroups.accountsInNewGroup,
  );
  const { toggleCreateGroupDrawer, requestUpdateEntityGroupDetails } = useEntityGroupDrawer();
  const sb = useAlphaSnackbar();

  useEffect(() => {
    if (accountsInNewGroup) {
      form.setFieldValue('accountIds', accountsInNewGroup.map(((account) => account.id)));
    }
  }, [accountsInNewGroup]);

  const addAccountToNewGroup = (account: TUserAccount) => {
    dispatch(entityGroupActions.addAccountToNewGroupForm({ account }));
  };

  const removeAccount = (account: TUserAccount) => {
    dispatch(entityGroupActions.removeAccountFromNewGroupForm({ account }));
  };

  const resetAccountsInNewGroupForm = () => {
    dispatch(entityGroupActions.resetAccountsInNewGroupForm());
  };

  const createNewGroup = async (formValues:CreateEntityGroupRequest) => {
    try {
      setLoading(true);
      await EntityGroupsService.createEntityGroup(
        formValues.entityGroupName,
        formValues.accountIds,
      );
      form.resetForm();
      resetAccountsInNewGroupForm();
      toggleCreateGroupDrawer(false);
      requestUpdateEntityGroupDetails();
    } catch (error) {
      sb.trigger('Could not create entity group at this time', 'error');
    } finally {
      setLoading(false);
    }
  };
  const form = useForm(initialValues, validation, createNewGroup);
  const handleSubmitForm = () => {
    createNewGroup(form.values);
  };

  return {
    form,
    loading,
    createNewGroup,
    addAccountToNewGroup,
    accountsInNewGroup,
    removeAccount,
    handleSubmitForm,
    resetAccountsInNewGroupForm,
  };
};
export default useCreateEntityGroup;
