import React, { useEffect } from 'react';

import clsx from 'clsx';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';

import {
  CurrencyPair,
  NationalityPair,
} from '../../../components/International';
import DashboardTable from '../../../components/Table/DashboardTable';
import { TextEllipsis } from '../../../components/TextEllipsis';
import useBeneficiary from '../../../hooks/useBeneficiary';
import { TSearchBaseResponse } from '../../../hooks/useSearch';
import useShareBeneficiaryDrawer
  from '../../../hooks/useShareBeneficiaryDrawer';
import {
  ITableColumn,
} from '../../../services/DatabaseServices/marker.interface';
import browserHistory from '../../../services/history/browserHistory';
import { TStore } from '../../../store';
import {
  actions,
} from '../../../store/outstandingTables/outstandingTables.reducer';
import formatIsoDate from '../../../utils/formatIsoDate';
import { setDateTime } from '../../../utils/setDateTime';
import {
  BeneInfoDrawer,
} from '../../Beneficiaries/Body/BeneInfoDrawer/BeneInfoDrawer';
import DeleteOrRejectModal from '../../Beneficiaries/Body/DeleteOrRejectModal';
import useBody, { BeneStatusIndex } from '../../Beneficiaries/Body/useBody';
import {
  DashboardTabs,
  IPendingTableProps,
} from '../Common/helpers';
import ActionDropDown from './ActionDropdown/index';

const Beneficiaries: React.FC<IPendingTableProps> = (
  props: IPendingTableProps,
) => {
  const {
    enableSearch, enablePagination, searchPlaceholder, entityClassName,
  } = props;

  const {
    searchParams,
    tableSearch,
    selectedBeneficiary,
    setSelectedBeneficiary,
    loading,
    modalOpen,
    setModalOpen,
    handleInputChange,
    handleOnDeleteClick,
    handleCloseModal,
    handleTableRowClick,
    authyState,
    handleOnRejectionClickFromDashboard,
    setLoading,
  } = useBody(BeneStatusIndex.pending, true);
  const { approveBeneficiary } = useBeneficiary();

  const { handleBeneficiaryShareButtonClick } = useShareBeneficiaryDrawer(
    setSelectedBeneficiary,
  );
  const dispatch = useDispatch();

  const selectedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const allowedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const tableSizeRedux = useSelector<TStore, Record<string, number>>(
    (state) => state.outstandingDashboardTables.tablesSize,
  );
  const userAccountsLoading = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.userAccountsLoading,
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        authyState.type?.type === 'BENEFICIARY'
        && authyState.status === 'SUCCESS'
      ) {
        setSelectedBeneficiary(undefined);
        tableSearch.handleInitialSearch(searchParams, true);
      }
    }, 5000);

    return () => clearTimeout(timeout);
  }, [authyState.status, authyState.type, browserHistory.push]);

  const columns: ITableColumn[] = [
    { header: 'Friendly Name', accessor: 'friendlyName' },
    { header: 'Entity', accessor: 'accountName', className: clsx(entityClassName, 'dd-privacy-allow') },
    { header: 'Country', accessor: 'bankCountryCode', className: 'dd-privacy-allow' },
    { header: 'Currency', accessor: 'currencyCode', className: 'dd-privacy-allow' },
    { header: 'IBAN', accessor: 'iban' },
    { header: 'Account Number', accessor: 'accountNumber' },
    { header: 'Swift', accessor: 'swift' },
    { header: 'Created Date', accessor: 'createdDate', className: 'dd-privacy-allow' },
    { header: 'Action', accessor: 'review', className: 'dd-privacy-allow' },
  ];

  useEffect(() => {
    if (
      !userAccountsLoading
      && (selectedAccounts.length > 0 || allowedAccounts.length > 0)
    ) {
      setLoading(false);
      tableSearch.handleInitialSearch(searchParams, true);
    } else {
      setLoading(true);
    }
  }, [selectedAccounts, allowedAccounts, userAccountsLoading]);

  useEffect(() => {
    if (tableSearch.items?.items?.total) {
      dispatch(
        actions.updateOutstandingTablesSize({
          ...tableSizeRedux,
          [DashboardTabs.BENE_APPROVALS]: tableSearch.items?.items.total,
        }),
      );
    }
  }, [tableSearch.items?.items?.total]);

  const generateTableData = (tableData: TSearchBaseResponse | undefined) => (
    tableData?.records as BeneficiaryDto[])?.map((beneficiary) => ({
    name: beneficiary.name,
    accountName: <TextEllipsis text={beneficiary.accountName || '-'} />,
    friendlyName: beneficiary.friendlyName || '-',
    iban:
  <div>
    {(beneficiary.iban && beneficiary.iban.toUpperCase())}
    {' '}
  </div> || '-',
    accountNumber:
  <div>
    {' '}
    {beneficiary.accountNumber}
    {' '}
  </div> || '-',
    swift:
  <div>
    {beneficiary.swift}
  </div> || '-',
    bankCountryCode:
        (beneficiary.bankCountryCode && (
          <NationalityPair countryCode={beneficiary.bankCountryCode} />
        ))
        || '-',
    currencyCode:
        (beneficiary.currencyCode && (
          <CurrencyPair currencyCode={beneficiary.currencyCode} />
        ))
        || '-',
    createdDate: formatIsoDate(beneficiary.createdDate),
    uploadedDateTime: setDateTime(beneficiary.createdDate),
    review: beneficiary.uploadedBy && (
    <ActionDropDown
      setSelectedBeneficiary={setSelectedBeneficiary}
      beneficiary={beneficiary}
      approveBeneficiary={approveBeneficiary}
      handleOnRejectionClick={handleOnRejectionClickFromDashboard}
    />
    ),
  }));

  return (
    <>
      <DashboardTable
        columns={columns}
        data={generateTableData(tableSearch.items?.items) || []}
        handlePendingTableRowClick={() => {
          if (selectedBeneficiary) {
            handleTableRowClick(selectedBeneficiary);
          }
        }}
        loading={tableSearch.loading || loading}
        searchProps={{
          searchEnabled: enableSearch,
          searchValue: tableSearch.searchText,
          totalItems: tableSearch.items?.items.total || 0,
          searchPlaceholder,
          onSearchTermChange: handleInputChange,
        }}
        paginationProps={{
          paginationEnabled: enablePagination,
          pagination: {
            hasPrevious: tableSearch.items?.hasPrevious || false,
            hasNext: tableSearch.items?.hasNext || false,
            handlePrevious: () => tableSearch.handlePreviousPage(searchParams, 10, true),
            handleNext: () => tableSearch.handleNextPage(searchParams, 10, true),
          },
        }}
        emptyTable={{
          title: 'No Pending Beneficiaries',
          subtitle: 'You currently do not have any pending beneficiaries',
        }}
      />
      <BeneInfoDrawer
        selectedTabIndex={BeneStatusIndex.pending}
        selectedBeneficiary={selectedBeneficiary}
        beneDrawerOpen={Boolean(selectedBeneficiary)}
        closeDrawer={() => setSelectedBeneficiary(undefined)}
        handleBeneficiaryShareButtonClick={handleBeneficiaryShareButtonClick}
        setModalOpen={setModalOpen}
        hasEntitiesToShareTo={false}
      />
      {selectedBeneficiary && (
        <DeleteOrRejectModal
          modalType={modalOpen}
          loading={loading}
          modalOpen={Boolean(modalOpen)}
          handleCloseModal={handleCloseModal}
          handleOnDeleteClick={handleOnDeleteClick}
          handleOnRejectionClick={handleOnRejectionClickFromDashboard}
        />
      )}
    </>
  );
};

export default Beneficiaries;
