import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Flags } from 'react-feature-flags';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import { useStyles } from '../ChoosePayment.styles';
import DragAndDrop from '../DragAndDrop/DragAndDrop';
import { CHOOSE_PAYMENT_TEST_IDS } from '../ChoosePayment';
import uploadIcon from '../../../../../assets/upload_icon.svg';
import PaymentsService from '../../../../../services/Payments/payments.service';
import { actions } from '../../../../../store/payments/payments.reducer';

export const availableExt = ['csv', 'xls', 'xlsx', 'xml'];

export const FileUpload: React.FC = () => {
  const classes = useStyles();
  const inputRef = React.createRef<HTMLInputElement>();
  const dispatch = useDispatch();

  const snackbar = useAlphaSnackbar();

  const uploadFileToS3 = async (file: File) => {
    try {
      // set batchStatus to 'REQUESTED' before BE does - to show loading screen
      dispatch(actions.updateBatchStatus('REQUESTED'));
      const { batchId, url } = await PaymentsService.getPresignedUrl(file.name);
      dispatch(actions.updateBatchId(batchId));

      await PaymentsService.putPaymentFile(url, file);

      //  Start the polling now
      dispatch(actions.triggerBatchStatus());
    } catch (e) {
      snackbar.trigger(
        `There was an error uploading your file. (${e.message})`,
      );
      // set batchStatus back to 'undefined' in case of failure
      // clears batchStatus state so that user can reupload a new batch on 'Create Payments' drawer
      dispatch(actions.updateBatchStatus(undefined));
    }
  };

  const sendFileHandler = async (file: File) => {
    const fileArray = file.name.split('.');
    // Validate
    if (availableExt.includes(fileArray[fileArray.length - 1])) {
      await uploadFileToS3(file);
    } else {
      snackbar.trigger(
        `The chosen file type is not one that is supported: ${availableExt.join(
          ', ',
        )}.`,
      );
    }
  };

  const inputHandlerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      sendFileHandler(e.target.files[0]);
    }
  };

  return (
    <DragAndDrop
      className={classes.file}
      data-testid={CHOOSE_PAYMENT_TEST_IDS.fileUploadSection}
      onDropHandler={sendFileHandler}
    >
      <div className="fileContent">
        <Typography variant="h6" className={classes.title}>
          File Upload
        </Typography>
        <Typography variant="subtitle2" className={classes.subtitle}>
          Upload a batch of payment instructions to be processed in one go.
        </Typography>
      </div>

      <div className={classes.dragnDropBox}>
        <div className="middleContent">
          <img src={uploadIcon} alt="upload-icon" />
          <Typography variant="h6">Drag & Drop</Typography>
          <Box component="p">
            Or click
            {' '}
            <button onClick={() => { inputRef.current?.click(); }} className={classes.browseButton} type="button">browse</button>
            {' '}
            files
          </Box>

        </div>
        <Box className={classes.endContent}>
          <Box className={classes.download}>
            <Link to="/static/files/ALPHA_PAYMENTS_TEMPLATE.xlsx" target="_blank" download>Download Template</Link>
          </Box>
          <Box component="span" className={classes.fileTypeTitle}>
            Supported file types
          </Box>
          <Box component="span" className={classes.fileType}>
            .csv
          </Box>
          <Box component="span" className={classes.fileType}>
            .xls
          </Box>
          <Box component="span" className={classes.fileType}>
            .xlsx
          </Box>
          <Flags authorizedFlags={['paymentXmlUpload']}>
            <Box component="span" className={classes.fileType}>
              .xml
            </Box>
          </Flags>
        </Box>
      </div>
      <Flags
        authorizedFlags={['paymentXmlUpload']}
        renderOn={() => (
          <input
            data-testid="input"
            ref={inputRef}
            type="file"
            name="file"
            accept=".csv, .xlsx, .xml"
            hidden
            onChange={inputHandlerChange}
          />
        )}
        renderOff={() => (
          <input
            data-testid="input"
            ref={inputRef}
            type="file"
            name="file"
            accept=".csv, .xlsx,"
            hidden
            onChange={inputHandlerChange}
          />
        )}
      />
    </DragAndDrop>
  );
};

export default FileUpload;
