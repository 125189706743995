import theme, { makeStyles } from 'theme';

const { typography, shadows } = theme;

export const useStyles = makeStyles(() => (
  {
    documentsUploadBox: {
      fontSize: 14,
      marginTop: 25,
    },
    documentsUploadInfo: {
      marginBottom: 30,
    },
    text: {
      fontSize: 14,
      marginBottom: 20,
    },
    link: {
      fontSize: 'inherit',
      color: 'rgb(9, 131, 117)',
      fontFamily: 'inherit',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
    },
    addIcon: {
      marginRight: 8,
    },
    modalBox: {
      position: 'relative',
      maxWidth: 560,
      padding: 25,
      fontSize: 14,
      maxHeight: 'calc(100vh - 180px)',
      overflow: 'auto',
    },
    modalTitle: {
      fontSize: 24,
      marginBottom: 20,
    },
    modalSubtitle: {
      fontSize: 16,
      color: '#909090',
      marginBottom: 20,
      fontFamily: 'inherit',
    },
    modalFooter: {
      paddingTop: 25,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    footerButton: {
      textTransform: 'none',
      marginLeft: 10,
    },
    limitWarning: {
      color: '#ff0000',
      fontSize: 14,
      marginBottom: 10,
    },
    closeButton: {
      background: 'none',
      color: '#000',
      fontSize: 14,
      minWidth: 0,
      width: 50,
      height: 50,
      borderRadius: '100%',
      position: 'absolute',
      right: 0,
      top: 0,

      '&:hover': {
        background: 'none',
        color: 'rgb(9, 131, 117)',
      },
    },
    dragNDropArea: {
      border: '2px dashed #d4d4d4',
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: 160,
      boxSizing: 'border-box',
      marginTop: 25,
      position: 'relative',
    },
    dragNDropText: {
      fontFamily: typography.fontFamilySecondary,
      fontSize: 14,
      color: '#5d6d82',
      marginBottom: 10,
      padding: '0 20px',
    },
    sizeNote: {
      fontFamily: typography.fontFamilySecondary,
      fontSize: 14,
      color: '#909090',
      textAlign: 'center',
      padding: '0 20px',
    },
    accordionSummary: {
      padding: 0,
    },
    accordionIcon: {
      width: 12,
      display: 'inline-block',
      marginRight: 5,
    },
    uploadInput: {
      opacity: 0,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      cursor: 'pointer',
    },
    file: {
      display: 'flex',
      padding: 16,
      background: '#f7f7f7',
      borderRadius: 3,
      marginTop: 10,
      justifyContent: 'space-between',
      alignItems: 'center',

      '&:first-child': {
        marginTop: 0,
      },
    },
    fileInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    fileName: {
      fontSize: 14,
      cursor: 'pointer',

      '&:hover': {
        color: 'rgb(9, 131, 117)',
      },
    },
    fileSize: {
      fontSize: 14,
      color: '#909090',
    },
    fileIcon: {
      fontSize: 40,
      color: 'rgb(9, 131, 117)',
      marginRight: 20,
    },
    removeFile: {
      background: 'none',
      color: '#000',
      fontSize: 14,
      minWidth: 0,

      '&:hover': {
        background: 'none',
        color: 'rgb(9, 131, 117)',
      },
    },
  }
), { name: 'DocumentsUpload' });

export default useStyles;
