import _default, { makeStyles } from 'theme';

const { palette } = _default;

export const useStyles = makeStyles(
  () => ({
    swiftHint: {
      color: '#737373',
      fontWeight: 'normal',
    },
    swiftLearnMoreButton: {
      all: 'unset',
      color: palette.primary.main,
      cursor: 'pointer',
      fontWeight: 'bold',
    },
  }),
  { name: 'CreateBeneficiaryDrawer' },
);

export default useStyles;
