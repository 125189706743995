import _default, { makeStyles } from 'theme';

const { palette, typography } = _default;

export const useStyles = makeStyles(
  () => ({
    dropdownWrapperSelected: {
      position: 'relative',
      '& .MuiInputBase-root': {
        paddingLeft: '45px !important',
      },
    },
    dropdown: {
      backgroundColor: 'transparent',
      '& .MuiFormControl-root': {
        '& .MuiInputBase-root': {
          backgroundColor: palette.grey[100],
          borderRadius: '5px',
          '&::before': {
            borderBottom: 'none',
          },
          '&::after': {
            display: 'none',
          },
          height: 'auto',
          padding: '15px 65px 15px 13px',
          ' & .MuiInputBase-input': {
            fontSize: '16px',
            padding: 0,
            height: 'auto',
          },
        },
      },
    },
    divider: {
      display: 'flex',
      width: '90%',
      marginLeft: '5%',
      borderBottom: '1px solid rgba(0,0,0,0.3)',
      marginTop: '8px',
      marginBottom: '8px',
    },
    hideFlag: {
      '& .MuiInputBase-root': {
        paddingLeft: '16px !important',
      },
    },
    output: {
      '&.hideFlag': {
        display: 'none',
      },
      display: 'flex',
      overflow: 'hidden',
      borderRadius: '50%',
      marginRight: '8px',
      minWidth: '20px',
      height: '20px !important',
      border: '2px solid rgb(224,218,218)',
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'relative',
      alignItems: 'center',
      padding: '5px !important',
      '& img': {
        width: '22px',
        height: '30px',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
    },
    itemRow: {
      display: 'flex',
    },
    optionIcon: {
      '&.hideFlag': {
        display: 'none',
      },
      display: 'flex',
      overflow: 'hidden',
      borderRadius: '50%',
      marginRight: '8px',
      width: '20px',
      height: '20px',
      border: '2px solid rgb(224,218,218)',
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '27px',
      transform: 'translate(-50%,-50%)',
      alignItems: 'center',
      padding: '5px',
      '& img': {
        width: '22px',
        height: '30px',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
    },
    outputText: {
      fontSize: '16px',
    },
    labelWrapper: {
      display: 'flex',
      alignitems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    paymentInfo: {
      textAlign: 'center',
      margin: 'auto',
      paddingLeft: 10,
      fontWeight: typography.fontWeightLight,
      color: 'rgb(51, 51, 51, 0.5)',
    },
    selectedCheck: {
      color: palette.primary.main,
      marginLeft: 'auto',
      alignSelf: 'center',
    },
  }),
  { name: 'AutocompleteDropDown' },
);

export default useStyles;
