import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TBeneBatchId } from '../../models/beneficiaries';

export type TAuthyType = 'BENEFICIARY' | 'PAYMENTS' | 'PAYMENT_APPROVE' | 'BENEFICIARY_BATCH' | 'USER_ROLE_REQUEST';
export enum AuthyPageStates {
  AUTHY = 'authy',
  SMS = 'sms',
  CALL = 'call',
  OPTIONS = 'options',
}
export type TAuthyBeneficiaryType = {
  type: TAuthyType;
  batchId: TBeneBatchId;
  accountBeneficiaryIds: string[];
  accountId?: string;
  fraudWarningAcknowledged?: boolean
};
export type TAuthyPaymentsType = {
  batchId: string;
  type: TAuthyType;
  approverOwn?: boolean;
  accountId?: string;
  fraudWarningAcknowledged?: boolean;
};
export type TAuthyUserManagementType = {
  type: 'USER_ROLE_REQUEST';
  requestId?: string;
  requestIds?: string[];
};

export type TAuthyBeneficiaryBatchType = {
  batchId: string;
  type: 'BENEFICIARY_BATCH';
  accountIds: string[];
  accountBeneId: string;
  accountId: string;
  fraudWarningAcknowledged?: boolean
};

export type TAuthyStatus = 'INITIATED' | 'CODE SUBMITTED' | 'SUCCESS' | 'ERROR';
export type TAuthyState = {
  open: boolean;
  type?:
    | TAuthyBeneficiaryType
    | TAuthyPaymentsType
    | TAuthyBeneficiaryBatchType
    | TAuthyUserManagementType;
  status?: TAuthyStatus;
  pageState?: AuthyPageStates;
  phoneNumber?: string;
  approvalRequestId?: string;
};

const initialState: TAuthyState = {
  open: false,
};

export const authySlice = createSlice({
  initialState,
  name: 'authy',
  reducers: {
    reset: (): TAuthyState => initialState,
    initiate: (
      state: TAuthyState,
      { payload }: PayloadAction<TAuthyBeneficiaryType
        | TAuthyPaymentsType
        | TAuthyBeneficiaryBatchType
        | TAuthyUserManagementType>,
    ) => ({
      ...state,
      open: true,
      type: payload,
      status: 'INITIATED' as TAuthyStatus,
    }),
    updateStatus: (
      state: TAuthyState,
      { payload }: PayloadAction<TAuthyStatus>,
    ) => ({
      ...state,
      status: payload,
    }),
    updatePhoneNumber: (
      state: TAuthyState,
      { payload }: PayloadAction<string>,
    ) => ({
      ...state,
      phoneNumber: payload,
    }),
    updateApprovalRequestId: (
      state: TAuthyState,
      { payload }: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      approvalRequestId: payload,
    }),
    updateAuthyPageState: (
      state: TAuthyState,
      { payload }: PayloadAction<AuthyPageStates>,
    ) => ({
      ...state,
      pageState: payload,
    }),
  },
});

export const { actions } = authySlice;
