import { makeStyles } from 'theme';

export const useListStyles = makeStyles(
  () => ({
    // -- Todo -- we have 6 instances of calling the <AutoComplete> component
    // from ui-lib, this is a temp fix for a styling issue with custom renderOptions
    // Eventually we need to refactor these 6 instances into a reusable single component.
    listBox: {
      '& .MuiAutocomplete-option': {
        '&[aria-selected="true"]': {
          background: 'rgba(0,0,0,0.08) !important',
          '&:hover': {
            background: 'rgba(0,0,0,0.05) !important',
          },
        },
      },
    },
  }),
  { name: 'listBox' },
);

export default useListStyles;
