import { IATDraftDto, IATDraftResponse } from '@alpha/iat-dtos';
import Status from 'components/Status/Status';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import clsx from 'clsx';
import DashboardTable from '../../../components/Table/DashboardTable';
import { TSearchBaseResponse } from '../../../hooks/useSearch';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';
import { formatNumber } from '../../../utils/currency.helpers';
import formatIsoDate from '../../../utils/formatIsoDate';
import { ISelectedAccount } from '../../CurrencyAccounts/AccountInfoDrawer/AccountInfoDrawer';
import useBody from './useBody';
import InterAccountTransfer from '../../InterAccountTransfer/InterAccountTransfer';
import { DashaboardTablesSearchUrls, DashboardTabs, IPendingTableProps } from '../Common/helpers';
import usePendingTable from '../Common/usePendingTable';
import ActionDropDown from './ActionDropDown/ActionDropDown';
import RejectIATModal from '../../CurrencyAccounts/RejectIATModal';
import { TStore } from '../../../store';
import { actions } from '../../../store/outstandingTables/outstandingTables.reducer';
import browserHistory from '../../../services/history/browserHistory';
import { TextEllipsis } from '../../../components/TextEllipsis';

const BalanceTransfer: React.FC<IPendingTableProps> = (props: IPendingTableProps) => {
  const {
    enableSearch, enablePagination, searchPlaceholder, entityClassName,
  } = props;
  const {
    searchParams,
    tableSearch,
    dashboardTablesState,
    handleInputChange,
  } = usePendingTable(DashaboardTablesSearchUrls.BALANCE_TRANSFERS);
  const dispatch = useDispatch();

  const columns: ITableColumn[] = [
    { header: 'Entity', accessor: 'entity', className: clsx(entityClassName, 'dd-privacy-allow') },
    { header: 'Debiting Account', accessor: 'debitingAccount' },
    { header: 'Crediting Account', accessor: 'creditingAccount' },
    { header: 'Fixed Amount', accessor: 'fixedAmount', className: 'dd-privacy-allow' },
    { header: 'Created Date', accessor: 'createdDate', className: 'dd-privacy-allow' },
    { header: 'Approvers', accessor: 'approvers', className: 'dd-privacy-allow' },
    { header: 'Actions', accessor: 'buttons', className: 'dd-privacy-allow' },
  ];

  const [
    selectedDraftIAT,
    setSelectedDraftIAT,
  ] = useState<IATDraftResponse>();

  const [
    selectedAccount,
    setSelectedAccount,
  ] = useState<ISelectedAccount>();

  const {
    drawer, handleOpenDrawer,
  } = useBody();

  const selectedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const allowedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const tableSizeRedux = useSelector<TStore, Record<string, number>>(
    (state) => state.outstandingDashboardTables.tablesSize,
  );
  const userAccountsLoading = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.userAccountsLoading,
  );

  const [IATRejectModalOpen, setIATRejectModalOpen] = useState<boolean>(false);
  const [initialSearchCalled, setInitialSearchCalled] = useState<boolean>(false);

  const returnCurrencyAccountName = (currencyAccount?: CurrencyAccountDto): string => {
    if (currencyAccount) {
      return currencyAccount.friendlyName
       || currencyAccount.iban
       || currencyAccount.accountNumber
       || currencyAccount.reference
       || '-';
    }
    return '-';
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (dashboardTablesState.dashboardAction.includes('REJECT_MODAL')
      || dashboardTablesState.dashboardAction.includes('IAT_DRAWER')
      || dashboardTablesState.dashboardAction.includes('FINISH_IAT')
      ) {
        tableSearch.handleInitialSearch(searchParams, true);
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [browserHistory.push, dashboardTablesState?.dashboardAction]);

  useEffect(() => {
    if (
      !userAccountsLoading
      && (selectedAccounts.length > 0 || allowedAccounts.length > 0)
    ) {
      tableSearch.handleInitialSearch(searchParams, true);
      setInitialSearchCalled(true);
    }
  }, [selectedAccounts, allowedAccounts, userAccountsLoading]);

  useEffect(() => {
    if (tableSearch.items?.items?.total) {
      dispatch(
        actions.updateOutstandingTablesSize({
          ...tableSizeRedux,
          [DashboardTabs.BALANCE_TRANSFER]: tableSearch.items?.items.total || 0,
        }),
      );
    }
  }, [tableSearch.items?.items?.total]);
  const getFixedSideInfo = (
    credAmount : number,
    debAmount : number,
    credCurrency : string | undefined,
    debCurrency : string | undefined,
  ): string => {
    let amount = credAmount;
    let currency = credCurrency;
    if (credAmount == null) {
      amount = debAmount;
      currency = debCurrency;
    }
    return `${formatNumber(amount, 2)} ${currency}`;
  };
  const generateTableData = (tableData: TSearchBaseResponse | undefined) => (
        tableData?.records as IATDraftDto[]
  )?.map((balanceTransfer) => ({
    entity: <TextEllipsis text={balanceTransfer.accountName || '-'} />,
    debitingAccount: <div>{returnCurrencyAccountName(balanceTransfer.debitCurrencyAccount)}</div>,
    creditingAccount: <div>{returnCurrencyAccountName(balanceTransfer.creditCurrencyAccount)}</div>,
    fixedAmount: `${getFixedSideInfo(balanceTransfer.creditAmount, balanceTransfer.debitAmount, balanceTransfer.creditCurrencyAccount?.currencyCode, balanceTransfer.debitCurrencyAccount?.currencyCode)}`,
    approvers: (
      <div>
        <Status variant="default">
          {`${balanceTransfer.approvals.length}/${balanceTransfer.approvalsRequired}`}
        </Status>
      </div>),
    createdDate: (<Typography variant="subtitle1">{formatIsoDate(balanceTransfer.submittedByDateTime)}</Typography>),
    buttons: (
      <ActionDropDown
        iatDraft={balanceTransfer}
        handleOpenDrawer={handleOpenDrawer}
        setSelectedDraftIAT={setSelectedDraftIAT}
        setSelectedAccount={setSelectedAccount}
        setIATRejectModalOpen={setIATRejectModalOpen}
      />
    ),
  }));

  return (
    <div>
      <DashboardTable
        columns={columns}
        data={generateTableData(tableSearch.items?.items) || []}
        loading={tableSearch.loading || !initialSearchCalled}
        searchProps={{
          searchEnabled: enableSearch,
          searchValue: tableSearch.searchText,
          totalItems: tableSearch.items?.items.total || 0,
          searchPlaceholder,
          onSearchTermChange: handleInputChange,
        }}
        paginationProps={{
          paginationEnabled: enablePagination,
          pagination: {
            hasPrevious: tableSearch.items?.hasPrevious || false,
            hasNext: tableSearch.items?.hasNext || false,
            handlePrevious: () => tableSearch.handlePreviousPage(searchParams, 10, true),
            handleNext: () => tableSearch.handleNextPage(searchParams, 10, true),
          },
        }}
        emptyTable={{
          title: 'No Pending Balance Transfers',
          subtitle: 'You currently do not have any pending Balance Transfers',
        }}
      />
      <InterAccountTransfer
        open={drawer === 'IAT'}
        caDrawer={drawer}
        handleCloseIATDrawer={() => handleOpenDrawer(false)}
        selectedAccount={selectedAccount}
        selectedDraftIAT={selectedDraftIAT}
        setSelectedDraftIAT={setSelectedDraftIAT}
        multiEntity
      />
      <RejectIATModal
        selectedDraftIATId={selectedDraftIAT?.id}
        accountId={selectedDraftIAT?.accountId}
        selectedDraftIATSubmittedById={selectedDraftIAT?.submittedById}
        modalOpen={IATRejectModalOpen}
        handleCloseModal={() => setIATRejectModalOpen(false)}
      />
    </div>
  );
};

export default BalanceTransfer;
