import { BeneficiaryDraft, BeneficiaryReleasedDto } from '@alpha/bene-dtos';
import React, { useEffect, useState } from 'react';
import {
  PaymentReleasedDto,
  PaymentDraftDto,
  PaymentBatchStatus,
  PaymentBatchDraftStatus,
} from '@alpha/payments-dtos';
import clipboardCross from '../../../../assets/clipboardCross.svg';
import BackdropLoader from '../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import Search from '../../../../components/Organisms/Search';
import EmptyTable from '../../../../components/Table/EmptyTable/EmptyTable';
import useSearch, { TSearchParams } from '../../../../hooks/useSearch';
import BeneficiaryInfoDrawer from './BeneficiaryInfoDrawer/BeneficiaryInfoDrawer';
import Table from './Table/Table';
import AccountInfoDrawer, {
  ISelectedAccount,
} from '../../../CurrencyAccounts/AccountInfoDrawer/AccountInfoDrawer';

interface IProps {
  batchId: string;
  accountId: string;
  currencyPairId?: string;
  postApproval: boolean;
  sellCurrencyCode: string;
  buyCurrencyCode: string;
  fixedSide: string;
  actionNeeded: boolean;
  batchStatus: PaymentBatchDraftStatus | PaymentBatchStatus;
  setPaymentDrafts: (paymentDrafts: PaymentDraftDto[]) => void;
  setPaymentDraftsLoading: (paymentDraftsLoading: boolean) => void;
}
export type PaymentSummaryBeneficiary =
  | BeneficiaryDraft
  | BeneficiaryReleasedDto
  | undefined;

const createSearchParams = (
  batchId: string,
  sellCurrencyCode: string,
  buyCurrencyCode: string,
  fixedSide: string,
  skip: number,
  currencyPairId?: string,
): TSearchParams => {
  if (currencyPairId) {
    return {
      baseUrl: `/payments/batches/released/${batchId}/payments/search`,
      queryParams: {
        batchcurrencyid: currencyPairId,
        skip,
        take: 10,
      },
    };
  }
  return {
    baseUrl: `/payments/batches/${batchId}/payments/search`,
    queryParams: {
      sellcurrencycode: sellCurrencyCode,
      buycurrencycode: buyCurrencyCode,
      fixedside: fixedSide,
      skip,
      take: 10,
    },
  };
};

const PaymentSummary: React.FC<IProps> = (props: IProps) => {
  const {
    batchId,
    accountId,
    sellCurrencyCode,
    buyCurrencyCode,
    fixedSide,
    currencyPairId,
    postApproval,
    actionNeeded,
    batchStatus,
    setPaymentDrafts,
    setPaymentDraftsLoading,
  } = props;
  const {
    items,
    loading,
    searchText,
    skip,
    handleNewSearch,
    handleInitialSearch,
    handleNextPage,
    handlePreviousPage,
    setSearchText,
  } = useSearch(accountId);

  const [beneficiary, setBeneficiary] = useState<PaymentSummaryBeneficiary>();
  const [selectedCurrencyAccount, setSelectedCurrencyAccount] =
    useState<ISelectedAccount>();

  if (items?.items?.records) {
    setPaymentDrafts((items?.items.records as PaymentDraftDto[]) || []);
  }
  setPaymentDraftsLoading(loading);

  const searchParams: TSearchParams = createSearchParams(
    batchId,
    sellCurrencyCode,
    buyCurrencyCode,
    fixedSide,
    skip,
    currencyPairId,
  );

  useEffect(() => {
    handleInitialSearch(searchParams, true);
  }, []);

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.searchtext = e.target.value;
    }
    await handleNewSearch(searchParams, true);
  };

  return (
    <div style={{ position: 'relative', minHeight: '350px' }}>
      <div style={{ width: '400px' }}>
        <Search
          testId="random"
          totalItems={items?.items.total || 0}
          placeholder="Search beneficiary name or payment ref"
          onChange={handleInputChange}
        />
      </div>
      {!items?.items.records.length && !loading ? (
        <div
          style={{
            height: '341px',
            position: 'relative',
            marginBottom: '41px',
          }}
        >
          <EmptyTable
            icon={clipboardCross}
            title="No payment summary"
            subtitle={
              !searchText
                ? 'We were unable to retrieve your payment summary for this currency pair'
                : 'No results found for this search term'
            }
          />
        </div>
      ) : (
        <>
          <Table
            setSelectedCurrencyAccount={setSelectedCurrencyAccount}
            data={
              (items?.items.records as
                | PaymentDraftDto[]
                | PaymentReleasedDto[]) || []
            }
            handleNextPage={() => handleNextPage(searchParams, 10, true)}
            handlePreviousPage={() =>
              handlePreviousPage(searchParams, 10, true)
            }
            hasNext={items?.hasNext || false}
            hasPrevious={items?.hasPrevious || false}
            isSameCurrency={Boolean(sellCurrencyCode === buyCurrencyCode)}
            postApproval={postApproval}
            actionNeeded={actionNeeded}
            setBeneficiary={setBeneficiary}
            batchStatus={batchStatus}
          />
          <BeneficiaryInfoDrawer
            accountId={accountId}
            paymentSummaryBeneficiary={beneficiary}
            onClose={() => setBeneficiary(undefined)}
            open={Boolean(beneficiary)}
            setPaymentSummaryBeneficiary={setBeneficiary}
          />
          <AccountInfoDrawer
            open={Boolean(selectedCurrencyAccount)}
            selectedAccount={selectedCurrencyAccount}
            setSelectedDraftIAT={() => {}}
            viewOnly
            handleOpenDrawer={() => setSelectedCurrencyAccount(undefined)}
            inputterAccounts={[]}
          />
        </>
      )}
      {loading && <BackdropLoader testId="loader" />}
    </div>
  );
};

export default PaymentSummary;
