import { AccountDto } from '@alpha/auth-dtos';
import { Role } from '@alpha/auth-types';
import {
  BeneCopyRequestDto,
  BeneCopyResponseDto,
  Beneficiary,
  BeneficiaryCreateRequest,
  BeneficiaryDraft,
  CountryRequirements,
  CurrencyList,
  ValidateBeneficiaryRequest,
} from '@alpha/bene-dtos';
import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import { AxiosResponse } from 'axios';
import { TUserAccount } from '../../models/user';
import generateResidentAddress from '../../utils/generateResidentAddress';
import instance, { instanceAccountId, instanceNoAccountId } from '../Axios/instance';

export class BeneficiariesService {
  public static mapBeneficiaryDraftToBeneficiary(
    beneDraft: BeneficiaryDraft,
  ): BeneficiaryDto {
    return {
      ...beneDraft,
      accountBeneId: '',
      beneficiaryId: beneDraft.id,
      beneficiaryCountryCode: beneDraft.countryCode,
      nationalCodeType: beneDraft.nationalBankCode,
      residentAddress: generateResidentAddress(
        beneDraft.addressLine1,
        beneDraft.city,
        beneDraft.countryCode,
      ),
      bankName: beneDraft.bankName || '',
      approvedBy: beneDraft.approvedBy,
      approvedByDateTime: beneDraft.approvedByDateTime,
      accountNumber: beneDraft.accountNumber || '',
      friendlyName: beneDraft.friendlyName,
      correspondentAccountNumber: beneDraft.correspondentAccountNumber,
      correspondentSwift: beneDraft.correspondentSwift,
      furtherToAccountNumber: beneDraft.furtherToAccountNumber,
      furtherToSwift: beneDraft.furtherToSwift,
    };
  }

  public static async getCurrencies({
    filterAllowedCurrencies,
    includeCurrencies,
  } : {
    filterAllowedCurrencies: boolean;
    includeCurrencies: string[];
  }): Promise<CurrencyList> {
    const hasMultipleCurrencies = includeCurrencies.length > 0;
    let queryString = '';
    if (filterAllowedCurrencies) {
      queryString = `?filterAllowedCurrencies=${filterAllowedCurrencies}`;
    }

    if (hasMultipleCurrencies) {
      queryString += `${filterAllowedCurrencies ? '&' : '?'}includeCurrencies=${JSON.stringify(includeCurrencies)}`;
    }

    const response: AxiosResponse<CurrencyList> = await instance.get(
      `/bene/currencies${queryString}`,
    );
    return response.data;
  }

  public static async postValidatePaymentBene(
    beneficiaryValidateRequest: ValidateBeneficiaryRequest,
    accountId?: string,
  ): Promise<BeneficiaryDraft> {
    const response = accountId
      ? await instanceAccountId(accountId).post(
        '/bene/beneficiaries/validate',
        beneficiaryValidateRequest,
      )
      : await instance.post(
        '/bene/beneficiaries/validate',
        beneficiaryValidateRequest,
      );

    if (
      response.data.applyFinancialErrored
      && !response.data.userIgnoresApplyFinancialErrors
    ) {
      return response.data;
    }

    if (response.status !== 200) {
      throw Error('Something went wrong');
    }

    if (response.data.errors?.length) {
      const errorString = response.data.errors.join('\r\n');
      throw Error(errorString);
    }

    return response.data;
  }

  public static async getAllManualPaymentBeneficiaries(
    searchParams?: string,
    filterCurrency?: string,
  ): Promise<Beneficiary[]> {
    return this.getAllManualPaymentBeneficiariesAsync(
      searchParams,
      filterCurrency,
    );
  }

  private static async getAllManualPaymentBeneficiariesAsync(
    searchParams?: string,
    filterCurrency?: string,
  ): Promise<Beneficiary[]> {
    const searchParamsQuery = searchParams && `searchparams=${searchParams}&`;
    const filterCurrencyQuery = filterCurrency && `filtercurrency=${filterCurrency}`;

    let url = '/bene/beneficiaries/search?';

    if (searchParamsQuery) {
      url += searchParamsQuery;
    }

    if (filterCurrencyQuery) {
      url += filterCurrencyQuery;
    }

    const response = await instance.get(url);
    return response.data;
  }

  public static async getCountryRequirements(
    countryCode: string,
    currencyCode: string,
    accountIds?: string[],
  ): Promise<CountryRequirements> {
    return this.getCountryRequirementsAsync(countryCode, currencyCode, accountIds);
  }

  public static async submitBeneficiary(
    beneficiaryCreateRequest: BeneficiaryCreateRequest,
  ): Promise<BeneficiaryDraft[]> {
    const response: AxiosResponse<BeneficiaryDraft[]> = beneficiaryCreateRequest.accountIds
      ? await instanceNoAccountId.post(
        '/bene/v3/submit',
        beneficiaryCreateRequest,
        {
          headers: {
            'account-id': beneficiaryCreateRequest.accountIds[0],
          },
        },
      )
      : await instance.post(
        '/bene/v3/submit',
        beneficiaryCreateRequest,
      );
    return response.data;
  }

  public static async rejectBeneficiary(
    beneficiary: BeneficiaryDto,
    accountId?: string,
  ): Promise<void> {
    return this.rejectBeneficiaryAsync(beneficiary, accountId);
  }

  public static async deleteBeneficiary(
    beneficiary: BeneficiaryDto,
    accountId?: string,
  ): Promise<void> {
    return this.deleteBeneficiaryAsync(beneficiary, accountId);
  }

  public static async getIndividualBeneficiary(
    accountBeneId: string, accountId?: string,
  ): Promise<Beneficiary> {
    const response = accountId
      ? await instanceAccountId(accountId).get(
        `/bene/beneficiaries/approved/${accountBeneId}`,
      )
      : await instance.get(
        `/bene/beneficiaries/approved/${accountBeneId}`,
      );
    return response.data;
  }

  public static async deleteDraftBeneficiary(
    beneId: string,
    accountId?: string,
  ): Promise<void> {
    return this.deleteDraftBeneficiaryAsync(beneId, accountId);
  }

  public static async getAvailableAccountsToShareBeneficiary() {
    const response: AxiosResponse<AccountDto[]> = await instance.get(
      '/bene/copy/available-accounts',
    );
    return response.data;
  }

  public static async shareBeneficiaryWithSelectedAccounts(
    shareAccountsRequest: BeneCopyRequestDto,
  ): Promise<BeneCopyResponseDto> {
    const response = await instance.post('/bene/copy', shareAccountsRequest);
    return response.data;
  }

  public static async copyBeneficiary(
    accountBeneId: string,
    accountIds: string[],
    totp: number,
    accountId: string,
    softToken?: boolean,
    fraudWarningAcknowledged?: boolean,
  ): Promise<void> {
    if (accountId) {
      const response = await instanceAccountId(accountId).post('/bene/v3/copy', {
        totp,
        accountBeneId,
        accountIds,
        softToken,
        fraudWarningAcknowledged,
      });
      return response.data;
    }
    const response = await instance.post('/bene/v3/copy', {
      totp,
      accountBeneId,
      accountIds,
      softToken,
      fraudWarningAcknowledged,
    });
    return response.data;
  }

  private static async getCountryRequirementsAsync(
    countryCode: string,
    currencyCode: string,
    accountIds?: string[],
  ): Promise<CountryRequirements> {
    const response: AxiosResponse<CountryRequirements> = await instance.post(
      `/bene/country-requirements?countryCode=${countryCode}`,
      { accountIds, currencyCode },
    );
    return response.data;
  }

  private static async rejectBeneficiaryAsync(
    beneficiary: BeneficiaryDto,
    accountId?: string,
  ): Promise<void> {
    if (accountId) {
      await instanceAccountId(accountId).post(`/bene/v3/${beneficiary.accountBeneId}/reject`, {
        accountBeneficiaryId: beneficiary.accountBeneId,
      });
    } else {
      await instance.post(`/bene/v3/${beneficiary.accountBeneId}/reject`, {
        accountBeneficiaryId: beneficiary.accountBeneId,
      });
    }
  }

  private static async deleteBeneficiaryAsync(
    beneficiary: BeneficiaryDto,
    accountId?: string,
  ): Promise<void> {
    if (accountId) {
      await instanceAccountId(accountId).delete(`/bene/v3/delete/${beneficiary.accountBeneId}`);
    } else {
      await instance.delete(`/bene/v3/delete/${beneficiary.accountBeneId}`);
    }
  }

  private static async deleteDraftBeneficiaryAsync(
    beneId: string,
    accountId?: string,
  ): Promise<void> {
    if (accountId) {
      await instanceAccountId(accountId).delete(`/bene/v3/draft/delete/${beneId}`);
    } else {
      await instance.delete(`/bene/v3/draft/delete/${beneId}`);
    }
  }

  public static async getBeneficiaryInputterEntities(): Promise<TUserAccount[]> {
    const response: AxiosResponse<{accounts: TUserAccount[]}> = await instance(
      `/auth/user-accounts-by-role/${Role.BENEFICIARY_INPUTTER.replace(/\s+/g, '').toUpperCase()}`,
    );
    if (response.status !== 200) {
      throw Error('Unable to get user accounts');
    }
    return response?.data?.accounts;
  }

  public static async getBeneficiaryApproverOwnEntities(): Promise<TUserAccount[]> {
    const response: AxiosResponse<{accounts: TUserAccount[]}> = await instance(
      `/auth/user-accounts-by-role/${Role.BENEFICIARY_APPROVER_OWN.replace(/\s+/g, '').toUpperCase()}`,
    );
    if (response.status !== 200) {
      throw Error('Unable to get user accounts');
    }
    return response?.data?.accounts;
  }

  public static async getEntityGroupsAccountsWithBeneInputter(
    groupIds: string[],
    accountIds: string[],
  ): Promise<string[]> {
    const response: AxiosResponse<{accounts: string[]}> = await instanceNoAccountId.post(
      '/auth/entity-group/accounts-read',
      {
        groupIds, accountIds,
      },
      {
        headers: {
          'account-id': 'null',
        },
      },
    );
    return response.data?.accounts;
  }
}

export default BeneficiariesService;
