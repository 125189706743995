import { alpha } from '@mui/material';
import _default, { makeStyles } from 'theme';

const { palette } = _default;

export const useStyles = makeStyles(
  () => ({
    root: {
      maxWidth: '420px',
      margin: 'auto',
      transition: '0.3s',
      '& .MuiChip-root': {
        background: alpha(palette.primary.main, 0.15),
        color: palette.primary.main,
        fontSize: '1rem',
      },
      '& .MuiSvgIcon-root': {
        borderRadius: '100%',
        '& path': {
          color: palette.primary.main,
        },
      },
    },
    typography: {
      fontSize: '1rem',
      marginTop: '0.2em',
      marginBottom: '1em',
    },
    dropdown: {},
    textInput: {
      background: alpha(palette.common.black, 0.02),
      borderRadius: 4,
      '& fieldset': {
        border: 'none !important',
      },
    },
    icon: {
      marginTop: 3,
      marginBottom: 3,
    },
    alert: {
      fontSize: '1rem',
      fontWeight: 'bold',
      '& > *': {
        padding: '10px 0',
      },
    },
  }),
  { name: 'EntitiesSelector' },
);

export default useStyles;
