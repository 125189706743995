import { TextField } from '@mui/material';
import { styled } from '@mui/styles';

import _default from 'theme';

const { typography, background } = _default;

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: '1rem',
    padding: '0.375rem 0.75rem',
    height: '2.84rem',
    boxSizing: 'border-box',
  },

  '& .MuiInputLabel-root': {
    fontSize: '1rem',
  },

  '& .MuiOutlinedInput-root': {
    lineHeight: 1.5,
    height: '2.84rem',
    fontWeight: typography.fontWeightLight,
    backgroundColor: background.primary,
  },

  '& .MuiFilledInput-root': {
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: typography.fontWeightLight,
    borderRadius: 5,
    height: 50,
    backgroundColor: 'rgb(247, 247, 247, 0.13)',

    '&:after, &:before': {
      content: 'none',
    },
    '&:hover, &.Mui-focused': {
      backgroundColor: 'rgb(247, 247, 247, 0.13)',
    },
    '& .MuiInputBase-input': {
      marginLeft: 10,
      fontSize: 14,
      color: 'white',
      padding: '0.375rem 0.75rem',
      height: 50,
      lineHeight: 1.35,
      fontWeight: typography.fontWeightLight,
    },
  },
});

export default StyledTextField;
