import { ITheme } from '../theme.interfaces';
import shade from '../utils';
import logo from './Alpha-FX-Logo.png';
import fullLogo from './Alpha-FX-Full-logo.png';

export type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

export const theme: RecursivePartial<ITheme> = {
  typography: {
    fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    fontFamilySecondary: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    fontFamilyRebrand: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  background: {
    primary: '#fff',
    secondary: '#F7F9FC',
  },
  images: {
    logo,
    fullLogo,
  },
  palette: {
    primary: {
      light: '#3a9b90',
      main: '#098375',
      dark: '#065b51',
      contrastText: '#fff',
    },
    secondary: {
      light: '#5c5c5c',
      main: '#222222',
      dark: '#242424',
    },
    text: {
      primary: '#212529',
      secondary: '#16181b',
    },
    error: {
      main: '#b95656',
    },
    info: {
      main: '#197fab',
    },
    warning: {
      main: '#ffc107',
    },
    success: {
      main: '#3a9b90',
    },
  },
  rebrandPalette: {
    primary: {
      light: '#4EB2A3',
      main: '#098375',
      dark: '#00564A',
    },
    secondary: {
      light: '#494949',
      main: '#222222',
      dark: '#000000',
    },
    grey: {
      A100: '#F7F7F7',
      A200: '#C4C4C4',
      A400: '#949494',
    },
    error: {
      main: '#D55E5E',
    },
    info: {
      main: '#1473E6',
    },
    warning: {
      main: '#FFC107',
    },
    success: {
      main: '#55B171',
    },
    text: {
      primary: '#212529',
      secondary: '#ffffff',
    },
  },
  shadows: [
    'none',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
    '0 3px 6px #00000029',
  ],
  utils: {
    shade,
  },
  layout: {
    sidePadding: 60,
  },
};
