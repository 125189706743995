import AcknowledgeModal from 'components/Modals/AcknowledgeModal';
import React from 'react';
import {
  ContainerStyled,
  EntityInformationStyled,
  TextStyled,
  ProminentTextStyled,
  AlertBoxStyled,
  UnOrderedListStyled,
} from './ProfileStatusModal.styles';

export interface IAccount{
  id: string,
  name: string,
  kycReviewDate: string,
  reviewDue: boolean,
  reviewOverDue: boolean,
  accountFrozen: boolean,
}

interface IKYCProps {
  entities: IAccount[];
  modalOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}
const ProfileStatusModal = (props: IKYCProps) => {
  const {
    entities,
    modalOpen,
    onClose,
  } = props;

  const modalContent = (
    <ContainerStyled>
      <EntityInformationStyled>
        <TextStyled>
          Its important to complete entity onboarding as soon as possible.&nbsp;
          Please contact your client services representative tel&nbsp;
          <ProminentTextStyled>+44 (0)20 3854 9265</ProminentTextStyled>
          &nbsp;
          to complete onboarding.
        </TextStyled>
        <AlertBoxStyled severity="warning">
          <TextStyled>
            <ProminentTextStyled>Payments may take longer than usual.</ProminentTextStyled>
            <br />
            Until the incomplete entities have been fully onboarded payments&nbsp;
            may take longer than unusual from this entity.
          </TextStyled>
        </AlertBoxStyled>
        <TextStyled>
          <ProminentTextStyled>Incomplete entities:</ProminentTextStyled>
        </TextStyled>
        <UnOrderedListStyled>
          {entities.map((entity) => (
            <li key={entity.id} data-testid={entity.id}>{ entity.name }</li>
          ))}
        </UnOrderedListStyled>
        <TextStyled>
          We understand this may be inconvenient for you,&nbsp;
          but we appreciate your cooperation in completing your entity onboarding.
        </TextStyled>
      </EntityInformationStyled>
    </ContainerStyled>
  );

  return (
    <>
      <AcknowledgeModal
        open={modalOpen}
        title="Incomplete profile"
        content={modalContent}
        actionButtonText="Ok"
        handleActionButton={() => onClose(false)}
      />
    </>
  );
};
export default ProfileStatusModal;
