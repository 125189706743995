import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import useAlphaSnackbar from './useAlphaSnackbar';

const useAmplifyProfile = () => {
  const sb = useAlphaSnackbar();
  const [cognitoUser, setCognitoUser] = useState<any>();

  useEffect(() => {
    const newCognitoUser = getLoggedInUserDetails();
    if (newCognitoUser) setCognitoUser(cognitoUser);
  }, []);

  const getLoggedInUserDetails = async (): Promise<any> => {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (e) {
      sb.trigger(e.message || 'There was an error getting your user details');
    }

    return undefined;
  };

  return {
    cognitoUser,
    getLoggedInUserDetails,
  };
};

export default useAmplifyProfile;
