import { PaymentBatchDraftDto, PaymentBatchDto } from '@alpha/payments-dtos';
import React from 'react';
import { Stat } from 'components/Stat';
import getSymbolFromCurrency from 'currency-symbol-map';
import isPaymentBatchDraftDto from '../../../../../utils/payments/isPaymentBatchDraftDto.helper';
import { IBatchCurrencyPairDecorated } from '../../BatchSummary.interface';
import { formatNumber } from '../../../../../utils/currency.helpers';
import { useStyles } from '../BatchDetails.styles';

interface IProps {
  batchDetails: PaymentBatchDraftDto | PaymentBatchDto,
  currencyPairTab: IBatchCurrencyPairDecorated,
}

const CurrencyAmount = (props: IProps) => {
  const classes = useStyles();
  const {
    batchDetails,
    currencyPairTab,
  } = props;

  const handleAmount = (): number => {
    if ((batchDetails.batchStatus !== 'PROCESSING'
      && batchDetails.batchStatus !== 'COMPLETE')) {
      return currencyPairTab.amount;
    } return currencyPairTab.debitAmount;
  };

  if (((batchDetails.batchStatus === 'PROCESSING'
    || batchDetails.batchStatus === 'COMPLETE')
    && !isPaymentBatchDraftDto(batchDetails)
    && batchDetails.isSameCurrencyBatch)
    || (batchDetails.batchStatus !== 'PROCESSING'
      && batchDetails.batchStatus !== 'COMPLETE')
  ) {
    return (
      <Stat
        testId="currency-pair-amount"
        title={`${currencyPairTab.fixedSide} Amount`}
        value={(
          <span className={classes.bold}>
            {`${getSymbolFromCurrency(currencyPairTab.fixedSide)}${formatNumber(
              +handleAmount(),
              2,
            )}`}
          </span>
        )}
        alignRight
      />
    );
  }
  return null;
};

export default CurrencyAmount;
