import React, { useEffect, useState } from 'react';
// @ts-ignore
import qs from 'query-string';
import { Redirect } from 'react-router-dom';
import routes from 'routes.path';
import { getCurrentUser } from './api';
import UnauthorizedPage from './components/UnauthorizedPage';

export default function AuthScreen() {
  const [loading, setLoading] = useState(false);
  const [unauthenticated, setUnauthenticated] = useState(true);

  useEffect(() => {
    if (unauthenticated) {
      try {
        setLoading(true);

        // eslint-disable-next-line no-restricted-globals
        const fioToken = qs.parse(location.search).fioToken || localStorage.getItem('formioToken');
        if (!fioToken) {
          setLoading(false);
          return;
        }

        getCurrentUser(fioToken)
          .then((res) => {
            localStorage.setItem('formioUser', JSON.stringify(res.data));
            setUnauthenticated(false);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } catch (e) {
        setLoading(false);
        setUnauthenticated(true);
      }
    }
  }, []);

  // auth form
  if (unauthenticated) {
    return <UnauthorizedPage loading={loading} />;
  }

  return (
    <Redirect to={{ pathname: routes.onboardingForm.submissions }} />
  );
}
