import { useState, useEffect } from 'react';
import { InvoiceDto } from '@alpha/invoice-dtos';
import { useSelector } from 'react-redux';
import { iAllowedAccounts } from 'store/accounts/accounts.reducer';
import { TCurrencyTab } from '../../models/currencyAccounts';
import InvoiceService from '../../services/Invoices/invoices.service';
import useAlphaSnackbar from '../../hooks/useAlphaSnackbar';
import CurrencyAccountsService from '../../services/CurrencyAccounts/currencyAccounts.service';
import { TStore } from '../../store';
import InterAccountTransferService from '../../services/InterAccountTransfer/interAccountTransfer.service';

const useCurrencyAccount = (multiEntity?: boolean) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [tabs, setTabs] = useState<TCurrencyTab[]>([]);
  const [loading, setLoading] = useState(true);
  const [oldestOverdueInvoice, setOldestOverdueInvoice] = useState<InvoiceDto>();
  const [userInputterAccounts, setUserInputterAccounts] = useState<string[]>([]);

  const selectedUserAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const allowedUserAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const userAccountsLoading = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.userAccountsLoading,
  );

  const sb = useAlphaSnackbar();

  useEffect(() => {
    if (multiEntity) {
      if (!userAccountsLoading && (
        selectedUserAccounts.length > 0 || allowedUserAccounts.length > 0
      )) {
        populateCurrencyTabs();
      }
    } else {
      populateCurrencyTabs();
    }
  }, [selectedUserAccounts, allowedUserAccounts, userAccountsLoading]);

  useEffect(() => {
    if (multiEntity) {
      getUserIatInputterAccounts();
    }
  }, []);

  const getUserIatInputterAccounts = async () => {
    const accounts = await InterAccountTransferService.getIatInputterEntities();
    setUserInputterAccounts(accounts.map((account) => account.id));
  };

  const getOldestOverdueInvoice = async () => {
    try {
      const response = await InvoiceService.getInvoicesAsync();
      const overdueInvoices = InvoiceService.filterInvoicesByOverdue(response);
      const retrieveOldestOverdueInvoice = InvoiceService
        .retrieveOldestOverdueInvoice(overdueInvoices);

      setOldestOverdueInvoice(retrieveOldestOverdueInvoice);
    } catch (e) {
      console.log('There was an error retrieving invoices');
    } finally {
      setLoading(false);
    }
  };

  const populateCurrencyTabs = async () => {
    setSelectedTabIndex(0);
    try {
      const currencyTabs: TCurrencyTab[] = [];
      const all: TCurrencyTab = {
        currencyCode: 'ALL',
        currency: 'all currencies',
      };
      const response = await CurrencyAccountsService.getCurrencyTabs(multiEntity);
      currencyTabs.push(all);
      for (let i = 0; i < response.length; i += 1) {
        currencyTabs.push(response[i]);
      }
      // Prevent infinite loop from setState calls
      if (JSON.stringify(currencyTabs) !== JSON.stringify(tabs)) {
        setTabs(currencyTabs);
      }
      if (!oldestOverdueInvoice) await getOldestOverdueInvoice();
    } catch (e) {
      sb.trigger('There was an error loading your accounts');
    } finally {
      setLoading(false);
    }
  };

  const handleSetSelectedTabIndex = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };

  return {
    allowedUserAccounts,
    selectedTabIndex,
    tabs,
    loading,
    oldestOverdueInvoice,
    handleSetSelectedTabIndex,
    selectedUserAccounts,
    setTabs,
    populateCurrencyTabs,
    userInputterAccounts,
  };
};

export default useCurrencyAccount;
