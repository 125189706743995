import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@mui/material';
import {
  faCheckCircle, faEllipsisH, faEye, faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import React, { Dispatch, SetStateAction } from 'react';
import { IATDraftDto, IATDraftResponse } from '@alpha/iat-dtos';
import Menu from 'components/Menu/Menu';
import useActionDropdown from '../../../../hooks/useActionDropDown';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useStyles from '../../../Payments/Dashboard/PendingTable/DisplayTable/ActionDropDown/ActionDropDown.styles';
import { TCurrencyAccount } from '../../../../models/currencyAccounts';
import { ISelectedAccount } from '../../../CurrencyAccounts/AccountInfoDrawer/AccountInfoDrawer';

interface IButtonProps {
  iatDraft: IATDraftDto;
  handleOpenDrawer: (_drawer: 'IAT' |'AccountDrawer' | false) => void;
  setSelectedDraftIAT: Dispatch<SetStateAction<IATDraftResponse | undefined>>;
  setSelectedAccount: Dispatch<SetStateAction<ISelectedAccount | undefined>>;
  setIATRejectModalOpen: Dispatch<SetStateAction<boolean>>;
}

const ActionDropDown: React.FC<IButtonProps> = ({
  iatDraft,
  handleOpenDrawer,
  setSelectedDraftIAT,
  setSelectedAccount,
  setIATRejectModalOpen,
}: IButtonProps) => {
  const classes = useStyles();
  const sb = useAlphaSnackbar();
  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    setOpen,
  } = useActionDropdown({});

  const iatResponse = iatDraft as unknown as IATDraftResponse;

  const debitCurrencyAccount: TCurrencyAccount = iatDraft.debitCurrencyAccount as TCurrencyAccount;

  const tab = {
    currencyCode: iatDraft.debitCurrencyAccount?.currencyCode,
    currency: iatDraft.debitCurrencyAccount?.currency,
  };
  const debitCurrencySelectedAccount = {
    account: debitCurrencyAccount,
    tabs: tab,
  } as ISelectedAccount;

  let buttonName = 'View transfer';
  if (iatDraft.status === 'APPROVED' && iatDraft.requiresFx && iatDraft.currentUserCanBookFx) {
    buttonName = 'Book Fx';
  }

  const handleOpenIATDrawer = (iatDraftResponse: IATDraftResponse): void => {
    try {
      setSelectedAccount(debitCurrencySelectedAccount);
      setSelectedDraftIAT(iatDraftResponse);
      handleOpenDrawer('IAT');
    } catch {
      sb.trigger('There was an error loading your chosen transfer');
    }
  };

  const handleOpenIATRejectModal = (iatDraftResponse: IATDraftResponse): void => {
    setSelectedAccount(debitCurrencySelectedAccount);
    setSelectedDraftIAT(iatDraftResponse);
    setIATRejectModalOpen(true);
  };

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    setMenuCloseCount(0);
  };

  const viewTransfer = {
    id: 'action-iat-view',
    content: (
      <Typography data-testid="bene-action-option-1">
        {buttonName}
      </Typography>
    ),
    icon: faEye,
    onClick: () => handleOpenIATDrawer(iatResponse),
    underline: false,
  };

  const approveRejectMenuItems = [
    {
      ...viewTransfer,
      underline: true,
    },
    {
      id: 'action-iat-approve',
      content: (
        <Typography data-testid={`iat-action-approve-${iatDraft.id}`}>
          Approve
        </Typography>
      ),
      icon: faCheckCircle,
      onClick: () => handleOpenIATDrawer(iatResponse),
      underline: true,
    },
    {
      id: 'action-iat-reject',
      content: (
        <Typography data-testid={`iat-action-reject-${iatDraft.id}`}>
          Reject
        </Typography>
      ),
      icon: faTimesCircle,
      onClick: () => handleOpenIATRejectModal(iatResponse),
      underline: false,
    },

  ];

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box data-testid={`action-${iatDraft.id}`} className={classes.wrapper}>
        <Menu
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={menuCloseCount === 1}
          disableHoverEffect={false}
          onClose={handleClose}
          buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
          buttonTitle=""
          icon={faEllipsisH}
        >
          {
            iatDraft.currentUserCanApprove ? (
              approveRejectMenuItems.map((item) => (
                <MenuDropdownItem key={item.id} setOpen={setOpen}>{item}</MenuDropdownItem>
              ))
            ) : (
              <MenuDropdownItem setOpen={setOpen}>{viewTransfer}</MenuDropdownItem>
            )
          }
        </Menu>
      </Box>
    </ClickAwayListener>

  );
};

export default ActionDropDown;
