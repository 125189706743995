import { PaymentBatchSummaryDto } from '@alpha/payments-dtos';
import { PaymentUploadType } from '@alpha/payments-dtos/lib/batch/PaymentUploadType';
import { ReleasedPaymentBatchesResponse } from '@alpha/payments-types';
import formatIsoDate from '../../../../utils/formatIsoDate';

const getApprovedDate = (item: ReleasedPaymentBatchesResponse | undefined): string => {
  if (item?.approvedDate) {
    return formatIsoDate(item.approvedDate);
  }

  return 'N/A';
};

const getUploadedDate = (item: ReleasedPaymentBatchesResponse | undefined): string => {
  if (item?.uploadedDate) {
    return formatIsoDate(item.uploadedDate);
  }

  return 'N/A';
};

const getCompletePaymentType = (data: ReleasedPaymentBatchesResponse): string => {
  if (data?.uploadType === PaymentUploadType.SWIFT) {
    return PaymentUploadType.SWIFT;
  }

  return 'Batch';
};

const getUploadedBy = (data: ReleasedPaymentBatchesResponse): string => {
  if (data?.uploadType === PaymentUploadType.SWIFT) {
    return 'SYSTEM';
  }

  return data.uploadedBy || '';
};

export {
  getApprovedDate, getCompletePaymentType, getUploadedBy, getUploadedDate,
};
