import { BatchApprover } from '@alpha/payments-dtos';
import { faExchange, faEye, faFileCheck } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';
import PaymentsService from '../services/Payments/payments.service';
import useAlphaSnackbar from './useAlphaSnackbar';
import useAuthorization from './useAuthorization';

interface IProps {
  currentOptions?: BatchApprover[],
}

const useActionDropdown = ({
  currentOptions,
}: IProps = {}) => {
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const [approvers, setApprovers] = useState<BatchApprover[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [menuCloseCount, setMenuCloseCount] = useState<number>(0);
  const [renderApproveBatch, setRenderApproveBatch] = useState<boolean>(false);
  const [renderBookFx, setRenderBookFx] = useState<boolean>(false);
  const [isApprovedAndFx, setIsApprovedAndFx] = useState<boolean>(false);
  const userAuth = useAuthorization();
  const [renderApproveBene, setRenderApproveBene] = useState<boolean>(false);
  const [displayMultiSelect, setDisplayMultiSelect] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const snackbar = useAlphaSnackbar();

  const displayActionButton = () => {
    if (renderApproveBatch) {
      return 'Approve / Reject Batch';
    }
    if (renderBookFx && userAuth.roles.includes('Payments Approver')) {
      return 'Book FX / Reject Batch';
    }
    if (userAuth.roles.includes('Payments Approver') && isApprovedAndFx) {
      return 'View Batch / Reject Batch';
    }
    if (renderBookFx) {
      return 'View Batch / Book FX';
    }
    return 'View Batch';
  };

  const displayIcon = () => {
    if (renderApproveBatch || renderApproveBene) {
      return faFileCheck;
    }
    if (renderBookFx) {
      return faExchange;
    }
    return faEye;
  };

  const handleRenderDropdown = async (
    id: string,
    accountId?: string,
  ) => {
    if (!displayMultiSelect) {
      setDisplayMultiSelect(true);
      try {
        if (renderBookFx) {
          setApprovers(await PaymentsService.getFxApprovers(id, accountId));
        } else {
          setApprovers(await PaymentsService.getApprovers(id, accountId));
        }
      } catch (e) {
        snackbar.trigger('There was an error retrieving your approvers');
      } finally { setLoading(false); }
    }
  };

  const handleRenderConfirmationModal = () => {
    setOpenModal(true);
  };

  const submitApproverEmails = async (id: string, accountId?: string): Promise<boolean> => {
    const currentApproverIds: string[] = [];
    if (currentOptions) {
      currentOptions.map((option) => currentApproverIds.push(option.userId));
    }
    try {
      await PaymentsService.postApproverEmails(id, currentApproverIds, accountId);
      snackbar.trigger('Successfully sent email reminders', 'success');
      return true;
    } catch (e) {
      snackbar.trigger('Unable to send email reminders');
      return false;
    }
  };
  return {
    open,
    approvers,
    loading,
    menuCloseCount,
    displayMultiSelect,
    openModal,
    setRenderBookFx,
    setIsApprovedAndFx,
    setRenderApproveBatch,
    setMenuCloseCount,
    setLoading,
    setApprovers,
    setOpen,
    displayActionButton,
    displayIcon,
    handleRenderDropdown,
    submitApproverEmails,
    setRenderApproveBene,
    setDisplayMultiSelect,
    setOpenModal,
    handleRenderConfirmationModal,
  };
};

export default useActionDropdown;
