import { styled } from '@mui/material';
import BaseButton from '../BaseButton';
import { IGhostButtonProps } from '..';

const colorTable = {
  warning: {
    text: '#B95656',
    background: 'rgba(185, 86, 86, 0.2)',
  },
  default: {
    text: '#696969',
    background: '#F0F0F0',
  },
};

export const StyledGhostButton = styled(BaseButton, {
  shouldForwardProp: (props) => props !== 'colorVariant',
})<IGhostButtonProps>(({ colorVariant }) => ({
  borderRadius: 0,
  color: colorVariant ? colorTable[colorVariant].text : '#696969',
  '&:hover': {
    backgroundColor: colorVariant ? colorTable[colorVariant].background : '#F0F0F0',
    color: colorVariant ? colorTable[colorVariant].text : '#696969',
  },
}));
