import styled from '@mui/styled-engine';
import { alpha } from '@mui/material';

type TStyleProps = {
  withOverlay?: boolean,
}

export const StyledLoader = styled('div')((props: TStyleProps) => {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: alpha('#098375', 0.5),
  };

  if (props.withOverlay) {
    return {
      ...style,
      position: 'absolute',
      width: '100%',
      zIndex: 1000,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    };
  }
  return style;
});
