import React, { FC } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Flags, FlagsProvider } from 'react-feature-flags';
import { BaseButton } from 'components/Buttons';
import routes from '../../routes.path';
import { FeatureFlag } from '../../models/featureFlag';
import { INotFoundPageProps } from '.';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledContent,
  StyledRoot,
} from './NotFoundPage.styles';

interface IProps {
  flags?: FeatureFlag[];
}

const NotFoundPageWrapper: React.FC<IProps> = ({ flags }: IProps) => (
  <FlagsProvider value={flags}>
    <Flags
      authorizedFlags={['outstandingDashboard']}
      renderOn={() => <Redirect to={routes.dashboard.base} />}
      renderOff={() => <Redirect to={routes.currencyAccounts} />}
    />
  </FlagsProvider>
);

const enum ClickType {
  GO_HOME,
  PREVIOUS_PAGE,
}

export const NotFoundPageNoRedirect: FC<INotFoundPageProps> = (props) => {
  const history = useHistory();
  const {
    title = 'Houston, we have a problem…',
    content = 'We can’t seem to find the page you are looking for',
    homeLink = 'Go home',
    previousLink = 'Previous page',
  } = props;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    clickType: ClickType,
  ) => {
    event.preventDefault();

    switch (clickType) {
      case ClickType.PREVIOUS_PAGE:
        history.goBack();

        break;

      case ClickType.GO_HOME:
      default:
        history.push('/');
        break;
    }
  };

  return (
    <StyledRoot>
      <StyledContent>
        <StyledContainer>
          <h2>404</h2>
          <h3>{title}</h3>
          <p>{content}</p>
          <StyledButtonContainer>
            <BaseButton
              data-testid="home-page-button"
              onClick={(e) => handleClick(e, ClickType.GO_HOME)}
            >
              {homeLink}
            </BaseButton>
            <BaseButton
              data-testid="previous-page-button"
              onClick={(e) => handleClick(e, ClickType.PREVIOUS_PAGE)}
            >
              {previousLink}
            </BaseButton>
          </StyledButtonContainer>
        </StyledContainer>
      </StyledContent>
    </StyledRoot>
  );
};

export default NotFoundPageWrapper;
