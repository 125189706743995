import { TextField } from '@mui/material';
import { Autocomplete, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Status from 'components/Status/Status';
import KycModal from 'domain/OutstandingActionsDashboard/KYCModal/KycModal';
import useBannerRefresh from 'hooks/useBannerRefresh';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { Flags } from 'react-feature-flags';
import { TUserAccount } from '../../../../../../models/user';
import { AutocompleteEvent } from '../../../../PaymentsMultiEntity';
import useStyles from './Dropdown.styles';
import useListStyles from '../../../../../../components/AutocompleteDropDown/Listbox.styles';

interface IProps {
  selectedEntity?: TUserAccount;
  inputterEntities: TUserAccount[];
  handleSetSelectedEntity: (event: any)=>void
}

const EntityDropdown: React.FC<IProps> = (props:IProps) => {
  const {
    selectedEntity,
    inputterEntities,
    handleSetSelectedEntity,
  } = props;
  const {
    frozen,
    overdue,
    due,
    incomplete,
  } = useBannerRefresh();
  const [open, setOpen] = useState<boolean>(false);

  const { requestGetFeatureFlags, flags } = useGetFeatureFlags();

  const classes = useStyles();
  const { listBox } = useListStyles();
  const [entitySearchValue, setEntitySearchValue] = useState('');
  const [freezePayments, setFreezePayments] = useState(false);

  const checkFrozenPaymentFlag = () => {
    if (flags && flags.some((flag) => flag.name === 'freezePaymentsOnFrozenEntities' && flag.isActive)) {
      setFreezePayments(true);
    }
  };
  useEffect(() => {
    requestGetFeatureFlags();
    checkFrozenPaymentFlag();
  }, []);

  const handleChange = (
    _event: AutocompleteEvent, newValue: TUserAccount | null,
  ) => handleSetSelectedEntity(newValue);

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className="label">Entity</Typography>
      <Autocomplete
        classes={{ listbox: listBox }}
        disableClearable
        value={selectedEntity}
        onChange={handleChange}
        inputValue={entitySearchValue}
        className={classes.dropdown}
        onInputChange={(_event: AutocompleteEvent, newInputValue: string) => {
          setEntitySearchValue(newInputValue);
        }}
        options={inputterEntities}
        getOptionLabel={({ name }) => name}
        getOptionDisabled={(option) => freezePayments && !!option.dateFrozenForKycRefresh}
        renderOption={(renderProps, option) => (
          <div className={classes.accountMenuOption}>
            <div style={{ width: '100%' }} {...renderProps}>
              <Box sx={{ width: '80%' }} component="li">
                {option.name}
              </Box>
            </div>
            <>
              {freezePayments && option.dateFrozenForKycRefresh && (
              <Status className={classes.frozenStatus} onClick={setOpen} size="small" variant="error">
                Frozen
                <FontAwesomeIcon
                  size="sm"
                  className={classes.questionIcon}
                  icon={faQuestionCircle}
                />
              </Status>
              )}
              {
                incomplete.find((entity) => entity.id === option.id) && (
                  <Flags authorizedFlags={['incompleteProfile']}>
                    <Status className={classes.frozenStatus} size="small" variant="incomplete">
                      Incomplete
                    </Status>
                  </Flags>
                )
              }
            </>

          </div>
        )}
        renderInput={(params) => <TextField {...params} placeholder="Search Entity" InputProps={{ ...params.InputProps }} />}
      />
      <KycModal
        due={due}
        overdue={overdue}
        frozen={frozen}
        modalOpen={open}
        onClose={setOpen}
      />
    </div>
  );
};

export default EntityDropdown;
