import { PaymentBatchSummaryDto } from '@alpha/payments-dtos';
import { Box } from '@mui/material';
import Status from 'components/Status/Status';
import React from 'react';
import { ITableColumn } from '../../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import { getApprovedDate, getUploadedBy } from '../../DashboardDataHelper/DashboardDataHelper';
import PaymentsType from '../../PaymentsType/PaymentsType';
import NoOfApprovers from '../../PendingTable/DisplayTable/NoOfApprovers/NoOfApprovers';
import useStyles from '../../PendingTable/PendingTable.styles';
import TableContainer from '../../TableContainer/TableContainer';
import ActionStatus from './ActionStatus/ActionStatus';

interface IProps {
  columns: ITableColumn[];
  table: ITable;
}

export interface ITable {
  data: PaymentBatchSummaryDto[] | undefined;
  hasMore: boolean | undefined;
  hasPrevious: boolean;
  handleNextPage: () => Promise<void>;
  handlePreviousPage: () => Promise<void>;
  loading: boolean;
}

const DisplayTable: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const { columns, table } = props;

  const mappedData = table.data && table.data.map((item: PaymentBatchSummaryDto) => {
    const container: any = {};
    container.id = <b>{item.friendlyId || ''}</b>;
    container.type = <PaymentsType type={item.uploadType} />;
    container.uploadedBy = (
      <Box className={classes.textAlignLeft} maxWidth="100px">
        {getUploadedBy(item)}
      </Box>
    );
    container.uploadedDate = (
      <Box className={classes.textAlignLeft}>
        {
          item.status === 'UPLOADING'
            ? <span className={classes.processing}>Processing...</span>
            : formatIsoDate(item.uploadedDate)
        }
      </Box>
    );
    container.approvedDate = (
      <Box className={classes.textAlignLeft}>
        {
          getApprovedDate(item)
        }
      </Box>
    );

    container.noOfApprovers = (
      <NoOfApprovers
        uploadedBy={item.uploadedBy}
        uploadedDate={item.uploadedDate}
        noOfApprovals={item.approvals?.length}
        approvedBy={item.approvals}
        approvalsRequired={item.approvals?.length}
      />
    );

    container.paymentStatus = (
      <Box className={classes.textAlignLeft}>
        {
          item.status === 'UPLOADING'
            ? <span className={classes.processing}>Processing...</span>
            : (
              <Status variant="default">
                {`${item.noOfProcessedPayments}/${item.noOfPayments}`}
              </Status>
            )
        }
      </Box>
    );
    container.batchStatus = (
      <Box className={classes.textAlignLeft}>
        <Status variant="warning">
          in progress
        </Status>
      </Box>
    );
    container.action = (
      <ActionStatus batchStatus={item.status} id={item.id} />
    );
    return container;
  });

  return (
    <TableContainer
      table={table}
      message="In Progress"
      mappedData={mappedData || []}
      columns={columns}
    />
  );
};

export default DisplayTable;
