import styled from '@mui/styled-engine';

export const PaymentsDrawerTableHeadStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& button': {
    position: 'absolute',
    right: 40,
    width: '46px !important',
    height: '43px !important',
    border: '1px solid #E9E9E9',
    marginTop: '-20px',

    '& svg': {
      margin: 0,
      top: 0,
    },
  },
});
