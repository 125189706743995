import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TabContent } from 'components/Tabs';
import { ApprovedTable, PendingTable } from '..';
import Search from '../../../components/Organisms/Search';
import ElasticTableContextContainer from '../../../context/ElasticTableContext';
import useShareBeneficiaryDrawer from '../../../hooks/useShareBeneficiaryDrawer';
import routes from '../../../routes.path';
import browserHistory from '../../../services/history/browserHistory';
import { TStore } from '../../../store';
import CreateBeneficiary from '../CreateBeneficiary';
import { BeneInfoDrawer } from './BeneInfoDrawer/BeneInfoDrawer';
import { BeneShareDrawer } from './BeneShareDrawer/index';
import useStyles from './Body.styles';
import DeleteOrRejectModal from './DeleteOrRejectModal';
import RejectedTable from './RejectedTable/RejectedTable';
import useBody from './useBody';

interface IBodyProps {
  createBeneficiaryOpen: boolean,
  tabIndex: 1 | 0 | 2,
  setCreateBeneficiaryOpen: React.Dispatch<React.SetStateAction<boolean>>,
  multiEntity?: boolean,
  accounts?: {
    value: string;
    label: string;}[];}

export type ModalType = 'reject' | 'delete'

const Body: React.FC<IBodyProps> = ({
  createBeneficiaryOpen,
  tabIndex,
  setCreateBeneficiaryOpen,
  multiEntity,
  accounts,
}: IBodyProps) => {
  const {
    searchParams,
    tableSearch,
    selectedBeneficiary,
    authyState,
    loading,
    modalOpen,
    setSelectedBeneficiary,
    setModalOpen,
    handleInputChange,
    handleOnDeleteClick,
    handleCloseModal,
    handleOnRejectionClick,
    handleTableRowClick,
    handleNewSearch,
  } = useBody(tabIndex, multiEntity || false);
  const {
    accountBeneId,
    accountId,
    availableAccounts,
    handleBeneficiaryShareButtonClick,
    getAvailableAccountsToShareBeneficiary,
  } = useShareBeneficiaryDrawer(setSelectedBeneficiary);

  const styles = useStyles();

  const selectedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const allowedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const userAccountsLoading = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.userAccountsLoading,
  );

  useEffect(() => {
    if (multiEntity) {
      if (
        !userAccountsLoading
      && (selectedAccounts.length > 0 || allowedAccounts.length > 0)
      ) {
        tableSearch.handleInitialSearch(searchParams, true);
      }
    } else { tableSearch.handleInitialSearch(searchParams); }
  }, [selectedAccounts, allowedAccounts, userAccountsLoading, tabIndex]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        authyState.type?.type === 'BENEFICIARY'
        && authyState.status === 'SUCCESS'
      ) {
        setSelectedBeneficiary(undefined);
        browserHistory.push(`${routes.beneficiaries}?tab=approved`);
        if (multiEntity) {
          tableSearch.handleInitialSearch(searchParams, true);
        } else {
          tableSearch.handleInitialSearch(searchParams);
        }
      }
    }, 5000);

    return () => clearTimeout(timeout);
  }, [authyState.status, authyState.type, browserHistory.push]);

  const handleNextPage = () => {
    if (multiEntity) {
      return tableSearch.handleNextPage(searchParams, 10, true);
    }
    return tableSearch.handleNextPage(searchParams);
  };

  const handlePrevPage = () => {
    if (multiEntity) {
      return tableSearch.handlePreviousPage(searchParams, 10, true);
    }
    return tableSearch.handlePreviousPage(searchParams);
  };

  return (
    <ElasticTableContextContainer callback={handleNewSearch}>
      <div className={styles.searchBar}>
        <Search
          testId="search-beneficiaries"
          value={tableSearch.searchText}
          totalItems={tableSearch.items?.items.total || 0}
          placeholder="Search beneficiary name"
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.tabContainer}>
        <TabContent index={0} value={tabIndex} data-testid="approved-tab">
          <ApprovedTable
            hasNext={tableSearch.items?.hasNext || false}
            hasPrevious={tableSearch.items?.hasPrevious || false}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePrevPage}
            loading={tableSearch.loading || loading}
            approvedTableData={tableSearch.items?.items}
            handleTableRowClick={
              (e: BeneficiaryDto) => {
                handleTableRowClick(e);
                if (typeof e.accountId === 'string') {
                  getAvailableAccountsToShareBeneficiary(e.accountId);
                }
              }
            }
          />
        </TabContent>
        <TabContent index={1} value={tabIndex} data-testid="pending-tab">
          <PendingTable
            hasNext={tableSearch.items?.hasNext || false}
            hasPrevious={tableSearch.items?.hasPrevious || false}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePrevPage}
            loading={tableSearch.loading || loading}
            pendingTableData={tableSearch.items?.items}
            setSelectedBeneficiary={setSelectedBeneficiary}
          />
        </TabContent>
        <TabContent index={2} value={tabIndex} data-testid="rejected-tab">
          <RejectedTable
            hasNext={tableSearch.items?.hasNext || false}
            hasPrevious={tableSearch.items?.hasPrevious || false}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePrevPage}
            handleTableRowClick={
              (e: BeneficiaryDto) => handleTableRowClick(e)
            }
            loading={tableSearch.loading || loading}
            pendingTableData={tableSearch.items?.items}
          />
        </TabContent>
      </div>
      <BeneInfoDrawer
        selectedTabIndex={tabIndex}
        selectedBeneficiary={selectedBeneficiary}
        beneDrawerOpen={Boolean(selectedBeneficiary)}
        closeDrawer={() => setSelectedBeneficiary(undefined)}
        handleBeneficiaryShareButtonClick={handleBeneficiaryShareButtonClick}
        setModalOpen={setModalOpen}
        hasEntitiesToShareTo={Boolean(availableAccounts.length)}
      />
      <BeneShareDrawer
        availableAccounts={availableAccounts}
        accountBeneId={accountBeneId}
        handleBeneficiaryShareButtonClick={handleBeneficiaryShareButtonClick}
        accountId={accountId}
      />
      <CreateBeneficiary
        open={createBeneficiaryOpen}
        handleDrawerClose={() => setCreateBeneficiaryOpen(false)}
        multiEntity={multiEntity}
        accounts={accounts}
      />
      {selectedBeneficiary && (
        <DeleteOrRejectModal
          modalType={modalOpen}
          loading={loading}
          modalOpen={Boolean(modalOpen)}
          handleCloseModal={handleCloseModal}
          handleOnDeleteClick={handleOnDeleteClick}
          handleOnRejectionClick={handleOnRejectionClick}
        />
      )}
    </ElasticTableContextContainer>
  );
};

export default Body;
