import { CountryPaymentPurposesDto } from '@alpha/payments-dtos';
import { MenuItem, Select, Typography } from '@mui/material';
import { Input } from 'components/Inputs/Input';
import React, { memo, useEffect } from 'react';
import useStyles from '../../../../../../../../components/InterAccountTransfer/Dropdown/Dropdown.styles';
import useAlphaSnackbar from '../../../../../../../../hooks/useAlphaSnackbar';
import {
  TChosenPaymentPurpose,
  TPayment,
} from '../../../../../../../../models/payments';
import PaymentsService from '../../../../../../../../services/Payments/payments.service';

interface IProps {
  currentPayment: TPayment;
  countryCode: string;
  accountId: string;
  validation: string | undefined;
  value: string | undefined;
  referenceValidation: string | undefined;
  paymentPurposes: CountryPaymentPurposesDto | undefined;
  setPaymentPurposes: React.Dispatch<React.SetStateAction<CountryPaymentPurposesDto | undefined>>;
  setCurrentPayment: React.Dispatch<React.SetStateAction<TPayment>>;
  setPurposesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  handleInputChange: (event: any) => void;
}

const PaymentPurpose: React.FC<IProps> = ({
  countryCode,
  currentPayment,
  validation,
  value,
  referenceValidation,
  paymentPurposes,
  accountId,
  setPaymentPurposes,
  handleInputChange,
  setCurrentPayment,
  setPurposesLoaded,
}: IProps) => {
  const sb = useAlphaSnackbar();
  const dropdownStyles = useStyles();
  useEffect(() => {
    let isCancelled = false;
    getAllPurposeOfPayments(isCancelled);

    return () => {
      isCancelled = true;
    };
  }, [countryCode, accountId]);

  const getAllPurposeOfPayments = async (isCancelled: boolean) => {
    try {
      setPurposesLoaded(false);
      const paymentPurposesResponse = await PaymentsService.getPaymentPurpose(
        countryCode,
        accountId,
      );
      if (!isCancelled) {
        setPaymentPurposes(paymentPurposesResponse);
        setPurposesLoaded(true);
      }
    } catch (e) {
      sb.trigger(
        'There was an error retrieving payment purposes for this currency code',
      );
    }
  };

  const handleDropdownChange = (event: any) => {
    if (paymentPurposes?.purposes) {
      const selectedPaymentPurpose = paymentPurposes.purposes.find(
        (purpose) => purpose.code === event.target.value,
      );
      if (selectedPaymentPurpose) {
        const chosenPaymentPurpose: TChosenPaymentPurpose = {
          required: paymentPurposes.required,
          option: selectedPaymentPurpose,
        };
        setCurrentPayment({
          ...currentPayment,
          purposeOfPaymentCode: chosenPaymentPurpose.option.code,
        });
      }
    }
  };

  const generateMenuOptions = () => {
    if (paymentPurposes) {
      return paymentPurposes.purposes
        .map((purpose) => <MenuItem value={purpose.code}>{purpose.description}</MenuItem>);
    }
    return <></>;
  };

  return (
    <div className="column longInput">
      <div>
        <Typography variant="subtitle1" className="label">
          Purpose of Payment
        </Typography>
        {paymentPurposes?.canBeFreeText ? (
          <Input
            onChange={handleInputChange}
            id="purposeOfPaymentCode"
            variant="filled"
            value={value || ''}
            testId="purposeOfPayment-input"
            placeholder="Purpose of payment"
            error={Boolean(validation)}
            helperText={validation}
          />
        )
          : (
            <Select
              data-testid="purposeOfPayment-select"
              onChange={handleDropdownChange}
              value={
                currentPayment.purposeOfPaymentCode || 'placeholder'
              }
              fullWidth
              className={dropdownStyles.dropdown}
            >
              {[
                <MenuItem
                  disabled
                  value="placeholder"
                >
                  <Typography
                    variant="subtitle1"
                    className={dropdownStyles.placeholder}
                  >
                    {paymentPurposes && paymentPurposes.required
                      ? '(Required)'
                      : '(Optional)'}
                  </Typography>
                </MenuItem>,
                generateMenuOptions(),
              ]}
            </Select>
          )}
      </div>
      <div className="referenceField">
        <Typography variant="subtitle1" className="label">
          Reference
        </Typography>
        <Input
          onChange={handleInputChange}
          id="reference"
          variant="filled"
          value={currentPayment.reference}
          testId="reference-input"
          placeholder="Reference"
          error={Boolean(referenceValidation)}
          helperText={referenceValidation}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default memo(PaymentPurpose);
