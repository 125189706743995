import React from 'react';
import Header from '../../../../../components/AuthyComponent/Header';
import BackdropLoader from '../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import useAuthy from '../../../../../hooks/useAuthy';
import { actions, AuthyPageStates } from '../../../../../store/authy/reducer';
import Footer from './Footer';
import useStyles from './Form.styles';
import FormBody from './FormBody';

interface IForm {
  handleComplete: (code: number) => void;
}

const createSubHeaderText = (
  pageState: AuthyPageStates,
): JSX.Element | string => {
  switch (pageState) {
    case AuthyPageStates.AUTHY:
      return 'Please open the Authy app and enter the code to continue:';
    case AuthyPageStates.OPTIONS:
      return 'Please select which backup method you would like';
    case AuthyPageStates.SMS:
    default:
      return (
        <>
          Please enter the code from your chosen vertification method.
        </>
      );
  }
};
const Form: React.FC<IForm> = ({
  handleComplete,
}: IForm) => {
  const {
    reduxState, triggerMfa, dispatch,
  } = useAuthy();
  const { pageState, type, approvalRequestId } = reduxState;

  const classes = useStyles();
  if (!pageState) {
    return (
      <BackdropLoader
        testId="backdrop-loader"
        thickness={5}
        size={60}
        style={{ outline: 'none' }}
      />
    );
  }

  const handleSetPageState = (_pageState: AuthyPageStates) => {
    dispatch(actions.updateAuthyPageState(_pageState));
  };

  return (
    <div
      className={classes.padding}
      data-testid="children-wrapper"
    >
      <Header
        authySymbol={Boolean(pageState === AuthyPageStates.AUTHY)}
        classes={classes}
        headerText={approvalRequestId ? 'Authy 2FA Approval Required' : 'Two-factor Authentication'}
        subHeaderText={approvalRequestId ? '' : createSubHeaderText(pageState)}
      />
      <FormBody
        classes={classes}
        handleComplete={handleComplete}
      />
      <Footer
        type={type?.type}
        triggerMfa={triggerMfa}
        pageState={pageState}
        handleSetPageState={handleSetPageState}
        classes={classes}
      />
    </div>
  );
};

export default Form;
