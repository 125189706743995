import React, { FC, memo } from 'react';
import { Moment } from 'moment';
import { ToolbarProps } from '@mui/material';
import StyledCustomSideBar from './CustomSideBar.styles';

export const datepickerFormat = 'L';

interface ICustomToolbarProps extends ToolbarProps {
    date: Moment;
    rebranded?: boolean;
}

export const CustomSideBar: FC<ICustomToolbarProps> = memo((props: any) => (
  <StyledCustomSideBar>
    <span className="dateHeader">{props.date.format('YYYY')}</span>
    <span className="dateBody">
      {props.date.format('dddd').substring(0, 3)}
      ,
      {' '}
      {props.date.format('MMMM').substring(0, 3)}
      {' '}
      {props.date.format('DD')}
    </span>
  </StyledCustomSideBar>
));
