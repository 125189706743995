import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './ChoosePayment.styles';
import { FileUpload } from './FileUpload/FileUpload';
import routes from '../../../../routes.path';
import { TUserAccount } from '../../../../models/user';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import PaymentsService from '../../../../services/Payments/payments.service';

export const CHOOSE_PAYMENT_TEST_IDS = {
  choosePayment: 'ChoosePayment',
  fileUploadSection: 'FileUploadSection',
  manualSection: 'ManualSection',
};

export const ChoosePayment: React.FC = () => {
  const classes = useStyles();
  const [accounts, setAccounts] = useState<TUserAccount[]>([]);
  const snackbar = useAlphaSnackbar();

  useEffect(() => {
    async function getAllAccounts() {
      try {
        const accountOptions = await PaymentsService.getPaymentInputterEntities();
        setAccounts(accountOptions);
      } catch {
        snackbar.trigger('There was an error retrieving your accounts for your user');
      }
    }
    getAllAccounts();
  }, []);

  return (
    <div
      data-testid={CHOOSE_PAYMENT_TEST_IDS.choosePayment}
      className={clsx(classes.root, 'dd-privacy-allow')}
    >
      <div
        className={classes.manual}
        data-testid={CHOOSE_PAYMENT_TEST_IDS.manualSection}
      >
        <div className="manualContent">
          <div className="text">
            <Link to={routes.payments.multientity.manual.base}>
              <Typography variant="h6" className={classes.title}>
                Manual Input
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Input new payments one by one or transfer funds between currency accounts.
              </Typography>
            </Link>
          </div>
          <div className="caretRight">
            <Link to={routes.payments.multientity.manual.base} className={classes.button}>
              <FontAwesomeIcon size="lg" icon={faAngleRight} className={classes.buttonIcon} />
            </Link>
          </div>
        </div>
        <div className="orDivider">
          <span>Or</span>
        </div>
      </div>
      <FileUpload
        accounts={accounts}
      />
    </div>
  );
};

export default ChoosePayment;
