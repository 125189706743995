import React from 'react';
import Tooltip from '../../../../../../../../components/Tooltip/Tooltip';
import Warning from '../../../../../../../../assets/warning.svg';

interface IProps {
    errors: string[];
}

const ErrorRow: React.FC<IProps> = ({ errors }: IProps) => {
  if (!errors.length) return <></>;

  return (
    <Tooltip title={errors}>
      <img src={Warning} alt="Warning icon" width="24" />
    </Tooltip>
  );
};

export default ErrorRow;
