import {
  AccountWithApprovalConfig,
  UserRoleRequest,
  ApproverLevel,
  Roles,
  ErrorCodes,
} from '@portal/user-types';
import React from 'react';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { FormikValues } from 'formik';
import { AxiosError, AxiosResponse } from 'axios';
import { IWizardModalConfig } from '../../../components/Modals/Modal.interfaces';
import { WizardError } from '../../../models/userManagement';
import UserManagementService from '../../../services/UserManagement/userManagement.service';
import { formatErrorMessage, validationSchema } from '../../../utils/formikValidation';
import { defaultRoles, isApproverLevelRequired, validateApproverLevel } from '../helpers/permissions';
import CreateUserDetail, { fieldNames } from '../components/CreateUserDetail';
import { IEntityAccessModalValues } from './entityAccessModal.config';
import { INestedStep } from '../../../components/Modals/WizardModal/context/reducer';
import { IWizardParams } from '../context/index.interfaces';
import EntitiesSelector from '../components/EntitiesSelector';
import Permissions from '../components/Permissions';

const {
  firstName: { title: firstName },
  lastName: { title: lastName },
  mobile: { title: mobile },
  phone: { title: phone },
  email: { title: email },
} = fieldNames;

const generateStepTitle = (title: string, num = 0, tot = 0) => {
  if (tot && tot > 0) {
    return `${title} (${num + 1} of ${tot + 1})`;
  }
  return title;
};

interface ICreateUserModalPermissions {
  [key: string]: {
    roles: Array<Roles>,
    approverLevel: ApproverLevel,
    approvedSignatory: boolean,
  }
}

const createParams = async (
  values: IEntityAccessModalValues,
):Promise<UserRoleRequest[]> => {
  const roles: UserRoleRequest[] = [];
  values.ids.forEach((selectedEntity) => {
    const currentPermission = values.permissions[selectedEntity.id];
    if (currentPermission.roles) {
      const requiredApproverLevel = isApproverLevelRequired(currentPermission.roles);
      const approvalLevel = requiredApproverLevel && currentPermission.approverLevel
        ? currentPermission.approverLevel
        : null;
      roles.push({
        userId: null,
        entityId: selectedEntity.id,
        roles: Array.from(new Set(currentPermission.roles)),
        approvalLevel,
      });
    }
  });
  return roles;
};

const determineError = (e:AxiosError) => {
  const responseError = (e.response as AxiosResponse<any>)?.data.error[0];

  switch (responseError.code) {
    case ErrorCodes.USER_ALREADY_EXISTS: {
      return new WizardError({ message: responseError.message, step: 0, nested: 0 });
    }
    default:
      return e;
  }
};

const populatePermissions = async (params: {
    selectedIds: AccountWithApprovalConfig[];
    existingRoles: ICreateUserModalPermissions;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  }) => {
  const { selectedIds, existingRoles, setFieldValue } = params;

  const currentRoles: ICreateUserModalPermissions = {};
  const idsToPopulate = selectedIds.reduce((acc: Array<string>, { id }: { id: string }) => {
    if (id in existingRoles) {
      currentRoles[id] = existingRoles[id];
    } else {
      acc.push(id);
    }
    return acc;
  }, []);

  const newPermissions: Record<string, any> = {};
  if (idsToPopulate.length > 0) {
    idsToPopulate.forEach((id: string) => {
      newPermissions[id] = {
        roles: defaultRoles,
        isApprovedSignatory: false,
        approverLevel: '',
      };
    });
  }
  setFieldValue('permissions', { ...currentRoles, ...newPermissions });
};

const createNewUserModalConfig = (
  { entity, submitCallback = (callback: () => Promise<any>) => callback() }: IWizardParams,
): IWizardModalConfig<any> => ({
  name: '',
  title: <>Create New User</>,
  steps: [
    {
      title: () => generateStepTitle('Details'),
      nextButtonText: () => 'Next',
      component: <CreateUserDetail />,
      validationSchema: validationSchema({
        firstName: Yup.string()
          .max(40, formatErrorMessage.max(firstName, 40))
          .required(formatErrorMessage.required(firstName)),
        lastName: Yup.string()
          .max(80, formatErrorMessage.max(lastName, 80))
          .required(formatErrorMessage.required(lastName)),
        email: Yup.string()
          .email(formatErrorMessage.invalid(email))
          .required(formatErrorMessage.required(email)),
        mobile: Yup.string()
          .min(8, formatErrorMessage.min(mobile, 8))
          .max(16, formatErrorMessage.max(mobile, 16))
          .required(formatErrorMessage.required(mobile))
          .test('test-mobile', formatErrorMessage.invalid(mobile),
            (value) => {
              if (!value) return false;
              return isValidPhoneNumber(value);
            }),
        phone: Yup.string()
          .min(8, formatErrorMessage.min(phone, 8))
          .max(16, formatErrorMessage.max(phone, 16))
          .required(formatErrorMessage.required(phone))
          .test('test-direct', formatErrorMessage.invalid(phone),
            (value) => {
              if (!value) return false;
              return isValidPhoneNumber(value);
            }),
      }),
      handleNext: async ({
        values, handleNext, toggleLoading, handleError, setName,
      }) => {
        if (values && handleNext && toggleLoading && handleError && setName) {
          toggleLoading();

          try {
            const user = await UserManagementService.getUser({ id: values.email });
            if (user) {
              handleError(new WizardError({ message: 'User already exists, please update their permissions instead.', step: 0, nested: 0 }));
              toggleLoading();
              return;
            }
          } catch (error) {
            if (error.response && error.response.status !== 404) {
              handleError(new WizardError({ message: 'Network Error', step: 0, nested: 0 }));
              toggleLoading();
              return;
            }
          }

          try {
            setName(`${values.firstName} ${values.lastName}`);
            handleNext();
          } catch (error) {
            handleError(new WizardError({ message: 'Network Error', step: 0, nested: 0 }));
          }

          toggleLoading();
        }
      },
    },
    {
      title: () => generateStepTitle('Entities'),
      component: <EntitiesSelector />,
      validationSchema: validationSchema({
        ids: Yup.array().of(
          Yup.object({
            id: Yup.string().required(),
          }),
        ).min(1),
      }),
      handleNext: async ({
        values, setNestedStepsTotal, handleNext, setFieldValue, toggleLoading, handleError,
      }) => {
        if (values?.ids
            && values.ids.length > 0
            && setNestedStepsTotal
            && handleNext
            && setFieldValue
            && toggleLoading
            && handleError
        ) {
          toggleLoading();
          try {
            await populatePermissions({
              selectedIds: values.ids,
              existingRoles: values.permissions,
              setFieldValue,
            });
            handleNext?.();

            setNestedStepsTotal?.(values.ids.length - 1, 2);
          } catch (e) {
            const error = new WizardError({ message: 'Network Error', step: 0, nested: 0 });
            handleError(error);
          }
          toggleLoading();
        }
      },
    },
    {
      title: (num?: number, total?: number) => generateStepTitle('Permissions', num, total),
      nextButtonText: () => 'Next',
      component: <Permissions />,
      validationSchema: validationSchema({}),
    },
  ],
  submitButtonText: 'Submit For Approval',
  initialValues: {
    ids: entity ? [entity] : [],
    permissions: {},
  },
  validate: (
    values: FormikValues, step: number, nestedSteps: INestedStep[],
  ) => validateApproverLevel(values, step, nestedSteps, 2),
  submitForm: async (values) => {
    const { ids, permissions, ...user } = values;
    const userRoleRequest = await createParams({ ids, permissions });

    if (userRoleRequest.length > 0) {
      const request = () => UserManagementService.createUserRoleRequest({
        user: { ...user },
        userRoleRequest,
      });

      try {
        await submitCallback(request);
      } catch (e) {
        throw determineError((e as AxiosError));
      }
      return { message: 'Request submitted for approval', type: 'success' };
    }
    return { message: 'No changes detected', type: 'info' };
  },
});

export default createNewUserModalConfig;
