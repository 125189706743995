import React from 'react';
import { Box, Button } from '@mui/material';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import { ActionButton, GhostButton } from 'components/Buttons';
import FooterWrapper from '../../../../../../components/Payments/FooterWrapper/FooterWrapper';
import useAuthorization from '../../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../../models/user';
import useStyles from './BookFxFooter.styles';

interface IProps {
  batchName: string;
  modalOpen: boolean;
  handleClose: () => void;
  handleBookFxClick: () => void;
  handleClick: () => void;
  handleRejectPayment: () => Promise<void>;
  handleRejectBatchButton: () => void;
}

const BookFxFooter: React.FC<IProps> = ({
  batchName,
  modalOpen,
  handleClose,
  handleClick,
  handleBookFxClick,
  handleRejectPayment,
  handleRejectBatchButton,
}: IProps) => {
  const styles = useStyles();
  const fxBooker = useAuthorization([[UserRole.FX_IAT]]);
  const currentUserIsApprover = useAuthorization([
    [UserRole.PAYMENTS_APPROVER],
  ]);
  return (
    <>
      <FooterWrapper>
        <Button
          onClick={handleClick}
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          Exit
        </Button>
        <Box>
          <>
            {currentUserIsApprover?.authorized && (
              <GhostButton
                className={styles.errorButton}
                onClick={handleRejectBatchButton}
                colorVariant="warning"
                data-testid="reject-batch-button"
              >
                REJECT BATCH
              </GhostButton>
            )}
          </>
          <>
            {fxBooker?.authorized && (
              <ActionButton size="large" onClick={handleBookFxClick}>
                BOOK FX
              </ActionButton>
            )}
          </>
        </Box>
      </FooterWrapper>
      <DecisionModal
        title={`Reject ${batchName?.length > 35 ? ' Batch' : `${batchName}`}`}
        open={modalOpen}
        subtitle="You're about to reject this batch.
        The payment will not be processed."
        actionButtonText="YES, REJECT BATCH"
        handleActionButton={handleRejectPayment}
        handleClose={handleClose}
        icon={faExclamationTriangle}
      />
    </>
  );
};

export default BookFxFooter;
