import { DateTime } from 'luxon';

const formatIsoDateTime = (date: string | undefined, format: string = 'dd/MM/yyyy HH:mm', zone: string = 'Europe/London') => {
  if (!date) return '-';

  const dateTime = DateTime.fromISO(date, { zone });
  if (dateTime.isValid) {
    return dateTime.toFormat(format);
  }

  return '-';
};

export default formatIsoDateTime;
