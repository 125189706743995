import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    bodyWrapper: {
      overflowX: 'hidden',
      padding: 30,
    },
    paginationWrapper: {
      display: 'block',
      textAlign: 'right',

    },
    noOfAccounts: {
      fontSize: 14,
      color: 'rgba(51, 51, 51, 0.93)',
      paddingBottom: 20,
    },
  }), { name: 'SelectBeneDrawerBody' },
);

export default useStyles;
