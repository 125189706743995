import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: string = '';

export const slice = createSlice({
  initialState,
  name: 'initialPath',
  reducers: {
    updateInitialPath: (
      state, action: PayloadAction<string>,
    ): string => action.payload,
  },
});

export const { actions } = slice;
