/* eslint-disable max-lines-per-function */
import { BatchQuoteDto } from '@alpha/payments-dtos';
import { Loader } from 'components/Loader';
import { StyledDivider } from 'components/StyledDivider';
import React, { memo } from 'react';
import { DualFlag } from 'components/Flag';
import FundingInput, { InputType } from '../../../../components/Inputs/Funding/FundingInput';
import formatIsoDate from '../../../../utils/formatIsoDate';
import { testIds } from '../Funding';
import { IBatchCurrencyPairRateDecorated } from '../Funding.interface';
import { useStyles } from './FxPayments.styles';
import IndicativeLive from './IndicativeLive/IndicativeLive';

interface IProps {
  loading: boolean;
  loadingLiveRate: boolean;
  displayPairs: IBatchCurrencyPairRateDecorated[];
  liveRates: BatchQuoteDto[];
  isStandingOrder?: boolean;
}

const FxPayments: React.FC<IProps> = (props: IProps) => {
  const {
    loading, displayPairs, liveRates, loadingLiveRate, isStandingOrder,
  } = props;
  const classes = useStyles({ liveRates: liveRates.length > 0 });
  return (
    <div className={classes.fxPayments}>
      {loading
        ? <Loader testId={testIds.loading} className="pageloadLoader" />
        : (
          <div>
            {(displayPairs as any[]).map(
              (currencyPair: IBatchCurrencyPairRateDecorated, index: number): JSX.Element => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${currencyPair.sellCurrency}/${currencyPair.buyCurrency}-${index}`}
                  className={classes.fxPayment}
                >
                  <div className={classes.paymentLeft}>
                    <div className={classes.currency}>
                      <DualFlag
                        startFlag={{
                          code: currencyPair.sellCurrency,
                          rounded: true,
                          size: 47,
                          showCodeLabel: true,
                          locked: currencyPair.sellCurrency === currencyPair.fixedSide,
                        }}
                        endFlag={{
                          code: currencyPair.buyCurrency,
                          rounded: true,
                          size: 47,
                          showCodeLabel: true,
                          locked: currencyPair.buyCurrency === currencyPair.fixedSide,
                        }}
                        arrowHeight={11}
                        arrowWidth={10}
                      />
                    </div>
                    {!isStandingOrder && (
                      <>
                        <StyledDivider vertical />
                        <div className={classes.paymentStat}>
                          <p>Payments</p>
                          <p>{currencyPair.noOfPayments}</p>
                        </div>
                        <StyledDivider vertical />
                        <div className={classes.paymentStat}>
                          <p>Value Date</p>
                          <p>
                            {formatIsoDate(currencyPair.valueDate as any)}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <div className={classes.paymentRight}>
                    <FundingInput
                      type={InputType.Debit}
                      currencyPair={currencyPair}
                    />
                    <IndicativeLive
                      currencyPair={currencyPair}
                      liveRates={liveRates}
                      loadingLiveRate={loadingLiveRate}
                    />
                    <FundingInput
                      type={InputType.Credit}
                      currencyPair={currencyPair}
                    />
                  </div>
                </div>
              ),
            )}
          </div>
        )}
    </div>
  );
};

export default memo(FxPayments);
