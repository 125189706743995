import theme, { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  headerWrapper: {
    top: 0,
    left: 0,
    width: '100%',
    padding: '60px 30px 30px 50px',
    borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
  },
  headerTitle: {
    '& > h2': {
      lineHeight: '30px',
      fontSize: '22px',
      fontWeight: '400',
      letterSpacing: '-0.5px',
    },
    display: 'flex',
    alignItems: 'center',
  },

  headerSubtitle: {
    width: '120px',
    padding: '30px 0 12px 0',
    borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    '& > h6': {
      fontSize: 13,
      fontWeight: 600,
      color: '#999999',
    },
  },
  headerSubTitle2: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    paddingTop: '12px',
    '& > p': {
      fontSize: 39,
      fontWeight: 'lighter',
      color: theme.palette?.primary?.main,
    },
    '& > button': {
      marginLeft: 'auto',
    },
  },
  noOfEntities: {
    fontSize: 39,
    fontWeight: 'lighter',
    color: theme.palette?.primary?.main,
  },
  groupColourIcon: {
    fontSize: '23px',
    marginRight: '14px',
    color: ({ groupColour }: { groupColour?: string }) => (
      groupColour === 'DEFAULT' ? theme.palette?.primary?.main : groupColour
    ),
  },
  accountsDropdownContainer: {
    width: '24.5em',
    '& > .MuiInputBase-root': {
      backgroundColor: '#F7F7F7',
      borderRadius: '5px',
      paddingTop: '0px !important',
    },
  },
  accountsDropdown: {
    padding: '0',
    width: '250px',
    height: '40px',
    borderRadius: '5px',
  },
  drawerGoBackButton: {
    height: '30px',
    width: '60px',
    marginRight: '14px',
    color: '#4B4A4A',
    opacity: 0.3,
  },
  bodyWrapper: {
    overflowX: 'hidden',
    backgroundColor: '#FAFAFA',
    height: '100%',
    paddingTop: '24px',
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 15px 0 15px',
  },
  accountName: {
    fontSize: '19px',
    textAlign: 'left',
    fontWeight: 600,
    color: theme.palette?.primary?.main,
    marginRight: 'auto',
  },

  addAccountCardTitle: {
    fontSize: '16px',
    margin: ' 0px 0px 4px 2px',
    textAlign: 'left',
    fontWeight: 'lighter',
    color: 'black',
  },

  actionButtons: {
    display: 'flex',
    paddingTop: '8px',
    '& > button': {
      marginLeft: '8px',
      height: '30px',
      width: '48px',
      backgroundColor: 'white',
    },
    marginLeft: 'auto',

  },
  cardWrapper: {
    padding: '20px 36px',
    boxShadow: '0px 3px 6px #00000008',
    display: 'flex',
    height: '111px',
    backgroundColor: 'white',
    flexDirection: 'row',
    width: '84%',
    margin: 'auto',
    alignItems: 'center',
    gap: 10,
  },
  addAccountCardWrapper: {
    padding: '16px 16px 16px 20px',
    boxShadow: '0px 3px 6px #00000008',
    display: 'flex',
    height: '111px',
    width: '84%',
    margin: '0px auto 16px auto',
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  editGroupNameButton: {
    marginLeft: '16px',
    color: 'black',
    opacity: 0.3,
    '&:hover, &focus': {
      cursor: 'pointer',
    },
  },
  editGroupNameAccept: {
    marginLeft: '25px',
    color: 'black',
    opacity: 0.3,
    '&:hover, &focus': {
      cursor: 'pointer',
    },
  },
  editGroupNameDecline: {
    marginLeft: '17px',
    color: 'black',
    opacity: 0.3,
    '&:hover, &focus': {
      cursor: 'pointer',
    },
  },
  entityGroupNameInput: {
    '& > .MuiInputBase-input': {
      height: '30px',
      lineHeight: '30px',
      fontSize: '22px',
      fontWeight: '400',
      letterSpacing: '-0.5px',
      padding: '0 0 0 0',
    },
    display: 'flex',
    alignItems: 'center',
  },
  deleteEntityButton: {
    alignSelf: 'right',
    backgroundColor: 'white',
    height: '30px',
    width: '48px',
    margin: '2px',
    '&:hover, &focus': {
      backgroundColor: 'rgba(131, 9, 9, 0.2)',
      color: 'rgb(131, 9, 9)',
    },
  },
}), { name: 'EntityGroupInfo' });

export default useStyles;
