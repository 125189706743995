export enum ChallengeName {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  SMS_MFA = 'SMS_MFA',
  MFA_SETUP = 'MFA_SETUP',
  CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE',
}

type TChallengeParam = {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;
  CODE_DELIVERY_DESTINATION: string;
  CODE_DELIVERY_ERROR?: string;
  CHALLENGE_STEP?: 'METHOD_REQUEST' | 'CODE_REQUEST' | 'CODE_ENTRY';
};

export enum TMethodRequestTypes {
  METHOD_SMS = 'METHOD_SMS',
  METHOD_AUTHY = 'METHOD_AUTHY',
  METHOD_CALL = 'METHOD_CALL'
}

export type TSignIn = {
  challengeName: ChallengeName;
  challengeParam: TChallengeParam;
  session: string;
  username: string;
};

export type TSendTotp = {
  message: string,
  method: string,
}
