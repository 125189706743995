import axios, { AxiosRequestConfig } from 'axios';
import { PaymentBatchesResponse } from '@alpha/payments-types';
import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import SearchTable from '../../../../components/Table/SearchTable';
import { TextEllipsis } from '../../../../components/TextEllipsis';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import PaymentsService from '../../../../services/Payments/payments.service';
import formatIsoDate from '../../../../utils/formatIsoDate';
import { SearchPaymentsTabProps } from '../../usePaymentBatchSearch';
import PaymentsType from '../PaymentsType/PaymentsType';
import ActionDropDown from './DisplayTable/ActionDropDown/ActionDropDown';

import ApprovalStatus from './DisplayTable/ApprovalStatus/ApprovalStatus';
import NoOfApprovers from './DisplayTable/NoOfApprovers/NoOfApprovers';
import useStyles from './PendingTable.styles';

const PendingTable: React.FC<SearchPaymentsTabProps> = (props:SearchPaymentsTabProps) => {
  const [inputterEntities, setInputterEntities] = useState<string[]>([]);
  const classes = useStyles();

  const getPaymentInputterEntities = async (cancelToken: AxiosRequestConfig['cancelToken']) => PaymentsService.getPaymentInputterEntities({ cancelToken });

  useEffect(() => {
    let cancel = () => {};
    const cancelToken = new axios.CancelToken((canceler) => {
      cancel = canceler;
    });

    /**
     * @todo: Something is causing this component to unmount during this effect,
     * until that has been understood and fixed we need to cancel the axios request in the effect
     * cleanup handler. See https://alphafx.atlassian.net/browse/ABSP-4499
     *
     * Additionally:
     * 1. The cancelToken approach is deprecated so we should upgrade Axios to at least v0.22.0
     *    See: https://github.com/axios/axios#canceltoken-deprecated
     * 2. The empty .catch(() => {}) prevents a further error with an unhandled Promise rejection
     */
    getPaymentInputterEntities(cancelToken)
      .then((accounts) => setInputterEntities(accounts.map(({ id }) => id)))
      .catch(() => {});

    return () => cancel();
  }, []);

  const {
    items,
    hasNext,
    hasPrevious,
    handleNextPage,
    handlePreviousPage,
    loading,
    handleNewSearch,
  } = props;

  const columns: ITableColumn[] = [
    {
      header: (
        <Box paddingRight="80px" fontWeight="bold">
          ID
        </Box>
      ),
      accessor: 'id',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Entity</Box>,
      accessor: 'entity',
      className: clsx([classes.textAlignLeft, 'dd-privacy-allow']),
    },
    {
      header: <Box>Type</Box>,
      accessor: 'type',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.payments}>No. of Payments</Box>,
      accessor: 'noOfPayments',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Uploaded by</Box>,
      accessor: 'uploadedBy',
    },
    {
      header: (
        <Box className={classes.textAlignLeft}>
          uploaded date
        </Box>
      ),
      accessor: 'uploadedDate',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.noOfApprovers}>no. of approvers</Box>,
      accessor: 'noOfApprovers',
    },
    {
      header: <Box className={classes.approval}>approval status</Box>,
      accessor: 'approvalStatus',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.action}>Action</Box>,
      accessor: 'action',
      className: 'dd-privacy-allow',
    },
  ];
  const mappedData = items?.records
    && (items.records as PaymentBatchesResponse[]).map((item) => {
      const container: any = {};
      container.id = <b>{item.friendlyBatchId || ''}</b>;
      container.entity = (
        <TextEllipsis text={item.accountName} className={classes.spaceRight} />
      );
      container.type = <PaymentsType type={item.type} />;
      container.noOfPayments = (
        <Box className={classes.payments}>{item.noOfValidatedPayments || ''}</Box>
      );
      container.uploadedBy = (
        <Box className={classes.textAlignLeft} maxWidth="100px">
          <span>{item.uploadedBy || ''}</span>
        </Box>
      );
      container.uploadedDate = (
        <Box className={classes.textAlignLeft}>
          {item.uploadedDate ? formatIsoDate(item.uploadedDate) : ''}
        </Box>
      );
      container.noOfApprovers = (
        <NoOfApprovers
          uploadedBy={item.uploadedBy}
          uploadedDate={item.uploadedDate}
          noOfApprovals={item.approvalRequirement?.approvals?.length}
          approvedBy={item.approvalRequirement?.approvals || []}
          approvalsRequired={item.approvalRequirement?.approvalsRequired?.reduce(
            (sum, current) => sum + current.number, 0,
          )}
        />

      );
      container.approvalStatus = (
        <ApprovalStatus
          requiresFx={item.requiresFx || false}
          batchStatus={item.batchStatus}
        />
      );
      container.action = (
        <ActionDropDown
          refreshTable={handleNewSearch}
          payment={item}
          status={item.batchStatus}
          userCanApprove={item.userCanApprove || false}
          batchId={item.batchId}
          accountId={item.accountId}
          userIsInputter={inputterEntities.includes(item.accountId)}
        />
      );
      return container;
    });
  return (
    <SearchTable
      table={{
        columns,
        data: mappedData || [],
      }}
      loading={loading}
      pagination={{
        handleNext: () => handleNextPage(),
        handlePrevious: () => handlePreviousPage(),
        hasNext: hasNext || false,
        hasPrevious: hasPrevious || false,
      }}
      emptyTable={{
        title: 'No Pending Payments',
        subtitle: 'You currently do not have any pending payment batches',
      }}
    />
  );
};

export default PendingTable;
