import { ActionButton } from 'components/Buttons';
import { Button, Typography } from '@mui/material';
import React from 'react';
import Modal from 'components/Modals/Modal';
import useStyles from './ClearDebitingModal.styles';
import WarningSvg from '../../../../../../../assets/warning.svg';

interface IProps {
  open: boolean;
  handleModalOpen: (open: boolean) => void;
  handleSubmitButton: () => void;
}

const ClearDebitingModal: React.FC<IProps> = ({
  open,
  handleModalOpen,
  handleSubmitButton,
}: IProps) => {
  const styles = useStyles();
  return (
    <Modal open={open} onClose={() => handleModalOpen(false)}>
      <div className={styles.root}>
        <div className="wrapper">
          <div className="title">
            <img src={WarningSvg} alt="Warning Sign" />
            <Typography variant="h5">Change Debiting Account</Typography>
          </div>
          <Typography variant="subtitle1" className="bodyText">
            Please note, your batch will be cleared if you change the debiting
            account
          </Typography>
          <div className="footer">
            <Button onClick={() => handleModalOpen(false)}>No, go back</Button>
            <ActionButton variant="text" onClick={handleSubmitButton}>Yes, change account</ActionButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClearDebitingModal;
