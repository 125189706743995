import { Box, Typography } from '@mui/material';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { IconActionButton } from 'components/Buttons';
import { TUserAccount } from '../../../../models/user';
import useStyles from './CreateEntityGroup.style';

interface IProps {
  account: TUserAccount;
  removeAccount: (account: TUserAccount) => void;
}

const AccountInNewGroupCard: React.FC<IProps> = (props: IProps) => {
  const { account, removeAccount } = props;
  const classes = useStyles();
  const handleRemoveAccountClick = () => removeAccount(account);
  return (
    <Box
      data-testid={`account-in-new-group-card-${account.name}`}
      className={classes.cardWrapper}
    >
      <Box className={classes.accountName}>
        <Typography variant="subtitle1" className={classes.accountName}>
          {account?.name}
        </Typography>
      </Box>
      <Box className={classes.actionButtons}>
        <IconActionButton
          className={classes.deleteEntityButton}
          icon={faTrash}
          rounded
          variant="outlined"
          onClick={handleRemoveAccountClick}
        />
      </Box>
    </Box>
  );
};
export default AccountInNewGroupCard;
