import { IWizardModalConfig } from '../../Modal.interfaces';

export enum Actions {
  INCREMENT_STEP = 'INCREMENT_STEP',
  DECREMENT_STEP = 'DECREMENT_STEP',
  SET_NESTED_STEP = 'SET_NESTED_STEP',
  INCREMENT_NESTED_STEP = 'INCREMENT_NESTED_STEP',
  DECREMENT_NESTED_STEP = 'DECREMENT_NESTED_STEP',
  SET_STEPS = 'SET_STEPS',
  SET_ERROR = 'SET_ERROR',
  TOGGLE_LOADING = 'TOGGLE_LOADING',
  SET_NAME = 'SET_NAME'
}

export interface INestedStep {
  total: number,
  current: number,
}

export interface IState {
  name: string;
  step: number;
  nestedSteps: Array<INestedStep>;
  error: number | false;
  loading: boolean;
}

type IAction =
  | { type: Actions.INCREMENT_STEP }
  | { type: Actions.DECREMENT_STEP }
  | { type: Actions.INCREMENT_NESTED_STEP }
  | { type: Actions.DECREMENT_NESTED_STEP }
  | { type: Actions.TOGGLE_LOADING }
  | { type: Actions.SET_NAME, name: string }
  | {
    type: Actions.SET_NESTED_STEP,
    total: number,
    step: number,
  } | { type: Actions.SET_STEPS, step: number, nestedSteps: number}
  | { type: Actions.SET_ERROR, error: number | false }

export const createInitialState = <T, >(config: IWizardModalConfig<T>): IState => {
  const nestedSteps = config.steps.map(
    (obj: any) => obj.nestedSteps || { total: 0, current: 0 },
  );
  return {
    name: config.name,
    step: 0,
    nestedSteps,
    error: false,
    loading: false,
  };
};

export const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case Actions.INCREMENT_STEP:
      return { ...state, step: state.step + 1 };

    case Actions.DECREMENT_STEP:
      return { ...state, step: state.step - 1 };

    case Actions.SET_NESTED_STEP: {
      const setNested = [...state.nestedSteps];
      const setNestedStep = { ...setNested[action.step] };

      setNestedStep.total = action.total;
      setNested[action.step] = setNestedStep;

      return { ...state, nestedSteps: setNested };
    }
    case Actions.TOGGLE_LOADING: {
      return { ...state, loading: !state.loading };
    }
    case Actions.SET_NAME: {
      return { ...state, name: action.name };
    }
    case Actions.SET_ERROR: {
      return { ...state, error: action.error };
    }
    case Actions.SET_STEPS: {
      const setNested = [...state.nestedSteps];
      const setNestedStep = { ...setNested[action.step] };
      setNestedStep.current = action.nestedSteps;
      setNested[state.step] = setNestedStep;
      return { ...state, step: action.step, nestedSteps: setNested };
    }
    case Actions.INCREMENT_NESTED_STEP:
    case Actions.DECREMENT_NESTED_STEP: {
      const newNested = [...state.nestedSteps];
      const newNestedStep = { ...newNested[state.step] };

      if (action.type === Actions.INCREMENT_NESTED_STEP) {
        newNestedStep.current += 1;
      } else {
        newNestedStep.current -= 1;
      }

      newNested[state.step] = newNestedStep;
      return {
        ...state,
        nestedSteps: newNested,
      };
    }
    default:
      throw new Error();
  }
};
