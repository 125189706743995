import React, { useState, memo, useEffect } from 'react';
import { FormikProps } from 'formik';
import { Typography } from '@mui/material';
import { ValidateBeneficiaryRequest } from '@alpha/bene-dtos';
import { Checkbox } from 'components/Checkbox';
import InputAndLabel from '../../../../../components/Inputs/Beneficiary/InputAndLabel';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import useStyles from './styles';

interface IProps {
  form: FormikProps<ValidateBeneficiaryRequest>;
  error?: string;
}

const ExtraBankDetails: React.FC<IProps> = ({ form, error }: IProps) => {
  const styles = useStyles();
  const [checked, setChecked] = useState<boolean>(false);
  const [hasError, setError] = useState<boolean>(error?.trim().toLowerCase() === 'invalid swift code');

  useEffect(() => {
    setError(!form.isSubmitting && error?.trim().toLowerCase() === 'invalid swift code');
  }, [error, form.isSubmitting]);

  useEffect(() => { setError(false); }, [form.values]);

  return (
    <div>
      <Checkbox
        onChange={() => setChecked(!checked)}
        testId="testId"
        checked={checked}
        label={<Typography variant="subtitle1">Does this beneficiary have correspondent bank details?</Typography>}
      />
      {checked
        && (
          <div className={styles.extraInformationWrapper}>
            <InputAndLabel
              label="Correspondent Account Number"
              name="correspondentAccountNumber"
              id="correspondentAccountNumber"
              testId="correspondentAccountNumber"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.correspondentAccountNumber}
              error={
                form.touched.correspondentAccountNumber
                && Boolean(form.errors.correspondentAccountNumber)
              }
              helperText={
                form.touched.correspondentAccountNumber
                && form.errors.correspondentAccountNumber
              }
            />
            <Tooltip
              title={`Our validation check has found that the correspondent SWIFT you have provided is associated to your beneficiary 
              ${form.values.iban?.length ? 'IBAN' : 'account number'}. Please ensure the SWIFT code you are providing relates to 
              the correspondent bank and not your beneficiary.`}
              placement="bottom-end"
              open={hasError}
            >
              <div>
                <InputAndLabel
                  label="Correspondent SWIFT"
                  name="correspondentSwift"
                  id="correspondentSwift"
                  testId="correspondentSwift"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.correspondentSwift}
                  error={
                    form.touched.correspondentSwift
                    && Boolean(form.errors.correspondentSwift)
                  }
                  helperText={
                    form.touched.correspondentSwift
                    && form.errors.correspondentSwift
                  }
                />
              </div>
            </Tooltip>
            <InputAndLabel
              label="Further To Account Number"
              name="furtherToAccountNumber"
              id="furtherToAccountNumber"
              testId="furtherToAccountNumber"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.furtherToAccountNumber}
              error={
                form.touched.furtherToAccountNumber
                && Boolean(form.errors.furtherToAccountNumber)
              }
              helperText={
                form.touched.furtherToAccountNumber
                && form.errors.furtherToAccountNumber
              }
            />
            <InputAndLabel
              label="Further To SWIFT"
              name="furtherToSwift"
              id="furtherToSwift"
              testId="furtherToSwift"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.furtherToSwift}
              error={
                form.touched.furtherToSwift
                && Boolean(form.errors.furtherToSwift)
              }
              helperText={
                form.touched.furtherToSwift
                && form.errors.furtherToSwift
              }
            />
          </div>
        )}
    </div>
  );
};

export default memo(ExtraBankDetails);
