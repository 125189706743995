import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    beneDetails: {
      margin: '0 0 40px 0',
    },
  }),
  { name: 'Details' },
);
