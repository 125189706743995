import { Button, Typography } from '@mui/material';
import React from 'react';
import { GhostButton } from 'components/Buttons';
import APModal from '../APModal/APModal';
import { IDecisionModalProps } from './DecisionModal.interfaces';
import {
  StyledAPModalContainer,
  StyledBody,
  StyledButtonContainer,
  StyledHeader,
  StyledIcon,
  StyledLoader,
} from './DecisionModal.styles';

const DecisionModal: React.FC<IDecisionModalProps> = (props: IDecisionModalProps) => {
  const {
    open,
    title,
    subtitle,
    optionalSubtitle1,
    optionalSubtitle2,
    actionButtonText,
    closeButtonText,
    loading,
    icon,
    iconStyle,
    handleClose,
    handleActionButton,
  } = props;

  return (
    <StyledAPModalContainer open={open} onClose={handleClose}>
      <APModal.Wrapper>
        <StyledHeader>
          <StyledIcon size="lg" icon={icon} className={iconStyle} />
          <Typography variant="h2">{title}</Typography>
        </StyledHeader>
        <StyledBody>
          <Typography variant="subtitle1" className="pb-2">
            {subtitle}
          </Typography>
          {optionalSubtitle1 && (
            <Typography className="errorMessage" variant="subtitle1">
              {optionalSubtitle1}
            </Typography>
          )}
          {optionalSubtitle2 && <Typography variant="subtitle1">{optionalSubtitle2}</Typography>}
        </StyledBody>
        <StyledButtonContainer>
          {loading && <StyledLoader testId="decision-modal-test-id" />}

          <Button color="secondary" disabled={loading} className="closeButton" onClick={handleClose}>
            {closeButtonText}
          </Button>
          <GhostButton
            disabled={loading}
            testId="modal-action-button"
            colorVariant="warning"
            onClick={handleActionButton}
            className="rejectButton"
          >
            {actionButtonText}
          </GhostButton>
        </StyledButtonContainer>
      </APModal.Wrapper>
    </StyledAPModalContainer>
  );
};

export default DecisionModal;
