import { ApproverInfo } from '@alpha/payments-dtos';
import Status from 'components/Status/Status';
import React from 'react';
import Tooltip from '../../../../../../components/Tooltip/Tooltip';
import useStyles from '../../PendingTable.styles';
import ApproverContent from './ApproverContent/ApproverContent';

interface IProps {
  noOfApprovals?: number;
  approvalsRequired?: number;
  uploadedDate?: string;
  uploadedBy?: string;
  approvedBy?: ApproverInfo[];
}

const statusMessage = (noOfApprovals? :number, approvalsRequired?: number) : string => {
  if (approvalsRequired) {
    if (noOfApprovals) {
      return `${noOfApprovals}/${approvalsRequired}`;
    }
    return `0/${approvalsRequired}`;
  }
  return 'N/A';
};

const NoOfApprovers: React.FC<IProps> = (props: IProps) => {
  const {
    noOfApprovals, approvalsRequired, uploadedBy, uploadedDate, approvedBy,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.noOfApprovers}>
      <Tooltip
        data-testid="no-of-approvals"
        title={
            uploadedBy
              && uploadedDate
              && approvedBy
              ? (
                <ApproverContent
                  uploadedBy={uploadedBy}
                  uploadedDate={uploadedDate}
                  approvedBy={approvedBy}
                />
              ) : 'Unable to retrieve this information'
          }
      >
        <div>
          <Status
            variant={approvalsRequired && noOfApprovals === approvalsRequired ? 'success' : 'default'}
          >
            {statusMessage(noOfApprovals, approvalsRequired)}
          </Status>
        </div>
      </Tooltip>
    </div>
  );
};

export default NoOfApprovers;
