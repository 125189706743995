import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section: {
    width: '47%',
    position: 'relative',
  },
  overlayMaxWidth: {
    maxWidth: '1200px',
  },
  icon: {
    color: 'rgb(255, 199, 0)',
    marginRight: 10,
    alignSelf: 'center',
  },
}), { name: 'CreateBeneficiaryDrawer' });

export default useStyles;
