import React, { useEffect } from 'react';
import useStyles from './Body.styles';
import Footer from './Footer/Footer';
import FundingAccountContainer from './FundingAccount/FundingAccountContainer';
import BackdropLoader from '../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import useManualPaymentMultiEntity from '../../../../hooks/useManualPaymentMultiEntity';
import ManualPayment from './ManualPayment/ManualPayment';
import PaymentsList from './BatchPayments/PaymentsList/PaymentsList';
import { ManualPaymentsContext, SinglePaymentContext } from '../../PaymentContext';

const Body: React.FC = () => {
  const styles = useStyles();
  const {
    currentPayment,
    open,
    isValid,
    currentPayments,
    currencyAccounts,
    selectedFundingAccount,
    editRow,
    location,
    authyStore,
    paymentsStore,
    loading,
    setLoading,
    setSelectedFundingAccount,
    setEditRow,
    setIsValid,
    setCurrentPayment,
    setCurrentPayments,
    handleSetCurrentPayments,
    handleBeneficiaryDrawerToggle,
    parseAuthyStatus,
    parseBatchStatusUpdates,
    handleSelectEntity,
    inputterEntities,
    selectedEntity,
    currencyAccountsLoading,
    isStandingOrder,
    setIsStandingOrder,
  } = useManualPaymentMultiEntity();

  useEffect(() => {
    setCurrentPayments([]);
  }, [location.pathname]);

  useEffect(() => {
    parseBatchStatusUpdates();
  }, [paymentsStore.batchDetails?.batchStatus]);

  useEffect(() => {
    parseAuthyStatus();
  }, [authyStore.status]);

  return (
    <ManualPaymentsContext.Provider
      value={{
        currentPayments,
        currencyAccounts,
        fundingAccount: selectedFundingAccount,
        handleSetCurrentPayments,
        isStandingOrder,
        setIsStandingOrder,
      }}
    >
      <SinglePaymentContext.Provider
        value={{
          editRow,
          open,
          currentPayment,
          isValid,
          setEditRow,
          setIsValid,
          setCurrentPayment,
          handleBeneficiaryDrawerToggle,
        }}
      >
        <div className={styles.root}>
          <FundingAccountContainer
            currencyAccountsLoading={currencyAccountsLoading}
            inputterEntities={inputterEntities || []}
            selectedEntity={selectedEntity}
            handleSetSelectedEntity={handleSelectEntity}
            currencyAccounts={currencyAccounts}
            selectedFundingAccount={selectedFundingAccount}
            setSelectedFundingAccount={setSelectedFundingAccount}
          />
          {selectedFundingAccount && selectedEntity && (
          <>
            <ManualPayment
              accountName={selectedEntity.name}
              accountId={selectedEntity.id}
              selectedFundingAccount={selectedFundingAccount}
              allowSepaPaymentRail={selectedEntity.sepaOnPortal}
            />
            <PaymentsList />
          </>
          )}
        </div>
        <Footer
          accountId={selectedEntity?.id}
          setLoading={setLoading}
          currentPayment={currentPayment}
          currentPayments={currentPayments}
          selectedDebitingAccount={selectedFundingAccount}
        />
      </SinglePaymentContext.Provider>

      {loading
        && <BackdropLoader testId="loading" withOverlay positionFixed />}
    </ManualPaymentsContext.Provider>
  );
};

export default Body;
