/* eslint-disable no-nested-ternary */
import clsx from 'clsx';
import React, { FC } from 'react';
import accounting from 'accounting';
import { StyledKeyValueTableRow, StyledTable } from 'components/Table/StyledTable/StyledTable.styles';
import {
  TableCell, TableBody,
} from '@mui/material';
import { IStyledKeyValueTableProps, TKeyValue } from '../Table.interfaces';
import { StyledHeader } from './KeyValueTable.styles';

const generateRecordType = (record) => {
  if (typeof record.value === 'string' || React.isValidElement(record.value)) {
    return record.value;
  } if (typeof record.value === 'number') {
    return `${accounting.format(record.value, 2)} ${record.currency!}`;
  }
  return '-';
};

const KeyValueTable: FC<IStyledKeyValueTableProps> = (props) => {
  const {
    title, data, testId, obscure,
  } = props;

  return (
    <div data-testid={testId}>
      {title && (
        <StyledHeader>
          <span>{title}</span>
        </StyledHeader>
      )}
      <StyledTable props={props}>
        <TableBody>
          {data.map((record: TKeyValue, i) => (
            <StyledKeyValueTableRow key={i}>
              <TableCell className="dd-privacy-allow">{record.key}</TableCell>
              <TableCell
                className={clsx(
                  record.boldValue === true ? 'bold' : '',
                  obscure ? '' : 'dd-privacy-allow',
                )}
              >
                <span data-testid={`${record.key}-content`}>{generateRecordType(record)}</span>
              </TableCell>
            </StyledKeyValueTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </div>
  );
};

export default KeyValueTable;
