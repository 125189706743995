import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import { AccountBeneficiaryPortalStatus } from '@alpha/bene-types';
import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import React from 'react';
import { ModalType } from '../Body';
import Body from './Body/Body';
import Header from './Header/Header';

export interface ISelectedBeneficiary {
  beneficiary: BeneficiaryDto;
  approved: boolean;
}
interface IBeneInfoDrawer {
  selectedBeneficiary?: BeneficiaryDto;
  beneDrawerOpen: boolean;
  selectedTabIndex: number;
  hasEntitiesToShareTo: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<ModalType | false>>
  closeDrawer: () => void;
  handleBeneficiaryShareButtonClick: (beneficiaryId: string) => void;
}

export const BeneInfoDrawer: React.FC<IBeneInfoDrawer> = (
  props: IBeneInfoDrawer,
) => {
  const {
    selectedBeneficiary,
    beneDrawerOpen,
    selectedTabIndex,
    hasEntitiesToShareTo,
    closeDrawer,
    handleBeneficiaryShareButtonClick,
    setModalOpen,
  } = props;

  const approverOwn = selectedBeneficiary?.userCanApproveOwn || false;
  const approver = selectedBeneficiary?.userCanApprove || false;
  const canShareBeneficiary = selectedBeneficiary?.portalStatus
   === AccountBeneficiaryPortalStatus.APPROVED
   && hasEntitiesToShareTo
   && approverOwn;
  return (
    <BaseDrawer.Drawer
      data-testid="beneficiary-drawer"
      open={beneDrawerOpen}
      onClose={closeDrawer}
      anchor="right"
    >
      {
        selectedBeneficiary
        && (
          <>
            <Header
              handleBeneficiaryShareButtonClick={handleBeneficiaryShareButtonClick}
              canShareBeneficiary={canShareBeneficiary}
              selectedBeneficiary={selectedBeneficiary}
              setModalOpen={setModalOpen}
            />
            <Body
              setModalOpen={setModalOpen}
              selectedTabIndex={selectedTabIndex}
              selectedBeneficiary={selectedBeneficiary}
              userCanApprove={approver}
              userCanApproveOwn={approverOwn}
            />
          </>
        )
      }
    </BaseDrawer.Drawer>
  );
};
