import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    rightAlignedCtas: {
      display: 'flex',
      alignItems: 'flex-start',
      '& > div': {
        '& > div': {
          marginRight: '8px',
          position: 'relative',
          top: '-17px',
        },
      },
      '& > div:last-of-type': {
        maxHeight: '50px',
      },
      '& > button:first-of-type': {
        fontSize: '12px',
      },
    },
    divider: {
      width: 1,
      height: 50,
      backgroundColor: 'rgba(112, 112, 112, 0.2)',
      margin: '0px 20px',
    },
    datePicker: {
      boxShadow: 'none',
      height: '50px',
      '& .MuiFormLabel-root': {
        transform: 'none !important',
        marginTop: '-20px',
        fontSize: 10,
      },
      '& svg': {
        width: '20px !important',
        left: '12px',
        top: '12px',
      },
    },
    dates: {
      display: 'flex',
    },
    width: {
      minWidth: 110,
    },
  }),
  { name: 'TransactionsCtas' },
);

export default useStyles;
