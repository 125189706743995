import { LedgerDto } from '@alpha/currency-accounts-dtos';
import { Box } from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import APPagination from 'components/APPagination';
import browserSearchSVG from '../../../../assets/browserSearch.svg';
import noNotesSVG from '../../../../assets/noNotes.svg';
import BackdropLoader from '../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable, { IEmptyTable } from '../../../../components/Table/EmptyTable/EmptyTable';
import Table from '../../../../components/Table/Table';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import { IDisplayTable } from './DisplayTable.interfaces';
import useStyles from './DisplayTable.styles';

const DisplayTable: React.FC<IDisplayTable> = (props: IDisplayTable) => {
  const {
    table, tableData, handleSetTransaction,
  } = props;

  const styles = useStyles();

  const columns: ITableColumn[] = [
    { header: 'Date', accessor: 'date', className: 'dd-privacy-allow' },
    { header: 'Entity', accessor: 'entity', className: clsx(styles.entity, 'dd-privacy-allow') },
    { header: 'Currency', accessor: 'currency', className: 'dd-privacy-allow' },
    { header: 'Account', accessor: 'account' },
    { header: 'ID', accessor: 'friendlyId', className: 'dd-privacy-allow' },
    { header: 'Details', accessor: 'details', className: 'dd-privacy-allow' },
    { header: 'Reference', accessor: 'reference', className: clsx(styles.reference, 'dd-privacy-allow') },
    { header: 'Credit', accessor: 'creditAmount', className: 'dd-privacy-allow' },
    { header: 'Debit', accessor: 'debitAmount', className: 'dd-privacy-allow' },
    { header: 'Balance', accessor: 'runningBalance', align: 'right' },

  ];

  const emptyTableTransactions: IEmptyTable = {
    title: 'Please select an account to view associated transactions',
    subtitle: 'Account selector is located in the page header',
    icon: browserSearchSVG,
  };

  const emptyTableNoTransactions: IEmptyTable = {
    title: 'No transactions recorded between these dates',
    icon: noNotesSVG,
  };

  if (table.loading) {
    return (
      <BackdropLoader testId="backdrop-loader" />
    );
  } if (table.data && !table.loading) {
    if (table.data.length === 0) {
      return (
        <EmptyTable
          title={emptyTableNoTransactions.title}
          icon={emptyTableNoTransactions.icon}
        />
      );
    }
    return (
      <>
        { table && table.data && (
        <>
          <Table
            columns={columns}
            data={tableData(table.data)}
            shortRows
            data-dd-privacy="allow"
            handleTableRowClick={(data: LedgerDto) => { handleSetTransaction(data); }}
          />
          <Box display="block" textAlign="right">
            <APPagination
              hasPrevious={table.hasPrevious}
              hasNext={table.hasMore === true}
              handleNext={table.handleNextPage}
              handlePrevious={table.handlePreviousPage}
            />
          </Box>
        </>
        )}
      </>
    );
  }
  return (
    <EmptyTable
      title={emptyTableTransactions.title}
      subtitle={emptyTableTransactions.subtitle}
      icon={emptyTableTransactions.icon}
    />
  );
};

export default DisplayTable;
