import { Input } from 'components/Inputs/Input';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import React, { memo, useState } from 'react';
import Alert from 'components/Alert/Alert';
import Authorization from '../../../../../../../hocs/Authorization';
import { TCurrencyAccount } from '../../../../../../../models/currencyAccounts';
import { UserRole } from '../../../../../../../models/user';
import SelectBeneDrawer from '../SelectBeneficiaryDrawer/SelectBeneficiaryDrawer';
import useStyles from './Beneficiary.styles';
import CreateBeneficiary from '../../../../../../Beneficiaries/CreateBeneficiary';

interface IProps {
  open: boolean;
  handleBeneficiaryDrawerToggle: (open: boolean) => void;
  value?: string;
  selectedDebitingAccount : TCurrencyAccount |undefined
}

const Beneficiary: React.FC<IProps> = ({
  open,
  handleBeneficiaryDrawerToggle,
  value,
  selectedDebitingAccount,
}: IProps) => {
  const classes = useStyles();
  const [selectBeneDrawerOpen, setSelectBeneDrawerOpen] = useState(false);

  return (
    <div className="beneficiary" data-testid="select-bene-wrapper">

      <Typography variant="subtitle1" className="label">
        Beneficiary
      </Typography>
      <SelectBeneDrawer
        selectedDebitingAccount={selectedDebitingAccount}
        open={selectBeneDrawerOpen}
        onClose={() => setSelectBeneDrawerOpen(false)}
      />
      <Input
        className={value ? classes.selectBeneInput : classes.selectBenePlaceHolder}
        testId="select-bene-input"
        variant="filled"
        value={value || ' Select a Beneficiary'}
        startAdornmentOnClick={() => setSelectBeneDrawerOpen(true)}
        InputProps={{
          onClick: () => setSelectBeneDrawerOpen(true),
          readOnly: true,
          startAdornment: <FontAwesomeIcon icon={faEdit} />,
        }}
      />

      <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
        <button onClick={() => handleBeneficiaryDrawerToggle(true)} type="button" className="beneficiaryDrawerTriggerButton">
          <Typography
            variant="subtitle1"
            className="beneficiaryDrawerTrigger"
            data-testid="new-beneficiary"
          >
            Want to pay someone new?
          </Typography>
        </button>
        <CreateBeneficiary
          open={open}
          handleDrawerClose={() => handleBeneficiaryDrawerToggle(false)}
        />
      </Authorization>
    </div>
  );
};

export default memo(Beneficiary);
