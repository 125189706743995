import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TCurrencyAccount } from '../../../../../models/currencyAccounts';
import routes from '../../../../../routes.path';
import BatchPayment from './BatchPayments/BatchPayments';
import SinglePayment from './SinglePayment/SinglePayment';

export interface IPaymentTypeProps {
  selectedDebitingAccount: TCurrencyAccount | undefined;
}
const PaymentType:React.FC<IPaymentTypeProps> = ({
  selectedDebitingAccount,
}:IPaymentTypeProps) => (
  <Switch>
    <Route exact path={routes.payments.manual.single}>
      <SinglePayment />
    </Route>
    <Route path={routes.payments.manual.batch}>
      <BatchPayment selectedDebitingAccount={selectedDebitingAccount} />
    </Route>
    <Redirect to={routes.payments.manual.single} />
  </Switch>
);

export default PaymentType;
