import { datadogRum } from '@datadog/browser-rum';
import { Auth } from 'aws-amplify';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import BackdropLoader from '../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import useAlphaSnackbar from '../hooks/useAlphaSnackbar';
import useAuth from '../hooks/useAuth';
import useProfile from '../hooks/useProfile';
import useSwitchAccount from '../hooks/useSwitchAccount';
import routes from '../routes.path';
import history from '../services/history/browserHistory';
import { TStore } from '../store';

const PrivateRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const {
    checkAuth, loggedIn, userInfo, handleSignOut,
  } = useAuth();
  const [cognitoUser, setCognitoUser] = useState<any>();
  const dispatch = useDispatch();
  const { userProfile, updateUserProfile } = useProfile();
  const { currentAccount, setUserAccount, isValidAccountId } = useSwitchAccount();
  const [sessionRecordingStarted, setSessionRecordingStarted] = useState<boolean>(false);
  const key = useSelector<TStore, number>((store) => store.user.key);
  const sb = useAlphaSnackbar();

  const setUserAccountDetails = async (): Promise<void> => {
    try {
      await checkAuth();
      if (loggedIn && userInfo) {
        if (!currentAccount || !isValidAccountId) await setUserAccount();
        if (!userProfile) await updateUserProfile();
      }
    } catch {
      sb.trigger('Something went wrong with loading your account details');
      handleSignOut();
    }
  };

  const getCognitoUserDetails = async () => {
    try {
      const cognitoUserDetails = await Auth.currentAuthenticatedUser();
      setCognitoUser(cognitoUserDetails);
    } catch (error) {
      sb.trigger(error.message || 'There was an error getting your user details');
    }
  };

  useEffect(() => {
    setUserAccountDetails();
    if (userInfo) {
      getCognitoUserDetails();
    }
  }, [loggedIn, history.location.pathname, userInfo]);

  useEffect(() => {
    if (userInfo) {
      dispatch({ type: 'GET_USER_ACCOUNTS_REQUEST' });
    }
  }, [loggedIn, history.location.pathname, userInfo]);

  useEffect(() => {
    if (userInfo && cognitoUser && cognitoUser.attributes && cognitoUser.attributes.sub
      && !sessionRecordingStarted) {
      datadogRum.setUser({
        id: currentAccount?.id,
        name: cognitoUser?.attributes?.sub,
      });
      datadogRum.startSessionReplayRecording();
      setSessionRecordingStarted(true);
    } else if (!userInfo && sessionRecordingStarted) {
      datadogRum.stopSessionReplayRecording();
      setSessionRecordingStarted(false);
    }
  }, [cognitoUser, userInfo]);

  const showRoute = (): React.ReactNode => {
    if (loggedIn && isValidAccountId) {
      return <Route {...props} />;
    } if (loggedIn === false) {
      return <Redirect to={routes.auth.login} />;
    }
    return <BackdropLoader testId="backdrop-loader" value={100} />;
  };

  return <div key={key}>{showRoute()}</div>;
};

export default memo(PrivateRoute);
