import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardTabs } from '../../domain/OutstandingActionsDashboard/Common/helpers';

export interface OutstandingDashboardTablesState {
  tablesSize : Record<string, number>,
  dashboardAction: string,
  isAuthorizedToViewInvoices: boolean,
}

const initialState = {
  tablesSize: {
    [DashboardTabs.BENE_APPROVALS]: 0,
    [DashboardTabs.PAYMENT_APPROVALS]: 0,
    [DashboardTabs.AWAITING_FX]: 0,
    [DashboardTabs.BALANCE_TRANSFER]: 0,
  },
  dashboardAction: '',
  isAuthorizedToViewInvoices: false,

} as OutstandingDashboardTablesState;

export const outstandingDashboardTablesSlice = createSlice({
  name: 'outstandingDashboardTables',
  initialState,
  reducers: {
    updateOutstandingTablesSize: (state,
      action: PayloadAction<Record<string, number>>): OutstandingDashboardTablesState => ({
      ...state,
      tablesSize: action.payload,
    }),
    updateDashboardAction: (state, action: PayloadAction<string>) :
    OutstandingDashboardTablesState => ({
      ...state,
      dashboardAction: action.payload,
    }),
    updateIsAuthorizedToViewInvoices: (state, action: PayloadAction<boolean>) :
    OutstandingDashboardTablesState => ({
      ...state,
      isAuthorizedToViewInvoices: action.payload,
    }),
  },
});

export const { actions } = outstandingDashboardTablesSlice;
