import { makeStyles } from 'theme';

const useStyles = makeStyles(
  {
    root: {
      padding: '40px 56px',
      width: '460px',
      height: '248px',
      '& .wrapper': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        '& .title': {
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          '& > img': {
            width: '25px',
            height: '25px',
            marginRight: '8px',
          },
          '& > h2': {
            fontWeight: 'bold',
            lineHeight: 'normal',
          },
        },
        '& .bodyText': {
          fontSize: '16px',
          fontWeight: 300,
        },
        '& .footer': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
      },
    },
  },
  { name: 'clearDebitingModal' },
);

export default useStyles;
