import { Typography } from '@mui/material';
import clsx from 'clsx';
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { IAuthProps } from '..';
import AuthLayout from '../../../components/Layout/Auth';
import LinearProgress from '../../../components/LinearProgress/LinearProgress';
import WelcomeLoader from '../../../components/Organisms/Onboarding/WelcomeLoader';
import routes from '../../../routes.path';
import Login from '../Login';
import SetupMfaHelperAsset from '../SetupMFA/HelperAsset';
import VerifyMfaHelperAsset from '../VerifyMfa/HelperAsset';
import useStyles from './index.styles';
import PostOnboardingLogin from './PostOnboardingLogin';

export interface IOnboarding {
  onboarding?: boolean
}

type TLoaderSteps = 0 | 25 | 50 | 75 | 100;

type TOnboardingContext = {
  loaderPercentage?: number,
  handleSetLoaderPercentage?: (loaderPercentage: TLoaderSteps) => void,
}

const OnboardingContext = createContext<TOnboardingContext>({
  loaderPercentage: 0,
});

export const useOnboardingContext = () => useContext(OnboardingContext);

function generateStepAndDescription(
  loaderPercentage: TLoaderSteps,
) {
  return {
    0: {
      step: 'Step 1 of 4:',
      description: 'Welcome email verification',
    },
    25: {
      step: 'Step 1 of 4:',
      description: 'Welcome email verification',
    },
    50: {
      step: 'Step 2 of 4:',
      description: 'New Password',
    },
    75: {
      step: 'Step 3 of 4:',
      description: 'Two-factor authentication',
    },
    100: {
      step: 'Step 4 of 4:',
      description: 'Verify Mobile Device',
    },
  }[loaderPercentage];
}

const Onboarding: React.FC<IAuthProps> = ({ userObject, userInfo }: IAuthProps) => {
  const classes = useStyles();

  const [loaderPercentage, setLoaderPercentage] = useState<TLoaderSteps>(0);
  const [stepText, setStepText] = useState(generateStepAndDescription(loaderPercentage));
  const [mfaImage, setMfaImage] = useState<'authy' | 'sms' | undefined>();

  const { pathname } = useLocation();

  useEffect(() => {
    setStepText(generateStepAndDescription(loaderPercentage));
  }, [loaderPercentage]);

  function handleSetLoaderPercentage(number: TLoaderSteps) {
    setLoaderPercentage(number);
  }

  return (
    <OnboardingContext.Provider value={{
      loaderPercentage, handleSetLoaderPercentage,
    }}
    >
      <WelcomeLoader />
      <AuthLayout.Container>
        <AuthLayout.Header className={classes.header}>
          <Typography variant="subtitle1" className="stageText">
            <span className="name">ALPHA</span>
            <b>{` | ${stepText.step} `}</b>
            {stepText.description}
          </Typography>
          <LinearProgress value={loaderPercentage} variant="determinate" className={classes.linearProgress} />
        </AuthLayout.Header>
        <AuthLayout.LeftContent
          className={clsx(pathname === routes.auth.onboarding.verifyMfa && classes.leftContent)}
        >
          <Switch>
            <Route exact path={routes.auth.onboarding.login}>
              <Login onboarding />
            </Route>
            <PostOnboardingLogin
              userObject={userObject}
              userInfo={userInfo}
              mfaImage={mfaImage}
              setMfaImage={setMfaImage}
            />
          </Switch>
        </AuthLayout.LeftContent>
        <AuthLayout.RightContent
          className={clsx(pathname === routes.auth.onboarding.verifyMfa && classes.rightContent)}
        >
          <Switch>
            <Route exact path={routes.auth.onboarding.login}>
              <img
                src="/static/images/verify-email.png"
                alt="Onboarding email being displayed on a laptop and a mobile phone"
              />
            </Route>
            <Route exact path={routes.auth.onboarding.setupMfa}>
              <SetupMfaHelperAsset mfaImage={mfaImage} />
            </Route>
            <Route exact path={routes.auth.onboarding.verifyMfa}>
              <VerifyMfaHelperAsset mfaImage={mfaImage} />
            </Route>
          </Switch>
        </AuthLayout.RightContent>
      </AuthLayout.Container>
    </OnboardingContext.Provider>
  );
};

export default Onboarding;
