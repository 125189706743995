import styled from '@mui/styled-engine';
import _default from 'theme';

const { typography } = _default;

type TStyleProps = {
  size: number;
  rounded: boolean;
  locked?: boolean;
  rebrand?: boolean;
};

export const StyledFlag = styled('div')((props: TStyleProps) => ({
  display: 'inline-flex',
  flexDirection: props.rebrand ? 'row' : 'column',
  alignItems: 'center',
  '& .flagWrapper': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& .flag': {
      width: props.size,
      height: props.size,
      objectFit: 'cover',
      borderRadius: props.rounded ? '50%' : 0,
      border: '1px solid #DDDDDD',
    },
    '& .lockIcon': {
      position: 'absolute',
      height: '20px',
      width: '20px !important',
      border: '1px solid #C1C1C1',
      borderRadius: '50%',
      backgroundColor: 'white',
      padding: '0 5px',
      bottom: '-2px',
      right: '-4px',
    },
  },
  '& .codeLabel': {
    fontSize: props.rebrand ? '14px' : '11px',
    fontFamily: typography.fontFamilySecondary,
    fontWeight: props.locked ? typography.fontWeightBold : typography.fontWeightSemiBold,
    lineHeight: '14px',
    margin: props.rebrand ? '0 0 0 3px' : '4px 0 0 0',
  },
  '& .lockIconRebrand': {
    height: '8px',
    width: '8px !important',
    opacity: '0.5',
    position: 'relative',
    marginLeft: '1px',
  },
}));
