import React, { FC, memo } from 'react';
import { StyledAppMenuList, StyledNavLink } from './index.styles';
import { IAppMenuProps } from '../../index.interfaces';

export const AppMenu: FC<IAppMenuProps> = memo((props) => {
  const {
    items, sorted = true,
  } = props;

  if (sorted) {
    items.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <StyledAppMenuList>
      {items.map((item) => (
        <StyledNavLink
          key={item.name}
          to={item.url}
          activeClassName="active"
          onClick={() => item.onClick()}
          data-testid={`nav-${item.label.replace(' ', '-').toLowerCase()}`}
        >
          {item.label}
        </StyledNavLink>
      ))}
    </StyledAppMenuList>
  );
});

AppMenu.displayName = 'AppMenu';
