import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    creditingDetails: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      marginBottom: 100,
      '& tr > td:last-child': {
        color: 'rgb(9,131, 117)',
      },
    },
  }),
  { name: 'CreditingDetails' },
);

export default useStyles;
