import _default, { makeStyles } from 'theme';

const { palette } = _default;

export const useStyles = makeStyles(
  () => ({
    root: {
      '& .actionLink': {
        color: palette.primary.main,
      },
      '& .actionDisabled': {
        color: 'rgb(170, 170, 170)',
        cursor: 'default',
      },
      '& .type': {
        color: '#707070',
        '& svg': {
          marginRight: '8px',
          width: '16px',
          height: '16px',
        },
      },
    },
  }),
  { name: 'DraftedTable' },
);

export default useStyles;
