import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  arrow: {
    color: 'rgba(123, 123, 123)',
  },
  tooltip: {
    backgroundColor: 'rgba(123, 123, 123)',
    padding: '13px 22px',
    fontSize: '11px',
  },
}), { name: 'Tooltip' });

export default useStyles;
