import { Role } from '@alpha/auth-types';
import { InvoiceDto } from '@alpha/invoice-dtos';
import { InvoiceStatus } from '@alpha/invoice-types';
import { AxiosResponse } from 'axios';
import { TUserAccount } from '../../models/user';
import AuthorizationService from '../Authorization/authorization.service';
import instance, { instanceAccountId } from '../Axios/instance';

export class InvoicesService {
  public static async getInvoicesAsync(accountId?: string): Promise<InvoiceDto[]> {
    const response: AxiosResponse<InvoiceDto[]> = accountId
      ? await instanceAccountId(accountId).get('/invoices/multientity-invoices')
      : await instance.get(
        '/invoices/invoices',
      );
    return response.data;
  }

  public static async getInvoiceManagerEntities(): Promise<TUserAccount[]> {
    const response = await AuthorizationService.getEntitiesForRole(Role.INVOICEMANAGER);
    return response;
  }

  public static filterInvoicesByOverdue(listOfInvoices: InvoiceDto[]) {
    const invoiceStatus = InvoiceStatus.OVERDUE;
    const listOfOverdueInvoices: InvoiceDto[] = listOfInvoices.filter(
      (invoice) => invoice.status === invoiceStatus,
    );
    return listOfOverdueInvoices;
  }

  public static retrieveOldestOverdueInvoice(listOfInvoices: InvoiceDto[]) {
    return listOfInvoices.length
      ? listOfInvoices.sort(
        (a: InvoiceDto, b: InvoiceDto) => a.valueDate.localeCompare(b.valueDate),
      )[0] as InvoiceDto
      : undefined;
  }
}
export default InvoicesService;
