import { StyledDivider } from 'components/StyledDivider';
import React, { useEffect, useState } from 'react';
import { TCurrencyAccount } from '../../../../../models/currencyAccounts';
import Beneficiary from './Beneficiary/Beneficiary';
import useStyles from './ManualPayment.styles';
import PaymentFields from './PaymentFields/PaymentFields';
import useSinglePaymentContext from './useSinglePaymentContext';
import FormTitle from '../components/FormTitle';
import usePaymentsContext from './usePaymentsContext';

export interface IManualPaymentProps {
  selectedFundingAccount: TCurrencyAccount;
  accountId: string;
  accountName:string;
  allowSepaPaymentRail: boolean;
}
const ManualPayment: React.FC<IManualPaymentProps> = ({
  selectedFundingAccount,
  accountId,
  accountName,
  allowSepaPaymentRail,
}: IManualPaymentProps) => {
  const styles = useStyles();
  const { singlePaymentContext } = useSinglePaymentContext();
  const [inputValue, setInputValue] = useState<string>();
  const { paymentsContext } = usePaymentsContext();

  useEffect(() => {
    setInputValue(singlePaymentContext?.currentPayment?.beneficiary?.name);
  }, [singlePaymentContext?.currentPayment?.beneficiary]);

  if (!singlePaymentContext) return null;

  const handleBeneficiaryDrawerToggle = (_open: boolean) => {
    singlePaymentContext.handleBeneficiaryDrawerToggle(_open);
    singlePaymentContext.setCurrentPayment({
      ...singlePaymentContext.currentPayment,
      beneficiary: undefined,
    });
    singlePaymentContext.setEditRow(undefined);
  };

  if (paymentsContext.isStandingOrder && singlePaymentContext.editRow === undefined) {
    return null;
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <FormTitle text="To" />
          <Beneficiary
            accountName={accountName}
            accountId={accountId}
            selectedFundingAccount={selectedFundingAccount}
            open={singlePaymentContext.open}
            handleBeneficiaryDrawerToggle={handleBeneficiaryDrawerToggle}
            value={inputValue}
          />
          {
            (
              singlePaymentContext?.currentPayment.beneficiary
              && paymentsContext.fundingAccount
            ) && <FormTitle text="Payment Details" />
          }
          <PaymentFields
            allowSepaPaymentRail={allowSepaPaymentRail}
          />
        </div>
      </div>
      <StyledDivider />
    </>
  );
};

export default ManualPayment;
