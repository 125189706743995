import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    fxPayments: {
      marginBottom: 10,
      position: 'relative',
      '& .pageloadLoader': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
      '&:nth-child(2n)': {
        minHeight: 'calc(100vh - 600px)',
      },
    },
    fxPayment: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paymentLeft: {
      display: 'flex',
      margin: '12px 0px',
      height: '76px',
    },
    currency: {
      minWidth: '130px',
    },
    paymentStat: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& > p': {
        margin: 0,
      },
      '& > p:nth-of-type(1)': {
        textTransform: 'uppercase',
        fontSize: '11px',
        letterSpacing: '1.65px',
        lineHeight: '15px',
        fontWeight: typography.fontWeightSemiBold,
      },
      '& > p:nth-of-type(2)': {
        marginTop: '7px',
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
    paymentRight: {
      display: 'flex',
      minWidth: '36%',
      alignSelf: 'stretch',
    },
    indicative: ({ liveRates }: { liveRates: boolean }) => ({
      backgroundColor: liveRates ? '#C9E5E2' : '#FAFAFA',
      width: '142px',
      margin: '0px 30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& > p': {
        margin: 0,
      },
      '& > p:nth-of-type(1)': {
        fontSize: '14px',
        lineHeight: '19px',
      },
      '& > p:nth-of-type(2)': {
        marginTop: '2px',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: typography.fontWeightSemiBold,
      },
    }),
    liveRateLoader: {
      height: '22px',
      width: '22px',
      marginTop: '2px',
      '& > span.MuiCircularProgress-root': {
        animation: 'none',
        height: 'unset !important',
      },
    },
    rates: {
      display: 'flex',
      '& p': {
        fontWeight: typography.fontWeightBold,
        fontSize: 14,
      },
      '& > p:nth-of-type(1)': {
        paddingRight: 10,
        marginRight: 10,
        borderRight: '1px solid rgba(112, 112, 112, 0.3)',
      },
    },
    icon: {
      color: '#818181',
      marginLeft: 5,
    },
  }),
  { name: 'FxPayments' },
);

export default useStyles;
