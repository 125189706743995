import { all } from 'redux-saga/effects';
import authySaga from './authy/saga';
import { generatePdfSaga } from './generateReport/sagas';
import { paymentsSaga } from './payments/payments.saga';
import { watchUpdateEntityGroups, watchGetEUserAccountsSaga } from './entityGroups/saga';
import { watchGetFeatureFlagsSaga } from './featureFlags/saga';
import { watchGetUserAccountsSaga } from './accounts/accounts.saga';

export default function* rootSaga() {
  yield all([
    paymentsSaga(),
    authySaga(),
    generatePdfSaga(),
    watchUpdateEntityGroups(),
    watchGetEUserAccountsSaga(),
    watchGetFeatureFlagsSaga(),
    watchGetUserAccountsSaga(),
  ]);
}
