import { useEffect, useState } from 'react';

interface IUseTextEllipsis {
  text: string;
  maxChars: number;
}

const useTextEllipsis = ({ text, maxChars }: IUseTextEllipsis) => {
  const [textEllipsis, setTextEllipsis] = useState<string>('');

  useEffect(() => {
    const textLength = text.replace(/\s/g, '');
    if (textLength && textLength.length > maxChars) {
      // making sure that we split according to number of chars excluding any spaces
      let i = 0;
      let length = 0;
      while (length < maxChars) {
        if (!/\s/g.test(text[i])) length += 1;
        i += 1;
      }

      setTextEllipsis(`${text.substr(0, i)}...`);
    } else {
      setTextEllipsis(text);
    }
  }, [text, maxChars]);

  return textEllipsis;
};

export default useTextEllipsis;
