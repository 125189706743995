import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { IBreadcrumbsProps } from './Breadcrumbs.interfaces';
import StyledBreadcrumbs from './Breadcrumbs.styles';

const Breadcrumbs: FC<IBreadcrumbsProps> = (props) => {
  const { items } = props;

  const renderItems = () => items.map((item, index) => {
    const { text, url } = item;

    return (
      <li className="item" key={text}>
        {url ? (
          <Link className="link" to={url}>
            {text}
          </Link>
        ) : (
          <span>{text}</span>
        )}
        {index < items.length - 1 && <span data-testid="breadcrumb-separator" className="icon">/</span>}
      </li>
    );
  });

  return (
    <StyledBreadcrumbs>
      {renderItems()}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
