import theme from 'theme';
import styled from '@mui/styled-engine';
import BaseButton from '../BaseButton';

const { typography, palette } = theme;

const StyledActionButton = styled(BaseButton)(({
  boxShadow: 'none',
  fontWeight: typography.fontWeightSemiBold,
  textTransform: 'uppercase',
  font: typography.fontFamily,
  '&:hover': {
    backgroundColor: palette.primary.dark,
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: 'rgba(9, 131, 117, 0.2)',
    color: 'rgba(255, 255, 255, 0.4)',
  },
}));

export default StyledActionButton;
