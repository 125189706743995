import { Beneficiary } from '@alpha/bene-dtos';
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Typography } from '@mui/material';
import { faHome, faUniversity } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import clsx from 'clsx';
import useAlphaSnackbar from '../../../../../../../../hooks/useAlphaSnackbar';
import useSinglePaymentContext from '../../../useSinglePaymentContext';
import useStyles from './BeneficiaryItem.styles';
import { Flag } from 'components/Flag';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  beneficiary: Beneficiary;
  onClose: (open: boolean) => void;
}

const generateBankDetails = (ibanOrAccountNumber: string, swift?: string, bankCountryCode?: string): string => {
  let bankDetailsString = '';
  bankDetailsString = bankDetailsString.concat(ibanOrAccountNumber || '');
  if (swift) {
    bankDetailsString = bankDetailsString.concat(`  |  ${swift} `);
  }
  if (bankCountryCode) {
    bankDetailsString = bankDetailsString.concat(`  |  ${bankCountryCode}`);
  }
  return bankDetailsString;
};

const BeneficiaryItem: React.FC<IProps> = (props: IProps) => {
  const { beneficiary, onClose } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);

  const { singlePaymentContext } = useSinglePaymentContext();
  const sb = useAlphaSnackbar();

  const handleChange = (selectedBeneficiary: Beneficiary) => {
    try {
      singlePaymentContext?.setCurrentPayment({
        ...singlePaymentContext.currentPayment,
        beneficiary: { ...selectedBeneficiary, isExisting: true },
      });
    } catch (e) {
      singlePaymentContext?.setCurrentPayment({
        ...singlePaymentContext.currentPayment,
        beneficiary: undefined,
      });
      sb.trigger((e as Error).message || 'Something went wrong when selecting this beneficiary');
    } finally {
      onClose(false);
    }
  };

  return (
    <Box
      data-testid="beneficiary-item"
      className={
        beneficiary?.id === singlePaymentContext?.currentPayment?.beneficiary?.id
          ? classes.currentbeneficiarytWrapper
          : classes.beneficiaryWrapper
      }
    >
      <Accordion expanded={expanded}>
        <AccordionSummary className={classes.beneficiaryNameAndCurrencyWrapper}>
          <button type="button" className={classes.selectBeneButton} onClick={() => handleChange(beneficiary)}>
            <Typography className={classes.beneficiaryName} data-testid="beneficiary-name">
              {beneficiary.name}
            </Typography>
            {beneficiary.friendlyName && (
              <Typography className={classes.beneficiaryFriendlyName}>{beneficiary.friendlyName}</Typography>
            )}
          </button>
          <div className={clsx(classes.lowerBox, 'dd-privacy-allow')}>
            <span className={classes.currencyBox}>
              <Flag size="sm" code={beneficiary.bankCountryCode} />
              <Typography className={classes.beneficiaryCurrencyCode}>{beneficiary.bankCountryCode}</Typography>
              <div className={classes.currencyDivider} />
              <Flag size="sm" code={beneficiary.currencyCode} />
              <Typography className={classes.beneficiaryCurrencyCode}>{beneficiary.currencyCode}</Typography>
            </span>
            <span className={classes.moreInfoBox}>
              <button type="button" className={classes.moreInfoButton}>
                <Typography
                  className={classes.moreInfoText}
                  onClick={expanded ? () => setExpanded(false) : () => setExpanded(true)}
                >
                  {expanded ? 'Show Less' : 'More Info'}
                </Typography>
              </button>
            </span>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          <Box className={classes.accountDetailsBox}>
            <Typography className={classes.beneficiaryFurtherDetails}>
              <FontAwesomeIcon icon={faUniversity as IconDefinition} className={classes.detailsIcons} />
              {generateBankDetails(
                beneficiary.iban || beneficiary.accountNumber,
                beneficiary.swift,
                beneficiary.nationalBankCode,
              )}
            </Typography>
            {beneficiary.residentAddress && (
              <Typography className={classes.beneficiaryFurtherDetails}>
                <FontAwesomeIcon className={classes.detailsIcons} icon={faHome as IconDefinition} />
                {beneficiary.residentAddress}
              </Typography>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default BeneficiaryItem;
