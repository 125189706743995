import styled from '@mui/styled-engine';
import _default from 'theme';
import { Box } from '@mui/material';

const { palette, shadows, typography } = _default;

const StyledSnack = styled(Box, {
  shouldForwardProp: (props) => props !== 'type',
})(({ type }) => ({
  display: 'flex',
  backgroundColor: '#fff',
  boxShadow: shadows[1],
  overflow: 'hidden',
  width: '320px',
  minHeight: 60,
  '& .iconWrapper': {
    fontSize: '1.6rem',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    width: '75px',
    backgroundColor: palette[type].main,
  },
  '& .content': {
    display: 'flex',
    flex: '1 1 auto',
    padding: '10px 15px 10px 10px',
    justifyContent: 'space-between',
    fontWeight: typography.fontWeightRegular,
  },
  '& .header': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .title': {
    flex: '1 1 auto',
    fontSize: '13px',
    marginBottom: '5px',
  },
  '& .close': {
    flex: '0 0 auto',
    color: 'black',
    fontSize: '20px',
    cursor: 'pointer',
  },
  '& .message': {
    lineHeight: 1.4,
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
}));

export default StyledSnack;
