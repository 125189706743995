import { Box, Button, Typography } from '@mui/material';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import clsx from 'clsx';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../hooks/useReportsPolling';
import { TTransactionData } from '../../../../models/transactions';
import formatIsoDate from '../../../../utils/formatIsoDate';
import useStyles from './Header.styles';

interface IHeader {
  transaction: TTransactionData;
}

const Header: React.FC<IHeader> = (props: IHeader) => {
  const { transaction } = props;
  const { fxRate, arrivalDate } = transaction;
  const classes = useStyles();

  const { executeReportGeneration } = useReportsPolling(PageType.Payments);

  const handleExecuteReportGeneration = async (
    type: FileType, downloadType: DownloadType, id: string,
  ) => {
    await executeReportGeneration(type, id, undefined, undefined, downloadType);
  };

  const handleId = (): string => {
    if (transaction && transaction.clientFxTradeId) {
      return transaction && transaction.clientFxTradeId;
    }
    if (transaction && transaction.paymentId) {
      return transaction && transaction.paymentId;
    }
    return '';
  };

  const handleDownloadType = (): DownloadType => {
    if (transaction && transaction.clientFxTradeId) {
      return DownloadType.Fx;
    }
    return DownloadType.Single;
  };

  return (
    <Box className={classes.headerContent}>
      <Box className={clsx(classes.titleWrapper, 'dd-privacy-allow')}>
        <Typography className={classes.headerTitle}>
          {
            transaction && transaction.paymentId
              ? 'Payment Confirmation'
              : `${fxRate ? 'FX ' : ''}Transfer complete`
          }
        </Typography>
        <Typography className={classes.headerSubtitle}>
          The below
          {transaction && transaction.paymentId ? ' payment ' : ' transfer '}
          has been confirmed and completed on
          {' '}
          {arrivalDate ? formatIsoDate(arrivalDate) : '-'}
        </Typography>
      </Box>
      {
        (transaction && (transaction?.clientFxTradeId || transaction?.paymentId))
        && (
        <Tooltip title={`Download ${transaction?.clientFxTradeId ? 'FX' : ''} ticket`}>
          <Button
            onClick={
              () => handleExecuteReportGeneration(FileType.PDF, handleDownloadType(), handleId())
            }
            className={classes.button}
          >
            <FontAwesomeIcon className={classes.icon} size="1x" icon={faDownload} />
          </Button>
        </Tooltip>
        )
      }
    </Box>
  );
};

export default Header;
