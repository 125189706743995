import styled from '@mui/styled-engine';

const StyledLineBreak = styled('hr')({
  backgroundColor: 'rgba(112,112,112,0.2)',
  width: '100%',
  height: '1px',
  border: 'none',
});

export default StyledLineBreak;
