import * as yup from 'yup';

interface FormValues<T> {
  [key: string]: T;
}

export const validationSchema = <T extends yup.SchemaOf<any>>(
  values: FormValues<T>,
) => yup.object().shape(values) as yup.ObjectSchema<FormValues<T>>;

export type ValidationSchemaType = ReturnType<typeof validationSchema>;

export const formatErrorMessage = {
  required: (field: string) => `${field} is required`,
  max: (field: string, char: number) => `${field} cannot exceed more than ${char} characters`,
  min: (field: string, char: number) => `${field} must be at least ${char} characters`,
  invalid: (field: string) => `${field} format is invalid`,
};
