import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      float: 'left',
      marginRight: '47px',
      paddingRight: '47px',
      display: 'flex',
      alignItems: 'center',
      borderRight: '1px solid #eee',
    },
    tableIcon: {
      marginRight: 15,
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
    },
    currencyCode: {
      fontSize: 22,
      lineHeight: 1,
      fontWeight: 100,
      margin: '0 0 5px 0',
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    currencyName: {
      display: 'inline-block',
      textTransform: 'uppercase',
      fontSize: 11,
      lineHeight: 1,
      padding: '4px 0 0 0',
      color: '#B1B1B1',
      letterSpacing: 2,
      margin: 0,
    },
  }),
  { name: 'CurrencyHeader' },
);

export default useStyles;
