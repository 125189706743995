import { useFormik } from 'formik';
import * as yup from 'yup';
import { useContext } from 'react';
import { originalUseSnackbar } from 'components/Snackbar';
import { TSignIn } from '../models/auth';
import AuthService from '../services/Auth/auth.service';
import useHandleLogin from './useHandleLogin';
import { AuthContext } from '../context/AuthContext';
import browserHistory from '../services/history/browserHistory';
import routes from '../routes.path';

type TUpdatePassword = {
  password: string;
};

const initialValue: TUpdatePassword = {
  password: '',
};

const validation = yup.object({
  password: yup.string().required('Password is required'),
});

const useUpdatePassword = (newUserObject: TSignIn) => {
  const snackbar = originalUseSnackbar();
  const {
    auth, loading, setLoading, handleLogin,
  } = useHandleLogin();
  const { temporaryPassword } = useContext(AuthContext);

  const handleNewPassword = async (userObject: TSignIn, password: string, callback: Function) => {
    let expiredSession = false;

    try {
      setLoading(true);
      const response = await AuthService.newPassword(userObject, password, temporaryPassword);
      await auth.challengeRedirect(response, callback);
    } catch (e) {
      if (e.message?.includes('CUSTOM_AUTH_REQUIRED')) {
        await handleLogin(userObject.username, password);
      } else {
        snackbar.enqueueSnackbar(e.message, {
          className: 'dd-privacy-allow',
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
        });

        if (e.message.includes('Session expired')) {
          expiredSession = true;
        }
      }
    } finally {
      setLoading(false);

      if (expiredSession) {
        browserHistory.push(routes.auth.onboarding.login);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values: TUpdatePassword) => {
      try {
        await handleNewPassword(newUserObject, values.password, snackbar.enqueueSnackbar);
      } catch (e) {
        snackbar.enqueueSnackbar(e.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
          className: 'dd-privacy-allow',
        });
      }
    },
    validationSchema: validation,
  });

  return {
    formik,
    loading,
  };
};

export default useUpdatePassword;
