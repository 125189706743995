import approvalConfig from './ApprovalConfigMock';
import getGroups from './GroupMock';
import getRoles from './RolesMock';

export const getEntity = (
  includeApprovalConfig: boolean,
  index: number,
  active: boolean,
  isManageable: boolean,
  groups?: 'true' | 'false',
) => ({
  id: `12345678-1234-1234-1234-EEEEEEEE${(index).toString().padStart(4, '0')}`,
  name: `Entity ${index + 1}`,
  entityType: 'Reseller',
  incorporatedIn: 'Malta',
  isManageable,
  approvalLevel: 'A',
  status: active,
  address: '123 Valletta Ma nafx',
  created: new Date('05 October 2011 14:48 UTC'),
  onboarded: new Date('05 October 2011 14:48 UTC'),
  approvalConfig: includeApprovalConfig ? approvalConfig : {},
  roles: getRoles(index),
  groups: groups === 'true' ? getGroups(10, false) : [],
});

export const getEntities = (includeApprovalConfig: boolean, total: number) => {
  const entities = new Array(0);
  for (let i = 0; i < total; i += 1) {
    if (i % 2 === 0) {
      entities.push(getEntity(includeApprovalConfig, i, true, true, 'true'));
    } else if (i % 3 === 0) {
      entities.push(getEntity(includeApprovalConfig, i, true, true, 'true'));
    } else {
      entities.push(getEntity(includeApprovalConfig, i, false, true, 'true'));
    }
  }
  return entities;
};
