import React from 'react';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import Tooltip from '../../Tooltip/Tooltip';
import useStyles from './InverseFxRateTooltip.styles';

interface IProps {
  text: string | JSX.Element;
  displayInfoCircleIcon?: boolean;
  placement?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
}

const InverseFxRateTooltip = (props: IProps) => {
  const classes = useStyles();
  const {
    text,
    placement,
    displayInfoCircleIcon,
  } = props;

  return (
    <Tooltip
      placement={placement || 'bottom'}
      title={(
        <div className={classes.tooltipText}>
          The first rate shown is market convention
          and the second rate shown is the counter convention.
        </div>
      )}
    >
      <div className={classes.key}>
        <Typography variant="body1">{text}</Typography>
        {
          displayInfoCircleIcon
          && <FontAwesomeIcon className={classes.icon} icon={faInfoCircle} />
        }
      </div>
    </Tooltip>
  );
};

export default InverseFxRateTooltip;
