import { Button, styled } from '@mui/material';

export const StyledButton = styled(Button)({
  borderRadius: 5,
  fontWeight: 600,
  fontSize: 13,
  textTransform: 'capitalize',
  margin: '5px',
});

export const StyledApproveButton = styled(StyledButton)({
  backgroundColor: '#1E8777',
  '&:hover': {
    backgroundColor: '#065b51',
  },
});

export const StyledRejectButton = styled(StyledButton)({
  backgroundColor: '#C64545',
  '&:hover': {
    backgroundColor: '#A03737',
  },
});
