import { ChangeEvent } from 'react';
import useSearch, { TSearchParams } from '../../../../../../../hooks/useSearch';
import { TCurrencyAccount } from '../../../../../../../models/currencyAccounts';
import { TPayment } from '../../../../../../../models/payments';
import usePaymentsContext from '../usePaymentsContext';

const useSelectBeneDrawer = (selectedDebitingAccount: TCurrencyAccount | undefined) => {
  const {
    items,
    handleInitialSearch,
    setSearchText,
    handleNextPage,
    handlePreviousPage,
    handleNewSearch,
    skip,
    loading,
  } = useSearch();

  const { paymentsContext } = usePaymentsContext();

  const createSearchParams = () => {
    const searchParams: TSearchParams = {
      baseUrl: '/bene/account-bene/search',
      queryParams: {
        skip,
        take: 5,
        sortby: 'name',
        sortorder: 'asc',
        status: 'Approved',
      },
    };
    if (paymentsContext.currentPayments) {
      generateCurrencyCodeParams(searchParams, paymentsContext.currentPayments);
    }
    return searchParams;
  };

  const generateCurrencyCodeParams = (searchParams: TSearchParams, currentPayments: TPayment[]) => {
    const newSearchParams = { ...searchParams };
    if (selectedDebitingAccount?.currencyCode
      === currentPayments[0]?.beneficiary?.currencyCode) {
      newSearchParams
        .queryParams
        .currencyCode = selectedDebitingAccount?.currencyCode as string;
    } else if (currentPayments.length > 0) {
      newSearchParams
        .queryParams
        .excludeCurrencyCode = selectedDebitingAccount?.currencyCode || '';
    }
  };

  const searchParams = createSearchParams();

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.searchtext = e.target.value;
    }
    await handleNewSearch(searchParams);
  };

  const getBeneficiaries = async () => {
    await handleInitialSearch(searchParams);
  };

  return {
    items,
    loading,
    searchParams,
    handleNextPage,
    handleInputChange,
    getBeneficiaries,
    handlePreviousPage,
  };
};
export default useSelectBeneDrawer;
