import { Role } from '@alpha/auth-types';
import { AxiosResponse } from 'axios';
import { EntityGroup } from '../../models/entityGroups';
import { TUserAccount } from '../../models/user';
import instance, { instanceNoAccountId } from '../Axios/instance';

export default class EntityGroupsService {
  public static async getEntityGroups() : Promise<EntityGroup[]> {
    const response: AxiosResponse<{entityGroups: EntityGroup[]}> = await instanceNoAccountId.get(
      '/auth/entity-group/read-groups', {
        headers: {
          'account-id': 'null',
        },
      },
    );
    return response?.data?.entityGroups;
  }

  public static async createEntityGroup(
    entityGroupName: string,
    accountIds: string[],
    entityGroupColour?: string,
  ): Promise<void> {
    const accountIdHeader = this.getAccountIdHeader(accountIds);
    const response: AxiosResponse<string> = await instanceNoAccountId.post(
      '/auth/entity-group/create-group', {
        entityGroupName,
        accountIds,
        entityGroupColour,
      },
      {
        headers: {
          'account-id': accountIdHeader,
        },
      },
    );
    if (response.status !== 200) {
      throw Error('Unable to create entity group at this time');
    }
  }

  public static async addAccountToEntityGroup(
    entityGroupId: string,
    accountId: string,
  ): Promise<any> {
    const response: AxiosResponse<string> = await instanceNoAccountId.post(
      '/auth/entity-group/add-account', {
        entityGroupId,
        accountId,
      },
      {
        headers: {
          'account-id': accountId,
        },
      },
    );
    if (response.status !== 200) {
      throw Error('Unable to add account to entity group');
    }
    return response;
  }

  public static async updateGroupName(
    entityGroupId: string,
    groupEntityIds: string[],
    updatedEntityGroupName: string,
  ): Promise<any> {
    const response: AxiosResponse<string> = await instance.patch(
      `auth/entity-group/update-name/${entityGroupId}`, {
        updatedEntityGroupName,
        entityGroupId,
        groupEntityIds,
      },
    );
    if (response.status !== 200) {
      throw Error('Unable to update group name');
    }
    return response;
  }

  public static async deleteEntityGroup(
    entityGroupId: string,
  ): Promise<void> {
    const response: AxiosResponse<string> = await instance.delete(
      `/auth/entity-group/delete-group/${entityGroupId}`,
    );
    if (response.status !== 200) {
      throw Error('Unable to delete entity group');
    }
  }

  public static async deleteEntityFromGroup(
    entityAccountIds: string[],
    entityGroupId: string,
  ): Promise<void> {
    const response: AxiosResponse<string> = await instance.delete(
      `auth/entity-group/delete-entity/${entityGroupId}`, {
        data:
        {
          entityAccountIds,
          entityGroupId,
        },
      },
    );
    if (response.status !== 200) {
      throw Error('Unable to delete entity from group');
    }
  }

  public static async getAccountsWithEntityManagerPerm(): Promise<TUserAccount[]> {
    const response: AxiosResponse<{accounts: TUserAccount[]}> = await instance(
      `/auth/user-accounts-by-role/${Role.ENTITY_MANAGER_ADD_ALL_PERMS.replace(/\s+/g, '').toUpperCase()}`,
    );
    if (response.status !== 200) {
      throw Error('Unable to get user accounts');
    }
    return response?.data?.accounts;
  }

  public static async getAccountsWithEntityViewerPerm(): Promise<TUserAccount[]> {
    const response: AxiosResponse<{accounts: TUserAccount[]}> = await instance(
      `/auth/user-accounts-by-role/${Role.ENTITY_VIEWER.replace(/\s+/g, '').toUpperCase()}`,
    );
    if (response.status !== 200) {
      throw Error('Unable to get user accounts');
    }
    return response?.data?.accounts;
  }

  private static getAccountIdHeader(accountIdsArray: string[]) {
    return accountIdsArray.map((accountId) => accountId.trim()).join();
  }
}
