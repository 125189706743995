import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { datadogRum } from '@datadog/browser-rum';
import ShareAccessModal from '../../ShareAccessModal';
import { StyledButton, StyledHeader, StyledHeaderLink, StyledShareButton } from '../index.styles';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import environmentVars from 'env.variables';

interface IHeader {
  id?: string;
  showShareForm: boolean;
  ownerEmail?: string;
  isFormSubmitted: boolean;
  loading: boolean;
  setLoadingSaveDraft: Dispatch<SetStateAction<boolean>>;
  setLoadingSubmit: Dispatch<SetStateAction<boolean>>;
  showSubmittedPage: boolean;
  isFormInitialised: boolean;
  customMargin: string;
  formId?: string;
}

const reusableKycHeaderTitle = 'Reusable KYC Onboarding';
const reusableKycHeaderMessage =
  'In order to submit a new reusable KYC onboarding application please complete the form below. If you need to submit multiple entities at the same time this is possible by selecting the “add new” button at the bottom of the form.';

const Header = ({
  id,
  showShareForm,
  isFormSubmitted,
  loading,
  ownerEmail,
  setLoadingSubmit,
  setLoadingSaveDraft,
  showSubmittedPage,
  isFormInitialised,
  customMargin,
  formId,
}: IHeader) => {
  const [isFixed, setIsFixed] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      const scrollTop = window.scrollY;
      setIsFixed(scrollTop >= 40);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const saveAllEditGridRows = () => {
    const buttonRef = 'button[ref="editgrid-entities-saveRow"]';
    while (document.querySelector(buttonRef)) {
      (document.querySelector(buttonRef) as HTMLButtonElement)?.click();
    }
  };

  const submitForm = () => {
    datadogRum.addAction('ONBOARDING FORM SUBMIT');

    setLoadingSubmit(true);

    saveAllEditGridRows();

    document.getElementById('submitForm')?.click();
  };

  const saveDraft = async () => {
    datadogRum.addAction('ONBOARDING FORM SAVE DRAFT');

    setLoadingSaveDraft(true);

    saveAllEditGridRows();

    document.getElementById('customDraftSave')?.click();
  };

  return (
    <StyledHeader marginTop={customMargin} className={clsx(isFixed && 'fixed')}>
      <div>
        <h1>
          {formId === environmentVars.FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID
            ? reusableKycHeaderTitle
            : 'New Entity Onboarding'}
        </h1>
        {!showSubmittedPage && isFormInitialised && (
          <>
            {showShareForm && ownerEmail && (
              <>
                <StyledShareButton
                  variant="contained"
                  color="secondary"
                  disableElevation
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setOpenModal(true);
                    datadogRum.addAction('ONBOARDING FORM SHARE BUTTON');
                  }}
                >
                  Share Form
                  {loading && <FontAwesomeIcon icon={faSpinner as IconDefinition} spin />}
                </StyledShareButton>
                <ShareAccessModal openModal={openModal} setOpenModal={setOpenModal} submissionId={id} />
              </>
            )}
            <StyledButton
              id="save-draft-button"
              variant="contained"
              color="secondary"
              disableElevation
              type="button"
              onClick={saveDraft}
              disabled={loading}
              hidden={isFormSubmitted}
            >
              Save Draft
              {loading && <FontAwesomeIcon icon={faSpinner as IconDefinition} spin />}
            </StyledButton>
            <StyledButton
              id="submit-form-button"
              variant="contained"
              color="primary"
              disableElevation
              type="button"
              onClick={submitForm}
              disabled={loading}
              hidden={isFormSubmitted}
            >
              Submit
              {loading && <FontAwesomeIcon icon={faSpinner as IconDefinition} spin />}
            </StyledButton>
          </>
        )}
      </div>
      <p>
        {formId === environmentVars.FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID
          ? reusableKycHeaderMessage
          : `In order to submit a new account application please complete the form below. If you need to submit multiple 
          entities at the same time this is possible by selecting the “add new” button at the bottom of the form.`}
        <br />
        <br />
        If you have any queries or questions during the process please reach out to your usual Alpha contact, or
        alternatively you can contact our client service team on{' '}
        <StyledHeaderLink href="tel:0203 854 9265">0203 854 9265</StyledHeaderLink> or via emailing us at{' '}
        <StyledHeaderLink href="mailto:clientservices-abs@alphagroup.com">
          clientservices-abs@alphagroup.com
        </StyledHeaderLink>
        .
      </p>
    </StyledHeader>
  );
};

export default Header;
