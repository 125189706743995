import { CountryPaymentPurposesDto } from '@alpha/payments-dtos';
import React, { useEffect, useState } from 'react';
import BackdropLoader from '../../../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import useAlphaSnackbar from '../../../../../../../hooks/useAlphaSnackbar';
import usePaymentsContext from '../usePaymentsContext';
import useSinglePaymentContext from '../useSinglePaymentContext';
import PaymentAmount from './PaymentAmount/PaymentAmount';
import PaymentDate from './PaymentDate/PaymentDate';
import PaymentFx from './PaymentFx/PaymentFx';
import PaymentPurpose from './PaymentPurpose/PaymentPurpose';
import SubmitButton from './SubmitButton/SubmitButton';

const PaymentFields: React.FC = () => {
  const sb = useAlphaSnackbar();
  const [datesLoaded, setDatesLoaded] = useState<boolean>(false);
  const [
    paymentPurposes,
    setPaymentPurposes,
  ] = useState<CountryPaymentPurposesDto>();
  const [purposesLoaded, setPurposesLoaded] = useState<boolean>(false);
  const [paymentFieldErrors, setPaymentFieldErrors] = useState<
    Record<string, string>
  >({});
  const { singlePaymentContext } = useSinglePaymentContext();
  const { paymentsContext } = usePaymentsContext();

  const isFormValid = () => {
    if (singlePaymentContext && paymentPurposes) {
      if (
        singlePaymentContext.currentPayment.beneficiary
        && singlePaymentContext.currentPayment.date
        && singlePaymentContext.currentPayment.paymentAmount
        && singlePaymentContext.currentPayment.reference
        && Object.keys(paymentFieldErrors).length === 0
      ) {
        if (
          paymentPurposes?.required
          && !singlePaymentContext.currentPayment.purposeOfPaymentCode) {
          singlePaymentContext.setIsValid(false);
          return;
        }
        if (
          requiresFx
          && (!singlePaymentContext.currentPayment.fixedSide
            || !singlePaymentContext.currentPayment.debitingAccountId)
        ) {
          singlePaymentContext.setIsValid(false);
          return;
        }
        singlePaymentContext.setIsValid(true);
      } else {
        singlePaymentContext.setIsValid(false);
      }
    }
  };

  useEffect(() => {
    isFormValid();
  }, [singlePaymentContext!.currentPayment]);

  if (
    !singlePaymentContext?.currentPayment.beneficiary
    || !paymentsContext.selectedDebitingAccount) {
    return <></>;
  }

  const requiresFx: boolean = paymentsContext.selectedDebitingAccount.currencyCode
    !== singlePaymentContext.currentPayment.beneficiary?.currencyCode;

  const handleAddPaymentToBatch = (): void => {
    const newCurrentPayments = [...paymentsContext.currentPayments];
    if (singlePaymentContext.editRow !== undefined) {
      newCurrentPayments[singlePaymentContext.editRow] = singlePaymentContext.currentPayment;
    } else {
      newCurrentPayments.push(singlePaymentContext.currentPayment);
    }
    paymentsContext.handleSetCurrentPayments(newCurrentPayments);
    singlePaymentContext.setCurrentPayment({
      reference: '',
      date: '',
      paymentAmount: 0,
    });
    if (singlePaymentContext.editRow !== undefined) {
      sb.trigger('Updated payment row', 'success');
      singlePaymentContext.setEditRow(undefined);
    }
  };

  const handleInputChange = (event: any) => {
    const fieldName = event.target.id;
    const newCurrentPayment: any = { ...singlePaymentContext.currentPayment };
    newCurrentPayment[fieldName] = event.target.value;
    singlePaymentContext.setCurrentPayment(newCurrentPayment);

    const clonedFieldErrors = { ...paymentFieldErrors };

    if (event.target.id === 'paymentAmount') {
      const numberRegex = /^\d+(\.\d{1,2})?$/;
      const newValue = event.target.value.replaceAll(',', '');
      if (!numberRegex.test(newValue)) {
        clonedFieldErrors[fieldName] = 'Please enter a correct amount';
        setPaymentFieldErrors(clonedFieldErrors);
        return;
      }
    } else {
      const specialCharRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      const referenceRegex = /[`!@#$%^&*_=\[\]{};"\\|<>~]/;

      if (event.target.id === 'reference') {
        if (referenceRegex.test(event.target.value)) {
          clonedFieldErrors[fieldName] = 'Please remove any special characters except /-?:().,\'+';
          setPaymentFieldErrors(clonedFieldErrors);
          return;
        }
      } else if (specialCharRegex.test(event.target.value)) {
        clonedFieldErrors[fieldName] = 'Please remove any special characters';
        setPaymentFieldErrors(clonedFieldErrors);
        return;
      }

      if (
        event.target.value.length > 0
        && event.target.value.trim().length <= 0
      ) {
        clonedFieldErrors[fieldName] = 'Please remove any whitespace';
        setPaymentFieldErrors(clonedFieldErrors);
        return;
      }

      if (event.target.value.length === 0 && (paymentPurposes?.required && event.target.id === 'purposeOfPaymentCode')) {
        clonedFieldErrors[fieldName] = 'This field is required';
        setPaymentFieldErrors(clonedFieldErrors);
        return;
      }
    }

    delete clonedFieldErrors[fieldName];
    setPaymentFieldErrors(clonedFieldErrors);
  };

  return (
    <div className="paymentFields">
      {(!datesLoaded || !purposesLoaded) && <BackdropLoader className="backdropLoader" testId="amir" withOverlay />}
      <PaymentDate
        requiresFx={requiresFx}
        setDatesLoaded={setDatesLoaded}
        selectedDebitingAccount={paymentsContext.selectedDebitingAccount}
        currentPayment={singlePaymentContext.currentPayment}
        debitingCurrencyCode={
          paymentsContext.selectedDebitingAccount.currencyCode!
        }
        setCurrentPayment={singlePaymentContext.setCurrentPayment}
      />
      <PaymentPurpose
        paymentPurposes={paymentPurposes}
        setPaymentPurposes={setPaymentPurposes}
        setPurposesLoaded={setPurposesLoaded}
        countryCode={
          singlePaymentContext.currentPayment.beneficiary?.bankCountryCode!
        }
        accountId={
          singlePaymentContext.currentPayment.beneficiary?.accountId!
        }
        currentPayment={singlePaymentContext.currentPayment}
        value={singlePaymentContext.currentPayment.purposeOfPaymentCode}
        handleInputChange={handleInputChange}
        setCurrentPayment={singlePaymentContext.setCurrentPayment}
        validation={paymentFieldErrors.purposeOfPaymentCode}
        referenceValidation={paymentFieldErrors.reference}
      />
      <div className="column">
        {
          requiresFx
          && (
            <PaymentFx
              handleInputChange={handleInputChange}
              currencyAccounts={paymentsContext.currencyAccounts}
              currentPayment={singlePaymentContext.currentPayment}
              setCurrentPayment={singlePaymentContext.setCurrentPayment}
            />
          )
        }
        <PaymentAmount
          currencyCode={singlePaymentContext.currentPayment.fixedSide
            || singlePaymentContext.currentPayment.beneficiary?.currencyCode}
          handleInputChange={handleInputChange}
          value={singlePaymentContext.currentPayment.paymentAmount || undefined}
          validation={paymentFieldErrors.paymentAmount}
        />
      </div>
      <SubmitButton
        disabled={!singlePaymentContext.isValid}
        handleAddPaymentToBatch={handleAddPaymentToBatch}
        editable={singlePaymentContext.editRow !== undefined}
      />
    </div>
  );
};

export default PaymentFields;
