import { Box } from '@mui/material';
import React from 'react';
import Status from 'components/Status/Status';
import { TStatusVariants } from 'components/Status';
import useStyles from '../Body.styles';

interface IProps {
  status: string;
}

const InvoiceStatus: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    status,
  } = props;
  const classes = useStyles();

  const handleVariant = (s: string): TStatusVariants => {
    const statusObj: Record<string, TStatusVariants> = {
      DELETED: 'default',
      OVERDUE: 'error',
      PENDING: 'info',
      PAID: 'success',
      SETTLED: 'success',
      UNVERIFIED: 'default',
    };
    return statusObj[s] || 'default';
  };

  const settleName = (s: string) => {
    const names: Record<string, string> = {
      PAID: 'SETTLED',
    };

    return names[s] || s;
  };

  return (
    <Box className={classes.label}>
      <Status variant={handleVariant(status)}>
        {settleName(status)}
      </Status>
    </Box>
  );
};

export default InvoiceStatus;
