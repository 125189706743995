import styled from '@mui/styled-engine';
import _default from 'theme';

const { typography, palette } = _default;

export const FooterStyled = styled('footer')(() => ({
  background: '#fff',
  padding: '7px 15px',
  textAlign: 'center',
  color: '#444',
  fontSize: '10px',
  fontFamily: typography.fontFamily,
  borderTop: '1px solid #d2d6de',

  '& span > a, strong > a': {
    color: palette.primary.main,
  },

  '& a': {
    textDecoration: 'none',
  },
  logo: {
    width: 75,
    height: 19,
    position: 'relative',
    marginRight: 40,
  },
  links: {
    display: 'flex',
    flexGrow: 1,
    gap: 20,

    '& span': {
      '& a': {
        color: '#FFFFFF',
      },
    },
  },
  company: {
    display: 'flex',
    paddingLeft: 20,

    '& a': {
      color: '#A0D6D0',
    },
  },
}));
