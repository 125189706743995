import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    beneficiaryCount: {
      padding: '30px 16px 0px 30px',
      fontSize: '14px',
      '& > span': {
        padding: '0px 0px 0px 0px',
        fontWeight: '700',

      },
    },
    drawerWrapper: {
      position: 'relative',
      '& > div > button': {
        zIndex: 101,
      },
    },
  }), { name: 'SelectBeneDrawer' },
);
export default useStyles;
