import React from 'react';
import { IUserManagementConfig, Titles } from '../../../models/userManagement';
import paths from '../../../routes.path';
import GroupManagerButton from '../components/Buttons/GroupManagerButton';
import { CreateNewUserButton } from '../components/Buttons';
import User from '../pages/User';
import Users from '../pages/Users';

const {
  base, allUsers, user, allEntities,
} = paths.userManagement;

export const routes = {
  [Titles.ENTITIES]: allEntities,
  [Titles.USERS]: allUsers,
  [Titles.INFORMATION]: undefined,
};

const listView = {
  breadcrumb: [{ text: 'Home', url: paths.base }],
  text: 'Entity Management',
  tabs: [Titles.USERS],
  rightAlignedCta: [
    <GroupManagerButton key="user-management-group-manager" />,
    <CreateNewUserButton key="create-new-user-button" />,
  ],
};

const singleView = (title: Titles) => ({
  breadcrumb: [{ text: 'Home', url: paths.base }, { text: title, url: routes[title] }],
  tabs: [Titles.INFORMATION],
  rightAlignedCta: [],
});

const defaultView = {
  ...listView,
  component: <Users />,
  defaultTabIndex: 0,
};

const userManagementConfig: IUserManagementConfig = {
  [base]: { ...defaultView },
  [allUsers]: { ...defaultView },
  [user]: {
    ...singleView(Titles.USERS),
    component: <User />,
    defaultTabIndex: 0,
  },
  // TODO implemented in phase 2
  // [allEntities]: {
  //   ...listView,
  //   component: <Entities />,
  //   defaultTabIndex: 1,
  // },
  // [entity]: {
  //   ...singleView(Titles.ENTITIES),
  //   component: <Entity />,
  //   defaultTabIndex: 0,
  // },
};

export default userManagementConfig;
