import { IATDraftResponse } from '@alpha/iat-dtos';
import React from 'react';
import { useDispatch } from 'react-redux';
import { InterAccountTransferDrawer as Drawer } from '../../components/InterAccountTransfer/Drawer/Drawer';
import DrawerBackdropLoader from '../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import { InterAccountTransferFormContext } from '../../context/InterAccountTransferFormContext';
import { actions } from '../../store/outstandingTables/outstandingTables.reducer';
import { ISelectedAccount } from '../CurrencyAccounts/AccountInfoDrawer/AccountInfoDrawer';
import TransactionInfoDrawer from '../Transactions/TransactionsInfoDrawer/TransactionsInfoDrawer';
import BookFxDrawer from './BookFxDrawer/BookFxDrawer';
import DebitingAndCreditingDrawer from './DebitingAndCreditingDrawer/DebitingAndCrediting';
import useInterAccountTransfer from './useInterAccountTransfer';

interface IProps {
  selectedAccount: ISelectedAccount | undefined;
  selectedDraftIAT?: IATDraftResponse;
  open: boolean;
  caDrawer: 'IAT' | 'AccountDrawer' | 'PendingTransfers' | false;
  handleCloseIATDrawer: () => void
  setSelectedDraftIAT:
  React.Dispatch<React.SetStateAction<IATDraftResponse | undefined>>;
  multiEntity?: boolean;
  reloadData: () => void;
}
const InterAccountTransfer: React.FC<IProps> = (props: IProps) => {
  const {
    selectedAccount,
    open,
    caDrawer,
    selectedDraftIAT,
    handleCloseIATDrawer,
    setSelectedDraftIAT,
    multiEntity,
    reloadData,
  } = props;

  const accountId = multiEntity
    ? (selectedDraftIAT?.accountId || selectedAccount?.account?.accountId)
    : undefined;

  const {
    currencyAccounts,
    form,
    loading,
    transactionData,
    drawerState,
    setDrawerState,
    handleResetFormStateAndCloseIATDrawer,
    setTransactionData,
    handleOpenTransactionDataDrawer,
  } = useInterAccountTransfer(
    handleCloseIATDrawer,
    setSelectedDraftIAT,
    open,
    caDrawer,
    selectedAccount,
    selectedDraftIAT,
    accountId,
  );

  const dispatch = useDispatch();

  return (
    <>
      <Drawer.Drawer
        open={open}
        onClose={handleResetFormStateAndCloseIATDrawer}
        anchor="right"
      >
        <InterAccountTransferFormContext.Provider value={form}>
          {drawerState === 'BookFX' ? (
            <BookFxDrawer
              form={form}
              handleClose={() => setDrawerState('DebitAndCredit')}
              handleOpenTransactionDataDrawer={handleOpenTransactionDataDrawer}
              handleResetFormStateAndCloseIATDrawer={handleResetFormStateAndCloseIATDrawer}
              accountId={accountId}
            />
          ) : (
            <DebitingAndCreditingDrawer
              selectedDraftIAT={selectedDraftIAT}
              accountId={accountId}
              currencyAccounts={currencyAccounts}
              isValid={Boolean(form.isValid
                && form.values.creditingAccount.amount
                && form.values.debitingAccount.amount)}
              isFxTrade={form.values.debitingAccount.currencyAccount?.currencyCode
                  !== form.values.creditingAccount.currencyAccount?.currencyCode}
              rate={form.values.indicativeRate?.rate}
              reloadData={reloadData}
              handleSubmit={form.handleSubmit}
            />
          )}
        </InterAccountTransferFormContext.Provider>
        <DrawerBackdropLoader display={loading} text="Submitting IAT request" />
      </Drawer.Drawer>
      <TransactionInfoDrawer
        displayFinishButton
        onClose={() => {
          setTransactionData(undefined);
          handleResetFormStateAndCloseIATDrawer();
          dispatch(actions.updateDashboardAction(`FINISH_IAT_ACTION_${selectedDraftIAT?.id}`));
          reloadData();
        }}
        transaction={transactionData}
      />
    </>
  );
};

export default InterAccountTransfer;
