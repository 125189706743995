import * as yup from 'yup';

export const initialValues:CreateEntityGroupRequest = {
  entityGroupName: '',
  accountIds: [],
  entityGroupColour: '',
};

export type CreateEntityGroupRequest = {
  entityGroupName: string;
  accountIds: string[];
  entityGroupColour?: string | undefined
}

export const validation = {
  entityGroupName: yup
    .string()
    .matches(RegExp('^[/A-Za-z0-9.,/?:()+‘ -]*$'), 'Special characters are not allowed')
    .max(30, 'Must be 30 characters or less')
    .required('Entity Group Name is required'),
  accountIds: yup
    .array(yup.string().required())
    .min(1, 'must have at least one account in new group')
    .max(90, 'can initially add 90 accounts to group. More accounts can be added to group later')
    .required(),
  entityGroupColour: yup
    .string(),
};
export default validation;
