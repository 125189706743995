import getSymbolFromCurrency from 'currency-symbol-map';
import React, { memo } from 'react';
import AmountAndBalance from '../../../components/InterAccountTransfer/AmountAndBalance/AmountAndBalance';
import { TIATAccountTypes } from '../../../models/interAccountTransfer';
import InterAccountTransferService from '../../../services/InterAccountTransfer/interAccountTransfer.service';
import { formatNumber } from '../../../utils/currency.helpers';
import useInterAccountTransferFormContext from '../useInterAccountTransferFormContext';

interface IProps {
  type: TIATAccountTypes;
  disabled?: boolean;
}
const AmountAndBalanceContainer: React.FC<IProps> = ({
  type,
  disabled,
}: IProps) => {
  const { form, isSectionComplete } = useInterAccountTransferFormContext();

  let actionBalanceId: string;
  let actionBalanceLabel: string;
  let formattedBalance: string;
  let startAdornment: string;
  let fixedSideIndicator: string;
  const tooltipText: string = 'Please select a crediting account';
  const { currencyAccount, amount } = type === 'DEBITING' ? form.values.debitingAccount : form.values.creditingAccount;
  const currencySymbol = getSymbolFromCurrency(currencyAccount?.currencyCode!);
  if (type === 'DEBITING') {
    actionBalanceId = 'debitingAccount.amount';
    actionBalanceLabel = 'debit';

    const calculateBalanceAfter = currencyAccount?.availableBalance! - (amount || 0);
    formattedBalance = formatNumber(calculateBalanceAfter, 2);
    startAdornment = `-${currencySymbol}`;
  } else {
    actionBalanceId = 'creditingAccount.amount';
    actionBalanceLabel = 'credit';

    const calculateBalanceAfter = currencyAccount?.availableBalance! + (amount || 0);
    formattedBalance = formatNumber(calculateBalanceAfter, 2);
    startAdornment = `+${currencySymbol}`;
  }

  if (form.values.creditingAccount.currencyAccount?.currencyCode
    === form.values.debitingAccount.currencyAccount?.currencyCode
    || !form.values.creditingAccount.currencyAccount) {
    fixedSideIndicator = 'hidden';
  } else if (currencyAccount?.currencyCode === form.values.fixedSide
    && form.values.creditingAccount?.amount && form.values.debitingAccount?.amount
    && form.values.creditingAccount?.amount !== 0 && form.values.debitingAccount?.amount !== 0) {
    fixedSideIndicator = 'active';
  } else {
    fixedSideIndicator = 'inactive';
  }

  const handleChange = (event: any) => {
    form.handleChange(event);
    const opposingCurrencyAccount = type === 'DEBITING' ? form.values.creditingAccount.currencyAccount : form.values.debitingAccount.currencyAccount;
    const opposingFieldAmount = InterAccountTransferService.calculateAmount(
      type,
      event.target.value,
      opposingCurrencyAccount?.currencyCode! ?? '',
      form.values.indicativeRate?.rate,
    );

    form.setFieldValue(
      type === 'DEBITING'
        ? 'creditingAccount.amount'
        : 'debitingAccount.amount',
      opposingFieldAmount,
      false,
    );
    form.setFieldValue(
      'fixedSide',
      type === 'DEBITING'
        ? form.values.debitingAccount.currencyAccount?.currencyCode
        : form.values.creditingAccount.currencyAccount?.currencyCode,
    );
  };

  const handleDisabled = () => {
    if (!isSectionComplete('CREDITING')
      || !isSectionComplete('DEBITING')
      || requiresIndicativeRate()) {
      return true;
    }
    return false;
  };

  const isSameCurrency = () => form.values.creditingAccount.currencyAccount?.currencyCode === form.values.debitingAccount.currencyAccount?.currencyCode;

  const hasIndicativeRate = () => {
    if (form.values.indicativeRate === null || form.values.indicativeRate === undefined) {
      return false;
    }
    return true;
  };

  const requiresIndicativeRate = () => !hasIndicativeRate() && !isSameCurrency();

  const handleFilterChars = (e: any) => {
    const letters = /^[A-Za-z]*[^.0-9]/;
    if (e.key.length === 1 && e.key.match(letters)) return e.preventDefault();
  };

  if (!isSectionComplete(type)) return null;

  return (
    <AmountAndBalance
      actionBalanceId={actionBalanceId}
      actionBalanceLabel={actionBalanceLabel}
      disabled={disabled || handleDisabled()}
      disableTooltip={handleDisabled()}
      tooltipText={tooltipText}
      currencyCode={currencyAccount?.currencyCode}
      friendlyName={currencyAccount?.friendlyName}
      amount={amount}
      currencySymbol={currencySymbol!}
      formattedBalance={formattedBalance}
      handleChange={handleChange}
      handleOnKeyDown={handleFilterChars}
      handleBlur={form.handleBlur}
      startAdornment={startAdornment}
      type={type}
      fixedSideIndicator={fixedSideIndicator}
    />
  );
};

export default memo(AmountAndBalanceContainer);
