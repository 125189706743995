import { IndicativeRateResponse } from '@alpha/fx-dtos';
import * as yup from 'yup';
import { TCurrencyAccount } from '../../models/currencyAccounts';
import { TIATFixedSide, TIATQuote } from '../../models/interAccountTransfer';

export type TFormAccount = {
  amount?: number;
  currencyAccount?: TCurrencyAccount;
  reference: string;
}

export type TInterAccountTransferForm = {
  debitingAccount: Omit<TFormAccount, 'reference'>;
  creditingAccount: TFormAccount;
  indicativeRate?: IndicativeRateResponse;
  liveRate?: TIATQuote;
  fixedSide: TIATFixedSide;
  tradeId?: string;
  tradeFriendlyId?: string;
  iatDraftId?: string;
};

export enum errorMessages {
  MINAMOUNT = 'Amount too small',
  INSUFFICIENT_FUNDS = 'Debit amount cannot be greater than debit balance',
}

export const initialValues: TInterAccountTransferForm = {
  debitingAccount: {},
  creditingAccount: {
    reference: '',
  },
  fixedSide: 'SELL',
};

const validation = {
  debitingAccount: yup.object().shape({
    amount: yup
      .number()
      .typeError('Amount must be a number')
      .required('Please enter a valid amount'),
    currencyAccount: yup
      .object()
      .required('Please select a debiting account'),
  }),
  creditingAccount: yup.object().shape({
    amount: yup
      .number()
      .typeError('Amount must be a number')
      .required('Please enter a valid amount'),
    currencyAccount: yup
      .object()
      .required('Please select a crediting account'),
    reference: yup
      .string()
      .matches(/^^[A-Za-z0-9 _]*$/, 'Special characters are not allowed'),
  }),
};

export default validation;
