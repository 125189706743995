import { Box, styled } from '@mui/material';

export const StyledBox = styled(Box)({
  position: 'fixed',
  left: '0',
  bottom: '0',
  width: '100%',
  backgroundColor: 'white',
  borderTop: '1px solid #D4D4D4',
  height: '100px',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
});
