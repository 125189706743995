import { useState } from 'react';
import { useDispatch } from 'react-redux';
import InvoiceService from '../services/Invoices/invoices.service';
import { actions } from '../store/outstandingTables/outstandingTables.reducer';

export const useInvoicePermissions = () => {
  const dispatch = useDispatch();
  const [isAuthorizedInvoiceManager, setIsAuthorizedInvoiceManager] = useState<boolean>(false);

  const getUserInvoiceEntities = async () => {
    const response = await InvoiceService.getInvoiceManagerEntities();
    setIsAuthorizedInvoiceManager(response.length > 0);
    dispatch(actions.updateIsAuthorizedToViewInvoices(response.length > 0));
    return response;
  };

  return {
    isAuthorizedInvoiceManager,
    getUserInvoiceEntities,
  };
};

export default useInvoicePermissions;
