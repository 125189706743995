import React from 'react';
import { Box } from '@mui/material';
import { ILoaderProps, Loader } from 'components/Loader';
import clsx from 'clsx';
import useStyles from './BackdropLoader.styles';

interface IProps extends ILoaderProps {
  positionFixed?: boolean;
}

const BackdropLoader: React.FC<IProps> = ({ positionFixed, ...props }: IProps) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx([classes.root, props.className])}
      style={positionFixed ? { position: 'fixed' } : {}}
    >
      <Loader {...props} />
    </Box>
  );
};

export default BackdropLoader;
