import { Box, Typography } from '@mui/material';
import React from 'react';
import moment from 'moment';
import { UserStatus } from '@portal/user-types';
import useStyles from './index.styles';
import { UserStatusEnum } from '../../helpers/permissions';
import getStatusVariant from '../../utils/getStatusVariant';

interface IDetailsProps {
  status: UserStatus,
  date: Date,
  username: string,
}
const Details = (props: IDetailsProps) => {
  const { date, status, username } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="h5">Details</Typography>
      <Box className={classes.detailRow}>
        <Typography variant="h6" className={classes.label}>Status</Typography>
        {getStatusVariant(UserStatusEnum[status], classes.status)}
      </Box>
      <Box className={classes.detailRow}>
        <Typography variant="h6" className={classes.label}>Username</Typography>
        <Typography data-testid="username" className={classes.detailText}>
          {username}
        </Typography>
      </Box>
      <Box className={classes.detailRow}>
        <Typography variant="h6" className={classes.label}>Last Updated</Typography>
        <Typography data-testid="date" className={classes.detailText}>
          {moment(date).format('DD/MM/YYYY')}
        </Typography>
      </Box>
    </Box>
  );
};
export default Details;
