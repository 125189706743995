import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserRoleRequestResponse } from '@portal/user-types';
import UserGuidesCard from 'domain/UserManagement/components/UserGuidesCard';
import BatchRequestsControls from '../../components/BatchRequestsControls';
import StickyFooterContainer from '../../../../components/StickyFooter';
import PendingApprovalTable from './PendingApprovalTable';
import ApprovedUsersTable from './ApprovedUsersTable';
import useStyles from './index.styles';
import { userManagementState } from '../../../../store/userManagement/userManagement.reducer';
import { TStore } from '../../../../store';
import InReviewTable from './InReviewTable';
import useDebouncedSearch from '../../../../hooks/useDebouncedSearch';
import SearchBar from '../../components/SearchBar';

const Users = () => {
  const { cardContainer } = useStyles();
  const {
    isEntityManager,
  } = useSelector<TStore, userManagementState>(
    (state) => state.userManagementPage,
  );
  const [userGuidesOpen, setUserGuidesOpen] = useState(
    () => localStorage.getItem('acknowledged-user-guides') === 'true',
  );

  const toggleClose = () => {
    localStorage.setItem('acknowledged-user-guides', 'false');
    setUserGuidesOpen(false);
  };
  const { search, setSearch, clearSearch } = useDebouncedSearch();
  const [tablesCount, setTablesCount] = useState<{ [key: string]: number }>({});
  const [selectedPendingRequestsIds, setSelectedPendingRequestsIds] = useState<
    UserRoleRequestResponse[]>([]);

  const totalTablesCount = useMemo(() => Object.keys(tablesCount).reduce(
    (total, currentTable) => total + (
      currentTable in tablesCount && tablesCount[currentTable] ? tablesCount[currentTable] : 0
    ), 0,
  ), [tablesCount]);

  const tableProps = { setTablesCount, search };

  return (
    <div data-testid="users-page" className={cardContainer}>
      {isEntityManager && userGuidesOpen
        && (
          <UserGuidesCard handleClose={toggleClose} />
        )}

      <SearchBar
        count={totalTablesCount}
        debouncedSearch={search}
        setDebouncedSearch={setSearch}
        clearSearch={clearSearch}
      />

      {isEntityManager && (
        <>
          <PendingApprovalTable {
            ...{
              ...tableProps,
              setSelectedRequests: setSelectedPendingRequestsIds,
            }
          }
          />
          <InReviewTable {...tableProps} />
        </>
      )}
      <ApprovedUsersTable {...tableProps} />
      {selectedPendingRequestsIds.length ? (
        <StickyFooterContainer>
          <BatchRequestsControls
            selectedRequests={selectedPendingRequestsIds}
          />
        </StickyFooterContainer>
      ) : <></>}
    </div>
  );
};

export default Users;
