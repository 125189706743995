import React, { useEffect, useState } from 'react';
import {
  Switch, Route, useLocation, Redirect,
} from 'react-router-dom';
import Onboarding from './Onboarding';
import routes from '../../routes.path';
import useAuth from '../../hooks/useAuth';
import { TSignIn } from '../../models/auth';
import browserHistory from '../../services/history/browserHistory';
import { AuthContext } from '../../context/AuthContext';
import Returning from './Returning';
import { TUser } from '../../models/user';
import PasswordReset from './PasswordReset';
import ForcedSignOutPage from '../../components/ForcedSignOutPage';

export interface IAuthProps {
  userObject?: TSignIn;
  userInfo?: TUser;
}

const Auth = () => {
  const {
    loggedIn, userInfo, setUserObject, userObject,
  } = useAuth();
  const [temporaryPassword, setTemporaryPassword] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    shouldRedirectToWelcome();
  }, []);

  function shouldRedirectToWelcome() {
    if (loggedIn && location.pathname.indexOf(routes.auth.welcome) < 0) {
      browserHistory.push(routes.auth.welcome);
    }
  }

  function handleSetUserObject(_userObject: TSignIn) {
    setUserObject(_userObject);
  }

  return (
    <AuthContext.Provider value={{
      userObject,
      handleSetUserObject,
      setTemporaryPassword,
      temporaryPassword,
    }}
    >
      <Switch>
        <Route path={routes.forcedSignOut}>
          <ForcedSignOutPage />
        </Route>
        <Route path={routes.auth.onboarding.base}>
          <Onboarding userObject={userObject} userInfo={userInfo} />
        </Route>
        <Route path={routes.auth.passwordReset.base}>
          <PasswordReset />
        </Route>
        <Route path={routes.auth.base}>
          <Returning userObject={userObject} userInfo={userInfo} />
        </Route>
        <Redirect to={routes.notFoundPage} />
      </Switch>
    </AuthContext.Provider>
  );
};

export default Auth;
