import { Box } from '@mui/material';
import React, { memo } from 'react';
import { List } from 'react-virtualized';
import { EntityGroup } from '../../../../models/entityGroups';
import useStyles from './DrawerBody.styles';
import EntityGroupCard from './EntitiesGroupCard/EntityGroupCard';

interface IProps {
  entityGroupsForUser: EntityGroup[];
  setCanEditOrDeleteGroup: React.Dispatch<React.SetStateAction<boolean>>;
  canEditOrDeleteGroup: boolean;
}
const DisplayDrawer: React.FC<IProps> = (props: IProps) => {
  const {
    entityGroupsForUser,
    setCanEditOrDeleteGroup,
    canEditOrDeleteGroup,

  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.bodyWrapper}>
      {entityGroupsForUser
        && (
          <List
            autoHeight
            autoWidth
            height={620}
            width={610}
            rowHeight={144}
            rowCount={entityGroupsForUser.length}
            rowRenderer={({ index, style }) => {
              const entityGroup = entityGroupsForUser[index];
              return (
                <div style={style} key={index}>
                  <EntityGroupCard
                    groupName={entityGroup.name}
                    numberOfEntities={entityGroup?.accounts?.length}
                    accounts={entityGroup?.accounts}
                    colour={entityGroup.colour}
                    groupId={entityGroup.id}
                    setCanEditOrDeleteGroup={setCanEditOrDeleteGroup}
                    canEditOrDeleteGroup={canEditOrDeleteGroup}
                  />
                </div>
              );
            }}
          />
        )}
    </Box>
  );
};

export default memo(DisplayDrawer);
