import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAutocompleteSelectOption } from 'components/MultiAutoCompleteSelect';
import { TPayment, TPendingPayment, TPendingPaymentParams } from '../../models/payments';

enum ESortDir {
  ASC = 'asc',
  DESC = 'desc',
}

export type TNewPayment = {
  id: string;
  name: string;
  sellCurrency: IAutocompleteSelectOption | null;
  buyAmount: string;
  purposeOfPayment: IAutocompleteSelectOption | null;
  paymentReference: string;
  buyCurrency: IAutocompleteSelectOption | null;
  date: string | null;
  country?: string | null;
  iban?: string | null;
  bankCode?: string | null;
  bankName?: string | null;
  swift?: string | null;
  accountNumber?: string | null;
  creditName?: string | null;
  credit?: string | null;
  email?: string | null;
  uploadBy?: string | null;
  uploadDate?: string | null;
};

export type TPaymentsStatus = 'COMPLETED' | 'ERROR' | 'INITIATE';

export type TPaymentsUploadedItemsType = 'VALID' | 'INVALID' | 'REJECTED';

export type TPaymentsUploadedItems = {
  id: string;
  name: string;
  date: number;
  sellCurrency: string;
  currency: string;
  amount: string;
  type: TPaymentsUploadedItemsType;
}

export type TPaymentBatchDetails = {
  batchId?: string,
  batchStatus?: string,
  batchErrors?: string[],
  batchName?: string,
  totalRecords?: number;
  totalRejectedPayments?: number;
  totalValidatedPayments?: number;
  totalInvalidPayments?: number;
  accountId?: string;
  fraudWarningAcknowledged?: boolean;
};

export type TPaymentsStore = {
  data: TPayment[];
  totalRecords: number;
  newItems: TNewPayment[];
  uploadedItems: TPaymentsUploadedItems[];
  loaded: boolean,
  pendingPayments: {
    totalItems: number,
    data: TPendingPayment[],
  },
  batchDetails?: TPaymentBatchDetails,
  accountId?: string,
};

export type TPaymentQuery = {
  state?: string;
  orderBy?: string;
  direction?: ESortDir;
  top?: number;
  skip?: number;
  search?: string;
};

export type TBatchStatus = {
}

const initialState: TPaymentsStore = {
  data: [],
  newItems: [],
  totalRecords: 0,
  uploadedItems: [],
  loaded: false,
  pendingPayments: {
    totalItems: 0,
    data: [],
  },
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    loadPayments: (state, action: PayloadAction<TPaymentQuery>): TPaymentsStore => state,
    loadedPayments: (state, action: PayloadAction<{
      data: TPayment[];
      totalRecords: number
    }>): TPaymentsStore => ({
      ...state,
      data: action.payload.data,
      totalRecords: action.payload.totalRecords,
    }),
    addNewItems: (state, action: PayloadAction<TNewPayment | TNewPayment[]>): TPaymentsStore => ({
      ...state,
      newItems: [
        ...state.newItems, ...(Array.isArray(action.payload) ? action.payload : [action.payload]),
      ],
    }),
    updateNewItems: (state, action: PayloadAction<TNewPayment>): TPaymentsStore => ({
      ...state,
      newItems: state.newItems.map((item) => (
        item.id === action.payload.id ? action.payload : item
      )),
    }),
    clearNewItems: (state): TPaymentsStore => ({
      ...state,
      newItems: [],
    }),
    removeNewItem: (state, action: PayloadAction<TNewPayment>): TPaymentsStore => ({
      ...state,
      newItems: state.newItems.filter((item) => item.id !== action.payload.id),
    }),
    clear: (state, action: PayloadAction<void>): TPaymentsStore => initialState,
    triggerBatchStatus: (state): TPaymentsStore => state,
    updateBatchId: (state, action: PayloadAction<string>) => ({
      ...state,
      batchDetails: {
        ...state.batchDetails,
        batchId: action.payload,
      },
    }),
    updateAccountId: (state, action: PayloadAction<string>) => ({
      ...state,
      batchDetails: {
        ...state.batchDetails,
        accountId: action.payload,
      },
    }),
    updateBatchStatus: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      batchDetails: {
        ...state.batchDetails,
        batchStatus: action.payload,
      },
    }),
    updateBatchDetails: (state, action: PayloadAction<{
      batchId?: string,
      batchStatus?: string,
      batchName?: string,
      totalRecords?: number;
      totalRejectedPayments?: number;
      totalValidatedPayments?: number;
      totalInvalidPayments?: number;
    }>) => ({
      ...state,
      batchDetails: {
        ...state.batchDetails,
        ...action.payload,
      },
    }),
    updateFraudFlagValue: (state, action: PayloadAction<boolean | undefined>) => ({
      ...state,
      batchDetails: {
        ...state.batchDetails,
        fraudWarningAcknowledged: action.payload,
      },
    }),
    clearBatchDetails: (state) => ({
      ...state, batchDetails: {},
    }),
    reuploadPayment: (state) => initialState,
    loadPendingPayments: (
      state, action: PayloadAction<TPendingPaymentParams>,
    ): TPaymentsStore => ({ ...state, loaded: false }),
    loadedPendingPayments: (
      state, action: PayloadAction<{ totalItems: number, data: TPendingPayment[] }>,
    ): TPaymentsStore => ({
      ...state,
      loaded: true,
      pendingPayments: {
        totalItems: action.payload.totalItems,
        data: action.payload.data,
      },
    }),
  },
});

export const { actions } = paymentsSlice;
