import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 0,
      width: 50,
      height: 50,
      marginLeft: 'auto',
      alignSelf: 'flex-end',
    },
    header: {
      padding: '70px 35px 35px 50px',
      marginBottom: 45,
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    title: {
      fontSize: 22,
      fontWeight: typography.fontWeightSemiBold,
    },
    subtitle: {
      fontSize: 11,
      fontWeight: typography.fontWeightSemiBold,
      textTransform: 'uppercase',
      color: 'rgba(52, 52, 52, 0.5)',
      letterSpacing: 1.5,
    },
    headerText: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 20,
    },
    headerInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: 20,
    },
    headerStats: {
      display: 'flex',
    },
    countryCurrency: {
      '& > div': {
        marginRight: 35,
      },
    },
  }),
  { name: 'BeneInfoDrawerHeader' },
);

export default useStyles;
