import { Box } from '@mui/material';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Tooltip from '../../../../../../../components/Tooltip/Tooltip';
import useStyles from './ErrorRow.styles';
import TooltipTitle from './TooltipTitle/TooltipTitle';

interface IErrorRow {
  batchError?: string;
  paymentErrors?: string[];
  paymentIndex: number;
  row: number;
}

const ErrorRow: React.FC<IErrorRow> = (props: IErrorRow) => {
  const {
    paymentIndex, paymentErrors, row, batchError,
  } = props;
  const classes = useStyles();

  const setErrors = (): string => {
    if (batchError) {
      return batchError;
    }
    if (paymentErrors) {
      return paymentErrors[0];
    }
    return '';
  };

  return (
    <Tooltip
      placement="bottom"
      title={<TooltipTitle paymentErrors={paymentErrors} row={row} />}
      disableHoverListener={!(paymentErrors && paymentErrors.length > 1)}
    >
      {paymentErrors && paymentErrors.length > 1
        ? (
          <div
            key={paymentIndex}
            className={classes.row}
          >
            <b>Multiple Errors</b>
            <FontAwesomeIcon className={classes.icon} icon={faInfoCircle} />
          </div>
        )
        : <Box key={paymentIndex} className={classes.row}>{setErrors()}</Box>}
    </Tooltip>
  );
};

export default ErrorRow;

// color="#00A09B"
