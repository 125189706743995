import styled from '@mui/styled-engine';
import { Button } from '@mui/material';
import theme from 'theme';

const { typography, palette } = theme;

export const StyledMenuDropdownButton = styled(Button)({
  '&.menuButton': {
    fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    fontSize: 12,
    letterSpacing: 1.5,
    backgroundColor: palette.primary.main,
    color: '#ffffff',
    borderRadius: 0,
    padding: '13px 20px',
    fontWeight: typography.fontWeightSemiBold,
    '&:hover': {
      backgroundColor: '#0A6A5F',
    },
    '&:disabled': {
      backgroundColor: 'rgba(9, 131, 117, 0.2)',
      color: 'rgba(255, 255, 255, 0.4)',
    },
  },
  '&.spacer': {
    margin: '0 8px',
  },
  '&.buttonIcon': {
    marginLeft: 18,
    color: 'inherit',
    opacity: 1,
    height: 'inherit',
  },
});
