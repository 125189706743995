import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiFormLabel-root, .MuiTypography-subtitle2': {
        fontSize: '14px',
        color: '#333333',
        marginBottom: '8px',
      },
      '& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    selectAccount: {
      marginBottom: '40px',
    },
    inputAmount: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    willDebit: {
      width: '100%',
      '& .MuiInputBase-input': {
        width: '80%',
        maxWidth: '250px',
      },
      '& .MuiInput-root': {
        fontSize: '40px',
        fontWeight: 300,
        color: '#343434',
      },
    },
    liveRateLabel: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    imageHolder: {
      display: 'flex',
      overflow: 'hidden',
      borderRadius: '50%',
      marginLeft: '8px',
      marginRight: '4px',
      width: '20px',
      height: '20px',
      border: '2px solid rgb(224,218,218)',
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'relative',
      alignItems: 'center',
      padding: '5px',
      '& img': {
        width: '22px',
        height: '30px',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
    },
    balanceAfter: {
      width: '200px',
      maxWidth: '250px',
      overflow: 'hidden',
      '& span': {
        marginLeft: '4px',
      },
      '& .MuiTypography-subtitle2': {
        textAlign: 'left',
      },
    },
    fixedIndicatorActive: {
      backgroundColor: 'rgba(8, 17, 31, 0.53)',
      color: '#FFFFFF',
    },
    fixedIndicatorInactive: {
      backgroundColor: 'rgba(8, 17, 31, 0.106)',
      color: '#FFFFFF',
    },
    fixedIndicatorHidden: {
      display: 'none',
      backgroundColor: 'rgba(8, 17, 31, 0.106)',
      color: '#FFFFFF',
    },
    amountInput: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    input: {
      '&::placeholder': {
        color: 'red',
      },
    },
    black: {
      color: '#343434 !important',
    },
    grey: {
      color: 'rgb(170, 170, 170) !important',
    },
    red: {
      color: '#b95656 !important',
      '& .Mui-disabled':{
        textFillColor: '#b95656',
      }
    },
    green: {
      color: '#098375 !important',
      '& .Mui-disabled':{
        textFillColor: '#098375',
      }
    },
    bold: {
      fontWeight: 'bold',
    },
    error: {
      position: 'absolute',
      fontSize: '12px !important',
    },
  }),
  { name: 'InterAccountTransferActionAccounts' },
);

export default useStyles;
