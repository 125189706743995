import theme, { makeStyles } from 'theme';

const { palette } = theme;

export const useStyles = makeStyles(
  () => ({
    fieldLabel: {
      marginBottom: '6px',
    },
    requiredFieldLabel: {
      color: palette.error.main,
      marginBottom: '6px',
    },
    dropdown: {
      backgroundColor: 'transparent',
      '& .MuiFormControl-root': {
        '& .MuiInputBase-root': {
          backgroundColor: palette.grey[100],
          borderRadius: '5px',
          '&::before': {
            borderBottom: 'none',
          },
          '&::after': {
            display: 'none',
          },
          height: 'auto',
          padding: '15px 50px 15px 13px',
          ' & .MuiInputBase-input': {
            fontSize: '16px',
            padding: 0,
            height: 'auto',
          },
        },
      },
    },
    text: {
      color: '#2680EB',
      fontSize: '14px',
      alignSelf: 'center',
    },
    icon: {
      color: 'rgb(102 181 233)',
      alignSelf: 'start',
      justifySelf: 'start',
      margin: '5px 12px 0 0',
    },
  }),
  { name: 'EntitiesMultiSelectDropDown' },
);

export default useStyles;
