import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    wrapper: {
      color: '#989898',
      fontWeight: typography.fontWeightSemiBold,
      '& > span.bold': {
        color: '#222222',
      },
    },
  }),
  { name: 'BatchSummary' },
);

export default useStyles;
