/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  TableBody, TableCell, TableHead, TableProps, TableRow,
} from '@mui/material';
import { StyledTable, StyledTableCell, StyledTableRow } from './index.styles';

interface IStyledGenericTableColumn {
    header: JSX.Element | string;
    accessor: string;
    align?: 'right' | 'left';
    width?: number;
    className?: string;
}

export interface IStyledGenericTableProps extends TableProps {
    testId: string;
    columns: Array<IStyledGenericTableColumn>;
    data: any[];
    handleTableRowClick?: Function;
    clickable?: boolean;
    noHeader?: boolean;
}

const StyledGenericTable: React.FC<IStyledGenericTableProps> = (props) => {
  const {
    columns, data, testId, handleTableRowClick, clickable, noHeader, className,
  } = props;
  return (
    <StyledTable data-testid={testId} className={className}>
      {!noHeader && (
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell
                key={i}
                align={column.align}
                style={{ width: column.width }}
                className={column.className}
              >
                {column.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {data.map((record, i) => (
          <StyledTableRow
            key={`styled-table-row=${i}`}
            onClick={(): void => handleTableRowClick && handleTableRowClick(record)}
            clickable={clickable?.toString()}
            data-testid={`${testId}-row-${i + 1}`}
          >
            {columns.map((column, j) => (
              <StyledTableCell
                key={`styled-table-cell-${j}`}
                align={column.align}
                style={{ width: column.width }}
                accessor={column.accessor}
                className={column.className}
                data-testid={`${testId}-col-${i + 1}`}
              >
                {record[column.accessor]}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default StyledGenericTable;
