import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    header: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      padding: '60px 25px 15px 50px',
    },
    headerContent: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > span:first-child': {
        fontSize: 22,
        fontWeight: typography.fontWeightBold,
      },
    },
    title: {
      marginTop: '15px',
      marginBottom: '10px',
      '& > span:first-child': {
        fontSize: 18,
        fontWeight: typography.fontWeightLight,
        borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      },
    },
    subHeaderContent: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 40,
      fontWeight: typography.fontWeightLight,
      color: '#098375',
    },
    btn: {
      alignSelf: 'flex-end',
      '& .MuiButtonBase-root': {
        borderRadius: '5px',
      },
    },
  }),
  { name: 'DrawerHeader' },
);

export default useStyles;
