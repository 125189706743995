import { Table, TableCell, TableRow } from '@mui/material';
import styled from '@mui/styled-engine';

import _default from 'theme';

const { typography, palette } = _default;

export const StyledTable = styled(Table)(({ funding }: { funding?: boolean }) => ({
  '& th.MuiTableCell-root, td.MuiTableCell-root': {
    fontSize: 14,
    height: 54,
    padding: '16px 0',
  },
  '& th.MuiTableCell-head': {
    textTransform: 'uppercase',
    fontSize: 11,
    fontWeight: typography.fontWeightSemiBold,
    padding: '14px 0',
    letterSpacing: 1.65,
  },
  '& th.paymentTitle': {
    paddingRight: funding ? '4%' : 'inherit',
  },
  '& td.currencyAmount': {
    padding: '16px 0',
    '& > span': {
      display: 'flex',
      width: '70%',
      '& > span:first-of-type': {
        width: 32,
        marginRight: 16,
      },
      '& > span:last-of-type': {
        fontWeight: typography.fontWeightBold,
      },
    },
  },
  '& td.currencyFlag': {
    paddingLeft: 20,
  },
  '& td.currencyPayments': {
    paddingRight: funding ? '4%' : '2%',
  },
  '& td.paymentAmount': {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 12,
    },
  },
  '& td.paymentDate': {
    '& > svg': {
      marginRight: 7,
    },
  },
}));

export const StyledTableRow = styled(TableRow)(({ clickable }: { clickable?: string }) => ({
  ...(clickable === 'true' && { cursor: 'pointer' }),
  color: palette.primary.main,
  fontSize: 14,
  '&:hover': {
    backgroundColor: '#FCFCFC',
  },
}));

export const StyledTableCell = styled(TableCell)(({ accessor }: { accessor: string }) => ({
  ...(accessor === 'link' && {
    color: palette.primary.main,
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#FCFCFC',
    },
  }),

  ...(accessor === 'buttons' && {
    '& a': {
      display: 'inline-block',
    },
    '& > button:not(:last-child), & > a:not(:last-child)': {
      marginRight: 5,
    },
  }),
}));
