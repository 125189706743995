import {
  FormControl, MenuItem, Select, Tooltip,
} from '@mui/material';
import React, { useMemo } from 'react';
import {
  Field, FieldAttributes, useFormikContext,
} from 'formik';
import { IRequirement } from '@portal/user-types';
import useStyles from './index.styles';

interface IApproverLevelSelector {
    entity: string,
    fieldName: string,
    approvalConfigRequirements: IRequirement[],
  }

const ApproverLevelToolTip = ({ approvalStructure, entity } : {
  approvalStructure: Array<{value: string, level: string}>,
  entity: string,
}) => {
  const { popper, tooltipContent, tooltipTitle } = useStyles();
  return (
    <Tooltip
      title={(
        <div className={tooltipContent}>
          <h3>{entity}</h3>
          <table>
            <thead>
              <tr>
                <th>Level</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {approvalStructure.map(({ value, level }) => (
                <tr key={`approvalStructure-${level}`}>
                  <td>{level}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      classes={{ popper }}
      leaveDelay={200}
      placement="top"
      arrow
    >
      <div className={tooltipTitle}>
        What is the approval structure for this entity?
      </div>
    </Tooltip>
  );
};

const ApproverLevelSelector = (props: IApproverLevelSelector) => {
  const {
    approvalLevelSelect, approvalLevelTitle,
  } = useStyles();

  const { errors } = useFormikContext();

  const {
    entity, approvalConfigRequirements, fieldName,
  } = props;

  const approvalStructure = useMemo(() => approvalConfigRequirements.map(
    (requirement, index) => {
      const level = requirement.approvalsRequired.reduce((acc: Array<string>, curr) => {
        const currentLevel = [...Array(curr.requiredNumber)].map(() => curr.level);
        return [...acc, ...currentLevel];
      }, []).join(', ');

      if (index === 0) {
        return { level, value: `0 - ${requirement.limitAmount}` };
      }
      return { level, value: `${approvalConfigRequirements[index - 1].limitAmount + 0.01} - ${requirement.limitAmount}` };
    },
  ), [approvalConfigRequirements]);

  const approvalLevels = useMemo(() => Array.from(
    new Set(approvalConfigRequirements.reduce((acc: Array<string>, curr) => {
      const approvalsRequired = curr.approvalsRequired.map(
        (approval) => approval.level,
      );
      return [...acc, ...approvalsRequired];
    }, []).sort()),
  ), [approvalConfigRequirements]);

  return (
    <>
      <div className={approvalLevelTitle}>
        <p>Please select what approval level this user will have on the platform.</p>
        <ApproverLevelToolTip approvalStructure={approvalStructure} entity={entity} />
      </div>
      <Field name={fieldName}>
        {({ field } : FieldAttributes<any>) => (
          <FormControl className={approvalLevelSelect} error={'permissions' in errors}>
            Approval Level
            <Select
              data-testid="approvalLevelSelector"
              {...field}
              displayEmpty
            >
              <MenuItem hidden disabled value="">
                <em>Please select approval level</em>
              </MenuItem>
              {approvalLevels.map(
                (level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        )}
      </Field>
    </>
  );
};

export default ApproverLevelSelector;
