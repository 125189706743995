import { Button } from '@mui/material';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import { ActionButton } from 'components/Buttons';
import FooterWrapper from '../../../../../components/Payments/FooterWrapper/FooterWrapper';

interface IProps {
  buttonText: string;
  disabled?: boolean;
  handleOnClick: () => void;
  handleExitClick: () => void;
}

const BatchSubmissionFooter: React.FC<IProps> = (props: IProps) => {
  const {
    buttonText, disabled, handleOnClick, handleExitClick,
  } = props;

  return (
    <FooterWrapper>
      <Button
        onClick={handleExitClick}
        color="secondary"
        startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
      >
        Exit
      </Button>
      <ActionButton testId="submitPaymentButton" size="large" disabled={disabled} onClick={handleOnClick}>{buttonText}</ActionButton>
    </FooterWrapper>
  );
};

export default memo(BatchSubmissionFooter);
