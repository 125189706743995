import React, {
  memo,
  useCallback,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { repeatOptions } from 'domain/PaymentsMultiEntity/ManualPayments/Body/ManualPayment/PaymentFields/StandingOrder/constants';
import AcknowledgeModal from 'components/Modals/AcknowledgeModal';
import { Drawer } from 'components/Drawer';
import { TStandingOrder } from 'models/payments';
import { Box } from '@mui/material';
import SearchTable from 'components/Table/SearchTable';
import { TextEllipsis } from 'components/TextEllipsis';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import PaymentsService from 'services/Payments/payments.service';
import { SearchPaymentsTabProps } from 'domain/PaymentsMultiEntity/usePaymentBatchSearch';
import Status from 'components/Status/Status';
import useStyles from '../SearchPaymentsTable/SearchPaymentsTable.styles';
import { DeleteButtonStyled, ViewButtonStyled } from './styles';
import { StandingOrderStatus } from './constants';
import ScheduledTableDrawer from './ScheduledTableDrawer';
import ScheduledTableDrawerHead from './ScheduledTableDrawerHead';

const ScheduledTableContainer: React.FC<SearchPaymentsTabProps> = (props: SearchPaymentsTabProps) => {
  const {
    items,
    hasNext,
    hasPrevious,
    handleNextPage,
    handlePreviousPage,
    loading,
    handleNewSearch,
  } = props;
  const [deleteModalOpen, setDeleteModalOpen] = useState<any | null>(null);
  const [drawerOpen, setDrawerOpen] = useState<any | null>(null);

  const classes = useStyles();

  const formatFrequency = useCallback((frequency: string, date: string) => {
    const opt = repeatOptions.find((item) => item.name === frequency);

    if (!opt) return frequency;

    if (opt.addDay) {
      return (
        <>
          {opt.label}
          &nbsp;- On&nbsp;
          {moment(date).format('dddd')}
          s
        </>
      );
    }

    if (opt.addDate) {
      return (
        <>
          {opt.label}
          &nbsp;- On the&nbsp;
          {moment(date).format('Do')}
        </>
      );
    }

    if (opt.addDateAndMonth) {
      return (
        <>
          {opt.label}
          &nbsp;- On&nbsp;
          {moment(date).format('MMM Do')}
        </>
      );
    }
    return opt.label;
  }, []);

  const columns: ITableColumn[] = [
    {
      header: 'Entity From',
      accessor: 'entity',
      className: 'dd-privacy-allow',
    },
    {
      header: 'Beneficiary',
      accessor: 'beneficiary',
      className: 'dd-privacy-allow',

    },
    {
      header: 'Reference',
      accessor: 'reference',
      className: 'dd-privacy-allow',

    },
    {
      header: 'Frequency',
      accessor: 'frequency',
      className: 'dd-privacy-allow',
    },
    {
      header: 'Next Payment',
      accessor: 'date',
      className: 'dd-privacy-allow',
    },
    {
      header: 'Status',
      accessor: 'status',
      className: 'dd-privacy-allow',
    },
    {
      header: 'Amount',
      accessor: 'amount',
      className: 'dd-privacy-allow',

    },
    {
      header: 'Action',
      accessor: 'action',
      className: 'dd-privacy-allow',
      width: 100,
    },
  ];
  const sb = useAlphaSnackbar();

  const getStatusVariant = (status: string) => {
    if (status === StandingOrderStatus.Active) return 'success';
    if (status === StandingOrderStatus.PendingApproval) return 'blue';
    if (status === StandingOrderStatus.Failed) return 'error';
    return 'error';
  };

  const getStatus = (status: string) => status.replace(/([a-z])([A-Z])/g, '$1 $2');

  const mappedData = items?.records
  && (items.records as TStandingOrder[]).map((item) => {
    const container: any = {};
    container.entity = <TextEllipsis text={item.accountName} className={classes.spaceRight} />;
    container.beneficiary = item.beneficiaryDraft?.name;
    container.reference = item.reference;
    container.frequency = (
      <Box>
        {formatFrequency(item.frequency, item.startDate)}
      </Box>
    );
    container.date = (
      item.nextFireDate ? moment(item.nextFireDate).format('DD/MM/YYYY') : ''
    );
    container.status = (
      !!item.status
      && <Status variant={getStatusVariant(item.status)}>{getStatus(item.status)}</Status>
    );
    container.amount = (
      <b>
        { Number(item.amount).toFixed(2) }
        &nbsp;
        { item.fixedSide ? item.fixedSide : item.debitingCurrency }
      </b>
    );
    container.action = (
      <>
        <ViewButtonStyled
          onClick={() => setDrawerOpen(item)}
          data-testid="scheduled-detailsBtn"
        >
          Details
        </ViewButtonStyled>
        <DeleteButtonStyled onClick={() => setDeleteModalOpen(item)} data-testid="scheduled-deleteBtn">
          <FontAwesomeIcon className="icon" icon={faTrashAlt} />
        </DeleteButtonStyled>
      </>
    );
    return container;
  });

  const handleDeleteClick = async (): Promise<void> => {
    try {
      await PaymentsService.deletePayment(
        deleteModalOpen.scheduleId,
        deleteModalOpen.accountId,
        true,
      );
      setTimeout(() => handleNewSearch(), 500);

      sb.trigger('Successfully deleted your payment', 'success');
    } catch {
      sb.trigger('There was an issue deleting your payment', 'error');
    }
  };

  const handleDeleteFromDrawer = useCallback(() => {
    setDeleteModalOpen(drawerOpen);
    setDrawerOpen(null);
  }, [drawerOpen]);

  return (
    <>
      <SearchTable
        table={{
          columns,
          data: mappedData || [],
        }}
        loading={loading}
        pagination={{
          handleNext: handleNextPage,
          handlePrevious: handlePreviousPage,
          hasNext,
          hasPrevious,
        }}
        emptyTable={{
          title: 'No Scheduled Payments',
          subtitle: 'You currently do not have any scheduled payment batches',
        }}
      />
      <AcknowledgeModal
        open={!!deleteModalOpen}
        title="Cancel standing order?"
        content="The standing order will be deleted and no future payments will be sent."
        actionButtonText="Yes, cancel"
        cancelButtonText="No"
        handleActionButton={handleDeleteClick}
        handleClose={() => setDeleteModalOpen(null)}
      />
      <Drawer
        headerTitle={(
          <ScheduledTableDrawerHead
            item={drawerOpen}
            deleteButtonAction={handleDeleteFromDrawer}
          />
        )}
        open={drawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(null)}
      >
        <ScheduledTableDrawer item={drawerOpen} formatFrequency={formatFrequency} />
      </Drawer>
    </>
  );
};

export default memo(ScheduledTableContainer);
