import { TKeyValue } from 'components/Table/Table.interfaces';
import React from 'react';
import KeyValueTable from 'components/Table/KeyValueTable/KeyValueTable';
import { TTransactionData } from '../../../../../../models/transactions';
import AccountInformation from '../AccountInformation/AccountInformation';
import useStyles from './CreditingDetails.styles';

interface IProps {
  transaction: TTransactionData;
  creditingDetails: TKeyValue[];
}

const CreditingDetails = (props: IProps) => {
  const classes = useStyles();
  const { transaction, creditingDetails } = props;
  const { creditType, creditIban, creditReference, creditFriendlyName } = transaction;
  return (
    <>
      <AccountInformation
        header="crediting"
        type={creditType}
        iban={creditIban}
        reference={creditReference}
        friendlyName={creditFriendlyName}
      />
      <KeyValueTable
        className={classes.creditingDetails}
        testId="transactions-drawer-crediting"
        data={creditingDetails}
        obscure
      />
    </>
  );
};

export default CreditingDetails;
