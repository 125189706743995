import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box, CircularProgress, Typography,
} from '@mui/material';
import { faTimes, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import {
  StyledActionButton,
  StyledBackButton,
  StyledBody,
  StyledCloseButton,
  StyledDefaultIcon,
  StyledHeader,
} from './index.styles';
import Modal from '../Modal';

export interface IDecisionModalProps {
  open: boolean;
  title?: JSX.Element | string;
  subtitle?: string;
  content?: JSX.Element | string;
  actionButtonText?: string;
  actionButtonSecondText?: string;
  actionButtonColour?: 'inherit' | 'error' | 'info' | 'success' | 'secondary' | 'primary' | 'warning' | undefined;
  actionButtonSecondColor?: 'inherit' | 'error' | 'info' | 'success' | 'secondary' | 'primary' | 'warning' | undefined;
  cancelButtonText?: string;
  icon?: IconDefinition;
  separator?: boolean;
  closeButton?: boolean;
  iconStyle?: string;
  loading?: boolean;
  handleClose?: () => void;
  handleActionButton?: () => Promise<void> | void;
  handleActionButtonSecond?: () => Promise<void> | void;
  buttonsDisabled?: boolean;
  headerStyle?: any;
  bodyStyle?: any,
}

const AcknowledgeModal: React.FC<IDecisionModalProps> = (props: IDecisionModalProps) => {
  const {
    open,
    title,
    content,
    actionButtonText,
    actionButtonSecondText,
    actionButtonColour,
    cancelButtonText,
    loading,
    separator,
    subtitle,
    icon,
    iconStyle,
    headerStyle,
    handleClose,
    handleActionButton,
    handleActionButtonSecond,
    closeButton,
    buttonsDisabled,
    bodyStyle,
    actionButtonSecondColor,
  } = props;

  return (
    <Modal
      open={open}
      separator={separator}
      header={(
        <Box>
          <StyledHeader sx={headerStyle}>
            <span>
              {icon && (
                <StyledDefaultIcon
                  size="lg"
                  icon={icon}
                  data-testid="modal-icon"
                  className={iconStyle}
                />
              )}
              {title && typeof title === 'string' ? (
                <Typography variant="h2">{title}</Typography>
              ) : (
                title
              )}
            </span>
            {closeButton && handleClose && (
              <StyledCloseButton
                color="secondary"
                disabled={loading}
                onClick={handleClose}
              >
                <FontAwesomeIcon size="lg" icon={faTimes} />
              </StyledCloseButton>
            )}
          </StyledHeader>
          {subtitle && (
            <Typography variant="subtitle1" className="pb-2">
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
    >
      <>
        <StyledBody sx={bodyStyle}>
          {content}
        </StyledBody>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {cancelButtonText && (
            <Box sx={{ width: '100%' }}>
              <StyledBackButton
                disabled={loading}
                data-testid="modal-back-button"
                colorVariant="default"
                onClick={handleClose}
              >
                {cancelButtonText}
              </StyledBackButton>
            </Box>
          )}
          <Box
            sx={{
              width:
                cancelButtonText && actionButtonText && actionButtonSecondText
                  ? '60%'
                  : '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {actionButtonText && (
              <StyledActionButton
                variant="contained"
                data-testid="modal-action-button"
                color={actionButtonColour || 'primary'}
                className={actionButtonColour === 'error' ? 'actionButtonRed' : ''}
                disabled={buttonsDisabled}
                disableElevation
                type="submit"
                onClick={handleActionButton}
              >
                {actionButtonText}
              </StyledActionButton>
            )}
            {actionButtonSecondText && (
              <StyledActionButton
                variant="contained"
                data-testid="modal-action-button"
                color={actionButtonSecondColor || 'primary'}
                className={actionButtonSecondColor === 'error' ? 'actionButtonRed' : ''}
                disabled={buttonsDisabled}
                disableElevation
                type="submit"
                onClick={handleActionButtonSecond}
                {...(loading && {
                  endIcon: <CircularProgress color="inherit" size={12} />,
                })}
              >
                {actionButtonSecondText}
              </StyledActionButton>
            )}
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default AcknowledgeModal;
