export enum UserRole {
  PAYMENTS_INPUTTER = 'Payments Inputter',
  BENEFICIARY_INPUTTER = 'Beneficiary Inputter',
  PAYMENTS_APPROVER = 'Payments Approver',
  PAYMENTS_APPROVER_OWN = 'Payments Approver Own',
  BENEFICIARY_APPROVER = 'Beneficiary Approver',
  BENEFICIARY_APPROVER_OWN = 'Beneficiary Approver Own',
  IAT = 'IAT',
  IAT_INPUTTER = 'IAT Inputter',
  IAT_APPROVER_OWN = 'IAT Approver Own',
  IAT_APPROVER = 'IAT Approver',
  FX_IAT = 'FX IAT',
  READ_ONLY = 'Read Only / Reporting',
  ONBOARDER = 'Onboarder',
  INVOICEMANAGER = 'Invoice Manager',
  ENTITY_VIEWER = 'Entity Viewer',
  ENTITY_MANAGER_ADD_ALL_PERMS = 'Entity Manager Add All Perms',
}

export type UserRoles = UserRole[];

export type TUserRoute = {
  NoHref: boolean;
  Route: string;
  SubRoutes: TUserRoute[];
  Title: string;
};

export type TUserAccount = {
  id: string;
  name: string;
  onboardingEnabled: boolean;
  invoicesEnabled: boolean;
  dynamicFxSpreadEnabled: boolean;
  kycRefreshDate?: string,
  reviewDue: boolean,
  reviewOverDue: boolean,
  accountFrozen: boolean,
  sepaOnPortal: boolean
  dateFrozenForKycRefresh?: string,
  lineByLineFxEnabled: boolean;
  status?: string;
  incompleteProfileReason?: string;
  isMicroEnterprise?: boolean;
};

export type TUserAccounts = TUserAccount[];

export type TUserCredentials = {
  username: string;
  password: string;
};

export type TUser = {
  SubId: string;
  Username: string;
  Email: string;
  PhoneNumber: string;
  Name: string;
  Account?: TUserAccount;
  ContactId?: string;
  Permissions?: string[];
  MasterContactId?: string | null;
  Role?: {
    Id: number;
    Name: string;
  };
};

export type TAuthyStatus = {
  registered: boolean;
  devices: string[];
};
