import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    debitingDetails: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      marginBottom: 50,
      '& tr > td:last-child': {
        color: 'rgb(199, 81, 81)',

      },
    },
    fxRate: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      marginBottom: 50,
      display: 'flex',
      justifyContent: 'space-between',
    },
    icon: {
      color: '#197FAB',
      marginLeft: 5,
    },
    key: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 11.5,
    },
    divider: {
      borderRight: '1px solid rgba(112, 112, 112, 0.4)',
      paddingRight: 12,
      marginRight: 12,
    },
    tooltipText: {
      width: 150,
    },
  }),
  { name: 'DebtingDetails' },
);

export default useStyles;
