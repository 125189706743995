import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    beneficiaryWrapper: {
      marginBottom: 50,
    },
    beneficiaryHeader: {
      paddingBottom: 31.5,
      fontWeight: typography.fontWeightSemiBold,
      letterSpacing: -0.5,
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
  }), { name: 'BeneficiaryDetails' },
);

export default useStyles;
