import React, { useState } from 'react';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import { IBaseButtonProps, IconActionButton } from 'components/Buttons';

interface IDeleteButtonProps extends IBaseButtonProps {
  handleClick: () => Promise<void>;
  getAllPendingTransfers?: () => Promise<void>;
}

const DeleteButton: React.FC<IDeleteButtonProps> = ({
  handleClick,
  getAllPendingTransfers,
}: IDeleteButtonProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  return (
    <>
      <IconActionButton
        icon={faTrash}
        onClick={() => setIsDeleteModalOpen(true)}
        className="deleteButton"
        variant="outlined"
        color="secondary"
      />
      <DecisionModal
        open={isDeleteModalOpen}
        title="Are you sure you want to delete this IAT?"
        subtitle="This is irreversible and the IAT will be permanently deleted."
        actionButtonText="DELETE"
        handleActionButton={() => {
          handleClick().then(() => {
            setIsDeleteModalOpen(false);
            if (getAllPendingTransfers) getAllPendingTransfers();
          });
        }}
        closeButtonText="CANCEL"
        handleClose={() => setIsDeleteModalOpen(false)}
        icon={faExclamationTriangle}
      />
    </>
  );
};

export default DeleteButton;
