import { APMainLayout } from 'components/Layout/MainLayout';
import React, { useEffect } from 'react';
import BackdropLoader from '../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import routes from '../../routes.path';
import TransactionsCtas from './TransactionsCtas/TransactionsCtas';
import TransactionInfoDrawer from './TransactionsInfoDrawer/TransactionsInfoDrawer';
import TransactionsSelect from './TransactionsSelect/TransactionsSelect';
import TransactionsSummary from './TransactionsSummary/TransactionsSummary';
import TransactionsTable from './TransactionsTable/TransactionsTable';
import useTransactions from './useTransactions';

export const componentTestIds = {
  TransactionsCtas: 'transactions-ctas-component',
  TransactionsSummary: 'transactions-summary-component',
  TransactionsTable: 'transactions-table-component',
};

export enum DateFields {
  START = 'startDate',
  END = 'endDate',
}

export const Transactions: React.FC = () => {
  const breadcrumbs = [{ text: 'Home', url: routes.home }];

  const {
    loading,
    tableLoading,
    transaction,
    currencyAccountId,
    selectedDates,
    setSelectedDates,
    setTransaction,
    handlePageLoad,
    handleSetTransaction,
  } = useTransactions();

  useEffect(() => {
    handlePageLoad();
  }, [currencyAccountId]);

  const startDateIso = selectedDates.startDate.format('yyyy-MM-DD');
  const endDateIso = selectedDates.endDate.format('yyyy-MM-DD');

  return (
    <APMainLayout
      title={(
        <span className="dd-privacy-allow">

          Transactions

        </span>
      )}
      breadCrumbs={breadcrumbs}
      pageTabs={(
        <TransactionsSelect
          currencyAccountId={currencyAccountId}
        />
      )}
      rightAlignedCta={(
        <TransactionsCtas
          componentTestId={componentTestIds.TransactionsCtas}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          currencyAccountId={currencyAccountId || ''}
          startDateIso={startDateIso}
          endDateIso={endDateIso}
        />
      )}
    >
      {tableLoading ? (
        <BackdropLoader testId="loader-transactions" />
      ) : (
        <>
          <TransactionsSummary
            componentTestId={componentTestIds.TransactionsSummary}
            currencyAccountId={currencyAccountId}
            startDate={startDateIso}
            endDate={endDateIso}
          />
          <TransactionsTable
            key={`${startDateIso}-${endDateIso}`}
            currencyAccountId={currencyAccountId}
            componentTestId={componentTestIds.TransactionsTable}
            startDate={startDateIso}
            endDate={endDateIso}
            handleSetTransaction={handleSetTransaction}
          />
        </>
      )}

      <TransactionInfoDrawer
        loading={loading}
        transaction={transaction}
        paymentId={transaction && transaction.paymentId}
        onClose={() => {
          setTransaction(undefined);
        }}
      />
    </APMainLayout>
  );
};
