import _default, { makeStyles } from 'theme';

const { palette } = _default;

import { alpha } from '@mui/material';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      gap: 10,
    },
    baseSelect: {
      position: 'relative',
      padding: '6px 8px',
      display: 'flex',
      borderRadius: '35px',
      overflow: 'hidden',
      alignItems: 'center',
      marginBottom: '10px',
      marginLeft: 0,
      marginRight: 0,
      '& div': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.05)',
        transition: '0.3s',
      },
      '& span': {
        position: 'relative',
        zIndex: 1,
        marginLeft: 5,
        transition: '0.4s',
        fontSize: '1rem',
      },
      '& input': {
        position: 'relative',
        zIndex: 1,
        accentColor: alpha(palette.primary.main, 1),
        cursor: 'pointer !important',
      },
      '& input:checked + div': {
        background: alpha(palette.primary.main, 0.15),
      },
      '& input:checked ~ span': {
        color: palette.primary.main,
      },
    },
    checkbox: {
      paddingRight: '12px',
      overflow: 'hidden',
      justifyContent: 'space-between',
    },
    radio: {
      minWidth: '100px',
      paddingRight: '12px',
      '& input': {
        accentColor: alpha(palette.primary.main, 1),
        marginTop: '0px',
        width: 12,
        marginRight: 6,
      },
    },
  }),
  { name: 'CheckboxStyles' },
);

export default useStyles;
