import React, { useEffect } from 'react';
import {
  GridValidRowModel, GridColumns,
} from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { faHourglass } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import {
  GetAllUserRoleRequestsByStatusRequest,
  UserRoleRequestResponse,
  Response,
  RequestedUserRolesStatus,
} from '@portal/user-types';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import CollapsibleCard from '../../../../../components/CollapsibleCard';
import DataTable from '../../../../../components/Table/DataTable';
import useDataTable from '../../../../../hooks/useDataTable';
import routes from '../../../../../routes.path';
import useStyles from '../index.styles';
import RequestedRoleDescription from '../RequestedRoleDescription';
import getStatusVariant from '../../../utils/getStatusVariant';
import UserManagementService from '../../../../../services/UserManagement/userManagement.service';
import { TAuthyState } from '../../../../../store/authy/reducer';
import { TStore } from '../../../../../store';
import PendingApprovalDropDown from './PendingApprovalTableActionDropdown';
import { IUserTableParams, UserTables } from '../../../context/index.interfaces';
import { useUserManagementContext } from '../../../context';

const PendingApprovalTable = (
  {
    disableVirtualization = false,
    search,
    setTablesCount,
    setSelectedRequests,
  }: IUserTableParams,
) => {
  const { tableColumn, tableColumnDisabled } = useStyles();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const { refreshDataTable, setRefreshDataTable } = useUserManagementContext();

  const {
    rows, page, setPage, isLoading, totalRowCount, fetchData,
  } = useDataTable<
  GetAllUserRoleRequestsByStatusRequest,
  Response<UserRoleRequestResponse[]>
  >(
    UserManagementService.getAllUserRoleRequestsByStatus, {
      status: [RequestedUserRolesStatus.PENDING_APPROVAL],
    },
    search,
  );

  useEffect(() => {
    setTablesCount((tablesCount) => ({
      ...tablesCount,
      [UserTables.PENDING_APPROVAL]: totalRowCount,
    }));
  }, [setTablesCount, totalRowCount]);

  useEffect(() => {
    if (
      authyState.type?.type === 'USER_ROLE_REQUEST'
        && authyState.status === 'SUCCESS'
    ) {
      fetchData();
    }
  }, [authyState.status, authyState.type, fetchData]);

  useEffect(() => {
    if (refreshDataTable === UserTables.PENDING_APPROVAL) {
      fetchData();
      setRefreshDataTable(null);
    }

    return () => {
      setRefreshDataTable(null);
    };
  }, [fetchData, refreshDataTable, setRefreshDataTable]);

  const pendingApprovalColumns = React.useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: 'userName',
        headerName: 'Username',
        sortable: false,
        flex: 0.25,
        renderCell: ({ row: { userId, isNewUser }, value }) => (isNewUser
          ? <p className={tableColumnDisabled}>{value}</p>
          : <Link className={tableColumn} to={`${routes.userManagement.userRoute(userId)}`}>{value}</Link>
        ),
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 0.65,
        sortable: false,
        renderCell: ({
          row: {
            isNewUser,
            userName,
            accountName,
            requestedRoles,
            currentRoles,
            currentApprovalLevel,
            requestedApprovalLevel,
          },
        }) => (
          <RequestedRoleDescription
            isNewUser={isNewUser}
            userName={userName}
            accountName={accountName}
            requestedRoles={requestedRoles}
            currentRoles={currentRoles}
            currentApprovalLevel={currentApprovalLevel}
            requestedApprovalLevel={requestedApprovalLevel}
          />
        )
        ,
      },
      {
        field: 'requestDate',
        headerName: 'Request Date',
        flex: 0.2,
        sortable: false,
        renderCell: ({ row: { created } }) => (
          <p>{moment(created).format('DD/MM/YYYY h:mma')}</p>
        ),
      },
      {
        field: 'status',
        headerName: 'User Status',
        flex: 0.2,
        sortable: false,
        renderCell: ({ value }) => getStatusVariant(value || 'default'),
      },
      {
        field: 'userId',
        headerName: 'Action',
        type: 'actions',
        flex: 0.1,
        sortable: false,
        getActions: ({
          row: {
            userId, id, isApprovable, isRejectable, isNewUser,
          },
        }) => [
          <PendingApprovalDropDown
            userId={userId}
            roleRequestId={id}
            onSubmitAction={(
            ) => ((page !== 0 && rows?.data.length === 1) ? setPage(page - 1) : fetchData())}
            isApprovable={isApprovable}
            isRejectable={isRejectable}
            isNewUser={isNewUser}
          />,
        ],
      },
    ], [fetchData, tableColumn, tableColumnDisabled, rows],
  );

  if (totalRowCount === 0 && search.length > 0) return null;

  return (
    <CSSTransition
      appear
      in
      timeout={1000}
      classNames="fade-up"
    >
      <CollapsibleCard
        title={`Pending Approval (${totalRowCount})`}
        subHeader="All users who require approval from another user"
        icon={faHourglass}
      >
        <DataTable
          columns={pendingApprovalColumns}
          data={(rows?.data || [])}
          page={page}
          pageSize={10}
          setPage={setPage}
          isLoading={isLoading}
          totalRowCount={totalRowCount}
          getRowId={(row: any) => row.id}
          disableVirtualization={disableVirtualization}
          noRowsLabel="You currently do not have any users requiring approval"
          checkboxSelection
          onSelectionModelChange={(selectedIds) => {
            if (setSelectedRequests && rows?.data) {
              const selectedRows = (selectedIds as string[]).reduce(
                (accumulator: UserRoleRequestResponse[], currentValue: string) => {
                  const row = rows.data.find((e) => e.id === currentValue as string);
                  if (row) {
                    return [
                      ...accumulator,
                      row,
                    ];
                  }
                  return accumulator;
                }, [],
              );

              setSelectedRequests(selectedRows);
            }
          }}
        />
      </CollapsibleCard>
    </CSSTransition>
  );
};

export default PendingApprovalTable;
