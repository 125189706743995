import { PaymentBatchDraftStatus } from '@alpha/payments-dtos';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TCurrencyAccount, TCurrencyAccounts } from '../models/currencyAccounts';
import { PaymentRouteEnum, TPayment } from '../models/payments';
import { UserRole } from '../models/user';
import routes from '../routes.path';
import browserHistory from '../services/history/browserHistory';
import PaymentsService from '../services/Payments/payments.service';
import { TStore } from '../store';
import * as authyActions from '../store/authy/actions';
import { authySlice, TAuthyState } from '../store/authy/reducer';
import { actions, TPaymentsStore } from '../store/payments/payments.reducer';
import useAlphaSnackbar from './useAlphaSnackbar';
import useAuthorization from './useAuthorization';

const useManualPayment = () => {
  const sb = useAlphaSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [currentPayment, setCurrentPayment] = useState<TPayment>({
    reference: '',
    date: '',
    paymentAmount: 0,
  });
  const [
    selectedDebitingAccount,
    setSelectedDebitingAccount,
  ] = useState<TCurrencyAccount>();
  const paymentsStore = useSelector<TStore, TPaymentsStore>((store) => store.payments);
  const authyStore = useSelector<TStore, TAuthyState>((store) => store.authy);
  const { authorized } = useAuthorization([[UserRole.PAYMENTS_APPROVER_OWN]]);
  const dispatch = useDispatch();
  const [currentPayments, setCurrentPayments] = useState<TPayment[]>([]);
  const [currencyAccounts, setCurrencyAccounts] = useState<TCurrencyAccounts>(
    [],
  );
  const [editRow, setEditRow] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const handleBeneficiaryDrawerToggle = (_open: boolean) => setOpen(_open);

  const getBatchErrors = async () => {
    try {
      if (!paymentsStore.batchDetails?.batchId) return;
      const errors = await PaymentsService.getBatchInvalid(paymentsStore.batchDetails?.batchId);
      sb.trigger(errors[0].errors.join('\n'));
    } catch (e) {
      sb.trigger('There was an error getting your payment errors');
    }
  };

  const getBatchStatus = async (): Promise<string | void> => {
    try {
      if (!paymentsStore.batchDetails?.batchId) throw Error();
      const { batchStatus } = await PaymentsService.getBatchStatus(
        paymentsStore.batchDetails.batchId,
      );
      return batchStatus;
    } catch {
      return sb.trigger('There was an error retrieving your batch status');
    }
  };

  const parseBatchStatusUpdates = async () => {
    if (paymentsStore.batchDetails?.totalInvalidPayments) {
      if (location.pathname.includes('single')) {
        getBatchErrors();
      } else {
        sb.trigger('There were some payment errors with your batch.');
      }
    }
    switch (paymentsStore.batchDetails?.batchStatus) {
      case PaymentBatchDraftStatus.UPLOADED:
        setLoading(true);
        return;
      case PaymentBatchDraftStatus.VALIDATED:
        setLoading(false);
        if (!paymentsStore.batchDetails.totalInvalidPayments) {
          dispatch(
            authyActions.initiatePayment({
              batchId: paymentsStore.batchDetails!.batchId!,
              type: 'PAYMENTS',
              approverOwn: authorized,
            }),
          );
        }
        return;
      default:
        setLoading(false);
    }
  };

  const isFx = (): boolean => Boolean(
    currentPayment.fixedSide,
  ) || Boolean(
    currentPayments[0]?.fixedSide,
  ) || false;

  const parseAuthyStatus = async () => {
    if (authyStore.status === 'SUCCESS') {
      const batchStatus = await getBatchStatus();
      if (batchStatus) {
        if (batchStatus === PaymentBatchDraftStatus.APPROVED) {
          if (isFx()) {
            dispatch(authySlice.actions.reset());
            dispatch(actions.clear());
            browserHistory.push(
              routes.payments.batchSummaryRouteFunc(
                paymentsStore.batchDetails!.batchId!,
                PaymentRouteEnum.FUNDING,
              ),
            );
            return;
          }
        }
      }
      dispatch(authySlice.actions.reset());
      dispatch(actions.clear());
      browserHistory.push(routes.payments.base);
    }
  };

  const handleSetCurrentPayments = (_currentPayments: TPayment[]) => {
    setCurrentPayments(_currentPayments);
  };

  return {
    open,
    isValid,
    currentPayment,
    loading,
    editRow,
    location,
    currencyAccounts,
    currentPayments,
    selectedDebitingAccount,
    paymentsStore,
    authyStore,
    setLoading,
    setSelectedDebitingAccount,
    setCurrencyAccounts,
    setEditRow,
    setIsValid,
    setCurrentPayment,
    setCurrentPayments,
    handleBeneficiaryDrawerToggle,
    handleSetCurrentPayments,
    parseBatchStatusUpdates,
    parseAuthyStatus,
  };
};

export default useManualPayment;
