import theme, { makeStyles } from 'theme';
import { Input, TInputProps } from 'components/Inputs/Input';
import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';

const { palette } = theme;

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
  requiredLabel: {
    marginBottom: '6px',
    color: palette.error.main,
  },
  labelDescription: {
    '& .MuiFormHelperText-root': {
      bottom: '-36px',
    },
  },
}), { name: 'inputAndLabel' });

type TInputAndLabelProps = {
  label: string;
  labelDescription?: ReactNode;
} & Omit<TInputProps, 'variant'>

const InputAndLabel: React.FC<TInputAndLabelProps> = (props: TInputAndLabelProps) => {
  const styles = useStyles();
  const {
    error,
    label,
    labelDescription,
    helperText,
    name,
    value,
    id,
    placeholder,
    onBlur,
    onChange,
    testId,
  } = props;
  return (
    <div>
      {error && helperText?.toString().includes('required')
        ? (
          <Typography variant="subtitle1" className={styles.requiredLabel}>
            {`${label} *`}
          </Typography>
        )
        : <Typography variant="subtitle1" className={styles.label}>{label}</Typography>}

      <Input
        className={labelDescription ? styles.labelDescription : ''}
        fullWidth
        name={name}
        id={id}
        variant="filled"
        placeholder={placeholder}
        testId={testId}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={error}
        helperText={helperText}
      />
      {labelDescription}
    </div>
  );
};

export default InputAndLabel;
