import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect, useContext } from 'react';
import { originalUseSnackbar } from 'components/Snackbar';
import { TUserCredentials } from '../models/user';
import useHandleLogin from './useHandleLogin';
import { AuthContext } from '../context/AuthContext';

const initialValue: TUserCredentials = {
  username: '',
  password: '',
};

const validation = (onboarding: boolean) => {
  const yupSchema = {
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
  };

  if (onboarding) {
    yupSchema.password = yup.string().min(8, 'Password must be 8 characters').required('Password is required');
  }

  return yup.object(yupSchema);
};

const useLogin = (onboarding: boolean = false) => {
  const snackbar = originalUseSnackbar();
  const { loading, handleLogin } = useHandleLogin();
  const [validationSchema, setValidationSchema] = useState(validation(onboarding));
  const { setTemporaryPassword } = useContext(AuthContext);

  useEffect(() => {
    setValidationSchema(validation(onboarding));
  }, [onboarding]);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values: TUserCredentials) => {
      try {
        if (setTemporaryPassword) {
          setTemporaryPassword(values.password);
        }
        await handleLogin(
          values.username.trim(),
          values.password,
        );
      } catch (e) {
        let errorMessage: string = e.message;
        if (errorMessage.indexOf('validation') > -1) {
          errorMessage = 'Incorrect username or password.';
        }
        snackbar.enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
          className: 'dd-privacy-allow',
        });
      }
    },
    validationSchema,
  });

  return {
    formik,
    loading,
  };
};

export default useLogin;
