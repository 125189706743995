import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box, Button, styled,
} from '@mui/material';
import { GhostButton } from 'components/Buttons';

const StyledHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  '& h2': {
    fontWeight: '400',
    fontSize: '22px',
    lineHeight: '46px',
    alignItems: 'end',
  },
  '& img': {
    width: '25px',
    marginRight: '8px',
    alignItems: 'flex-start',
  },
  '& span': {
    display: 'flex',
  },
});

const StyledCloseButton = styled(Button)({
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  '&:hover': {
    backgroundColor: 'inherit',
  },
});

const StyledBody = styled(Box)({
  maxHeight: 420,
  marginBottom: 20,
  padding: '0 0 24px',
  fontSize: '16px',
  '& h6': {
    color: '#222222',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
  },
  '& .errorMessage': {
    fontWeight: 'bold',
    paddingBottom: '20px',
  },
});

const StyledBackButton = styled(GhostButton)({
  color: '#5c5c5c',
  borderRadius: 5,
  fontWeight: 600,
});

const StyledActionButton = styled(Button)({
  borderRadius: 5,
  fontWeight: 600,
  marginLeft: 10,
  '& .actionButtonRed': {
    color: 'white',
    border: '1px solid',
    backgroundColor: '#C64545',
  },
});

const StyledDefaultIcon = styled(FontAwesomeIcon)({
  color: 'rgb(255, 199, 0)',
  marginRight: 10,
  alignSelf: 'center',
});

export {
  StyledHeader,
  StyledCloseButton,
  StyledBody,
  StyledBackButton,
  StyledActionButton,
  StyledDefaultIcon,
};
