import React, { useEffect, useState } from 'react';
import CreateEntityGroup from './CreateEntityGroups/CreateEntityGroup';
import useEntityGroupDrawer from './useEntityGroupDrawer';
import EntityGroupInfoEdit from './ViewEditEntityGroupInfo/EntityGroupInfoEdit';
import ViewEntityGroupsDrawer from './ViewEntityGroups/ViewEntityGroupsDrawer';

const EntityGroupDrawer = () => {
  const [canEditOrDeleteGroup, setCanEditOrDeleteGroup] = useState<boolean>(false);

  const {
    selectedEntityGroup,
    viewGroupsDrawerOpen,
    createGroupDrawerOpen,
    toggleViewGroupsDrawer,
    requestGetUserAccounts,
  } = useEntityGroupDrawer();

  useEffect(() => {
    requestGetUserAccounts();
  }, []);

  return (
    <div>
      <EntityGroupInfoEdit
        open={Boolean(selectedEntityGroup)}
        entityGroup={selectedEntityGroup!}
        canEditOrDeleteGroup={canEditOrDeleteGroup}
      />
      <ViewEntityGroupsDrawer
        open={viewGroupsDrawerOpen}
        onClose={() => toggleViewGroupsDrawer(false)}
        setCanEditOrDeleteGroup={setCanEditOrDeleteGroup}
        canEditOrDeleteGroup={canEditOrDeleteGroup}
      />
      <CreateEntityGroup open={createGroupDrawerOpen} />
    </div>
  );
};
export default EntityGroupDrawer;
