import React from 'react';
import { Box, Typography } from '@mui/material';
import { faCreditCard, faLayerGroup } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getSymbolFromCurrency from 'currency-symbol-map';
import StyledGenericTable from 'components/Table/StyledGenericTable';
import clipboardCheck from '../../../assets/clipboardCheck.svg';
import { TCurrencyTab } from '../../../models/currencyAccounts';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';
import { formatNumber } from '../../../utils/currency.helpers';
import useStyles from './CurrencyAccountsWidget.styles';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import FlagImage from 'components/Flag/FlagImage';

interface IProps {
  accountCurrencyStats: TCurrencyTab[];
}

const columns: ITableColumn[] = [
  {
    header: <Box paddingRight="80px" fontWeight="bold" />,
    accessor: 'currencyCode',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box paddingRight="80px" fontWeight="bold" />,
    accessor: 'entities',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box paddingRight="80px" fontWeight="bold" />,
    accessor: 'total',
    className: 'dd-privacy-allow',
  },
  {
    header: <Box paddingRight="80px" fontWeight="bold" />,
    accessor: 'availableBalance',
    className: 'dd-privacy-allow',
  },
];

const getFormattedBalance = (currencyCode: string, balance: number): string => {
  const currencySymbol = getSymbolFromCurrency(currencyCode!);
  const formatedAvailableBalance = formatNumber(+balance, 2);
  return `${currencySymbol}${formatedAvailableBalance}`;
};

const CurrencyAccountsTable: React.FC<IProps> = (props: IProps) => {
  const { accountCurrencyStats } = props;

  const classes = useStyles();

  const generateTableData = (tableData: TCurrencyTab[]) =>
    tableData.map((currencyTab) => ({
      currencyCode: (
        <div className={classes.currencyCell}>
          <Box className={classes.imageHolder}>
            <FlagImage currencyCode={currencyTab.currencyCode} />
          </Box>
          {currencyTab.currencyCode}
        </div>
      ),
      entities: (
        <Box className={classes.totals}>
          <FontAwesomeIcon icon={faLayerGroup as IconDefinition} /> {`${currencyTab.entities} Entities`}
        </Box>
      ),
      total: (
        <Box className={classes.totals}>
          <FontAwesomeIcon icon={faCreditCard as IconDefinition} /> {`${currencyTab.total} Accounts`}
        </Box>
      ),
      availableBalance: (
        <Box className={classes.balance}>
          {getFormattedBalance(currencyTab.currencyCode, currencyTab.availableBalance || 0)}
        </Box>
      ),
    }));

  if (accountCurrencyStats.length > 0) {
    return (
      <StyledGenericTable
        testId="currency-accounts-widget-table"
        data={generateTableData(accountCurrencyStats)}
        columns={columns}
        noHeader
        className={classes.tableContent}
      />
    );
  }
  return (
    <div className={classes.emptyTable}>
      <Box>
        <Typography className={classes.emptyTableText} variant="h5">
          No Currency Accounts Found
        </Typography>
        <Box className={classes.emptyTableIcon}>
          <img src={clipboardCheck} alt="empty-table" />
        </Box>
      </Box>
    </div>
  );
};

export default CurrencyAccountsTable;
