import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  dropdownContainer: {
    position: 'relative',
  },
  amountHolder: {
    display: 'flex',
    alignItems: 'stretch',
    '& > div:first-child': {
      width: '80px',
      marginRight: '-6px',
      height: 'auto',
    },
  },
  dropdown: {
    boxShadow: 'none',
    height: '50px',
    backgroundColor: '#f7f7f7',
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
    '& .MuiInputBase-input': {
      height: '100%',
      borderRadius: '5px',
      padding: '0 16px',
      paddingRight: '40px',
      marginBottom: '0px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
      right: '7px',
    },
  },
  dropdownList: {
    '& .MuiList-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
      maxHeight: '90vh',
      '& > li:first-of-type': { // Using first of type here as the placeholder will always be positioned as the first item
        display: 'none',
      },
      '& > *': {
        height: '49px',
      },
    },
  },
  placeholder: {
    color: '#34343473',
    fontWeight: 400,
  },
  red: {
    color: '#b95656 !important',
  },
  error: {
    position: 'absolute',
    fontSize: '12px !important',
  },
}), { name: 'InterAccountTransferDropdown' });
export default useStyles;
