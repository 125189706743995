import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    container: {
      padding: '70px 55px 0px 55px',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    tabs: {
      display: 'flex',
      marginTop: '30px',
      justifyContent: 'space-between',
    },
  }),
  { name: 'HeaderButtons' },
);

export default useStyles;
