import { APMainLayout } from 'components/Layout/MainLayout';
import React from 'react';
import Dropdown from '../../components/Dashboard/Dropdown';
import routes from '../../routes.path';
import Body from './Body/Body';

interface IInvoicePage {
  multiEntity?: boolean;
}

const Invoices: React.FC<IInvoicePage> = ({ multiEntity }: IInvoicePage) => {
  const breadcrumbs = [{ text: 'Home', url: routes.home }];

  return (
    multiEntity ? (
      <APMainLayout
        data-id="Invoices"
        breadCrumbs={breadcrumbs}
        title={(
          <div style={{ display: 'flex' }}>
            <span className="dd-privacy-allow">
              {' '}
              Invoices
              {' '}
              |
              {' '}
            </span>
            <Dropdown />
          </div>
        )}
      >
        <Body multiEntity />
      </APMainLayout>
    ) : (
      <APMainLayout
        data-id="Invoices"
        breadCrumbs={breadcrumbs}
        title="Invoices"
      >
        <Body />
      </APMainLayout>
    )
  );
};

export default Invoices;
