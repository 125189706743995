import { Box } from '@mui/material';
import React, { memo } from 'react';
import APPagination from 'components/APPagination';
import BackdropLoader from '../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable from '../../../../../components/Table/EmptyTable/EmptyTable';
import { Table } from '../../../../../components/Table/Table';
import clipboardCheck from '../../../../../assets/clipboardCheck.svg';
import { TUseDynamoTable } from '../../../../../hooks/useDynamoTable';
import { ITableColumn } from '../../../../../services/DatabaseServices/marker.interface';
import useStyles from './Drafted.styles';

interface IProps {
  tableData: any[] | undefined;
  table: TUseDynamoTable;
  columns: ITableColumn[];
}

const DraftedTable: React.FC<IProps> = (props: IProps) => {
  const { tableData, table, columns } = props;
  const styles = useStyles();

  if (table.loading) {
    return <BackdropLoader testId="backdrop-loader" />;
  }
  if (tableData?.length && !table.loading) {
    return (
      <Box className={styles.root}>
        <Table columns={columns} data={tableData} />
        <Box display="block" textAlign="right">
          <APPagination
            hasPrevious={table.hasPrevious}
            hasNext={table.hasMore === true}
            handleNext={table.handleNextPage}
            handlePrevious={table.handlePreviousPage}
          />
        </Box>
      </Box>
    );
  }

  if (!tableData?.length && !table.loading) {
    return (
      <EmptyTable
        icon={clipboardCheck}
        title="No Drafted Items"
        subtitle="You are all up to date"
      />
    );
  }

  return null;
};
export default memo(DraftedTable);
