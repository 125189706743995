import {
  Box, Fade, ModalProps,
} from '@mui/material';
import React from 'react';
import { StyledBox, StyledBaseModal } from './APModal.styles';

interface IContainerProps extends ModalProps {}

const Container: React.FC<IContainerProps> = ({
  open,
  onClose,
  children,
  className,
  ...rest
}: IContainerProps) => (
  <StyledBaseModal
    {...rest}
    open={open}
    onClose={onClose}
  >
    <Fade in={open}>
      <StyledBox className={className}>
        {children}
      </StyledBox>
    </Fade>
  </StyledBaseModal>
);

interface IWrapperProps {
    children: JSX.Element | JSX. Element[];
    className?: string | undefined;
}
const Wrapper: React.FC<IWrapperProps> = ({
  children,
  className,
}: IWrapperProps) => (
  <Box padding="0 56px" className={className}>
    { children }
  </Box>
);

const APModal = {
  Container,
  Wrapper,
};

export default APModal;
