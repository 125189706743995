import { Box, Typography } from '@mui/material';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';
import { Loader } from 'components/Loader';
import { rateType } from '../../../domain/InterAccountTransfer/DebitingAndCreditingDrawer/DisplayIndicativeRate/DisplayIndicativeRate';
import setInverseRate from '../../../utils/setInverseRate';
import InverseFxRateTooltip from '../../Molecules/InverseFxRateTooltip/InverseFxRateTooltip';
import { useStyles } from './Rate.styles';

interface IProps {
  loading?: boolean;
  type: 'live' | 'indicative' | 'expired';
  rate?: number;
  debitingCurrency: string | undefined;
  creditingCurrency: string | undefined;
}

const Rate: React.FC<IProps> = (props: IProps) => {
  const {
    type, rate, debitingCurrency, creditingCurrency, loading,
  } = props;
  const styles = useStyles();

  if (!debitingCurrency || !creditingCurrency) return null;

  const handleRateStyling = () => {
    if (type === rateType.LIVE) return styles.live;
    if (type === rateType.EXPIRED) return styles.expired;
    return styles.indicative;
  };

  return (
    <Box className={styles.root} data-testid="rates">
      <InverseFxRateTooltip
        text={(
          <span
            className={clsx(
              styles.rateFont,
              styles.coloredBackground,
              handleRateStyling(),
            )}
          >
            {type.toUpperCase()}
            <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} />
          </span>
        )}
      />
      <Box className={styles.rate}>
        <Box className={styles.amount}>
          <Typography
            variant="h5"
            className={clsx(
              styles.bold,
              type === rateType.LIVE
              && styles.liveRate,
            )}
          >
            {
              (loading || rate === undefined)
                ? (
                  <Loader testId="loader" size="20px" />
                )
                : rate.toFixed(4)
            }
          </Typography>
        </Box>
      </Box>
      {
        rate
        && (
          <Box className={styles.inverseRateWrapper}>
            <Typography variant="h5" className={clsx(styles.inverseRate)}>
              {setInverseRate(rate)}
            </Typography>
          </Box>
        )
      }
    </Box>
  );
};

export default Rate;
