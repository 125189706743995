import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactText } from 'react';
import { DateTime } from 'luxon';

export type TStatus = 'COMPLETED' | 'ERROR' | 'INITIATE';
export type TDownload = {
  status: TStatus;
  downloadLink?: string;
  id: ReactText;
  downloadTime: DateTime;
  accountId?:string;
};
export type TDownloadQueue = Array<TDownload>;
export type TInitialState = {
  downloadQueue: TDownloadQueue;
};

const initialState: TInitialState = {
  downloadQueue: [],
};

export const generatePdfSlice = createSlice({
  initialState,
  name: 'generatePdf',
  reducers: {
    enqueue: (
      state,
      actions: PayloadAction<Omit<TDownload, 'downloadTime'>>,
    ): TInitialState => (
      {
        ...state,
        downloadQueue:
         [...state.downloadQueue, { ...actions.payload, downloadTime: DateTime.now() }],
      }),
    dequeue: (
      state,
      actions: PayloadAction<ReactText>,
    ): TInitialState => {
      const index = state.downloadQueue.findIndex((download) => download.id === actions.payload);
      const clonedDownloadQeueue = [...state.downloadQueue];
      if (index > -1) {
        clonedDownloadQeueue.splice(index, 1);
      }

      return {
        ...state,
        downloadQueue: clonedDownloadQeueue,
      };
    },
  },
});

export const { actions } = generatePdfSlice;
