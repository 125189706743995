import React, { memo, useEffect } from 'react';
import useAuthy from '../../hooks/useAuthy';
import Modal from './Modal/Modal';

const Authy: React.FC = () => {
  const {
    triggerMfa, reduxState, clearAuthy, status, triggerSubmit,
  } = useAuthy();

  const { open, phoneNumber } = reduxState;

  useEffect(() => {
    if (
      open
      && status === 'INITIATED'
      && !phoneNumber
    ) {
      triggerMfa('OPTIONS');
    }
  }, [status]);

  return (
    <>
      {open
        ? (
          <Modal
            open={open}
            status={status}
            onClose={clearAuthy}
            handleComplete={triggerSubmit}
          />
        )
        : <></>}
    </>
  );
};

export default memo(Authy);
