import styled from '@mui/styled-engine';
import _default from 'theme';
import { Tab } from '@mui/material';

const { palette, typography } = _default;

const StyledTab = styled(Tab)({
  textDecoration: 'none',
  textTransform: 'uppercase',
  color: '#343434',
  borderBottom: '3px solid transparent',
  fontSize: 12,
  lineHeight: '17px',
  marginLeft: 40,
  padding: 0,
  fontWeight: typography.fontWeightSemiBold,
  minWidth: 0,
  letterSpacing: '1.8px',
  fontFamily: typography.fontFamily,
  '&:first-of-type': {
    marginLeft: 0,
  },
  '&:hover, &:focus': {
    color: `${palette.primary.main} !important`,
  },
});

export default StyledTab;
