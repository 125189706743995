const allowedActionsButtons = [
  'IAT SUCCESSFULLY RELEASED',
  'IAT SUBMITTED REQUIRE FX',
  'IAT SUBMITTED DOES NOT REQUIRE FX',
  'IAT CLICKED',
  'FINISH BALANCE TRANSFER',
  'IAT APPROVED',
  'IAT SUBMITTED FOR APPROVAL',
  'BOOK FX',
  'CREATE BENEFICIARY',
  'SUBMIT BENEFICIARY FOR APPROVAL',
  'SUBMIT BENEFICIARY FOR APPROVAL SUCCESSFULLY',
  'APPROVE/REJECT CLICKED',
  'APPROVE BENEFICIARY',
  'REJECT BENEFICIARY',
  'BENEFICIARY SUBMITTED',
  'BENEFICIARY REJECTED',
  'NO, KEEP BENEFICIARY',
  'SHARE BENEFICIARY',
  'SHARE BENEFICIARY TO ENTITY',
  'SUCCESSFULLY SHARED SELECTED BENEFICIARIES',
  'CREATE PAYMENTS',
  'SUBMIT PAYMENT',
  'REQUEST LIVE RATES',
  'PAYMENT IS BEING FUNDED',
  'APPROVE BATCH AND BOOK FX',
  'REJECT BATCH',
  'SUCCESSFULLY REJECTED PAYMENT',
  'ONBOARDING FORM INITIATE',
  'ONBOARDING FORM SUBMIT',
  'ONBOARDING FORM SAVE DRAFT',
  'ONBOARDING FORM SHARE BUTTON',
  'ONBOARDING FORM SEND EMAILS',
];

export default allowedActionsButtons;
