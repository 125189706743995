import { styled, Modal } from '@mui/material';

const StyledModal = styled(Modal)({
  fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledDiv = styled('div')({
  minWidth: '350px',
  borderRadius: '5px',
  backgroundColor: '#fff',
  padding: '15px',
  outline: 'none',
});

export {
  StyledModal,
  StyledDiv,
};
