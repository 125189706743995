import { Box } from '@mui/material';
import React from 'react';
import APPagination from 'components/APPagination';
import useStyles from './Footer.styles';
import Buttons from '../Buttons/Buttons';

interface IProps {
  handleClose: () => void;
  totalPayments: number | undefined;
  totalInvalidPayments: number | undefined;
  table: ITable;
  batchId: string;
  batchErrors: string[] | undefined;
}

interface ITable {
  data: any[] | undefined;
  hasMore: boolean | undefined;
  hasPrevious: boolean;
  handleNextPage: () => Promise<void>;
  handlePreviousPage: () => Promise<void>;
  loading: boolean;
  startFromStack?: string[];
}

const Footer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    table,
    totalPayments,
    totalInvalidPayments,
    batchId,
    batchErrors,
    handleClose,
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
      <Box className={classes.pagination}>
        {batchErrors
          && batchErrors.length === 0
          && table.data
          && (
            <APPagination
              hasPrevious={table.hasPrevious}
              hasNext={Boolean(table.hasMore)}
              handleNext={table.handleNextPage}
              handlePrevious={table.handlePreviousPage}
            />
          )}
      </Box>
      <Buttons
        handleClose={handleClose}
        batchErrors={batchErrors}
        batchId={batchId}
        disableSubmitButton={Boolean(totalPayments
          && totalInvalidPayments
          && (totalPayments <= totalInvalidPayments))}
      />
    </Box>
  );
};

export default Footer;
