import { Typography, Box } from '@mui/material';
import React, { FC, memo } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'components/Inputs/Input';
import useStyles from './index.styles';

interface IProps {
  handleShareKeyUp: (e: any) => void;
  totalAcccounts: number,
}
const Header: FC<IProps> = ({ handleShareKeyUp, totalAcccounts }:IProps) => {
  const classes = useStyles();

  const subtitle = 'Only entities which you have permission to share beneficiaries with will be displayed below.';

  return (
    <div className={classes.header}>
      <div className={classes.headerText} data-testid="info-share-bene">
        <Typography className={classes.pretitle}>
          Beneficiary share
        </Typography>
        <b className={classes.title}>
          Select which entities to share with
        </b>
        <Typography className={classes.subtitle}>
          { subtitle }
        </Typography>
      </div>
      <Box className={classes.inputWrapper}>
        <Input
          onKeyUp={handleShareKeyUp}
          fullWidth
          testId="input"
          InputProps={{
            startAdornment: <FontAwesomeIcon className={classes.icon} icon={faSearch} />,
          }}
          variant="filled"
          placeholder={`Search ${totalAcccounts} ${totalAcccounts === 1 ? 'entity' : 'entities'}`}
          disabled={!totalAcccounts}
        />
      </Box>
    </div>
  );
};

export default memo(Header);
