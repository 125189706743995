import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import { Stat } from 'components/Stat';
import React from 'react';
import KeyValueTable from 'components/Table/KeyValueTable/KeyValueTable';
import { DrawerLineBreak } from 'components/Drawer';
import { formatCurrency } from '../../../../utils/currency.helpers';
import { ISelectedAccount } from '../AccountInfoDrawer';

interface IBody {
  selectedAccount: ISelectedAccount,
}

const CurrencyAccountInfo: React.FC<IBody> = (props: IBody) => {
  const { selectedAccount } = props;

  const details = [
    {
      key: 'Currency',
      value: selectedAccount.tabs ? selectedAccount.account.currencyCode : '-',
      boldValue: true,
    },
    { key: 'SWIFT/BIC', value: selectedAccount.account.swift || '-' },
    {
      key: 'IBAN',
      value: selectedAccount.account.iban
        ? selectedAccount.account.iban.toUpperCase()
        : '-',
    },
    { key: 'Account Number', value: selectedAccount.account.accountNumber || '-' },
    selectedAccount.account.type === 'Wallet'
      ? { key: 'Reference', value: selectedAccount.account.reference || '-' }
      : { key: '', value: '' },
  ];
  return (
    <div style={{ paddingTop: '32px', paddingBottom: '32px' }}>
      <BaseDrawer.Body>

        <div className="dd-privacy-allow" data-testid="drawer-ballance-transactions" style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '24px' }}>
          <Stat
            title="Pending Transactions"
            value={(
              formatCurrency(selectedAccount?.account.currencyCode,
                selectedAccount?.account.pendingTransactions)
            )}
            testId="drawer-pending-transactions"
          />
          <Stat
            title="Cleared Balance"
            value={(
              formatCurrency(selectedAccount?.account.currencyCode,
                selectedAccount?.account.clearedBalance)

            )}
            testId="drawer-cleared-ballance"
          />
          <Stat
            title="Available Balance"
            value={(
              <b data-testid="drawer-available-ballance">
                {formatCurrency(selectedAccount?.account.currencyCode,
                  selectedAccount?.account.availableBalance)}
              </b>
            )}
          />
        </div>
      </BaseDrawer.Body>
      <DrawerLineBreak />
      <BaseDrawer.Body>
        <div style={{ paddingTop: '25px', paddingBottom: '25px' }}>
          <KeyValueTable testId="account-drawer-body" obscure data={details} />
        </div>
      </BaseDrawer.Body>
    </div>
  );
};

export default CurrencyAccountInfo;
