import { Checkbox } from 'components/Checkbox';
import AcknowledgeModal from 'components/Modals/AcknowledgeModal';
import React, { useState } from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import useStyles from './index.styles';

export interface IProps {
  handleConfirmation?: () => Promise<void> | void;
  handleClose?: () => void;
  acknowledgeModalOpen: boolean;
}

const BeneFraudAcknowledgeModal: React.FC<IProps> = (props: IProps) => {
  const { handleConfirmation, handleClose, acknowledgeModalOpen } = props;

  const styles = useStyles();
  const [isConfirmed, setIsConfirmed] = useState(false);

  const modalContent = (
    <span className={styles.list} data-testid="beneficiary-fraud-ack-modal">
      <p>
        Scams can happen to anyone. Please be aware that criminals can use
        different methods to defraud you. When making a payment, you should
        always carry out some simple checks to protect yourself and your
        business against fraud:
      </p>
      {' \n '}
      <ul>
        <li>
          Scammers often impersonate people that you know. Before making a
          payment to an individual, company, or organisation, always check that
          the request for payment is genuine, and the payment details are
          accurate, using a trusted method of communication to verify.
        </li>
        <li>
          Always conduct the necessary due diligence into the investment being
          made, ensuring to verify the legitimacy of the company, organisation,
          and individuals associated, with whom you are entrusting with your
          money.
        </li>
      </ul>
      <p>
        If you are unsure of any payment that you have been asked to make, stop
        and carryout additional corroboration to ensure that you are comfortable
        before authorising the transaction.
        {' \n '}
        For further information on steps to take if you believe a payment may be
        fraudulent, please go to
        {' '}
        <a
          href="https://www.fca.org.uk/consumers/protect-yourself-scams"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.fca.org.uk/consumers/protect-yourself-scams
        </a>
      </p>
      <div className={styles.confirmContainer}>
        <Checkbox
          checked={isConfirmed}
          onChange={() => setIsConfirmed(!isConfirmed)}
          testId="confirmationCheckbox"
          className="confirmCheckbox"
        />
        <div className="confirmText">
          I confirm the beneficiary details entered are correct.
        </div>
      </div>
    </span>
  );

  return (
    <AcknowledgeModal
      open={acknowledgeModalOpen}
      title="Fraud Alert"
      content={modalContent}
      actionButtonText="Continue"
      cancelButtonText="Cancel"
      buttonsDisabled={!isConfirmed}
      handleActionButton={handleConfirmation}
      icon={faExclamationTriangle}
      handleClose={handleClose}
      headerStyle={{ justifyContent: 'center' }}
      bodyStyle={{ maxHeight: 600 }}
    />
  );
};
export default BeneFraudAcknowledgeModal;
