import { Button, CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useFormikContext } from 'formik';
import { IWizardModalFooter } from '../../../Modal.interfaces';
import useStyles from '../../index.styles';
import { useWizardModal } from '../../context';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const WizardModalFooter = ({
  submitText = 'Submit',
  prevText = 'Prev',
  nextText = 'Next',
}: IWizardModalFooter) => {
  const {
    state, isFinalStep, handlePrevious,
  } = useWizardModal();
  const { step, nestedSteps, loading } = state;

  const { footer, icon } = useStyles({ isFirstStep: step === 0 });

  const { errors, isSubmitting, validateForm } = useFormikContext();

  useEffect(() => {
    validateForm();
  }, [validateForm, nestedSteps, step]);

  const isReadyForSubmission = isFinalStep && nestedSteps[step].current === nestedSteps[step].total;

  return (
    <div className={footer}>
      {
        step > 0 && (
          <Button
            type="button"
            color="secondary"
            variant="contained"
            disableElevation
            disabled={isSubmitting}
            startIcon={<FontAwesomeIcon icon={faArrowLeft as IconDefinition} className={icon} color="#33333350" />}
            onClick={handlePrevious}
            data-testid="prev-button"
          >
            {prevText}
          </Button>
        )
      }

      <Button
        variant="contained"
        color={isReadyForSubmission ? 'primary' : 'secondary'}
        disableElevation
        disabled={Object.keys(errors).length > 0 || isSubmitting || loading}
        type="submit"
        {...(!isReadyForSubmission && { endIcon: <FontAwesomeIcon icon={faArrowRight as IconDefinition} className={icon} color="#33333350" /> })}
        {...((isSubmitting || loading) && { endIcon: <CircularProgress color="inherit" size={12} /> })}
        data-testid="submit-next-button"
      >
        {isReadyForSubmission ? submitText : nextText}
      </Button>
    </div>
  );
};

export default WizardModalFooter;
