/* eslint-disable max-lines-per-function */
import { CurrencyList, ValidateBeneficiaryRequest } from '@alpha/bene-dtos';
import { datadogRum } from '@datadog/browser-rum';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { TStore } from 'store';
import { iAllowedAccounts } from 'store/accounts/accounts.reducer';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { ElasticTableContext } from '../../../context/ElasticTableContext';
import { SinglePaymentContext } from '../../../context/ManualPaymentContexts';
import { SinglePaymentContext as MultiEntityPaymentContext } from '../../PaymentsMultiEntity/PaymentContext';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useAuthorization from '../../../hooks/useAuthorization';
import { useForm } from '../../../hooks/useForm';
import { TCountries } from '../../../models/countries';
import { TUserAccount, UserRole } from '../../../models/user';
import routes from '../../../routes.path';
import BeneficiariesService from '../../../services/Beneficiaries/beneficiaries.service';
import CountriesService from '../../../services/Countries/countries.service';
import browserHistory from '../../../services/history/browserHistory';
import { initiateBeneficiary } from '../../../store/authy/actions';
import { initialValues, validation } from './Form/formData';
import EntityGroupsService from '../../../services/EntityGroups/entityGroups.service';
import { EntityGroup } from '../../../models/entityGroups';
import CurrencyAccountsService from '../../../services/CurrencyAccounts/currencyAccounts.service';

type Identifiers = {
  accountIds: string[];
  entityGroupIds: string[];
};

export const useCreateBeneficiary = (
  handleDrawerClose: () => void,
  multiEntity: boolean,
) => {
  const [currencyList, setCurrencies] = useState<CurrencyList>({
    currencies: [],
    topCurrencies: [],
  });
  const [countries, setCountries] = useState<TCountries>([]);
  const [formValidation, setFormValidation] = useState(validation);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [acknowledgeModalOpen, setAcknowledgeModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const elasticTableContext = useContext(ElasticTableContext);
  const singlePaymentContext = useContext(SinglePaymentContext);
  const multiEntitySinglePaymentContext = useContext(MultiEntityPaymentContext);
  const [error, setError] = useState<string>('');
  const dispatch = useDispatch();
  const [entityGroups, setEntityGroups] = useState<EntityGroup[]>([]);

  const beneficiaryApproverOwn = useAuthorization([
    [UserRole.BENEFICIARY_APPROVER_OWN],
  ]);

  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );

  const { flags } = useGetFeatureFlags();
  const isFraudModalEnabled = useMemo(() => (
    flags?.some((flag) => flag.name === 'fraudModalEnabled' && flag.isActive)
  ), [flags]);

  const form = useForm(
    multiEntity
      ? {
        ...initialValues,
        accountIds: [],
      }
      : initialValues,
    multiEntity
      ? {
        ...formValidation,
        accountIds: yup
          .array()
          .min(1, 'Please select at least one Entity/ Group'),
      }
      : formValidation,
    handleFormSubmission,
  );
  const sb = useAlphaSnackbar();

  useEffect(() => {
    const promises = [getCurrencies(), getCountries()];
    if (multiEntity) {
      promises.push(getEntityGroups());
    }
    Promise.all(promises);
  }, []);

  async function getCurrencies() {
    try {
      const accountCurrencies = await CurrencyAccountsService.getAccountsCurrencies();
      setCurrencies(await BeneficiariesService.getCurrencies(
        {
          filterAllowedCurrencies: true,
          includeCurrencies: accountCurrencies,
        },
      ));
    } catch (e) {
      console.log(e);
      sb.trigger(
        e.response?.data?.error || 'There was an issue retrieving currencies',
      );
    }
  }

  async function getCountries() {
    try {
      setCountries(await CountriesService.getCountries());
    } catch (e) {
      sb.trigger(
        e.response?.data?.error || 'There was an issue retrieving countries',
      );
    }
  }

  const getAccountsUserHasApproverOwnPermFor = async (): Promise<TUserAccount[]> => {
    try {
      return await BeneficiariesService.getBeneficiaryApproverOwnEntities();
    } catch {
      sb.trigger('There was an error retrieving your accounts for your user');
      return [];
    }
  };

  async function getEntityGroups() {
    try {
      setEntityGroups(await EntityGroupsService.getEntityGroups());
    } catch (e) {
      sb.trigger(
        e.response?.data?.error || 'There was an issue retrieving Entity Groups',
      );
    }
  }

  async function handleSubmitBeneSingleEntity(formValues: ValidateBeneficiaryRequest) {
    setLoading(true);
    if (singlePaymentContext) {
      await validateManualPaymentBeneficiary(formValues);
      setLoading(false);
      handleDrawerClose();
      return;
    }

    const [beneficiary] = await BeneficiariesService.submitBeneficiary({
      ...formValues,
      accountIds: undefined,
      channel: 'portal',
      beneInputterFraudWarningAcknowledgment: formValues.fraudWarningAcknowledged,
    });

    if (beneficiary.applyFinancialErrored) {
      setModalOpen(true);
    }
    if (
      beneficiary.status === 'UPLOADED'
        && beneficiary.accountBeneficiaryId
        && beneficiary.beneficiaryId
    ) {
      validated([beneficiary.accountBeneficiaryId], beneficiary.beneficiaryId,
        formValues.fraudWarningAcknowledged);
      handleDrawerClose();
    } else if (beneficiary.status === 'INVALID') {
      sb.trigger(beneficiary.errors[0]);
    }
  }

  async function handleSubmitBeneMultiEntity(formValues: ValidateBeneficiaryRequest,
    mappedAccountIds: string[]) {
    setLoading(true);
    if (mappedAccountIds?.length === 0) {
      throw new Error('You do not have Beneficiary Inputter permissions for any entities within the groups selected');
    }

    if (multiEntitySinglePaymentContext) {
      await validateManualPaymentBeneficiaryMultiEntity(
        formValues,
        mappedAccountIds[0],
      );
      setLoading(false);
      handleDrawerClose();
      return;
    }

    const beneficiaries = await BeneficiariesService.submitBeneficiary({
      ...formValues,
      accountIds: mappedAccountIds,
      channel: 'portal',
      beneInputterFraudWarningAcknowledgment: formValues.fraudWarningAcknowledged,
    });

    const accountBeneficiaryIds = beneficiaries.reduce(
      (previousValue: string[], currentValue) => {
        if (currentValue?.accountBeneficiaryId) {
          return [
            ...previousValue,
            currentValue.accountBeneficiaryId,
          ];
        }
        return [...previousValue];
      },
      [],
    ) ?? [];

    if (!beneficiaries.length) {
      throw new Error('There was a problem submitting your Beneficiary, please try again later.');
    }

    if (beneficiaries[0].applyFinancialErrored) {
      setModalOpen(true);
    }

    if (
      beneficiaries[0].status === 'UPLOADED'
        && beneficiaries[0].beneficiaryId
        && accountBeneficiaryIds
    ) {
      const accountWithApproverPermissions = await getAccountsUserHasApproverOwnPermFor();
      const authorizedAccounts = accountWithApproverPermissions.filter(
        (authorizedAccount) => mappedAccountIds.includes(authorizedAccount.id),
      );
      validatedMultiEntity(accountBeneficiaryIds, beneficiaries[0].beneficiaryId,
        authorizedAccounts?.length ? authorizedAccounts[0].id : undefined,
        formValues.fraudWarningAcknowledged);
      handleDrawerClose();
    } else if (beneficiaries[0].status === 'INVALID') {
      sb.trigger(beneficiaries[0].errors[0]);
    }
  }

  async function handleFormSubmission(formValues: ValidateBeneficiaryRequest) {
    const mappedAccountIds: string[] = multiEntity ? await preValidateFormValues(formValues) : [];

    const showFraudModal = shouldShowFraudModal(mappedAccountIds,
      formValues.fraudWarningAcknowledged);

    if (showFraudModal) {
      setAcknowledgeModalOpen(true);
    } else {
      setAcknowledgeModalOpen(false);
      try {
        if (multiEntity) {
          await handleSubmitBeneMultiEntity(formValues, mappedAccountIds);
        } else {
          await handleSubmitBeneSingleEntity(formValues);
        }
      } catch (e) {
        const errorMessage = (e.response?.data?.errors?.length
        && e.response.data.errors[0].errors[0])
      || e.response?.data?.error
      || e.message
      || 'There was an error submitting your beneficiary';
        setError(errorMessage);
        sb.trigger(errorMessage);
      } finally {
        setLoading(false);
        if (elasticTableContext) elasticTableContext();
      }
    }
  }

  const shouldShowFraudModal = (accountIds: string[], modalAcknowledged?: boolean) => {
    if (modalAcknowledged || !isFraudModalEnabled) return false;

    let accountContainMicroEnterprise = false;

    accountIds.forEach((accId) => {
      const accountIsMicroEnterprise = allowedAccounts
        .some((acc) => acc.id === accId && acc.isMicroEnterprise === true);

      if (accountIsMicroEnterprise) {
        accountContainMicroEnterprise = true;
      }
    });

    return accountContainMicroEnterprise;
  };

  const handleApproveAFCreateBeneficiary = () => {
    form.setFieldValue('userIgnoresApplyFinancialErrors', true);
    form.handleSubmit();
    setModalOpen(false);
  };

  async function validateManualPaymentBeneficiaryMultiEntity(
    formValues: ValidateBeneficiaryRequest,
    accountId: string,
  ) {
    if (!multiEntitySinglePaymentContext) return;

    const validatedBene = await BeneficiariesService.postValidatePaymentBene(
      formValues,
      accountId,
    );

    if (validatedBene.applyFinancialErrored && validatedBene?.errors?.length) {
      setModalOpen(true);
      throw Error(validatedBene.errors[0]);
    }

    if (validatedBene) {
      multiEntitySinglePaymentContext.setCurrentPayment({
        ...multiEntitySinglePaymentContext.currentPayment,
        beneficiary:
          BeneficiariesService.mapBeneficiaryDraftToBeneficiary(validatedBene),
      });
    }
  }

  async function validateManualPaymentBeneficiary(
    formValues: ValidateBeneficiaryRequest,
  ) {
    if (!singlePaymentContext) return;

    const validatedBene = await BeneficiariesService.postValidatePaymentBene(
      formValues,
    );

    if (validatedBene.applyFinancialErrored && validatedBene?.errors?.length) {
      setModalOpen(true);
      throw Error(validatedBene.errors[0]);
    }

    if (validatedBene) {
      singlePaymentContext.setCurrentPayment({
        ...singlePaymentContext.currentPayment,
        beneficiary:
          BeneficiariesService.mapBeneficiaryDraftToBeneficiary(validatedBene),
      });
    }
  }

  const validated = (accountBeneficiaryIds: string[], beneficiaryId: string,
    fraudWarningAcknowledged?: boolean) => {
    if (beneficiaryApproverOwn.authorized) {
      dispatch(
        initiateBeneficiary({
          batchId: beneficiaryId,
          accountBeneficiaryIds,
          type: 'BENEFICIARY',
          fraudWarningAcknowledged,
        }),
      );
    } else {
      datadogRum.addAction('SUBMIT BENEFICIARY FOR APPROVAL SUCCESSFULLY');
      sb.trigger('Beneficiary successfully submitted for approval', 'success');
    }
    browserHistory.push(`${routes.beneficiaries}?tab=pending`);
  };

  const validatedMultiEntity = (accountBeneficiaryIds: string[], beneficiaryId: string,
    accountId?: string, fraudWarningAcknowledged?: boolean) => {
    if (accountId) {
      dispatch(
        initiateBeneficiary({
          batchId: beneficiaryId,
          accountBeneficiaryIds,
          type: 'BENEFICIARY',
          accountId,
          fraudWarningAcknowledged,
        }),
      );
    } else {
      datadogRum.addAction('SUBMIT BENEFICIARY FOR APPROVAL SUCCESSFULLY');
      sb.trigger('Beneficiary successfully submitted for approval', 'success');
    }
    browserHistory.push(`${routes.beneficiaries}?tab=pending`);
  };

  const preValidateFormValues = async (
    formValues: ValidateBeneficiaryRequest,
  ): Promise<string[]> => {
    const defaultIdentifiers: Identifiers = {
      accountIds: [],
      entityGroupIds: [],
    };

    const { accountIds, entityGroupIds }: Identifiers = formValues?.accountIds?.reduce(
      (previousValue, { value }) => (value.includes('#Group')
        ? {
          ...previousValue,
          entityGroupIds: [
            ...previousValue.entityGroupIds,
            value.replace('#Group', ''),
          ],
        }
        : {
          ...previousValue,
          accountIds: [...previousValue.accountIds, value],
        }),
      defaultIdentifiers,
    ) ?? defaultIdentifiers;

    let authorizedAccountIds;
    if (entityGroupIds?.length > 0) {
      authorizedAccountIds = await BeneficiariesService.getEntityGroupsAccountsWithBeneInputter(
        entityGroupIds,
        accountIds,
      );
      return authorizedAccountIds;
    }
    return accountIds;
  };

  return {
    form,
    countries,
    currencyList,
    modalOpen,
    error,
    setModalOpen,
    loading,
    setFormValidation,
    handleFormSubmission,
    handleApproveAFCreateBeneficiary,
    entityGroups,
    acknowledgeModalOpen,
    setAcknowledgeModalOpen,
  };
};

export default useCreateBeneficiary;
