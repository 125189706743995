import { Button, styled } from '@mui/material';

const StyledMenuIconWrapper = styled(Button)({
  height: '100%',
  width: '100%',
  '&:hover svg': {
    color: 'rgba(51, 51, 51, 1)',
  },
});

export { StyledMenuIconWrapper };
