import { Box } from '@mui/material';
import React from 'react';
import useStyles from './TooltipTitle.styles';

interface IProps {
  paymentErrors: string[] | undefined;
  row: number | undefined;
}

const TooltipTitle: React.FC<IProps> = (props: IProps) => {
  const { paymentErrors, row } = props;
  const classes = useStyles();
  return (
    <>
      <Box className={classes.tooltipHeader}>
        <b>Multiple errors</b>
        {row
          ? (
            <span>
              Row
              {' '}
              {row}
            </span>
          )
          : null}
      </Box>
      <ul className={classes.tooltipList}>
        {paymentErrors?.map((error: string, errorIndex: number) => (
          <li key={errorIndex}>{error}</li>
        ))}
      </ul>
    </>
  );
};

export default TooltipTitle;
