import { AxiosResponse } from 'axios';
import instance from '../Axios/instance';

import { TSearchParams } from '../../hooks/useSearch';

class SearchService {
  /**
     * GetTableData - retrieves table data based on the
     * url provided including the query strings which consist of the search criteria
     */
  public static async GetTableData<T>(searchParams: TSearchParams): Promise<T> {
    const response: AxiosResponse<T> = await instance({
      method: searchParams.method || 'GET',
      url: searchParams.baseUrl,
      params: { ...searchParams.queryParams },
      data: searchParams.body,
    });

    return response.data;
  }
}

export default SearchService;
