/* eslint-disable react/jsx-indent */
import { PaymentDraftDto, PaymentReleasedDto } from '@alpha/payments-dtos';
import { PaymentStatus } from '@alpha/payments-dtos/lib/paymentsReleased/PaymentStatus';
import { Box, Button } from '@mui/material';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { TStatusVariants } from 'components/Status';
import Status from 'components/Status/Status';
import APPagination from 'components/APPagination';
import { Table as GenericTable } from '../../../../../components/Table/Table';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../hooks/useReportsPolling';
import { TSearchParams } from '../../../../../hooks/useSearch';
import { formatNumber } from '../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import { PaymentSummaryBeneficiary } from '../PaymentSummary';
import { getColumns } from './columns';
import useStyles from './Table.styles';
import { ISelectedAccount } from '../../../../CurrencyAccounts/AccountInfoDrawer/AccountInfoDrawer';

interface IProps {
  data: Array<PaymentDraftDto | PaymentReleasedDto>;
  isSameCurrency: boolean;
  postApproval: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  handleNextPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handlePreviousPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  setBeneficiary: React.Dispatch<
    React.SetStateAction<PaymentSummaryBeneficiary>
  >;
  setSelectedCurrencyAccount: React.Dispatch<React.SetStateAction<ISelectedAccount | undefined>>
}

const isPaymentDraftDto = (
  param: PaymentDraftDto | PaymentReleasedDto,
): param is PaymentDraftDto => {
  if ((param as PaymentDraftDto).beneficiaryDraft) {
    return true;
  }
  return false;
};

const getPaymentStatusVariant = (status: PaymentStatus): TStatusVariants | undefined => {
  switch (status) {
    case PaymentStatus.RETURNED:
    case PaymentStatus.CANCELLED:
    case PaymentStatus.REJECTED:
      return 'error';
    case PaymentStatus.RELEASED:
      return 'success';
    case PaymentStatus.PENDING:
      return 'orange';
    default:
      return 'info';
  }
};

const Table: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();
  const {
    data,
    hasNext,
    hasPrevious,
    postApproval,
    isSameCurrency,
    handleNextPage,
    handlePreviousPage,
    setBeneficiary,
    setSelectedCurrencyAccount,
  } = props;
  const { executeReportGeneration } = useReportsPolling(PageType.Payments);
  const handleReportGeneration = async (type: FileType, id: string, downloadType: DownloadType) => {
    await executeReportGeneration(type, id, undefined, undefined, downloadType);
  };
  const tableData = data.map(
    (item: PaymentDraftDto | PaymentReleasedDto) => {
      if (isPaymentDraftDto(item)) {
        return {
          beneficiaryName:
            <Button
              disableRipple
              className={styles.drawerButton}
              onClick={() => {
                setBeneficiary(item.beneficiaryDraft);
              }}
            >
             <span>{item.beneficiaryDraft.name}</span>
            </Button>,
          beneficiaryIban:
          <span>{item.beneficiaryDraft.iban}</span> || '-',
          fundingAccount:
            <Button
              disableRipple
              className={styles.drawerButton}
              onClick={() => {
                setSelectedCurrencyAccount({
                  account: item.fundingCurrencyAccount as CurrencyAccountDto,
                  tabs: {
                    currency: item.fundingCurrencyAccount!.currency,
                    currencyCode: item.fundingCurrencyAccount!.currencyCode,
                  },
                });
              }}
            >
              <span>{item.fundingAccount}</span>
            </Button>,
          debitAccount:
            <Button
              disableRipple
              className={styles.drawerButton}
              onClick={() => {
                setSelectedCurrencyAccount({
                  account: item.debitingCurrencyAccount as CurrencyAccountDto,
                  tabs: {
                    currency: item.debitingCurrencyAccount!.currency,
                    currencyCode: item.debitingCurrencyAccount!.currencyCode,
                  },
                });
              }}
            >
              <span>
                {' '}
                {item.debitingAccount}
                {' '}
              </span>
            </Button>,
          purposeOfPayment: item.purpose,
          paymentRef: item.reference,
          paymentDate: formatIsoDate(item.paymentDate),
          paymentCurrency: item.debitingCurrency,
          amount: (
            <Box textAlign="right" fontWeight="bold">
              {formatNumber(item.amount, 2)}
              {' '}
              {item.fixedSide}
            </Box>
          ),
        };
      }
      return {
        beneficiaryName:
          <Button
            disableRipple
            className={styles.drawerButton}
            onClick={() => {
              setBeneficiary(item.beneficiary);
            }}
          >
            {item.beneficiary.name}
          </Button>,
        purposeOfPayment: item.purpose,
        paymentRef: item.reference,
        paymentDate: formatIsoDate(item.paymentDate),
        paymentAmount: (
          <Box textAlign="right" fontWeight="bold">
            {formatNumber(item.amount, 2)}
            {' '}
            {item.currencyCode}
          </Box>
        ),
        fixedAmount: (
          <Box textAlign="right" fontWeight="bold">
            {formatNumber(item.instructedAmount, 2)}
            {' '}
            {item.instructedCurrency}
          </Box>
        ),
        paymentStatus: (
          <Box textAlign="right">
            <Status
              variant={getPaymentStatusVariant(item.status)}
            >
              {item.status}
            </Status>
          </Box>
        ),
        downloadButton: (
          <Box className={styles.columnDownloadButton}>
            <Tooltip title={`Download ${!isSameCurrency ? 'FX' : ''} ticket`}>
              <Button
                className={styles.downloadButton}
                size="large"
                color="secondary"
                onClick={() => handleReportGeneration(FileType.PDF, item.id, DownloadType.Single)}
              >
                <FontAwesomeIcon className={styles.downloadIcon} icon={faDownload} />
              </Button>
            </Tooltip>
          </Box>
        ),
      };
    },
  );
  return (
    <>
      <div className={styles.genericTableWrapper}>
        <GenericTable
          columns={getColumns(postApproval)}
          data={tableData}
        />
      </div>
      <Box className={styles.paginationWrapper}>
        <APPagination
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          handleNext={handleNextPage as any} // need to update props here on sb
          handlePrevious={handlePreviousPage as any} // need to update props here on sb
        />
      </Box>
    </>
  );
};
export default memo(Table);
