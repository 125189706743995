import {
  Alert,
  Box,
  Typography,
  styled,
} from '@mui/material';

export const ContainerStyled = styled(Box)(() => ({
  maxHeight: 'inherit',
  overflowY: 'scroll',
  paddingRight: 24,
  position: 'relative',
  animation: 'myEffect 10s',
}));

export const EntityInformationStyled = styled(Box)(() => ({
  fontSize: 15,
  fontWeight: 400,
}));

export const TextStyled = styled(Typography)(() => ({
  color: '#909090',
  fontSize: '16px',
  lineHeight: '24px',
}));

export const ProminentTextStyled = styled('b')(() => ({
  fontWeight: 'normal',
  color: '#000',
}));

export const AlertBoxStyled = styled(Alert)(() => ({
  margin: '20px 0',
}));

export const UnOrderedListStyled = styled('ul')(() => ({
  paddingInlineStart: 15,
  paddingLeft: 10,
  marginLeft: 5,
}));

export const AlertSubtitleStyled = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 'normal',
  padding: '0 2px',
}));

export const AlertTextStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const learnMoreStyles = {
  fontSize: 14,
  color: 'inherit',
  border: 0,
  backgroundColor: 'inherit',
  padding: '0 2px',
  cursor: 'pointer',
  textDecoration: 'underline',
};

export const AlertLearnMoreButtonStyled = styled('button')(() => (learnMoreStyles));

export const AlertLearnMoreStyled = styled(Typography)(() => (learnMoreStyles));
