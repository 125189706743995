import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    header: {
      lineHeight: '26px',
      marginBottom: 30,
      letterSpacing: -0.5,
      fontWeight: typography.fontWeightSemiBold,
    },
    subtitle: {
      fontSize: 14,
      fontWeight: typography.fontWeightSemiBold,
      color: '#445262',
    },
    form: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'InterAccountTransfer' },
);

export default useStyles;
