import { ActionButton } from 'components/Buttons';
import { Box, Button, Typography } from '@mui/material';
import { faRedo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import React, { useEffect, useState } from 'react';
import { InterAccountTransferDrawer as Drawer } from '../../../../components/InterAccountTransfer/Drawer/Drawer';
import LinearProgress from '../../../../components/LinearProgress/LinearProgress';
import useStyles from './Footer.styles';
import { TDrawerState } from '../useBookFx';

interface IProps {
  liveRateStatus: TDrawerState;
  handleBookFx: (event: any) => void;
  handleClose: () => void;
  setLiveRateStatus: React.Dispatch<React.SetStateAction<TDrawerState>>;
}

const Footer: React.FC<IProps> = (props: IProps) => {
  const {
    handleBookFx,
    handleClose,
    setLiveRateStatus,
    liveRateStatus,
  } = props;

  const styles = useStyles();
  const [timer, setTimer] = useState<number>(100);

  useEffect(() => {
    if (timer < -4) {
      setLiveRateStatus('timeout');
    }

    if (liveRateStatus === 'success') {
      const interval = setInterval(() => {
        setTimer(timer - 2);
      }, 200);

      return () => {
        clearInterval(interval);
      };
    }

    return () => { };
  }, [timer, setTimer, liveRateStatus]);

  useEffect(() => {
    if (liveRateStatus === 'initiated') setTimer(100);
  }, [liveRateStatus]);

  let handleButtonClick: (event: any) => void;
  let startIcon: boolean = false;
  let disclaimerText: string;

  const buttonText = (() => {
    if (
      liveRateStatus === 'failed'
      || liveRateStatus === 'failedBookTrade'
      || liveRateStatus === 'timeout'
    ) {
      handleButtonClick = () => setLiveRateStatus('initiated');
      startIcon = true;

      if (liveRateStatus === 'failed' || liveRateStatus === 'failedBookTrade') {
        disclaimerText = 'There was an error with your booking. Please click below to request new live rate.';
      } else {
        disclaimerText = 'Your quote has timed out. Please request a new live rate.';
      }
      return 'Request Live Rate';
    }
    handleButtonClick = handleBookFx;
    disclaimerText = 'If you click “Book FX” you will enter into a legal contract with Alpha to buy or sell the currencies you have selected.';
    startIcon = false;
    return 'Book FX';
  })();

  return (
    <Drawer.Footer>
      <LinearProgress variant="determinate" value={timer} />
      <Box className={styles.message}>
        <Typography
          variant="subtitle1"
          id="disclaimer-text"
          className={clsx(
            liveRateStatus === 'failed' || liveRateStatus === 'failedBookTrade'
              ? 'red'
              : null,
            liveRateStatus === 'failed'
              || liveRateStatus === 'failedBookTrade'
              || liveRateStatus === 'timeout'
              ? 'bold'
              : null,
          )}
        >
          {disclaimerText}
        </Typography>
      </Box>
      <Box className={styles.buttons}>
        <Button size="large" className="cancelButton" onClick={handleClose}>
          CANCEL
        </Button>
        <ActionButton
          size="large"
          startIcon={startIcon && <FontAwesomeIcon icon={faRedo} />}
          className="bookFxButton"
          onClick={handleButtonClick}
          testId="action-button"
        >
          {buttonText}
        </ActionButton>
      </Box>
    </Drawer.Footer>
  );
};

export default Footer;
