import { APMainLayout } from 'components/Layout/MainLayout';
import { StyledDivider } from 'components/StyledDivider';
import { StyledTabsWrapper } from 'components/Tabs';
import React, { useState } from 'react';
import useChangePassword from '../../hooks/useChangePassword';
import useProfile from '../../hooks/useProfile';
import routes from '../../routes.path';
import history from '../../services/history/browserHistory';
import ChangePasswordForm from './ChangPasswordForm/ChangePasswordForm';
import useStyles from './Profile.styles';
import TwoFactorAuth from './TwoFactorAuth/TwoFactorAuth';
import UserDetails from './UserDetails/UserDetails';
import useAmplifyProfile from '../../hooks/useAmplifyProfile';

const Profile: React.FC = () => {
  const classes = useStyles();
  const breadcrumbs = [{ text: 'Home', url: routes.home }, { text: 'Profile' }];
  const [selectedTabIndex] = useState<number>(0);
  const tabs: string[] = ['User Settings'];
  const { userProfile, loading } = useProfile();
  const { cognitoUser } = useAmplifyProfile();

  const handleTabClick = () => {
    history.push(`${routes.profile}?tab=user-settings`);
  };

  const { formik, changePasswordLoading } = useChangePassword(cognitoUser);
  const names = <span>{` - ${loading ? '' : userProfile?.firstName} ${loading ? '' : userProfile?.lastName}` }</span>;

  return (
    <APMainLayout
      data-id="profile-settings"
      title={(
        <span className={classes.header}>
          <b>Profile Settings</b>
          {names}
        </span>
      )}
      breadCrumbs={breadcrumbs}
      pageTabs={(
        <StyledTabsWrapper
          testId="screen-tabs"
          className="dd-privacy-allow"
          tabTitles={tabs}
          tabIndex={selectedTabIndex}
          handleChange={handleTabClick}
        />
      )}
    >
      <div className={classes.container}>
        <UserDetails userProfile={userProfile} loading={loading} />
        <StyledDivider />
        <ChangePasswordForm loading={changePasswordLoading} formik={formik} />
        <StyledDivider />
        <TwoFactorAuth userProfile={userProfile} />
      </div>
    </APMainLayout>
  );
};

export default Profile;
