import { Box, Typography } from '@mui/material';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { IconActionButton } from 'components/Buttons';
import { TUserAccount } from '../../../../models/user';
import useEntityGroupDrawer from '../useEntityGroupDrawer';
import useStyles from './EntityGroupInfoEdit.style';
import useEditEntityGroup from './useEditEntityGroup';

interface IProps {
  account: TUserAccount;
  canEditOrDeleteGroup: boolean;
  entityGroupId: string;
  numOfAccounts: number;
}

const AccountInGroupCard: React.FC<IProps> = (props: IProps) => {
  const {
    account,
    canEditOrDeleteGroup,
    entityGroupId,
    numOfAccounts,
  } = props;
  const classes = useStyles();

  const { setSelectedEntityGroup } = useEntityGroupDrawer();
  const { deleteEntitiesFromGroup } = useEditEntityGroup();

  const handleDeleteEntityFromGroup = () => {
    if (numOfAccounts < 2) setSelectedEntityGroup(undefined);
    deleteEntitiesFromGroup([account.id], entityGroupId);
  };

  return (
    <Box className={classes.cardWrapper}>
      <Box className={classes.accountName}>
        <Box>
          <Typography variant="subtitle1" className={classes.accountName}>
            {account?.name}
          </Typography>
        </Box>
      </Box>
      {
        canEditOrDeleteGroup
          && (
          <IconActionButton
            className={classes.deleteEntityButton}
            icon={faTrash}
            rounded
            variant="outlined"
            onClick={handleDeleteEntityFromGroup}
          />
          )
      }
    </Box>
  );
};
export default AccountInGroupCard;
