import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    padding: 8,
    fontSize: 24,
  },
}), { name: 'CreateUserDetailStyles' });

export default useStyles;
