import React from 'react';
import Dropdown from '../../components/Dashboard/Dropdown';
import useStyles from './CurrencyAccounts.styles';

const CurrencyAccountHeader: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <span className="dd-privacy-allow">Accounts |</span>
      {' '}
      <Dropdown />
    </div>
  );
};

export default CurrencyAccountHeader;
