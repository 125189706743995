/* eslint-disable react/jsx-indent */
import { PaymentBatchesResponse } from '@alpha/payments-types';
import { Box } from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import Status from 'components/Status/Status';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import SearchTable from '../../../../components/Table/SearchTable';
import { TextEllipsis } from '../../../../components/TextEllipsis';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../utils/formatIsoDate';
import { SearchPaymentsTabProps } from '../../usePaymentBatchSearch';
import PaymentsType from '../PaymentsType/PaymentsType';
import NoOfApprovers from '../PendingTable/DisplayTable/NoOfApprovers/NoOfApprovers';
import useStyles from '../SearchPaymentsTable/SearchPaymentsTable.styles';
import ActionDropDown from './ActionDropDown/ActionDropDown';

const ActionRequiredTable:React.FC<SearchPaymentsTabProps> = (props: SearchPaymentsTabProps) => {
  const {
    items,
    hasNext,
    hasPrevious,
    handleNextPage,
    handlePreviousPage,
    loading,
  } = props;
  const classes = useStyles();
  const columns: ITableColumn[] = [
    {
      header: <Box paddingRight="80px">ID</Box>,
      accessor: 'id',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box paddingRight="80px">Batch Status</Box>,
      accessor: 'batchStatus',
      className: clsx(classes.textAlignLeft, 'dd-privacy-allow'),
    },
    {
      header: <Box paddingRight="80px">Entity</Box>,
      accessor: 'entity',
      className: clsx(classes.textAlignLeft, 'dd-privacy-allow'),
    },
    {
      header: <Box paddingRight="80px">Type</Box>,
      accessor: 'type',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Uploaded by</Box>,
      accessor: 'uploadedBy',
    },
    {
      header: <Box className={classes.textAlignLeft}>uploaded date</Box>,
      accessor: 'uploadedDate',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Approved date</Box>,
      accessor: 'approvedDate',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.action}>Action</Box>,
      accessor: 'action',
      className: 'dd-privacy-allow',
    },
  ];

  const actionNeededText = (
    <div>
      We require further information for one or more payments in this batch.
      <br />
      <br />
      View the batch to find out more.
    </div>
  );

  const records = items?.records || [] as PaymentBatchesResponse[];

  const mappedData = records.map((item) => {
    const container: any = {};
    container.id = <b>{item.friendlyId || ''}</b>;
    container.batchStatus = (
      <Tooltip
        title={actionNeededText}
      >
        <span>
          <Status variant="overdue">
            Action Needed
          </Status>
        </span>
      </Tooltip>
    );
    container.entity = (
        <TextEllipsis text={item.accountName} className={classes.spaceRight} />
    );
    container.type = <PaymentsType type={item.uploadType} />;
    container.uploadedBy = (
      <Box className={classes.textAlignLeft} maxWidth="100px">
        <span>{item.uploadedBy || ''}</span>
      </Box>
    );
    container.uploadedDate = (
      <Box className={classes.textAlignLeft}>
        {item.uploadedDate ? formatIsoDate(item.uploadedDate) : ''}
      </Box>
    );
    container.approvedDate = (
      <Box className={classes.textAlignLeft}>
        {
          item.approvedDate ? formatIsoDate(item.approvedDate) : 'N/A'
        }
      </Box>
    );
    container.action = (
      <ActionDropDown
        id={item.batchId || item.id}
        accountId={item.accountId}
        batchId={item.batchId || item.id}
      />
    );
    return container;
  });

  return (
    <SearchTable
      table={{
        columns,
        data: mappedData || [],
      }}
      loading={loading}
      pagination={{
        handleNext: () => handleNextPage(),
        handlePrevious: () => handlePreviousPage(),
        hasNext: hasNext || false,
        hasPrevious: hasPrevious || false,
      }}
      emptyTable={{
        title: 'No Payments Requiring Further Action',
        subtitle: 'You currently do not have any payments that require further action',
      }}
    />
  );
};

export default ActionRequiredTable;
