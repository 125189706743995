import { Box } from '@mui/material';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TAuthyStatus } from '../../../store/authy/reducer';
import { useStyles } from './AuthyResponse.styles';

interface IAuthyResponseProps {
  status: TAuthyStatus
}
const AuthyResponse: React.FC<IAuthyResponseProps> = (
  { status }: IAuthyResponseProps,
) => {
  const styles = useStyles();
  return (
    <Box className={styles.wrapper}>
      <FontAwesomeIcon
        icon={status === 'SUCCESS' ? faCheckCircle : faTimesCircle}
        className={status === 'SUCCESS' ? styles.green : styles.red}
        data-testid="img"
      />
    </Box>
  );
};
export default AuthyResponse;
