import { originalUseSnackbar } from 'components/Snackbar';
import { VariantType } from 'notistack';

const useAlphaSnackbar = () => {
  const snackbar = originalUseSnackbar();

  const trigger = (
    message: string,
    type: VariantType = 'error',
    duration: number = 5000,
  ): void => {
    if (snackbar) {
      snackbar.enqueueSnackbar(message, {
        variant: type,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
        style: { whiteSpace: 'pre-wrap' as any },
        className: 'dd-privacy-allow',
        autoHideDuration: duration,
      });
    }
  };

  return { trigger };
};

export default useAlphaSnackbar;
