import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    errorButton: {
      marginRight: '16px',
    },
    icon: {
      color: 'rgb(255, 199, 0)',
      marginRight: 10,
      alignSelf: 'center',
    },
  }),
  { name: 'Footer' },
);

export default useStyles;
