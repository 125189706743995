import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiFormLabel-root': {
        color: '#343434',
        fontSize: '14px',
        marginBottom: '8px',
      },
      marginTop: 'auto',
    },
    hr: {
      border: 'none',
      backgroundColor: '#70707026',
      height: '1px',
      margin: '24px 0',
    },
  }),
  { name: 'CreditingReferenceStyles' },
);

export default useStyles;
