import {
  Typography, InputLabel, MenuItem, Select,
} from '@mui/material';
import clsx from 'clsx';
import { groupBy } from 'lodash';
import React, { memo } from 'react';
import {
  TCurrencyAccount,
  TCurrencyAccounts,
} from '../../../models/currencyAccounts';
import { useStyles } from './Dropdown.styles';
import { Item } from './Item/Item';
import RenderValue from './RenderValue/RenderValue';

interface IProps {
  currencyAccounts: TCurrencyAccounts;
  helperText?: string | string[] | false;
  selectValue?: string;
  selectName?: string;
  placeholderText?: string;
  disabled?: boolean;
  hideBalance?: boolean;
  testId?: string;
  handleChange: (event: any) => void;
  handleBlur?: (event: any) => void;
  loading?: boolean;
}
const Dropdown: React.FC<IProps> = (props: IProps) => {
  const {
    currencyAccounts,
    selectValue,
    selectName,
    helperText,
    placeholderText,
    disabled,
    hideBalance,
    testId,
    handleChange,
    handleBlur,
    loading,
  } = props;

  const styles = useStyles();

  const currencyAccountsGroupedByCurrency = groupBy(
    currencyAccounts,
    (ca: TCurrencyAccount) => ca.currencyCode,
  );

  const dropdownItems = Object.keys(currencyAccountsGroupedByCurrency).map(
    (currencyCode: string) => {
      const elements: JSX.Element[] = [
        <Item.Sticky currencyCode={currencyCode} />,
      ];
      const currencyAccountRows = (currencyAccountsGroupedByCurrency[
        currencyCode
      ] as TCurrencyAccounts).map((currencyAccount: TCurrencyAccount) => (
        <MenuItem
          key={currencyAccount.id}
          value={currencyAccount.id}
          style={{ padding: 0, backgroundColor: 'transparent' }}
        >
          <Item.Data currencyAccount={currencyAccount} />
        </MenuItem>
      ));

      return elements.concat(currencyAccountRows);
    },
  );

  const noCurrencyAccounts = (
    <MenuItem
      key="iat-no-accounts"
      value="noAccounts"
      disabled
    >
      <Typography variant="subtitle1" className={styles.placeholder}>
        {loading ? 'loading accounts...' : '  No Currency Accounts found for this entity'}
      </Typography>
    </MenuItem>
  );

  const renderOptions = currencyAccounts.length > 0
    ? dropdownItems
    : [noCurrencyAccounts];

  const renderValue: Function | undefined = (
    values: string,
    _currencyAccounts: TCurrencyAccounts,
  ) => {
    const selectedCurrencyAccount = _currencyAccounts.find(
      (ca) => ca.id === values,
    );
    if (!selectedCurrencyAccount) return undefined;
    return () => (
      <RenderValue selectedCurrencyAccount={selectedCurrencyAccount} hideBalance={hideBalance} />
    );
  };

  const showValueOrPlaceHolder = selectValue || 'placeholder';
  const renderedPlaceHolderText = placeholderText || 'Select an account you would like to credit';

  return (
    <div className={styles.dropdownContainer}>
      <Select
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        value={showValueOrPlaceHolder}
        name={selectName}
        id={testId || 'dropdown'}
        renderValue={renderValue(showValueOrPlaceHolder, currencyAccounts)}
        fullWidth
        className={styles.dropdown}
        MenuProps={{
          className: styles.dropdownList,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {[
          <MenuItem key="iat-placeholder" value="placeholder">
            <Typography variant="subtitle1" className={styles.placeholder}>
              {renderedPlaceHolderText}
            </Typography>
          </MenuItem>,
          ...renderOptions,
        ]}
      </Select>
      <InputLabel className={clsx(styles.error, styles.red)}>
        {helperText}
      </InputLabel>
    </div>
  );
};

export default memo(Dropdown);
