import React, { FC } from 'react';
import Status from 'components/Status/Status';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import {
  StyledSecondaryTitle, StyledStat, StyledStatHeader, StyledValue,
} from './Stat.styles';
import { IStatProps } from './Stat.interfaces';

export const Stat: FC<IStatProps> = (props) => {
  const {
    title,
    value,
    style,
    infoTip,
    variant,
    statusVariant,
    testId,
    alignRight,
  } = props;

  return (
    <StyledStat data-testid={testId} style={style}>
      {variant === 'secondary' ? (
        <StyledSecondaryTitle>
          {title}
          {' '}
        </StyledSecondaryTitle>
      ) : (
        <StyledStatHeader alignRight={alignRight}>
          <span>{title}</span>
          {infoTip && (
            <Tooltip title={infoTip}>
              <span>
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            </Tooltip>
          )}
        </StyledStatHeader>
      )}
      <StyledValue alignRight={alignRight} secondary={variant === 'secondary'}>
        {variant === 'status' ? <Status variant={statusVariant}>{value}</Status> : value}
      </StyledValue>
    </StyledStat>
  );
};
