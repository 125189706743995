import styled from '@mui/styled-engine';

const StyledFunding = styled('div')({
  '& > div:first-child': {
    height: '212px',
  },
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '32px',
  },
});

export default StyledFunding;
