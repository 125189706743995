import React, { FC } from 'react';
import { FooterStyled } from './Footer.styles';

const mainUrl = { text: 'Alpha FX Group plc', url: 'https://www.alphagroup.com' };
const footerLinks = [
  { text: 'Privacy and Cookie Policy', url: 'https://alphagroup.com/privacy-policy/' },
  { text: 'Terms of Use', url: 'https://alphagroup.com/legal' },
];
const year = new Date().getFullYear();
const testId = 'main-footer';

export const Footer: FC = () => (
  <FooterStyled data-testid={testId}>
    <strong>
      Copyright ©
      {' '}
      {year}
      {' '}
      <a target="_blank" href={mainUrl.url} rel="noreferrer">
        {mainUrl.text}
      </a>
      .
    </strong>
    <span>
                    &nbsp;All rights reserved.
      {footerLinks.map((link) => (
        <span key={link.url}>
                            &nbsp;|&nbsp;
          <a target="_blank" href={link.url} rel="noreferrer">
            {link.text}
          </a>
        </span>
      ))}
    </span>
  </FooterStyled>
);

export default Footer;
