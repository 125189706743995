import { Button } from '@mui/material';
import { Stat } from 'components/Stat';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import clsx from 'clsx';
import { CurrencyHeader } from '../../../../../../components/International';
import { formatCurrency } from '../../../../../../utils/currency.helpers';
import ClearDebitingModal from './ClearDebitingModal/ClearDebitingModal';
import useStyles from './FundingAccountSummary.styles';
import { IFundingAccountContainerSummaryProps } from './FundingAccountSummaryContainer';

interface IProps extends IFundingAccountContainerSummaryProps {
  handleChangeDebitingAccount: () => void;
  open: boolean;
  handleModalOpen: (open: boolean) => void;
  handleSubmitButton: () => void;
}

const FundingAccountSummary: React.FC<IProps> = ({ ...props }: IProps) => {
  const styles = useStyles();
  return (
    <>
      <div className={clsx(styles.root, 'dd-privacy-allow')}>
        <div className="currencyHeader">
          <CurrencyHeader
            currencyCode={props.selectedDebitingAccount.currencyCode || ''}
            currency={props.selectedDebitingAccount.currency || ''}
            account={props.selectedDebitingAccount.friendlyName}
          />
          <Button onClick={props.handleChangeDebitingAccount}>
            <FontAwesomeIcon icon={faEdit} fontSize={16} />
          </Button>
        </div>
        <div className="stats">
          <Stat
            title="Cleared Balance"
            value={formatCurrency(
              props.selectedDebitingAccount.currencyCode!,
              props.selectedDebitingAccount.clearedBalance,
            )}
          />
          <div className="boldStat">
            <Stat
              title="Available Balance"
              value={formatCurrency(
                props.selectedDebitingAccount.currencyCode!,
                props.selectedDebitingAccount.availableBalance,
              )}
            />
          </div>
        </div>
      </div>
      <ClearDebitingModal
        open={props.open}
        handleModalOpen={props.handleModalOpen}
        handleSubmitButton={props.handleSubmitButton}
      />
    </>
  );
};

export default FundingAccountSummary;
