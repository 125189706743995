import styled from '@mui/styled-engine';
import Divider from '@mui/material/Divider';

export type IDividerStyles = {
    vertical?: boolean;
};

const StyledDivider = styled(Divider, {
  shouldForwardProp: (prop) => (prop !== 'vertical'),
})(({ vertical }: IDividerStyles) => {
  const verticalStyles = {
    minHeight: 60,
    height: '100%',
    width: 0,
    borderLeft: '1px solid rgba(112, 112, 112, 0.12)',
    margin: '0 28px',
  };

  return {
    borderBottom: vertical ? 'none' : '1px solid rgba(112, 112, 112, 0.12)',
    ...(vertical && verticalStyles),
  };
});

export default StyledDivider;
