import React from 'react';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';

import { ModalType } from '../Body';

interface IProps {
  modalType: ModalType | false;
  modalOpen: boolean;
  loading: boolean;
  handleCloseModal: () => void;
  handleOnRejectionClick: () => void;
  handleOnDeleteClick: () => void;
}

const DeleteOrRejectModal: React.FC<IProps> = (props: IProps) => {
  const {
    loading,
    modalOpen,
    modalType,
    handleCloseModal,
    handleOnRejectionClick,
    handleOnDeleteClick,
  } = props;
  const modalTypeText = modalType === 'reject' ? 'reject' : 'delete';

  return (
    <DecisionModal
      open={modalOpen}
      title={`Are you sure you want to ${modalTypeText} this beneficiary?`}
      subtitle={`
          ${
            modalTypeText === 'reject' ? 'Rejecting' : 'Deleting'
          } this beneficiary will permanently remove them from your account
        `}
      actionButtonText={`YES ${
        modalType === 'reject' ? 'REJECT' : 'DELETE'
      } BENEFICIARY`}
      closeButtonText="NO, KEEP BENEFICIARY"
      handleActionButton={
        modalType === 'reject' ? handleOnRejectionClick : handleOnDeleteClick
      }
      handleClose={handleCloseModal}
      loading={loading}
      icon={faExclamationTriangle}
    />
  );
};

export default DeleteOrRejectModal;
