import React, { memo } from 'react';
import { Stat } from 'components/Stat';
import { useStyles } from './BatchDetails.styles';
import { testIds } from '../Funding';

interface IProps {
  noOfPayments?: number | string;
}

const BatchDetails: React.FC<IProps> = (props: IProps) => {
  const { noOfPayments } = props;
  const classes = useStyles();
  return (
    <div className={classes.batchDetails}>
      <div>
        <h4 className="title">FX Payments</h4>
        <p className="subtitle">Please review the FX transactions below before continuing to the next screen</p>
      </div>
      <Stat
        testId={testIds.numberOfPaymentsStat}
        title="No of payments"
        value={(
          <span className={classes.semiBold}>
            {noOfPayments}
          </span>
                )}
        alignRight
      />
    </div>
  );
};

BatchDetails.defaultProps = {
  noOfPayments: '-',
};

export default memo(BatchDetails);
