import { UserStatus } from '@portal/user-types';
import getRoles from './RolesMock';

let status = Object.values(UserStatus);

export enum approvalLevel {
  'A', 'B', 'C', 'D', 'E', 'F'
}

export const getUsers = (total: number, statuses: UserStatus[]) => {
  const users = [];
  status = statuses || [];
  for (let i = 0; i < total; i += 1) {
    const statuskey = i % status.length;
    const approvalLevelkey = i % Object.keys(approvalLevel).length;
    users.push({
      id: `12345678-1234-1234-1234-UUUUUUUU${(i).toString().padStart(4, '0')}`,
      firstName: `Name ${i + 1}`,
      lastName: `Surname ${i + 1}`,
      status: status[statuskey],
      created: new Date('05 October 2011 14:48 UTC'),
      updated: new Date('10 October 2011 14:48 UTC'),
      roles: getRoles(i),
      approvalLevel: approvalLevel[approvalLevelkey],
    });
  }
  return users;
};

export const getUser = () => ({
  id: '133218df-b6bb-4ca2-896b-b30248004b90',
  username: 'Name Surname',
  firstName: 'Name',
  lastName: 'Surname',
  status: status[0],
  created: new Date('05 October 2011 14:48 UTC'),
  updated: new Date('10 October 2011 14:48 UTC'),
  mobile: '+35679797979',
  phone: '+35621212121',
  email: 'email@email.com',
});
