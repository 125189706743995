import { ActionButton } from 'components/Buttons';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { APInput } from 'components/APInput';
import FormTitle from '../../../components/Molecules/Auth/FormTitle';
import useUpdatePassword from '../../../hooks/useUpdatePassword';
import routes from '../../../routes.path';
import hasCorrectChars from '../../../utils/hasCorrectChars';
import hasLowerCase from '../../../utils/hasLowerCase';
import hasNumber from '../../../utils/hasNumber';
import hasSpecialChars from '../../../utils/hasSpecialChars';
import hasUpperCase from '../../../utils/hasUpperCase';
import useFormStyles from '../form.styles';
import useStyles from './index.styles';
import { useOnboardingContext, IOnboarding } from '../Onboarding';
import { IReturning } from '../Returning';

interface IProps extends IOnboarding, IReturning { }

const NewPassword: React.FC<IProps> = ({ onboarding, userObject }: IProps) => {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');

  const { formik, loading } = useUpdatePassword(userObject);
  const { handleSetLoaderPercentage } = useOnboardingContext();

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      handleSetLoaderPercentage(50);
    }
  }, []);

  const handleButtonDisabled = (): boolean => {
    if (!(
      hasLowerCase(formik.values.password)
      && hasUpperCase(formik.values.password)
      && hasNumber(formik.values.password)
      && hasCorrectChars(formik.values.password, 8)
      && hasSpecialChars(formik.values.password))
    ) return true;

    return false;
  };

  return (
    <div>
      <FormTitle
        number={2}
        title="Set your new password"
        description="Thank you for verifying your email. You can now set your new password in line with the criteria below."
      />
      <form onSubmit={formik.handleSubmit}>
        <Typography className={formClasses.label}>New Password</Typography>
        <APInput
          autoComplete="off"
          testid="password"
          id="password"
          name="password"
          placeholder="Enter a new password"
          variant="filled"
          type={passwordType}
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <button
          aria-label="password-type"
          type="button"
          className={formClasses.eyeIconStyles}
          onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
        >
          <FontAwesomeIcon
            icon={passwordType === 'password' ? faEye : faEyeSlash}
          />
        </button>
        <ul className={classes.list}>
          <li
            className={clsx(hasLowerCase(formik.values.password) && classes.contains)}
          >
            One lowercase character
          </li>
          <li
            className={clsx(hasUpperCase(formik.values.password) && classes.contains)}
          >
            One uppercase character
          </li>
          <li
            className={clsx(hasNumber(formik.values.password) && classes.contains)}
          >
            One number
          </li>
          <li
            className={clsx(hasCorrectChars(formik.values.password, 8) && classes.contains)}
          >
            8 characters minimum
          </li>
          <li
            className={clsx(hasSpecialChars(formik.values.password) && classes.contains)}
          >
            One special character
          </li>
        </ul>
        <ActionButton
          type="submit"
          testId="submit-new-password"
          fullWidth
          loading={loading}
          disabled={loading || handleButtonDisabled()}
          size="large"
        >
          Submit New Password
        </ActionButton>
        <div className={formClasses.formBacklink}>
          <span className={formClasses.white}>Need more time? </span>
          <NavLink to={onboarding ? routes.auth.onboarding.login : routes.auth.login}>
            Go back to beginning
          </NavLink>
        </div>
      </form>

    </div>
  );
};

export default NewPassword;
