import { CurrencyAccountSearchDto, LedgerDto } from '@alpha/currency-accounts-dtos';
import moment, { Moment as MomentType } from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAlphaSnackbar from '../../hooks/useAlphaSnackbar';
import { TTransactionData } from '../../models/transactions';
import FXTransferService from '../../services/FXTransfer/fxTransfer.service';
import InterAccountTransferService from '../../services/InterAccountTransfer/interAccountTransfer.service';
import PaymentsService from '../../services/Payments/payments.service';
import TransactionsService from '../../services/Transactions/transactions.service';
import { TStore } from '../../store';
import { TTransactionContextType } from './TransactionsContext';

const useTransactions = () => {
  const { currencyAccountId, accountId }: {
    currencyAccountId: string, accountId: string } = useParams();
  const [currencyAccountIdParam, setCurrencyAccountIdParam] = useState<string | undefined>(
    currencyAccountId,
  );
  const [accountIdInParam, setAccountIdInParam] = useState<string | undefined>(
    accountId,
  );
  const [disabled, setDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState<TTransactionData>();
  const [currencyAccountIds, setCurrencyAccountIds] = useState<string[]>([]);
  const [options, setOptions] = useState<CurrencyAccountSearchDto[]>();
  const [total, setTotal] = useState<number>();
  const [entitiesSelected, setEntitiesSelected] = useState((new Set<string>()));
  const [selectedDates, setSelectedDates] = useState<{
    startDate: MomentType;
    endDate: MomentType;
  }>({
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
  });
  const allowedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const selectedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const userAccountsLoading = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.userAccountsLoading,
  );

  const snackbar = useAlphaSnackbar();

  const handleSetTransaction = async (clickedTableRowTransaction: LedgerDto): Promise<void> => {
    try {
      setLoading(true);
      if (clickedTableRowTransaction.iatId) {
        setTransaction(
          await InterAccountTransferService.getTransactionData(
            clickedTableRowTransaction.iatId,
            clickedTableRowTransaction.accountId,
          ),
        );
      } else if (clickedTableRowTransaction.clientFxTradeId) {
        setTransaction(
          await FXTransferService.getFxData(
            clickedTableRowTransaction.clientFxTradeId,
            clickedTableRowTransaction.friendlyId,
            clickedTableRowTransaction.accountId,
          ),
        );
      } else if (clickedTableRowTransaction.paymentId) {
        setTransaction(
          await PaymentsService.getPaymentData(
            clickedTableRowTransaction.paymentId,
            clickedTableRowTransaction.accountId,
          ),
        );
      }
    } catch (e) {
      snackbar.trigger('There was an error retrieving your transaction data');
      setTransaction(undefined);
    } finally {
      setLoading(false);
    }
  };

  const transactionContext: TTransactionContextType = {
    currencyAccountTotal: total,
    setCurrencyAccountTotal: setTotal,
    currencyAccountsOptions: options,
    setCurrencyAccountsOptions: setOptions,
    allowedAccounts,
    selectedAccounts,
    currencyAccountIds,
    setCurrencyAccountIds,
    entitiesSelected,
    setEntitiesSelected,
  };

  useEffect(() => {
    (async () => {
      if (allowedAccounts?.length > 0 || selectedAccounts?.length > 0) {
        let response;
        if (accountIdInParam) {
          response = await TransactionsService.getTransactionAccountsMultiEntity(
            0, accountIdInParam,
          );
          setAccountIdInParam(undefined);
        } else {
          response = await TransactionsService.getTransactionAccountsMultiEntity(0);
        }
        transactionContext?.setCurrencyAccountTotal(
          response.totals.totalAccounts,
        );
        transactionContext?.setCurrencyAccountsOptions(
          response.currencyAccounts as CurrencyAccountSearchDto[],
        );
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    })();
  }, [allowedAccounts, selectedAccounts]);

  return {
    loading,
    transaction,
    selectedDates,
    setSelectedDates,
    setTransaction,
    handleSetTransaction,
    transactionContext,
    userAccountsLoading,
    currencyAccountIdParam,
    setCurrencyAccountIdParam,
    setLoading,
    disabled,
  };
};
export default useTransactions;
