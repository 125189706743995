
import _default, { makeStyles } from 'theme';

const {
  palette, typography,
} = _default;

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      gap: 10,
    },
    title: {
      color: palette.primary.main,
      marginBottom: 20,
      fontWeight: typography.fontWeightSemiBold,
      fontSize: '1.3rem',
    },
    detailRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 35,
    },
    label: {
      minWidth: 80,
      fontSize: '1.1rem',
      color: palette.primary.main,
      fontWeight: typography.fontWeightSemiBold,
    },
    detailText: {
      fontSize: '1.1rem',
    },
    status: {
      textAlign: 'center',
    },
  }),
  { name: 'UserDetails' },
);

export default useStyles;
