import { Stat } from 'components/Stat';
import { StyledDivider } from 'components/StyledDivider';
import React, { useEffect, useMemo, useState } from 'react';
import { CurrencyHeader } from '../../../components/International';
import { TTransactionsSummary } from '../../../models/currencyAccounts';
import routes from '../../../routes.path';
import CurrencyAccountsService from '../../../services/CurrencyAccounts/currencyAccounts.service';
import browserHistory from '../../../services/history/browserHistory';
import { formatCurrency } from '../../../utils/currency.helpers';
import formatIsoDate from '../../../utils/formatIsoDate';
import useStyles from './TransactionsSummary.styles';

export interface IProps {
  componentTestId?: string;
  currencyAccountId?: string,
  startDate: string,
  endDate: string,
}

export const TransactionsSummary: React.FC<IProps> = (props: IProps) => {
  const [transactionsSummary, setTransactionsSummary] = useState<TTransactionsSummary>();
  const classes = useStyles();
  const {
    componentTestId,
    currencyAccountId,
    startDate,
    endDate,
  } = props;

  useEffect(() => {
    getTransactionSummary();
  }, [
    currencyAccountId, startDate, endDate,
  ]);

  const getTransactionSummary = async () => {
    try {
      if (currencyAccountId && startDate && endDate) {
        const summary = await CurrencyAccountsService.getTransactionsSummary(
          currencyAccountId,
          startDate,
          endDate,
        );
        setTransactionsSummary(summary);
      }
    } catch (error) {
      browserHistory.push(routes.noTransactions);
    }
  };

  const dateString = useMemo(() => (
    `${formatIsoDate(startDate)} - ${formatIsoDate(endDate)}`
  ), [startDate, endDate]);

  const balanceLabel = transactionsSummary?.isBalanceClosed
    ? 'Closing Balance'
    : 'Current Balance';

  if (!transactionsSummary) {
    return <div data-testid={componentTestId} />;
  }

  return (
    <div data-testid={componentTestId}>
      <div className={classes.transactionCurrencySummary}>
        <div className={classes.transactionCurrencySummaryLeft}>
          <CurrencyHeader
            currencyCode={transactionsSummary.currencyCode}
            currency={transactionsSummary.currency}
            account={transactionsSummary.friendlyName}
          />
          <Stat title="Date" value={dateString} />
        </div>
        <div className={classes.transactionCurrencySummaryRight}>
          <Stat
            title="Opening balance"
            value={formatCurrency(
              transactionsSummary.currencyCode,
              transactionsSummary.openingBalance,
            )}
            alignRight
          />
          <Stat
            title="Money in"
            value={formatCurrency(transactionsSummary.currencyCode, transactionsSummary.moneyIn)}
            alignRight
          />
          <Stat
            title="Money out"
            value={formatCurrency(transactionsSummary.currencyCode, transactionsSummary.moneyOut)}
            alignRight
          />
          <Stat
            title={balanceLabel}
            value={formatCurrency(
              transactionsSummary.currencyCode,
              transactionsSummary.balance,
            )}
            alignRight
          />
        </div>
      </div>
      <StyledDivider />
    </div>
  );
};

export default TransactionsSummary;
