import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import { EntityGroup } from '../../../models/entityGroups';
import { TUserAccount } from '../../../models/user';
import EntityGroupsService from '../../../services/EntityGroups/entityGroups.service';
import { TStore } from '../../../store';
import { actions as entityGroupActions } from '../../../store/entityGroups/reducer';

const useEntityGroupDrawer = () => {
  const [userAccounts, setUserAccounts] = useState<TUserAccount[]>([]);
  const sb = useAlphaSnackbar();
  const dispatch = useDispatch();
  const selectedEntityGroup = useSelector<TStore, EntityGroup | undefined>(
    (state: TStore) => state?.entityGroups?.selectedEntityGroup,
  );
  const entityAccounts = useSelector<TStore, TUserAccount[]>(
    (state: TStore) => state?.entityGroups?.userAccountsWithEPerms,
  );
  const entityGroups = useSelector<TStore, EntityGroup[] | undefined>(
    (state: TStore) => state?.entityGroups.entityGroupsForUser,
  );
  const loading = useSelector<TStore, boolean>(
    (state: TStore) => state?.entityGroups.entityGroupsLoading,
  );
  const showAccountDropdown = useSelector<TStore, boolean | undefined>(
    (state: TStore) => state?.entityGroups?.showAccountDropdown,
  );
  const viewGroupsDrawerOpen: boolean = useSelector<TStore, boolean>(
    (state: TStore) => state.entityGroups.viewGroupsDrawerOpen,
  );
  const createGroupDrawerOpen: boolean = useSelector<TStore, boolean>(
    (state: TStore) => state.entityGroups.createGroupDrawerOpen,
  );

  const requestUpdateEntityGroupDetails = () => {
    dispatch({ type: 'UPDATE_ENTITY_GROUPS_REQUEST' });
  };

  const requestGetUserAccounts = () => {
    dispatch({ type: 'GET_USER_ACCOUNTS_E_REQUEST' });
  };

  const updateSelectedGroupDetails = async (
    selectedGroupId: string,
  ): Promise<void> => {
    requestUpdateEntityGroupDetails();
    if (entityGroups) {
      const selectedAccountUpdated = entityGroups.find(
        (entityGroup) => entityGroup.id === selectedGroupId,
      );

      if (selectedAccountUpdated) {
        setSelectedEntityGroup(selectedEntityGroup);
      } else {
        throw new Error('could not update accounts in entity group list');
      }
    }
  };

  const setSelectedEntityGroup = (entityGroup?: EntityGroup) => {
    if (entityGroup) {
      dispatch(
        entityGroupActions.setSelectedEntityGroup({
          id: entityGroup.id,
          name: entityGroup.name,
          colour: entityGroup.colour,
          accounts: entityGroup.accounts,
        }),
      );
    } else {
      dispatch(entityGroupActions.setSelectedEntityGroup(undefined));
    }
  };

  const toggleAccountsDropdown = (open: boolean) => {
    dispatch(entityGroupActions.toggleOpenAccountDropdown({ open }));
  };
  const toggleViewGroupsDrawer = (open: boolean) => {
    dispatch(entityGroupActions.toggleOpenViewGroupsDrawer({ open }));
  };
  const toggleCreateGroupDrawer = (open: boolean) => {
    dispatch(entityGroupActions.toggleOpenCreateEntityGroupDrawer({ open }));
  };

  const getAccountsForDropdown = async (): Promise<any> => {
    let userAccountsFiltered: TUserAccount[] = [];
    if (entityAccounts.length > 0) {
      const accounts = selectedEntityGroup?.accounts || [];
      userAccountsFiltered = entityAccounts.filter(
        (userAccount) => !accounts.some((entityAccount) => userAccount.id === entityAccount.id),
      );
    }
    setUserAccounts(userAccountsFiltered);
  };

  const addAccountToGroup = async (
    entityGroupId: string,
    accountId: string,
  ) => {
    dispatch(entityGroupActions.updateLoadingState({ loading: true }));
    try {
      const response = await EntityGroupsService.addAccountToEntityGroup(
        entityGroupId,
        accountId,
      );
      if (response?.status === 200) {
        await updateSelectedGroupDetails(entityGroupId);
        sb.trigger('Account Added', 'success');
      } else {
        throw new Error('Failed to add account to group');
      }
    } catch (error) {
      sb.trigger('Could not add account', 'error');
    } finally {
      dispatch(entityGroupActions.updateLoadingState({ loading: false }));
    }
  };

  const deleteGroup = async (entityGroupId: string) => {
    dispatch(entityGroupActions.updateLoadingState({ loading: true }));
    try {
      await EntityGroupsService.deleteEntityGroup(entityGroupId);
      sb.trigger('Group Deleted', 'success');
      requestUpdateEntityGroupDetails();
    } catch (error) {
      sb.trigger('Could not delete account', 'error');
    } finally {
      dispatch(entityGroupActions.updateLoadingState({ loading: false }));
    }
  };

  const closeAllGroupManagerDrawers = () => {
    toggleCreateGroupDrawer(false);
    toggleViewGroupsDrawer(false);
    setSelectedEntityGroup(undefined);
    toggleAccountsDropdown(false);
  };

  return {
    closeAllGroupManagerDrawers,
    updateSelectedGroupDetails,
    selectedEntityGroup,
    requestGetUserAccounts,
    requestUpdateEntityGroupDetails,
    entityGroups,
    loading,
    entityAccounts,
    addAccountToGroup,
    getAccountsForDropdown,
    userAccounts,
    toggleAccountsDropdown,
    toggleViewGroupsDrawer,
    viewGroupsDrawerOpen,
    setSelectedEntityGroup,
    showAccountDropdown,
    createGroupDrawerOpen,
    toggleCreateGroupDrawer,
    deleteGroup,
  };
};

export default useEntityGroupDrawer;
