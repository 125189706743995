import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { DebouncedFunc } from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import theme from 'theme';
import {
  StyledSearchBarWrapper, StyledTextField, StyledSearchIcon, StyledIconButton, StyledNoSearchResult,
} from './index.styles';

interface ISearchBarProps {
  debouncedSearch: string,
  setDebouncedSearch: DebouncedFunc<(e: ChangeEvent<HTMLInputElement>) => void>
  clearSearch: () => void,
  count: number,
}
const SearchBar = ({
  debouncedSearch, setDebouncedSearch, clearSearch, count,
}: ISearchBarProps) => {
  const [search, setSearch] = useState(debouncedSearch);

  const handleClearSearch = () => {
    clearSearch();
    setSearch('');
  };

  const searchResult = [`Showing (${count}) result${count > 1 ? 's' : ''} for `, <span key="search-span">{search}</span>];
  const noSearchResult = ["We're sorry your search - ", <span key="no-search-result">{search}</span>, ' - did not match any user.'];

  return (
    <React.Fragment key="search">
      <StyledSearchBarWrapper theme={theme} key="search-bar">
        <span>
          {count > 0 && search.trim().length > 0 && (<>{searchResult}</>)}
        </span>
        <StyledTextField
          type="text"
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
            setDebouncedSearch(e);
          }}
          placeholder="Search Name / Username"
          InputProps={{
            startAdornment: (
              <StyledSearchIcon
                icon={faSearch}
              />
            ),
            endAdornment: (
              <StyledIconButton
                aria-label="Clear Search"
                onClick={handleClearSearch}
                onMouseDown={handleClearSearch}
                icon={faTimes}
              />
            ),
          }}
        />
      </StyledSearchBarWrapper>
      {count === 0 && search.length > 0 && (
        <StyledNoSearchResult
          key="no-search"
          theme={theme}
          initial={{ opacity: 0, translateY: 50 }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'spring' }}
        >
          {noSearchResult}
        </StyledNoSearchResult>
      )}
    </React.Fragment>
  );
};

export default SearchBar;
