import {
  BatchApprover,
  PaymentBatchDraftDto,
} from '@alpha/payments-dtos';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { ITableColumn } from '../../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import PaymentsType from '../../PaymentsType/PaymentsType';
import TableContainer from '../../TableContainer/TableContainer';
import useStyles from '../PendingTable.styles';
import ActionDropDown from './ActionDropDown/ActionDropDown';
import ApprovalStatus from './ApprovalStatus/ApprovalStatus';
import NoOfApprovers from './NoOfApprovers/NoOfApprovers';

interface IProps {
  table: ITable;
  columns: ITableColumn[];
}

export interface ITable {
  data: PaymentBatchDraftDto[] | undefined;
  hasMore: boolean | undefined;
  hasPrevious: boolean;
  handleNextPage: () => Promise<void>;
  handlePreviousPage: () => Promise<void>;
  loading: boolean;
  rerenderTable: () => Promise<void>;
}

const DisplayTable: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const { table, columns } = props;
  const [textValue, setTextValue] = useState('');
  const [currentOptions, setCurrentOptions] = useState<BatchApprover[]>([]);

  const mappedData = table.data
    && table.data.map((item: PaymentBatchDraftDto) => {
      const container: any = {};
      container.id = <b data-dd-privacy = "allow" >{item.friendlyBatchId || ''}</b>;
      container.type = <PaymentsType type={item.type} />;
      container.noOfPayments = (
        <Box data-dd-privacy = "allow" className={classes.payments}>{item.noOfValidatedPayments || ''}</Box>
      );
      container.uploadedBy = (
        <Box className={classes.textAlignLeft} maxWidth="100px">
          {item.uploadedBy || ''}
        </Box>
      );
      container.uploadedDate = (
        <Box data-dd-privacy = "allow" className={classes.textAlignLeft}>
          {item.uploadedDate ? formatIsoDate(item.uploadedDate) : ''}
        </Box>
      );
      container.noOfApprovers = (
        <NoOfApprovers
          uploadedBy={item.uploadedBy}
          uploadedDate={item.uploadedDate}
          noOfApprovals={item.noOfApprovals}
          approvedBy={item.approverInfo}
          approvalsRequired={item.approvalsRequired}
        />

      );
      container.approvalStatus = (
        <ApprovalStatus
          requiresFx={item.requiresFx}
          batchStatus={item.batchStatus}
        />
      );
      container.action = (
        <ActionDropDown
          id={item.batchId}
          status={item.batchStatus}
          userCanApprove={item.currentUserCanApprove}
          requiresFx={item.requiresFx}
          setCurrentOptions={setCurrentOptions}
          currentOptions={currentOptions}
          setTextValue={setTextValue}
          batchDetails={item}
          table={table}
        />
      );
      return container;
    });

  return (
    <TableContainer
      message="Pending"
      table={table}
      mappedData={mappedData || []}
      columns={columns}
    />
  );
};

export default DisplayTable;
