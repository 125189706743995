import React, { FC } from 'react';
import moment from 'moment';
import { SelectOptionNoteStyled } from './styles';

type IProps = {
  opt: any;
  date: string;
}

const FormattedRepeatLabel: FC<IProps> = ({ opt, date }) => {
  if (!opt) return null;
  const {
    addDay,
    addDate,
    addDateAndMonth,
    label,
  } = opt;

  if (addDay) {
    return (
      <>
        {label}
        <SelectOptionNoteStyled>
          &nbsp;- On&nbsp;
          {moment(date).format('dddd')}
          s
        </SelectOptionNoteStyled>
      </>
    );
  }

  if (addDate) {
    return (
      <>
        {label}
        <SelectOptionNoteStyled>
          &nbsp;- On the&nbsp;
          {moment(date).format('Do')}
        </SelectOptionNoteStyled>
      </>
    );
  }

  if (addDateAndMonth) {
    return (
      <>
        {label}
        <SelectOptionNoteStyled>
          &nbsp;- On&nbsp;
          {moment(date).format('MMM Do')}
        </SelectOptionNoteStyled>
      </>
    );
  }
  return label;
};

export default FormattedRepeatLabel;
