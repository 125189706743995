import APDatePicker from 'components/APDatePicker/APDatePicker';
import moment, { Moment } from 'moment';
import clsx from 'clsx';
import React, { useState } from 'react';
import { TextField } from '@mui/material';
import ExportMenu from '../ExportMenu/ExportMenu';
import useStyles from './TransactionsCtas.styles';

export const testIds = {
  applyDatesBtn: 'apply-dates-btn',
};

export interface IProps {
  testId?: string;
  componentTestId?: string;
  setSelectedDates: any,
  selectedDates: { startDate: Moment, endDate: Moment };
  startDateIso: string;
  endDateIso: string;
  currencyAccounts: string[]
}

const TransactionsCtas: React.FC<IProps> = (props: IProps) => {
  const {
    componentTestId,
    setSelectedDates,
    selectedDates,
    startDateIso,
    endDateIso,
    currencyAccounts,
  } = props;
  const [newStartDate, setNewStartDate] = useState<Moment>(selectedDates.startDate);
  const [newEndDate, setNewEndDate] = useState<Moment|null>(selectedDates.endDate);
  const classes = useStyles();

  const handleStartDate = (date:Moment) => {
    setNewStartDate(date);
    setNewEndDate(null);
  };

  const handleEndDate = (date:Moment) => {
    setNewEndDate(date);
    setSelectedDates({
      startDate: newStartDate,
      endDate: date,
    });
  };

  const handleNullDate = () => {
    if (newEndDate === null) {
      setNewEndDate(moment(newStartDate).add(1, 'month'));
    }
  };

  return (
    <div data-testid={componentTestId} className={classes.rightAlignedCtas}>
      <div className={classes.dates}>
        <APDatePicker
          label="Date from"
          onChange={(date) => handleStartDate(date)}
          value={newStartDate}
          format="DD/MM/YYYY"
          minDate={moment('2021-05-01')}
          disableFuture
          className={clsx('dd-privacy-allow', classes.datePicker)}
          renderInput={(params) => <TextField {...params} />}
        />
        <APDatePicker
          label="Date to"
          onChange={(date) => handleEndDate(date)}
          value={newEndDate}
          format="DD/MM/YYYY"
          minDate={moment(newStartDate)}
          maxDate={moment(newStartDate).add(365, 'day')}
          onOpen={handleNullDate}
          disableFuture
          className={clsx('dd-privacy-allow', classes.datePicker)}

        />
      </div>
      <ExportMenu
        startDate={startDateIso}
        endDate={endDateIso}
        currencyAccounts={currencyAccounts}
      />
    </div>
  );
};

export default TransactionsCtas;
