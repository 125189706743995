import {
  Alert, AlertTitle, Box, Typography,
} from '@mui/material';
import AcknowledgeModal from 'components/Modals/AcknowledgeModal';
import React, { useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import clsx from 'clsx';
import useStyles from './KycModal.styles';

export interface IAccount{
  id: string,
  name: string,
  kycReviewDate: string,
  reviewDue: boolean,
  reviewOverDue: boolean,
  accountFrozen: boolean,
}

interface IKYCProps {
  overdue: IAccount[];
  due: IAccount[];
  frozen: IAccount[];
  modalOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}
const KYCModal = (props: IKYCProps) => {
  const {
    overdue, due, frozen, modalOpen, onClose,
  } = props;
  const styles = useStyles();
  const [ref, { height }] = useMeasure();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (height < 505 && height !== 0) {
      setDisabled(false);
    }
  }, [height, overdue]);

  const handleScroll = (e:
    { target: { scrollHeight: number; scrollTop: number; clientHeight: number; };
  }) => {
    const bottom = e.target.scrollHeight - (e.target.scrollTop + 50) < e.target.clientHeight;
    if (bottom) {
      setDisabled(false);
    }
  };
  const formatDate = (date:string) => {
    const formattedDate = new Date(date).toLocaleDateString();
    return formattedDate;
  };
  const overDueList = overdue.map((entity, i) => (
    <li data-testid={`overdue-${i}`} key={`${entity.id}-overdue`}>
      <Typography className={styles.entityInformation} variant="inherit">
        <span data-testid={`overdue-entity-${i}`}>
          {entity.name}
        </span>
        {' '}
        (Due date
        {' '}
        {formatDate(entity.kycReviewDate)}
        )
      </Typography>
    </li>
  ));

  const upcomingList = due.map((entity, i) => (
    <li data-testid={`upcoming-${i}`} key={`${entity.id}-upcoming`}>
      <Typography className={styles.entityInformation} variant="inherit">
        <span data-testid={`upcoming-entity-${i}`}>
          {entity.name}
        </span>
        {' '}
        (Due date
        {' '}
        {formatDate(entity.kycReviewDate)}
        )
      </Typography>
    </li>
  ));

  const frozenAccList = frozen.map((entity, i) => (
    <li data-testid={`frozen-${i}`} key={`${entity.id}-frozen`}>
      <Typography className={styles.entityInformation} variant="inherit">
        <span data-testid={`frozen-entity-${i}`}>
          {entity.name}
        </span>
      </Typography>
    </li>
  ));

  const modalContent = (
    <Box
      className={clsx(styles.container, { [styles.scrollBar]: height > 600 })}
      onScroll={disabled ? ((e) => handleScroll(e)) : undefined}
    >
      <Box className={styles.entityInformation} ref={ref}>
        <>
          <p>
            We have a regulatory obligation to ensure that information
            and documentation is kept up to date.
          </p>
          {frozen.length > 0
          && (
          <Alert className={styles.alert} severity="error">
            <AlertTitle className={styles.sectionTitle}>
              The following entities have been frozen. Payments cannot be made from these entities:
            </AlertTitle>
            <ul className={styles.unOrderedList}>
              {frozenAccList}
            </ul>
          </Alert>
          )}
          {overdue.length > 0
          && (
          <Alert className={styles.alert} severity="warning">
            <AlertTitle className={styles.sectionTitle}>
              KYC review is overdue on the following entities, and are at risk of being frozen:
            </AlertTitle>
            <ul className={styles.unOrderedList}>
              {overDueList}
            </ul>
          </Alert>
          )}
          {due.length > 0
          && (
          <Alert severity="info">
            <AlertTitle className={styles.sectionTitle}>
              KYC review is due on the following entities within the next
              30 days:
            </AlertTitle>
            <Typography>
              Please provide the requested information prior to the due
              date to prevent the account from being frozen.
            </Typography>
            <ul className={styles.unOrderedList}>
              {upcomingList}
            </ul>
          </Alert>
          )}
          <p>
            We understand this may be inconvenient for you, but we appreciate your
            cooperation in helping comply with regulatory requirements. Please contact your
            client services team to verify your information.
          </p>
        </>
      </Box>
    </Box>
  );

  return (
    <>
      <AcknowledgeModal
        open={modalOpen}
        title="Periodic KYC review"
        content={modalContent}
        actionButtonText="Acknowledge"
        buttonsDisabled={disabled}
        handleActionButton={() => onClose(false)}
      />
    </>
  );
};
export default KYCModal;
