import React, { FC, memo } from 'react';
import { IconButtonProps } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledIconButton from './IconButton.styles';

export interface IIconButtonProps extends IconButtonProps {
    icon: any;
}

const IconButton: FC<IIconButtonProps> = memo((props) => {
  const { icon, color = 'default', ...rest } = props;

  return (
    <StyledIconButton color={color} {...rest}>
      <FontAwesomeIcon icon={icon} />
    </StyledIconButton>
  );
});

export default IconButton;
