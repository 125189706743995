import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    drawerBody: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
    },
    drawerFooter: {
      borderTop: '1px solid rgba(112, 112, 112, 0.12)',
      padding: '12px 53px',
    },
  }),
  { name: 'BeneInfoDrawer' },
);

export default useStyles;
