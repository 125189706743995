import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { RolesEnum } from '@portal/user-types/dist/schema';
import _ from 'lodash';
import useStyles from './index.styles';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { useUserManagementContext } from '../../context';

type PermissionChangesTooltipProps = {
    hoverText: string;
    title: string;
    currentRoles: RolesEnum[] | [];
    requestedRoles: RolesEnum[] | [];
    currentApprovalLevel: string | null;
    requestedApprovalLevel: string | null;
}

const PermissionChangesTooltip: React.FC<PermissionChangesTooltipProps> = (props) => {
  const {
    hoverText,
    title,
    currentRoles,
    requestedRoles,
    currentApprovalLevel,
    requestedApprovalLevel,
  } = props;

  const styles = useStyles();
  const { rolesMap } = useUserManagementContext();

  const rolesAdded = _.difference(requestedRoles, currentRoles);
  const rolesRemoved = _.difference(currentRoles, requestedRoles);
  const approvalLevelChange = !_.isEqual(requestedApprovalLevel, currentApprovalLevel);

  const renderPermissions = (perms: RolesEnum[], permsArray: RolesEnum[]) => {
    if (perms.length === 0) return 'N/A';

    return perms.map((perm) => {
      const label = rolesMap?.[perm]?.label;

      return permsArray.includes(perm)
        ? <strong key={perm}>{label}</strong>
        : <p key={perm}>{label}</p>;
    });
  };

  return (
    <div className={styles.hoverBase}>
      <Tooltip
        title={(
          <>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.row}>
              <div className={styles.col1}>From</div>
              <div className={styles.col2}>
                <FontAwesomeIcon className={styles.arrowIcon} icon={faArrowRight} />
              </div>
              <div className={styles.col3}>To</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col1}>
                {renderPermissions(currentRoles, rolesRemoved)}
              </div>
              <div className={styles.col2}>
                <FontAwesomeIcon className={styles.arrowIcon} icon={faArrowRight} />
              </div>
              <div className={styles.col3}>
                {renderPermissions(requestedRoles, rolesAdded)}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col1}>
                {approvalLevelChange ? (
                  <strong>
                    {'Approval level: '}
                    {currentApprovalLevel ?? 'N/A'}
                  </strong>
                ) : (
                  <p>
                    {'Approval level: '}
                    {currentApprovalLevel ?? 'N/A'}
                  </p>
                ) }
              </div>
              <div className={styles.col2}>
                <FontAwesomeIcon className={styles.arrowIcon} icon={faArrowRight} />
              </div>
              <div className={styles.col3}>
                {approvalLevelChange ? <strong>{`Approval level: ${requestedApprovalLevel ?? currentApprovalLevel ?? 'N/A'}`}</strong> : <p>{`Approval level: ${requestedApprovalLevel ?? currentApprovalLevel ?? 'N/A'}`}</p> }
              </div>
            </div>
          </>
      )}
        classes={styles}
        leaveDelay={100}
        arrow
      >
        <div className={styles.tooltipContainer}>
          <p>|</p>
          <FontAwesomeIcon icon={faInfoCircle} />
          <span>{hoverText}</span>
        </div>
      </Tooltip>
    </div>
  );
};

export default PermissionChangesTooltip;
