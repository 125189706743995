import { Box } from '@mui/material';
import { Input } from 'components/Inputs/Input';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import useStyles from './Header.styles';

interface IProps {
  handleKeyUp: (event: any) => void;
}

const Header: React.FC<IProps> = (props: IProps) => {
  const { handleKeyUp } = props;
  const classes = useStyles();
  return (
    <Box className={classes.headerWrapper}>
      <Box className={classes.breadcrumbs}>
        Switch account
      </Box>
      <Box className={classes.title}>Choose which account to view</Box>
      <Box className={classes.inputWrapper}>
        <Input
          onKeyUp={handleKeyUp}
          fullWidth
          testId="input"
          InputProps={useMemo(
            () => ({
              startAdornment: <FontAwesomeIcon className={classes.icon} icon={faSearch} />,
            }),
            [],
          )}
          variant="filled"
          placeholder="Search entity"
        />
      </Box>
    </Box>
  );
};

export default Header;
