import React, { FC } from 'react';

import IconStyled from './Icon.styles';

export const Icon: FC<{
  position: 'left' | 'right';
  wrap?: boolean;
  wrapVariant: 'filled' | 'standard' | 'outlined' | undefined;
  onClick?: () => void;
}> = (props) => {
  const {
    position,
    wrapVariant,
    onClick,
    wrap,
    children,
  } = props;

  if (!wrap) {
    return <>{children}</>;
  }

  return (
    <IconStyled onClick={onClick} wrapVariant={wrapVariant} positioning={position} className="InputIconBox">
      {children}
    </IconStyled>
  );
};
