import { BatchApprover } from '@alpha/payments-dtos';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import DropDownWrapper from './DropDownWrapper/DropDownWrapper';

interface IProps {
  id: string;
   accountId: string;
  loading: boolean;
  approvers: BatchApprover[],
  displayMultiSelect: boolean,
  setOpen: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  setDisplayMultiSelect: (val: boolean) => void,
  submitApproverEmails: (id: string, accountId?:string) => Promise<boolean>,
  setCurrentOptions: React.Dispatch<React.SetStateAction<BatchApprover[]>>,
  currentOptions: BatchApprover[],
}

const MultiSelectSection = ({
  id,
  approvers,
  accountId,
  loading,
  displayMultiSelect,
  setOpen,
  setDisplayMultiSelect,
  submitApproverEmails,
  currentOptions,
  setCurrentOptions,
}: IProps) => {
  const [awaitingSubmit, setAwaitingSubmit] = useState<boolean>(false);
  const [textValue, setTextValue] = useState('');

  const handleApprovers = (): BatchApprover[] => approvers.filter(
    (approver: BatchApprover) => approver.eligible,
  );

  const handleSubmit = async (): Promise<void> => {
    setAwaitingSubmit(true);
    const success = await submitApproverEmails(id, accountId);
    if (success) {
      setDisplayMultiSelect(false);
      setOpen(null);
    }
    setAwaitingSubmit(false);
    setCurrentOptions([]);
  };
  if (displayMultiSelect) {
    return (
      <DropDownWrapper
        loading={loading}
        currentOptions={currentOptions}
        awaitingSubmit={awaitingSubmit}
        handleSubmit={handleSubmit}
        handleApprovers={handleApprovers}
        setTextValue={setTextValue}
        setCurrentOptions={setCurrentOptions}
      />
    );
  }
  return (
    <Typography>
      Send email reminder to approvers
    </Typography>
  );
};

export default MultiSelectSection;
