import { Beneficiary } from '@alpha/bene-dtos';
import { Box, Button } from '@mui/material';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { faEdit, faRedoAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { PaymentFeatureFlags } from 'domain/PaymentsMultiEntity/ManualPayments/constants';
import StyledGenericTable from 'components/Table/StyledGenericTable';
import { Drawer } from 'components/Drawer';
import moment from 'moment';
import { TCurrencyAccount } from '../../../../../../models/currencyAccounts';
import {
  TPayment,
} from '../../../../../../models/payments';
import PaymentsService from '../../../../../../services/Payments/payments.service';
import { TStore } from '../../../../../../store';
import { actions, TPaymentsStore } from '../../../../../../store/payments/payments.reducer';
import { formatCurrency } from '../../../../../../utils/currency.helpers';
import ErrorRow from './ErrorRow';
import useStyles, { IconSOStyled } from './PaymentsTable.styles';
import usePaymentsContext from '../../ManualPayment/usePaymentsContext';
import { repeatOptions } from '../../ManualPayment/PaymentFields/StandingOrder/constants';
import FormattedRepeatLabel from '../../ManualPayment/PaymentFields/StandingOrder/FormattedRepeatLabel';
import PaymentsDrawerHead from './PaymentsDrawer/PaymentsDrawerHead';
import PaymentsDrawer from './PaymentsDrawer/PaymentsDrawer';
import { formatFrequency } from './formatFrequency';

interface IProps {
  fundingAccount: TCurrencyAccount;
  payments: TPayment[];
  handleSetCurrentPayments: (_currentPayments: TPayment[]) => void;
  handleSetCurrentPayment: (_currentPayment: TPayment, index: number) => void;
  handleSetSelectedBeneficiary: (
    beneficiary: Beneficiary | undefined
  ) => Promise<void>;
  fxBatch: boolean;
}

const PaymentsTable: React.FC<IProps> = ({
  fundingAccount,
  payments,
  handleSetCurrentPayments,
  handleSetCurrentPayment,
  handleSetSelectedBeneficiary,
  fxBatch,
}) => {
  const paymentStore = useSelector<TStore, TPaymentsStore>((store: TStore) => store.payments);
  const [batchErrors, setBatchErrors] = useState<any[]>();
  const dispatch = useDispatch();
  const styles = useStyles();
  const { flags } = useGetFeatureFlags();
  const { paymentsContext } = usePaymentsContext();
  const [viewPaymentDetails, setViewPaymentDetails] = useState<{
    payment: TPayment;
    index: number
  } | null>(null);

  const tableColumns = useMemo(() => {
    const columns = [
      {
        header: <Box paddingRight="80px">Beneficiary</Box>,
        accessor: 'beneficiary',
      },
      {
        header: <Box textAlign="left">Debit Account</Box>,
        accessor: 'debitAccount',
      },

      {
        header: <Box textAlign="left">Purpose Of Payment</Box>,
        accessor: 'purposeOfPayment',
      },
      {
        header: <Box textAlign="left">Payment Ref</Box>,
        accessor: 'paymentRef',
      },

      {
        header: <Box textAlign="left">Payment Date</Box>,
        accessor: 'paymentDate',
      },
      {
        header: <Box textAlign="right">Amount</Box>,
        accessor: 'amount',
      },
      {
        header: <Box textAlign="right" />,
        accessor: 'action',
      },
    ];

    if (fxBatch) {
      columns.splice(2, 0, {
        header: <Box textAlign="left">Funding Account</Box>,
        accessor: 'fundingAccount',
      });
    }

    if (
      flags?.find((flag) => flag.name === PaymentFeatureFlags.SUPPORTING_DOCUMENTS && flag.isActive)
      && !paymentsContext.isStandingOrder
    ) {
      columns.splice(4, 0, {
        header: <Box textAlign="center">Documents</Box>,
        accessor: 'documents',
      });
    }

    return columns;
  }, [fxBatch, flags, paymentsContext.isStandingOrder]);

  const removeErrorFromState = (index: number): void => {
    if (!batchErrors) return;
    const newErrors = batchErrors.filter((_, i) => i !== index);
    if (!newErrors.length) dispatch(actions.clear());
    setBatchErrors(newErrors);
  };

  const handleDeletePayment = (index: number) => {
    const newCurrentPayments = [...payments];
    newCurrentPayments.splice(index, 1);
    const errors = getErrorsIndex(index);
    if (errors >= 0) {
      removeErrorFromState(index);
    }
    handleSetCurrentPayments(newCurrentPayments);
  };

  const getBatchErrors = async () => {
    if (!paymentStore.batchDetails?.batchId) return;
    const response = await PaymentsService.getBatchInvalid(paymentStore.batchDetails?.batchId);
    setBatchErrors(response);
  };

  useEffect(() => {
    if (paymentStore.batchDetails?.totalInvalidPayments) {
      getBatchErrors();
    }
  }, [paymentStore.batchDetails]);

  const getErrorsForRow = (tableIndex: number): string[] => {
    if (!batchErrors) return [];
    const errorRow = batchErrors
      .filter((batchError: any) => (+batchError.batchLineNumber - 1) === tableIndex);
    if (!errorRow.length) return [];
    return errorRow[0].errors;
  };

  const getErrorsIndex = (tableIndex: number): number => {
    if (!batchErrors) return -1;
    return batchErrors?.findIndex((batchError) => (+batchError.batchLineNumber - 1) === tableIndex);
  };

  const getOpt = (label?: string) => repeatOptions.find((item) => item.name === label);

  const data = payments.map((payment, index) => ({
    beneficiary: (
      <Button
        onClick={async () => handleSetSelectedBeneficiary(payment.beneficiary)}
        variant="text"
        color="primary"
      >
        <b>{payment.beneficiary?.name}</b>
      </Button>
    ),
    debitAccount: fxBatch
      ? (payment.debitingAccountFriendlyName || payment.debitingAccountIban)
      : (fundingAccount.friendlyName || fundingAccount.iban),
    fundingAccount: fundingAccount.friendlyName || fundingAccount.iban,
    purposeOfPayment: payment.purposeOfPaymentCode,
    paymentRef: payment.reference || '-',
    documents: (
      <div className={styles.documentsCol}>
        <div className={styles.documentsBox}>
          <FontAwesomeIcon icon={faFileAlt} className={styles.documentsIcon} />
          { payment?.files?.length || 0 }
        </div>
      </div>
    ),
    paymentDate: (
      <Box textAlign="left">
        {
          paymentsContext.isStandingOrder ? (
            <>
              <IconSOStyled icon={faRedoAlt} className="icon" />
              <FormattedRepeatLabel opt={getOpt(payment.frequency)} date={payment.date} />
            </>
          ) : (
            payment.date
          )
        }
      </Box>
    ),
    amount: (
      <Box textAlign="right">
        <b>
          {formatCurrency(
            payment.fixedSide || payment.beneficiary?.currencyCode,
            payment.paymentAmount,
          )}
        </b>
      </Box>
    ),
    action: (
      <Box
        textAlign="center"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {batchErrors
          && paymentStore.batchDetails?.totalInvalidPayments
          && (
            <ErrorRow errors={getErrorsForRow(index)} />
          )}
        <div>
          {
            paymentsContext.isStandingOrder && (
              <Button color="secondary" onClick={() => setViewPaymentDetails({ payment, index })} className={styles.viewButton}>
                View
              </Button>
            )
          }
          <Button
            color="secondary"
            onClick={() => {
              handleSetCurrentPayment(payment, index);
            }}
            data-testid="paymentTable-editPayment"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button color="secondary" onClick={() => handleDeletePayment(index)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      </Box>
    ),
  }));

  const handleEditFromDrawer = () => {
    if (viewPaymentDetails) {
      handleSetCurrentPayment(viewPaymentDetails.payment, viewPaymentDetails.index);
      setViewPaymentDetails(null);
    }
  };

  const handleDeleteFromDrawer = () => {
    if (viewPaymentDetails) {
      handleDeletePayment(viewPaymentDetails.index);
      setViewPaymentDetails(null);
    }
  };

  return (
    <div>
      <StyledGenericTable
        testId="genericTable"
        columns={tableColumns}
        data={data}
        handleTableRowClick={() => { }}
      />
      {
        viewPaymentDetails && !!viewPaymentDetails.payment && (
          <Drawer
            headerTitle={(
              <PaymentsDrawerHead
                item={viewPaymentDetails.payment}
                onEditPayment={handleEditFromDrawer}
                onDeletePayment={handleDeleteFromDrawer}
              />
            )}
            open={!!viewPaymentDetails}
            anchor="right"
            onClose={() => setViewPaymentDetails(null)}
          >
            <PaymentsDrawer
              item={viewPaymentDetails.payment}
              formatFrequency={formatFrequency}
              fundingAccount={fundingAccount.friendlyName || fundingAccount.iban}
              debitingAccount={
                fxBatch
                  ? (
                    viewPaymentDetails.payment.debitingAccountFriendlyName
                    || viewPaymentDetails.payment.debitingAccountIban
                  )
                  : (fundingAccount.friendlyName || fundingAccount.iban)
              }
            />
          </Drawer>
        )
      }
    </div>
  );
};

export default PaymentsTable;
