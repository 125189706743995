import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface transactionsPageState {
  selectedCurrencyAccounts : string[],
}

const initialState = {
  selectedCurrencyAccounts: [],
} as transactionsPageState;

export const transactionsPageSlice = createSlice({
  name: 'transactionsPage',
  initialState,
  reducers: {
    updateSelectedCurrencyAccounts: (state,
      action: PayloadAction<string[]>): transactionsPageState => ({
      ...state,
      selectedCurrencyAccounts: action.payload,
    }),
  },
});

export const { actions } = transactionsPageSlice;
