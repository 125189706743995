import React from 'react';
import Breadcrumbs, { IBreadcrumbItem } from 'components/Breadcrumbs';
import { RouteChildrenProps } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  StyledApBody, StyledApHeader, StyledPageTabs, StyledRightAlignedCta, StyledTabsAndCta,
} from './APMainLayout.styles';

export interface IAPMainLayout {
    breadCrumbs?: IBreadcrumbItem[];
    title?: string | JSX.Element;
    rightAlignedCta?: JSX.Element;
    pageTabs?: JSX.Element;
    bodyBackgroundColor?: string;
    children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const APMainLayout: React.FC<IAPMainLayout> = ({
  breadCrumbs, title, pageTabs, rightAlignedCta, bodyBackgroundColor, children,
}: IAPMainLayout) => (
  <>
    <StyledApHeader>
      {breadCrumbs && breadCrumbs.length > 0 && <Breadcrumbs items={breadCrumbs} />}
      <Typography variant="h1">{title}</Typography>
      <StyledTabsAndCta aria-label="header-tabs-and-ctas">
        {pageTabs && <StyledPageTabs aria-label="header-page-tabs">{pageTabs}</StyledPageTabs>}
        {rightAlignedCta && <StyledRightAlignedCta aria-label="header-ctas">{rightAlignedCta}</StyledRightAlignedCta>}
      </StyledTabsAndCta>
    </StyledApHeader>
    <StyledApBody data-testid="main-layout-body" bodyBackgroundColor={bodyBackgroundColor}>
      {children}
    </StyledApBody>
  </>
);
