import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    searchBar: {
      display: 'flex',
      width: '400px',
    },
    dashboardHeaderLine: {
      borderTop: '1px solid rgb(224,224,224)',
      paddingTop: '5px',
    },
  }),
  { name: 'DashboardTable' },
);

export default useStyles;
