import { IATDraftResponse } from '@alpha/iat-dtos';
import clsx from 'clsx';
import { StyledDivider } from 'components/StyledDivider';
import { Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { InterAccountTransferDrawer as Drawer } from '../../../components/InterAccountTransfer/Drawer/Drawer';
import Alert from '../../../components/Alert/Alert';
import { TCurrencyAccounts } from '../../../models/currencyAccounts';
import { useStyles } from '../InterAccountTransfer.styles';
import useInterAccountTransferFormContext from '../useInterAccountTransferFormContext';
import ActionAccounts from './ActionAccounts/ActionAccounts';
import DisplayIndicativeRate from './DisplayIndicativeRate/DisplayIndicativeRate';
import Footer from './Footer/Footer';
import Reference from './Reference/Reference';
import { errorMessages } from '../FormValues';

interface IProps {
  currencyAccounts: TCurrencyAccounts;
  selectedDraftIAT?: IATDraftResponse;
  accountId?: string;
  isValid: boolean;
  isFxTrade: boolean;
  rate: number | undefined;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  reloadData: () => void;
}

const DebitingAndCreditingDrawer: React.FC<IProps> = ({
  currencyAccounts,
  selectedDraftIAT,
  accountId,
  isValid,
  isFxTrade,
  rate,
  reloadData,
  handleSubmit,
}: IProps) => {
  const styles = useStyles();
  const { form, isSectionComplete } = useInterAccountTransferFormContext();
  const [isIndicativeRateLoading, setIsIndicativeRateLoading] = useState(false);

  useEffect(() => {
    if (reloadData) {
      reloadData();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} className={styles.form} data-testid="debit-and-credit">
      <Drawer.Child section="top">
        <Typography variant="h4" className={clsx(styles.header, 'dd-privacy-allow')}>
          Balance Transfer
        </Typography>

        {!selectedDraftIAT && <Alert data-dd-privacy="allow" variant="info" text={<Typography className={styles.subtitle}>If you are unable to see your desired currency listed below, please contact Client Services to help activate your accounts</Typography>} />}

        {form.errors.debitingAccount?.amount === errorMessages.INSUFFICIENT_FUNDS
          && (<Alert variant="error" text="Insufficient funds to complete balance transfer" />)}

        {((form.errors.debitingAccount?.amount || form.errors.creditingAccount?.amount)
          === errorMessages.MINAMOUNT)
          && (<Alert variant="error" text="The amount you have entered is below the minimum allowed amount" />)}

        <ActionAccounts
          disabled={Boolean(selectedDraftIAT)}
          type="DEBITING"
          currencyAccounts={currencyAccounts}
        />
      </Drawer.Child>
      <StyledDivider />
      <Drawer.Child section="bottom">
        <DisplayIndicativeRate
          accountId={accountId}
          isIndicativeRateLoading={isIndicativeRateLoading}
          setIsIndicativeRateLoading={setIsIndicativeRateLoading}
        />
        <ActionAccounts
          disabled={Boolean(selectedDraftIAT)}
          type="CREDITING"
          currencyAccounts={currencyAccounts}
        />
        <Reference
          disabled={Boolean(selectedDraftIAT)}
          sectionComplete={isSectionComplete('CREDITING')}
          error={
            form.touched.creditingAccount?.reference
            && Boolean(form.errors.creditingAccount?.reference)
          }
          helperText={
            form.touched.creditingAccount?.reference
            && form.errors.creditingAccount?.reference
          }
          onBlur={form.handleBlur}
          onChange={form.handleChange}
        />
      </Drawer.Child>
      <Drawer.Footer>
        <Footer
          selectedDraftIAT={selectedDraftIAT}
          isBalanceNegative={
            +(form.values.debitingAccount.currencyAccount?.availableBalance || 0)
            - +(form.values.debitingAccount.amount || 0) < 0
          }
          isValid={isValid}
          isFxTrade={isFxTrade}
          rate={rate}
          isIndicativeRateLoading={isIndicativeRateLoading}
        />
      </Drawer.Footer>
    </form>
  );
};

export default memo(DebitingAndCreditingDrawer);
