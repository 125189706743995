import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    tooltipHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        marginLeft: 130,
      },
    },
    tooltipList: {
      paddingLeft: 18,
    },
  }), { name: 'TooltipTitle' },
);

export default useStyles;
