import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemeProvider as Emotion10ThemeProvider } from '@emotion/react';
import { ITheme } from './theme.interfaces';

const ThemeProvider: FC<{ theme: ITheme; children: ReactNode }> = (props) => {
  const { children, theme } = props;
  return (
    <StyledEngineProvider injectFirst>
      <Emotion10ThemeProvider theme={theme}>
        <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
      </Emotion10ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
