import {
  Autocomplete, Typography, Box,
  TextField,
} from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { useStyles } from './AutocompleteDropDown.styles';
import useListStyles from './Listbox.styles';

export interface IOption {
  code: string;
  paymentInfo?: string;
  disabled?: boolean;
  countryCode?: string;
  id?: string;
  name: string | null;
}
interface IAutocompleteDropDown {
  type?: 'CODE' | 'NAME' | 'CODE-NAME';
  placeholder?: string;
  hideFlag?: boolean;
  testId?: string;
  options: any;
  name: string;
  setFieldValue: Function;
  value?: any;
  touched?: any;
  errors?: any;
  currentOption: IOption;
  textFieldValue: string;
  setTextFieldValue: React.Dispatch<React.SetStateAction<string>>;
  handleKeyUp?: (textFieldValue: string) => void;
  setCurrentOption: React.Dispatch<React.SetStateAction<IOption>>;
  setTouched?: () => void;
}
const AutocompleteDropDown: React.FC<IAutocompleteDropDown> = (
  props: IAutocompleteDropDown,
) => {
  const {
    type,
    options,
    placeholder,
    hideFlag,
    name,
    testId,
    setFieldValue,
    handleKeyUp,
    value,
    touched,
    errors,
    currentOption,
    setCurrentOption,
    textFieldValue,
    setTextFieldValue,
    setTouched,
  } = props;
  const classes = useStyles();
  const { listBox } = useListStyles();
  const showInputValue = () => {
    const dropdownValue = options.find((option: any) => typeReturn(option) === value);
    if (dropdownValue) {
      return {
        inputValue: typeReturn(dropdownValue) || undefined,
      };
    }
  };

  const handleChange = (_name: string, valueCode: string, option: IOption): void => {
    setFieldValue(_name, valueCode, option);
    setCurrentOption(option);
  };

  const handleDisplayIcon = (): JSX.Element => {
    if (currentOption && currentOption.name && currentOption.code) {
      if (textFieldValue === typeReturn(currentOption)) {
        return (
          <Box className={clsx(classes.optionIcon, hideFlag && 'hideFlag')}>
            <img alt="option" src={(`../../../static/flags/${currentOption.code.toLowerCase().substr(0, 2)}.svg`)} />
          </Box>
        );
      }
    }
    return <></>;
  };

  const setDropdownWrapper = () => {
    if (currentOption && textFieldValue) {
      if (textFieldValue === typeReturn(currentOption)) {
        return classes.dropdownWrapperSelected;
      }
    }
  };

  const typeReturn = (prop: IOption) => {
    switch (type) {
      case 'CODE':
        return prop?.code;
      case 'NAME':
        return prop.name;
      case 'CODE-NAME':
        if (prop.code && prop.name) { return `${prop.code} (${prop.name})`; }
        return '';
      default:
        return prop.name;
    }
  };

  return (
    <Box className={setDropdownWrapper()}>
      <Autocomplete
        onFocus={setTouched}
        data-testid={testId}
        classes={{ listbox: listBox }}
        popupIcon={<FontAwesomeIcon icon={faAngleDown} />}
        onInputChange={(event, _value) => setTextFieldValue(_value)}
        className={clsx(classes.dropdown, hideFlag && classes.hideFlag)}
        size="small"
        autoHighlight={false}
        getOptionDisabled={(option) => Boolean(option.disabled)}
        blurOnSelect={false}
        onChange={(_: unknown, option: any | null) => handleChange(name, option?.code, option)}
        id={name}
        clearIcon={false}
        value={currentOption}
        options={options}
        isOptionEqualToValue={(option:IOption, autoValue:IOption) => option.code === autoValue.code}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={(placeholder || 'Select')}
            name={name}
            error={touched && Boolean(errors)}
            helperText={touched && errors}
            variant="filled"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
              form: {
                autoComplete: 'new-off',
              },
            }}
            onKeyUp={() => handleKeyUp && handleKeyUp(textFieldValue)}
          />
        )}
        renderOption={(autocompleteProps, option: IOption, { selected }) => {
          if (option.code === 'divider') {
            return (
              <div className={classes.divider} />
            );
          }
          let icon;
          try {
            icon = `../../../static/flags/${option.code.toLowerCase().substr(0, 2)}.svg`;
          } catch { }
          return (
            <div className={classes.itemRow} {...autocompleteProps}>
              <div className={clsx(classes.output, hideFlag && 'hideFlag')}>
                <img src={icon ?? ''} alt="dropdown" />
              </div>
              <div className={classes.labelWrapper}>
                <Typography data-testid={`currency option: ${option.code}`} className={classes.outputText}>{typeReturn(option)}</Typography>
                {
                  hideFlag
                  && option.countryCode
                  && <Typography className={classes.paymentInfo}>{option.countryCode}</Typography>
                }
                {
                  hideFlag
                  && option.code
                  && <Typography className={classes.paymentInfo}>{option.code}</Typography>
                }
                {
                  option.paymentInfo
                  && <Typography className={classes.paymentInfo}>{option.paymentInfo}</Typography>
                }
                {
                  selected
                  && <FontAwesomeIcon className={classes.selectedCheck} icon={faCheckCircle} />
                }
              </div>
            </div>
          );
        }}
        getOptionLabel={(option) => (typeReturn(option) || '')}
        {...showInputValue()}
      />
      {handleDisplayIcon()}
    </Box>
  );
};

export default AutocompleteDropDown;
