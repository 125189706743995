import { Input } from 'components/Inputs/Input';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import React, {
  memo, useEffect, useState,
} from 'react';
import Alert from 'components/Alert/Alert';
import AuthorizationService from 'services/Authorization/authorization.service';
import { Role } from '@alpha/auth-types';
import { TCurrencyAccount } from '../../../../../../models/currencyAccounts';
import SelectBeneDrawer from '../SelectBeneficiaryDrawer/SelectBeneficiaryDrawer';
import useStyles from './Beneficiary.styles';
import CreateBeneficiary from '../../../../../Beneficiaries/CreateBeneficiary';

interface IProps {
  open: boolean;
  handleBeneficiaryDrawerToggle: (open: boolean) => void;
  value?: string;
  selectedFundingAccount : TCurrencyAccount;
  accountId: string;
  accountName:string;
}

const Beneficiary: React.FC<IProps> = ({
  open,
  handleBeneficiaryDrawerToggle,
  value,
  selectedFundingAccount,
  accountId,
  accountName,
}: IProps) => {
  const classes = useStyles();
  const [selectBeneDrawerOpen, setSelectBeneDrawerOpen] = useState(false);
  const [isBeneficiaryInputter, setIsBeneficiaryInputter] = useState(false);

  useEffect(() => {
    const settingBeneficiaryInputter = async () => {
      const beneficiaryInputterAccounts = await AuthorizationService.getEntitiesForRole(
        Role.BENEFICIARY_INPUTTER,
      );
      const paymentInputterAccountIds = beneficiaryInputterAccounts.map(({ id }) => id);
      setIsBeneficiaryInputter(paymentInputterAccountIds.includes(accountId));
    };
    settingBeneficiaryInputter();
  }, [accountId]);

  return (
    <div className="beneficiary" data-testid="select-bene-wrapper">
      <Typography variant="subtitle1" className="label" fontSize={14}>
        Beneficiary
      </Typography>
      <SelectBeneDrawer
        accountId={accountId}
        selectedFundingAccount={selectedFundingAccount}
        open={selectBeneDrawerOpen}
        onClose={() => setSelectBeneDrawerOpen(false)}
      />
      <Input
        className={value ? classes.selectBeneInput : classes.selectBenePlaceHolder}
        testId="select-bene-input"
        variant="filled"
        value={value || ' Select a Beneficiary'}
        startAdornmentOnClick={() => setSelectBeneDrawerOpen(true)}
        InputProps={{
          onClick: () => setSelectBeneDrawerOpen(true),
          readOnly: true,
          startAdornment: <FontAwesomeIcon icon={faEdit} />,
        }}
      />
      { isBeneficiaryInputter
          && (
          <>
            <button onClick={() => handleBeneficiaryDrawerToggle(true)} type="button" className="beneficiaryDrawerTriggerButton">
              <Typography
                variant="subtitle1"
                className="beneficiaryDrawerTrigger"
                data-testid="new-beneficiary"
              >
                Want to pay someone new?
              </Typography>
            </button>
            <CreateBeneficiary
              open={open}
              handleDrawerClose={() => handleBeneficiaryDrawerToggle(false)}
              multiEntity
              accounts={[{
                value: accountId,
                label: accountName,
              }]}
            />
          </>
          )}
    </div>
  );
};

export default memo(Beneficiary);
