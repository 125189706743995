import {
  PaymentBatchDraftDto,
  PaymentBatchDraftStatus,
  PaymentBatchDto,
  PaymentDraftDto,
} from '@alpha/payments-dtos';
import React, { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';
import { iAllowedAccounts } from 'store/accounts/accounts.reducer';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { UserRole } from '../../../../models/user';
import isPaymentBatchDraftDto from '../../../../utils/payments/isPaymentBatchDraftDto.helper';
import ApproveRejectContainer from './ApproveRejectFooterContainer/ApproveRejectFooterContainer';
import ReleaseFundedBatchContainer from './ReleaseFundedBatchContainer/ReleaseFundedBatchContainer';
import history from '../../../../services/history/browserHistory';
import * as authyActions from '../../../../store/authy/actions';
import { actions as paymentActions } from '../../../../store/payments/payments.reducer';
import routes from '../../../../routes.path';
import BatchSubmissionFooter from './BatchSubmissionFooter';
import BookFxFooterContainer from './BookFxFooterContainer/BookFxFooterContainer';
import useAuthorizationMultiEntity from '../../../../hooks/useAuthorizationMultiEntity';
import BatchSubmittedFooter from './BatchSubmittedFooter/BatchSubmittedFooter';

interface IProps {
  batchDetails: PaymentBatchDraftDto | PaymentBatchDto;
  accountId: string;
  paymentDrafts?: PaymentDraftDto[];
  paymentDraftsLoading: boolean;
}
const FooterContainer: React.FC<IProps> = (props: IProps) => {
  const { batchDetails, accountId, paymentDrafts, paymentDraftsLoading } =
    props;
  const { hasRole } = useAuthorizationMultiEntity(accountId);
  const fxBooker = hasRole(UserRole.FX_IAT);
  const approverOwn = hasRole(UserRole.PAYMENTS_APPROVER_OWN);
  const approver = hasRole(UserRole.PAYMENTS_APPROVER);
  const inputter = hasRole(UserRole.PAYMENTS_INPUTTER);
  const dispatch = useDispatch();
  const { flags } = useGetFeatureFlags();
  const isFraudModalEnabled = useMemo(
    () =>
      flags?.some((flag) => flag.name === 'fraudModalEnabled' && flag.isActive),
    [flags],
  );

  const [acknowledgeModalOpen, setAcknowledgeModalOpen] = useState(false);
  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );

  const isFinalApprover = (_batchDetails: PaymentBatchDraftDto) => {
    if (
      _batchDetails.approvalsRequired &&
      _batchDetails.noOfApprovals !== undefined &&
      _batchDetails.approvalsRequired - _batchDetails.noOfApprovals === 1
    ) {
      return true;
    }
    return false;
  };

  const buttonTextFunc = (_batchDetails: PaymentBatchDraftDto): string => {
    if (
      _batchDetails.currentUserCanApprove &&
      _batchDetails.approvalsRequired === 1
    ) {
      return _batchDetails.requiresFx
        ? 'Book FX & Send Payments'
        : 'Send Payments';
    }
    return 'Submit for approval';
  };

  const handleExitClick = () => {
    dispatch(paymentActions.clear());
    history.push(routes.payments.multientity.base);
  };

  const handleModalConfirmation = () => {
    dispatch(
      authyActions.initiatePayment({
        batchId: batchDetails.batchId,
        accountId,
        type: 'PAYMENTS',
        approverOwn,
        fraudWarningAcknowledged: true,
      }),
    );
    setAcknowledgeModalOpen(false);
  };

  const shouldShowFraudWarningModal = () => {
    if (isFraudModalEnabled) {
      const accountIsMicroEnterprise = allowedAccounts.some(
        (acc) => acc.id === accountId && acc.isMicroEnterprise === true,
      );

      const batchHasNewAccBenes = (paymentDrafts || []).some(
        (item: PaymentDraftDto) =>
          item.beneficiaryDraft?.accountBeneficiaryIsDuplicate !== true,
      );

      return accountIsMicroEnterprise && batchHasNewAccBenes;
    }

    return false;
  };

  const shouldShowFraudWarningModalResult = shouldShowFraudWarningModal();

  const handleOnClick = () => {
    if (shouldShowFraudWarningModal()) {
      setAcknowledgeModalOpen(true);
    } else {
      dispatch(
        authyActions.initiatePayment({
          batchId: batchDetails.batchId,
          accountId,
          type: 'PAYMENTS',
          approverOwn,
        }),
      );
    }
  };

  if (isPaymentBatchDraftDto(batchDetails)) {
    switch (batchDetails.batchStatus) {
      case PaymentBatchDraftStatus.SUBMITTED: {
        return (
          <ApproveRejectContainer
            accountId={accountId}
            batchId={batchDetails.batchId}
            batchName={batchDetails.sourceName || 'Payment Batch'}
            uploadedById={batchDetails.uploadedById}
            canApprove={batchDetails.currentUserCanApprove}
            requiresFx={batchDetails.requiresFx}
            isFinalApprover={isFinalApprover(batchDetails)}
            canReject={batchDetails.currentUserCanReject}
            shouldShowFraudWarningModal={shouldShowFraudWarningModalResult}
            paymentDraftsLoading={paymentDraftsLoading}
          />
        );
      }
      case PaymentBatchDraftStatus.APPROVED:
        if (batchDetails.requiresFx && fxBooker) {
          return (
            <BookFxFooterContainer
              uploadedById={batchDetails.uploadedById}
              batchName={batchDetails.friendlyBatchId || ''}
              accountId={accountId}
              batchId={batchDetails.batchId}
              userApprover={approver}
              userApproverOwn={approverOwn}
            />
          );
        }
        return <BatchSubmittedFooter />;
      case PaymentBatchDraftStatus.FUNDED:
        return (
          <ReleaseFundedBatchContainer
            accountId={accountId}
            batchId={batchDetails.batchId}
          />
        );
      case PaymentBatchDraftStatus.VALIDATED:
        return (
          <BatchSubmissionFooter
            handleExitClick={handleExitClick}
            handleOnClick={handleOnClick}
            buttonText={buttonTextFunc(batchDetails)}
            inputter={inputter}
            handleModalConfirmation={handleModalConfirmation}
            acknowledgeModalOpen={acknowledgeModalOpen}
            setAcknowledgeModalOpen={setAcknowledgeModalOpen}
          />
        );
      default:
        return <BatchSubmittedFooter />;
    }
  }

  return <BatchSubmittedFooter />;
};

export default memo(FooterContainer);
