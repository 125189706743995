import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    heading: {
      color: '#333333',
      opacity: '100%',
      letterSpacing: '-0.9px',
      fontWeight: typography.fontWeightSemiBold,
      paddingBottom: 8,
      fontSize: 18,
      marginBottom: '10px',
    },
    container: {
      '& .MuiSvgIcon-root': {
        height: '27px',
        width: '27px',
      },
      height: '86px',
      display: 'flex',
      flexDirection: 'column',
    },
    radio: {
      marginRight: 28,
      fontSize: '50px !important',
      padding: '0',
      color: '#B1B1B1',
      disabled: 'disabled',
      '&$checked': {
        color: '#098375',
      },
    },
    checked: {},
    optionDisabled: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: 'fit-content',
    },
    optionEnabled: {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
    },
    authyBackground: {
      background: '#EC1C24',
      height: '86px',
      width: '86px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    authyHeader: {
      fontSize: '18px',
      color: '#080808',
      margin: '0 0 5px 0',
    },
    authyDescription: {
      fontSize: '12px',
      color: '#080808',
      opacity: '80%',
      margin: '0',
    },
    descriptionContainer: {
      background: '#F1F1F1',
      height: '86px',
      width: '472px',
      backdropFilter: 'blur(50px)',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '0 0 0 25px',
    },
    image: {
      height: '60px',
      width: '60px',
      margin: '0',
    },
    status: {
      margin: '0 0 0 15px',
      position: 'relative',
      top: '-3px',
      letterSpacing: '1.65px',
    },
    disabled: {
      cursor: 'pointer',
    },
  }),
  { name: 'Success' },
);

export default useStyles;
