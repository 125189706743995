import React, { useState } from 'react';
import {
  faBan,
  faPencil, // faPlus, faBan,
} from '@fortawesome/pro-light-svg-icons';
import { AccountWithApprovalConfig, User, UserAccount } from '@portal/user-types';
import { Typography } from '@mui/material';
import RemoveAccessModal from 'domain/UserManagement/components/RemoveAccessModal';
import ActionDropDown from '../../../components/ActionDropDown';
import { useUserManagementContext } from '../../../context';
import { ModalTypeEnum } from '../../../context/index.interfaces';

interface IProps {
  entity: AccountWithApprovalConfig;
  user: User,
}

const UserActionDropdown: React.FC<IProps> = (props: IProps) => {
  const { user, entity } = props;
  const { openModal } = useUserManagementContext();

  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const menuItems = [];

  if (entity.isManageable) {
    menuItems.push(
      {
        id: 'action-permission-edit',
        content: (
          <Typography>
            Edit permissions
          </Typography>
        ),
        icon: faPencil,
        onClick: () => openModal({ user, entity, modalType: ModalTypeEnum?.ENTITY_ACCESS }),
        underline: true,
      },
      {
        id: 'action-permission-remove-access-from-entity',
        content: (
          <Typography>
            Remove access
          </Typography>
        ),
        icon: faBan,
        onClick: () => { setOpenRemoveModal(true); },
        underline: false,
        userCanApprove: false,
      },
    );
  }

  // To be implemented in future phases.
  // {
  //   id: 'action-permission-add-to-entity',
  //   content: (
  //     <Typography>
  //       Add to entity
  //     </Typography>
  //   ),
  //   icon: faPlus,
  //   onClick: () => { console.log('Add to entity ', user); },
  //   underline: true,
  //   userCanApprove: false,
  // },

  return (
    <>
      <RemoveAccessModal
        openModal={openRemoveModal}
        setOpenModal={setOpenRemoveModal}
        manageableEntities={[entity as UserAccount]}
        user={user}
      />
      <ActionDropDown menuItems={menuItems} />
    </>
  );
};

export default UserActionDropdown;
