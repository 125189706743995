import { Box, ClickAwayListener, Typography } from '@mui/material';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import Menu from 'components/Menu/Menu';
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import useActionDropdown from '../../../../../hooks/useActionDropDown';
import { PaymentRouteEnum } from '../../../../../models/payments';
import routes from '../../../../../routes.path';
import useStyles from './ActionDropDown.styles';
import history from '../../../../../services/history/browserHistory';

interface IProps {
  id: string;
  batchId: string;
  accountId: string;
}

const ActionDropDown: React.FC<IProps> = ({
  id,
  batchId,
  accountId,
}: IProps) => {
  const classes = useStyles();

  const {
    open,
    displayActionButton,
    displayIcon,
    setOpen,
  } = useActionDropdown();

  const handleClose = (): void => {
    setOpen(null);
  };

  const menuItems = [
    {
      id: 'action-funded-payment-view',
      content: (
        <Typography data-testid={`payment-action-option-${batchId}`}>
          {displayActionButton()}
        </Typography>
      ),
      icon: displayIcon(),
      onClick: () => history.push(routes.payments.multientity.batchSummaryRouteFunc(
        accountId,
        id,
        PaymentRouteEnum.ACTIONNEEDED,
      )),
      underline: true,
    },
  ];

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box data-testid={`action-${batchId}`} className={classes.wrapper}>
        <Menu
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={false}
          disableHoverEffect={false}
          onClose={handleClose}
          buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
          buttonTitle=""
          icon={faEllipsisH}
        >
          {menuItems.map((item) => (
            <MenuDropdownItem key={item.id} setOpen={setOpen}>{item}</MenuDropdownItem>
          ))}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
};

export default ActionDropDown;
