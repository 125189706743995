import { Radio } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Status from 'components/Status/Status';
import { User } from '@alpha/profile-dtos';
import AuthyLogo from '../../../assets/authyLogo.svg';
import TwoFactorAuthService from '../../../services/TwoFactorAuth/twoFactorAuth.service';
import { TStore } from '../../../store';
import { TAuthyStatus } from '../../../models/user';
import { actions } from '../../../store/user/user.reducer';
import useStyles from './TwoFactorAuth.styles';
import AuthSetupModal from '../../../components/AuthSetupModal';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';

interface IProps {
  userProfile?: User | undefined
}

const TwoFactorAuth: React.FC<IProps> = (props: IProps) => {
  const { userProfile } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const authy = useSelector<TStore, TAuthyStatus | undefined>(
    (state) => state.user.authyStatus,
  );
  const sb = useAlphaSnackbar();

  const updateMfa = async () => {
    try {
      const response = await TwoFactorAuthService.getAuthyStatus();
      dispatch(actions.updateMfaStatus(response));
    } catch (err) {
      sb.trigger(err.message || 'Could not update MFA status');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateMfa();
  }, []);

  useEffect(() => {
    if (authy) {
      setChecked(authy?.registered);
    }
  }, [authy]);

  return (
    <div>
      <div>
        {displayModal === true && (
          <AuthSetupModal
            open
            phoneNumber={userProfile?.phoneNumber || ''}
            handleModalClose={() => setDisplayModal(false)}
            handleSuccessButtonClick={() => setDisplayModal(false)}
          />
        )}
      </div>
      <div className={styles.container}>
        <h2 className={styles.heading}>Two-factor Authentication</h2>
        <div
          className={checked ? styles.optionEnabled : styles.optionDisabled}
          onClick={!checked ? () => setDisplayModal(true) : undefined}
        >
          <Radio
            disabled
            checked={checked}
            classes={{ root: styles.radio, checked: styles.checked }}
          />
          <div className={styles.authyBackground}>
            <img src={AuthyLogo} alt="authyLogo" className={styles.image} />
          </div>
          <div className={styles.descriptionContainer}>
            <p className={styles.authyHeader}>
              Authy (Recommended)
              <span>
                <Status className={checked ? styles.status : styles.disabled} variant={checked ? 'info' : 'error'}>
                  {checked ? 'ENABLED' : 'NOT ENABLED'}
                </Status>
              </span>
            </p>
            <p className={styles.authyDescription}>
              Download the authy app for fast, secure, offline authentication
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuth;
