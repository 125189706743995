import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    container: {
      maxHeight: 'inherit',
      overflowY: 'scroll',
      paddingRight: 24,
      position: 'relative',
      animation: 'myEffect 10s',
    },
    '@keyframes myEffect': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    scrollBar: {
      '&::-webkit-scrollbar': {
        width: '0.4em',
        borderRadius: 5,
        background: 'white',
        position: 'relative',
        border: '1px solid rgba(0, 0, 0, .2)',

      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        backgroundColor: 'rgba(0, 0, 0, .3)',
        boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
      },
    },
    list: {
      marginBottom: 0,
    },
    entityInformation: {
      fontSize: 15,
      fontWeight: 400,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    unOrderedList: {
      paddingInlineStart: 15,
    },
    alert: {
      marginBottom: 20,
    },
  }),
  { name: 'KYCModal' },
);

export default useStyles;
