import { CurrencyAccountSearchTotalDto } from '@alpha/currency-accounts-dtos';
import { debounce, DebouncedFunc } from 'lodash';
import {
  useCallback, useState, Dispatch, SetStateAction,
} from 'react';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import { TSearchItems, TSearchParams } from '../../../hooks/useSearch';
import { TCurrencySearchResponse } from '../../../models/currencyAccounts';
import SearchService from '../../../services/Search/SearchService';
import SearchServiceMultiEntity from '../../../services/Search/SearchServiceMultiEntity';

export interface ICaSearch {
  items: TSearchItems<CurrencyAccountSearchTotalDto> | undefined;
  skip: number,
  loading: boolean,
  searchText: string,
  handleNewSearch: DebouncedFunc<(searchParams: TSearchParams) => Promise<void>>;
  setItems: Dispatch<SetStateAction<TSearchItems<CurrencyAccountSearchTotalDto>
  | undefined>>;
  setSearchText: Dispatch<SetStateAction<string>>;
  handleInitialSearch: (searchParams: TSearchParams) => Promise<void>;
  handlePreviousPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handleNextPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handleUpdateTableItems: (searchParams: TSearchParams) => Promise<void>;
}

const useCurrencyAccountsSearch = (multiEntity?: boolean) => {
  const [searchText, setSearchText] = useState<string>('');
  const [items, setItems] = useState<TSearchItems<TCurrencySearchResponse>>();
  const [skip, setSkip] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();

  async function fetchItemsFromUrl(searchParams: TSearchParams):
  Promise<TCurrencySearchResponse| null> {
    try {
      setLoading(true);
      if (multiEntity) {
        return await SearchServiceMultiEntity.GetTableData(searchParams);
      }
      return await SearchService.GetTableData(searchParams);
    } catch (e) {
      sb.trigger('error');
    } finally {
      setLoading(false);
    }
    return null;
  }

  const createSearchItemsObj = (
    responseItems: TCurrencySearchResponse,
    searchParams: TSearchParams,
  ): TSearchItems<TCurrencySearchResponse> => {
    const hasPrevious = Boolean(searchParams.queryParams.skip > 0);
    const hasNext = Boolean(
      responseItems
        .totals.totalAccounts - (searchParams.queryParams.skip + searchParams.queryParams.take) > 0,
    );
    return {
      items: responseItems,
      hasPrevious,
      hasNext,
    };
  };
  const handleUpdateTableItems = async (searchParams: TSearchParams): Promise<void> => {
    const responseItems: TCurrencySearchResponse| null = await fetchItemsFromUrl(
      searchParams,
    );

    if (responseItems) {
      const searchItems = createSearchItemsObj(responseItems, searchParams);
      setItems(searchItems);
    }
  };

  const handleNewSearch = useCallback(debounce(
    async (searchParams: TSearchParams): Promise<void> => {
      const newSearchParams = { ...searchParams };
      newSearchParams.queryParams.skip = 0;
      setSkip(0);
      await handleUpdateTableItems(newSearchParams);
    }, 300,
  ), []);

  const handleInitialSearch = async (searchParams: TSearchParams): Promise<void> => {
    const newSearchParams = { ...searchParams };
    newSearchParams.queryParams.skip = 0;
    newSearchParams.queryParams.searchtext = undefined;

    setSearchText('');
    setSkip(0);

    await handleUpdateTableItems(newSearchParams);
  };

  const handlePaginationRequest = async (searchParams: TSearchParams, newSkip: number) => {
    setSkip(newSkip < 0 ? 0 : newSkip);

    const newSearchParams = { ...searchParams };
    newSearchParams.queryParams.skip = newSkip;

    if (searchText) newSearchParams.queryParams.searchtext = searchText;

    await handleUpdateTableItems(newSearchParams);
  };

  const handleNextPage = async (searchParams: TSearchParams,
    skipAmount: number = 10): Promise<void> => {
    const newSkip = skip + skipAmount;
    await handlePaginationRequest(searchParams, newSkip);
  };

  const handlePreviousPage = async (searchParams: TSearchParams,
    skipAmount: number = 10): Promise<void> => {
    const newSkip = skip - skipAmount;

    await handlePaginationRequest(searchParams, newSkip);
  };
  return {
    items,
    skip,
    loading,
    searchText,
    handleNewSearch,
    setItems,
    setSearchText,
    handleInitialSearch,
    handlePreviousPage,
    handleNextPage,
    handleUpdateTableItems,
  };
};
export default useCurrencyAccountsSearch;
