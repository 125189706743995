import { Input } from 'components/Inputs/Input';
import { Typography } from '@mui/material';
import React from 'react';
import useSinglePaymentContext from '../../useSinglePaymentContext';

interface IProps {
  currencyCode: string | undefined;
  validation: string | undefined;
  value: number | undefined;
  handleInputChange: (event: any) => void;
}

const PaymentAmount: React.FC<IProps> = ({
  currencyCode,
  validation,
  value,
  handleInputChange,
}: IProps) => {
  const { singlePaymentContext } = useSinglePaymentContext();
  const numberRegex = /^\d+(\.\d{1,2})?$/;
  const handleBlur = (event: any): void => {
    if (singlePaymentContext) {
      const newCurrentPayment = { ...singlePaymentContext.currentPayment };
      let newValue = event.target.value;
      const testValue = newValue.replaceAll(',', '');
      if (numberRegex.test(testValue)) {
        newValue = parseFloat(testValue).toFixed(2).toString();
        newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      newCurrentPayment.paymentAmount = newValue;
      singlePaymentContext.setCurrentPayment(newCurrentPayment);
    }
  };

  return (
    <>
      <div className="paymentAmount">
        <Typography variant="subtitle1" className="label">
          Payment Amount
        </Typography>
        <Input
          onChange={handleInputChange}
          onBlur={handleBlur}
          id="paymentAmount"
          value={value}
          variant="filled"
          testId="paymentAmountInput"
          placeholder="Payment amount"
          error={Boolean(validation)}
          helperText={validation}
          currency={currencyCode}
          autoComplete="off"
        />
      </div>
    </>
  );
};

export default PaymentAmount;
