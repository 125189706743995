import { throttle } from 'lodash';
import React, { ReactText, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { originalUseSnackbar } from 'components/Snackbar';
import SnackMessage from '../components/SnackMessage/SnackMessage';
import { TStore } from '../store';
import { initiateSynchronousDownload } from '../store/generateReport/actions';
import { TDownloadQueue } from '../store/generateReport/reducer';
import { FileType } from './useReportsPolling';

const useReportsDownload = () => {
  const dispatch = useDispatch();
  const downloadQueue = useSelector<TStore, TDownloadQueue>(
    (store) => store.generatePdf.downloadQueue,
  );
  const [download, setDownload] = useState<{
    id: string,
    downloadLink: string,
    key: ReactText,
    accountId?: string
  }>();
  const { enqueueSnackbar } = originalUseSnackbar();

  useEffect(() => {
    if (download && downloadQueue.findIndex((dl) => dl.id === download.key) === -1) {
      dispatch(
        initiateSynchronousDownload(
          download.id, download.downloadLink, download.key, download.accountId,
        ),
      );
    }
  }, [download?.key]);

  const executeReportGeneration = throttle(async (
    type: FileType,
    id: string,
    downloadLink: string,
    reportMessage: string,
    accountId?:string,
  ) => {
    try {
      enqueueSnackbar(null, {
        autoHideDuration: 60000,
        className: 'dd-privacy-allow',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        content: (key) => {
          setDownload({
            id, downloadLink, key, accountId,
          });
          return (
            <SnackMessage
              reportType={type}
              reportMessage={reportMessage}
              id={key}
            />
          );
        },
      });
    } catch (e) {
      enqueueSnackbar(null, {
        autoHideDuration: 3000,
        className: 'dd-privacy-allow',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        content: (key) => (
          <SnackMessage
            reportType={type}
            reportMessage="error"
            id={key}
            error
          />
        ),
      });
    }
  }, 2000000);

  const getDownloadLinkFromSnackKey = (key?: ReactText): string | undefined => {
    if (!key) return undefined;

    const response = downloadQueue.find((dl) => dl.id === key);
    if (!response) return undefined;

    // Check if the link has expired, if so get a new one
    if (response.downloadTime.diffNow('seconds').toObject().seconds! < -25) {
      setDownload(undefined);

      return undefined;
    }

    return response.downloadLink;
  };

  return {
    executeReportGeneration,
    download,
    getDownloadLinkFromSnackKey,
    setDownload,
  };
};

export default useReportsDownload;
