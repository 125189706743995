import React, { FC } from 'react';
import { faRedoAlt } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import { TStandingOrder } from 'models/payments';
import {
  DefinitionStyled,
  DrawerBodyStyled,
  DrawerSubtitleStyled,
  FrequencyIconStyled,
} from './styles';

type IProps = {
  item: TStandingOrder;
  formatFrequency: (frequency: string, date: string) => string | JSX.Element;
}

const ScheduledTableDrawer: FC<IProps> = ({ item, formatFrequency }) => {
  if (!item) return null;

  const currency = item.fixedSide || item.debitingCurrency;

  return (
    <DrawerBodyStyled>
      <DrawerSubtitleStyled variant="subtitle1">Payment details</DrawerSubtitleStyled>
      <div data-testid="scheduled-drawer-paymentDetails" className="noPadding">
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-paymentid">Payment ID</p>
          <span data-testid="drawer-paymentid-value">{ item.id }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-start-date">Start date</p>
          <span data-testid="drawer-start-date-value">{ moment(item.startDate).format('DD/MM/YYYY') }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-frequency">Frequency</p>
          <span data-testid="drawer-frequency-value">
            <FrequencyIconStyled icon={faRedoAlt} />
            { formatFrequency(item.frequency, item.startDate) }
          </span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-next-date">Next Date</p>
          <span>
            <span data-testid="drawer-next-date-value">{ moment(item.nextFireDate).format('DD/MM/YYYY') }</span>
          </span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-end-date">End date</p>
          <span data-testid="drawer-end-date-value">{ item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : 'Continuous' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-payment-ref">Payment reference</p>
          <span data-testid="drawer-payment-ref-value">{ item.reference || '-' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-purpose-of-payment">Purpose of payment</p>
          <span data-testid="drawer-purpose-of-payment-value">{ item.purpose || '-' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-currency">Currency</p>
          <span data-testid="drawer-currency-value">{ currency }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-ammount-sent">Amount sent</p>
          <span data-testid="drawer-ammount-sent-value">
            { Number(item.amount).toFixed(2) }
            &nbsp;
            { currency }
          </span>
        </DefinitionStyled>
      </div>

      <DrawerSubtitleStyled variant="subtitle1">Beneficiary details</DrawerSubtitleStyled>
      <div data-testid="scheduled-drawer-beneDetails" className="noPadding">
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-bene-name">Beneficiary name</p>
          <span data-testid="drawer-bene-value">{ item.beneficiaryDraft.name || '-' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-bene-bank">Beneficiary bank</p>
          <span data-testid="drawer-bene-bank-value">{ item.beneficiaryDraft.bankName || '-' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-swift">SWIFT/BIC</p>
          <span data-testid="drawer-swift-value">{ item.beneficiaryDraft.swift || '-' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-iban">IBAN</p>
          <span data-testid="drawer-iban-value">{ item.beneficiaryDraft.iban || '-' }</span>
        </DefinitionStyled>
      </div>

      <DrawerSubtitleStyled variant="subtitle1">Paid from</DrawerSubtitleStyled>
      <div data-testid="scheduled-drawer-paidFrom" className="noPadding">
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-funding-acc">Funding account</p>
          <span data-testid="drawer-funding-acc-value">{ item.fundingAccount }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-debiting-acc">Debiting account</p>
          <span data-testid="drawer-debiting-acc-value">{ item.debitingAccount }</span>
        </DefinitionStyled>
      </div>
    </DrawerBodyStyled>
  );
};

export default ScheduledTableDrawer;
