import React, { memo, useEffect } from 'react';
import clsx from 'clsx';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import BeneFraudAcknowledgeModal from 'domain/Beneficiaries/BeneFraudAcknowledgeModal';
import { Flags } from 'react-feature-flags';
import DrawerBackdropLoader from '../../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import useCreateBeneficiary from '../useCreateBeneficiary';
import Footer from './Footer/Index';
import PaymentMethods from './PaymentMethods';
import PersonalDetails from './PersonalDetails';
import useStyles from './styles';

interface IProps {
  handleDrawerClose: () => void;
  multiEntity: boolean;
  accounts?: {
    value: string;
    label: string;
  }[];
}

const Form: React.FC<IProps> = ({
  handleDrawerClose,
  multiEntity,
  accounts,
}: IProps) => {
  const styles = useStyles();
  const {
    form,
    countries,
    currencyList,
    loading,
    error,
    setFormValidation,
    setModalOpen,
    modalOpen,
    handleApproveAFCreateBeneficiary,
    entityGroups,
    acknowledgeModalOpen,
    setAcknowledgeModalOpen,
  } = useCreateBeneficiary(handleDrawerClose, multiEntity);

  useEffect(() => {
    if (accounts?.length === 1) {
      form.setFieldValue('accountIds', accounts);
    }
  }, [accounts]);

  const handleModalConfirmation = () => {
    form.setFieldValue('fraudWarningAcknowledged', true);
    form.handleSubmit();
    setAcknowledgeModalOpen(false);
  };

  return (
    <form
      onSubmit={form.handleSubmit}
      className={clsx(styles.formContainer, 'dd-privacy-mask-user-input')}
    >
      <div className={styles.fieldsContainer}>
        <div className={styles.section} style={{ marginTop: '5px' }}>
          <PersonalDetails
            form={form}
            countries={countries}
            currencyList={currencyList}
            accounts={accounts}
            multiEntity={multiEntity}
            entityGroups={entityGroups}
            disabled={accounts?.length === 1}
          />
        </div>
        <div className={styles.section}>
          <PaymentMethods
            form={form}
            setFormValidation={setFormValidation}
            error={error}
          />
        </div>
      </div>
      <Footer
        isFormValid={Boolean(
          form.isValid
            && (form.values.iban
              || (form.values.accountNumber && form.values.swift)
              || (form.values.accountNumber && form.values.nationalBankCode)),
        )}
        handleDrawerClose={handleDrawerClose}
      />
      <DrawerBackdropLoader
        className={styles.overlayMaxWidth}
        text="Validating your beneficiary"
        display={loading}
        width="80%"
      />
      <DecisionModal
        open={modalOpen}
        title="Validation Failed"
        subtitle="The details you have entered have failed our validation for the below reason. Please check the details with the beneficiary and select ‘Edit details’ to correct them."
        optionalSubtitle1={error}
        optionalSubtitle2="By choosing ‘Continue’ you are accepting that the details entered may be incorrect which could cause delays in your beneficiary receiving funds or our counterparties rejecting the payment completely."
        actionButtonText="CONTINUE"
        handleActionButton={handleApproveAFCreateBeneficiary}
        closeButtonText="No, go back"
        handleClose={() => setModalOpen(false)}
        icon={faExclamationTriangle}
        loading={loading}
      />

      {
        acknowledgeModalOpen
        && (
          <Flags authorizedFlags={['fraudModalEnabled']}>
            <BeneFraudAcknowledgeModal
              handleConfirmation={handleModalConfirmation}
              handleClose={() => setAcknowledgeModalOpen(false)}
              acknowledgeModalOpen={acknowledgeModalOpen}
            />
          </Flags>
        )
      }
    </form>
  );
};

export default memo(Form);
