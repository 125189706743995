import { makeStyles } from 'theme';

const useStyles = makeStyles({
  root: {
    paddingTop: '40px',
    position: 'relative',
    height: '100%',
    minHeight: 'calc(100vh - 460px)',
    marginBottom: '100px',
  },
}, { name: 'body' });

export default useStyles;
