import React, { useEffect } from 'react';
import { List } from 'react-virtualized';
import { TUserAccount } from '../../../../models/user';
import useEntityGroupDrawer from '../useEntityGroupDrawer';
import AccountInGroupCard from './AccountInGroupCard';
import AddAccountDropdownCard from './AddAccountDropdownCard';
import useStyles from './EntityGroupInfoEdit.style';

interface IProps {
  entityGroupId: string;
  accounts: TUserAccount[];
  canEditOrDeleteGroup: boolean;
}

const EntityGroupInfoEditBody: React.FC<IProps> = (props: IProps) => {
  const {
    accounts,
    entityGroupId,
    canEditOrDeleteGroup,
  } = props;

  const classes = useStyles();

  const {
    getAccountsForDropdown,
    userAccounts,
    showAccountDropdown,
    addAccountToGroup,
    toggleAccountsDropdown,
  } = useEntityGroupDrawer();
  useEffect(() => {
    if (showAccountDropdown) {
      getAccountsForDropdown();
    }
  }, [showAccountDropdown]);

  const closeAccountDropdown = () => {
    toggleAccountsDropdown(false);
  };

  return (
    <div className={classes.bodyWrapper}>
      {
        showAccountDropdown && accounts?.length > 0 && (
          <AddAccountDropdownCard
            closeAccountDropdown={closeAccountDropdown}
            addAccountToGroup={addAccountToGroup}
            accounts={userAccounts}
            entityGroupId={entityGroupId}
          />
        )
      }
      {accounts && (
        <List
          width={610}
          height={620}
          rowHeight={128}
          rowCount={accounts.length}
          rowRenderer={({ index, style }) => {
            const account = accounts[index];
            return (
              <div style={style} key={index}>
                <AccountInGroupCard
                  account={account}
                  canEditOrDeleteGroup={canEditOrDeleteGroup}
                  entityGroupId={entityGroupId}
                  numOfAccounts={accounts.length}
                />
              </div>
            );
          }}
        />
      )}

    </div>
  );
};
export default EntityGroupInfoEditBody;
