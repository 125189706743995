import { originalUseSnackbar } from 'components/Snackbar';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { TSignIn } from '../models/auth';
import { TUser } from '../models/user';
import AuthService from '../services/Auth/auth.service';
import useForm from './useForm';
import useHandleLogin from './useHandleLogin';

type TChangePassword = {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
};

const initialValues: TChangePassword = {
  password: '',
  newPassword: '',
  confirmNewPassword: '',
};

const validation = {
  confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), undefined], 'New passwords must match').required(),
};

const useChangePassword = (userInfo: TUser | undefined) => {
  const snackbar = originalUseSnackbar();
  const [changePasswordLoading, setChangePasswordLoading] = useState<boolean>(false);
  const [signInDetails, setSignInDetails] = useState<TSignIn>();
  const formik = useForm<TChangePassword>(
    initialValues,
    validation,
    submitChangePassword,
  );

  const {
    auth,
  } = useHandleLogin();

  useEffect(() => {
    (async () => {
      if (signInDetails) {
        snackbar.enqueueSnackbar('Password changed successfully, redirecting to the login screen', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
          className: 'dd-privacy-allow',
        });
        setTimeout(async () => auth.challengeRedirect(signInDetails, snackbar.closeSnackbar), 300);
      }
    })();
  }, [signInDetails]);

  const handleChangePassword = async (
    password: string,
    newPassword: string,
  ) => {
    try {
      await AuthService.changePassword(password, newPassword);
      if (userInfo) {
        setSignInDetails(await AuthService.signIn(userInfo.Username, newPassword));
      } else {
        snackbar.enqueueSnackbar('Password changed successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
          className: 'dd-privacy-allow',
        });
      }
    } catch (e) {
      const errorMessage = e?.response?.data?.error ? e?.response?.data?.error : e.message;
      snackbar.enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
        className: 'dd-privacy-allow',
      });
    }
  };

  async function submitChangePassword(values: TChangePassword) {
    try {
      setChangePasswordLoading(true);
      await handleChangePassword(
        values.password,
        values.newPassword,
      );
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
        className: 'dd-privacy-allow',
      });
    }
    setChangePasswordLoading(false);
  }

  return {
    formik,
    changePasswordLoading,
  };
};

export default useChangePassword;
