export const encodeData = (data: any) => {
  const presentQueryParams = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(data)) {
    if (value) {
      presentQueryParams.push(`${key}=${value}`);
    }
  }
  return presentQueryParams.join('&');
};

export default encodeData;
