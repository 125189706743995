import { makeStyles } from 'theme';
import { TInputProps } from 'components/Inputs/Input';
import { Typography } from '@mui/material';
import React from 'react';
import SearchInput from '../../Inputs/Search/SearchInput';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '11px',
    paddingBottom: '11px',
    '& .borderBottom': {
      borderBottom: '2px solid rgba(112, 112, 112, 0.1)',
    },
    '& .left': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& .inputWrapper': {
        minWidth: '300px',
      },
      '& .totalItems': {
        display: 'inline-block',
        width: 'max-content',
        whiteSpace: 'nowrap',
        '& > h6': {
          fontWeight: 'bold',
        },
      },
      '& .verticalLine': {
        display: 'block',
        height: '100%',
        border: '1px solid #F7F7F7',
        margin: '0 8px',
      },
    },
  },
}), { name: 'Search' });

type TProps = {
  totalItems?: number
} & TInputProps

const Search: React.FC<TProps> = (props: TProps) => {
  const { totalItems, ...restProps } = props;
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className="left">
        <SearchInput {...restProps} className="inputWrapper" />
        <div className="verticalLine" />
        {(totalItems !== undefined) && (
          <div className="totalItems">
            <Typography className="dd-privacy-allow" variant="subtitle1">
              {totalItems > 0 ? `Showing All ${totalItems} items` : 'No Results'}
            </Typography>
          </div>
        )}
      </div>
      <div />
    </div>
  );
};

export default Search;
