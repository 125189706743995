import React from 'react';
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@mui/material';
import { InvoiceDto } from '@alpha/invoice-dtos';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import Menu from 'components/Menu/Menu';
import useActionDropdown from '../../../../hooks/useActionDropDown';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useStyles from './ActionDropDown.styles';
import useReportsDownload from '../../../../hooks/useReportsDownload';
import { FileType } from '../../../../hooks/useReportsPolling';

interface IProps {
  invoice: InvoiceDto;
}

const ActionDropDown: React.FC<IProps> = ({
  invoice,
}: IProps) => {
  const classes = useStyles();
  const sb = useAlphaSnackbar();
  const { executeReportGeneration } = useReportsDownload();

  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    setOpen,
  } = useActionDropdown();

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    setMenuCloseCount(0);
  };

  const handleDownloadPdfClick = async () => {
    if (invoice.hasPDFGenerated) {
      try {
        if (invoice.invoiceName) {
          const downloadLink = `/invoices/invoices-pdf?invoiceUUID=${invoice.invoiceUUID}&invoiceName=${invoice.invoiceName}`;
          await executeReportGeneration(FileType.PDF, invoice.invoiceUUID, downloadLink, 'Invoice PDF', invoice.accountId);
        }
      } catch {
        sb.trigger('We could not retrieve any pdf');
      }
    }
  };

  const menuItems = [
    {
      id: 'action-invoice-download-pdf',
      content: (
        <Typography data-testid="invoice-download pdf">
          Download PDF
        </Typography>
      ),
      icon: faEye,
      onClick: handleDownloadPdfClick,
      underline: true,
    },
  ];

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box data-testid="action-invoice" className={classes.wrapper}>
        <Menu
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={menuCloseCount === 1}
          onClose={handleClose}
          buttonProps={{
            style: { height: 30, width: 30, minWidth: 0 },
            disabled: !invoice.hasPDFGenerated,
          }}
          buttonTitle="invoice-menu-pdf"
          icon={faEllipsisH}
        >
          {menuItems.map((item) => (
            <MenuDropdownItem key={item.id} setOpen={setOpen}>{item}</MenuDropdownItem>
          ))}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
};

export default ActionDropDown;
