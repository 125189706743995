import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { TUserAccount, UserRole } from 'models/user';
import AuthService from 'services/Auth/auth.service';
import {
  CurrencyPair,
  NationalityPair,
} from '../../../../components/International';
import SearchTable from '../../../../components/Table/SearchTable';
import { TextEllipsis } from '../../../../components/TextEllipsis';
import {
  TSearchBaseResponse,
  TSearchParams,
} from '../../../../hooks/useSearch';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../utils/formatIsoDate';
import { setDateTime } from '../../../../utils/setDateTime';
import ActionDropDown from './ActionDropDown/ActionDropDown';
import useStyles from '../Body.styles';

interface IPendingTable {
  pendingTableData: TSearchBaseResponse | undefined;
  hasNext: boolean;
  hasPrevious: boolean;
  loading: boolean;
  setSelectedBeneficiary: React.Dispatch<React.SetStateAction< BeneficiaryDto| undefined>>;
  handleNextPage: (
    searchParams: TSearchParams,
    skipAmount?: number
  ) => Promise<void>;
  handlePreviousPage: (
    searchParams: TSearchParams,
    skipAmount?: number
  ) => Promise<void>;
}

const PendingTable: React.FC<IPendingTable> = (props: IPendingTable) => {
  const {
    pendingTableData,
    hasPrevious,
    hasNext,
    loading,
    setSelectedBeneficiary,
    handleNextPage,
    handlePreviousPage,
  } = props;

  const styles = useStyles();

  const columns: ITableColumn[] = [
    { header: 'Entity', accessor: 'entity', className: clsx(styles.entity, 'dd-privacy-allow') },
    { header: 'Account Holder', accessor: 'name' },
    { header: 'Friendly Name', accessor: 'friendlyName' },
    { header: 'Country', accessor: 'bankCountryCode', className: 'dd-privacy-allow' },
    { header: 'Currency', accessor: 'currencyCode', className: 'dd-privacy-allow' },
    { header: 'IBAN', accessor: 'iban' },
    { header: 'Account Number', accessor: 'accountNumber' },
    { header: 'Swift', accessor: 'swift' },
    { header: 'Created Date', accessor: 'createdDate', className: 'dd-privacy-allow' },
    { header: '', accessor: 'actionDropDown' },
  ];

  const [beneInputterAccounts, setBeneInputterAccounts] =
    useState<TUserAccount[] | null>(null);

  useEffect(() => {
    const getPerms = async () => {
      const res = await AuthService
        .getEntitiesUserHasRoleFor(UserRole.BENEFICIARY_INPUTTER);
      setBeneInputterAccounts(res);
    }
    getPerms();
  }, []);

  const generateTableData = (
    tableData: TSearchBaseResponse | undefined,
  ) => (tableData?.records as BeneficiaryDto[])?.map((beneficiary) => ({
    ...beneficiary,
    entity: (
      <div>
        <TextEllipsis text={beneficiary.accountName ? beneficiary.accountName : '-'} />
      </div>
    ),
    name: <TextEllipsis maxChars={30} text={beneficiary.name || '-'} />,
    friendlyName: (
      <div style={{ textTransform: 'capitalize' }}>
        {beneficiary.friendlyName || '-'}
      </div>
    ),
    iban: (
      <div>
        {(beneficiary.iban && beneficiary.iban.toUpperCase()) || '-'}
      </div>
    ),
    accountNumber: <div>{beneficiary.accountNumber || '-'}</div>,
    swift: <div>{beneficiary.swift || '-'}</div>,
    bankCountryCode:
        (beneficiary.bankCountryCode && (
          <NationalityPair countryCode={beneficiary.bankCountryCode} />
        ))
        || '-',
    currencyCode:
        (beneficiary.currencyCode && (
          <CurrencyPair currencyCode={beneficiary.currencyCode} />
        ))
        || '-',
    createdDate: formatIsoDate(beneficiary.createdDate),
    uploadedDateTime: setDateTime(beneficiary.createdDate),
    actionDropDown: (
      <ActionDropDown
        beneficiary={beneficiary}
        setSelectedBeneficiary={setSelectedBeneficiary}
        canDelete={!!(beneInputterAccounts
          && beneInputterAccounts.findIndex(
            (UserAccount) => UserAccount.id === beneficiary.accountId,
          ) > -1)}
      />
    ),
  }));

  return (
    <SearchTable
      table={{
        columns,
        data: generateTableData(pendingTableData),
        clickable: false,
      }}
      loading={loading}
      pagination={{
        handleNext: handleNextPage as any,
        handlePrevious: handlePreviousPage as any,
        hasNext,
        hasPrevious,
      }}
      emptyTable={{
        title: 'No Pending Beneficiaries',
        subtitle: 'You currently do not have any pending beneficiaries',
      }}
    />
  );
};

export default PendingTable;
