import { Box } from '@mui/material';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import useDynamoTable from '../../../../hooks/useDynamoTable';
import { TDynamoTableQueryParams } from '../../../../services/DatabaseServices/DynamoService';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../utils/formatIsoDate';
import ActionDropDown from './DraftedTable/ActionDropDown/ActionDropDown';
import DraftedTable from './DraftedTable/DraftedTable';
import PaymentsService from '../../../../services/Payments/payments.service';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';

const initialTableQuery: TDynamoTableQueryParams = {
  url: '/payments/batches/drafted',
};

export interface IDraftedTableData {
  sourceName: string;
  type: JSX.Element;
  noOfPayments: number;
  createdBy: string;
  createdDate: string;
  action: JSX.Element
}

const DraftedTableContainer: React.FC = () => {
  const columns: ITableColumn[] = [
    {
      header: 'File Name',
      accessor: 'sourceName',
      className:  'dd-privacy-allow'
    },
    {
      header: 'Type',
      accessor: 'type',
      className:  'dd-privacy-allow'

    },
    {
      header: 'No of payments',
      accessor: 'noOfPayments',
      className:  'dd-privacy-allow'

    },
    {
      header: 'Created By',
      accessor: 'createdBy',
    },
    {
      header: 'Created Date',
      accessor: 'createdDate',
      className:  'dd-privacy-allow'

    },
    {
      header: 'Action',
      accessor: 'action',
      className:  'dd-privacy-allow'

    },
  ];
  const table = useDynamoTable(initialTableQuery);
  const sb = useAlphaSnackbar();

  const handleDeleteClick = async (batchId: string): Promise<void> => {
    try {
      await PaymentsService.deletePayment(batchId);
      table.rerenderTable();
      sb.trigger('Successfully deleted your payment', 'success');
    } catch {
      sb.trigger('There was an error deleting your payment');
    }
  };

  const createTableData = (datas: any[]) => datas.map((data: any): IDraftedTableData => ({
    sourceName: data.sourceName,
    type: (
      <Box className="type">
        <FontAwesomeIcon icon={faLayerGroup} />
        Batch
      </Box>
    ),
    noOfPayments: data.noOfValidatedPayments,
    createdBy: data.uploadedBy,
    createdDate: formatIsoDate(data.uploadedDate),
    action: (
      <ActionDropDown
        handleDeleteClick={handleDeleteClick}
        batchStatus={data.batchStatus}
        batchId={data.batchId}
      />
    ),
  }));

  return (
    <DraftedTable
      columns={columns}
      tableData={createTableData(table.data || [])}
      table={table}
    />
  );
};

export default memo(DraftedTableContainer);
