import _default, { makeStyles } from 'theme';

const { palette, typography } = _default;

const useStyles = makeStyles(
  () => ({
    permissionNames: {
      fontWeight: typography.fontWeightBold,
      '&.MuiTableCell-body': {
        color: palette.primary.main,
      },
    },
    headerStyles: {
      '&.MuiTableCell-head': {
        fontWeight: typography.fontWeightLight,
        color: palette.text.disabled,
        textTransform: 'capitalize',
      },
    },
  }),
  { name: 'PermissionInfoDrawer' },
);

export default useStyles;
