import {
  Autocomplete, Box, TextField, Typography,
} from '@mui/material';
import { faCheck, faTrash } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useRef, useState } from 'react';
import { IconActionButton } from 'components/Buttons';
import { TUserAccount } from '../../../../models/user';
import useStyles from './EntityGroupInfoEdit.style';
import useListStyles from '../../../../components/AutocompleteDropDown/Listbox.styles';

interface IProps {
  entityGroupId?: string;
  accounts: TUserAccount[];
  addAccountToGroup:((entityGroupId: string, accountId: string) => Promise<void>) | any ;
  closeAccountDropdown: () => void;
}

interface SelectOption {
  id: string,
  label: string,
}

const AddAccountDropdownCard: React.FC<IProps> = (props: IProps) => {
  const [value, setValue] = useState<SelectOption| null>(null);
  const {
    entityGroupId, accounts, addAccountToGroup, closeAccountDropdown,
  } = props;
  const classes = useStyles();
  const { listBox } = useListStyles();
  const handleAddAccountClick = () => {
    if (value && value?.id !== '') {
      addAccountToGroup(entityGroupId, value.id);
    }
    closeAccountDropdown();
  };
  const accountDropdownRef = useRef<HTMLElement>(null);

  useEffect(() => {
    accountDropdownRef.current?.scrollIntoView({
      block: 'end',
      inline: 'nearest',
    });
  }, []);

  return (
    <Box className={classes.addAccountCardWrapper}>
      <Typography variant="subtitle1" className={classes.addAccountCardTitle}>
        Select Entity
      </Typography>
      <div style={{ display: 'flex' }}>
        <Autocomplete
          classes={{ listbox: listBox }}
          ref={accountDropdownRef}
          onChange={(event: any, newValue:SelectOption|null) => { setValue(newValue); }}
          value={value}
          getOptionLabel={(option) => option.label}
          options={accounts.map((account) => (
            {
              id: account.id,
              label: account.name,
            }
          ))}
          renderInput={(params) => (
            <TextField
              className={classes.accountsDropdownContainer}
              variant="filled"
              {...params}
              InputProps={{ ...params.InputProps }}
              inputProps={{ ...params.inputProps, className: classes.accountsDropdown }}
            />
          )}
        />
        <Box className={classes.actionButtons}>
          <IconActionButton
            icon={faTrash}
            rounded
            variant="outlined"
            onClick={closeAccountDropdown}
          />
          <IconActionButton
            disabled={value === null}
            icon={faCheck}
            rounded
            variant="outlined"
            onClick={handleAddAccountClick}
          />
        </Box>

      </div>
    </Box>
  );
};
export default AddAccountDropdownCard;
