import { StyledTabsWrapper } from 'components/Tabs';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../../../../routes.path';
import history from '../../../../services/history/browserHistory';
import useStyles from '../Dashboard.styles';

 interface IProps {
  fundedPaymentsExist?: boolean
}
const Tabs = ({ fundedPaymentsExist }:IProps) => {
  const classes = useStyles();
  const fundedTabElement = (
    <Badge
      overlap="rectangular"
      badgeContent={(
        <FontAwesomeIcon
          icon={faExclamationCircle}
          viewBox="0 100 150 512"
          style={{
            fontSize: 'medium',
            color: 'rgba(255, 152, 0)',
          }}
          className={classes.root}
        />
    )}
    >
      ACTION REQUIRED
    </Badge>

  );
  type TTabTitles = 'PENDING' | 'IN PROGRESS' | 'COMPLETE' | 'DRAFT' | typeof fundedTabElement;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const location = useLocation();

  const tabTitles: TTabTitles[] = ['PENDING', 'IN PROGRESS', 'COMPLETE', 'DRAFT'];

  const tabTitlesFunded : TTabTitles[] = ['PENDING', 'IN PROGRESS', 'COMPLETE', 'DRAFT', fundedTabElement];

  const setTabIndexBasedOnLocation = (): void => {
    if (location.pathname.includes(routes.payments.pending)) {
      setTabIndex(0);
    } else if (location.pathname.includes(routes.payments.inProgress)) {
      setTabIndex(1);
    } else if (location.pathname.includes(routes.payments.complete)) {
      setTabIndex(2);
    } else if (location.pathname.includes(routes.payments.draft)) {
      setTabIndex(3);
    } else if (location.pathname.includes(routes.payments.funded)) {
      setTabIndex(4);
    }
  };

  useEffect(() => setTabIndexBasedOnLocation(), [
    location.pathname,
    setTabIndex,
  ]);

  const handleSetSelectedTabIndex = (selectedTabIndex: number): void => {
    switch (true) {
      case selectedTabIndex === 0:
        return history.push(routes.payments.pending);
      case selectedTabIndex === 1:
        return history.push(routes.payments.inProgress);
      case selectedTabIndex === 2:
        return history.push(routes.payments.complete);
      case selectedTabIndex === 3:
        return history.push(routes.payments.draft);
      case selectedTabIndex === 4:
        return history.push(routes.payments.funded);
      default:
        return history.push(routes.payments.pending);
    }
  };

  return (
    <StyledTabsWrapper
      testId="payments-tabs"
      tabTitles={fundedPaymentsExist ? tabTitlesFunded : tabTitles}
      tabIndex={tabIndex}
      handleChange={(selectedTabIndex: number) => handleSetSelectedTabIndex(selectedTabIndex)}
    />
  );
};

export default Tabs;
