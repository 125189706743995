import { IATDraftResponse } from '@alpha/iat-dtos';
import { ActionButton } from 'components/Buttons';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import useAuthorization from '../../../../hooks/useAuthorization';
import { UserRole } from '../../../../models/user';
import { useStyles } from './Footer.styles';

interface IProps {
  isValid: boolean;
  rate?: number;
  isFxTrade: boolean;
  isBalanceNegative: boolean;
  selectedDraftIAT?: IATDraftResponse;
  isIndicativeRateLoading?: boolean;
}

const Footer: React.FC<IProps> = ({
  isValid, isFxTrade, rate, selectedDraftIAT, isBalanceNegative, isIndicativeRateLoading,
}: IProps) => {
  const fxBooker = useAuthorization([[UserRole.FX_IAT]]).authorized;
  const getCanBookFx = () => {
    if (selectedDraftIAT?.currentUserCanBookFx !== undefined) {
      return selectedDraftIAT?.currentUserCanBookFx;
    }
    return fxBooker;
  };

  const canBookFX = getCanBookFx();
  const styles = useStyles();

  const shouldHideButton = ((selectedDraftIAT?.currentUserCanApprove === false)
    && selectedDraftIAT?.status === 'SUBMITTED')
    || (selectedDraftIAT?.status === 'APPROVED' && isFxTrade && !canBookFX);

  const fieldsAreInvalid = (() => {
    if (isValid) {
      if ((isFxTrade && rate) || !isFxTrade) { // FX trade, need to have a rate
        return false;
      }
    }
    return true;
  })();

  const createButtonText = (): string => {
    if (!selectedDraftIAT) {
      return 'Submit Balance Transfer for approval';
    }

    if (selectedDraftIAT.status === 'APPROVED') {
      if (isFxTrade && canBookFX) {
        // Will take you to another step to book an FX
        return 'Next';
      }
      return 'Complete Balance Transfer';
    }

    const isFinalApprover = selectedDraftIAT.approvals.length
      === selectedDraftIAT.approvalsRequired - 1;

    if (canBookFX && isFxTrade && isFinalApprover
      && selectedDraftIAT.currentUserCanApprove) {
      // When pressed -> Approves Balance Transfer and it will navigate you to another step to book an FX
      return 'Next';
    } if (!selectedDraftIAT.requiresFx && isFinalApprover
      && selectedDraftIAT.currentUserCanApprove) {
      return 'Approve and Submit Transfer';
    }

    return 'Submit Balance Transfer for approval';
  };

  return (
    <Box className={styles.root}>
      <ActionButton
        size="large"
        style={{
          visibility: shouldHideButton ? 'hidden' : 'visible',
        }}
        disabled={
          fieldsAreInvalid
          || isIndicativeRateLoading
          || (selectedDraftIAT && isButtonDisabled(
            selectedDraftIAT?.currentUserCanApprove,
            selectedDraftIAT?.status,
          ))
        }
        fullWidth
        testId="submitButton"
        type="submit"
      >
        {createButtonText()}
      </ActionButton>
    </Box>
  );
};

function isButtonDisabled(
  currentUserCanApprove: boolean | undefined,
  batchStatus: unknown | undefined,
): boolean {
  if (!currentUserCanApprove && batchStatus !== 'APPROVED') return true;
  return false;
}

export default memo(Footer);
