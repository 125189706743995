import { AxiosResponse } from 'axios';
import {
  TBulkReportGenerationPayload,
  TCurrencyResponse,
  TCurrencyTab,
  TReportGenerationPayload,
  TReportGenerationResponse,
  TTransactionsSummary,
} from '../../models/currencyAccounts';
import instance, { instanceAccountId, instanceNoAccountId } from '../Axios/instance';
import multiEntityInstance from '../Axios/multiEntityInstance';

export default class CurrencyAccountsService {
  public static async getCurrencyTabs(multiEntity?: boolean): Promise<TCurrencyTab[]> {
    if (multiEntity) {
      return this.getCurrencyTabsMultiEntity();
    }
    return this.getCurrencyTabsAsync();
  }

  public static async getCurrencyStats(
    currencyCode: string,
  ): Promise<TCurrencyResponse> {
    return this.getCurrencyStatsAsync(currencyCode);
  }

  public static async getAllCurrencyStats(): Promise<TCurrencyResponse> {
    return this.getAllCurrencyStatsAsync();
  }

  public static async getTransactionsSummary(
    currencyAccountId: string,
    startDate: string,
    endDate: string,
    accountId?: string,
  ): Promise<TTransactionsSummary> {
    if (accountId) {
      return this.getTransactionsSummaryWithAccountIdAsync(
        currencyAccountId,
        startDate,
        endDate,
        accountId,
      );
    }
    return this.getTransactionsSummaryAsync(
      currencyAccountId,
      startDate,
      endDate,
    );
  }

  public static async postReportGeneration(
    payload: TReportGenerationPayload,
  ): Promise<string> {
    return (await this.postReportGenerationAsync(payload)).executionArn;
  }

  public static async postBulkReportGeneration(
    payload: TBulkReportGenerationPayload,
  ): Promise<string> {
    return (await this.postBulkReportGenerationAsync(payload)).executionArn;
  }

  public static async postAccountLetterReportGeneration(
    payload: TReportGenerationPayload,
    accountId?: string,
  ): Promise<string> {
    return (await this.postAccountLetterReportGenerationAsync(payload, accountId)).executionArn;
  }

  private static async getCurrencyTabsAsync(): Promise<TCurrencyTab[]> {
    const response: AxiosResponse<TCurrencyTab[]> = await instance.get(
      '/ca/account-currencies/search',
    );
    return response.data;
  }

  private static async getCurrencyTabsMultiEntity(): Promise<TCurrencyTab[]> {
    const response: AxiosResponse<TCurrencyTab[]> = await multiEntityInstance.get(
      '/ca/account-currencies/search',
    );
    return response.data;
  }

  public static async getAccountsCurrencies(): Promise<string[]> {
    const response: AxiosResponse<TCurrencyTab[]> = await multiEntityInstance.get(
      '/ca/account-currencies/search',
      { params: { includeAllAllowedAccounts: true } },
    );
    return response.data.map((x) => x.currencyCode);
  }

  public static async getAccountsCurrenciesByAccountId(accountId: string): Promise<TCurrencyTab[]> {
    const response: AxiosResponse<TCurrencyTab[]> = await instanceAccountId(accountId).get(
      '/ca/account-currencies/search',
    );
    return response.data;
  }

  private static async getCurrencyStatsAsync(
    currencyCode: string,
  ): Promise<TCurrencyResponse> {
    const response: AxiosResponse<TCurrencyResponse> = await instance.get(
      `/ca/currency-accounts?currencyCode=${currencyCode}`,
    );
    return response.data;
  }

  private static async getAllCurrencyStatsAsync(): Promise<TCurrencyResponse> {
    const response: AxiosResponse<TCurrencyResponse> = await instance.get(
      '/ca/currency-accounts/search',
    );
    return response.data;
  }

  private static async getTransactionsSummaryAsync(
    currenctAccountId: string,
    startDate: string,
    endDate: string,
  ): Promise<TTransactionsSummary> {
    const response: AxiosResponse<TTransactionsSummary> = await instance.get(
      `/ca/currency-accounts/${currenctAccountId}/summary?startdate=${startDate}&enddate=${endDate}`,
    );
    return response.data;
  }

  private static async postReportGenerationAsync(
    payload: TReportGenerationPayload,
  ): Promise<TReportGenerationResponse> {
    const {
      type, id, startDate, endDate,
    } = payload;
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `/reports/currency-accounts/${id}/ledgers?type=${type}&startdate=${startDate}&enddate=${endDate}`,
    );
    return response.data;
  }

  private static async postAccountLetterReportGenerationAsync(
    payload: TReportGenerationPayload,
    accountId?: string,
  ): Promise<TReportGenerationResponse> {
    const {
      type, id,
    } = payload;
    const response: AxiosResponse<TReportGenerationResponse> = accountId
      ? await instanceAccountId(accountId).get(
        `/reports/currency-accounts/${id}/account-letter?type=${type}`,
      )
      : await instance.get(
        `/reports/currency-accounts/${id}/account-letter?type=${type}`,
      );
    return response.data;
  }

  private static async getTransactionsSummaryWithAccountIdAsync(
    currencyAccountId: string,
    startDate: string,
    endDate: string,
    accountId: string,
  ): Promise<TTransactionsSummary> {
    const response: AxiosResponse<TTransactionsSummary> = await instanceNoAccountId.get(
      `/ca/currency-accounts/${currencyAccountId}/summary?startdate=${startDate}&enddate=${endDate}`,
      {
        headers: {
          'account-id': accountId,
        },
      },
    );
    return response.data;
  }

  private static async postBulkReportGenerationAsync(
    payload: TBulkReportGenerationPayload,
  ): Promise<TReportGenerationResponse> {
    const {
      type, currencyAccounts, startDate, endDate,
    } = payload;
    const response: AxiosResponse<TReportGenerationResponse> = await instance.post(
      `/reports/currency-accounts/ledgers?type=${type}&startdate=${startDate}&enddate=${endDate}`,
      {
        currencyAccounts,
      },
    );
    return response.data;
  }
}
