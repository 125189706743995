import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#343434',
    },
    wrapper: {
      textAlign: 'center',
      outline: 'none',
    },
    welcomeText: {
      color: '#EAEAEA',
      '& > h2': {
        fontSize: '22px',
        margin: '40px 0 24px 0',
      },
      '& > span': {
        fontWeight: '100',
        fontSize: '14px',
      },
    },
    loader: {
      color: '#EAEAEA',
    },
  }),
  { name: 'WelcomeLoader' },
);

export default useStyles;
