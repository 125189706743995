import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Typography } from '@mui/material';
import { faTrashAlt, faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import Menu from 'components/Menu/Menu';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { iAllowedAccounts } from 'store/accounts/accounts.reducer';
import AuthorizationService from 'services/Authorization/authorization.service';
import { Role } from '@alpha/auth-types';
import { PaymentRouteEnum } from '../../../../../models/payments';
import routes from '../../../../../routes.path';
import history from '../../../../../services/history/browserHistory';

interface IProps {
  batchStatus: string;
  batchId: string;
  accountId: string;
  handleDeleteClick: (batchId: string, accountId:string) => void;
}

const ActionDropDown: React.FC<IProps> = (props: IProps) => {
  const {
    batchStatus,
    batchId,
    accountId,
    handleDeleteClick,
  } = props;

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [isPaymentsInputter, setIsPaymentsInputter] = useState<boolean>();

  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const userAccountsLoading = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.userAccountsLoading,
  );

  useEffect(() => {
    if (!userAccountsLoading && allowedAccounts.length > 0) {
      const checkIfPaymentsInputter = async () => {
        const userAccounts = allowedAccounts.map(
          ({ id }) => id,
        );
        const paymentInputterAccounts = await AuthorizationService.getEntitiesForRole(
          Role.PAYMENTS_INPUTTER,
        );
        const paymentInputterAccountIds = new Set(paymentInputterAccounts.map(({ id }) => id));
        setIsPaymentsInputter(userAccounts.some((element) => paymentInputterAccountIds.has(
          element,
        )));
      };
      checkIfPaymentsInputter();
    }
  }, [userAccountsLoading, allowedAccounts]);

  const getContent = () => {
    if (batchStatus === 'VALIDATED') {
      return isPaymentsInputter ? <Typography>Resume</Typography> : <>View</>;
    }
    return <Typography className="actionDisabled">Resume</Typography>;
  };
  const menuItems = [
    {
      id: 'action-draft-payment-multi-entity-resume',
      content: getContent(),
      icon: faPlayCircle,
      onClick: () => history.push(
        routes.payments.multientity.batchSummaryRouteFunc(
          accountId, batchId, PaymentRouteEnum.DRAFTED,
        ),
      ),
      underline: true,
    },
    {
      id: 'action-draft-payment-multi-entity-delete',
      content: <Typography>Delete</Typography>,
      icon: faTrashAlt,
      onClick: () => handleDeleteClick(batchId, accountId),
    },
  ];

  return (
    <div data-testid={`action-${batchId}`} style={{ marginLeft: 8 }}>
      <Menu
        open={Boolean(open)}
        setOpen={setOpen}
        anchorEl={open}
        buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
        buttonTitle=""
        icon={faEllipsisH}
      >
        {menuItems.map((item) => (
          <MenuDropdownItem key={item.id} setOpen={setOpen}>{item}</MenuDropdownItem>
        ))}
      </Menu>
    </div>

  );
};

export default ActionDropDown;
