import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    widgetContainer: {
      height: '6vw',
    },
    cardRoot: {
      height: '100%',
    },
    card: {
      backgroundColor: 'rgba(9,131,117,0.1)',
      height: '100%',
    },
    cardContent: {
      textAlign: 'center',
    },
    cardIcon: {
      color: '#098375',
    },
    cardText: {
      fontSize: '12',
      fontWeight: 'bold',
      color: '#098375',
    },
  }),
  { name: 'EntryPointWidget' },
);
export default useStyles;
