import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import InterAccountTransferService from '../../../services/InterAccountTransfer/interAccountTransfer.service';
import { TStore } from '../../../store';
import { actions } from '../../../store/outstandingTables/outstandingTables.reducer';

interface IProps {
  modalOpen: boolean;
  selectedDraftIATId: string | undefined;
  accountId?: string | undefined;
  selectedDraftIATSubmittedById: string | undefined;
  handleCloseModal: () => void;
  getAllPendingTransfers?: () => Promise<void>;
}

const RejectIATModal: React.FC<IProps> = (props: IProps) => {
  const {
    modalOpen,
    selectedDraftIATId,
    accountId,
    selectedDraftIATSubmittedById,
    handleCloseModal,
    getAllPendingTransfers,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const username = useSelector<TStore>((store) => store.user.user?.Username);
  const sb = useAlphaSnackbar();
  const dispatch = useDispatch();

  const handleRejectIAT = async () => {
    try {
      setLoading(true);
      if (!selectedDraftIATId || !selectedDraftIATSubmittedById) { throw Error('No IAT selected to reject'); }
      if (!username) throw Error('Username is missing');

      const rejectingOwn = username === selectedDraftIATSubmittedById;

      if (accountId !== undefined) {
        await InterAccountTransferService.rejectTransfer(
          selectedDraftIATId,
          rejectingOwn,
          accountId,
        );
      } else {
        await InterAccountTransferService.rejectTransfer(
          selectedDraftIATId,
          rejectingOwn,
        );
      }

      if (getAllPendingTransfers !== undefined) {
        await getAllPendingTransfers();
      }

      sb.trigger('Transfer rejected', 'info');
    } catch (e) {
      sb.trigger(
        e.response?.data?.error || e.message || 'Error rejecting your transfer',
      );
    } finally {
      handleCloseModal();
      dispatch(
        actions.updateDashboardAction(
          `REJECT_MODAL_CLOSE_${selectedDraftIATId}`,
        ),
      );
      setLoading(false);
    }
  };

  return (
    <DecisionModal
      open={modalOpen}
      title="Are you sure you want to reject this transfer?"
      subtitle=""
      actionButtonText="YES, REJECT TRANSFER"
      handleActionButton={handleRejectIAT}
      closeButtonText="NO, KEEP TRANSFER"
      handleClose={handleCloseModal}
      icon={faExclamationTriangle}
      loading={loading}
    />
  );
};

export default RejectIATModal;
