import { Box, InputLabel } from '@mui/material';
import { Input } from 'components/Inputs/Input';
import React, { memo } from 'react';
import { useStyles } from './Reference.styles';
import useInterAccountTransferFormContext from '../../useInterAccountTransferFormContext';

interface IProps {
  error: boolean | undefined;
  helperText: string | false | undefined;
  sectionComplete: boolean;
  disabled: boolean;
  onBlur: (event: any) => void;
  onChange: (event: any) => void;
}

const Reference: React.FC<IProps> = (props: IProps) => {
  const {
    error,
    helperText,
    sectionComplete,
    disabled,
    onChange,
    onBlur,
  } = props;
  const styles = useStyles();
  const { form } = useInterAccountTransferFormContext();

  if (!sectionComplete) return null;

  return (
    <Box className={styles.root}>
      <hr className={styles.hr} />
      <InputLabel htmlFor="reference">Reference</InputLabel>
      <Input
        id="creditingAccount.reference"
        name="creditingAccount.reference"
        inputProps={{
          maxLength: 256,
        }}
        value={form.values.creditingAccount.reference}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        fullWidth
        autoComplete="off"
        placeholder="Please enter reference for the transfer"
        testId="reference"
        variant="filled"
        disabled={disabled}
      />
    </Box>
  );
};

export default memo(Reference);
