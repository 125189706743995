import { Box } from '@mui/material';
import React, { memo } from 'react';
import { PaymentDraftDto } from '@alpha/payments-dtos';
import formatIsoDate from 'utils/formatIsoDate';
import { formatNumber } from 'utils/currency.helpers';
import APPagination from 'components/APPagination';
import { Table as GenericTable } from '../../../../../components/Table/Table';
import { TSearchParams } from '../../../../../hooks/useSearch';
import { getColumns } from './columns';
import useStyles from './Table.styles';
import { BatchQuoteDtoDecorated } from '../../Funding.interface';

interface IProps {
  data: Array<PaymentDraftDto>;
  lineByLineRatesMap: Record<string, number>;
  hasNext: boolean;
  hasPrevious: boolean;
  handleNextPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handlePreviousPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  liveRates: BatchQuoteDtoDecorated[];
}

const Table: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();
  const {
    data,
    lineByLineRatesMap,
    hasNext,
    hasPrevious,
    handleNextPage,
    handlePreviousPage,
    liveRates,
  } = props;

  const newLiveRates: Record<string, number> = liveRates
    .map((v) => v.paymentRates)
    .reduce((result, current) => Object.assign(result, current), {});

  const tableData = data.map((item: PaymentDraftDto) => {
    const paymentDraft = {
      ...item,
      lineByLineRate: lineByLineRatesMap[item.id],
    } as PaymentDraftDto;

    let result = {
      beneficiaryName: (
        <span>{paymentDraft.beneficiaryDraft.name}</span>
      ),
      paymentRef: (
        <span>{paymentDraft.reference}</span>
      ),
      valueDate: (
        <span>{formatIsoDate(paymentDraft.paymentDate)}</span>
      ),
      sellCurrency: (
        <span>{paymentDraft.fundingCurrency}</span>
      ),
      buyCurrency: (
        <span>{paymentDraft.debitingCurrency}</span>
      ),
      fixedSide: (
        <span>{paymentDraft.fixedSide}</span>
      ),
    };
    if (liveRates.length > 0) {
      result = {
        ...result,
        ...{
          amount: (
            <Box textAlign="justify" fontWeight="bold">
              {paymentDraft.fixedSide === paymentDraft.debitingCurrency
                ? formatNumber(paymentDraft.amount * (1 / newLiveRates[item.id]), 2)
                : formatNumber(paymentDraft.amount, 2)}
              {' '}
              {paymentDraft.fundingCurrency}
            </Box>
          ),
          sendAmount: (
            <Box textAlign="justify" fontWeight="bold">
              {paymentDraft.fixedSide === paymentDraft.debitingCurrency
                ? formatNumber(paymentDraft.amount, 2)
                : formatNumber(paymentDraft.amount * newLiveRates[item.id], 2)}
              {' '}
              {paymentDraft.debitingCurrency}
            </Box>
          ),
          liveRate: (
            <Box textAlign="justify" fontWeight="bold">
              {formatNumber(newLiveRates[item.id], 4)}
              {' | '}
              {formatNumber(1 / newLiveRates[item.id], 4)}
            </Box>
          ),
        },
      };
    } else {
      result = {
        ...result,
        ...{
          amount: (
            <Box textAlign="justify" fontWeight="bold">
              {paymentDraft.fixedSide === paymentDraft.debitingCurrency
                ? formatNumber(paymentDraft.amount * (1 / paymentDraft.lineByLineRate!), 2)
                : formatNumber(paymentDraft.amount, 2)}
              {' '}
              {paymentDraft.fundingCurrency}
            </Box>
          ),
          sendAmount: (
            <Box textAlign="justify" fontWeight="bold">
              {paymentDraft.fixedSide === paymentDraft.debitingCurrency
                ? formatNumber(paymentDraft.amount, 2)
                : formatNumber(paymentDraft.amount * paymentDraft.lineByLineRate!, 2)}
              {' '}
              {paymentDraft.debitingCurrency}
            </Box>
          ),
          indicativeRate: (
            <Box textAlign="justify" fontWeight="bold">
              {formatNumber(paymentDraft.lineByLineRate!, 4)}
              {' | '}
              {formatNumber(1 / paymentDraft.lineByLineRate!, 4)}
            </Box>
          ),
        },
      };
    }
    return (result);
  });

  return (
    <>
      <div className={styles.genericTableWrapper}>
        <GenericTable
          columns={getColumns(liveRates)}
          data={tableData}
        />
      </div>
      <Box className={styles.paginationWrapper}>
        <APPagination
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          handleNext={handleNextPage as any} // need to update props here on sb
          handlePrevious={handlePreviousPage as any} // need to update props here on sb
        />
      </Box>
    </>
  );
};

export default memo(Table);
