import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import cls from 'classnames';
import { Box } from '@mui/material';
import { useStyles } from '../ChoosePayment.styles';

let dragCounter = 0; // avoid blinking on draging

interface IDragAndDrop {
  onDropHandler: (file: File) => void;
  onClick?: () => void;
  className?: string;
  'data-testid': string;
  children?: any;
}

const DragAndDrop: FC<IDragAndDrop> = (props: IDragAndDrop) => {
  const classes = useStyles();
  const [isDragEnter, setDragEnter] = useState(false);

  useEffect(() => {
    dragCounter = 0;
  }, []);

  const onDragEnter = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragCounter++;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDragEnter(true);
      }
    },
    [setDragEnter],
  );

  const onDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onDragLeave = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragCounter--;
      if (dragCounter > 0) return;
      setDragEnter(false);
    },
    [setDragEnter],
  );

  const onDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragEnter(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        props.onDropHandler(file);

        e.dataTransfer.clearData();
        dragCounter = 0;
      }
    },
    [props.onDropHandler],
  );

  return (
    <Box
      className={cls(props.className, isDragEnter ? classes.chooseFileIsDragEnter : '')}
      data-testid={props['data-testid']}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={props.onClick}
    >
      {props.children}
    </Box>
  );
};

export default DragAndDrop;
