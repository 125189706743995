import { ManualPaymentRequest } from '@alpha/payments-dtos';
import { useDispatch } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { Buffer } from 'buffer';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import { TCurrencyAccount } from '../../../../../models/currencyAccounts';
import { TPayment } from '../../../../../models/payments';
import routes from '../../../../../routes.path';
import browserHistory from '../../../../../services/history/browserHistory';
import PaymentsService from '../../../../../services/Payments/payments.service';
import { actions } from '../../../../../store/payments/payments.reducer';
import { IFooterProps } from './Footer';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import useAuthorizationMultiEntity from '../../../../../hooks/useAuthorizationMultiEntity';
import { Frequency } from '../ManualPayment/PaymentFields/StandingOrder/constants';
import usePaymentsContext from '../ManualPayment/usePaymentsContext';

type TempManualPaymentRequest = ManualPaymentRequest & {
  beneficiaryFriendlyName?: string;
  documents?: File[];
  startDate?: string;
  endDate?: string;
  frequency?: string;
  numberOfPayments?: string;
  isStandingOrder?: boolean;
}

const mapManualPaymentToValidateManualPayment = (payments:
  TPayment, selectedDebitingAccount: TCurrencyAccount): TempManualPaymentRequest => ({
    id: crypto.randomUUID(),
    purposeOfPayment: payments.purposeOfPaymentCode,
    reference: payments.reference,
    fundingAccount: selectedDebitingAccount.id,
    fundingCurrency: selectedDebitingAccount.currencyCode,
    debitingAccount: payments.debitingAccountId || selectedDebitingAccount.id,
    debitingCurrency: payments.debitingAccountCurrencyCode || selectedDebitingAccount.currencyCode!,
    fixedSide: payments.fixedSide,
    amount: payments.paymentAmount,
    paymentDate: formatIsoDate(payments.date),
    beneficiaryName: payments.beneficiary!.name!,
    beneficiaryAddress: payments.beneficiary!.residentAddress!,
    beneficiaryBankCountry: payments.beneficiary!.bankCountryCode!,
    beneficiaryFriendlyName: payments.beneficiary!.friendlyName || undefined,
    beneficiaryType: payments.beneficiary?.type,
    iban: payments.beneficiary?.iban || '',
    accountNumber: payments.beneficiary?.accountNumber,
    swift: payments.beneficiary?.swift || '',
    nationalBankCode: payments.beneficiary?.nationalBankCode || '',
    beneficiaryCountry: payments.beneficiary?.beneficiaryCountryCode,
    correspondentAccount: payments.beneficiary?.correspondentAccountNumber || undefined,
    correspondentSwift: payments.beneficiary?.correspondentSwift || undefined,
    furtherToAccountNumber: payments.beneficiary?.furtherToAccountNumber || undefined,
    furtherToSwift: payments.beneficiary?.furtherToSwift || undefined,
    beneficiaryIsExisting: payments.beneficiary?.isExisting,
    userIgnoresApplyFinancialErrors: payments.beneficiary?.userIgnoresApplyFinancialErrors || false,
    channel: 'portal',
    documents: payments.files || undefined,
    paymentRail: payments.paymentRail,
    startDate: formatIsoDate(payments.date),
    endDate: payments.endDate || undefined,
    frequency: payments.frequency || Frequency.ONCE,
    numberOfPayments: payments.numberOfPayments || undefined,
    isStandingOrder: payments.isStandingOrder,
    beneInputterFraudWarningAcknowledgment:
      payments.beneficiary?.beneInputterFraudWarningAcknowledgment,
  });

const useFooter = ({
  selectedDebitingAccount,
  currentPayments,
  setLoading,
  accountId,
}: IFooterProps) => {
  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();
  const { getRolesForAccount } = useAuthorizationMultiEntity();
  const handleExitClick = () => {
    browserHistory.push(routes.payments.multientity.base);
  };
  const { paymentsContext } = usePaymentsContext();

  const generateBatchId = async (): Promise<string | void> => {
    try {
      const { batchId } = await PaymentsService.postGenerateBatchId(accountId);
      return batchId;
    } catch {
      return sb.trigger('There was an error generating your batch id');
    }
  };

  const createPayloadObject = (): TempManualPaymentRequest[] => {
    try {
      const payments = [...currentPayments];
      return payments.map((payment: TPayment) => {
        if (!payment.beneficiary || !selectedDebitingAccount) throw Error();
        return mapManualPaymentToValidateManualPayment(
          {
            ...payment,
            isStandingOrder: paymentsContext.isStandingOrder,
            files: paymentsContext.isStandingOrder ? [] : payment.files,
          },
          selectedDebitingAccount,
        );
      });
    } catch {
      sb.trigger('There was an error generating your payment payload');
    }

    return [];
  };

  const submitPaymentForValidation = async (
    batchId: string, flattenedPayments: TempManualPaymentRequest[],
  ) => {
    const submitManualPaymentStatus = await PaymentsService.postValidateManualPayment(
      batchId, { payments: [...flattenedPayments] }, accountId,
    );

    if (!`${submitManualPaymentStatus}`.startsWith('20')) throw Error();
  };

  const submitPaymentSupportingFiles = async (payments: TempManualPaymentRequest[]) => {
    const paymentsWithFiles = payments.filter(
      (x) => x.documents !== undefined && x.documents.length > 0,
    );

    const paymentFiles = paymentsWithFiles.map(
      (payment) => ({ paymentId: payment.id, files: payment.documents! }),
    );

    if (!paymentFiles || paymentFiles.length === 0) return;

    await PaymentsService.submitTMDocForPayment(
      paymentFiles,
      accountId!,
    );
  };

  const handleSubmitPayment = async () => {
    if (accountId) {
      try {
        setLoading(true);
        dispatch(actions.clear());
        const batchId = await generateBatchId();
        if (!batchId) return;

        dispatch(actions.updateBatchId(batchId));
        dispatch(actions.updateAccountId(accountId));
        const flattenedPayments = createPayloadObject();
        const isFraudAcknowledged = flattenedPayments
          .some((x) => x.beneInputterFraudWarningAcknowledgment === true);
        dispatch(actions.updateFraudFlagValue(isFraudAcknowledged));

        await submitPaymentForValidation(batchId, flattenedPayments);
        try {
          await submitPaymentSupportingFiles(flattenedPayments);
        } catch (e) {
          sb.trigger('Payment submission still in progress but some documents have failed to upload. We may contact you if we need more information.', 'warning', 15000);
          datadogRum.addError(e, {
            source: 'PaymentsMultiEntity.ManualPayments.Body.Footer.useFooter.tsx',
            message: 'Error submitting payment supporting files',
            tags: ['submitPaymentSupportingFiles'],
          });
          console.error(e);
        }
        dispatch(actions.triggerBatchStatus());
      } catch (e) {
        sb.trigger('There was an issue submitting your payment for validation');
        dispatch(actions.clear());
        setLoading(false);
        const keySets: string[][] = [];
        currentPayments.forEach((payment) => {
          keySets.push(Object.keys(payment));
        });
        datadogRum.addError(e, {
          source: 'PaymentsMultiEntity.ManualPayments.Body.Footer.useFooter.tsx',
          message: 'Error submitting payments for validation:',
          numberOfPayments: currentPayments.length,
          sizeInBytes: Buffer.byteLength(JSON.stringify([...currentPayments])),
          payloadKeySets: keySets,
          tags: ['submitPaymentForValidation'],
        });
      }
    } else {
      sb.trigger('No entity to select payment from selected');
    }
  };

  return {
    handleExitClick,
    handleSubmitPayment,
    getRolesForAccount,
  };
};

export default useFooter;
