import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    mainLayout: {
      '& > div:first-child': {
        height: '195px',
      },
    },
  }),
  { name: 'OutstandingActions' },
);

export default useStyles;
