import _default, { makeStyles } from 'theme';

const { palette } = _default;

const useStyles = makeStyles(
  () => ({
    popper: {
      '& > .MuiTooltip-tooltip': {
        padding: '2rem 3rem',
        backgroundColor: '#1F1F1F',
        minWidth: 'calc(600px - 6rem)',
        maxWidth: 'calc(600px - 6rem)',
      },
      '& .MuiTooltip-arrow': {
        color: '#1F1F1F',
      },
    },
    tooltipContent: {
      textAlign: 'left',
      '& h3': {
        margin: '0 0 15px 0',
        fontSize: '1.1rem',
      },
      '& table': {
        fontSize: '1rem',
        width: '100%',
        borderCollapse: 'collapse',
        '& thead': {
          color: '#FFFFFF50',
          borderBottom: '1px solid #FFFFFF20',
          '& th': {
            paddingBottom: 7,
          },
        },
        '& td:nth-child(1)': {
          fontWeight: 'bold',
          color: palette.primary.main,
        },
        '& td:nth-child(2), & th:nth-child(2)': {
          width: '1%',
          whiteSpace: 'nowrap',
        },
        '& td': {
          paddingTop: 7,
        },
      },
    },
    tooltipTitle: {
      color: palette.primary.main,
      fontWeight: 'bold',
      textDecoration: 'underline',
      cursor: 'default',
    },
    approvalLevelSelect: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      '& .MuiInputBase-formControl': {
        backgroundColor: '#F7F7F7',
        borderRadius: 5,
        outline: 'none',
        border: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline, & .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
      '& .Mui-error': {
        transition: 'all 0.3s',
        border: `1px solid ${palette.error.main}`,
        color: palette.error.main,
        backgroundColor: `${palette.error.main}20`,
      },
    },
    approvalLevelTitle: {
      '& p': {
        margin: '0 0 5px 0',
      },
    },
  }),
  { name: 'ApproverLevelSelector' },
);

export default useStyles;
