import React, { useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useSelector } from 'react-redux';
import BookFxFooter from './BookFxFooter/BookFxFooter';
import history from '../../../../../services/history/browserHistory';
import routes from '../../../../../routes.path';
import { PaymentRouteEnum } from '../../../../../models/payments';
import PaymentsService from '../../../../../services/Payments/payments.service';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import { TStore } from '../../../../../store';
import navigateToStartOfJourney from '../../../../../utils/navigateToStartOfJourney';

interface IProps {
  batchId: string;
  accountId: string;
  batchName: string;
  userApprover: boolean;
  userApproverOwn: boolean;
  uploadedById?: string;
}

const BookFxFooterContainer: React.FC<IProps> = ({
  batchId, batchName, uploadedById, accountId, userApprover, userApproverOwn,
}: IProps) => {
  const handleBookFxClick = () => {
    history.push(routes.payments.multientity.batchSummaryRouteFunc(
      accountId, batchId, PaymentRouteEnum.FUNDING,
    ));
  };
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const userId = useSelector<TStore, string | undefined>(
    (store) => store.user.user?.Username,
  );

  const handleRejectBatchButton = () => setModalOpen(true);

  const handleRejectPayment = async () => {
    setLoading(true);
    try {
      await PaymentsService.postRejectPayment(batchId, userId, uploadedById, accountId);
      setModalOpen(false);
      datadogRum.addAction('SUCCESSFULLY REJECTED PAYMENT');
      sb.trigger('Successfully rejected your payment', 'info');
      navigateToStartOfJourney();
    } catch {
      sb.trigger(
        'Something went wrong rejecting your payment, please try again',
      );
    } finally {
      setLoading(false);
    }
  };
  const userCanReject = userId === uploadedById ? userApproverOwn : userApprover;

  return (
    <BookFxFooter
      batchName={batchName}
      modalOpen={modalOpen}
      userCanReject={userCanReject}
      handleClose={() => (modalOpen ? setModalOpen(false) : null)}
      handleRejectBatchButton={handleRejectBatchButton}
      handleRejectPayment={handleRejectPayment}
      handleBookFxClick={handleBookFxClick}
      loading={loading}
    />
  );
};

export default BookFxFooterContainer;
