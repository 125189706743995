import clsx from 'clsx';
import { motion } from 'framer-motion';
import React from 'react';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import useStyles from './index.styles';

interface StepperProps {
  steps: string[],
  activeStep: number,
  error: number | false,
}

const CustomStepper = ({ steps, activeStep, error }: StepperProps) => {
  const {
    step, container, active, progressContainer, progressBar, circle, pastStep, errorIcon,
  } = useStyles();
  const returnPosition = () => `${activeStep * (100 / (steps.length - 1))}%`;

  return (
    <>
      <Box sx={{ width: steps.length > 2 ? '70%' : '50%' }} data-testid="progress-container" className={progressContainer}>
        <motion.div
          data-testid="progress-bar"
          initial={{ width: 0 }}
          animate={{ width: returnPosition() }}
          transition={{ stiffness: 100 }}
          className={progressBar}
        >
          <div className={circle} />
        </motion.div>
      </Box>
      <div className={container}>
        {steps.map((label: string, idx: number) => (
          <div
            data-passed={idx < activeStep}
            data-active={idx === activeStep}
            data-testid={`label-${idx}`}
            className={clsx({
              [step]: true,
              [active]: idx === activeStep,
              [pastStep]: idx < activeStep,
            })}
            key={label}
          >
            <h3>
              {` ${idx + 1}. `}
              {label}
              {error === idx && (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                data-testid={`erroricon-${idx}`}
                className={errorIcon}
              />
              )}
            </h3>
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomStepper;
