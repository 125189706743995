import { useDispatch, useSelector } from 'react-redux';
import { FeatureFlag } from '../models/featureFlag';
import { TStore } from '../store';

const useGetFeatureFlags = () => {
  const dispatch = useDispatch();
  const flags = useSelector<TStore, FeatureFlag[] | undefined>(
    (state: TStore) => state.featureFlags.flags,
  );
  const requestGetFeatureFlags = () => dispatch({ type: 'GET_FEATURE_FLAGS_REQUEST' });
  return {
    requestGetFeatureFlags,
    flags,
  };
};

export default useGetFeatureFlags;
