import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    downloadButton: {
      minWidth: 40,
      height: 40,
    },
    columnDownloadButton: {
      width: '50px',
      margin: 'auto',
      textAlign: 'center',
    },
    downloadIcon: {
      fontSize: 14,
    },
    drawerButton: {
      fontWeight: typography.fontWeightSemiBold,
      fontSize: 14,
      padding: 0,
      color: 'rgb(9, 131, 117)',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    genericTableWrapper: {
      minHeight: '280px',
      position: 'relative',
    },
    paginationWrapper: {
      display: 'block',
      textAlign: 'right',
      marginBottom: '60px',
    },
  }),
  { name: 'PaymentSummaryTable' },
);

export default useStyles;
