import { merge, cloneDeep } from 'lodash';
import { useTheme as useThemeMUI, createTheme as createMuiTheme } from '@mui/material/styles';
import makeStylesMUI from '@mui/styles/makeStyles';

import { ITheme } from './theme.interfaces';
import { theme as defaultTheme, RecursivePartial } from './themes/default';

export const createTheme = (newTheme?: RecursivePartial<ITheme>): ITheme => {
  if (newTheme) {
    return createMuiTheme(merge(cloneDeep(defaultTheme), newTheme) as ITheme) as unknown as ITheme;
  }

  return createMuiTheme(defaultTheme as ITheme) as unknown as ITheme;
};

// Will be removed once we migrate all styles to Styled
export function makeStyles(style, options) {
  return makeStylesMUI<ITheme>(style, options);
}
export const useTheme = () => useThemeMUI<ITheme>();
export { default as ThemeProvider } from './Provider';

export default createTheme();

export * from './theme.interfaces';
