import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@mui/styled-engine';
import { userManagementState } from '../../../../../store/userManagement/userManagement.reducer';
import { TStore } from '../../../../../store';
import StyledButton from '../index.styles';
import { useUserManagementContext } from '../../../context';
import { ModalTypeEnum, UserTables } from '../../../context/index.interfaces';

const StyledCreateNewUserButton = styled(StyledButton)({
  marginLeft: 10,
});

const CreateNewUserButton = () => {
  const {
    isEntityManager,
  } = useSelector<TStore, userManagementState>(
    (state) => state.userManagementPage,
  );

  const { openModal, setRefreshDataTable } = useUserManagementContext();

  const submitCallback = async (callback: () => Promise<any>) => {
    await callback();
    setRefreshDataTable(UserTables.PENDING_APPROVAL);
  };

  if (!isEntityManager) return null;

  return (
    <StyledCreateNewUserButton
      variant="contained"
      color="primary"
      disableElevation
      type="button"
      onClick={() => openModal({ submitCallback, modalType: ModalTypeEnum?.CREATE_USER })}
    >
      Create New User
    </StyledCreateNewUserButton>
  );
};

export default CreateNewUserButton;
