import {
  Backdrop, Box, Fade, Modal, Typography,
} from '@mui/material';
import React, { memo, useState } from 'react';
import AlphaWhite from '../../../../assets/alphaWhite.svg';
import BackdropLoader from '../../../Molecules/Loaders/BackdropLoader/BackdropLoader';
import useStyles from './index.styles';

interface IProps {
  name?: string,
  completedOnboarding?: boolean,
}

const WelcomeLoader: React.FC<IProps> = ({ name, completedOnboarding }: IProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(true);
  const [displayWelcome, setDisplayWelcome] = useState<boolean>(Boolean(completedOnboarding));

  setTimeout(() => {
    setDisplayWelcome(true);
  }, 3000);

  setTimeout(() => {
    setOpen(false);
  }, 8000);

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#343434',
        position: 'absolute',
      }}
      open={open}
      aria-labelledby="onboarding-modal"
      aria-describedby="onboarding-modal"
      slots={{ backdrop: Backdrop }}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={{ textAlign: 'center', outline: 'none !important' }}>
          {
            displayWelcome
              ? (
                <Fade in={displayWelcome} timeout={{ enter: 5000, exit: 500 }}>
                  <Box>
                    <img src={AlphaWhite} alt="Alpha logo in white" />
                    <Typography sx={{ fontSize: '22px', margin: '40px 0 24px 0', color: '#EAEAEA' }} variant="h2">
                      {completedOnboarding && name ? `Thank you for completing onboarding, ${name}` : 'Welcome to Alpha'}
                    </Typography>
                    <Typography sx={{ fontWeight: '100', fontSize: '14px', color: '#EAEAEA' }} variant="subtitle1">
                      {completedOnboarding && name ? 'Logging you in now'
                        : 'We are just going to get your account set up in a few quick steps'}
                    </Typography>
                  </Box>
                </Fade>
              )
              : (
                <BackdropLoader
                  testId="backdrop-loader"
                  thickness={5}
                  size={60}
                  className={classes.loader}
                />
              )
          }
        </Box>
      </Fade>
    </Modal>
  );
};

export default memo(WelcomeLoader);
