import React, { memo } from 'react';
import { useStyles } from './NationalityPair.styles';
import clsx from 'clsx';
import { Flag } from 'components/Flag';

interface IProps {
  countryCode: string;
}

const NationalityPair: React.FC<IProps> = (props: IProps) => {
  const { countryCode } = props;
  const classes = useStyles();

  return (
    <span className={clsx(classes.root, 'dd-privacy-allow')}>
      <Flag size="sm" rounded code={countryCode} className={classes.tableIcon} />
      <span className={classes.tableCode}>{countryCode}</span>
    </span>
  );
};

export default memo(NationalityPair);
