import moment from 'moment';
import { repeatOptions } from '../../ManualPayment/PaymentFields/StandingOrder/constants';

export const formatFrequency = (frequency: string, date: string) => {
  const opt = repeatOptions.find((item) => item.name === frequency);

  if (!opt) return frequency;

  let formattedFrequency = opt.label;

  if (opt.addDay) {
    formattedFrequency += ` - On ${moment(date).format('dddd')}s`;
  } else if (opt.addDate) {
    formattedFrequency += ` - On the ${moment(date).format('Do')}`;
  } else if (opt.addDateAndMonth) {
    formattedFrequency += ` - On ${moment(date).format('MMM Do')}`;
  }

  return formattedFrequency;
};
