import { Box } from '@mui/material';
import React from 'react';
import { TUserAccount, TUserAccounts } from '../../../../../models/user';
import Account from '../Account/Account';
import useStyles from './Body.styles';

interface IProps {
  accounts: TUserAccounts;
  filterAccounts: string;
  setFilterAccounts: (val: string) => void;
  onClose: (event: any) => void;
}

const Body: React.FC<IProps> = (props: IProps) => {
  const {
    accounts, onClose, filterAccounts, setFilterAccounts,
  } = props;
  const classes = useStyles();
  return (
    <Box className={classes.bodyWrapper}>
      <Box className={classes.noOfAccounts}>
        Number of accounts:
        {' '}
        <b>{accounts.length}</b>
      </Box>
      {accounts.map((account: TUserAccount) => (
        <Account
          key={account.id}
          setFilterAccounts={setFilterAccounts}
          onClose={onClose}
          filterAccounts={filterAccounts}
          account={account}
        />
      ))}
    </Box>
  );
};

export default Body;
