import { TShade } from './theme.interfaces';

const SHADES = [
  '#fff', // 0
  '#d6d6d6', // 1
  '#c2c2c2', // 2
  '#aeaeae', // 3
  '#9a9a9a', // 4
  '#868686', // 5
  '#727272', // 6
  '#5e5e5e', // 7
  '#4a4a4a', // 8
  '#343434', // 9
];

const shade = (color: string, chosenShade: TShade): string => SHADES[chosenShade] || color;

export default shade;
