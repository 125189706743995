import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import useFormStyles from '../../form.styles';
import browserHistory from '../../../../services/history/browserHistory';
import routes from '../../../../routes.path';

const Success = () => {
  const formClasses = useFormStyles();
  const redirectUserToDashboard = () => setInterval(() => {
    browserHistory.push(routes.auth.login);
  }, 4000);

  useEffect(() => {
    const interval = redirectUserToDashboard();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Typography className={formClasses.title}>
        Password successfully reset
      </Typography>
      <Typography className={formClasses.subtitle}>
        Redirecting you back to the login page. You can now log in with your new credentials.
      </Typography>
    </div>
  );
};

export default Success;
