import React, { useContext, useEffect, useState } from 'react';
import {
  Autocomplete, Box, FormControl, TextField, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Flags } from 'react-feature-flags';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Status from 'components/Status/Status';
import KycModal from 'domain/OutstandingActionsDashboard/KYCModal/KycModal';
import useBannerRefresh from 'hooks/useBannerRefresh';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import { useStyles } from '../ChoosePayment.styles';
import DragAndDrop from '../DragAndDrop/DragAndDrop';
import { CHOOSE_PAYMENT_TEST_IDS } from '../ChoosePayment';
import uploadIcon from '../../../../../assets/upload_icon.svg';
import PaymentsService from '../../../../../services/Payments/payments.service';
import { actions } from '../../../../../store/payments/payments.reducer';
import { TUserAccount } from '../../../../../models/user';
import { TStore } from '../../../../../store';
import { PaymentContext } from '../../../PaymentContext';
import { AutocompleteEvent } from '../../../PaymentsMultiEntity';

export const availableExt = ['csv', 'xls', 'xlsx', 'xml'];

interface IProps {
  accounts: TUserAccount[];
}

export const FileUpload: React.FC<IProps> = (props: IProps) => {
  const { accounts } = props;

  const classes = useStyles();
  const inputRef = React.createRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const paymentContext = useContext(PaymentContext);
  const [open, setOpen] = useState<boolean>(false);

  const [entitySearchValue, setEntitySearchValue] = React.useState('');
  const { requestGetFeatureFlags, flags } = useGetFeatureFlags();
  const [freezePayments, setFreezePayments] = useState(false);

  const checkFrozenPaymentFlag = () => {
    if (flags) {
      flags.forEach((flag) => {
        flag.name === 'freezePaymentsOnFrozenEntities' && flag.isActive ? setFreezePayments(true) : null;
      });
    }
  };

  const selectedRedux = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const {
    frozen,
    overdue,
    due,
  } = useBannerRefresh();
  const snackbar = useAlphaSnackbar();

  const uploadFileToS3 = async (file: File, selectedEntityId: string) => {
    try {
      // set batchStatus to 'REQUESTED' before BE does - to show loading screen
      dispatch(actions.updateBatchStatus('REQUESTED'));
      const { batchId, url } = await PaymentsService.getPresignedUrl(
        file.name, selectedEntityId,
      );
      dispatch(actions.updateBatchId(batchId));
      dispatch(actions.updateAccountId(selectedEntityId));

      await PaymentsService.putPaymentFile(url, file);

      //  Start the polling now
      dispatch(actions.triggerBatchStatus());
    } catch (e) {
      snackbar.trigger(
        `There was an error uploading your file. (${e.message})`,
      );
      // set batchStatus back to 'undefined' in case of failure
      // clears batchStatus state so that user can reupload a new batch on 'Create Payments' drawer
      dispatch(actions.updateBatchStatus(undefined));
    }
  };

  const sendFileHandler = async (file: File) => {
    if (paymentContext?.selectedEntity?.id) {
      const fileArray = file.name.split('.');
      // Validate
      if (availableExt.includes(fileArray[fileArray.length - 1].toLowerCase())) {
        await uploadFileToS3(file, paymentContext.selectedEntity.id);
      } else {
        snackbar.trigger(
          `The chosen file type is not one that is supported: ${availableExt.join(
            ', ',
          )}.`,
        );
      }
    }
  };

  useEffect(() => {
    if (selectedRedux.length === 1 && paymentContext.setSelectedEntity && accounts) {
      const preSelectedEntity = accounts.find(
        (account) => account.id === selectedRedux[0].id,
      );
      if (preSelectedEntity) {
        paymentContext.setSelectedEntity(preSelectedEntity);
      }
    }
    requestGetFeatureFlags();
    checkFrozenPaymentFlag();
  }, [accounts]);

  const inputHandlerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      sendFileHandler(e.target.files[0]);
    }
  };

  return (
    <DragAndDrop
      className={classes.file}
      data-testid={CHOOSE_PAYMENT_TEST_IDS.fileUploadSection}
      onDropHandler={sendFileHandler}
    >
      <div className="fileContent">
        <Typography variant="h6" className={classes.title}>
          File Upload
        </Typography>
        <Typography variant="subtitle2" className={classes.subtitle}>
          Upload a batch of payment instructions to be processed in one go.
          You can only upload payments for one entity at a time.
        </Typography>
      </div>

      <Box className={classes.stepOneContainer}>
        <b>Step 1:</b>
        {' '}
        <p className={classes.textStyle}>Select Entity</p>
        <FormControl fullWidth>
          <Autocomplete
            className={classes.dropdownContainer}
            value={paymentContext.selectedEntity}
            onChange={(_event: AutocompleteEvent, newValue: TUserAccount | null) => {
              paymentContext?.setSelectedEntity?.(newValue || undefined);
            }}
            inputValue={entitySearchValue}
            getOptionDisabled={(option) => (!!option.dateFrozenForKycRefresh && freezePayments)}
            onInputChange={(_event: AutocompleteEvent, newInputValue: string) => {
              setEntitySearchValue(newInputValue);
            }}
            options={accounts}
            getOptionLabel={({ name }) => name}
            renderInput={(params) => (
              <TextField {...params} placeholder="Search Entity" className={classes.dropdown} InputProps={{ ...params.InputProps }} />
            )}
            renderOption={(renderProps, option) => (
              <div style={{ position: 'relative' }} key={option.id}>
                <div className={classes.accountMenuOption} {...renderProps} style={{ maxWidth: '70%' }}>
                  {option.name}
                </div>
                { option.dateFrozenForKycRefresh && freezePayments && (
                <Status className={classes.frozenStatus} onClick={setOpen} size="small" variant="error">
                  Frozen
                  <FontAwesomeIcon
                    size="sm"
                    className={classes.questionIcon}
                    icon={faQuestionCircle}
                  />
                </Status>
                )}
              </div>
            )}
          />
          <KycModal
            due={due}
            overdue={overdue}
            frozen={frozen}
            modalOpen={open}
            onClose={setOpen}
          />
        </FormControl>
      </Box>

      { paymentContext.selectedEntity
      && (!paymentContext.selectedEntity.dateFrozenForKycRefresh || !freezePayments) && (
      <>
        <b>Step 2:</b>
        {' '}
        <p className={classes.textStyle}>Upload payment file</p>
        <div className={classes.dragnDropBox}>
          <div className="middleContent">
            <img src={uploadIcon} alt="upload-icon" />
            <Typography variant="h6">Drag & Drop</Typography>
            <Box component="p">
              Or click
              {' '}
              <button onClick={() => { inputRef.current?.click(); }} className={classes.browseButton} type="button">browse</button>
              {' '}
              files
            </Box>

          </div>
          <Box className={classes.endContent}>
            <Box className={classes.download}>
              <Link to="/static/files/ALPHA_PAYMENTS_TEMPLATE.xlsx" target="_blank" download>Download Template</Link>
            </Box>
            <Box component="span" className={classes.fileTypeTitle}>
              Supported file types
            </Box>
            <Box component="span" className={classes.fileType}>
              .csv
            </Box>
            <Box component="span" className={classes.fileType}>
              .xls
            </Box>
            <Box component="span" className={classes.fileType}>
              .xlsx
            </Box>
            <Flags authorizedFlags={['paymentXmlUpload']}>
              <Box component="span" className={classes.fileType}>
                .xml
              </Box>
            </Flags>
          </Box>
        </div>
        <Flags
          authorizedFlags={['paymentXmlUpload']}
          renderOn={() => (
            <input
              data-testid="input"
              ref={inputRef}
              type="file"
              name="file"
              accept=".csv, .xlsx, .xml"
              hidden
              onChange={inputHandlerChange}
            />
          )}
          renderOff={() => (
            <input
              data-testid="input"
              ref={inputRef}
              type="file"
              name="file"
              accept=".csv, .xlsx,"
              hidden
              onChange={inputHandlerChange}
            />
          )}
        />
      </>
      )}
    </DragAndDrop>
  );
};

export default FileUpload;
