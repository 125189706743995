import cls from 'classnames';
import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';

import { ILoaderProps } from './Loader.interfaces';

import { StyledLoader } from './Loader.styles';

export const Loader: FC<ILoaderProps> = (props) => {
  const {
    className = '',
    withOverlay,
    testId = 'loader',
    ...rest
  } = props;

  const composedClassName = cls('loader-root', className);

  return (
    <StyledLoader data-testid={testId} className={composedClassName} withOverlay={withOverlay}>
      <CircularProgress color="inherit" {...rest} />
    </StyledLoader>
  );
};
