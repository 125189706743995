import { makeStyles } from 'theme';
import styled from '@mui/styled-engine';
import { IconButton } from 'components/Buttons';

const useStyles = makeStyles(
  () => ({
    root: {
      borderRadius: 10,
      height: '100%',
      backgroundColor: '#e3f4f1',
      display: 'flex',
      position: 'relative',

    },
    img: {
      background: "url('/static/images/userGuide.png')",
      backgroundSize: 'contain',
      backgroundPositionX: 'right',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      opacity: 0.05,
    },
    content: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: 25,
      paddingRight: 0,
      paddingLeft: 40,
      flexWrap: 'wrap',
    },
    header: {
      fontSize: 16,
      fontWeight: 500,
    },
    subHeader: {
      color: '#999999',
      fontWeight: 500,
    },
    title: {
      color: '#098375',
      fontWeight: 900,
    },
    playIcon: {
      paddingRight: 10,
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      minWidth: 200,

    },
    button: {
      margin: 10,
      padding: 10,
      paddingRight: 15,
      paddingLeft: 15,
    },
  }),
  { name: 'UserGuidesCard' },

);

export const StyledIconButton = styled(IconButton)({
  height: 25,
  width: 25,
  fontSize: '1.2rem',
  marginRight: 14,
  marginTop: 10,
});

export default useStyles;
