import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import {
  StyledBody, StyledButton, StyledDrawer, StyledHeader,
} from './SimplifiedDrawer.styles';
import { ISimplifiedDrawer } from './SimplifiedDrawer.interfaces';

const SimplifiedDrawer: React.FC<ISimplifiedDrawer> = ({
  headerContent,
  children,
  noPadding,
  onClose,
  ...rest
}: ISimplifiedDrawer) => (
  <StyledDrawer {...rest}>
    <StyledHeader>
      <StyledButton
        onClick={(e) => onClose && onClose(e, 'escapeKeyDown')}
        type="button"
      >
        <FontAwesomeIcon icon={faTimes} />
      </StyledButton>
      <div data-testid="simplified-drawer-header-content">{headerContent}</div>
    </StyledHeader>
    <StyledBody data-testid="simplified-drawer-body" noPadding={noPadding}>{children}</StyledBody>
  </StyledDrawer>
);

export default SimplifiedDrawer;
