import { VerificationMethod } from '@alpha/payments-dtos/lib/batchVerification/VerificationMethod';
import { makeStyles } from 'theme';
import { Typography } from '@mui/material';
import React from 'react';
import { AuthyPageStates, TAuthyType } from '../../../../../../store/authy/reducer';
import useAuthy from '../../../../../../hooks/useAuthy';

interface IProps {
    type?: TAuthyType;
    pageState: AuthyPageStates;
    classes: Record<string, string>;
    handleSetPageState: (_pageState: AuthyPageStates) => void;
    triggerMfa?: (
        mfaOptionChosen?: string | undefined,
         verificationMethod?: VerificationMethod | undefined
         ) => Promise<void>;
}

const useStyles = makeStyles(
  () => ({
    paymentsFooter: {
      '& > h3': {
        color: 'white',
        marginBottom: '16px',
      },
      '& h3': {
        textDecoration: 'none',
        fontWeight: 700,
      },
    },
  }),
  { name: 'Footer' },
);

const Footer: React.FC<IProps> = ({
  pageState,
  type,
  classes,
  handleSetPageState,
  triggerMfa,
}: IProps) => {
  const styles = useStyles();
  const { clearAuthy } = useAuthy();
  const handleHelperTextClick = (): void => {
    if (pageState === AuthyPageStates.OPTIONS) {
      clearAuthy();
    } else {
      handleSetPageState(AuthyPageStates.OPTIONS);
    }
  };

  if ((type === 'PAYMENTS' || type === 'PAYMENT_APPROVE') && triggerMfa) {
    const handleLeftActionButtonClick = async () => {
      if (pageState === AuthyPageStates.SMS) {
        await sendSMS();
        return;
      }

      await resendPushNotification();
    };

    const handleRightActionButtonClick = async () => {
      if (pageState === AuthyPageStates.AUTHY) {
        await sendSMS();
        return;
      }
      await resendPushNotification();
    };

    const sendSMS = async () => {
      await triggerMfa(undefined, VerificationMethod.SMS);
    };

    const resendPushNotification = async () => {
      await triggerMfa(undefined, VerificationMethod.PUSH_NOTIFICATION);
    };

    return (
      <div className={styles.paymentsFooter}>
        <Typography style={{ color: 'white' }} variant="h3" className={classes.cta}>
          OneTouch not available?
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h3" className={classes.cta} onClick={handleLeftActionButtonClick}>
            { pageState === AuthyPageStates.SMS ? 'Resend SMS' : 'Resend Push Notification' }
          </Typography>
          <Typography
            variant="h3"
            className={classes.cta}
            onClick={handleRightActionButtonClick}
          >
            { pageState === AuthyPageStates.SMS ? 'Send Authy' : 'Send SMS'}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <Typography variant="h3" className={classes.cta} onClick={handleHelperTextClick!}>
      {pageState !== AuthyPageStates.OPTIONS ? 'Haven\'t received your code?' : 'Go back'}
    </Typography>
  );
};

export default Footer;
