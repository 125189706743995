import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import { AccountBeneficiaryPortalStatus } from '@alpha/bene-types';
import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import React, { useMemo, useState } from 'react';
import BeneFraudAcknowledgeModal from 'domain/Beneficiaries/BeneFraudAcknowledgeModal';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { iAllowedAccounts } from 'store/accounts/accounts.reducer';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { Flags } from 'react-feature-flags';
import useBeneficiary from '../../../../../hooks/useBeneficiary';
import { ModalType } from '../../Body';
import ActionButtons from './ActionButtons/ActionButtons';
import useStyles from './Body.styles';
import Details from './Details/Details';

interface IBodyProps {
  selectedBeneficiary?: BeneficiaryDto;
  selectedTabIndex: number;
  setModalOpen: React.Dispatch<React.SetStateAction<ModalType | false>>
  userCanApprove: boolean;
  userCanApproveOwn: boolean;
}
const Body: React.FC<IBodyProps> = ({
  selectedBeneficiary,
  selectedTabIndex,
  setModalOpen,
  userCanApprove,
  userCanApproveOwn,
}: IBodyProps) => {
  const classes = useStyles();
  const { approveBeneficiary } = useBeneficiary();
  const [acknowledgeModalOpen, setFraudAcknowledgeModalOpen] = useState(false);
  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const { flags } = useGetFeatureFlags();
  const isFraudModalEnabled = useMemo(() => (
    flags?.find((flag) => flag.name === 'fraudModalEnabled' && flag.isActive)
  ), [flags]);

  const handleModalConfirmation = () => {
    if (selectedBeneficiary) {
      approveBeneficiary({
        batchId: selectedBeneficiary.beneficiaryId,
        accountBeneficiaryIds: [selectedBeneficiary?.accountBeneId],
        type: 'BENEFICIARY',
        accountId: selectedBeneficiary.accountId,
        fraudWarningAcknowledged: true,
      });
    }
    setFraudAcknowledgeModalOpen(false);
  };

  const initiateApproveBeneficiary = (beneficiaryId: string, accountBeneficiaryIds: any,
    accountId: any) => {
    const accountIsMicroEnterprise = allowedAccounts
      .some((acc) => acc.id === accountId && acc.isMicroEnterprise === true);

    if (accountIsMicroEnterprise && isFraudModalEnabled) {
      setFraudAcknowledgeModalOpen(true);
    } else {
      approveBeneficiary({
        batchId: beneficiaryId,
        accountBeneficiaryIds,
        type: 'BENEFICIARY',
        accountId,
        fraudWarningAcknowledged: false,
      });
    }
  };

  return (
    <div className={classes.drawerBody}>
      <BaseDrawer.Body>
        <Details
          selectedTabIndex={selectedTabIndex}
          selectedBeneficiary={selectedBeneficiary}
        />
      </BaseDrawer.Body>
      {selectedBeneficiary?.portalStatus === AccountBeneficiaryPortalStatus.PENDING
        && (
          <div className={classes.drawerFooter}>
            <ActionButtons
              setModalOpen={setModalOpen}
              selectedBeneficiary={selectedBeneficiary}
              initiateApproveBeneficiary={() => {
                initiateApproveBeneficiary(selectedBeneficiary.beneficiaryId,
                  [selectedBeneficiary.accountBeneId],
                  selectedBeneficiary.accountId);
              }}
              userCanApprove={userCanApprove}
              userCanApproveOwn={userCanApproveOwn}
            />
          </div>
        )}
      {
        acknowledgeModalOpen
        && (
          <Flags authorizedFlags={['fraudModalEnabled']}>
            <BeneFraudAcknowledgeModal
              handleConfirmation={handleModalConfirmation}
              handleClose={() => setFraudAcknowledgeModalOpen(false)}
              acknowledgeModalOpen={acknowledgeModalOpen}
            />
          </Flags>
        )
      }
    </div>
  );
};

export default Body;
