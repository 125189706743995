import { Typography, Box } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import { TCurrencyAccount } from '../../../../models/currencyAccounts';
import { formatNumber } from '../../../../utils/currency.helpers';

import { useStyles } from './RenderValue.styles';
import FlagImage from 'components/Flag/FlagImage';

interface IProps {
  selectedCurrencyAccount: TCurrencyAccount;
  hideBalance?: boolean;
}
const RenderValue: React.FC<IProps> = ({
  selectedCurrencyAccount,
  hideBalance,
}: IProps) => {
  const styles = useStyles();

  const {
    friendlyName, reference, iban, accountNumber, availableBalance,
  } = selectedCurrencyAccount;
  const currencyCode = selectedCurrencyAccount.currencyCode!;

  const currencySymbol = getSymbolFromCurrency(currencyCode!);
  const formatedAvailableBalance = formatNumber(+availableBalance, 2);
  const currencySymbolAndFormattedBalance = `${currencySymbol}${formatedAvailableBalance}`;

  return (
    <Box className={styles.root}>
      <Box className={styles.imageHolder}>
        <FlagImage currencyCode={currencyCode} />
      </Box>
      <Typography variant="subtitle1" className={styles.friendlyName}>
        {friendlyName || iban || accountNumber || reference}
      </Typography>
      {!hideBalance && (
        <>
          <Box className={styles.verticalLine} />
          <Typography variant="subtitle1" className={styles.availableBalance}>
            {currencySymbolAndFormattedBalance}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default RenderValue;
