import { AxiosResponse } from 'axios';
import instance from '../Axios/multiEntityInstance';

import { TSearchParams } from '../../hooks/useSearch';
import { instanceNoAccountId } from '../Axios/instance';

class SearchService {
  /**
     * GetTableData - retrieves table data based on the
     * url provided including the query strings which consist of the search criteria
     */
  public static async GetTableData<T>(searchParams: TSearchParams, accountId?: string): Promise<T> {
    let response: AxiosResponse<T>;
    const request = {
      method: searchParams.method || 'GET',
      url: searchParams.baseUrl,
      params: { ...searchParams.queryParams },
      data: searchParams.body,
    };
    if (accountId) {
      response = await instanceNoAccountId({
        ...request,
        headers: {
          'account-id': accountId,
        },
      });
    } else {
      response = await instance(request);
    }

    return response.data;
  }
}

export default SearchService;
