import React from 'react';
import { TabsProps } from '@mui/material/Tabs';
import StyledTab from './Tabs.styles';

interface ITabs extends TabsProps {}

const TabsWrapper = (props:ITabs) => {
  const { children, ...rest } = props;

  return (
    <StyledTab {...rest}>
      {children}
    </StyledTab>
  );
};

export default TabsWrapper;
