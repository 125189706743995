import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  popper: {
    '& > .MuiTooltip-tooltip': {
      padding: '20px 30px',
      marginTop: 8,
      backgroundColor: '#1F1F1F',
      minWidth: 480,
      maxWidth: 490,
    },
    '& .MuiTooltip-arrow': {
      color: '#1F1F1F',
    },
  },
  title: {
    fontSize: '1rem',
    marginTop: 0,
  },
  hoverBase: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    color: '#2680EB',
    margin: 5,
    gap: 5,
    cursor: 'default',
    fontSize: '1rem',
    width: 'fit-content',
    '& p': {
      margin: 0,
      color: '#D6D6D6',
    },
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    fontSize: '0.9rem',
    '&:first-of-type': {
      borderBottom: '1px solid rgba(112, 112, 112, .34)',
      color: '#FAFAFA50',
      paddingBottom: 10,
    },
    '&:nth-last-of-type(2)': {
      paddingTop: 10,
    },
    '&:last-of-type': {
      paddingTop: 10,
    },
  },
  arrowIcon: {
    top: 5,
    bottom: 10,
    position: 'relative',
    color: '#E8E8E8',
    fontSize: 10,
  },
  dataRow: {
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  col1: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: 13,
    paddingBottom: 3,
    '& *': {
      margin: 0,
      color: '#FFFFFF',
      paddingBottom: 3,
    },
    '& p': {
      fontWeight: 'light',
      opacity: 0.7,
    },
  },
  col2: {
    flex: 0.2,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  col3: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: 13,
    opacity: 1,
    '& *': {
      color: '#098375',
      margin: 0,
      paddingBottom: 3,
    },
    '& p': {
      fontWeight: 'light',
      opacity: 0.75,
    },
  },
}), { name: 'PermissionChangesTooltip' });

export default useStyles;
