import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  header: {
    letterSpacing: -0.5,
    lineHeight: '26px',
    marginBottom: 30,
  },
  form: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  debitingValuesContainer: {
    marginBottom: '35px',
  },
  creditingValuesContainer: {
    marginTop: '50px',
  },
}), { name: 'InterAccountTransfer' });

export default useStyles;
