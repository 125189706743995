import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';

import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { TStore } from '../../../../../../store';
import { ModalType } from '../../../Body';
import useStyles from './ActionButtons.styles';
import ApproveButton from './ApproveButton/ApproveButton';
import RejectButton from './RejectButton/RejectButton';

interface IActionButtonsProps {
  selectedBeneficiary: BeneficiaryDto;
  initiateApproveBeneficiary: (
    own: boolean,
    batchId?: string,
  ) => void;
  setModalOpen: React.Dispatch<React.SetStateAction<ModalType | false>>;
  userCanApprove: boolean;
  userCanApproveOwn: boolean;
}
const ActionButtons: React.FC<IActionButtonsProps> = ({
  selectedBeneficiary,
  initiateApproveBeneficiary,
  setModalOpen,
  userCanApprove,
  userCanApproveOwn,
}: IActionButtonsProps) => {
  const classes = useStyles();

  const approverButton = () => {
    if (
      userCanApproveOwn
      && selectedBeneficiary.uploadedBy === user?.Name
    ) {
      return (
        <>
          <RejectButton setModalOpen={setModalOpen} />
          <ApproveButton
            initiateApproveBeneficiary={initiateApproveBeneficiary}
            own
          />
        </>
      );
    }
    if (
      userCanApprove
      && selectedBeneficiary.uploadedBy !== user?.Name
    ) {
      return (
        <>
          <RejectButton setModalOpen={setModalOpen} />
          <ApproveButton
            initiateApproveBeneficiary={initiateApproveBeneficiary}
            own={false}
          />
        </>
      );
    }
    return <></>;
  };

  let user = useSelector((state: TStore) => state.user.user);
  return (
    <Box className={classes.buttonContainer}>
      {approverButton()}
    </Box>
  );
};

export default ActionButtons;
