import { styled } from '@mui/material';

const StyledUserManagementContainer = styled('div')({
  '& .fade-up-appear, & .fade-up-enter': {
    opacity: 0,
    transform: 'translateY(50px)',
  },
  '& .fade-up-appear-active, & .fade-up-enter-active': {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'opacity 500ms, transform 500ms',
  },
  '& .fade-up-appear-done, & .fade-up-enter-done': {
    opacity: 1,
    transform: 'translateY(0)',
  },
  '& .fade-up-exit': {
    opacity: 1,
  },
  '& .fade-up-exit-active': {
    opacity: 0,
    transform: 'translateY(50px)',
    transition: 'opacity 500ms, transform 500ms',
  },
  '& .fade-up-exit-done': {
    opacity: 0,
    transform: 'translateY(50px)',
  },
});

export default StyledUserManagementContainer;
