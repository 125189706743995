import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { faCheck, faPencil, faTimes } from '@fortawesome/pro-light-svg-icons';
import { Input } from 'components/Inputs/Input';
import { ActionButton, IconButton } from 'components/Buttons';
import useStyles from './EntityGroupInfoEdit.style';
import useEntityGroupDrawer from '../useEntityGroupDrawer';
import useEditEntityGroup from './useEditEntityGroup';

interface IProps {
  groupId: string;
  groupName: string;
  groupColour: string;
  groupEntityIds: string[];
  numberOfEntities: number;
  canEditOrDeleteGroup?: boolean;
  handleClickBack: React.Dispatch<React.SetStateAction<void>>;
}

const EntityGroupInfoEditHeader: React.FC<IProps> = (props: IProps) => {
  const {
    groupId,
    groupColour,
    groupName,
    groupEntityIds,
    numberOfEntities,
    handleClickBack,
    canEditOrDeleteGroup,
  } = props;

  const [isEditingGroupName, setIsEditingGroupName] = useState<boolean>(false);
  const [newGroupName, setNewGroupName] = useState<string>(groupName);
  const [errorText, setErrorText] = useState<string>();

  const { toggleAccountsDropdown, showAccountDropdown } = useEntityGroupDrawer();
  const { editGroupName } = useEditEntityGroup();

  const handleDeclineEditGroupName = () => {
    setIsEditingGroupName(false);
    setNewGroupName(groupName);
    setErrorText('');
  };

  const handleAcceptEditGroupName = async () => {
    if (groupName !== newGroupName && !errorText) {
      const isError = await editGroupName(groupId, groupEntityIds, newGroupName);
      // reset newGroupName back to initial if update failed
      if (isError) {
        setNewGroupName(groupName);
      }
    }
    setIsEditingGroupName(false);
  };

  const handleCheckInput = (userInput: string) => {
    if (userInput.length < 1 || userInput.length > 30) {
      setErrorText('Name must be between 0 and 30 characters long');
    } else if (!RegExp('^[/A-Za-z0-9.,/?:()+‘ -]*$').test(userInput)) {
      setErrorText('Special characters are not allowed');
    } else {
      setErrorText('');
    }
    setNewGroupName(userInput);
  };

  const classes = useStyles({ groupColour });
  return (
    <div>
      <Box className={classes.headerWrapper}>
        <div className={classes.headerTitle}>
          <IconButton
            onClick={() => handleClickBack()}
            className={classes.drawerGoBackButton}
            icon={faArrowLeft}
          />
          <FontAwesomeIcon
            className={classes.groupColourIcon}
            icon={faSquare}
          />
          {canEditOrDeleteGroup && isEditingGroupName ? (
            <div className={classes.entityGroupNameInput}>
              <Input
                InputProps={{
                  className: classes.entityGroupNameInput,
                }}
                id="entityGroupName"
                testId="input-entity-group-name"
                fullWidth
                variant="standard"
                value={newGroupName}
                onChange={(event) => handleCheckInput(event.target.value)}
                error={Boolean(errorText)}
                helperText={errorText}
              />
              {!errorText && (
                <FontAwesomeIcon
                  className={classes.editGroupNameAccept}
                  icon={faCheck}
                  onClick={handleAcceptEditGroupName}
                />
              )}
              <FontAwesomeIcon
                className={classes.editGroupNameDecline}
                icon={faTimes}
                onClick={handleDeclineEditGroupName}
              />
            </div>
          ) : (
            <Typography variant="h2">{newGroupName}</Typography>
          )}
          {canEditOrDeleteGroup && !isEditingGroupName && (
            <FontAwesomeIcon
              className={classes.editGroupNameButton}
              icon={faPencil}
              onClick={() => setIsEditingGroupName(true)}
            />
          )}
        </div>
        <div>
          <Box className={classes.headerSubtitle}>
            <Typography variant="subtitle1">Entities</Typography>
          </Box>
        </div>
        <div className={classes.headerSubTitle2}>
          <Typography>{numberOfEntities}</Typography>
          {canEditOrDeleteGroup && (
            <ActionButton
              disabled={showAccountDropdown}
              onClick={() => toggleAccountsDropdown(true)}
              rounded
            >
              Add Entity to Group
            </ActionButton>
          )}
        </div>
      </Box>
    </div>
  );
};
export default EntityGroupInfoEditHeader;
