import { Beneficiary } from '@alpha/bene-dtos';
import { Box, Button } from '@mui/material';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StyledGenericTable from 'components/Table/StyledGenericTable';
import {
  TPayment,
} from '../../../../../../../models/payments';
import PaymentsService from '../../../../../../../services/Payments/payments.service';
import { TStore } from '../../../../../../../store';
import { actions, TPaymentsStore } from '../../../../../../../store/payments/payments.reducer';
import { formatCurrency } from '../../../../../../../utils/currency.helpers';
import ErrorRow from './ErrorRow/ErrorRow';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const columns = [
  {
    header: <Box paddingRight="80px">Beneficiary</Box>,
    accessor: 'beneficiary',
  },
  {
    header: <Box textAlign="left">Debit Account</Box>,
    accessor: 'debitAccount',
  },
  {
    header: <Box textAlign="left">Puropse Of Payment</Box>,
    accessor: 'purposeOfPayment',
  },
  {
    header: <Box textAlign="left">Payment Ref</Box>,
    accessor: 'paymentRef',
  },

  {
    header: <Box textAlign="right">Amount</Box>,
    accessor: 'amount',
  },
  {
    header: <Box textAlign="right" />,
    accessor: 'action',
  },
];

interface IProps {
  payments: TPayment[];
  handleSetCurrentPayments: (_currentPayments: TPayment[]) => void;
  handleSetCurrentPayment: (_currentPayment: TPayment, index: number) => void;
  handleSetSelectedBeneficiary: (
    beneficiary: Beneficiary | undefined
  ) => Promise<void>;
}

const PaymentsTable: React.FC<IProps> = ({
  payments,
  handleSetCurrentPayments,
  handleSetCurrentPayment,
  handleSetSelectedBeneficiary,
}) => {
  const paymentStore = useSelector<TStore, TPaymentsStore>((store: TStore) => store.payments);
  const [batchErrors, setBatchErrors] = useState<any[]>();
  const dispatch = useDispatch();

  const removeErrorFromState = (index: number): void => {
    if (!batchErrors) return;
    const newErrors = batchErrors.filter((_, i) => i !== index);
    if (!newErrors.length) dispatch(actions.clear());
    setBatchErrors(newErrors);
  };

  const handleDeletePayment = (index: number) => {
    const newCurrentPayments = [...payments];
    newCurrentPayments.splice(index, 1);
    const errors = getErrorsIndex(index);
    if (errors >= 0) {
      removeErrorFromState(index);
    }
    handleSetCurrentPayments(newCurrentPayments);
  };

  const getBatchErrors = async () => {
    if (!paymentStore.batchDetails?.batchId) return;
    const response = await PaymentsService.getBatchInvalid(paymentStore.batchDetails?.batchId);
    setBatchErrors(response);
  };

  useEffect(() => {
    if (paymentStore.batchDetails?.totalInvalidPayments) {
      getBatchErrors();
    }
  }, [paymentStore.batchDetails]);

  const getErrorsForRow = (tableIndex: number): string[] => {
    if (!batchErrors) return [];
    const errorRow = batchErrors.filter((batchError: any) => (+batchError.batchLineNumber - 1) === tableIndex);
    if (!errorRow.length) return [];
    return errorRow[0].errors;
  };

  const getErrorsIndex = (tableIndex: number): number => {
    if (!batchErrors) return -1;
    return batchErrors?.findIndex((batchError) => (+batchError.batchLineNumber - 1) === tableIndex);
  };

  const data = payments.map((payment, index) => ({
    beneficiary: (
      <Button
        onClick={async () => await handleSetSelectedBeneficiary(payment.beneficiary)}
        variant="text"
        color="primary"
      >
        <b>{payment.beneficiary?.name}</b>
      </Button>
    ),
    debitAccount: '1234',
    purposeOfPayment: payment.purposeOfPaymentCode,
    paymentRef: payment.reference || '-',
    amount: (
      <Box textAlign="right">
        <b>
          {formatCurrency(
            payment.fixedSide || payment.beneficiary?.currencyCode,
            payment.paymentAmount,
          )}
        </b>
      </Box>
    ),
    action: (
      <Box
        textAlign="center"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {batchErrors
          && paymentStore.batchDetails?.totalInvalidPayments
          && (
            <ErrorRow errors={getErrorsForRow(index)} />
          )}
        <div>
          <Button onClick={() => {
            handleSetCurrentPayment(payment, index);
          }}
          >
            <FontAwesomeIcon icon={faEdit as IconDefinition} />
          </Button>
          <Button onClick={() => handleDeletePayment(index)}>
            <FontAwesomeIcon icon={faTrash as IconDefinition} />
          </Button>
        </div>
      </Box>
    ),
  }));

  return (
    <div>
      <StyledGenericTable
        testId="genericTable"
        columns={columns}
        data={data}
        handleTableRowClick={() => { }}
      />
    </div>
  );
};

export default PaymentsTable;
