import { Box, Typography } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import {
  StyledCloseButton, StyledDrawer, StyledHeader, StyledLineBreak,
} from './BaseDrawer.styles';
import { IBodyProps, IDrawerProps, IHeaderProps } from './BaseDrawer.interfaces';

const Drawer: React.FC<IDrawerProps> = (props: IDrawerProps) => {
  const { onClose, children, ...rest } = props;
  return (
    <StyledDrawer {...rest}>
      {onClose && (
      <StyledCloseButton
        data-testid="drawer-close-button"
        onClick={(e) => onClose && onClose(e, 'escapeKeyDown')}
        type="button"
      >
        <FontAwesomeIcon size="2x" color="#C3C3C3" icon={faTimes} />
      </StyledCloseButton>
      )}
      {children}
    </StyledDrawer>
  );
};

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const { headerTitle, headerSubtitle } = props;
  return (
    <StyledHeader>
      <Box>
        <Typography variant="h2">{headerTitle}</Typography>
      </Box>
      {headerSubtitle && (
      <Box>
        <Typography variant="caption">{headerSubtitle}</Typography>
      </Box>
      )}
    </StyledHeader>
  );
};

export const Body: React.FC<IBodyProps> = (props: IBodyProps) => {
  const { className, children } = props;
  return (
    <Box padding="0 53px" className={className}>
      {children}
    </Box>
  );
};

export const LineBreak: React.FC = () => <StyledLineBreak />;

export const BaseDrawer = {
  Drawer,
  Header,
  Body,
  LineBreak,
};
