import { FormControlLabel } from '@mui/material';
import React from 'react';
import useStyles from './index.styles';
import InputFormField from '../InputFormField';

interface IFieldNames {
  [key:string]: {
    title: string,
    type: string
  }
}

export const fieldNames: IFieldNames = {
  firstName: {
    title: 'First name',
    type: 'text',
  },
  lastName: {
    title: 'Last name',
    type: 'text',
  },
  phone: {
    title: 'Landline phone number',
    type: 'direct',
  },
  mobile: {
    title: 'Mobile phone number',
    type: 'mobile',
  },
  email: {
    title: 'Email',
    type: 'email',
  },
};

export const CreateUserDetail = () => {
  const classes = useStyles();

  return (
    <>
      {Object.keys(fieldNames).map((field, index) => (
        <FormControlLabel
          data-testid={`fieldTitle-${index}`}
          labelPlacement="top"
          label={fieldNames[field].title}
          key={field}
          className={classes.root}
          control={(
            <InputFormField
              type={fieldNames[field].type}
              name={field}
            />
          )}
        />
      ))}
    </>
  );
};

export default CreateUserDetail;
