import { Input } from 'components/Inputs/Input';
import { Typography } from '@mui/material';
import { MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import useSinglePaymentContext from '../useSinglePaymentContext';
import { formatCurrency, getCurrencyDecimalPlaces } from '../../../../../../utils/currency.helpers';
import { TPayment } from '../../../../../../models/payments';
import { TCurrencyAccount } from '../../../../../../models/currencyAccounts';
import useParentStyles from '../../../../../../components/InterAccountTransfer/Dropdown/Dropdown.styles';
import FormNote from '../../components/FormNote';
import { PaymentAmountBox } from './styles';

interface IProps {
  currencyCode: string | undefined;
  validation: string | undefined;
  value: number | undefined;
  handleInputChange: (event: any) => void;
  requiresFx: boolean;
  currentPayment: TPayment;
  setCurrentPayment: React.Dispatch<React.SetStateAction<TPayment>>;
  selectedDebitingAccount: TCurrencyAccount;
}

const PaymentAmount: React.FC<IProps> = ({
  currencyCode,
  validation,
  value,
  handleInputChange,
  requiresFx,
  currentPayment,
  setCurrentPayment,
  selectedDebitingAccount,
}: IProps) => {
  const { singlePaymentContext } = useSinglePaymentContext();
  const numberRegex = /^\d+(\.\d{1,2})?$/;
  const handleBlur = (event: any): void => {
    if (singlePaymentContext) {
      const newCurrentPayment = { ...singlePaymentContext.currentPayment };
      let newValue = event.target.value;
      const testValue = newValue.replaceAll(',', '');
      if (numberRegex.test(testValue)) {
        newValue = parseFloat(testValue).toFixed(getCurrencyDecimalPlaces(currencyCode || '')).toString();
        newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      newCurrentPayment.paymentAmount = newValue;
      singlePaymentContext.setCurrentPayment(newCurrentPayment);
    }
  };
  const parentStyles = useParentStyles();

  const handleFxSideChange = (event: any) => {
    setCurrentPayment({ ...currentPayment, fixedSide: event.target.value });
  };

  useEffect(() => {
    if (!currentPayment.fixedSide && requiresFx) {
      setCurrentPayment({ ...currentPayment, fixedSide: currencyCode });
    }
  }, [currentPayment, currencyCode, setCurrentPayment, requiresFx]);

  return (
        <PaymentAmountBox>
          <Typography variant="subtitle1" className="label" fontSize={14}>
            Send amount
          </Typography>
          <div className={parentStyles.amountHolder}>
            {
              requiresFx && (
                <Select
                  onChange={handleFxSideChange}
                  value={currencyCode}
                  fullWidth
                  className={parentStyles.dropdown}
                  data-testid="fixed-side-select"
                >
                  {[
                    currentPayment.beneficiary!.currencyCode,
                    selectedDebitingAccount.currencyCode!,
                  ].map((currencyCodeItem: string) => (
                    <MenuItem value={currencyCodeItem}>
                      <Typography variant="subtitle1" fontSize={14}>{currencyCodeItem}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )
            }
            <Input
              onChange={handleInputChange}
              onBlur={handleBlur}
              id="paymentAmount"
              value={value}
              variant="filled"
              testId="paymentAmountInput"
              placeholder={formatCurrency(currencyCode, 0)}
              error={Boolean(validation)}
              helperText={validation}
              autoComplete="off"
            />
          </div>
          {requiresFx && <FormNote text="You can choose the fixed side by clicking the currency" />}
        </PaymentAmountBox>
  );
};

export default PaymentAmount;
