import React, { memo } from 'react';
import { Stat } from 'components/Stat';
import Alert from 'components/Alert/Alert';
import { useStyles } from './BatchDetails.styles';
import { testIds } from '../Funding';

interface IProps {
  noOfPayments?: number | string;
  isStandingOrder?: boolean;
}

const BatchDetails: React.FC<IProps> = (props: IProps) => {
  const { noOfPayments, isStandingOrder } = props;
  const classes = useStyles();
  const alertText = 'Recipients may get more or less than the indicative amount shown.';

  return (
    <div className={classes.batchDetailsWrapper}>
      <div className={classes.batchDetails}>
        <div>
          {
            !isStandingOrder
              ? (
                <>
                  <h4 className="title">FX Payments</h4>
                  <p className="subtitle">Please review the FX transactions below before continuing to the next screen</p>
                </>
              )
              : (
                <>
                  <h4 className="title">Indicative amounts</h4>
                  <p className="subtitle">Below shows an indicative fund and send amount based on current exchange rates. The amounts shown are indicative, meaning the FX rate and amounts may differ when the scheduled payment is sent.</p>
                </>
              )
          }
        </div>
        {
          !isStandingOrder && (
            <Stat
              testId={testIds.numberOfPaymentsStat}
              title="No of payments"
              value={(
                <span className={classes.semiBold}>
                  {noOfPayments}
                </span>
                      )}
              alignRight
            />
          )
        }
      </div>
      { isStandingOrder && <div className={classes.batchDetailsAlert}><Alert variant="warning" text={alertText} /></div> }
    </div>
  );
};

BatchDetails.defaultProps = {
  noOfPayments: '-',
};

export default memo(BatchDetails);
