import { ActionButton } from 'components/Buttons';
import { Button } from '@mui/material';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import FooterWrapper from '../../../../../../components/Payments/FooterWrapper/FooterWrapper';

interface IProps {
    handleReleaseFundedBatch: () => void;
    handleClick: () => void;
}

const ReleaseFundedBatchFooter: React.FC<IProps> = (
  { handleClick, handleReleaseFundedBatch }: IProps,
) => (
  <FooterWrapper>
    <Button
      onClick={handleClick}
      color="secondary"
      startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
    >
      Exit
    </Button>
    <ActionButton size="large" onClick={handleReleaseFundedBatch}>RELEASE BATCH</ActionButton>
  </FooterWrapper>
);

export default ReleaseFundedBatchFooter;
