import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    '& .MuiButton-root': {
      backgroundColor: '#098375 !important',
      color: 'white',
      width: '100%',
    },
    '& .Mui-disabled': {
      backgroundColor: 'rgba(9, 131, 117, 0.4) !important',
    },
  },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  fabProgress: {
    color: 'white',
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}), { name: 'LoadingButton' });
