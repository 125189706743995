import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { ActionButton } from 'components/Buttons';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/pro-light-svg-icons';
import { APInput } from 'components/APInput';
import hasLowerCase from '../../../../utils/hasLowerCase';
import hasUpperCase from '../../../../utils/hasUpperCase';
import hasNumber from '../../../../utils/hasNumber';
import hasCorrectChars from '../../../../utils/hasCorrectChars';
import hasSpecialChars from '../../../../utils/hasSpecialChars';
import routes from '../../../../routes.path';
import browserHistory from '../../../../services/history/browserHistory';
import usePasswordReset from '../../../../hooks/usePasswordReset';
import useStyles from '../../NewPassword/index.styles';
import useFormStyles from '../../form.styles';
import FormTitle from '../../../../components/Molecules/Auth/FormTitle';

interface IProps {
  username?: string,
  email?: string,
}

const Verify: React.FC<IProps> = ({ username, email }: IProps) => {
  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');

  const { submitNewPassword, loading } = usePasswordReset(username);
  const classes = useStyles();
  const formClasses = useFormStyles();

  useEffect(() => {
    if (!username || !email) browserHistory.push(routes.auth.passwordReset.base);
  }, []);

  const handleButtonDisabled = () => {
    if (!(
      hasLowerCase(submitNewPassword.values.password)
      && hasUpperCase(submitNewPassword.values.password)
      && hasNumber(submitNewPassword.values.password)
      && hasCorrectChars(submitNewPassword.values.password, 8)
      && hasSpecialChars(submitNewPassword.values.password)
      && submitNewPassword.values.verificationCode)
    ) return true;

    return false;
  };

  return (
    <div>
      <FormTitle
        title="Please check your inbox for your verification code"
        description={(
          <>
            A verification code has been emailed to
            {' '}
            <b>{email}</b>
            .
            <br />
            Please input the code along with your new password below to continue.
          </>
        )}
      />
      <form onSubmit={submitNewPassword.handleSubmit}>
        <Typography className={formClasses.label}>Verification code</Typography>
        <APInput
          autoComplete="off"
          testid="enter-code"
          id="verification-code"
          name="verificationCode"
          placeholder="Enter your code"
          fullWidth
          variant="filled"
          type="text"
          onChange={submitNewPassword.handleChange}
          value={submitNewPassword.values.verificationCode}
          error={submitNewPassword.touched.verificationCode
            && Boolean(submitNewPassword.errors.verificationCode)}
          helperText={submitNewPassword.touched.verificationCode
            && submitNewPassword.errors.verificationCode}
        />
        <Typography className={formClasses.label} style={{ marginTop: '16px' }}>New Password</Typography>
        <APInput
          autoComplete="off"
          testid="input-password"
          id="password"
          name="password"
          placeholder="Enter your new password"
          fullWidth
          variant="filled"
          type={passwordType}
          onChange={submitNewPassword.handleChange}
          value={submitNewPassword.values.password}
          error={submitNewPassword.touched.password && Boolean(submitNewPassword.errors.password)}
          helperText={submitNewPassword.touched.password && submitNewPassword.errors.password}
        />
        <button
          type="button"
          className={formClasses.eyeIconStyles}
          onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
        >
          <FontAwesomeIcon
            icon={passwordType === 'password' ? faEye : faEyeSlash}
          />
        </button>
        <ul className={classes.list}>
          <li className={clsx(hasLowerCase(submitNewPassword.values.password)
            && classes.contains)}
          >
            One lowercase character
          </li>
          <li className={clsx(hasUpperCase(submitNewPassword.values.password)
            && classes.contains)}
          >
            One uppercase character
          </li>
          <li className={clsx(hasNumber(submitNewPassword.values.password) && classes.contains)}>
            One number
          </li>
          <li className={clsx(
            hasCorrectChars(submitNewPassword.values.password, 8) && classes.contains,
          )}
          >
            8 characters minimum
          </li>
          <li className={clsx(
            hasSpecialChars(submitNewPassword.values.password) && classes.contains,
          )}
          >
            One special character
          </li>
        </ul>

        <ActionButton
          size="large"
          type="submit"
          testId="submit-password-reset"
          fullWidth
          loading={loading}
          disabled={loading || handleButtonDisabled()}
        >
          Log In
        </ActionButton>
        <NavLink
          to={routes.auth.login}
          className={formClasses.formBacklink}
        >
          Back to Sign in
        </NavLink>
      </form>
    </div>
  );
};

export default Verify;
