import { AxiosResponse } from 'axios';
import { BatchSubmitVerificationDto, VerificationMethod } from '@alpha/payments-dtos';
import instance, { instanceAccountId, instanceNoAccountId } from '../Axios/instance';
import { TSendTotp } from '../../models/auth';
import { UserRolesRequestTOTPResponse } from '../../models/userManagement';

export class AuthyService {
  public static async requestPhoneNumber(method?: string, accountId?: string): Promise<TSendTotp> {
    return this.requestPhoneNumberAsync(method, accountId);
  }

  public static async getPaymentsPhoneNumber(
    batchId: string,
    verificationMethod?: VerificationMethod,
    accountId?: string,
  ): Promise<BatchSubmitVerificationDto> {
    return this.getPaymentsPhoneNumberAsync(batchId, verificationMethod, accountId);
  }

  public static async getPaymentsApprovalPhoneNumber(
    batchId: string,
    verificationMethod?: VerificationMethod,
    accountId?: string,
  ): Promise<BatchSubmitVerificationDto> {
    return this.getPaymentsApprovalPhoneNumberAsync(batchId, verificationMethod, accountId);
  }

  private static async requestPhoneNumberAsync(
    method?: string,
    accountId?: string,
  ): Promise<TSendTotp> {
    try {
      const response: AxiosResponse<TSendTotp> = accountId
        ? await instanceNoAccountId.get(
          `/auth/mfa/send-totp?${method ? `method=${method}&` : ''}itemId=12345`,
          {
            headers: {
              'account-id': accountId,
            },
          },
        )
        : await instance.get(
          `/auth/mfa/send-totp?${method ? `method=${method}&` : ''}itemId=12345`,
        );
      return response.data;
    } catch (e) {
      throw Error(e.response?.data?.message || 'Something went wrong');
    }
  }

  public static async requestUserRoleTotpAsync(
    requestIds: string[],
    method?: string,
  ): Promise<UserRolesRequestTOTPResponse> {
    const response = await instanceNoAccountId.post('/user/api/v1/user-roles-request/totp', { method, requestIds });
    return response.data;
  }

  private static async getPaymentsPhoneNumberAsync(
    batchId: string,
    verificationMethod?: VerificationMethod,
    accountId?: string,
  ): Promise<BatchSubmitVerificationDto> {
    const url = `/payments/batches/${batchId}/verification/submit?method=${verificationMethod || VerificationMethod.PUSH_NOTIFICATION}`;
    let response: AxiosResponse<BatchSubmitVerificationDto>;
    if (accountId) {
      response = await instanceAccountId(accountId).get(url);
    } else {
      response = await instance.get(
        url,
      );
    }
    return response.data;
  }

  private static async getPaymentsApprovalPhoneNumberAsync(
    batchId: string,
    verificationMethod?: VerificationMethod,
    accountId?: string,
  ): Promise<BatchSubmitVerificationDto> {
    const url = `/payments/batches/${batchId}/verification/approve?method=${verificationMethod || VerificationMethod.PUSH_NOTIFICATION}`;
    let response: AxiosResponse<BatchSubmitVerificationDto>;
    if (accountId) {
      response = await instanceAccountId(accountId).get(url);
    } else {
      response = await instance.get(
        url,
      );
    }
    return response.data;
  }
}

export default AuthyService;
