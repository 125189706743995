import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    background: "url('/static/images/login.jpg') bottom center",
    backgroundSize: 'cover',
    minHeight: '100vh',
    minWidth: '100%',
    '& > .wrapper': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '100vh',
    },
  },
  header: {
    display: 'flex',
    boxSizing: 'border-box',
    margin: '3% 8%',
    width: '84%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    zIndex: 1,
  },
  leftContent: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'rgba(1,1,1,0.2)',
    padding: '6% 8% 0 8%',
    '& > .wrapper': {
      width: '590px',
      minHeight: '462px',
    },
  },
  rightContent: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '6% 4% 0 0%',
    '& > .wrapper': {
      width: '750px',
      maxWidth: '80%',
      minHeight: '462px',
      textAlign: 'center',
      '& img': {
        maxWidth: '100%',
      },
    },
  },
}), { name: 'AuthLayoutStylesl' });

export default useStyles;
