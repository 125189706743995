import { Box, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import clsx from 'clsx';
import { InterAccountTransferDrawer as Drawer } from '../../../components/InterAccountTransfer/Drawer/Drawer';
import Rate from '../../../components/InterAccountTransfer/Rate/Rate';
import Alert from '../../../components/Alert/Alert';
import AmountAndBalanceContainer from '../AmountAndBalanceContainer/AmountAndBalanceContainer';
import { TInterAccountTransferForm, errorMessages } from '../FormValues';
import BackdropDrawers from './BackdropDrawers/BackdropDrawers';
import { useStyles } from './BookFxDrawer.styles';
import Footer from './Footer/Footer';
import Reference from './Reference/Reference';
import useBookFx from './useBookFx';

interface IProps {
  form: FormikProps<TInterAccountTransferForm>;
  handleClose: () => void;
  handleOpenTransactionDataDrawer: () => void;
  handleResetFormStateAndCloseIATDrawer: () => void;
  accountId?: string;
}

const BookFxDrawer: React.FC<IProps> = ({
  form,
  handleClose,
  handleOpenTransactionDataDrawer,
  handleResetFormStateAndCloseIATDrawer,
  accountId,
}: IProps) => {
  const styles = useStyles();

  const {
    liveRateStatus,
    liveRate,
    creditingAccount,
    debitingAccount,
    setLiveRateStatus,
    handleAcknowledgeDuplicateTrade,
    handleClickBookFx,
    duplicateTrade,
  } = useBookFx(form, handleOpenTransactionDataDrawer, accountId);

  const rate = form.values.liveRate?.rate;
  const rateType = liveRateStatus.indexOf('failed') > -1 || liveRateStatus === 'timeout'
    ? 'expired'
    : 'live';

  return (
    <form onSubmit={form.handleSubmit} className={styles.form}>
      <Drawer.Child section="top">
        <Typography variant="h4" className={styles.header}>
          <b className="dd-privacy-allow">Balance Transfer</b>
        </Typography>

        {form.errors.debitingAccount?.amount === errorMessages.INSUFFICIENT_FUNDS
        && (<Alert variant="error" text="Insufficient funds to complete balance transfer" />)}

        {((form.errors.debitingAccount?.amount || form.errors.creditingAccount?.amount)
        === errorMessages.MINAMOUNT)
        && (<Alert variant="error" text="The amount you have entered is below the minimum allowed amount" />)}

        <Box className={clsx(styles.debitingValuesContainer, 'dd-privacy-allow')}>
          <AmountAndBalanceContainer type="DEBITING" disabled />
        </Box>
      </Drawer.Child>
      <Drawer.Child section="bottom">
        <Rate
          type={rateType}
          creditingCurrency={creditingAccount.currencyAccount!.currencyCode!}
          debitingCurrency={debitingAccount.currencyAccount!.currencyCode!}
          rate={rate === undefined ? 0 : rate}
        />
        <Box className={styles.creditingValuesContainer}>
          <AmountAndBalanceContainer
            type="CREDITING"
          />
        </Box>
        <Reference
          estimatedSettlementDate={liveRate?.valueDate}
          reference={creditingAccount.reference || '-'}
        />
      </Drawer.Child>
      <Footer
        liveRateStatus={liveRateStatus}
        setLiveRateStatus={setLiveRateStatus}
        handleBookFx={handleClickBookFx}
        handleClose={handleClose}
      />
      <BackdropDrawers
        liveRateStatus={liveRateStatus}
        handleAcknowledgeDuplicateTrade={handleAcknowledgeDuplicateTrade}
        handleResetFormStateAndCloseIATDrawer={handleResetFormStateAndCloseIATDrawer}
        paymentDetails={duplicateTrade}
      />
    </form>
  );
};

export default BookFxDrawer;
