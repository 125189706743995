const generateResidentAddress = (addressLine1: string | undefined,
  city: string | undefined,
  countryCode: string | undefined): string => {
  if (!addressLine1 && !city && !countryCode) {
    return '';
  }
  return `${addressLine1 || ''} ${city || ''} ${countryCode || ''}`.trim();
};

export default generateResidentAddress;
