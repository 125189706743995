import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { PaymentRouteEnum } from '../../../models/payments';
import routes from '../../../routes.path';
import browserHistory from '../../../services/history/browserHistory';
import { actions } from '../../../store/payments/payments.reducer';
import ChoosePayment from './ChoosePayment/ChoosePayment';
import ErrorPayment from './ErrorPayment/ErrorPayment';
import UploadingPayment from './UploadingPayment/UploadingPayment';

interface IProps {
  batchDetails?: any;
  handleClose: () => void;
}

const DisplayDrawer: React.FC<IProps> = (props: IProps) => {
  const { batchDetails, handleClose } = props;
  const dispatch = useDispatch();
  if (!batchDetails?.batchStatus) {
    return <ChoosePayment />;
  }
  if (
    batchDetails?.batchStatus === 'INVALID'
    || (batchDetails?.batchStatus === 'VALIDATED'
      && batchDetails?.totalInvalidPayments)
  ) {
    return (
      <ErrorPayment
        handleClose={handleClose}
        batchErrors={batchDetails?.batchErrors}
        totalInvalidPayments={batchDetails?.totalInvalidPayments}
        totalPayments={batchDetails?.totalRecords}
        batchId={batchDetails?.batchId!}
      />
    );
  }
  if (
    batchDetails?.batchId && batchDetails?.batchStatus === 'VALIDATED'
    && !batchDetails?.totalInvalidPayments
  ) {
    browserHistory.push(
      routes.payments.batchSummaryRouteFunc(
        batchDetails.batchId,
        PaymentRouteEnum.DRAFTED,
      ),
    );
    dispatch(actions.clear());
    return <></>;
  }
  if (
    batchDetails?.batchStatus === 'UPLOADED'
    || batchDetails?.batchStatus === 'REQUESTED'
  ) {
    return (
      <UploadingPayment
        batchStatus={batchDetails?.batchStatus}
        totalInvalidPayments={batchDetails?.totalInvalidPayments}
        totalValidatedPayments={batchDetails?.totalValidatedPayments}
        totalRejectedPayments={batchDetails?.totalRejectedPayments}
        totalRecords={batchDetails?.totalRecords}
      />
    );
  }
  return <></>;
};

export default memo(DisplayDrawer);
