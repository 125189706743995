import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    tabContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      minHeight: 'calc(100vh - 310px)',
      '& > div': {
        width: '100%',
      },
    },
    bodyWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      overflowX: 'hidden',
    },
    noOfAccounts: {
      fontSize: 14,
      color: 'rgba(51, 51, 51, 0.93)',
      paddingBottom: 20,
    },
    allEntriesBox: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      padding: '0px 35px 0px 50px',
    },
    accountsBox: {
      padding: '0px 35px 0px 50px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
    accButton: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '35px 35px 35px 50px',
      borderTop: '1px solid rgba(112, 112, 112, 0.1)',
      backgroundColor: '#F7F7F7',
      alignItems: 'center',
      position: 'absolute',
      bottom: '0',
      width: '100%',
    },
    totalSelected: {
      color: '#098375',
    },
    emptyTable: {
      height: '341px',
      position: 'relative',
      marginBottom: '41px',
    },
  }),
  { name: 'BeneShareDrawerBody' },
);

export default useStyles;
