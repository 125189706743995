import { Amplify, Auth } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { PersistGate } from 'redux-persist/integration/react';
import theme, { ThemeProvider } from 'theme';
import Hotjar from '@hotjar/browser';
import App from './App';
import awsExports from './aws-exports';
// This is commented because of https://mswjs.io/docs/getting-started/integrate/browser#create-react-app-version-3
// import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store';
import { injectStore } from './services/Axios/multiEntityInstance';
import env from './env.variables';
import allowedActionsButtons from './models/dataDogAllowedActions';
import worker from './mockServiceWorker/browser';

injectStore(store);

if (env.REACT_APP_HTTP_MOCKS) {
  worker.start({
    ServiceWorker: {
      url: 'public/mockServiceWorker.js',
    },
    onUnhandledRequest: 'bypass',
  });
}

if (env.HOTJAR_ENABLED) {
  const siteId = env.HOTJAR_SITE_ID;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}

Amplify.configure(awsExports);
Auth.configure(awsExports);

datadogRum.init({
  applicationId: env.REACT_APP_DATA_DOG.appId,
  clientToken: env.REACT_APP_DATA_DOG.clientToken,
  site: 'datadoghq.eu',
  service: env.REACT_APP_DATA_DOG.service,
  sampleRate: 100,
  sessionReplaySampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask',
  beforeSend: (event: any) => {
    if (event?.action && event?.action?.target && event?.action?.target?.name) {
      if (allowedActionsButtons.indexOf(event?.action?.target?.name) < 0) {
        // eslint-disable-next-line no-param-reassign
        event.action.target = { name: 'xxxxxx' };
      }
    }
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// This is commented because of https://mswjs.io/docs/getting-started/integrate/browser#create-react-app-version-3
// serviceWorker.unregister();
