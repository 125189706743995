import React, { useCallback } from 'react';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePickerProps, MobileDatePicker } from '@mui/x-date-pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import StyledAPDatePicker from './APDatePicker.styles';
import { CustomSideBar } from './CustomSideBar';

interface IAPDatePickerProps extends DatePickerProps<any, any> {
    label: string;
    rebranded?: boolean;
    fullWidth?: boolean;
}
const APDatePicker: React.FC<IAPDatePickerProps> = React.memo((props) => {
  const defaultRenderInput = useCallback((params) => <TextField {...params} />, []);

  const {
    renderInput = defaultRenderInput, rebranded, value, fullWidth, ...rest
  } = props;

  return (
    <StyledAPDatePicker fullWidth={fullWidth}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDatePicker
          {...rest}
          showToolbar
          value={value}
          orientation="landscape"
          ToolbarComponent={(toolbarProps) => <CustomSideBar {...toolbarProps} date={value} />}
          renderInput={renderInput}
          componentsProps={{
            actionBar: { sx: { display: 'none' } },
          }}
          closeOnSelect
        />
        <FontAwesomeIcon icon={faCalendarAlt} className="icon" />
        {rebranded && <FontAwesomeIcon icon={faAngleDown} className="rebrandIcon" />}
      </LocalizationProvider>
    </StyledAPDatePicker>
  );
});

export default APDatePicker;
