import React, { Dispatch, useEffect } from 'react';
import {
  BatchApprover,
  PaymentBatchDraftDto,
  PaymentBatchDraftStatus,
} from '@alpha/payments-dtos';
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@mui/material';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelope, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import Menu from 'components/Menu/Menu';
import useActionDropdown from '../../../../../../hooks/useActionDropDown';
import useAlphaSnackbar from '../../../../../../hooks/useAlphaSnackbar';
import useAuthorization from '../../../../../../hooks/useAuthorization';
import { PaymentRouteEnum } from '../../../../../../models/payments';
import { UserRole } from '../../../../../../models/user';
import routes from '../../../../../../routes.path';
import history from '../../../../../../services/history/browserHistory';
import PaymentsService from '../../../../../../services/Payments/payments.service';
import { flowToBatchStatusMapping } from '../../../../../../utils/batchStatus.helpers';
import { ITable } from '../DisplayTable';
import useStyles from './ActionDropDown.styles';
import MultiSelectSection from './MultiSelectSection/MultiSelectSection';

interface IProps {
  id: string;
  status: PaymentBatchDraftStatus;
  userCanApprove: boolean;
  requiresFx: boolean;
  currentOptions: BatchApprover[];
  setCurrentOptions: React.Dispatch<React.SetStateAction<BatchApprover[]>>;
  setTextValue: Dispatch<React.SetStateAction<string>>;
  batchDetails: PaymentBatchDraftDto;
  table: ITable;
}

const ActionDropDown: React.FC<IProps> = ({
  id,
  status,
  userCanApprove,
  requiresFx,
  currentOptions,
  batchDetails,
  setCurrentOptions,
  setTextValue,
  table,
}: IProps) => {
  const classes = useStyles();
  const userAuth = useAuthorization();

  const {
    open,
    approvers,
    loading,
    menuCloseCount,
    openModal,
    setRenderApproveBatch,
    setRenderBookFx,
    setIsApprovedAndFx,
    setMenuCloseCount,
    displayActionButton,
    displayIcon,
    submitApproverEmails,
    handleRenderDropdown,
    setOpen,
    setOpenModal,
    handleRenderConfirmationModal,
    displayMultiSelect,
    setDisplayMultiSelect,
  } = useActionDropdown({
    currentOptions,
  });

  useEffect(() => {
    if (status === PaymentBatchDraftStatus.APPROVED && requiresFx) {
      setIsApprovedAndFx(true);
      if (userAuth.roles.includes('FX IAT')) {
        setRenderBookFx(true);
      }
    }
    if (flowToBatchStatusMapping.approver.includes(status) && userCanApprove) {
      setRenderApproveBatch(true);
    }
  }, [userAuth]);

  const sb = useAlphaSnackbar();

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleDeleteBatchClick = async (batchId: string): Promise<void> => {
    try {
      await PaymentsService.deletePayment(batchId);
      table.rerenderTable();
      sb.trigger('Successfully deleted your payment', 'success');
    } catch {
      sb.trigger('There was an error deleting your payment', 'error');
    }
  };

  const handleClose = (): void => {
    setDisplayMultiSelect(false);
    setMenuCloseCount(0);
  };

  const menuItems = [
    {
      id: 'action-pending-payment-view-batch',
      content: (
        <Typography
          data-testid={`payment-action-option-${batchDetails.batchId}`}
        >
          {displayActionButton()}
        </Typography>
      ),
      icon: displayIcon(),
      onClick: () =>
        history.push(
          routes.payments.batchSummaryRouteFunc(id, PaymentRouteEnum.PENDING),
        ),
      underline: true,
    },
    {
      id: 'action-pending-payment-batch-options',
      content: (
        <MultiSelectSection
          setMenuCloseCount={setMenuCloseCount}
          menuCloseCount={menuCloseCount}
          setOpen={setOpen}
          displayMultiSelect={displayMultiSelect}
          loading={loading}
          id={id}
          approvers={approvers}
          currentOptions={currentOptions}
          setCurrentOptions={setCurrentOptions}
          setTextValue={setTextValue}
          setDisplayMultiSelect={setDisplayMultiSelect}
          submitApproverEmails={submitApproverEmails}
        />
      ),
      onClick: () => handleRenderDropdown(id),
      disableClick: true,
      icon: !displayMultiSelect ? faEnvelope : undefined,
      disableHoverEffect: displayMultiSelect,
      underline: true,
    },
  ];

  const { authorized } = useAuthorization([[UserRole.PAYMENTS_INPUTTER]]);
  if (
    authorized &&
    batchDetails.noOfApprovals &&
    batchDetails.approvalsRequired &&
    batchDetails.noOfApprovals < batchDetails.approvalsRequired
  ) {
    menuItems.push({
      id: 'action-pending-payment-delete-batch',
      content: (
        <Typography
          data-testid={`payment-action-option-${batchDetails.batchId}`}
        >
          Delete Batch
        </Typography>
      ),
      icon: faTrash,
      onClick: handleRenderConfirmationModal,
      underline: false,
    });
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        data-testid={`action-${batchDetails.batchId}`}
        className={classes.wrapper}
      >
        <Menu
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={menuCloseCount === 1}
          disableHoverEffect={displayMultiSelect}
          onClose={handleClose}
          buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
          buttonTitle=""
          icon={faEllipsisH}
        >
          {menuItems.map((item) => (
            <MenuDropdownItem key={item.id} setOpen={setOpen}>
              {item}
            </MenuDropdownItem>
          ))}
        </Menu>
        <DecisionModal
          title="Are you sure you want to delete this batch?"
          open={openModal}
          subtitle="This is irreversible and the batch will be permanently deleted."
          actionButtonText="Delete"
          handleActionButton={() =>
            handleDeleteBatchClick(batchDetails.batchId)
          }
          handleClose={() => setOpenModal(false)}
          icon={faExclamationTriangle}
          closeButtonText="Cancel"
        />
      </Box>
    </ClickAwayListener>
  );
};

export default ActionDropDown;
