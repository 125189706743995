import { LinearProgress } from '@mui/material';
import { Loader } from 'components/Loader';
import { APMainLayout } from 'components/Layout/MainLayout';
import { StyledTabsWrapper, TabContent } from 'components/Tabs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { TStandingOrder } from 'models/payments';
import DrawerBackdrop from '../../../components/Drawer/DrawerBackdrop/DrawerBackdrop';
import SameTradeWarning from '../../../components/Molecules/SameTradeWarning';
import TransactionError from '../../../components/Molecules/TransactionError';
import Title from '../../../components/Title/Title';
import useFunding from './useFunding';
import routes from '../../../routes.path';
import browserHistory from '../../../services/history/browserHistory';
import BatchDetails from './BatchDetails/BatchDetails';
import BookFxFooter from './BookFxFooter/BookFxFooter';
import { IProps } from './Funding.interface';
import FxPayments from './FxPayments/FxPayments';
import FxPaymentsLineByLine from './FxPaymentsLineByLine/FxPaymentsLineByLine';
import RequestLiveRateFooter from './RequestLiveRateFooter/RequestLiveRateFooter';
import StyledFunding from './Funding.styles';
import usePaymentBatchSearch from '../usePaymentBatchSearch';
import { scheduledBaseUrl } from '../Dashboard/ScheduledTableContainer/constants';
import StandingOrdersDetails from './StandingOrdersDetails';
import { PaymentFeatureFlags } from '../ManualPayments/constants';

export const testIds = {
  funding: 'funding-screen',
  fundingTab: 'funding-tab',
  loading: 'loading',
  batchStatusStat: 'batch-status-stat',
  numberOfPaymentsStat: 'number-of-payments-stat',
  backToSummaryBtn: 'back-to-summary-btn',
  requestLiveRateBtn: 'request-live-rate-btn',
  cancelBookingBtn: 'cancel-booking-btn',
  bookFxBtn: 'book-fx-btn',
  loadingLiveRate: 'loading-live-rate',
};

const Funding: React.FC<IProps> = () => {
  const { batchId, accountId } = useParams<{ batchId: string, accountId: string, }>();

  const {
    loading,
    batchDetails,
    displayPairs,
    liveRates,
    timer,
    disableLiveRateBtn,
    disableBookFxBtn,
    bookFxErrored,
    handleLiveRateReset,
    handleFxBooking,
    handleCheckSameTrade,
    handleBookNow,
    duplicateTradesDetails,
    handleAcknowledgeDuplicate,
    loadingScreen,
    fxApprover,
    getUserFxApprover,
    selectedEntity,
    lineByLineRates,
  } = useFunding({ batchId, accountId });
  const { flags } = useGetFeatureFlags();
  // 3 is SCHEDULED and we need to retrieve Standing Order for the specific batch
  const { search, searchParams } = usePaymentBatchSearch(3, flags);
  searchParams.queryParams.searchtext = batchId;

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [tabTitles, setTabTitles] = useState<string[]>(['']);
  const [standingOrders, setStandingOrders] = useState<TStandingOrder | null>(null);
  const handleSetSelectedTabIndex = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };
  const standingOrderFFEnabled = flags?.find((flag) => (
    flag.name === PaymentFeatureFlags.STANDING_ORDER && flag.isActive
  ))?.isActive;

  useEffect(() => {
    if (standingOrderFFEnabled) {
      search.handleNewSearch({
        ...searchParams,
        baseUrl: scheduledBaseUrl,
      }, true);
    }
  }, []);

  useEffect(() => {
    getUserFxApprover();
  }, []);

  useEffect(() => {
    const items = search?.items?.items.records as unknown as TStandingOrder[];

    if (items && items.find((item) => item.batchId === batchId)) {
      setStandingOrders(items.find((item) => item.batchId === batchId) as TStandingOrder || null);
    } else {
      setStandingOrders(null);
    }
  }, [search, batchId]);

  useEffect(() => {
    if (selectedEntity?.lineByLineFxEnabled && !loading) {
      setTabTitles(['Overview', 'Payments']);
    }
  }, [selectedEntity, loading]);

  const liveRatesButtonDisabled = standingOrders ? disableBookFxBtn : disableLiveRateBtn;
  const liveRatesButtonAction = standingOrders ? handleBookNow : handleCheckSameTrade;

  return (
    <StyledFunding>
      <APMainLayout
        data-testid={testIds.funding}
        title={(
          <Title
            title="Batch funding"
            batchFileName={(batchDetails && batchDetails.sourceName) || 'Loading..'}
            batchStatus="PENDING FX"
          />
        )}
        breadCrumbs={[
          { text: 'Home', url: routes.home },
          { text: 'Payments', url: routes.payments.multientity.base },
          { text: 'Batch Approval' },
        ]}
        pageTabs={(
          <StyledTabsWrapper
            testId={testIds.fundingTab}
            tabTitles={tabTitles}
            tabIndex={selectedTabIndex}
            handleChange={handleSetSelectedTabIndex}
            className="dd-privacy-allow"
          />
        )}
      >
        <TabContent index={0} value={selectedTabIndex}>
          <div className="content">
            { !!standingOrders && <StandingOrdersDetails standingOrders={standingOrders} /> }
            <BatchDetails
              noOfPayments={batchDetails?.noOfValidatedPayments}
              isStandingOrder={!!standingOrders}
            />
            <FxPayments
              loading={loading}
              loadingLiveRate={disableLiveRateBtn}
              displayPairs={displayPairs}
              liveRates={liveRates}
              isStandingOrder={!!standingOrders}
            />
          </div>
        </TabContent>
        {selectedEntity?.lineByLineFxEnabled && (
        <TabContent index={1} value={selectedTabIndex}>
          <div className="content">
            <BatchDetails
              noOfPayments={batchDetails?.noOfValidatedPayments}
            />
            <FxPaymentsLineByLine
              batchId={batchId}
              accountId={accountId}
              lineByLineRatesMap={lineByLineRates}
              liveRates={liveRates}
            />
          </div>
        </TabContent>
        )}
        {liveRates.length > 0 && timer > 0 && <LinearProgress variant="determinate" value={timer} />}
        {
        liveRates.length > 0 && timer > 0
          ? (
            <BookFxFooter
              fxApprover={fxApprover}
              disableBookFxBtn={disableBookFxBtn}
              handleLiveRateReset={handleLiveRateReset}
              handleFxBooking={handleFxBooking}
            />
          )
          : (
            <RequestLiveRateFooter
              handleCheckSameTrade={liveRatesButtonAction}
              disableLiveRateBtn={liveRatesButtonDisabled}
              isStandingOrder={!!standingOrders}
            />
          )
        }
        <DrawerBackdrop display={bookFxErrored} width="100%" backgroundColor="rgba(255, 255, 255, 0.95)">
          <TransactionError onClick={() => browserHistory.push(routes.payments.multientity.base)} />
        </DrawerBackdrop>
        <DrawerBackdrop display={duplicateTradesDetails?.length > 0} width="100%" backgroundColor="rgba(255, 255, 255, 0.95)">
          <SameTradeWarning
            paymentDetails={duplicateTradesDetails}
            onClick={handleAcknowledgeDuplicate}
          />
        </DrawerBackdrop>
        <DrawerBackdrop display={loadingScreen} width="100%" backgroundColor="rgba(255, 255, 255, 0.95)">
          <Loader testId="payment-complete-loading" />
        </DrawerBackdrop>
      </APMainLayout>
    </StyledFunding>
  );
};

export default Funding;
