import { Box, Checkbox, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { TCurrencyAccount } from '../../../../models/currencyAccounts';
import { useStyles } from './Item.styles';

interface IProps {
  currencyAccount: TCurrencyAccount;
  checked: boolean;
}
const Data: React.FC<IProps> = (props: IProps) => {
  const { currencyAccount, checked } = props;
  const styles = useStyles();

  return (
    <Box className={clsx(styles.root, styles.item)}>
      <Checkbox color="primary" checked={checked} />
      <Typography variant="subtitle1">
        {currencyAccount.friendlyName
       || `${currencyAccount.currencyCode} Account`}
      </Typography>
      <Typography variant="subtitle1" className={styles.bankInfo}>
        {currencyAccount.iban
          || currencyAccount.swift
          || currencyAccount.accountNumber
          || currencyAccount.sortCode
          || currencyAccount.reference}
      </Typography>
    </Box>
  );
};

interface IStickyProps {
  currencyCode: string;
  checked: boolean;
}
const Sticky: React.FC<IStickyProps> = (props: IStickyProps) => {
  const { currencyCode, checked } = props;
  const styles = useStyles();

  return (
    <Box className={clsx(styles.root, styles.sticky)}>
      <Checkbox color="primary" checked={checked} />
      <Typography variant="subtitle1">{currencyCode}</Typography>
    </Box>
  );
};

export const Item = {
  Data,
  Sticky,
};

export default Data;
