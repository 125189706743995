import { AxiosResponse } from 'axios';
import { TCountries } from '../../models/countries';
import instance from '../Axios/instance';

export class CountriesService {
  public static async getCountries(): Promise<TCountries> {
    const response: AxiosResponse<TCountries> = await instance.get(
      '/bene/countries',
    );
    return response.data;
  }
}

export default CountriesService;
