import styled from '@mui/styled-engine';
import { IAPMainLayout } from '.';

const StyledApHeader = styled('div')({
  backgroundColor: '#FDFDFD',
  borderBottom: '1px solid rgba(34, 34, 34, 0.1)',
  padding: '50px 2.25% 0px',
  minHeight: 190,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& h1': {
    margin: '16px 0',
    fontSize: '28px !important',
    lineHeight: '38px !important',
  },
});

const StyledTabsAndCta = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  overflowX: 'hidden',
});

const StyledPageTabs = styled('div')({
  flexGrow: 1,
});

const StyledRightAlignedCta = styled('div')({
  alignContent: 'right',
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: 7,
});

const StyledApBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bodyBackgroundColor',
})<IAPMainLayout>(({ bodyBackgroundColor }) => ({
  minHeight: 'calc(100vh - 310px)',
  position: 'relative',
  padding: 0,
  backgroundColor: bodyBackgroundColor || 'inherit',
  '& > *': {
    padding: '0 2.25%',
  },
  '& > *.noPadding': {
    padding: 0,
  },
}));

export {
  StyledApHeader, StyledTabsAndCta, StyledPageTabs, StyledRightAlignedCta, StyledApBody,
};
