import { Box } from '@mui/material';
import React, { useContext } from 'react';
import useDynamoTable from '../../../../../hooks/useDynamoTable';
import { TDynamoTableQueryParams } from '../../../../../services/DatabaseServices/DynamoService';
import useStyles from './Body.styles';
import Footer from './Footer/Footer';
import Headers from './Headers/Headers';
import TableContainer from './TableContainer/TableContainer';
import { PaymentContext } from '../../../PaymentContext';

interface IBody {
  handleClose: () => void;
  batchId: string;
  batchErrors?: string[];
  totalPayments: number | undefined;
  totalInvalidPayments: number | undefined;
}

const Body: React.FC<IBody> = (props: IBody) => {
  const {
    batchId, totalPayments, totalInvalidPayments, batchErrors, handleClose,
  } = props;

  const { selectedEntity } = useContext(PaymentContext);

  const classes = useStyles();
  const pageSize = 6;
  const initialQuery: TDynamoTableQueryParams = {
    url: `/payments/batches/${batchId}/invalid`,
    pageSize,
  };

  const table = useDynamoTable(initialQuery, selectedEntity?.id);

  return (
    <Box className={classes.bodyWrapper}>
      <Headers
        batchErrors={batchErrors}
        totalPayments={totalPayments}
        totalInvalidPayments={totalInvalidPayments}
      />
      <TableContainer loading={table.loading} batchErrors={batchErrors} tableData={table.data} />
      <Footer
        handleClose={handleClose}
        batchErrors={batchErrors}
        batchId={batchId}
        table={table}
        totalPayments={totalPayments}
        totalInvalidPayments={totalInvalidPayments}
      />
    </Box>
  );
};

export default Body;
