/* eslint-disable max-lines-per-function */
import { Box, Typography } from '@mui/material';
import { Loader } from 'components/Loader';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { GetUserRequest, GetUserResponse, UserAccount } from '@portal/user-types';
import { CSSTransition } from 'react-transition-group';
import RemoveAccessModal from 'domain/UserManagement/components/RemoveAccessModal';
import { useSelector } from 'react-redux';
import { userManagementState } from 'store/userManagement/userManagement.reducer';
import { TStore } from 'store';
import Card from '../../../../components/Card';
import DrawerBackdrop from '../../../../components/Drawer/DrawerBackdrop/DrawerBackdrop';
import { useUserManagementContext } from '../../context';
import Details from '../../components/UserDetails';
import useStyles from './index.styles';
import DataTable from '../../../../components/Table/DataTable';
import { PermissionInfoDrawer } from '../../components/PermissionInfoDrawer';
import useDataTable from '../../../../hooks/useDataTable';
import UserManagementService from '../../../../services/UserManagement/userManagement.service';
import { NotFoundPageNoRedirect } from '../../../../components/NotFoundPage';
import columns from './index.columns';
import useToggle from '../../../../hooks/useToggle';
import { StyledButton, StyledRemoveButton } from '../../components/RemoveAccessModal/index.styles';
import { ModalTypeEnum, TUser } from '../../context/index.interfaces';

const User = ({ getRowHeight = true }: { getRowHeight?: boolean }) => {
  const {
    isEntityManager,
  } = useSelector<TStore, userManagementState>(
    (state) => state.userManagementPage,
  );

  const { userId } = useParams<{ userId: string }>();
  const classes = useStyles();
  const { isOpen, toggle } = useToggle();

  const items = 10;

  const {
    rows,
    page = 0,
    setPage,
    isLoading,
    totalRowCount = 0,
    isError,
  } = useDataTable<GetUserRequest, GetUserResponse>(UserManagementService.getUser, {
    id: userId,
    items: items.toString(),
    approvalConfig: 'true',
  });

  const { user, entities, totalItems } = rows || {};
  const { setTitle, openModal: openEditPermissionsModal } = useUserManagementContext();
  const [allEntities, setAllEntities] = useState<UserAccount[]>();

  const getAllEntities = async () => {
    const { entities: userEntities } = await UserManagementService.getUser({
      id: userId,
      page: '0',
    });

    setAllEntities(userEntities.filter((entity) => entity.isManageable) || []);
  };

  const submitCallback = async (callback: () => Promise<any>) => {
    await callback();
  };

  useEffect(() => {
    if (user) {
      setTitle(`${user.firstName} ${user.lastName}`);
    }
    getAllEntities();
  }, [user, setTitle]);

  const [openModal, setOpenModal] = useState(false);

  return isError ? (
    <NotFoundPageNoRedirect />
  ) : (
    <div data-testid="user-page">
      <DrawerBackdrop display={isLoading} width="100%" backgroundColor="white">
        <Loader testId="user-loading" />
      </DrawerBackdrop>
      {allEntities && (
        <RemoveAccessModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          manageableEntities={allEntities}
          user={user as TUser}
          removingAccessToAll
        />
      )}
      <PermissionInfoDrawer permissionDrawerOpen={isOpen} setPermissionDrawerOpen={toggle} />
      {user && (
        <Box className={classes.pageRow}>
          <CSSTransition appear in timeout={1000} classNames="fade-up">
            <Card className={classes.detailsHolder}>
              <Details
                status={user.status}
                username={user.username}
                date={user.updated || user.created}
              />
            </Card>
          </CSSTransition>
          <CSSTransition appear in timeout={1000} classNames="fade-up">
            <Card className={classes.tableHolder}>
              <div className={classes.tableHeader}>
                <Typography data-testid="entity-table" className={classes.tableTitle}>
                  Entities (
                  {totalItems}
                  )
                </Typography>
                <div>
                  {allEntities && allEntities.length > 0 && (
                  <StyledRemoveButton
                    variant="contained"
                    disableElevation
                    type="button"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Remove Access From All Entities
                  </StyledRemoveButton>
                  )}
                  {isEntityManager && (
                  <StyledButton
                    data-testid="edit-permissions-button"
                    variant="contained"
                    disableElevation
                    type="button"
                    onClick={() => {
                      openEditPermissionsModal(
                        { user, submitCallback, modalType: ModalTypeEnum?.ENTITY_ACCESS },
                      );
                    }}
                  >
                    Edit Permissions
                  </StyledButton>
                  )}
                </div>
              </div>
              <DataTable
                columns={columns({
                  classes,
                  handleDrawerToggle: toggle,
                  status: user?.status || '',
                  user,
                })}
                data={entities || []}
                page={page}
                pageSize={items}
                setPage={setPage}
                isLoading={isLoading}
                totalRowCount={totalRowCount}
                getRowId={(row: any) => row.id}
                {...(getRowHeight && { getRowHeight: () => 'auto' })}
                noRowsLabel="Unable to retrieve the entities for this user"
              />
            </Card>
          </CSSTransition>
        </Box>
      )}
    </div>
  );
};

export default User;
