import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    container: {
      height: '100%',
      minHeight: 'calc(100vh - 310px)',
      position: 'relative',
      paddingBottom: 30,
      '& .MuiBox-root > table > tbody > tr > td': {
        fontSize: '16px',
        padding: '7px 0',
        '&:last-child': {
          fontWeight: 'bold',
        },
      },
    },
    alertBanner: {
      paddingTop: 25,
    },
    header: {
      display: 'flex',
      margin: '4px 0px 4px 0px',
      height: '4vh',
    },
    stats: {
      width: '100%',
      padding: '40px 0',
      borderBottom: '1px solid #efefef',
      margin: '0 0 20px 0',
    },
    pullRight: {
      float: 'right',
      '& div': {
        margin: '0 0 0 80px',
      },
      '& > div:nth-of-type(3)': {
        fontWeight: 'bold',
      },
    },
  }),
  { name: 'CurrencyAccounts' },
);

export default useStyles;
