import { useSelector, useDispatch } from 'react-redux';
import { User } from '@alpha/profile-dtos';
import { useState } from 'react';
import { TStore } from '../store';
import ProfileService from '../services/Profile/profile.service';
import useAlphaSnackbar from './useAlphaSnackbar';
import { actions } from '../store/user/user.reducer';

const useProfile = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const userProfile = useSelector<TStore, User | undefined>((store) => store.user.profileDetails);
  const dispatch = useDispatch();

  const sb = useAlphaSnackbar();

  const updateUserProfile = async () => {
    try {
      setLoading(true);
      const response = await ProfileService.getUserProfile();
      dispatch(actions.updateProfileDetails(response));
    } catch (e) {
      sb.trigger(e.response?.data?.error || e.message || 'Error getting user profile');
    } finally {
      setLoading(false);
    }
  };

  return {
    updateUserProfile,
    userProfile,
    loading,
  };
};

export default useProfile;
