import { TKeyValue } from 'components/Table/Table.interfaces';
import React from 'react';
import KeyValueTable from 'components/Table/KeyValueTable/KeyValueTable';
import { TTransactionData } from '../../../../../../models/transactions';
import AccountInformation from '../AccountInformation/AccountInformation';
import useStyles from './DebitingDetails.styles';
import FxRate from './FxRate/FxRate';

interface IProps {
  transaction: TTransactionData;
  debitingDetails: TKeyValue[];
}

const DebitingDetails = (props: IProps) => {
  const classes = useStyles();
  const { transaction, debitingDetails } = props;
  const { debitCurrencyCode, debitType, debitIban, debitReference, debitFriendlyName, fxRate } = transaction;

  return (
    <>
      <AccountInformation
        header="debiting"
        type={debitType}
        iban={debitIban}
        reference={debitReference}
        friendlyName={debitFriendlyName}
        currencyCode={debitCurrencyCode}
      />
      <KeyValueTable
        className={classes.debitingDetails}
        testId="transactions-drawer-debiting"
        data={debitingDetails}
        obscure
      />
      {fxRate && <FxRate fxRate={fxRate} />}
    </>
  );
};

export default DebitingDetails;
