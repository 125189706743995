import { PaymentBatchStatus } from '@alpha/payments-dtos';
import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import routes from '../../../../routes.path';
import useStyles from '../PendingTable/PendingTable.styles';
import { PaymentRouteEnum } from '../../../../models/payments';

interface IProps {
  batchStatus: PaymentBatchStatus;
  id: string;
  accountId: string;
  actionNeeded: boolean;
}

const ActionStatus: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const {
    batchStatus,
    id,
    accountId,
    actionNeeded,
  } = props;
  return (
    <>
      {
        batchStatus === 'UPLOADING'
          ? <Box data-testid={`action-${id}`} className={classes.processing}>Processing...</Box>
          : (
            <Link
              className={classes.link}
              to={
              routes.payments.multientity.batchSummaryRouteFunc(
                accountId,
                id,
                actionNeeded ? PaymentRouteEnum.ACTIONNEEDED : PaymentRouteEnum.INPROGRESS,
              )
              }
            >
              <Box
                data-testid={`action-${id}`}
                className={classes.action}
              >
                View
              </Box>
            </Link>
          )
      }
    </>
  );
};

export default ActionStatus;
