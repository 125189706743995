import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    buttons: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      '& > button:first-child': {
        marginLeft: 'auto',
        marginRight: 8,
      },
      '& button': {
        fontSize: 12,
        height: '100%',

      },
    },
  }),
  { name: 'ErrorPaymentButtons' },
);

export default useStyles;
