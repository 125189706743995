import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import Status from 'components/Status/Status';

interface IProps {
  number: number;
}

const PendingInvoice: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    number,
  } = props;

  const title = `Pending Invoice (${number})`;

  return (
    <Box className="dd-privacy-allow">
      <Link to="/app/invoices" data-testid="invoices-link">
        <Status variant="error">
          {title}
        </Status>
      </Link>
    </Box>
  );
};

export default PendingInvoice;
