/* eslint-disable max-lines-per-function */
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { IATDraftResponse } from '@alpha/iat-dtos';
import { IATDraftStatus } from '@alpha/iat-types';
import { datadogRum } from '@datadog/browser-rum';
import { TStore } from '../../store';
import useAuthorization from '../../hooks/useAuthorization';
import { UserRole } from '../../models/user';
import useAlphaSnackbar from '../../hooks/useAlphaSnackbar';
import useForm from '../../hooks/useForm';
import validation, { TInterAccountTransferForm, initialValues } from './FormValues';
import InterAccountTransferService from '../../services/InterAccountTransfer/interAccountTransfer.service';
import useAuthorizationMultiEntity from '../../hooks/useAuthorizationMultiEntity';

type TPostApprovalParams = {
  iatStatus?: IATDraftStatus,
  requiresFx: boolean,
  iatId: string,

}

const useInterAccountTransferForm = (
  setSelectedDraftIAT: Dispatch<SetStateAction<IATDraftResponse | undefined>>,
  handleOpenTransactionDataDrawer: () => void,
  handleCloseIATDrawer: () => void,
  initialFormValues: TInterAccountTransferForm,
  selectedDraftIAT?: IATDraftResponse,
  accountId?: string,
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [approverOwn, setApproverOwn] = useState<boolean>(false);
  const [fxBooker, setFxBooker] = useState<boolean>(false);
  const [drawerState, setDrawerState] = useState<'BookFX' | 'DebitAndCredit'>(
    'DebitAndCredit',
  );

  const username = useSelector<TStore>((store) => store.user.user?.Username);
  const { roles: authRoles } = useAuthorization();
  const canBookFX = authRoles.includes(UserRole.FX_IAT);
  const canApproveOwn = authRoles.includes(UserRole.IAT_APPROVER_OWN);
  const { getRolesForAccount } = useAuthorizationMultiEntity();

  const getApproverOwn = async (_accountId: string) => {
    const roles = await getRolesForAccount(_accountId);
    if (roles.includes(UserRole.IAT_APPROVER_OWN)) {
      setApproverOwn(true);
    }
  };

  const getBookFx = async (_accountId?: string, _selectedDraftIAT?: IATDraftResponse) => {
    if (_selectedDraftIAT) {
      if (_accountId) {
        return setFxBooker(_selectedDraftIAT.currentUserCanBookFx || false);
      }
      return setFxBooker(canBookFX);
    }
    if (_accountId) {
      const roles = await getRolesForAccount(_accountId);
      return setFxBooker(roles.includes(UserRole.FX_IAT));
    }
    return setFxBooker(canBookFX);
  };

  useEffect(() => {
    if (accountId) {
      getApproverOwn(accountId);
    } else {
      setApproverOwn(canApproveOwn);
    }
  }, [accountId]);

  useEffect(() => {
    getBookFx(accountId, selectedDraftIAT);
  }, [selectedDraftIAT, accountId]);

  useEffect(() => {
    if (selectedDraftIAT) {
      if (selectedDraftIAT.requiresFx && fxBooker && selectedDraftIAT.status === 'APPROVED') {
        setDrawerState('BookFX');
      }
    }
  }, [selectedDraftIAT, fxBooker]);
  const snackbar = useAlphaSnackbar();

  const form = useForm<TInterAccountTransferForm>(
    initialFormValues,
    validation,
    submitForm,
    true,
    Boolean(selectedDraftIAT),
  );
  async function submitForm(
    formValues: TInterAccountTransferForm,
  ): Promise<void> {
    try {
      setLoading(true);

      let postApprovalParams: TPostApprovalParams | undefined;
      // Happens when you open existing IAT
      if (selectedDraftIAT && username) {
        postApprovalParams = await submitPendingIAT(selectedDraftIAT);
      } else {
        postApprovalParams = await submitNewIAT(formValues);
      }
      if (!postApprovalParams) throw Error('Post Approval params was empty');
      await handlePostApproval(postApprovalParams);
    } catch (e) {
      datadogRum.addAction('ERROR SUBMITTING IAT');
      snackbar.trigger(e.message || e.response?.data?.error || 'Error Submitting your IAT');
    } finally {
      setLoading(false);
    }
  }

  async function submitPendingIAT(
    _selectedDraftIAT: IATDraftResponse,
  ): Promise<TPostApprovalParams> {
    let newStatus: IATDraftStatus = _selectedDraftIAT.status;

    // Approve - if status not approved and curr user can approve
    if (_selectedDraftIAT.currentUserCanApprove && _selectedDraftIAT.status !== 'APPROVED') {
      newStatus = await approveIAT(_selectedDraftIAT.id,
        _selectedDraftIAT.submittedById === username);
      setSelectedDraftIAT({ ..._selectedDraftIAT, status: newStatus });
    }
    datadogRum.addAction(`IAT SUBMITTED ${_selectedDraftIAT.requiresFx ? 'REQUIRE FX' : 'DOES NOT REQUIRE FX'}`);
    return {
      iatStatus: newStatus,
      requiresFx: _selectedDraftIAT.requiresFx,
      iatId: _selectedDraftIAT.id,
    };
  }

  async function submitNewIAT(
    formValues: TInterAccountTransferForm,
  ): Promise<TPostApprovalParams | undefined> {
    try {
      const submittedIAT = await submitIAT(formValues);
      if (!submittedIAT?.iatId) return undefined;
      datadogRum.addAction(`IAT SUBMITTED ${submittedIAT.requiresFx ? 'REQUIRE FX' : 'DOES NOT REQUIRE FX'}`);

      return {
        iatStatus: submittedIAT.iatStatus,
        requiresFx: Boolean(submittedIAT.requiresFx),
        iatId: submittedIAT.iatId,
      };
    } catch (e) {
      if (e.response?.data?.errors) {
        setFieldErrors(e.response.data.errors);
      }

      throw Error('There was an error submitting your IAT');
    }
  }

  async function approveIAT(
    draftId: string,
    approveOwn: boolean,
  ): Promise<IATDraftStatus> {
    try {
      const approvalResponse = await InterAccountTransferService.approveInterAccountTransfer(
        draftId,
        approveOwn,
        accountId,
      );
      datadogRum.addAction('IAT APPROVED');
      snackbar.trigger('IAT has been approved', 'success');

      return approvalResponse.status;
    } catch (e) {
      throw Error(e.response?.data?.error || e.message || 'There was an error approving your transfer');
    }
  }

  async function handlePostApproval(postApprovalParams: TPostApprovalParams) {
    const { iatStatus, iatId, requiresFx } = postApprovalParams;
    if (iatStatus === 'APPROVED') {
      if (requiresFx && fxBooker) {
        form.setFieldValue('iatDraftId', iatId);
        setDrawerState('BookFX');
        return; // we don't want to close the drawer - hence returning here
      }
      if (!requiresFx) {
        // flow ends here
        const success = await releaseIAT(iatId, selectedDraftIAT?.accountId || accountId);
        if (success) {
          handleOpenTransactionDataDrawer();
          datadogRum.addAction('IAT SUCCESSFULLY RELEASED');
          snackbar.trigger('IAT successfully released', 'success');
        }
      }
    }
    handleCloseIATDrawer();
  }

  async function submitIAT(formValues: TInterAccountTransferForm): Promise<
    TPostApprovalParams | undefined> {
    try {
      let iatStatus: IATDraftStatus | undefined;

      const iatId = await InterAccountTransferService.submitInterAccountTransfer(
        formValues, accountId,
      );

      if (approverOwn) {
        iatStatus = await approveIAT(iatId, true);
      } else {
        datadogRum.addAction('IAT SUBMITTED FOR APPROVAL');
        snackbar.trigger('Successfully submitted IAT for approval', 'success');
      }

      const requiresFx = Boolean(formValues.indicativeRate);

      return {
        iatId,
        iatStatus,
        requiresFx,
      };
    } catch (e) {
      throw Error(e.response?.data?.error);
    }
  }

  const setFieldErrors = (errors: { errors: string[]; key: string }[]) => {
    errors.forEach((error) => {
      switch (error.key) {
        case 'amount':
          return form.setFieldError('debitingAccount.amount', error.errors[0]);
        case 'debitCurrencyAccountId':
          return form.setFieldError(
            'debitingAccount.currencyAccount',
            error.errors[0],
          );
        case 'creditCurrencyAccountId':
          return form.setFieldError(
            'creditingAccount.currencyAccount',
            error.errors[0],
          );
        case 'reference':
          return form.setFieldError(
            'creditingAccount.reference',
            error.errors[0],
          );
        default:
          return null;
      }
    });
  };

  async function releaseIAT(
    iatId: string,
    accountID?: string,
  ): Promise<true> {
    try {
      if (accountID) {
        await InterAccountTransferService.releaseSameCurrencyIAT(iatId, accountID);
      } else {
        await InterAccountTransferService.releaseSameCurrencyIAT(iatId);
      }
      return true;
    } catch (e) {
      throw Error(e.response?.data?.error || e.message || 'There was an error releasing your IAT');
    }
  }

  return {
    form,
    loading,
    drawerState,
    setDrawerState,
    submitForm,
    approverOwn,
    setApproverOwn,
  };
};

export default useInterAccountTransferForm;
