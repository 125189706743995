import { Box, Typography } from '@mui/material';
import { makeStyles } from 'theme';

import React, { ChangeEventHandler } from 'react';
import SearchInput from '../../../../../../../../components/Inputs/Search/SearchInput';

const useStyles = makeStyles(() => ({
  headerWrapper: {
    position: 'relative',
    zIndex: 100,
    top: 0,
    left: 0,
    width: '100%',
    padding: '60px 30px 9px 30px',
    backgroundColor: 'white',
    borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    '& .searchWrapper': {
      padding: '11px 0',
      width: '100%',
    },
  },
  headerTitle: {
    '& > h2': {
      lineHeight: '30px',
      fontSize: '22px',
      fontWeight: '600',
      letterSpacing: '-0.5px',
    },
  },
  controls: {
    margin: '8px 0',
    width: '100%',
    display: 'inline',
  },
}), { name: 'Header' });

interface ISelectBeneDrawerHeaderProps {
  handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

const SelectBeneDrawerHeader: React.FC<ISelectBeneDrawerHeaderProps> = ({
  handleInputChange,
}: ISelectBeneDrawerHeaderProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.headerWrapper}>
      <Box className={styles.headerTitle}>
        <Typography variant="h2">Select Beneficiary</Typography>
      </Box>
      <div className="searchWrapper">
        <SearchInput
          placeholder="Type to search your list of beneficiaries"
          onChange={handleInputChange}
          className={styles.controls}
          testId="select-bene-search"
        />
      </div>
    </Box>
  );
};
export default SelectBeneDrawerHeader;
