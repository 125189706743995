import { StyledTabsWrapper } from 'components/Tabs';
import React from 'react';
import { theme } from 'theme/themes/default';
import { ISelectedAccount } from '../AccountInfoDrawer';
import HeaderButtons from './HeaderButtons/HeaderButtons';
import HeaderText from './HeaderText/HeaderText';
import useStyles from './styles';
import HeaderButtonsMultiEntity from './HeaderButtons/HeaderButtonsMultiEntity';

interface IProps {
  selectedAccount: ISelectedAccount | undefined;
  selectedTabIndex: number;
  transfersCount: number;
  viewOnly?: boolean;
  handleChange: (index: number) => void;
  handleOpenDrawer: (drawer: 'IAT' | 'AccountDrawer' | false) => void;
  inputterAccounts: string[];
  multiEntity?: boolean;
  testId?: string;
}

const Header: React.FC<IProps> = ({
  selectedAccount,
  selectedTabIndex,
  transfersCount,
  viewOnly,
  handleChange,
  handleOpenDrawer,
  inputterAccounts,
  multiEntity,
  testId,
}: IProps) => {
  const styles = useStyles();

  const currCode = selectedAccount?.account.currencyCode
    ? selectedAccount?.account.currencyCode
    : selectedAccount?.tabs.currencyCode;

  return (
    <div className={styles.container}>
      <HeaderText selectedAccount={selectedAccount} currencyCode={currCode} data-testid={testId} />
      <div className={styles.tabs}>
        <StyledTabsWrapper
          testId="header-tabs"
          tabTitles={viewOnly ? ['info'] : ['info', <TabTitle number={transfersCount} />]}
          tabIndex={selectedTabIndex}
          handleChange={handleChange}
          iconTitles={false}
        />
        {!viewOnly &&
          (multiEntity && selectedAccount?.account?.accountId ? (
            <HeaderButtonsMultiEntity
              currencyAccountId={selectedAccount?.account.id || '-'}
              currencyAccountType={selectedAccount?.account.type}
              handleOpenDrawer={handleOpenDrawer}
              entity={selectedAccount?.account?.accountId}
              inputter={inputterAccounts.includes(selectedAccount?.account?.accountId)}
            />
          ) : (
            <HeaderButtons
              currencyAccountId={selectedAccount?.account.id || '-'}
              currencyAccountType={selectedAccount?.account.type}
              handleOpenDrawer={handleOpenDrawer}
            />
          ))}
      </div>
    </div>
  );
};

export default Header;

const TabTitle = ({ number }: { number: number }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    Transfer
    <div
      style={{
        backgroundColor: theme.palette?.primary?.main,
        width: '22px',
        height: '22px',
        color: 'white',
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '8px',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          transform: 'translate(-45%,-54%)',
          top: '50%',
          left: '50%',
          fontSize: '12px',
        }}
      >
        {number}
      </div>
    </div>
  </div>
);
