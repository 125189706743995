import { APMainLayout } from 'components/Layout/MainLayout';
import React from 'react';
import routes from '../../../routes.path';
import Body from './Body/Body';
import Tabs from './Tabs/Tabs';
import Title from './Title/Title';

const breadCrumbs = [
  { text: 'Home', url: routes.home },
  { text: 'Payments', url: routes.payments.base },
  { text: 'Manual' },
];

const ManualPayments: React.FC = () => (
  <APMainLayout
    data-testid="main-layout"
    title={<Title />}
    breadCrumbs={breadCrumbs}
    pageTabs={<Tabs />}
  >
    <Body />
  </APMainLayout>
);
export default ManualPayments;
