import _default, { makeStyles } from 'theme';
import shade from 'theme/utils';

const {
  palette, typography,
} = _default;

const useStyles = makeStyles(
  () => ({
    root: {
      paddingTop: 25,
    },
    tabContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      minHeight: 'calc(100vh - 550px)',
      '& > div': {
        width: '100%',
      },
    },
    boxShadow: {
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.03)',
    },
    outstandingDashboardContainer: {
      paddingRight: '16px',
    },
    cardContent: {
      marginRight: '2px',
      position: 'relative',
      paddingLeft: '32px',
      paddingTop: '32px',
    },
    viewAllButton: {
      position: 'absolute',
      right: '24px',
      top: '26px',
      zIndex: 999,
      color: shade(palette.secondary.light, 6),
      '& > button > div': {
        fontWeight: typography.fontWeightBold,
        color: palette.secondary.dark,
      },

    },
    tableHeader: {
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold,
      fontSize: '16px',
      paddingBottom: '8px',
    },
    backgroundColouringClass: {
      backgroundColor: '#FAFAFA',
    },
    entity: {
      maxWidth: 200,
      '& p': {
        paddingRight: 30,
      },
    },
  }),
  { name: 'OutstandingActionsDashboard' },
);

export default useStyles;
