import { RolesEnum } from '@portal/user-types/dist/schema';
import { ApprovalConfig } from '@portal/user-types';
import { FormikValues } from 'formik';
import { INestedStep } from 'components/Modals/WizardModal/context/reducer';
import { TGroupedRoles } from '../components/Permissions';
import { TRole } from '../context/index.interfaces';

export enum UserStatusEnum {
  PENDING_PERMISSION = 'PENDING PERMISSION',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  REJECTED = 'REJECTED'
}

export const defaultRoles = [
  RolesEnum.READ_ONLY_REPORTING,
  RolesEnum.INVOICE_MANAGER,
  RolesEnum.ENTITY_VIEWER,
];

export const approverRoles = [
  RolesEnum.PAYMENTS_APPROVER,
  RolesEnum.PAYMENTS_APPROVER_OWN,
  RolesEnum.IAT_APPROVER,
  RolesEnum.IAT_APPROVER_OWN,
];

export const isRoleDisabled = (role: RolesEnum) => role === RolesEnum.READ_ONLY_REPORTING;

export const isApproverLevelRequired = (roles: Array<RolesEnum>) => roles.some((
  role: RolesEnum,
) => approverRoles.includes(role));

export const groupRolesByGroup = (roles: TRole[], type: 'checkbox' | 'radio' = 'checkbox') => roles
  .reduce((
    acc: {[key: string]: TGroupedRoles[]}, role,
  ) => {
    const { group } = role;
    if (group) {
      const groupObj = acc[group] || [];
      groupObj.push({
        ...role,
        ...(role.id === RolesEnum.READ_ONLY_REPORTING && { level: 'Yes' }),
        type,
      });

      return { ...acc, [group]: groupObj };
    }
    return acc;
  }, {});

export const isValidApproverLevel = (
  approvalConfig: ApprovalConfig,
  approverLevel: string,
) => approvalConfig?.requirements?.some(
  (requirement) => requirement.approvalsRequired.some(
    (app) => app.level === approverLevel,
  ),
);

export const validateApproverLevel = (
  values: FormikValues,
  step: number,
  nestedSteps: Array<INestedStep>,
  validateOnStep: number,
) => {
  let errors = {};
  const currentEntity = values.ids[nestedSteps[step].current];

  if (values.ids && currentEntity?.id && step === validateOnStep) {
    const {
      roles,
      approverLevel,
    } = values.permissions[currentEntity.id];

    const { approvalConfig } = currentEntity;

    const requiresApprovalLevel = isApproverLevelRequired(roles);

    const missingApprovalConfig = requiresApprovalLevel && !approvalConfig?.requirements;
    const invalidApproverLevel = requiresApprovalLevel
        && !isValidApproverLevel(approvalConfig, approverLevel)
        && !approverLevel;

    if (missingApprovalConfig || invalidApproverLevel) {
      errors = { ...errors, permissions: { approverLevel: true } };
    }
  }
  return errors;
};
