import { TAuthyStatus } from '../../models/user';
import instance from '../Axios/instance';

export class TwoFactorAuthService {
  public static async getAuthyStatus(): Promise<TAuthyStatus> {
    const response = await instance.get('/auth/mfa/user-status');
    return response.data;
  }
}

export default TwoFactorAuthService;
