import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Input } from 'components/Inputs/Input';
import moment, { Moment } from 'moment';
import { TextField, Typography } from '@mui/material';
import {
  MenuItemStyled,
  SelectPlaceholderStyled,
  SelectStyled,
  APDatePickerStyled,
} from './styles';
import {
  ContinueUntil,
  Frequency,
  continueUntilOptions,
  defaultContinueUntil,
} from './constants';
import useSinglePaymentContext from '../../useSinglePaymentContext';

type IProps = {
  selectedDate: string;
  selectedRepeat: string | null;
};

const ContinueUntilDropdown: FC<IProps> = ({ selectedRepeat, selectedDate }) => {
  const { singlePaymentContext } = useSinglePaymentContext();
  const defaultNumberOfPayments = '1';
  const defaultStartDate = useMemo(() => moment(selectedDate).add(1, 'day').format('YYYY-MM-DD'), [selectedDate]);
  const [
    selectedContinueUntil,
    setSelectedContinueUntil,
  ] = useState<string | null>(defaultContinueUntil);
  const [noOfPayments, setNoOfPayments] = useState<string>(defaultNumberOfPayments);
  const [selectedSpecificDate, setSelectedSpecificDate] = useState<string>(defaultStartDate);
  const [minDate, setMinDate] = useState<string>(defaultStartDate);
  const showValueOrPlaceHolder = selectedContinueUntil || 'placeholder';
  const formattedPreselectedDate = singlePaymentContext?.currentPayment.endDate ? singlePaymentContext.currentPayment.endDate.split('/').reverse().join('-') : null;

  useEffect(() => {
    if (formattedPreselectedDate) {
      setSelectedContinueUntil(ContinueUntil.ON_DATE);
      setSelectedSpecificDate(formattedPreselectedDate);
    } else if (singlePaymentContext?.currentPayment.numberOfPayments) {
      setSelectedContinueUntil(ContinueUntil.SET_NUMBER);
      setNoOfPayments(singlePaymentContext.currentPayment.numberOfPayments);
    }
  }, []);

  const handleDropdownChange = useCallback((event: any) => {
    setSelectedContinueUntil(event.target.value);
    setNoOfPayments(defaultNumberOfPayments);
    const newCurrentPaymentData: any = {};
    switch (event.target.value) {
      case ContinueUntil.UNTIL_FURTHER_NOTICE:
        newCurrentPaymentData.endDate = undefined;
        newCurrentPaymentData.numberOfPayments = undefined;
        break;

      case ContinueUntil.SET_NUMBER:
        newCurrentPaymentData.endDate = undefined;
        newCurrentPaymentData.numberOfPayments = defaultNumberOfPayments;
        break;

      case ContinueUntil.ON_DATE:
        newCurrentPaymentData.endDate = defaultStartDate;
        newCurrentPaymentData.numberOfPayments = undefined;
        break;

      default:
        newCurrentPaymentData.endDate = undefined;
        newCurrentPaymentData.numberOfPayments = undefined;
    }

    singlePaymentContext?.setCurrentPayment({
      ...singlePaymentContext.currentPayment,
      ...newCurrentPaymentData,
    });
  }, [defaultStartDate, singlePaymentContext]);

  const handleInputChange = useCallback((event: any) => {
    const { target: { value } } = event;
    if (value && !Number(value)) return;

    setNoOfPayments(value);
    singlePaymentContext?.setCurrentPayment({
      ...singlePaymentContext.currentPayment,
      numberOfPayments: value,
    });
  }, [singlePaymentContext]);

  const handleDateChange = useCallback((_date: Moment) => {
    setSelectedSpecificDate(_date.format('YYYY-MM-DD'));
    singlePaymentContext?.setCurrentPayment({
      ...singlePaymentContext.currentPayment,
      endDate: _date.format('DD/MM/YYYY'),
    });
  }, [singlePaymentContext]);

  useEffect(() => {
    let startDate = null;

    if (!selectedRepeat) {
      startDate = defaultStartDate;
    } else {
      switch (selectedRepeat) {
        case Frequency.WEEKLY:
          startDate = moment(selectedDate).add(1, 'week');
          break;
        case Frequency.FORTNIGHTLY:
          startDate = moment(selectedDate).add(2, 'weeks');
          break;
        case Frequency.FOURWEEKS:
          startDate = moment(selectedDate).add(4, 'weeks');
          break;
        case Frequency.MONTHLY:
          startDate = moment(selectedDate).add(1, 'month');
          break;
        case Frequency.QUARTERLY:
          startDate = moment(selectedDate).add(3, 'months');
          break;
        case Frequency.YEARLY:
          startDate = moment(selectedDate).add(1, 'year');
          break;
        default:
          startDate = moment(selectedDate).add(1, 'day');
      }
      startDate = startDate.format('YYYY-MM-DD');
    }

    if (formattedPreselectedDate && formattedPreselectedDate < startDate) {
      setSelectedSpecificDate(startDate);
    }
    setMinDate(startDate);
  }, [selectedRepeat, defaultStartDate, selectedDate]);

  return (
    <>
      <Typography variant="subtitle1" className="label" fontSize={14} style={{ marginTop: 20 }}>
        Continue until
      </Typography>
      <SelectStyled
        data-testid="standingOrder-ContinueUntilDropdown"
        onChange={handleDropdownChange}
        value={showValueOrPlaceHolder}
        fullWidth
        isSelected={!!selectedContinueUntil}
      >
        <SelectPlaceholderStyled key="iat-placeholder" value="placeholder">
          <Typography variant="subtitle1">
            Please select
          </Typography>
        </SelectPlaceholderStyled>
        {
          continueUntilOptions.map((opt) => (
            <MenuItemStyled value={opt.name} key={opt.name} data-testid={`standingOrder-ContinueUntilDropdown-${opt.name}`}>
              <Typography variant="subtitle1">
                { opt.label }
              </Typography>
            </MenuItemStyled>
          ))
        }
      </SelectStyled>
      {
        selectedContinueUntil === ContinueUntil.SET_NUMBER && (
          <>
            <Typography variant="subtitle1" className="label" fontSize={14} style={{ marginTop: 20 }}>
              Number of payments
            </Typography>
            <Input
              onChange={handleInputChange}
              id="standingOrder-setNumberInput"
              variant="filled"
              value={noOfPayments || ''}
              testId="standingOrder-setNumberInput"
              placeholder="Enter number of payments"
            />
          </>
        )
      }
      {
        selectedContinueUntil === ContinueUntil.ON_DATE && (
          <div data-testid="standingOrder-datepickerBox">
            <Typography variant="subtitle1" className="label" fontSize={14} style={{ marginTop: 20 }}>
              Specific date
            </Typography>
            <APDatePickerStyled
              label=""
              onChange={handleDateChange}
              value={moment(selectedSpecificDate) || ''}
              format="DD/MM/YYYY"
              renderInput={(params: any) => <TextField {...params} />}
              minDate={moment(minDate)}
              fullWidth
            />
          </div>
        )
      }
    </>
  );
};

export default memo(ContinueUntilDropdown);
