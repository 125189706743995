import { isArray } from 'lodash';
import React from 'react';
import Dropdown, { AccountSelectionEnum } from '../../../components/Transactions/Dropdown/Dropdown';
import useTransactionsSelect from './useTransactionsSelect';

interface IProps {
  setApplySearch: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

const TransactionsSelect: React.FC<IProps> = (props: IProps) => {
  const { setApplySearch, disabled } = props;
  const {
    transactionContext,
    handleClickOnGroupedCurrencyHeader,
    handleAllAccountsClicked,
  } = useTransactionsSelect();

  const onDropDownSelectionChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string[] | unknown;
    }>,
  ) => {
    if (isArray(event?.target?.value)
    && event?.target?.value[event?.target?.value.length - 1] !== undefined) {
      const onChangeArray = event?.target.value.filter((onChangeItem) => !onChangeItem.includes('placeholder'));
      const indexOfCurrentChangeItem = onChangeArray.length - 1;
      switch (true) {
        case onChangeArray[indexOfCurrentChangeItem].includes(AccountSelectionEnum.HEADER):
          handleClickOnGroupedCurrencyHeader(
            onChangeArray,
            indexOfCurrentChangeItem,
          );
          break;
        case onChangeArray[indexOfCurrentChangeItem].includes(AccountSelectionEnum.ALL_ACCOUNTS):
          handleAllAccountsClicked(onChangeArray);
          break;
        default:
          transactionContext?.setCurrencyAccountIds(onChangeArray);
          break;
      }
    } else if (isArray(event?.target?.value)
     && event?.target?.value.length === 0) {
      transactionContext?.setCurrencyAccountIds([]);
    }
  };

  return (
    <div style={{ maxWidth: 'fit-content', marginBottom: 7 }}>
      <Dropdown
        total={transactionContext?.currencyAccountTotal || 0}
        disabled={disabled}
        setApplySearch={setApplySearch}
        currencyAccounts={transactionContext?.currencyAccountsOptions || []}
        handleChange={(event) => onDropDownSelectionChange(event)}
      />
    </div>
  );
};

export default TransactionsSelect;
