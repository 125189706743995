import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    listHolder: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      '& span': {
        display: 'block',
      },
    },
  }),
  { name: 'PermissionList' },
);

export default useStyles;
