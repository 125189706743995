import React from 'react';
import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import Alert from '../../../components/Alert/Alert';
import Form from './Form';
import useStyles from './styles';

interface IProps {
    open: boolean;
    handleDrawerClose: () => void;
    multiEntity?: boolean;
    accounts?: {
      value: string;
      label: string;}[];
  }

const CreateBeneficiary: React.FC<IProps> = (
  {
    open, handleDrawerClose, multiEntity, accounts,
  }: IProps,
) => {
  const styles = useStyles();
  const text = 'Before authorising this beneficiary, please ensure that you are certain the details are correct and that the payment request is genuine.';

  return (
    <BaseDrawer.Drawer
      open={open}
      anchor="right"
      onClose={handleDrawerClose}
      data-dd-privacy="allow"
      className={styles.drawer}
    >
      <BaseDrawer.Header className="dd-privacy-allow" headerTitle="Create Beneficiary" />
      <div className={styles.lineBreakMargin}>
        <div className={styles.alertMessage}>
          <Alert variant="info" text={text} cancel />
        </div>
        <BaseDrawer.LineBreak />
      </div>
      <div className={styles.body}>
        <BaseDrawer.Body>
          <Form
            handleDrawerClose={handleDrawerClose}
            multiEntity={multiEntity || false}
            accounts={accounts}
          />
        </BaseDrawer.Body>
      </div>
    </BaseDrawer.Drawer>

  );
};

export default CreateBeneficiary;
