import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Form, Utils } from '@formio/react';
import { Helmet } from 'react-helmet';
import { useParams, NavLink } from 'react-router-dom';
import OnboardingService from 'services/Onboarding/onboarding.service';
import axios, { AxiosError } from 'axios';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import { datadogRum } from '@datadog/browser-rum';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Alert, Box } from '@mui/material';
import { authedReqOpts } from 'domain/Onboarding/api';
import SubmittedPage from './components/SubmittedPage';
import Header from './components/Header';
import AlphaWhiteLogo from '../../../../assets/alpha-full-logo.png';
import { StyledForm, StyledNavBar, StyledBody, StyledLoadingForm } from './index.styles';

const mapSubmissions = (submissions: any[]): { ids: string[]; submissions: any[] } =>
  submissions.reduce(
    (result, submission) => {
      const id = uuid();
      return {
        ids: [...result.ids, id],
        submissions: [...result.submissions, { ...submission, id }],
      };
    },
    { ids: [], submissions: [] },
  );

const OnboardingForm = ({
  ownerEmail,
  formUrl,
  formType,
  formId,
  submissionId,
}: {
  formType: 'new' | 'draft';
  ownerEmail?: string;
  formUrl: string;
  formId: string;
  submissionId?: string;
}) => {
  const id = useParams<{ id: string }>().id || submissionId;
  const sb = useRef(useAlphaSnackbar());

  const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [formIdLocal, setFormIdLocal] = useState<string>(); // Required to trigger a re-render
  const [showShareForm, setShowShareForm] = useState(formType === 'draft');
  const [showSubmittedPage, setShowSubmittedPage] = useState(false);
  const [isFormInitialised, setIsFormInitialised] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const tokenizedOptions = localStorage.getItem('formioToken') ? authedReqOpts() : {};
  useEffect(() => {
    if (isFormInitialised) {
      let editButton;
      let interval = 0;
      interval = setInterval(() => {
        editButton = document.querySelector('.editRow') as HTMLElement;
        editButton?.click();
        if (editButton) {
          window.clearInterval(interval as number);
        }
      });
    }
  }, [isFormInitialised]);
  useEffect(() => {
    const fetchSubmission = async () => {
      if (id) {
        try {
          const url = `${formUrl}/submission/${id}`;
          const {
            data: { state, form },
          } = await axios.get(url, tokenizedOptions);
          if (formType === 'draft') localStorage.setItem(id, state);
          if (state === 'submitted') {
            setShowShareForm(false);
            setIsFormSubmitted(true);
          }
          setFormIdLocal(formId);
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response?.status === 403) {
              setUnauthorized(true);
            }
          }
          sb.current.trigger('Something went wrong. Please contact customer support.');
          datadogRum.addError(error, {
            message: 'ERROR GETTING SUBMISSION FROM FORM.IO',
          });
        }
      }
    };
    fetchSubmission();
  }, [id, formType, formUrl]);

  const handleGlobalEnterKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const activeElement = document.activeElement as HTMLInputElement | null;

      if (activeElement && activeElement instanceof HTMLInputElement) {
        const inputFields = Array.from(document.querySelectorAll('input'));

        const currentIndex = inputFields.indexOf(activeElement);
        if (currentIndex !== -1 && currentIndex < inputFields.length - 1) {
          const nextField = inputFields[currentIndex + 1] as HTMLInputElement;
          nextField.focus();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleGlobalEnterKeyPress);

    return () => {
      window.removeEventListener('keydown', handleGlobalEnterKeyPress);
    };
  }, []);

  const saveSubmission = async ({
    coreSubmissionIds,
    form,
    state,
    submissions,
    formRating,
    formRatingReason,
    submitted,
  }: {
    coreSubmissionIds?: string[];
    form: string;
    submissions?: any[];
    state: 'draft' | 'submitted';
    formRating?: number;
    formRatingReason?: string;
    submitted?: boolean;
  }) => {
    if (id) {
      const data = {
        formIoSubmissionId: id,
        isDraft: state === 'draft',
        coreSubmissionIds,
        formRating,
        formRatingReason,
        submissions: submissions || [],
      };

      // using localStorage as Formio does not support React state updates
      // i.e. stores the firstmost state not the updated state
      try {
        // if using new secure form
        if (submissionId && submitted) {
          await OnboardingService.saveSecureSubmission({
            formId: form,
            formIoSubmissionId: id,
            coreSubmissionIds,
            formRating,
            formRatingReason,
            submissions: submissions || [],
          });
          return;
        }

        if (!localStorage.getItem(id) && ownerEmail) {
          await OnboardingService.createSubmission({
            ...data,
            owner: ownerEmail,
            formId: form,
            editors: [],
          });
        } else {
          await OnboardingService.updateSubmission(data);
        }
      } catch (error) {
        datadogRum.addError(error, {
          message: 'ERROR SAVING/UPDATING SUBMISSION',
        });
        throw error;
      }

      localStorage.setItem(id, state);
    }
  };

  const handleSaveDraft = async (data: any) => {
    try {
      if (id && formIdLocal) {
        await axios.put(
          `${formUrl}/submission/${id}`,
          {
            data,
            state: 'draft',
          },
          tokenizedOptions,
        );

        saveSubmission({
          form: formIdLocal,
          state: 'draft',
          submissions: [],
        });

        if (!showShareForm && !isFormSubmitted) {
          setShowShareForm(true);
        }

        sb.current.trigger('Draft successfully saved', 'success');
      }
    } catch (error) {
      sb.current.trigger('There was an error saving your draft.');
      datadogRum.addError(error, {
        message: 'ERROR SAVING DRAFT',
      });
    }
    setLoadingSaveDraft(false);
    setLoadingSubmit(false);
  };

  const handleSubmission = async (submission: any) => {
    const {
      form,
      data: { entities, formRating, formRatingReason, submittedByDetails },
      state,
    } = submission;

    let combinedEntities = [];
    if (!_.isEmpty(submittedByDetails)) {
      combinedEntities = entities.map((entity: any) => ({
        ...entity,
        submittedByDetails,
      }));
    } else {
      combinedEntities = entities;
    }

    let coreSubmissionIds: string[] = [];

    if (state === 'submitted') {
      const { submissions, ids } = mapSubmissions(combinedEntities);
      coreSubmissionIds = ids;
      try {
        await saveSubmission({
          coreSubmissionIds,
          form,
          state,
          submissions,
          formRating,
          formRatingReason,
          submitted: true,
        });

        setIsFormSubmitted(true);
        setShowSubmittedPage(true);
      } catch (error) {
        sb.current.trigger('There was an error on submission. Please contact customer support.');
        datadogRum.addError(error, {
          message: 'ERROR SENDING/SAVING SUBMISSION',
        });
      }
    }
    setLoadingSaveDraft(false);
    setLoadingSubmit(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://cdn.form.io/formiojs/formio.full.min.css" />
        <script src="https://cdn.form.io/formiojs/formio.full.min.js" />
      </Helmet>
      <StyledNavBar>
        <div>Onboarding</div>
        <div>
          <NavLink to="/">
            <img alt="logo" src={AlphaWhiteLogo} />
          </NavLink>
        </div>
      </StyledNavBar>

      {unauthorized && (
        <Box
          sx={{
            mt: '60px',
            p: 2,
          }}
        >
          <Alert severity="error">Error: Unauthorized </Alert>
        </Box>
      )}
      {!unauthorized && (
        <StyledBody>
          <Header
            id={id}
            showShareForm={!submissionId && showShareForm}
            ownerEmail={ownerEmail}
            isFormSubmitted={isFormSubmitted}
            loading={loadingSaveDraft || loadingSubmit}
            setLoadingSaveDraft={setLoadingSaveDraft}
            setLoadingSubmit={setLoadingSubmit}
            showSubmittedPage={showSubmittedPage}
            isFormInitialised={isFormInitialised}
            customMargin={submissionId ? '0px' : '58px'}
            formId={formIdLocal}
          />
          {id && formIdLocal && (
            <>
              {showSubmittedPage && <SubmittedPage setShowSubmittedPage={setShowSubmittedPage} />}

              {!showSubmittedPage && !isFormInitialised && (
                <StyledLoadingForm>
                  Loading Form
                  <FontAwesomeIcon icon={faSpinner} spin />
                </StyledLoadingForm>
              )}

              {!showSubmittedPage && (
                <StyledForm>
                  <Form
                    src={`${formUrl}/submission/${id || ''}`}
                    onCustomEvent={async (customEvent: any) => {
                      if (customEvent.type === 'customDraftSave') {
                        if (id) {
                          try {
                            await axios.put(
                              `${formUrl}/submission/${id}`,
                              {
                                data: customEvent.data,
                                state: 'draft',
                              },
                              tokenizedOptions,
                            );
                            handleSaveDraft(customEvent.data);
                          } catch (error) {
                            sb.current.trigger('Something went wrong when saving your draft.');
                            datadogRum.addError(error, {
                              message: 'ERROR SUBMITTING DATA TO FORM.IO',
                            });
                          }
                        }
                      }
                    }}
                    options={{
                      saveDraft: true,
                      readOnly: isFormSubmitted || loadingSaveDraft || loadingSubmit,
                    }}
                    formReady={(formInstance: any) => {
                      const entities = Utils.getComponent(formInstance.components, 'entities', true);
                      if (entities.editRows.length === 0) {
                        entities.addRowElements[0].click();
                      }
                      setIsFormInitialised(true);
                      formInstance.on('submitError', () => {
                        setLoadingSubmit(false);
                      });
                    }}
                    onSubmitDone={handleSubmission}
                  />
                </StyledForm>
              )}
            </>
          )}
        </StyledBody>
      )}
    </>
  );
};

export default OnboardingForm;
