import {
  Box, styled,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from 'components/Loader';
import APModal from '../APModal/APModal';

const StyledAPModalContainer = styled(APModal.Container)({
  maxWidth: '600px',
});

const StyledIcon = styled(FontAwesomeIcon)({
  color: 'rgb(255, 199, 0)',
  marginRight: 10,
  alignSelf: 'center',
});

const StyledHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  '& h2': {
    fontWeight: '400',
    fontSize: '22px',
    lineHeight: '46px',
  },
  '& img': {
    width: '25px',
    marginRight: '8px',
  },
});

const StyledBody = styled(Box)({
  padding: '24px 0',
  '& h6': {
    color: '#222222',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
  },
  '& .errorMessage': {
    fontWeight: 'bold',
    paddingBottom: '20px',
  },
});

const StyledButtonContainer = styled(Box)({
  textAlign: 'right',
  '& .closeButton': {
    fontWeight: 'bold',
  },
  '& .rejectButton': {
    marginLeft: '16px',
  },
});

const StyledLoader = styled(Loader)({
  position: 'relative',
  alignSelf: 'center',
});

export {
  StyledAPModalContainer,
  StyledHeader,
  StyledBody,
  StyledButtonContainer,
  StyledLoader,
  StyledIcon,
};
