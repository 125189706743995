import React, { FC } from 'react';
import {
  AmountStyled,
  DebitTitleStyled,
  EntityStyled,
} from 'domain/PaymentsMultiEntity/Dashboard/ScheduledTableContainer/styles';
import { TPayment } from 'models/payments';
import ActionDropDown from './ActionDropDown';
import { PaymentsDrawerTableHeadStyled } from './styles';

type IProps = {
  item: TPayment;
  onEditPayment?: () => void;
  onDeletePayment?: () => void;
}

const PaymentsDrawerHead: FC<IProps> = ({ item, onEditPayment, onDeletePayment }) => (
  <PaymentsDrawerTableHeadStyled>
    <div>
      <DebitTitleStyled variant="body1">Debit</DebitTitleStyled>
      <AmountStyled variant="body1">
        { item.paymentAmount || item.amount }
        &nbsp;
        <span>{ item.fixedSide || item.beneficiary?.currencyCode }</span>
      </AmountStyled>
      <EntityStyled variant="body1">{ item.beneficiary?.accountName || item.beneficiaryDraft?.name }</EntityStyled>
    </div>
    {
      !!onEditPayment && !!onDeletePayment && (
        <ActionDropDown
          onEditPayment={onEditPayment}
          onDeletePayment={onDeletePayment}
        />
      )
    }
  </PaymentsDrawerTableHeadStyled>
);

export default PaymentsDrawerHead;
