import { Typography } from '@mui/material';
import styled from '@mui/styled-engine';
import Table from 'components/Table/Table';
import { FrequencyIconStyled } from 'domain/PaymentsMultiEntity/Dashboard/ScheduledTableContainer/styles';
import _default from 'theme';

const { typography } = _default;

export const StandingOrdersStyled = styled(Typography)({
  paddingTop: '35px',
});

export const TitleStyled = styled(Typography)({
  margin: 0,
  fontWeight: typography.fontWeightSemiBold,
  fontSize: '22px',
  lineHeight: '46px',
  letterSpacing: '-1.1px',
});

export const TextStyled = styled(Typography)({
  fontSize: '12px',
  lineHeight: '17px',
  margin: 0,
  marginBottom: 20,
});

export const TableStyled = styled(Table)({
  marginTop: 20,
});

export const StandingOrdersHeaderStyled = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const DocumentsBoxStyled = styled('div')({
  background: '#f5f5f5',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px 5px',
  minWidth: 58,
  boxSizing: 'border-box',
  fontSize: 11,
});

export const DocumentsIconStyled = styled(FrequencyIconStyled)({
  marginRight: 10,
});

export const StandingOrdersFooterStyled = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});
