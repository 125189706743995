import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import useReportsDownload from '../../../hooks/useReportsDownload';
import { FileType } from '../../../hooks/useReportsPolling';
import { TStore } from '../../../store';
import { actions, TPaymentBatchDetails } from '../../../store/payments/payments.reducer';
import useStyles from './CreatePayment.styles';
import DisplayDrawer from './DisplayDrawer';
import DrawerHeader from './DrawerHeader/DrawerHeader';
import { TUserAccount } from '../../../models/user';

interface IProps {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  account?: TUserAccount;
}

const CreatePayment: React.FC<IProps> = (props: IProps) => {
  const { open, onClose, account } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const batchDetails = useSelector<TStore, TPaymentBatchDetails | undefined>(
    (state: TStore) => state.payments.batchDetails,
  );
  const {
    executeReportGeneration, download, setDownload, getDownloadLinkFromSnackKey,
  } = useReportsDownload();

  const handleClose = () => {
    onClose(false);
    setDownload(undefined);
    dispatch(actions.clear());
  };

  const handleDownload = async () => {
    if (batchDetails?.batchId && account) {
      const downloadLink = `/payments/batches/${batchDetails.batchId}/payments/invalid/file`;
      await executeReportGeneration(FileType.EXCEL, batchDetails.batchId, downloadLink, 'Payment error file', account.id);
    }
  };

  const displayHeaderTitle = (): JSX.Element | string => {
    if (
      batchDetails?.batchStatus === 'INVALID'
      || batchDetails?.totalInvalidPayments
    ) {
      let title: string = 'Payment Errors';
      if (batchDetails?.batchErrors && batchDetails?.batchErrors.length > 0) {
        title = 'Batch Errors';
      }
      return (
        <span>
          <b className={clsx(classes.headerText, 'dd-privacy-allow')}>{title}</b>
          {` - ${batchDetails?.batchName}`}
        </span>
      );
    }
    return batchDetails?.batchName || <span className="dd-privacy-allow">How would you like to create your payments?</span>;
  };

  return (
    <BaseDrawer.Drawer
      className={classes.drawerWrapper}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <DrawerHeader
        downloadLink={getDownloadLinkFromSnackKey(download?.key)}
        handleDownload={handleDownload}
        displayHeaderTitle={displayHeaderTitle}
        totalInvalidPayments={batchDetails?.totalInvalidPayments || 0}
      />
      <DisplayDrawer handleClose={handleClose} batchDetails={batchDetails} />
    </BaseDrawer.Drawer>
  );
};

export default CreatePayment;
