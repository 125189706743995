import { http as rest } from 'msw';
import { UserStatus } from '@portal/user-types';
import { getUsers, getUser } from './mocks/UserMock';
import { getEntities, getEntity } from './mocks/EntityMock';
import env from '../env.variables';
import getErrorJsonFromCode from './mocks/ErrorMock';
import getGroups from './mocks/GroupMock';
import getUserRoleRequests from './mocks/UserRoleRequestMock';

const baseUrl = () => {
  const { REACT_APP_API_URL: url } = env;
  return `${url}${url?.endsWith('/') ? '' : '/'}`;
};

const handlers = [
  // UserRolesRequest
  rest.post(`${baseUrl()}api/v1/user-roles-request`, async (req, res, ctx) => {
    const { user, roles } = await req.body.body;
    return (user || roles) ? res(
      ctx.status(201),
    ) : res(
      ctx.status(400),
      ctx.json(
        getErrorJsonFromCode(10),
      ),
    );
  }),
  // UserRolesRequestTOTP
  rest.post(`${baseUrl()}api/v1/user-roles-request/totp`, (req, res, ctx) => {
    const { method, requestId } = req.body;
    const message = 'XXXXXXXX1234';
    return method && requestId ? res(
      ctx.status(201),
      message,
    ) : res(
      ctx.status(400),
      ctx.json(
        getErrorJsonFromCode(20),
      ),
    );
  }),
  // GetAll Entities
  rest.get(`${baseUrl()}api/v1/entity/all`, (req, res, ctx) => {
    const page = req.url.searchParams.get('page');
    const items = req.url.searchParams.get('items');
    const approvalConfig = req.url.searchParams.get('approvalConfig');

    return page ? res(
      ctx.status(200),
      ctx.json(
        {
          data: getEntities(approvalConfig, items),
          totalItems: items,
        },
      ),
    ) : res(
      ctx.status(400),
      ctx.json(
        getErrorJsonFromCode(30),
      ),
    );
  }),
  // GetEntity
  rest.get(`${baseUrl()}api/v1/entity/:entityId`, (req, res, ctx) => {
    const { entityId } = req.params;
    const approvalConfig = req.url.searchParams.get('approvalConfig');
    const groups = req.url.searchParams.get('groups');
    const users = req.url.searchParams.get('users');
    const items = req.url.searchParams.get('items');

    return entityId ? res(
      ctx.status(200),
      ctx.json(
        {
          data: {
            entity: getEntity(approvalConfig, 0, true, true, groups),
            users: users && getUsers(parseInt(items, 10)),
          },
          totalItems: 100,
        },
      ),
    ) : res(
      ctx.status(400),
      ctx.json(
        getErrorJsonFromCode(30),
      ),
    );
  }),

  // GetUser
  rest.get(`${baseUrl()}api/v1/user/:userId`, (req, res, ctx) => {
    const page = req.url.searchParams.get('page');
    const items = req.url.searchParams.get('items');
    return page ? res(
      ctx.status(200),
      ctx.json(
        {
          data: { user: getUser(), entities: getEntities(true, items) },
          totalItems: items,
        },
      ),
    ) : res(
      ctx.status(400),
      ctx.json(
        getErrorJsonFromCode(40),
      ),
    );
  }),
  // GetAllUsersByStatus
  rest.get(`${baseUrl()}api/v1/user/all/status/active%2Binactive`, (req, res, ctx) => {
    const {
      page, items,
    } = req.params;

    return page ? res(
      ctx.status(200),
      ctx.json(
        {
          data: getUsers(items, [UserStatus.ACTIVE, UserStatus.INACTIVE]),
          totalItems: items,
        },
      ),
    ) : res(
      ctx.status(503),
    );
  }),
  rest.get(`${baseUrl()}api/v1/user/all/status/deactivated`, (req, res, ctx) => {
    const {
      page, items,
    } = req.params;

    return page ? res(
      ctx.status(200),
      ctx.json(
        {
          data: getUsers(items, [UserStatus.DEACTIVATED]),
          totalItems: items,
        },
      ),
    ) : res(
      ctx.status(503),
    );
  }),
  // GetAllGroups
  rest.get(`${baseUrl()}api/v1/group/all`, (req, res, ctx) => {
    const {
      page, items,
    } = req.params;

    return page ? res(
      ctx.status(200),
      ctx.json(
        {
          data: getGroups(items, true),
          totalItems: items,
        },
      ),
    ) : res(
      ctx.status(504),
    );
  }),
  // GetAllUserRoleRequestsByStatus
  rest.get(`${baseUrl()}api/v1/user/all/request/:status`, (req, res, ctx) => {
    const { status } = req.params;
    const page = req.url.searchParams.get('page');
    const items = req.url.searchParams.get('items');

    return page ? res(
      ctx.status(200),
      ctx.json(
        {
          data: getUserRoleRequests(page, items, status),
          totalItems: items,
        },
      ),
    ) : res(
      ctx.status(504),
    );
  }),
  // ApproveUserRoleRequest
  rest.put(`${baseUrl()}api/v1/admin/user-role-request/approve`, (req, res, ctx) => {
    const {
      requestId, totpCode,
    } = req.body;
    return requestId && totpCode === '123456' ? res(
      ctx.status(202),
    ) : res(
      ctx.status(500),
      ctx.json(getErrorJsonFromCode(50)),
    );
  }),
  // RejectUserRoleRequest
  rest.put(`${baseUrl()}api/v1/admin/user-role-request/reject`, (req, res, ctx) => {
    const {
      requestId,
    } = req.params;

    return requestId ? res(
      ctx.status(204),
    ) : res(
      ctx.status(504),
    );
  }),
  // ApproveSignatoryUserRoleRequest
  rest.put(`${baseUrl()}api/v1/admin/user-role-request/signatory-approve`, (req, res, ctx) => {
    const {
      requestId,
    } = req.params;

    return requestId ? res(
      ctx.status(204),
    ) : res(
      ctx.status(504),
    );
  }),
  // RejectSignatoryUserRoleRequest
  rest.put(`${baseUrl()}api/v1/admin/user-roles-request/signatory-reject`, (req, res, ctx) => {
    const {
      requestId,
    } = req.body;

    return requestId ? res(
      ctx.status(204),
    ) : res(
      ctx.status(504),
    );
  }),
  // ApplyUserRoles
  rest.put(`${baseUrl()}api/v1/admin/user`, (req, res, ctx) => {
    const {
      user, roles,
    } = req.params;

    return user && roles ? res(
      ctx.status(202),
    ) : res(
      ctx.status(504),
      ctx.json(getErrorJsonFromCode(60)),
    );
  }),
  // DeactivateUser
  rest.put(`${baseUrl()}api/v1/admin/user/deactivate`, (req, res, ctx) => {
    const {
      userId,
    } = req.params;

    return userId ? res(
      ctx.status(202),
    ) : res(
      ctx.status(401),
    );
  }),
  // Acknowledge Modal
  rest.post(`${baseUrl()}api/v1/user/acknowledge`, (req, res, ctx) => {
    const success = true;
    return success ? res(
      ctx.status(201),
    ) : res(
      ctx.status(401),
    );
  }),
];

export default handlers;
