import { Box } from '@mui/material';
import React from 'react';
import useDynamoTable from '../../../../hooks/useDynamoTable';
import { TDynamoTableQueryParams } from '../../../../services/DatabaseServices/DynamoService';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import useStyles from '../PendingTable/PendingTable.styles';
import DisplayTable from './DisplayTable/DisplayTable';

const initialTableQuery: TDynamoTableQueryParams = {
  url: 'payments/batches/processing',
  pageSize: 9,
};
const InProgressTable: React.FC = () => {
  const classes = useStyles();
  const columns: ITableColumn[] = [
    {
      header: <Box paddingRight="80px">ID</Box>,
      accessor: 'id',
    },
    {
      header: <Box>Type</Box>,
      accessor: 'type',
    },
    {
      header: <Box className={classes.textAlignLeft}>Uploaded by</Box>,
      accessor: 'uploadedBy',
    },
    {
      header: <Box className={classes.textAlignLeft}>uploaded date</Box>,
      accessor: 'uploadedDate',
    },
    {
      header: <Box className={classes.textAlignLeft}>Approved date</Box>,
      accessor: 'approvedDate',
    },
    {
      header: <Box className={classes.textAlignLeft}>payment Status</Box>,
      accessor: 'paymentStatus',
    },
    {
      header: <Box className={classes.textAlignLeft}>batch status</Box>,
      accessor: 'batchStatus',
    },
    {
      header: <Box className={classes.action}>Action</Box>,
      accessor: 'action',
    },
  ];
  const table = useDynamoTable(initialTableQuery);

  return <DisplayTable columns={columns} table={table} />;
};

export default InProgressTable;
