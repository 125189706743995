import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    hr: {
      borderTop: '1px solid #D0D0D0',
      transform: 'translateY(-45px)',
      width: '106.25%',
      left: '-6.25%',
      position: 'absolute',
      top: '0px',
      overflow: 'hidden',
    },
    root: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 29,

      '& .svg-inline--fa': {
        fontSize: '14px',
      },
    },
  }),
  { name: 'Footer' },
);

export default useStyles;
