import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
  { name: 'BeneInfoDrawer' },
);

export default useStyles;
