import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    entityDiv: {
      display: 'flex',
      gap: '4px',
    },
    tooltipTitle: {
      'font-weight': 'bold',
    },
    divider: {
      'border-color': 'white',
      'border-bottom-width': '0px',
      'border-top-width': 'thin',
      'margin-top': '4px',
    },
    faDoNotEnter: {
      display: 'flex',
      'align-items': 'center',
    },
  }),
  { name: 'ApprovedTable' },
);

export default useStyles;
