import { CurrencyAccountSearchDto } from '@alpha/currency-accounts-dtos';
import React from 'react';
import SearchTable from '../../../components/Table/SearchTable';
import { TextEllipsis } from '../../../components/TextEllipsis';
import useAuthorization from '../../../hooks/useAuthorization';
import { TSearchParams } from '../../../hooks/useSearch';
import useSwitchAccount from '../../../hooks/useSwitchAccount';
import { UserRole } from '../../../models/user';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';
import { formatCurrency } from '../../../utils/currency.helpers';
import Buttons from './Buttons/Buttons';
import ButtonsMultiEntity from './Buttons/ButtonsMultiEntity';
import useStyles from './CurrencyAccountsTable.styles';
import FriendlyName from './CurrencyAccountTableRow';
import PendingInvoice from './PendingInvoice/PendingInvoice';

interface IProps {
  currencyAccounts: CurrencyAccountSearchDto[];
  hasNext: boolean;
  hasPrevious: boolean;
  loading: boolean;
  handleNextPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handlePreviousPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handleAccountTableRowClick: (account: CurrencyAccountSearchDto) => void;
  handleOpenDrawer: (_drawer: 'IAT' | 'AccountDrawer' | 'PendingTransfers'| false) => void
  multiEntity?: boolean;
  inputterAccounts: string[];
}

const CurrencyAccountsSearchTable: React.FC<IProps> = (props: IProps) => {
  const {
    hasPrevious,
    hasNext,
    loading,
    handleNextPage,
    handlePreviousPage,
    currencyAccounts,
    handleAccountTableRowClick,
    handleOpenDrawer,
    inputterAccounts,
    multiEntity,
  } = props;

  const styles = useStyles();

  const columns: ITableColumn[] = [
    { header: 'Friendly Name', accessor: 'friendlyNameJSX', width: 300 },
    {
      header: 'Entity', accessor: 'entities', width: 150, className: 'dd-privacy-allow',
    },
    {
      header: 'Currency', accessor: 'currency', width: 200, className: 'dd-privacy-allow',
    },
    {
      header: '', accessor: 'buttons', width: 200, className: 'dd-privacy-allow',
    },
    { header: ' ', accessor: 'pendingInvoices', className: 'dd-privacy-allow' },
    {
      header: 'Pending Transactions',
      accessor: 'pendingTransactionsFormatted',
      align: 'right',
      className: 'dd-privacy-allow',
    },
    {
      header: 'Cleared Balance', accessor: 'clearedBalanceFormatted', align: 'right', className: 'dd-privacy-allow',
    },
    {
      header: 'Available Balance',
      accessor: 'availableBalanceFormatted',
      align: 'right',
      className: 'dd-privacy-allow',
    },
  ];

  const invoiceAuthorization = useAuthorization([[UserRole.INVOICEMANAGER]]);
  const { currentAccount } = useSwitchAccount();

  const formatteddata = currencyAccounts?.map((column) => ({
    ...column,
    friendlyNameJSX: (
      <FriendlyName
        testId={column.id}
        handleOpenDrawer={handleOpenDrawer}
        friendlyName={column.friendlyName}
        pendingIATTransfers={column.pendingIATDrafts}
        bankInformation={column.type?.toLocaleLowerCase() === 'wallet' ? column.reference : column.iban}
      />
    ),
    entities: column.accountName ? <TextEllipsis text={column.accountName} maxChars={32} className={styles.entity} /> : '-',
    buttons: (multiEntity)
      ? (
        <ButtonsMultiEntity
          inputter={inputterAccounts.includes(column.accountId)}
          handleOpenDrawer={handleOpenDrawer}
          currencyAccountId={column.id}
          entity={column.accountId}
        />
      )
      : (
        <Buttons handleOpenDrawer={handleOpenDrawer} currencyAccountId={column.id} />
      ),
    pendingInvoices: (
      (column.pendingInvoice
      && column.pendingInvoice > 0
      && invoiceAuthorization.authorized
      && currentAccount.invoicesEnabled
      && (<PendingInvoice number={column.pendingInvoice} />)) || ''),
    pendingTransactionsFormatted: formatCurrency(
      column.currencyCode,
      column.pendingTransactions,
    ),
    clearedBalanceFormatted: formatCurrency(column.currencyCode, column.clearedBalance),
    availableBalanceFormatted: formatCurrency(column.currencyCode, column.availableBalance),
  }));

  return (
    <SearchTable
      table={{
        columns,
        data: formatteddata,
        handleTableRowClick: (account: CurrencyAccountSearchDto) => {
          handleAccountTableRowClick(account);
        },
      }}
      loading={loading}
      pagination={{
        handleNext: handleNextPage as any,
        handlePrevious: handlePreviousPage as any,
        hasNext,
        hasPrevious,
      }}
      emptyTable={{
        title: 'No Currency Accounts',
        subtitle: 'No accounts under this currency',
      }}
    />
  );
};

export default CurrencyAccountsSearchTable;
