import _default, { makeStyles } from 'theme';

const { palette } = _default;

const useStyles = makeStyles(
  () => ({
    entity: {
      wordBreak: 'break-word',
      maxWidth: 150,
      '& p': {
        paddingRight: 30,
      },
    },
    searchBarContainer: {
      width: '300px',
    },
    tableWrapper: {
      '& td': {
        padding: '10px 0px !important',
      },
    },
    alertWrapper: {
      paddingTop: 25,
    },
    approvalIcon: {
      marginRight: 5,
    },
    noOfApprovers: {
      margin: 'auto',
      maxWidth: 150,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    icon: {
      color: 'rgba(0, 0, 0, 0.45)',
      marginRight: 8,
    },
    textAlignRight: {
      margin: 'auto',
      maxWidth: 150,
      textAlign: 'right',
    },
    textAlignLeft: {
      maxWidth: 150,
      textAlign: 'left',
    },
    payments: {
      maxWidth: 115,
      marginRight: 'auto',
      textAlign: 'right',
    },
    approval: {
      maxWidth: 200,
      margin: 'auto',
      textAlign: 'left',
    },
    processing: {
      textAlign: 'center',
      paddingRight: 20,
      color: 'rgb(170, 170, 170)',
    },
    action: {
      textAlign: 'center',
      paddingRight: 20,
    },
    link: {
      color: palette.primary.light,
    },
    spaceRight: {
      paddingRight: 20,
      maxWidth: '250px',
    },
  }),
  { name: 'SearchPaymentsTable' },
);

export default useStyles;
