import _default, { makeStyles } from 'theme';

const { palette } = _default;

const useStyles = makeStyles(
  () => ({
    header: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      marginBottom: '1.5rem',
      '& *': {
        margin: 0,
      },
      '& h3': {
        color: palette.primary.main,
      },
    },
    icon: {
      marginTop: 3,
    },
    alert: {
      fontSize: '1rem',
      fontWeight: 'bold',
      '& > *': {
        padding: '10px 0',
      },
    },
    content: {
      gap: '1.5rem',
      '& h4': {
        borderBottom: `1px solid ${palette.grey[200]}`,
        paddingBottom: '0.6rem',
        margin: '0 0 0.7rem 0',
      },
    },
  }),
  { name: 'Permissions' },
);

export default useStyles;
