import { Button } from '@mui/material';
import { faFileAlt, faInfoCircle, faRandom } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import useStyles from './Buttons.styles';

interface IButtonProps {
  handleOpenDrawer: (_drawer: 'IAT' | 'AccountDrawer' | false) => void;
  currencyAccountId: string;
  inputter: boolean;
  entity: string;
}

const ButtonsMultiEntity: React.FC<IButtonProps> = (props: IButtonProps) => {
  const {
    handleOpenDrawer, currencyAccountId, inputter, entity,
  } = props;
  const classes = useStyles();
  // const { authorized } = useAuthorization([[UserRole.IAT_INPUTTER]]);

  return (
    <>
      <Tooltip title="Account information">
        <Button
          className={classes.button}
          onClick={() => handleOpenDrawer('AccountDrawer')}
          data-testid="currency-account-info-btn"
        >
          <FontAwesomeIcon size="sm" icon={faInfoCircle} />
        </Button>
      </Tooltip>
      {inputter ? (
        <Tooltip title="Balance transfer">
          <Button
            className={classes.button}
            data-testid="currency-account-transfer-btn"
            onClick={() => handleOpenDrawer('IAT')}
          >
            <FontAwesomeIcon size="sm" icon={faRandom} />
          </Button>
        </Tooltip>
      )
        : null}
      <Tooltip title="Transactions">
        <Link
          to={`/app/transactions-multi-entity/${entity}/${currencyAccountId}`}
          data-testid="currency-account-transactions-link"
        >
          <Button className={classes.button} data-testid="currency-account-transactions-btn">
            <FontAwesomeIcon size="sm" icon={faFileAlt} />
          </Button>
        </Link>
      </Tooltip>
    </>
  );
};

export default memo(ButtonsMultiEntity);
