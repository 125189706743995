import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSearch, { TSearchParams } from '../../../hooks/useSearch';
import { TStore } from '../../../store';
import { TAuthyState } from '../../../store/authy/reducer';
import { OutstandingDashboardTablesState } from '../../../store/outstandingTables/outstandingTables.reducer';
import { DashaboardTablesSearchUrls } from './helpers';

const createSearchParams = (
  baseUrl: DashaboardTablesSearchUrls,
  skip: number,
): TSearchParams => {
  const searchParams: TSearchParams = {
    baseUrl,
    queryParams: {
      skip,
      take: 10,
    },
  };
  return searchParams;
};

const usePendingTable = (baseUrl: DashaboardTablesSearchUrls) => {
  const location = useLocation();
  const tableSearch = useSearch();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const dashboardTablesState = useSelector<TStore, OutstandingDashboardTablesState>(
    (state) => state.outstandingDashboardTables,
  );

  const searchParams: TSearchParams = createSearchParams(
    baseUrl,
    tableSearch.skip,
  );

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    tableSearch.setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.searchtext = e.target.value;
    }
    await tableSearch.handleNewSearch(searchParams, true);
  };

  return {
    location,
    searchParams,
    tableSearch,
    handleInputChange,
    authyState,
    dashboardTablesState,
  };
};

export default usePendingTable;
