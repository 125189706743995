import Tick from '../../../assets/tick.svg';

import _default, { makeStyles } from 'theme';

const { palette, typography } = _default;

const useStyles = makeStyles(
  () => ({
    list: {
      fontFamily: typography.fontFamily,
      fontSize: 14,
      fontWeight: typography.fontWeightRegular,
      color: 'rgba(255, 255, 255, 0.4)',
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 16,
      '& li:not(:last-child)': {
        marginBottom: 5,
      },
    },
    contains: {
      position: 'relative',
      color: 'white',
      '&::marker': {
        color: palette.primary.main,
      },
      '&::before': {
        position: 'absolute',
        content: `url(${Tick})`,
        left: '-20px',
        top: '2px',
      },
    },
  }),
  { name: 'newPassword' },
);

export default useStyles;
