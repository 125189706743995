import { RolesEnum } from '@portal/user-types/dist/schema';
import React from 'react';
import { TextEllipsis } from '../../../../../components/TextEllipsis';
import PermissionChangesTooltip from '../../../components/PermissionChangesTooltip';
import useStyles from './index.styles';

type RequestedRoleDescriptionProps = {
    isNewUser: boolean;
    userName: string;
    accountName: string;
    currentRoles: RolesEnum[] | [];
    requestedRoles: RolesEnum[] | [];
    currentApprovalLevel: string | null;
    requestedApprovalLevel: string | null;
  }

enum DescriptionType {
  NEW_USER = 'NEW_USER',
  PERMISSION_CHANGE = 'PERMISSION_CHANGE',
  ACCESS_REMOVED = 'ACCESS_REMOVED',
}

const generatePermissionDescription = (type: DescriptionType) => {
  switch (type) {
    case DescriptionType.NEW_USER:
      return 'New user created';
    case DescriptionType.PERMISSION_CHANGE:
      return 'Permission changes';
    case DescriptionType.ACCESS_REMOVED:
      return 'User access removed';
    default:
      return '';
  }
};

const generateToolTipTitle = (type: DescriptionType, userName?: string, accountName?: string) => {
  switch (type) {
    case DescriptionType.NEW_USER:
      return `New user ${userName}`;
    case DescriptionType.PERMISSION_CHANGE:
      return `Permission change for ${userName} for ${accountName}`;
    default:
      return '';
  }
};

const RequestedRoleDescription = (props: RequestedRoleDescriptionProps) => {
  const {
    isNewUser,
    userName,
    accountName,
    currentRoles,
    requestedRoles,
    currentApprovalLevel,
    requestedApprovalLevel,
  } = props;

  const { root } = useStyles();

  let descriptionType;

  const isUserAccessRemoved = currentRoles?.length > 0 && requestedRoles?.length === 0;

  if (isNewUser) {
    descriptionType = DescriptionType.NEW_USER;
  } else if (isUserAccessRemoved) {
    descriptionType = DescriptionType.ACCESS_REMOVED;
  } else {
    descriptionType = DescriptionType.PERMISSION_CHANGE;
  }

  if (!descriptionType) return null;

  return (
    <div className={root}>
      <TextEllipsis
        testId="role-description-text"
        text={(
          <>
            {`${generatePermissionDescription(descriptionType)} for `}
            <b>
              {accountName}
            </b>
          </>
          )}
      />
      {descriptionType !== DescriptionType.ACCESS_REMOVED
        && (
          <PermissionChangesTooltip
            hoverText="Hover to view permissions"
            title={generateToolTipTitle(descriptionType, userName, accountName)}
            requestedRoles={requestedRoles}
            currentRoles={currentRoles}
            currentApprovalLevel={currentApprovalLevel}
            requestedApprovalLevel={requestedApprovalLevel}
          />
        )}
    </div>
  );
};

export default RequestedRoleDescription;
