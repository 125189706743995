import React from 'react';

import history from '../../../../../services/history/browserHistory';
import routes from '../../../../../routes.path';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import PaymentsService from '../../../../../services/Payments/payments.service';
import ReleaseFundedBatchFooter from '../../../../Payments/BatchSummary/FooterContainer/ReleaseFundedBatchContainer/ReleaseFundedBatch/ReleaseFundedBatch';

interface IProps {
  batchId: string;
  accountId: string;
}

const ReleaseFundedBatchContainer: React.FC<IProps> = ({ batchId, accountId }: IProps) => {
  const sb = useAlphaSnackbar();
  const handleReleaseFundedBatch = async () => {
    try {
      await PaymentsService.postReleasePaymentBatchAsync(batchId, accountId);
      sb.trigger('Payment released succesfully', 'success');
    } catch (e) {
      sb.trigger('This payment could not be released at this time');
    } finally { history.push(routes.payments.multientity.base); }
  };

  const handleClick = () => {
    history.push(routes.payments.base);
  };

  return (
    <ReleaseFundedBatchFooter
      handleReleaseFundedBatch={handleReleaseFundedBatch}
      handleClick={handleClick}
    />
  );
};

export default ReleaseFundedBatchContainer;
