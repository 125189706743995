import { Grid } from '@mui/material';
import { faSitemap, faUsers } from '@fortawesome/pro-light-svg-icons';
import { faMoneyBill, faRandom } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import routes from '../../../routes.path';
import browserHistory from '../../../services/history/browserHistory';
import EntryPointCard from './EntryPointCard';
import useStyles from './EntryPointWidget.styles';

interface IProps {
  emGroupManagerClick: () => void;
}

const EntryPointWidget: React.FC<IProps> = (props: IProps) => {
  const {
    emGroupManagerClick,
  } = props;

  const classes = useStyles();

  const paymentsClick = () => {
    browserHistory.push(routes.payments.multientity.base);
  };
  const beneficiariesClick = () => {
    browserHistory.push(routes.beneficiaries);
  };
  const transactionsClick = () => {
    browserHistory.push(routes.transactionsMultiEntity);
  };

  const widgets = [
    {
      page: 'Payments',
      icon: faMoneyBill,
      cardAction: paymentsClick,
    },
    {
      page: 'Beneficiaries',
      icon: faUsers,
      cardAction: beneficiariesClick,
    },
    {
      page: 'Transactions',
      icon: faRandom,
      cardAction: transactionsClick,
    },
    {
      page: 'Entity Groups',
      icon: faSitemap,
      cardAction: emGroupManagerClick,
    },
  ];

  return (
    <Grid className={classes.widgetContainer} container spacing={1}>
      {
        widgets.map((widget) => (
          <Grid key={widget.page} item md={3} sm={6} xs={12}>
            <EntryPointCard icon={widget.icon} page={widget.page} onClick={widget.cardAction} />
          </Grid>
        ))
      }
    </Grid>
  );
};
export default EntryPointWidget;
