import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    bodyWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '35px 40px',
    },
  }),
  { name: 'ErrorPaymentBody' },
);

export default useStyles;
