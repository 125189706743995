import _default, { makeStyles } from 'theme';

const { palette, typography } = _default;

const useStyles = makeStyles(
  () => ({
    tableColumn: {
      color: palette.primary.main,
      fontWeight: typography.fontWeightMedium,
      fontSize: '1.1rem',
    },
    tableColumnDisabled: {
      color: palette.text.disabled,
      fontWeight: typography.fontWeightMedium,
      fontSize: '1.1rem',
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      gap: 30,
      padding: '30px 0',
    },
  }),
  { name: 'UserManagement' },
);

export default useStyles;
