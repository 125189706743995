import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons';
import { PaymentUploadType } from '@alpha/payments-dtos/lib/batch/PaymentUploadType';
import useStyles from '../PendingTable/PendingTable.styles';
import clsx from 'clsx';

interface IProps {
  type: PaymentUploadType | undefined;
  className?: string;
}
const PaymentsType: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { type, className } = props;
  const classes = useStyles();
  return (
    <>
      {
        type && (
          <FontAwesomeIcon
            className={clsx(classes.icon, className)}
            icon={type === 'Batch' ? faLayerGroup : faStickyNote}
          />
        )
      }
      {type || ''}
    </>
  );
};

export default PaymentsType;
