import React, { memo } from 'react';
import { Box } from '@mui/material';
import APPagination from 'components/APPagination';
import BackdropLoader from '../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable from '../../../../components/Table/EmptyTable/EmptyTable';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import clipboardCheck from '../../../../assets/clipboardCheck.svg';
import Table from '../../../../components/Table/Table';
import useStyles from '../PendingTable/PendingTable.styles';

interface IProps {
  mappedData: IMappedData[];
  table: ITable;
  columns: ITableColumn[];
  message: string;
}

interface ITable {
  data: any[] | undefined;
  hasMore: boolean | undefined;
  hasPrevious: boolean;
  handleNextPage: () => Promise<void>;
  handlePreviousPage: () => Promise<void>;
  loading: boolean;
}

export interface IMappedData {
  id: string;
  type: JSX.Element;
  uploadedBy: JSX.Element;
  uploadedDate: JSX.Element;
  action: JSX.Element;
  noOfPayments?: JSX.Element;
  noOfApprovers?: JSX.Element;
  approvalStatus?: JSX.Element;
}

const TableContainer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    mappedData, table, columns, message,
  } = props;
  const classes = useStyles();
  if (table.loading) return (<BackdropLoader testId="backdrop-loader" />);
  if (mappedData.length > 0) {
    return (
      <Box className={classes.tableWrapper}>
        <Table columns={columns} data={mappedData} />
        <Box display="block" textAlign="right">
          <APPagination
            hasPrevious={table.hasPrevious}
            hasNext={table.hasMore === true}
            handleNext={table.handleNextPage}
            handlePrevious={table.handlePreviousPage}
          />
        </Box>
      </Box>
    );
  }
  return (
    <EmptyTable
      icon={clipboardCheck}
      title={`No ${message} Items`}
      subtitle="You are all up to date"
    />
  );
};

export default memo(TableContainer);
