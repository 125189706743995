import React from 'react';
import styled from '@mui/styled-engine';
import useEntityGroupDrawer from '../../../../EntityGroups/EntityGroupManagerDrawer/useEntityGroupDrawer';
import StyledButton from '../index.styles';

const StyledGroupManagerButton = styled(StyledButton)({
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#f7f7f7',
  },
});

const GroupManagerButton = ({ disabled = false }:{disabled?: boolean}) => {
  const handleOpenViewGroupsDrawer = () => {
    requestUpdateEntityGroupDetails();
    toggleViewGroupsDrawer(true);
  };

  const {
    requestUpdateEntityGroupDetails,
    toggleViewGroupsDrawer,
  } = useEntityGroupDrawer();

  return (
    <StyledGroupManagerButton
      variant="contained"
      color="inherit"
      disableElevation
      type="button"
      data-testid="group-manager-button"
      data-dd-privacy="allow"
      disabled={disabled}
      onClick={handleOpenViewGroupsDrawer}
    >
      Group Manager
    </StyledGroupManagerButton>
  );
};

export default GroupManagerButton;
