import { Box, Typography } from '@mui/material';
import React from 'react';
import useStyles from './AccountInformation.styles';

interface IAccount {
  type: string | undefined;
  iban: string | undefined;
  reference: string | undefined;
  friendlyName: string | undefined;
  header: string;
}

const AccountInformation: React.FC<IAccount> = (props: IAccount) => {
  const { type, iban, friendlyName, reference, header } = props;

  const classes = useStyles();
  const displayInformation = () => {
    if (type) {
      if (type === 'Wallet' && reference) return reference;
      if (iban) return iban;
    }

    return '';
  };

  return (
    <Box className={classes.details}>
      <>
        <Typography className={classes.header}>{`${header} Account`}</Typography>
        <Typography className={classes.friendlyName}>
          {friendlyName || '-'}
          <span className={classes.information}>{friendlyName ? `- ${displayInformation()}` : ''}</span>
        </Typography>
      </>
    </Box>
  );
};

export default AccountInformation;
