import { isEqual } from 'lodash';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { originalUseSnackbar } from 'components/Snackbar';
import { AuthContext } from '../context/AuthContext';
import { ChallengeName, TSignIn } from '../models/auth';
import { TUser } from '../models/user';
import routes from '../routes.path';
import AuthService from '../services/Auth/auth.service';
import history from '../services/history/browserHistory';
import { TStore } from '../store';
import { actions } from '../store/user/user.reducer';
import { useOnboardingContext } from '../domain/Auth/Onboarding';
import useSwitchAccount from './useSwitchAccount';
import { actions as accountActions } from '../store/accounts/accounts.reducer';

const useAuth = () => {
  const [userObject, setUserObject] = useState<TSignIn | undefined>();
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const { handleSetUserObject } = useContext(AuthContext);
  const onboarding = useOnboardingContext();
  const userInfo: TUser | undefined = useSelector<TStore, TUser | undefined>(
    (state: TStore) => state.user.user,
  );
  const dispatch = useDispatch();
  const { closeSnackbar } = originalUseSnackbar();
  const { clearUserAccountStorage } = useSwitchAccount();

  const checkAuth = async (): Promise<void> => {
    try {
      const response = await AuthService.currentUserInfo();
      if (response) {
        if (!isEqual(userInfo, response)) {
          dispatch(actions.updateUserDetails(response));
        }
        setLoggedIn(true);
      } else {
        throw Error('Not logged in');
      }
    } catch (e) {
      dispatch(actions.clearUserDetails());
      setLoggedIn(false);
    }
  };

  const handleSignOut = async () => {
    dispatch(accountActions.toggleKYCModal(false));
    await AuthService.signOut();
    closeSnackbar();
    clearUserAccountStorage();
    dispatch(actions.clearUserDetails());
  };

  const challengeRedirect = async (
    signInResponse: TSignIn,
    callback?: Function,
  ): Promise<void> => {
    let hasErrored: boolean | null = null;
    switch (signInResponse.challengeName) {
      case ChallengeName.NEW_PASSWORD_REQUIRED:
        handleSetUserObject(signInResponse);
        history.push(routes.auth.onboarding.newPassword);
        return;
      case ChallengeName.MFA_SETUP:
        history.push(routes.auth.onboarding.setupMfa);
        return;
      case ChallengeName.CUSTOM_CHALLENGE:
        if (
          signInResponse?.challengeParam?.CHALLENGE_STEP === 'METHOD_REQUEST'
        ) {
          handleSetUserObject(signInResponse);
          if (onboarding.loaderPercentage) {
            history.push(routes.auth.onboarding.setupMfa, {
              selectDefault: true,
            });
            return;
          }
          history.push(routes.auth.mfaOptions);
          return;
        }
        hasErrored = signInResponse.challengeParam.CODE_DELIVERY_ERROR !== undefined;
        callback!(
          hasErrored
            ? signInResponse.challengeParam.CODE_DELIVERY_ERROR
            : `Verification code sent to ${signInResponse.challengeParam.CODE_DELIVERY_DESTINATION}`,
          {
            variant: hasErrored ? 'error' : 'info',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            preventDuplicate: true,
          },
        );

        handleSetUserObject(signInResponse);
        history.push(
          onboarding.loaderPercentage
            ? routes.auth.onboarding.verifyMfa
            : routes.auth.mfaOptions,
        );
        return;
      default:
        throw Error(`Unhandle Challenge name: ${signInResponse.challengeName}`);
    }
  };

  return {
    userInfo,
    loggedIn,
    userObject,
    checkAuth,
    challengeRedirect,
    handleSignOut,
    setUserObject,
  };
};

export default useAuth;
