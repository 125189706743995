import { useContext } from 'react';
import { PaymentsContext } from '../../../../../../context/ManualPaymentContexts';

const usePaymentsContext = () => {
  const paymentsContext = useContext(PaymentsContext);
  return {
    paymentsContext,
  };
};
export default usePaymentsContext;
