import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Typography } from '@mui/material';
import { faTrashAlt, faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import Menu from 'components/Menu/Menu';
import Authorization from '../../../../../../hocs/Authorization';
import { PaymentRouteEnum } from '../../../../../../models/payments';
import { UserRole } from '../../../../../../models/user';
import routes from '../../../../../../routes.path';
import history from '../../../../../../services/history/browserHistory';

interface IProps {
  batchStatus: string;
  batchId: string;
  handleDeleteClick: (batchId: string) => void;
}

const ActionDropDown: React.FC<IProps> = (props: IProps) => {
  const {
    batchStatus,
    batchId,
    handleDeleteClick,
  } = props;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const menuItems = [
    {
      id: 'action-draft-payment-view',
      content:
        batchStatus === 'VALIDATED'
          ? (
            <Authorization requiredRoles={[[UserRole.PAYMENTS_INPUTTER]]} fallback={<>View</>}>
              <Typography>Resume</Typography>
            </Authorization>
          )
          : <Typography className="actionDisabled">Resume</Typography>,
      icon: faPlayCircle,
      onClick: () => history.push(
        routes.payments.batchSummaryRouteFunc(batchId, PaymentRouteEnum.DRAFTED),
      ),
      underline: true,
    },
    {
      id: 'action-draft-payment-delete',
      content: <Typography>Delete</Typography>,
      icon: faTrashAlt,
      onClick: () => handleDeleteClick(batchId),
    },
  ];

  return (
    <div data-testid={`action-${batchId}`} style={{ marginLeft: 8 }}>
      <Menu
        open={Boolean(open)}
        setOpen={setOpen}
        anchorEl={open}
        buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
        buttonTitle=""
        icon={faEllipsisH}
      >
        {menuItems.map((item) => (
          <MenuDropdownItem key={item.id} setOpen={setOpen}>{item}</MenuDropdownItem>
        ))}
      </Menu>
    </div>

  );
};

export default ActionDropDown;
