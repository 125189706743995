import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { ButtonProps } from '@mui/material/Button';
import React from 'react';
import clsx from 'clsx';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { TText } from 'types/index.interfaces';
import { StyledMenuDropdownButton } from './MenuDropdownButton.styles';

export interface IProps {
    buttonTitle: TText;
    userAccount?: TText;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    buttonProps?: ButtonProps;
    menuIconProps?: FontAwesomeIconProps;
    obscure?: boolean;
}

const MenuDropdownButton: React.FC<IProps> = ({
  userAccount,
  buttonTitle,
  handleClick,
  buttonProps,
  menuIconProps,
  obscure,
}: IProps) => (
  <StyledMenuDropdownButton
    className="menuButton"
    aria-controls="menu"
    aria-haspopup="true"
    onClick={handleClick}
    {...buttonProps}
  >
    <span className={clsx(obscure && 'dd-privacy-allow')}>{buttonTitle}</span>
    {userAccount && (
    <>
      <span className="spacer">|</span>
      {userAccount}
    </>
    )}
    <FontAwesomeIcon size="2x" className="buttonIcon" icon={faAngleDown} {...menuIconProps} />
  </StyledMenuDropdownButton>
);

export default MenuDropdownButton;
