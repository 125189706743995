import {
  PaymentBatchDraftDto, PaymentBatchDraftStatus, PaymentBatchDto, PaymentBatchStatus,
} from '@alpha/payments-dtos';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../hooks/useReportsPolling';
import { PaymentRouteEnum } from '../../../models/payments';
import routes from '../../../routes.path';
import browserHistory from '../../../services/history/browserHistory';
import PaymentsService from '../../../services/Payments/payments.service';
import { TStore } from '../../../store';
import { TAuthyStatus } from '../../../store/authy/reducer';
import { IBreadcrumbItem } from '../../../components/Breadcrumbs';

const useBatchSummary = () => {
  const snackbar = useAlphaSnackbar();
  const { executeReportGeneration } = useReportsPolling(PageType.Payments);
  const location = useLocation();
  const authyStatus = useSelector<TStore, TAuthyStatus | undefined>(
    (store) => store.authy.status,
  );
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);

  const [batchDetails, setBatchDetails] = useState<
        PaymentBatchDraftDto | PaymentBatchDto
    >();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const handleSetSelectedTabIndex = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };
  const { batchId, state } = useParams<{
        batchId: string;
        state: PaymentRouteEnum;
    }>();

  const handleBreadCrumbs = (): IBreadcrumbItem => {
    const paymentsBreadcrumbs: IBreadcrumbItem = { text: 'pending', url: routes.payments.pending };
    if (
      location.pathname
        .includes(routes.payments.batchSummaryRouteFunc(batchId, PaymentRouteEnum.INPROGRESS))) {
      paymentsBreadcrumbs.text = 'in progress';
      paymentsBreadcrumbs.url = routes.payments.inProgress;
    } else if (location.pathname
      .includes(routes.payments.batchSummaryRouteFunc(batchId, PaymentRouteEnum.COMPLETE))) {
      paymentsBreadcrumbs.text = 'complete';
      paymentsBreadcrumbs.url = routes.payments.complete;
    } else if (location.pathname
      .includes(routes.payments.batchSummaryRouteFunc(batchId, PaymentRouteEnum.FUNDED))) {
      paymentsBreadcrumbs.text = 'funded';
      paymentsBreadcrumbs.url = routes.payments.funded;
    } else if (location.pathname
      .includes(routes.payments.batchSummaryRouteFunc(batchId, PaymentRouteEnum.DRAFTED))) {
      paymentsBreadcrumbs.text = 'draft';
      paymentsBreadcrumbs.url = routes.payments.draft;
    }
    return paymentsBreadcrumbs;
  };

  const getBreadcrumbs = (
    batchStatus: PaymentBatchDraftStatus | PaymentBatchStatus,
  ) => {
    switch (true) {
      case batchStatus === 'REQUESTED' || batchStatus === 'INVALID':
        return [
          { text: 'Payments', url: routes.payments.base },
          { text: 'Create Payments', url: routes.payments.base },
          { text: 'Batch Upload' },
        ];
      default:
        return [
          { text: 'Home', url: routes.base },
          { text: 'Payments', url: routes.payments.base },
          handleBreadCrumbs(),
          { text: 'summary' },
        ];
    }
  };

  const getBatchDetails = async (
    _batchId: string,
    routeState: PaymentRouteEnum,
  ): Promise<PaymentBatchDraftDto | PaymentBatchDto | undefined> => {
    try {
      return await PaymentsService.getBatchDetails(_batchId, routeState);
    } catch (e) {
      snackbar.trigger(`There was an error: (${_batchId}). ${e.message}`);
    }
  };

  const updateBatchDetails = async (
    _batchId: string,
    routeState: PaymentRouteEnum,
  ) => {
    const newBatchDetails = await getBatchDetails(_batchId, routeState);
    setBatchDetails(newBatchDetails);
  };

  const handleSuccessfulAuthy = async (
    _authyStatus: TAuthyStatus | undefined,
    _batchId: string,
    routeState: PaymentRouteEnum,
  ) => {
    const response = await getBatchDetails(_batchId, routeState);
    if (!response) throw Error();
    try {
      if (
        response.batchStatus === PaymentBatchDraftStatus.APPROVED
            && response?.requiresFx
      ) {
        browserHistory.push(
          routes.payments.batchSummaryRouteFunc(
            batchId,
            PaymentRouteEnum.FUNDING,
          ),
        );
        return;
      }
      setLoadingScreen(true);
      setTimeout(() => {
        browserHistory.push(routes.payments.base);
        setLoadingScreen(false);
      }, 2000);
      return;
    } catch (e) {
      snackbar.trigger(`There was an error: (${_batchId}). ${e.message}`);
      browserHistory.push(routes.payments.base);
    }
  };

  const handleReportGeneration = async (fileType: FileType, downloadType: DownloadType) => {
    await executeReportGeneration(fileType, batchId, undefined, undefined, downloadType);
  };

  return {
    batchId,
    authyStatus,
    batchDetails,
    selectedTabIndex,
    routeParameterStats: state,
    handleSetSelectedTabIndex,
    updateBatchDetails,
    getBreadcrumbs,
    handleSuccessfulAuthy,
    handleReportGeneration,
    loadingScreen,
  };
};

export default useBatchSummary;
