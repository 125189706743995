import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PaymentsContext } from '../../../../../../context/ManualPaymentContexts';
import { TCurrencyAccount } from '../../../../../../models/currencyAccounts';
import { IFundingAccountContainerProps } from '../FundingAccountContainer';
import FundingAccountSummary from './FundingAccountSummary';

export interface IFundingAccountContainerSummaryProps
  extends IFundingAccountContainerProps {
  selectedDebitingAccount: TCurrencyAccount;
}

const FundingAccountSummaryContainer: React.FC<IFundingAccountContainerSummaryProps> = ({
  ...props
}: IFundingAccountContainerSummaryProps) => {
  const paymentsContext = useContext(PaymentsContext);
  const [open, setOpen] = useState<boolean>(false);

  const handleModalOpen = (open: boolean) => {
    setOpen(open);
  };

  const handleSubmitButton = () => {
    paymentsContext.handleSetCurrentPayments([]);
    clearDebitingAccounts();
  };

  const handleChangeDebitingAccount = () => {
    if (paymentsContext.currentPayments.length > 0) {
      handleModalOpen(true);
    } else {
      clearDebitingAccounts();
    }
  };

  const clearDebitingAccounts = () => props.setSelectedDebitingAccount(undefined);

  return (
    <FundingAccountSummary
      {...props}
      handleChangeDebitingAccount={handleChangeDebitingAccount}
      open={open}
      handleModalOpen={handleModalOpen}
      handleSubmitButton={handleSubmitButton}
    />
  );
};

export default FundingAccountSummaryContainer;
