import { makeStyles } from 'theme';

const useStyles = makeStyles(
  {
    dropdownContainer: {
      display: 'flex',
    },
  },
  { name: 'fundingAccountContainer' },
);

export default useStyles;
