import {
  Checkbox, FormControlLabel,
} from '@mui/material';
import React, { useState } from 'react';
import Status from 'components/Status/Status';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import KycModal from 'domain/OutstandingActionsDashboard/KYCModal/KycModal';
import useBannerRefresh from 'hooks/useBannerRefresh';
import useStyles from './index.styles';
import { AccountsViewDropdownOptions } from '../helpers';

  interface IProps {
    accountMenuOptions: AccountsViewDropdownOptions[],
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, accountId: string)=> void;
    nested?: boolean
    }

const AccountsDropdown: React.FC<IProps> = (props: IProps) => {
  const {
    accountMenuOptions,
    handleChange,
    nested,
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  const {
    frozen,
    overdue,
    due,
  } = useBannerRefresh();

  const classes = useStyles({ nested });

  const generateMenuOptions = accountMenuOptions
    .map((account) => (
      <div className={classes.accountMenuOption} key={account.id}>
        <FormControlLabel
          label={account.name}
          control={(
            <Checkbox
              color="primary"
              onChange={(event) => { handleChange(event, account.id); }}
              checked={account.selected}
            />
          )}
        />
        {account.frozen && (
          <Status className={classes.frozenStatus} onClick={setOpen} size="small" variant="error">
            Frozen
            <FontAwesomeIcon
              size="sm"
              className={classes.questionIcon}
              icon={faQuestionCircle}
            />
          </Status>
        )}
      </div>
    ));

  return (
    <>
      <div>
        {generateMenuOptions}
      </div>
      <KycModal
        due={due}
        overdue={overdue}
        frozen={frozen}
        modalOpen={open}
        onClose={setOpen}
      />

    </>
  );
};

export default AccountsDropdown;
