import { makeStyles } from 'theme';
import React, { useState } from 'react';
import {
  Route, Switch, Redirect, useLocation,
} from 'react-router-dom';
import AlphaLogoWhite from '../../../assets/logo.svg';
import PasswordResetImg from '../../../assets/resetPassword.svg';
import AuthLayout from '../../../components/Layout/Auth';
import routes from '../../../routes.path';
import Request from './Request';
import Verify from './Verify';
import Success from './Success';

const useStyles = makeStyles(() => ({
  leftContent: {
    backgroundColor: 'unset',
    paddingTop: 0,
    '& .alphaLogo': {
      marginBottom: '40px',
    },
  },
}), { name: 'Returning' });

const PasswordReset = () => {
  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState<string>();
  const { pathname } = useLocation();
  const styles = useStyles();

  return (
    <AuthLayout.Container>
      <AuthLayout.LeftContent
        className={
          !pathname.includes(routes.auth.passwordReset.verifyMfa)
            ? styles.leftContent : undefined
        }
      >
        <img
          className="alphaLogo"
          src={AlphaLogoWhite}
          alt="Alpha FX Logo"
        />
        <Switch>
          <Route exact path={routes.auth.passwordReset.request}>
            <Request setUsername={setUsername} setEmail={setEmail} />
          </Route>
          <Route exact path={routes.auth.passwordReset.verifyMfa}>
            <Verify username={username} email={email} />
          </Route>
          <Route exact path={routes.auth.passwordReset.success}>
            <Success />
          </Route>
          <Redirect to={routes.auth.passwordReset.request} />
        </Switch>
      </AuthLayout.LeftContent>
      <AuthLayout.RightContent>
        <Switch>
          <Route exact path={routes.auth.passwordReset.verifyMfa}>
            <img
              src={PasswordResetImg}
              alt="Onboarding email being displayed on a laptop and a mobile phone"
            />
          </Route>
        </Switch>
      </AuthLayout.RightContent>
    </AuthLayout.Container>
  );
};

export default PasswordReset;
