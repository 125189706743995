/* eslint-disable @typescript-eslint/no-shadow */
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@mui/material';
import {
  faCheckCircle,
  faEye,
  faTimesCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentBatchesResponse } from '@alpha/payments-types';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from 'components/Menu/Menu';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { iAllowedAccounts } from 'store/accounts/accounts.reducer';
import { Flags } from 'react-feature-flags';
import BeneFraudAcknowledgeModal from 'domain/Beneficiaries/BeneFraudAcknowledgeModal';
import useActionDropdown from '../../../../hooks/useActionDropDown';
import { PaymentRouteEnum } from '../../../../models/payments';
import useStyles from '../../../Payments/Dashboard/PendingTable/DisplayTable/ActionDropDown/ActionDropDown.styles';
import routes from '../../../../routes.path';
import history from '../../../../services/history/browserHistory';
import { PaymentsService } from '../../../../services/Payments/payments.service';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import { initiatePayment } from '../../../../store/authy/actions';
import { TStore } from '../../../../store';
import { actions } from '../../../../store/outstandingTables/outstandingTables.reducer';
import saveCurrentPageRoute from '../../../../utils/saveCurrentPageRoute';

interface IProps {
  payment: PaymentBatchesResponse;
  setAcknowledgeModalOpen: (ackowledgeModalOpen: boolean) => void;
  acknowledgeModalOpen: boolean;
}

const ActionDropDown: React.FC<IProps> = (props: IProps) => {
  const { payment, setAcknowledgeModalOpen, acknowledgeModalOpen } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const sb = useAlphaSnackbar();
  const { open, menuCloseCount, setMenuCloseCount, setOpen } =
    useActionDropdown({});

  const userId = useSelector<TStore, string | undefined>(
    (store) => store.user.user?.Username,
  );

  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const { flags } = useGetFeatureFlags();
  const isFraudModalEnabled = useMemo(
    () =>
      flags?.some((flag) => flag.name === 'fraudModalEnabled' && flag.isActive),
    [flags],
  );

  const shouldShowFraudWarningModal = () => {
    if (isFraudModalEnabled) {
      const accountIsMicroEnterprise = allowedAccounts.some(
        (acc) => acc.id === payment.accountId && acc.isMicroEnterprise === true,
      );

      return accountIsMicroEnterprise && payment.batchHasNewBeneficiary;
    }

    return false;
  };

  const [loading, setLoading] = useState(false);

  const handleRejectPayment = async () => {
    try {
      setLoading(true);
      await PaymentsService.postRejectPayment(
        payment.batchId,
        userId,
        payment.uploadedBy,
        payment.accountId,
      );
      dispatch(
        actions.updateDashboardAction(`REJECT_PAYMENT-${payment.batchId}`),
      );
      sb.trigger('Successfully rejected your payment', 'info');
    } catch {
      sb.trigger(
        'Something went wrong rejecting your payment, please try again',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleApprovePayment = async () => {
    if (isFraudModalEnabled && shouldShowFraudWarningModal()) {
      setAcknowledgeModalOpen(true);
      setMenuCloseCount(0);
    } else {
      dispatch(
        initiatePayment({
          batchId: payment.batchId,
          type: 'PAYMENT_APPROVE',
          approverOwn: Boolean(payment.uploadedBy === userId),
          accountId: payment.accountId,
        }),
      );
      setAcknowledgeModalOpen(false);
    }
  };

  const handleModalConfirmation = async () => {
    dispatch(
      initiatePayment({
        batchId: payment.batchId,
        type: 'PAYMENT_APPROVE',
        approverOwn: Boolean(payment.uploadedBy === userId),
        accountId: payment.accountId,
        fraudWarningAcknowledged: true,
      }),
    );
    setAcknowledgeModalOpen(false);
    setMenuCloseCount(0);
  };

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    setMenuCloseCount(0);
  };

  const handleRedirectToBatchSummary = () => {
    saveCurrentPageRoute();
    history.push(
      `${routes.payments.multientity.batchSummaryRouteFunc(
        payment.accountId,
        payment.batchId,
        PaymentRouteEnum.PENDING,
      )}`,
    );
  };

  const viewBatch = {
    id: 'action-payment-view',
    content: (
      <Typography data-testid={`payment-action-option-${payment.batchId}`}>
        View Batch
      </Typography>
    ),
    icon: faEye,
    onClick: () => {
      handleRedirectToBatchSummary();
    },
    underline: false,
    userCanApprove: payment.userCanApprove || false,
  };

  const menuItems = [
    {
      ...viewBatch,
      underline: true,
    },
    {
      id: 'action-payment-approve',
      content: (
        <>
          <Typography data-testid={`payment-action-approve-${payment.batchId}`}>
            Approve
          </Typography>
        </>
      ),
      icon: faCheckCircle,
      onClick: () => handleApprovePayment(),
      underline: true,
      userCanApprove: payment.userCanApprove || false,
    },
    {
      id: 'action-payment-reject',
      content: (
        <Typography data-testid={`payment-action-option-${payment.batchId}`}>
          Reject
        </Typography>
      ),
      icon: faTimesCircle,
      onClick: () => handleRejectPayment(),
      underline: false,
      userCanApprove: payment.userCanApprove || false,
    },
  ];

  return (
    <>
      {acknowledgeModalOpen && (
        <Flags authorizedFlags={['fraudModalEnabled']}>
          <BeneFraudAcknowledgeModal
            handleConfirmation={handleModalConfirmation}
            handleClose={() => setAcknowledgeModalOpen(false)}
            acknowledgeModalOpen={acknowledgeModalOpen}
          />
        </Flags>
      )}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          data-testid={`action-${payment.batchId}`}
          className={classes.wrapper}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <Menu
              open={Boolean(open)}
              setOpen={setOpen}
              anchorEl={open}
              disableBackdropClick={menuCloseCount === 1}
              disableHoverEffect={false}
              onClose={handleClose}
              buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
              buttonTitle=""
              icon={faEllipsisH}
            >
              {payment.userCanApprove ? (
                menuItems.map((item) => (
                  <MenuDropdownItem key={item.id} setOpen={setOpen}>
                    {item}
                  </MenuDropdownItem>
                ))
              ) : (
                <MenuDropdownItem setOpen={setOpen}>
                  {viewBatch}
                </MenuDropdownItem>
              )}
            </Menu>
          )}
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default ActionDropDown;
