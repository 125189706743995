import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
  }),
  { name: 'RequestedRoleDescription' },
);

export default useStyles;
