import React, { FC, memo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { StyledFlag } from './Flag.styles';
import { IFlagProps } from './Flag.interfaces';
import FlagImage from './FlagImage';

export const Flag: FC<IFlagProps> = memo((props: IFlagProps) => {
  let size: number;

  if (typeof props.size === 'number') {
    size = props.size;
  } else {
    switch (props.size) {
      case 'sm':
        size = 15;
        break;
      case 'md':
        size = 30;
        break;
      case 'lg':
        size = 47.1;
        break;
      default:
        size = 15;
    }
  }

  const rounded = props.rounded || true;

  return (
    <StyledFlag size={size} rounded={rounded} locked={props.locked} rebrand={props.rebrand} data-testid="styled-flag">
      <div className="flagWrapper">
        <FlagImage currencyCode={props.code} width={size} className={clsx('flag', props.className)} />
        {props.locked
          && !props.rebrand
          && <FontAwesomeIcon icon={faLockAlt as IconDefinition} className="lockIcon" data-testid="img" />}
      </div>
      {props.showCodeLabel && <span className="codeLabel">{props.code}</span>}
      {props.locked
        && props.rebrand
        && <FontAwesomeIcon icon={faLockAlt as IconDefinition} className="lockIconRebrand" />}
    </StyledFlag>
  );
});
