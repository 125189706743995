export enum Frequency {
  ONCE = 'Once',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  FORTNIGHTLY = 'Fortnightly',
  FOURWEEKS = 'FourWeeks',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  YEARLY = 'Yearly',
}

export enum ContinueUntil {
  SET_NUMBER = 'setNumber',
  ON_DATE = 'onDate',
  UNTIL_FURTHER_NOTICE = 'untilFurtherNotice',
}

type IRepeatOptions = {
  name: Frequency;
  label: string;
  addDate?: boolean;
  addDay?: boolean;
  addDateAndMonth?: boolean;
}

export const repeatOptions: IRepeatOptions[] = [
  {
    name: Frequency.DAILY,
    label: 'Every day',
  },
  {
    name: Frequency.WEEKLY,
    label: 'Every week',
    addDay: true,
  },
  {
    name: Frequency.FORTNIGHTLY,
    label: 'Fortnightly',
    addDay: true,
  },
  {
    name: Frequency.FOURWEEKS,
    label: 'Every 4 weeks',
    addDay: true,
  },
  {
    name: Frequency.MONTHLY,
    label: 'Every month',
    addDate: true,
  },
  {
    name: Frequency.QUARTERLY,
    label: 'Quarterly',
  },
  {
    name: Frequency.YEARLY,
    label: 'Every year',
    addDateAndMonth: true,
  },
];

export const continueUntilOptions = [
  {
    name: ContinueUntil.SET_NUMBER,
    label: 'Set number of payments',
  },
  {
    name: ContinueUntil.ON_DATE,
    label: 'Specific date',
  },
  {
    name: ContinueUntil.UNTIL_FURTHER_NOTICE,
    label: 'Until further notice',
  },
];

export const defaultContinueUntil = ContinueUntil.UNTIL_FURTHER_NOTICE;
