import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    tabContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      paddingBottom: 30,
      minHeight: 'calc(100vh - 310px)',
      '& > div': {
        width: '100%',
      },
    },
    searchBar: {
      display: 'flex',
      width: '400px',
    },
    entity: {
      maxWidth: '200px',
      textTransform: 'capitalize',
      '& div': {
        paddingRight: 25,
      },
    },
    capitalise: {
      textTransform: 'capitalize',
    },
  }),
  { name: 'Beneficiaries' },
);

export default useStyles;
