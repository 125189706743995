import { useState } from 'react';

export default function useToggle(initialValue = false) {
  const [isOpen, setIsOpen] = useState<boolean>(initialValue);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const toggle = () => setIsOpen(!isOpen);

  return {
    isOpen,
    open,
    close,
    toggle,
  };
}
