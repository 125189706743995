import styled from '@mui/styled-engine';
import _default from 'theme';
import TextField from '@mui/material/TextField';

const { typography } = _default;
const { fontWeightLight, fontWeightRegular } = typography;

export const StyledInput = styled(TextField, {
  shouldForwardProp: (prop) => (
    prop !== 'currencyInput'
    && prop !== 'rebranded'
    && prop !== 'disableUnderline'
    && prop !== 'variant'
    && prop !== 'position'
    && prop !== 'indent'
    && prop !== 'onClick'
  ),
})(({
  currencyInput, rebranded, className, variant, position, indent, onClick,
}: any) => ({
  '& .MuiInputBase-root': {
    backgroundColor: '#F7F7F7',
    boxSizing: 'border-box',
    borderColor: '#F7F7F7',
    '.currencyLabel': {
      display: 'inline-block',
      margin: '0 0 16px 0',
      textTransform: 'capitalize',
      fontSize: '14px',
      lineHeight: '19px',
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#F7F7F7',
      borderRadius: '5px',
    },
    '.InputIconBox': {
      display: 'inline-flex',
      flex: '0 0 auto',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0.375rem 0.75rem',
      marginBottom: 0,
      fontSize: '0.84rem',
      fontWeight: fontWeightRegular,
      lineHeight: 1.5,
      color: '#495057',
      backgroundColor: variant === 'outlined' ? '#e9ecef' : 'inherit',
      border: variant === 'outlined' ? '1px solid #ced4da' : 0,
      borderRadius: position === 'left' ? 'inherit 0 0 inherit' : 'inherit inherit 0 0',
      height: '100%',
      minWidth: variant === 'outlined' ? 0 : 50,
      marginLeft: position === 'left' ? indent : 0,
      marginRight: position === 'left' ? 0 : indent,
      borderTopRightRadius: position === 'right' ? 4 : 0,
      borderTopLeftRadius: position === 'left' ? 4 : 0,
      borderBottomLeftRadius: position === 'left' ? 4 : 0,
      borderBottomRightRadius: position === 'right' ? 4 : 0,
      '&:hover': {
        cursor: onClick ? 'pointer' : 'auto',
      },

      '& svg': {
        color: 'inherit',
        marginRight: 0,
      },
      '.currencyAdornment': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > span': {
          marginRight: '6px',
          fontSize: '14px',
          letterSpacing: '-0.7px',
          lineHeight: '19px',
        },
      },
    },
    input: {
      width: currencyInput && '213px',
    },
    '.warningIcon': {
      color: '#DEC500',
    },
    [className]: {
      '.MuiOutlinedInput-root': {
        lineHeight: 1.5,
        height: '2.84rem',
        fontWeight: fontWeightLight,

      },
      '.MuiFilledInput-root': {
        fontSize: '14px',
        lineHeight: 1.5,
        fontWeight: fontWeightLight,
        backgroundColor: '#F7F7F7',
        borderRadius: '5px',
        height: 50,

        '&:hover, & .Mui-focused': {
          backgroundColor: '#F7F7F7',
        },

        '&:after, &:before': {
          content: 'none',
        },

        '& .MuiInputBase-input': {
          fontSize: '14px',
          padding: '0.375rem 0.75rem',
          height: '50px',
          lineHeight: 1.35,
          // color: palette.secondary.main,
          fontWeight: fontWeightRegular,
        },

        '& svg': {
          fontSize: '16px',
        },
      },
      '.MuiInputLabel-outlined': {
        transform: 'translate(14px, 14px) scale(1)',
      },
    },
  },
  '& .MuiTextField-root': {
    '& .MuiInputBase-input': {
      fontSize: '1rem',
      padding: '0.375rem 0.75rem',
      height: '2.84rem',
      boxSizing: 'border-box',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: -18,
      marginLeft: 0,
    },
    '& .MuiInputLabel-root': {
      fontSize: '1rem',
    },
  },
  '& .MuiFilledInput-root': {
    '& .MuiInputBase-input': {
      marginLeft: rebranded && '30px',
    },
  },
  '& .Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#F7F7F7 !important',
      borderRadius: '5px',
    },
  },
  '& .MuiOutlinedInput-root:hover': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#F7F7F7',
      borderRadius: '5px',
    },
  },
}));
