import { styled, alpha } from '@mui/material';
import { TStatusVariants } from './Status.interfaces';

const colors = {
  // for old components
  default: '#848484',
  outstanding: '#1473E6',
  settled: '#55B171',
  orange: '#B7812A',
  success: '#098375',
  warning: '#FFC107',
  error: '#B95644',
  info: '#197FAB',
  overdue: '#E53535',
  // new designs
  grey: '#848484',
  blue: '#2A7BB7',
  yellow: '#9B6F1B',
  green: '#098375',
  darkGrey: '#1D1D1D',
  incomplete: '#FFF3CD',
};

const StyledStatusSpan = styled('span')<{ variant: TStatusVariants, size: 'default' | 'small'}>(
  ({ variant, size }: { variant: TStatusVariants; size: 'default' | 'small' }) => {
    let textColor: string = colors[variant];
    let backgroundColor: string;

    if (variant === 'warning') {
      backgroundColor = alpha(textColor, 0.3);
      textColor = '#212529';
    } else if (variant === 'incomplete') {
      backgroundColor = colors.incomplete;
      textColor = '#FFC107';
    } else if (variant === 'outstanding' || variant === 'settled' || variant === 'overdue') {
      backgroundColor = alpha(textColor, 0.2);
    } else {
      backgroundColor = alpha(textColor, 0.1);
    }
    return {
      backgroundColor,
      color: textColor,
      fontSize: '11px',
      lineHeight: '15px',
      letterSpacing: '1.65px',
      fontWeight: 600,
      textTransform: 'uppercase',
      display: 'inline-block',
      padding: size === 'default' ? '8px 16px' : '2px 6px',
      borderRadius: 4,
    };
  },
);

export default StyledStatusSpan;
