import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  IAutocompleteSelectMultiOption,
  MultiAutoCompleteSelect,
} from 'components/MultiAutoCompleteSelect';
import {
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Status from 'components/Status/Status';
import { useStyles } from './EntitiesMultiSelectDropDown.styles';
import useListStyles from '../AutocompleteDropDown/Listbox.styles';

interface IAutocompleteDropDown {
  placeholder?: string;
  testId?: string;
  options: any;
  name: string;
  setFieldValue: Function;
  groupSelected: boolean
  label: string;
  value?: any;
  touched?: any;
  errors?: any;
  setTouched?: () => void;
}
const EntitiesMultiSelectDropDown: React.FC<IAutocompleteDropDown> = (
  props: IAutocompleteDropDown,
) => {
  const {
    options,
    placeholder,
    name,
    testId,
    setFieldValue,
    touched,
    errors,
    label,
    groupSelected,
  } = props;

  const styles = useStyles();
  const { listBox } = useListStyles();
  const handleChange = (
    _event: React.ChangeEvent<{}>,
    changeArray: IAutocompleteSelectMultiOption[],
  ): void => {
    setFieldValue(name, changeArray);
  };

  return (
    <Box>
      {touched && errors ? (
        <Typography className={styles.requiredFieldLabel} variant="subtitle1">
          {label}
          {' '}
          *
          {' '}
        </Typography>
      ) : (
        <Typography className={styles.fieldLabel} variant="subtitle1">
          {label}
        </Typography>
      )}
      <MultiAutoCompleteSelect
        data-testid={testId}
        label=""
        options={options.sort(
          (
            a: IAutocompleteSelectMultiOption,
            b: IAutocompleteSelectMultiOption,
          ) => a.label.localeCompare(b.label),
        )}
        placeholder={placeholder}
        disableCloseOnSelect
        className={styles.dropdown}
        classes={{ listbox: listBox }}
        onChange={handleChange}
        renderOption={(dropdownProps, option: IAutocompleteSelectMultiOption) => {
          const isOptionGroup = option?.value.toString().split('#')[1] === 'Group';
          const classColor = isOptionGroup ? 'default' : 'success';
          return (
            <div
              {...dropdownProps}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography>{option?.label}</Typography>
              </div>
              <div>
                <Status variant={classColor} size="small">
                  {isOptionGroup ? 'Group' : 'Entity'}
                </Status>
              </div>
            </div>
          );
        }}
      />
      {touched && errors ? (
        <Typography className={styles.requiredFieldLabel} variant="subtitle1">
          {errors}
        </Typography>
      ) : (
        <></>
      )}
      {groupSelected ? (
        <Box style={{ display: 'flex' }}>
          <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} />
          <Typography className={styles.text}>
            {`As you have selected a group, a beneficiary will be created for all entities 
            where you hold the "beneficiary inputter role", if you do not have this permission for an entity, a beneficiary will not be created`}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default EntitiesMultiSelectDropDown;
