import { makeStyles } from 'theme';

const useStyles = makeStyles(
  {
    root: {
      width: '400px',
      marginBottom: '56px',
      '& .title': {
        marginBottom: '32px',
        letterSpacing: '-0.5px',
        fontWeight: 600,
      },
      '& .label': {
        marginBottom: '8px',
      },
    },
  },
  { name: 'fundingAccount' },
);

export default useStyles;
