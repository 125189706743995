import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import useAlphaSnackbar from './useAlphaSnackbar';

export const useDataTable = <TParams, TResponse extends { totalItems?: number }>(
  fetchDataCallback: (params: TParams) => Promise<TResponse>,
  fetchDataParams: Partial<TParams>,
  search?: string,
) => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState<TResponse>();
  const [searchQuery, setSearchQuery] = useState(search);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const sb = useAlphaSnackbar();

  const fetchDataCallbackRef = useRef(fetchDataCallback);
  const fetchDataParamsRef = useRef(fetchDataParams);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetchDataCallbackRef.current({
        ...fetchDataParamsRef.current,
        page: (page + 1).toString(),
        ...(searchQuery && { searchQuery }),
      } as TParams);
      setData(response);
    } catch (e: any) {
      setIsError(true);
      sb.trigger(e.response?.data?.error.message || 'Something went wrong retrieving your users');
    } finally {
      setIsLoading(false);
    }
  }, [page, searchQuery]);

  useEffect(() => {
    setPage(0);
    setSearchQuery(search);
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    rows: data,
    totalRowCount: data?.totalItems || 0,
    page,
    fetchData,
    setPage,
    isLoading,
    isError,
  };
};

export default useDataTable;
