import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    errorButton: {
      marginRight: '16px',
    },
  }),
  { name: 'Footer' },
);

export default useStyles;
