import React from 'react';
import { faCommentAltDots } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import PhoneAuthy from '../../../../assets/phoneAuthy.svg';
import useStyles from './index.style';

interface IMfaImage {
  mfaImage: 'authy' | 'sms' | undefined,
}

const HelperAsset: React.FC<IMfaImage> = ({ mfaImage }: IMfaImage) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, mfaImage ? classes.fullOpacity : classes.noOpacity)}>
      <img
        src={mfaImage === 'authy' ? PhoneAuthy : '/static/images/phoneSms.png'}
        alt="Onboarding email being displayed on a laptop and a mobile phone"
        className="assetImage"
      />
      <Typography className="assetTitle">
        {
          mfaImage === 'sms' ? <FontAwesomeIcon icon={faCommentAltDots} className="marginRight" />
            : (
              <img
                src="/static/images/authyIcon.png"
                alt="Authy Icon"
                className="marginRight authyIcon"
              />
            )
        }
        {mfaImage === 'authy' ? 'Authy sent to device' : 'SMS sent to device'}
      </Typography>
    </div>
  );
};
export default HelperAsset;
