import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 0,
      width: 50,
      height: 50,
    },
    transactionsButton: {
      minWidth: 0,
      padding: 16,
      margin: 'auto',

    },
  }),
  { name: 'HeaderButtons' },
);

export default useStyles;
