import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { ActionButton, GhostButton } from 'components/Buttons';
import { PaymentRouteEnum } from '../../../../models/payments';
import routes from '../../../../routes.path';
import history from '../../../../services/history/browserHistory';
import { testIds } from '../Funding';
import { useStyles } from './RequestLiveRateFooter.styles';

interface IProps {
  disableLiveRateBtn: boolean;
  handleCheckSameTrade: () => void;
  isStandingOrder: boolean;
}

const RequestLiveRateFooter: React.FC<IProps> = (props: IProps) => {
  const { disableLiveRateBtn, handleCheckSameTrade, isStandingOrder } = props;
  const classes = useStyles();
  const { batchId, accountId } = useParams<{
    batchId: string;
    accountId: string;
  }>();

  const buttonText = isStandingOrder ? 'Book now' : 'Request live rates';
  const disclamerText = isStandingOrder
    ? 'Once you click \'Book Now\', you will enter into a legal contract with Alpha Group to buy or sell the currencies you have selected.'
    : 'Once you request a rate, if you click \'Book\' on the next screen, you will enter into a legal contract with Alpha to buy or sell the currencies you have selected.';

  return (
    <div className={classes.requestFooter}>
      <GhostButton
        colorVariant="default"
        testId={testIds.backToSummaryBtn}
        size="large"
        startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={() => {
          history.push(
            routes.payments.multientity.batchSummaryRouteFunc(
              accountId,
              batchId,
              PaymentRouteEnum.PENDING,
            ),
          );
        }}
      >
        Back to summary
      </GhostButton>
      <p>
        <span className="bold">Disclaimer</span>
        {' '}
        { disclamerText }
      </p>
      <ActionButton
        testId={testIds.requestLiveRateBtn}
        size="large"
        onClick={handleCheckSameTrade}
        disabled={disableLiveRateBtn}
      >
        { buttonText }
      </ActionButton>
    </div>
  );
};

export default memo(RequestLiveRateFooter);
