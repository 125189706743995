interface ILink {
  text: string,
  url?: string
}

export enum Titles {
  ENTITIES = 'Entities',
  USERS = 'Users',
  INFORMATION = 'Information',
}

export interface IUserManagementConfig {
  [key: string]: {
    breadcrumb: Array<ILink>,
    tabs: Array<Titles>,
    rightAlignedCta: Array<JSX.Element>,
    defaultTabIndex: number,
    component: JSX.Element,
    text?: string,
  }
}

export type TUser = {
  id: string,
  firstName: string,
  lastName: string,
  status: string,
  created: string,
  updated: string,
  mobile: string,
  phone: string,
  email: string,
}

type TWizardError = {
  step: number | false,
  nested: number,
  message: string,
}
export class WizardError extends Error {
  readonly step: number | false;

  readonly nested: number | false;

  constructor({ step, nested, message }: TWizardError) {
    super(message);
    this.step = step;
    this.nested = nested;
  }
}
