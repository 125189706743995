import React from 'react';
import { TextFieldProps } from '@mui/material';

import StyledTextField from './index.styles';

export const APInput = (props: TextFieldProps & {
    testid: string;
}) => {
  const { testid } = props;
  return (
    <StyledTextField
      inputProps={{
        'data-testid': testid,
      }}
      {...props}
      autoComplete="off"
    />
  );
};
