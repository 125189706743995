import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  title: {
    color: 'white',
    fontSize: '22px',
    fontWeight: 100,
  },
  eyeIconStyles: {
    fontSize: '18px',
    transform: 'translate(-50px, 14px)',
    color: 'white',
    position: 'absolute',
    opacity: '50%',
    backgroundColor: 'transparent',
    border: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  subtitle: {
    color: 'white',
    fontSize: '14px',
    opacity: '70%',
  },
  label: {
    color: 'white',
    fontSize: '14px',
    marginBottom: '8px',
  },
  input: {
    marginBottom: '32px',
  },
  section: {
    marginBottom: '32px',
    '& :-internal-autofill-selected, & :-internal-autofill-previewed': {
      WebkitBoxShadow: '0 0 0 0 rgb(247, 247, 247, 0.13) inset',
      WebkitBackgroundClip: 'text',
      caretColor: 'white',
      WebkitTextFillColor: '#ffffff',
      transition: 'background-color 5000s ease-in-out 0s',
      boxShadow: 'inset 0 0 0 0 rgb(247, 247, 247, 0.13)',
    },
  },
  white: {
    color: 'white',
  },
  formBacklink: {
    display: 'inherit',
    marginTop: '8px',
  },
}), { name: 'FormStyles' });

export default useStyles;
