import { Tooltip } from '@mui/material';
import React, {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import useStyles from './TextEllipsis.styles';

interface ITextEllipsis {
  text: string | ReactNode;
  maxChars?: number;
  className?: string;
  testId?: string;
}

const isEllipsisActive = (e: HTMLParagraphElement) => (e.offsetWidth < e.scrollWidth);

const TextEllipsis = ({
  text, maxChars, className, testId = 'text-ellipsis',
} : ITextEllipsis) => {
  const styles = useStyles({ chars: maxChars });
  const ref = useRef<HTMLParagraphElement>(null);
  const [showToolTip, setShowToolTip] = useState<boolean>();

  useEffect(() => {
    setShowToolTip(!!(ref.current && isEllipsisActive(ref.current)));
  }, [ref.current, text]);

  const pElement = (
    <p data-testid={testId} ref={ref} className={clsx(styles.root, className)}>{text}</p>
  );

  return (
    showToolTip
      ? (
        <Tooltip
          title={(
            <div className={styles.tooltipText}>
              {text}
            </div>
        )}
          arrow
        >
          {pElement}
        </Tooltip>
      ) : pElement
  );
};

export default TextEllipsis;
