import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import React from 'react';
import DrawerBackdropLoader from '../../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import useEntityGroupDrawer from '../useEntityGroupDrawer';
import CreateEntityGroupBody from './CreateEntityGroupBody';
import CreateEntityGroupHeader from './CreateEntityGroupHeader';
import useCreateEntityGroup from './useCreateEntityGroup';
import useStyles from '../ViewEntityGroups/ViewEntityGroupsDrawer.styles';

interface IProps {
    open: boolean;
}

const CreateEntityGroup: React.FC<IProps> = (props: IProps) => {
  const {
    open,
  } = props;

  const classes = useStyles();
  const {
    toggleCreateGroupDrawer,
    closeAllGroupManagerDrawers,
    entityAccounts,
  } = useEntityGroupDrawer();
  const {
    form,
    accountsInNewGroup,
    handleSubmitForm,
    resetAccountsInNewGroupForm,
    addAccountToNewGroup,
    loading,
  } = useCreateEntityGroup();

  const handleClose = () => {
    resetAccountsInNewGroupForm();
    form.resetForm();
    closeAllGroupManagerDrawers();
  };
  const handleClickBack = () => {
    toggleCreateGroupDrawer(false);
    resetAccountsInNewGroupForm();
    form.resetForm();
  };

  return (
    <BaseDrawer.Drawer className={classes.drawerWrapper} data-testid="create-entity-group-drawer" open={open} onClose={handleClose} anchor="right">
      <CreateEntityGroupHeader
        selectedAccounts={accountsInNewGroup}
        addAccountToGroup={addAccountToNewGroup}
        userAccounts={entityAccounts}
        handleSubmitForm={handleSubmitForm}
        form={form}
        handleClickBack={handleClickBack}
      />
      <CreateEntityGroupBody accounts={accountsInNewGroup} />
      <DrawerBackdropLoader width="610px" display={loading || false} text="Creating new group..." />
    </BaseDrawer.Drawer>
  );
};

export default CreateEntityGroup;
