import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { Drawer } from '../../../../components/Drawer';
import { ITableColumn, ITableData } from '../../../../services/DatabaseServices/marker.interface';
import { Table } from '../../../../components/Table/Table';
import useStyles from './index.styles';
import { useUserManagementContext } from '../../context';

interface IPermissionInfoDrawer {
  permissionDrawerOpen: boolean;
  setPermissionDrawerOpen: Function;
  testId?: string;
}

export const PermissionInfoDrawer: React.FC<IPermissionInfoDrawer> = (
  props: IPermissionInfoDrawer,
) => {
  const { testId = 'permission-drawer', permissionDrawerOpen, setPermissionDrawerOpen } = props;
  const classes = useStyles();
  const { rolesMap } = useUserManagementContext();
  const permissionInfoData: ITableData[] = Object.values(rolesMap || {})
    .filter((role) => role.description);

  const permissionInfoHeaders: ITableColumn[] = [
    {
      header: 'Permission', accessor: 'label', width: 200, className: clsx(classes.permissionNames, classes.headerStyles),
    },
    { header: 'Description', accessor: 'description', className: classes.headerStyles },
  ];

  return (
    <Drawer
      noPadding
      testId={testId}
      anchor="right"
      open={permissionDrawerOpen}
      onClose={() => setPermissionDrawerOpen()}
      headerTitle="Permissions"
    >
      <Box>
        <Table
          columns={permissionInfoHeaders}
          data={permissionInfoData}
          testId="permission-desc-table"
        />
      </Box>
    </Drawer>
  );
};

export default PermissionInfoDrawer;
