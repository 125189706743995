import _default, { makeStyles } from 'theme';

const { palette, typography } = _default;

export const useStyles = makeStyles(
  () => ({
    dropdownTitle: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      marginLeft: 8,
      '& h2': {
        cursor: 'pointer',
        color: palette.primary.main,
        fontWeight: typography.fontWeightLight,
        fontSize: 28,
        margin: 0,
      },
      '& svg': {
        color: '#A5A5A5',
        cursor: 'pointer',
        marginTop: 3,
        fontSize: 15,
      },
    },
    accountMenuDivider: {
      border: 'none',
      height: 1,
      background: '#ececec',
      marginTop: 5,
      marginBottom: 0,
    },
    accountMenuOption: {
      alignItems: 'center',
      display: 'flex',
      padding: ({ nested }: { nested?: boolean }) => `5px 0px 5px ${nested ? '32' : '16'}px`,
      '&:hover': {
        backgroundColor: palette.background.default,
      },
      '& > label': {
        width: '100%',
      },
    },
    frozenStatus: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: 5,
      paddingLeft: 10,
      paddingRight: 10,
      marginRight: 10,
      height: 'fit-Content',
    },
    questionIcon: {
      marginLeft: 3,
    },
    groupMenuOption: {
      display: 'flex',
      padding: '5px 16px',
      backgroundColor: palette.background.default,
      '& > label': {
        width: '100%',
        '& .MuiFormControlLabel-label': {
          fontWeight: 600,
          color: palette.primary.main,
        },
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: palette.grey[100],
      },
    },
    groupMenuChevronBtn: {
      display: 'flex',
      lineHeight: 0,
      padding: 0,
      height: 25,
      width: 25,
      alignSelf: 'center',
    },
    groupMenuOptions: {
      backgroundColor: palette.background.default,
    },
    selectorDropdown: {
      width: '500px',
      maxHeight: '700px',
    },
    dropdown: {
      '& .MuiTextField-root': {
        height: '25px',
        borderRadius: '5px',
        padding: '0 16px',
        paddingRight: '40px',
        marginBottom: '6px',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: palette.background.default,
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiSvgIcon-root': {
        right: '7px',
        top: '0px',
      },
    },
    noResults: {
      textAlign: 'center',
      padding: '24px',
      '& > div': {
        padding: '40px',
      },
    },

    noResultsText: {
      marginBottom: '16px',
    },

    noResultsIcon: {
      margin: 'auto',
      height: '168px',
      width: '168px',
      backgroundColor: 'rgba(225, 225, 225, 0.2)',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        height: '90px',
        width: '90px',
        padding: '6px 0 0 6px',
      },
    },
  }),
  { name: 'Dropdown' },
);
export default useStyles;
