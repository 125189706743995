import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import PaymentsService from '../../../../../services/Payments/payments.service';
import { TStore } from '../../../../../store';
import { initiatePayment } from '../../../../../store/authy/actions';
import navigateToStartOfJourney from '../../../../../utils/navigateToStartOfJourney';
import BatchSubmittedFooter from '../BatchSubmittedFooter/BatchSubmittedFooter';
import ApproveRejectFooter from './ApproveRejectFooter/ApproveRejectFooter';

interface IProps {
  batchId: string;
  accountId: string;
  batchName: string;
  canApprove: boolean;
  requiresFx: boolean;
  isFinalApprover: boolean;
  uploadedById?: string;
  canReject?: boolean;
  shouldShowFraudWarningModal: boolean;
  paymentDraftsLoading: boolean;
}

const ApproveRejectContainer: React.FC<IProps> = (props: IProps) => {
  const {
    batchId,
    accountId,
    batchName,
    canApprove,
    requiresFx,
    isFinalApprover,
    uploadedById,
    canReject,
    shouldShowFraudWarningModal,
    paymentDraftsLoading,
  } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [acknowledgeModalOpen, setAckowledgdeModalOpen] = useState(false);
  const sb = useAlphaSnackbar();
  const dispatch = useDispatch();
  const userId = useSelector<TStore, string | undefined>(
    (store) => store.user.user?.Username,
  );

  const buttonTextFunc = (): string => {
    if (isFinalApprover && canApprove) {
      if (requiresFx) {
        return 'Approve Batch and Book Fx';
      }
      return 'Approve Batch and Send Payments';
    }
    return 'Approve Batch';
  };

  const handleRejectBatchButton = () => setModalOpen(true);

  const handleRejectPayment = async () => {
    setLoading(true);
    try {
      await PaymentsService.postRejectPayment(
        batchId,
        userId,
        uploadedById,
        accountId,
      );
      setModalOpen(false);
      datadogRum.addAction('SUCCESSFULLY REJECTED PAYMENT');
      sb.trigger('Successfully rejected your payment', 'info');
      navigateToStartOfJourney();
    } catch {
      sb.trigger(
        'Something went wrong rejecting your payment, please try again',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleModalConfirmation = async () => {
    dispatch(
      initiatePayment({
        batchId,
        type: 'PAYMENT_APPROVE',
        approverOwn: Boolean(uploadedById === userId),
        accountId,
        fraudWarningAcknowledged: true,
      }),
    );
    setAckowledgdeModalOpen(false);
  };

  const handleApprovePayment = async () => {
    if (shouldShowFraudWarningModal) {
      setAckowledgdeModalOpen(true);
    } else {
      dispatch(
        initiatePayment({
          batchId,
          type: 'PAYMENT_APPROVE',
          approverOwn: Boolean(uploadedById === userId),
          accountId,
          fraudWarningAcknowledged: false,
        }),
      );
      setAckowledgdeModalOpen(false);
    }
  };

  if (canApprove || canReject) {
    return (
      <ApproveRejectFooter
        buttonText={buttonTextFunc()}
        batchName={batchName}
        modalOpen={modalOpen}
        handleClose={() => (modalOpen ? setModalOpen(false) : null)}
        handleRejectBatchButton={handleRejectBatchButton}
        handleApprovePayment={handleApprovePayment}
        handleRejectPayment={handleRejectPayment}
        loading={loading}
        canApprove={canApprove}
        canReject={canReject}
        handleModalConfirmation={handleModalConfirmation}
        acknowledgeModalOpen={acknowledgeModalOpen}
        setAckowledgdeModalOpen={setAckowledgdeModalOpen}
        paymentDraftsLoading={paymentDraftsLoading}
      />
    );
  }
  return <BatchSubmittedFooter />;
};

export default ApproveRejectContainer;
