import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import MenuDropdownButton from 'components/MenuDropdownButton/MenuDropdownButton';
import { Menu as MuiMenu } from '@mui/material';
import { StyledMenuIconWrapper } from './Menu.styles';
import { IMenuProps } from './Menu.interfaces';

const Menu: FC<IMenuProps> = (props: IMenuProps) => {
  const {
    buttonTitle,
    userAccount,
    icon,
    onClose,
    open,
    anchorEl,
    setOpen,
    disableBackdropClick,
    children,
    obscure,
    buttonProps,
    menuIconProps,
    menuProps,
  } = props;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => setOpen(open ? null : event.currentTarget);

  const handleClose = () => {
    if (!disableBackdropClick) {
      setOpen(null);
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <>
      {!icon ? (
        <MenuDropdownButton
          buttonTitle={buttonTitle}
          userAccount={userAccount}
          handleClick={handleClick}
          buttonProps={buttonProps}
          menuIconProps={menuIconProps}
          obscure={obscure}
        />
      ) : (
        <StyledMenuIconWrapper onClick={handleClick} {...buttonProps}>
          <FontAwesomeIcon data-testid="menu-icon" icon={icon} color="rgba(51, 51, 51, 0.5)" />
        </StyledMenuIconWrapper>
      )}
      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
        {...menuProps}
      >
        {children}
      </MuiMenu>
    </>
  );
};

export default Menu;
