import { emphasize } from '@mui/material';
import _default, { makeStyles } from 'theme';

const {
  typography,
  palette: { primary },
} = _default;

const useStyles = makeStyles(
  () => ({
    loader: {
      display: 'flex',
      height: '100%',
      flexGrow: 1,
      flexDirection: 'column',
    },
    success: {
      backgroundColor: '#3a9b90 !important',
    },
    '@global': {
      'body, html': {
        fontSize: 13,
        lineHeight: 1.5,
        fontWeight: 300,
        height: '100%',
      },
      body: {
        fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
        backgroundColor: '#fff',
      },
      '#root': {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
      },
      a: {
        fontWeight: typography.fontWeightRegular,
        color: emphasize(primary.main, 0.15),
        textDecoration: 'none',
      },
      '*': {
        boxSizing: 'border-box',
      },
      '.MuiAutocomplete-popper': {
        '& li': {
          padding: '8px 18px',
          '& > div > span:first-of-type': {
            height: '20px',
            width: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            overflow: 'hidden',
            '& img': {
              height: '100%',
              objectFit: 'cover',
            },
          },
        },
      },
    },
  }),
  { name: 'App' },
);

export default useStyles;
