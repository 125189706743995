import theme, { makeStyles } from 'theme';

const { palette } = theme;

export const useStyles = makeStyles(
  () => ({
    linearProgress: {
      '& .MuiLinearProgress-root': {
        height: '8px',
        borderRadius: '5px',
      },
    },
    message: {
      '& .MuiTypography-subtitle1': {
        fontSize: '14px',
      },
      '& .red': {
        color: palette.error.main,
      },
      '& .bold': {
        fontWeight: 'bold',
      },
      margin: '16px 0 24px 0',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& .cancelButton': {
        width: '95px',
      },
      '& .MuiButton-containedPrimary': {
        width: '400px',
      },
    },
  }),
  { name: 'InterAccountTransfer' },
);

export default useStyles;
