import { useContext } from 'react';
import InterAccountTransferFormContext from '../../context/InterAccountTransferFormContext';
import { TIATAccountTypes } from '../../models/interAccountTransfer';

const useInterAccountTransferFormContext = () => {
  const form = useContext(InterAccountTransferFormContext);

  const { debitingAccount, creditingAccount } = form!.values;

  const isSectionComplete = (type: TIATAccountTypes): boolean => {
    const validateDebitingAmount = type === 'DEBITING'
      ? Boolean(debitingAccount.currencyAccount?.currencyCode)
      : Boolean(creditingAccount.currencyAccount?.currencyCode);

    return validateDebitingAmount;
  };

  return {
    form: form!,
    isSectionComplete,
  };
};

export default useInterAccountTransferFormContext;
