import { Typography } from '@mui/material';
import { List } from 'react-virtualized';
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TUserAccount } from '../../../../models/user';
import AccountInNewGroupCard from './AccountInNewGroupCard';
import useStyles from './CreateEntityGroup.style';
import useCreateEntityGroup from './useCreateEntityGroup';

interface IProps {
  accounts?: TUserAccount[];
}

const CreateEntityGroupBody: React.FC<IProps> = (props: IProps) => {
  const {
    accounts,
  } = props;
  const classes = useStyles();

  const {
    removeAccount,
  } = useCreateEntityGroup();

  return (
    <div className={classes.bodyWrapper}>
      {accounts && accounts.length > 0
        ? (
          <List
            width={610}
            height={620}
            rowHeight={128}
            rowCount={accounts.length}
            rowRenderer={({ index, style }) => {
              const account = accounts[index];
              return (
                <div key={account.id} style={style}>
                  <AccountInNewGroupCard removeAccount={removeAccount} account={account} />
                </div>
              );
            }}
          />
        ) : (
          <div data-testid="no-accounts-card" className={classes.noAccountsCard}>
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon className={classes.folderIcon} icon={faFolderOpen} />
            </div>
            <Typography variant="h5">
              No Entities added
            </Typography>
            <Typography variant="subtitle1">
              Select an entity to add to group
            </Typography>
          </div>
        )}
    </div>
  );
};
export default CreateEntityGroupBody;
