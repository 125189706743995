import React from 'react';
import { TTransactionData } from '../../../../models/transactions';
import { formatCurrency } from '../../../../utils/currency.helpers';
import formatIsoDateTime from '../../../../utils/formatIsoDateTime';
import BodyDetails from './BodyDetails/BodyDetails';

interface IBody {
  transaction: TTransactionData;
  paymentId?: string;
}

const Body: React.FC<IBody> = (props: IBody) => {
  const { transaction, paymentId } = props;

  const entityAccountDetails = [
    {
      key: 'Sent By(Entity)',
      value: transaction.account?.name,
    },
  ];

  const tradeDetails = [
    {
      key: 'Trade Id',
      value: transaction.tradeFriendlyId || 'N/A',
      boldValue: true,
    },
  ];

  const debitingDetails = [
    {
      key: 'Debiting amount',
      value:
        transaction.debitCurrencyCode && transaction.debitAmount
          ? '-'.concat(
            formatCurrency(
              transaction.debitCurrencyCode,
              transaction.debitAmount,
            ),
          )
          : 'N/A',
      boldValue: true,
    },
  ];

  const creditingDetails = [
    {
      key: 'Crediting amount',
      value:
        transaction.creditCurrencyCode && transaction.creditAmount
          ? '+'.concat(
            formatCurrency(
              transaction.creditCurrencyCode,
              transaction.creditAmount,
            ),
          )
          : 'N/A',
      boldValue: true,
    },
  ];

  const beneficiaryDetails = [
    {
      key: 'Beneficiary name',
      value: transaction.creditFriendlyName || 'N/A',
    },
    {
      key: 'Individual/Company',
      value: transaction.beneficiary?.type || 'N/A',
    },
    {
      key: 'Address',
      value: transaction.beneficiary?.address || 'N/A',
    },
    {
      key: 'Bank Country',
      value: transaction.beneficiary?.bankCountryCode || 'N/A',
    },
  ];

  const referenceDetails = [
    {
      key: 'Reference',
      value: transaction.reference || 'N/A',
    },
  ];

  const paymentDetails = [
    {
      key: 'Reference',
      value: transaction.reference,
    },
    {
      key: 'Amount',
      value: transaction.creditCurrencyCode && transaction.creditAmount
        ? ''.concat(
          formatCurrency(
            transaction.creditCurrencyCode,
            transaction.creditAmount,
          ),
        )
        : 'N/A',
      boldValue: true,
    },
    {
      key: 'Currency',
      value: transaction.creditCurrencyCode || 'N/A',
    },
    {
      key: 'Fixed Side',
      value: transaction.fixedCurrency || 'N/A',
    },
    {
      key: 'Account Number',
      value: transaction.beneficiary?.iban
        ? transaction.beneficiary.iban : transaction.beneficiary?.accountNumber
        || 'N/A',
    },
    {
      key: 'SWIFT',
      value: transaction.beneficiary?.swift || 'N/A',
    },
    {
      key: 'National Bank Code',
      value: transaction.beneficiary?.nationalBankCode || 'N/A',
    },
    {
      key: 'Purpose of payment',
      value: transaction.purpose || 'N/A',
    },
    {
      key: 'Correspondant account',
      value: transaction.beneficiary?.correspondentAccountNumber || 'N/A',
    },
    {
      key: 'Correspondant SWIFT',
      value: transaction.beneficiary?.correspondentSwift || 'N/A',
    },
    {
      key: 'Further to account',
      value: transaction.beneficiary?.furtherToAccountNumber || 'N/A',
    },
    {
      key: 'Further to SWIFT',
      value: transaction.beneficiary?.furtherToSwift || 'N/A',
    },
    {
      key: 'Debiting account details',
      value: transaction.debitingAccount?.friendlyName
        ? transaction.debitingAccount?.friendlyName?.concat(' | ', transaction.debitingAccount?.iban)
        : transaction.debitingAccount?.iban
        || 'N/A',
    },
    {
      key: 'Debiting account currency',
      value: transaction.debitingAccount?.currency || 'N/A',
    },
    {
      key: 'Funding account details',
      value: transaction.creditingAccount?.friendlyName
        ? transaction.creditingAccount?.friendlyName?.concat(' | ', transaction.creditingAccount?.iban)
        : transaction.creditingAccount?.iban
        || 'N/A',
    },
    {
      key: 'Funding account currency',
      value: transaction.creditingAccount?.currency || 'N/A',
    },
  ];

  const paymentTimeline = [
    {
      key: 'Payment Input',
      value: transaction.createdBy?.concat(' ', formatIsoDateTime(transaction.createdDate)),
    },
    {
      key: `Approvers (${transaction.approvals?.length?.toString()})`,
      value:
        (
          <>
            {transaction.approvals?.map((approver) => (
              <p>
                {approver.userId.concat(' ', formatIsoDateTime(approver.date))}
              </p>
            ))}
          </>
        ),
    },
    {
      key: 'Completed Date',
      value: formatIsoDateTime(transaction.valueDate),
    },
  ];

  return (

    <BodyDetails
      paymentId={paymentId}
      entityAccountDetails={entityAccountDetails}
      transaction={transaction}
      tradeDetails={tradeDetails}
      debitingDetails={debitingDetails}
      creditingDetails={creditingDetails}
      referenceDetails={referenceDetails}
      beneficiaryDetails={beneficiaryDetails}
      paymentDetails={paymentDetails}
      paymentTimeline={paymentTimeline}
    />

  );
};

export default Body;
