import {
  PaymentBatchDraftDto,
  PaymentBatchDraftStatus,
  PaymentBatchDto,
} from '@alpha/payments-dtos';
import React, { memo } from 'react';
import isPaymentBatchDraftDto from '../../../../utils/payments/isPaymentBatchDraftDto.helper';
import ApproveRejectContainer from './ApproveRejectFooterContainer/ApproveRejectFooterContainer';
import BatchSubmissionFooterContainer from './BatchSubmissionFooterContainer/BatchSubmissionFooterContainer';
import BatchSubmittedFooter from './BatchSubmittedFooter/BatchSubmittedFooter';
import BookFxFooterContainer from './BookFxFooterContainer/BookFxFooterContainer';
import ReleaseFundedBatchContainer from './ReleaseFundedBatchContainer/ReleaseFundedBatchContainer';

interface IProps {
  batchDetails: PaymentBatchDraftDto | PaymentBatchDto;
}
const FooterContainer: React.FC<IProps> = (props: IProps) => {
  const { batchDetails } = props;

  const isFinalApprover = (_batchDetails: PaymentBatchDraftDto) => {
    if (
      _batchDetails.approvalsRequired
      && _batchDetails.noOfApprovals !== undefined
      && _batchDetails.approvalsRequired - _batchDetails.noOfApprovals === 1
    ) {
      return true;
    }
    return false;
  };

  if (isPaymentBatchDraftDto(batchDetails)) {
    switch (batchDetails.batchStatus) {
      case PaymentBatchDraftStatus.SUBMITTED:
        return (
          <ApproveRejectContainer
            batchId={batchDetails.batchId}
            batchName={batchDetails.sourceName || 'Payment Batch'}
            uploadedById={batchDetails.uploadedById}
            canApprove={batchDetails.currentUserCanApprove}
            requiresFx={batchDetails.requiresFx}
            isFinalApprover={isFinalApprover(batchDetails)}
          />
        );
      case PaymentBatchDraftStatus.APPROVED:
        if (batchDetails.requiresFx) {
          return (
            <BookFxFooterContainer
              batchId={batchDetails.batchId}
              batchName={batchDetails.sourceName || 'Payment Batch'}
              uploadedById={batchDetails.uploadedById}
            />
          );
        }
        return <BatchSubmittedFooter />;
      case PaymentBatchDraftStatus.FUNDED:
        return <ReleaseFundedBatchContainer batchId={batchDetails.batchId} />;
      case PaymentBatchDraftStatus.VALIDATED:
        return <BatchSubmissionFooterContainer batchDetails={batchDetails} />;
      default:
        return <BatchSubmittedFooter />;
    }
  }

  return <BatchSubmittedFooter />;
};

export default memo(FooterContainer);
