import { AccountDto } from '@alpha/auth-dtos';
import { ActionButton } from 'components/Buttons';
import { Box } from '@mui/material';
import React from 'react';
import clipboardCross from '../../../../../assets/clipboardCross.svg';
import EmptyTable from '../../../../../components/Table/EmptyTable/EmptyTable';
import SelectAccount from '../SelectAccount/index';
import useStyles from './index.styles';

interface IProps {
  availableAccounts: AccountDto[],
  selectedAccounts: AccountDto[],
  displayingAccounts: AccountDto[],
  searchTerm: string,
  loading: boolean,
  handleShareBeneficiarySubmit: () => Promise<void>,
  handleSelectAccount: (accountId: string) => void,
  handleAllAccounts: () => void,
}

const Body: React.FC<IProps> = ({
  availableAccounts,
  selectedAccounts,
  displayingAccounts,
  searchTerm,
  loading,
  handleShareBeneficiarySubmit,
  handleSelectAccount,
  handleAllAccounts,
}: IProps) => {
  const classes = useStyles();

  const numberOfAvailableAccounts = selectedAccounts.length;
  const textButton = `Share ${numberOfAvailableAccounts > 1 ? 'beneficiary to entities' : 'beneficiary to entity'}`;

  const disabledActionButton = Boolean(loading || !selectedAccounts.length);

  return (
    <Box className={classes.bodyWrapper}>
      <Box>
        <div className={classes.allEntriesBox}>
          <Box className={classes.noOfAccounts}>
            {`${searchTerm ? `Number of entities for "${searchTerm}" : ` : 'Number of entities: '}`}
            <b>{displayingAccounts.length}</b>
          </Box>
          <SelectAccount
            handleChange={handleAllAccounts}
            checked={availableAccounts.length === selectedAccounts.length}
            text={`Select all entities (${availableAccounts.length})`}
            id="all"
            disabled={Boolean(!availableAccounts.length)}
          />
        </div>
        <div className={`${displayingAccounts.length ? `${classes.accountsBox}` : ''}`}>
          { displayingAccounts.length
            ? displayingAccounts.map(({ id, name }: AccountDto) => (
              <SelectAccount
                key={id}
                id={id}
                text={name}
                checked={selectedAccounts.some((sa) => sa.id === id)}
                handleChange={() => handleSelectAccount(id)}
              />
            ))
            : (
              <div className={classes.emptyTable}>
                <EmptyTable
                  icon={clipboardCross}
                  title="No entities found"
                  subtitle="No results found for this search term"
                />
              </div>
            )}
        </div>
      </Box>
      <Box className={classes.accButton}>
        <Box>
          Selected entities:
          <b className={classes.totalSelected}>
            {` ${selectedAccounts.length}`}
          </b>
        </Box>
        <ActionButton
          size="large"
          testId="share-bene-btn"
          onClick={handleShareBeneficiarySubmit}
          disabled={disabledActionButton}
        >
          {textButton}
        </ActionButton>
      </Box>
    </Box>
  );
};

export default Body;
