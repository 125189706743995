import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    tableWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      marginBottom: 'auto',
      '& th:first-child': {
        width: 90,
      },
    },
    loaderWrapper: {
      height: '100%',
      position: 'relative',
    },
  }),
  { name: 'ErrorPaymentTableContainer' },
);

export default useStyles;
