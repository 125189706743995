import React from 'react';
import { Typography } from '@mui/material';
import { ActionButton } from 'components/Buttons';
import { NavLink } from 'react-router-dom';
import { APInput } from 'components/APInput';
import usePasswordReset from '../../../../hooks/usePasswordReset';
import useFormStyles from '../../form.styles';
import FormTitle from '../../../../components/Molecules/Auth/FormTitle';
import routes from '../../../../routes.path';

interface IProps {
  setUsername: React.Dispatch<React.SetStateAction<string | undefined>>
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Request: React.FC<IProps> = ({ setUsername, setEmail }: IProps) => {
  const { sendEmailFormik, loading } = usePasswordReset(undefined, setUsername, setEmail);
  const formClasses = useFormStyles();
  return (
    <div>
      <FormTitle
        title="Password reset"
        description="To reset your password please enter your username below. We will send out instructions to resetting your password."
      />
      <form onSubmit={sendEmailFormik.handleSubmit}>
        <div className={formClasses.section}>
          <Typography className={formClasses.label}>Username</Typography>
          <APInput
            autoComplete="off"
            testid="username"
            id="username"
            name="username"
            placeholder="Enter your username"
            variant="filled"
            type="text"
            onChange={sendEmailFormik.handleChange}
            value={sendEmailFormik.values.username}
            error={sendEmailFormik.touched.username && Boolean(sendEmailFormik.errors.username)}
            fullWidth
            helperText={sendEmailFormik.touched.username && sendEmailFormik.errors.username}
          />
        </div>
        <ActionButton
          size="large"
          type="submit"
          testId="send-password-reset-email"
          fullWidth
          loading={loading}
          disabled={loading}
        >
          send code
        </ActionButton>
        <NavLink
          to={routes.auth.login}
          className={formClasses.formBacklink}
        >
          Back to Sign In
        </NavLink>
      </form>
    </div>
  );
};

export default Request;
