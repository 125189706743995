import React, { useEffect, useState } from 'react';
import { StyledTabsWrapper } from 'components/Tabs';
import browserHistory from '../../../../services/history/browserHistory';
import { Titles } from '../../../../models/userManagement';
import { routes } from 'domain/UserManagement/configs/userManagement.config';

interface UserManagementTabsProps {
  defaultTabIndex: number;
  tabs: Titles[];
}

const UserManagementTabs: React.FC<UserManagementTabsProps> = (
  { defaultTabIndex = 0, tabs = [] },
) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(defaultTabIndex);

  useEffect(() => {
    setSelectedTabIndex(defaultTabIndex);
  }, [defaultTabIndex]);

  const handleTabChange = (tabIndex: number) => {
    const tab = tabs[tabIndex];
    const route = routes[tab];
    if (tab && route) {
      browserHistory.push(route);
    }
    setSelectedTabIndex(tabIndex);
  };

  return (
    <StyledTabsWrapper
      testId="user-management-tabs"
      tabTitles={tabs}
      tabIndex={selectedTabIndex in tabs ? selectedTabIndex : defaultTabIndex}
      handleChange={handleTabChange}
    />
  );
};

export default UserManagementTabs;
