import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    titleStatusWrapper: {
      marginLeft: 40,
      fontWeight: typography.fontWeightSemiBold,
      display: 'flex',
      alignItems: 'center',
    },
    titleStatus: {
      marginRight: 8,
    },
    icon: {
      marginRight: 5,
    },
  }),
  { name: 'TitleStatus' },
);

export default useStyles;
