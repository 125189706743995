import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import { AccountBeneficiaryPortalStatus } from '@alpha/bene-types';
import { TKeyValue } from 'components/Table/Table.interfaces';
import React from 'react';
import KeyValueTable from 'components/Table/KeyValueTable/KeyValueTable';
import { createBeneficiaryType } from '../../../../../../components/Drawer/BeneficiaryDrawer/Body/Body';
import formatBankCode from '../../../../../../utils/formatBankCode';
import formatIsoDate from '../../../../../../utils/formatIsoDate';
import { useStyles } from './Details.styles';

interface IDetailsProps {
  // eslint-disable-next-line react/require-default-props
  selectedBeneficiary?: BeneficiaryDto;
  selectedTabIndex: number;
}
const Details: React.FC<IDetailsProps> = (props: IDetailsProps) => {
  const { selectedBeneficiary, selectedTabIndex } = props;
  const classes = useStyles();

  const showApprovedInformation = () => {
    if (selectedBeneficiary?.portalStatus === AccountBeneficiaryPortalStatus.APPROVED) {
      return [{
        key:
          'Approved by',
        value: `${selectedBeneficiary.approvedBy || '-'},
         ${formatIsoDate(selectedBeneficiary.approvedByDateTime)}`,
      }];
    }

    return [];
  };

  const showRejectedInformation = () => {
    if (selectedBeneficiary?.portalStatus === AccountBeneficiaryPortalStatus.REJECTED) {
      return [{
        key: 'Rejected By',
        value: `${selectedBeneficiary?.rejectedBy || '-'}
         ${formatIsoDate(selectedBeneficiary?.rejectedByDateTime)}`,
      }];
    }

    return [];
  };

  const details: TKeyValue[] = [
    {
      key: 'Beneficiary Type',
      value: createBeneficiaryType(selectedBeneficiary?.type),
      boldValue: true,
    },
    {
      key: 'Address',
      value: selectedBeneficiary?.residentAddress,
      boldValue: true,
    },
    { key: 'Bank Name', value: selectedBeneficiary?.bankName || '-' },
    { key: 'SWIFT/BIC', value: selectedBeneficiary?.swift || '-' },
    {
      key: 'IBAN',
      value: selectedBeneficiary?.iban
        ? selectedBeneficiary.iban && selectedBeneficiary.iban.toUpperCase()
        : '-',
    },
    { key: 'Account Number', value: selectedBeneficiary?.accountNumber || '-' },
    {
      key: 'National Bank Code',
      value: selectedBeneficiary?.nationalBankCode ? formatBankCode(selectedBeneficiary.nationalBankCode, selectedBeneficiary.bankCountryCode) : '-',
    },
    { key: 'Correspondent Account Number', value: selectedBeneficiary?.correspondentAccountNumber || '-' },
    { key: 'Correspondent SWIFT', value: selectedBeneficiary?.correspondentSwift || '-' },
    { key: 'Further To Account Number', value: selectedBeneficiary?.furtherToAccountNumber || '-' },
    {
      key: 'Further To SWIFT',
      value:
        selectedBeneficiary?.furtherToSwift || '-',
    },
  ];
  const info: TKeyValue[] = [
    {
      key: 'Uploaded by',
      value: `${selectedBeneficiary?.uploadedBy || ''
      }, ${formatIsoDate(selectedBeneficiary?.createdDate)}`,
    },
    ...showApprovedInformation(),
    ...showRejectedInformation(),

  ];
  return (
    <div>
      <KeyValueTable testId="beneficiary-detail" data={details} className={classes.beneDetails} obscure />
      <KeyValueTable testId="beneficiary-info" data={info} obscure />
    </div>
  );
};

export default Details;
