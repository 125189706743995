import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    transactionCreationDrawer: {
      '& .MuiDrawer-paper': {
        overflowX: 'hidden',
      },
    },
    drawer: {
      '& .MuiDrawer-paper': {
        overflowX: 'hidden',
        '& > button': {
          zIndex: 100,
        },
      },
    },
    finishButton: {
      marginTop: 'auto',
      marginBottom: 40,
      width: '100%',
      padding: '0px 40px',
      '& .MuiButton-root':{
        width: '100%',
      }
    },
  }),
  { name: 'TransactionsInfoDrawer' },
);

export default useStyles;
