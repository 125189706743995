import { Box } from '@mui/material';
import React from 'react';
import { RolesEnum } from '@portal/user-types/dist/schema';
import useStyles from './index.styles';
import { TRoles, useUserManagementContext } from '../../context';

const groupPermissions = (keys: RolesEnum[], rolesMap: TRoles): JSX.Element[] => {
  const permissionsByGroup: { [key: string]: string[] } = {};
  // Group the permissions by their permission group
  keys.forEach((key) => {
    if (rolesMap && key in rolesMap) {
      const permission = rolesMap[key];
      if (!permission) return;
      const { group, level } = permission;
      if (group) {
        if (!permissionsByGroup[group]) permissionsByGroup[group] = [];
        if (level) {
          permissionsByGroup[group].push(level);
        }
      }
    }
  });
  // Map the permissions for each group to a string
  const result = Object.entries(permissionsByGroup)
    .filter(([group]) => group !== 'null')
    .map(([group, permissions]) => {
      const levels = Array.from(permissions).join(', ');
      return (
        <div key={`${group}: ${levels}`}>
          <>
            {levels.length === 0
              ? <strong>{group}</strong>
              : (
                <>
                  <strong>
                    {group}
                    :
                    {' '}
                  </strong>
                  {levels}
                </>
              )}
          </>
        </div>
      );
    });

  return result;
};

const PermissionList = ({ id, roles }: {id: string, roles: Array<RolesEnum>}) => {
  const classes = useStyles();
  const { rolesMap } = useUserManagementContext();
  const list = groupPermissions(roles, rolesMap).map((item: JSX.Element, index) => (
    <span key={item.key} data-testid={`role-${index}-${id}`}>
      {item}
    </span>
  ));

  return (
    <Box data-testid="list-holder" className={classes.listHolder}>
      {roles.length ? list : 'N/A'}
    </Box>
  );
};

export default PermissionList;
