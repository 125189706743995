import React from 'react';
import { BeneficiaryType } from '@alpha/bene-types';
import KeyValueTable from 'components/Table/KeyValueTable/KeyValueTable';
import { TKeyValue } from 'components/Table/Table.interfaces';
import { TBeneficiaryDrawerBeneficiary } from '../BeneficiaryDrawer';
import useStyles from './Body.styles';
import formatBankCode from '../../../../utils/formatBankCode';

interface IProps {
  beneficiary: TBeneficiaryDrawerBeneficiary | undefined;
}

export const createBeneficiaryType = (beneficiaryType: BeneficiaryType | undefined): string => {
  if (beneficiaryType) {
    return beneficiaryType === BeneficiaryType.ENTITY ? 'Company' : 'Individual';
  }

  return '-';
};

const createDetails = (beneficiary: TBeneficiaryDrawerBeneficiary): TKeyValue[] => [
  {
    key: 'Beneficiary Type',
    value: createBeneficiaryType(beneficiary.type),
    boldValue: true,
  },
  {
    key: 'Address',
    value: beneficiary.residentAddress || '-',
    boldValue: true,
  },
  {
    key: 'Bank Name',
    value: beneficiary && beneficiary.bankName ? beneficiary.bankName : '-',
  },
  {
    key: 'SWIFT/BIC',
    value: beneficiary && beneficiary.swift ? beneficiary.swift : '-',
  },
  {
    key: 'IBAN',
    value:
      beneficiary && beneficiary.iban
        ? beneficiary.iban && beneficiary.iban.toUpperCase()
        : '-',
  },
  {
    key: 'Account Number',
    value:
      beneficiary && beneficiary.accountNumber
        ? beneficiary.accountNumber
        : '-',
  },
  {
    key: 'National Bank Code',
    value:
      beneficiary && beneficiary.nationalBankCode
        ? formatBankCode(beneficiary.nationalBankCode, beneficiary.bankCountryCode)
        : '-',
  },
  {
    key: 'Correspondent Account Number',
    value:
      beneficiary && beneficiary.correspondentAccountNumber
        ? beneficiary.correspondentAccountNumber
        : '-',
  },
  {
    key: 'Correspondent SWIFT',
    value:
      beneficiary && beneficiary.correspondentSwift
        ? beneficiary.correspondentSwift
        : '-',
  },
  {
    key: 'Further To Account Number',
    value:
      beneficiary && beneficiary.furtherToAccountNumber
        ? beneficiary.furtherToAccountNumber
        : '-',
  },
  {
    key: 'Further To SWIFT',
    value:
      beneficiary && beneficiary.furtherToSwift
        ? beneficiary.furtherToSwift
        : '-',
  },
];

const Body: React.FC<IProps> = (props: IProps) => {
  const { beneficiary } = props;
  const styles = useStyles();

  if (!beneficiary) return null;

  return (
    <div className={styles.drawerBody}>
      <KeyValueTable
        testId="payment-beneficiary-drawer"
        data={createDetails(beneficiary)}
        obscure
      />
    </div>
  );
};

export default Body;
