import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { iAllowedAccounts } from 'store/accounts/accounts.reducer';
import Rate from '../../../../components/InterAccountTransfer/Rate/Rate';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import InterAccountTransferService from '../../../../services/InterAccountTransfer/interAccountTransfer.service';
import useInterAccountTransferFormContext from '../../useInterAccountTransferFormContext';
import { IRateIATParams } from './Rate.interfaces';

export enum rateType {
  INDICATIVE = 'indicative',
  LIVE = 'live',
  EXPIRED = 'expired'
}

interface IProps {
  accountId?: string,
  isIndicativeRateLoading: boolean,
  setIsIndicativeRateLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

let rateCounter = 0;

const DisplayIndicativeRate: React.FC<IProps> = (props: IProps) => {
  const { accountId, setIsIndicativeRateLoading, isIndicativeRateLoading } = props;
  const { form } = useInterAccountTransferFormContext();
  const snackbar = useAlphaSnackbar();
  const creditingCurrencyCode = form.values.creditingAccount.currencyAccount?.currencyCode;
  const debitingCurrencyCode = form.values.debitingAccount.currencyAccount?.currencyCode;
  const { indicativeRate } = form.values;
  const fixedSideAmount = form.values.fixedSide === debitingCurrencyCode
    ? form.values.debitingAccount.amount : form.values.creditingAccount.amount;
  const fxRequired: boolean = debitingCurrencyCode !== creditingCurrencyCode;
  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );

  const iatEntity = useMemo(() => (
    allowedAccounts.find((account: iAllowedAccounts) => account.id === accountId)
  ), [accountId, allowedAccounts]);

  const callIndicativeRate = useCallback(debounce(
    async (params: IRateIATParams) => {
      try {
        rateCounter += 1;
        setIsIndicativeRateLoading(true);
        const responseRate = await InterAccountTransferService.getRate({
          debitingCurrencyCode: params.debitingCurrencyCode,
          creditingCurrencyCode: params.creditingCurrencyCode,
          fixedSide: params.fixedSide,
          accountId,
          amount: params.amount,
        });

        if (params.fixedSide === params.creditingCurrencyCode) {
          form.setFieldValue(
            'debitingAccount.amount',
            InterAccountTransferService.calculateAmount(
              'CREDITING',
              params.amount || 0,
              params.debitingCurrencyCode,
              responseRate.rate,
            ),
          );
        } else if (params.fixedSide === params.debitingCurrencyCode) {
          form.setFieldValue(
            'creditingAccount.amount',
            InterAccountTransferService.calculateAmount(
              'DEBITING',
              params.amount || 0,
              params.creditingCurrencyCode,
              responseRate.rate,
            ),
          );
        }

        if (params.isCancelled) return;

        form.setFieldValue('indicativeRate', responseRate, false);
      } catch (error) {
        const errorMessage = error?.response?.data?.error || 'There was an error retrieving your indicative rate';
        snackbar.trigger(errorMessage);
        setIsIndicativeRateLoading(false);
      } finally {
        rateCounter -= 1;
        if (rateCounter === 0) {
          setIsIndicativeRateLoading(false);
        }
      }
    }, 500,
  ), []);

  // we don't want to get the indicative rate based on the amount if dynamicFxSpreadEnable is false
  const rateTriggers = [debitingCurrencyCode, creditingCurrencyCode, fixedSideAmount];
  if (!iatEntity?.dynamicFxSpreadEnabled) {
    rateTriggers.pop();
  }

  useEffect(() => {
    const indicativeRateHandler = async () => {
      let isCancelled = false;
      if (
        debitingCurrencyCode
        && creditingCurrencyCode
        && fxRequired
      ) {
        const transactionAmount = form.values.fixedSide === debitingCurrencyCode
          ? form.values.debitingAccount.amount
          : form.values.creditingAccount.amount;

        await callIndicativeRate({
          debitingCurrencyCode,
          creditingCurrencyCode,
          isCancelled,
          fixedSide: form.values.fixedSide,
          amount: transactionAmount,
        });
      }

      if (!fxRequired) {
        form.setFieldValue(
          'creditingAccount.amount',
          form.values.debitingAccount.amount,
        );
        form.setFieldValue(
          'indicativeRate', undefined,
        );
      }

      return () => {
        isCancelled = true;
      };
    };

    indicativeRateHandler()
      .catch((error) => {
        console.error('Error while getting the indicative rate:', error);
      });
  }, rateTriggers);

  if (fxRequired) {
    return (
      <Rate
        type="indicative"
        debitingCurrency={debitingCurrencyCode}
        creditingCurrency={creditingCurrencyCode}
        rate={indicativeRate?.rate}
        loading={isIndicativeRateLoading}
      />
    );
  }

  return null;
};

export default DisplayIndicativeRate;
