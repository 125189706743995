import { makeStyles } from 'theme';

import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(
  () => ({
    container: {
      position: 'relative',
      width: '86px',
      height: 'inherit',
      '& > .icon': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '45px',
        height: '45px',
        '& > svg': {
          width: '40px',
          height: 'auto',
          color: 'white',
        },
        '& > img': {
          width: '100%',
          height: '100%',
        },
      },
    },
  }),
  { name: 'IconBadge' },
);

export interface IIconBadgeProps {
  backgroundColor: string;
  element: JSX.Element;
  className?: string | undefined;
  width?: string;
  height?: string;
  iconWidth?: string;
}

export const IconBadge: React.FC<IIconBadgeProps> = ({
  backgroundColor,
  element,
  className,
  width,
  height,
  iconWidth,
}: IIconBadgeProps) => {
  const styles = useStyles();
  return (
    <div
      className={clsx(styles.container, className)}
      style={{ backgroundColor, width, height }}
    >
      <div className="icon" style={{ width: iconWidth, height: iconWidth }}>
        {element}
      </div>
    </div>
  );
};

export default IconBadge;
