import { instanceNoInterceptors } from '../Axios/instance';

export class OnboardingService {
  public static async verifyEmail(formIoSubmissionId: string, email: string, params?: any): Promise<any> {
    const response = await instanceNoInterceptors.get(
      `/user-onboarding/api/v1/submission/${formIoSubmissionId}/email/${email}/verify`,
      {
        params: { ...params },
      },
    );
    return response;
  }

  public static async getSubmission(formIoSubmissionId: string, params?: any): Promise<any> {
    const response = await instanceNoInterceptors.get(`/user-onboarding/api/v1/submission/${formIoSubmissionId}`, {
      params: { ...params },
    });
    return response;
  }

  public static async updateSubmission({
    formIoSubmissionId,
    ...submission
  }: {
    formIoSubmissionId: string;
    coreSubmissionIds?: string[];
    submissions: any[];
    isDraft: boolean;
    editors?: string[];
    formRating?: number;
    formRatingReason?: string;
  }): Promise<any> {
    const response = await instanceNoInterceptors.put(`/user-onboarding/api/v1/submission/${formIoSubmissionId}`, {
      ...submission,
    });
    return response;
  }

  public static async createSubmission(submission: {
    formIoSubmissionId: string;
    isDraft: boolean;
    owner: string;
    submissions: any[];
    formId: string;
    coreSubmissionIds?: string[];
    editors?: string[];
    formRating?: number;
    formRatingReason?: string;
  }): Promise<any> {
    const response = await instanceNoInterceptors.post('/user-onboarding/api/v1/submission', {
      ...submission,
    });
    return response;
  }

  public static async saveSecureSubmission(payload: {
    formId: string;
    formIoSubmissionId: string;
    coreSubmissionIds?: string[];
    formRating?: number;
    formRatingReason?: string;
    submissions: any[];
  }): Promise<any> {
    const response = await instanceNoInterceptors.post('/user-onboarding/api/v1/secure-submission', {
      ...payload,
    });
    return response;
  }
}

export default OnboardingService;
