import clsx from 'clsx';
import { FieldProps } from 'formik';
import React from 'react';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import useStyles from './index.styles';
import 'react-phone-number-input/style.css';
import { useUserManagementContext } from '../../context';

const PhoneInputField = ({
  field, meta, form,
}: FieldProps) => {
  const { mobileField } = useStyles();

  const { countryCode } = useUserManagementContext();

  return (
    <>
      {/* TODO: remove below statement when upgrading react 18 */}
      {/* @ts-expect-error Server Component */}
      <PhoneInput
        flags={flags}
        defaultCountry={countryCode}
        addInternationalOption={false}
        className={clsx(mobileField, {
          error: meta.touched && meta.error,
        })}
        {...field}
        value={field.value}
        onChange={(phoneNumber: string) => (form.setFieldValue(field.name, phoneNumber))}
        international
        countryCallingCodeEditable={false}
      />
    </>
  );
};

export default PhoneInputField;
