import React from 'react';
import useBody from './useBody';
import InvoiceTable from './InvoiceTable/InvoiceTable';
import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';

interface IInvoicePage {
  multiEntity?: boolean;
}

const Body = ({ multiEntity }: IInvoicePage) => {
  const {
    loading,
    invoiceData,
    initialPageLoad,
  } = useBody(multiEntity);

  return (
    (
      <>
        { !loading && (
        <InvoiceTable
          invoiceTableData={invoiceData}
          multiEntity={multiEntity}
          initialPageLoad={initialPageLoad}
        />

        )}
        { loading
        && <BackdropLoader testId="loading" positionFixed />}
      </>
    )
  );
};

export default Body;
