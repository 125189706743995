import React from 'react';
import { CurrencyAccountSearchDto } from '@alpha/currency-accounts-dtos';
import clsx from 'clsx';
import { CurrencyTotals } from '..';
import { CurrencyHeader } from '../../../components/International';
import useStyles from '../CurrencyAccounts.styles';
import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import CurrencyAccountsSearchTable from '../CurrencyAccountsTable/CurrencyAccountsSearchTable';
import { TSearchParams } from '../../../hooks/useSearch';
import { ICaSearch } from './useCurrencyAccountsSearch';

interface IProps {
  currencyCode: string;
  currency: string;
  handleAccountTableRowClick: (account: CurrencyAccountSearchDto) => void;
  handleOpenDrawer: (_drawer: 'IAT' | 'AccountDrawer' | 'PendingTransfers' | false) => void;
  multiEntity?: boolean;
  inputterAccounts: string[];
  caSearch: ICaSearch;
  searchParams: TSearchParams;
  loading: boolean;
}

const CurrencyStats: React.FC<IProps> = (props: IProps) => {
  const {
    currencyCode,
    currency,
    handleAccountTableRowClick,
    handleOpenDrawer,
    multiEntity,
    inputterAccounts,
    caSearch,
    searchParams,
    loading,
  } = props;
  const classes = useStyles();

  return (
    <>
      {loading && <BackdropLoader testId="currencyaccount-loader" />}
      {caSearch.items && (
        <>
          <div className={clsx(classes.stats, 'dd-privacy-allow')} data-testid="currency-accounts-stats-wrapper">
            <CurrencyHeader currencyCode={currencyCode} currency={currency} />
            <CurrencyTotals currencyCode={currencyCode} totals={caSearch.items.items.totals} />
          </div>
          <CurrencyAccountsSearchTable
            currencyAccounts={caSearch?.items?.items.currencyAccounts || []}
            hasNext={caSearch?.items?.hasNext || false}
            hasPrevious={caSearch?.items?.hasPrevious || false}
            handleNextPage={() => caSearch.handleNextPage(searchParams)}
            handlePreviousPage={() => caSearch.handlePreviousPage(searchParams)}
            handleAccountTableRowClick={handleAccountTableRowClick}
            handleOpenDrawer={handleOpenDrawer}
            loading={caSearch.loading}
            multiEntity={multiEntity}
            inputterAccounts={inputterAccounts}
          />
        </>
      )}
    </>
  );
};

export default CurrencyStats;
