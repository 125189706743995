import clsx from 'clsx';
import {
  Backdrop, Modal, Fade, Typography,
} from '@mui/material';
import React from 'react';
import { faMobile } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionButton } from 'components/Buttons';
import iphoneAuthyApp from '../../assets/iphoneAuthyApp.svg';
import IconBadge from '../Atoms/IconBadge';
import useStyles from './index.styles';

interface IProps {
  open: boolean;
  phoneNumber: string;
  handleModalClose: () => void;
  handleSuccessButtonClick: () => void;
}

export const AuthSetupModal: React.FC<IProps> = ({
  open,
  phoneNumber,
  handleModalClose,
  handleSuccessButtonClick,
}: IProps) => {
  const styles = useStyles();
  return (
    <Modal
      open={open}
      className={styles.container}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <Fade in={open}>
        <div className={styles.wrapper}>
          <Typography className={styles.mainTitle}>Authy Setup</Typography>
          <div className={styles.step} style={{ justifyContent: 'space-between' }}>
            <div className={styles.downloadContainer}>
              <div className={styles.title}>Step 1: Download Authy</div>
              <div className={styles.body}>
                <Typography variant="subtitle1">Please download the app to continue</Typography>
                <div className={styles.appImages}>
                  <img src="/static/images/appDownload.png" alt="Google Play and Apple Store icons" />
                </div>
              </div>
            </div>
            <IconBadge
              backgroundColor="#EC1C24"
              element={(
                <img
                  src="/static/images/authyIcon.png"
                  alt="Authy icon"
                />
              )}
              className={styles.iconBadgeContainer}
            />
          </div>
          <div className={styles.step}>
            <div className={styles.body}>
              <div className={styles.title}>Step 2: Register your device</div>
              <Typography variant="subtitle1">
                <FontAwesomeIcon icon={faMobile} className={styles.icon} />
                Once downloaded, please register with your phone number
              </Typography>
              <Typography
                className={styles.phoneBackground}
                variant="subtitle1"
              >
                {phoneNumber}
              </Typography>
            </div>
          </div>
          <div className={styles.title} style={{ marginBottom: '32px' }}>
            Step 3: Use the application to authenticate
          </div>
          <div className={styles.step}>
            <div className={clsx(styles.body, styles.flexRow)}>
              <div className={styles.authyPhoneImage}>
                <img src={iphoneAuthyApp} alt="Iphone displaying authy app" />
              </div>
              <div className={styles.paragraphContainer}>
                <Typography className={styles.topPara} variant="subtitle1">
                  You will then see Alpha automatically appear within the Authy app.
                </Typography>
                <Typography className={styles.topPara} variant="subtitle1">
                  You can now use this token to securely login to your account
                  and authenticate actions within the platform.
                </Typography>
                <Typography className={styles.lowerPara} variant="subtitle1">
                  *Authy will no longer send you a push notifcation,
                  open the Authy app to recieve the code.
                </Typography>
              </div>
            </div>
          </div>
          <ActionButton onClick={handleSuccessButtonClick} size="large">
            I have completed registration
          </ActionButton>
        </div>
      </Fade>
    </Modal>
  );
};

export default AuthSetupModal;
