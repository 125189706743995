import { makeStyles } from 'theme';

export default makeStyles(
  () => ({
    transactionCurrencySummary: {
      display: 'flex',
      alignItems: 'center',
      margin: '35px 0',
      justifyContent: 'space-between',
      '& > div': {
        display: 'flex',
      },
    },
    transactionCurrencySummaryRight: {
      '& > div': {
        '& > p:first-of-type': {
          textAlign: 'right',
        },
        '&:not(:last-of-type)': {
          marginRight: '50px',
        },
      },
    },
    transactionCurrencySummaryLeft: {
      '& > div': {
        '& > p:first-of-type': {
          textAlign: 'left',
        },
      },
    },
  }),
  { name: 'TransactionsTable' },
);
