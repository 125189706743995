import styled from '@mui/styled-engine';

const StyledBreadcrumbs = styled('ol')({
  fontWeight: 600,
  fontSize: 11,
  lineHeight: '15px',
  letterSpacing: '1.65px',
  textTransform: 'uppercase',
  display: 'flex',
  padding: 0,
  margin: 0,
  listStyle: 'none',

  '& .item': {
    color: '#098375',
    marginLeft: 5,
    '&:nth-of-type(1)': {
      marginLeft: 0,
    },
  },
  '& .link': {
    color: '#098375',
    textDecoration: 'none',
  },
  '& .icon': {
    marginLeft: 5,
    opacity: 0.5,
    color: '#212529',
  },
});

export default StyledBreadcrumbs;
