import { ActionButton } from 'components/Buttons';
import { APMainLayout } from 'components/Layout/MainLayout';
import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import Authorization from '../../../hocs/Authorization';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import { UserRole } from '../../../models/user';
import routes from '../../../routes.path';
import DynamoService, { TDynamoTableQueryParams } from '../../../services/DatabaseServices/DynamoService';
import CreatePayment from '../CreatePayment/CreatePayment';
import CompleteTableContainer from './CompleteTableContainer/CompleteTableContainer';
import Classes from './Dashboard.module.scss';
import DraftedTableContainer from './DraftedTableContainer/DraftedTableContainer';
import FundedTable from './FundedTable/FundedTable';
import InProgressTable from './InProgressTable/InProgressTable';
import PendingTable from './PendingTable/PendingTable';
import Tabs from './Tabs/Tabs';

const Dashboard: React.FC = () => {
  const [createPaymentsDrawerOpen, setCreatePaymentsDrawerOpen] = useState(false);
  const [fundedPaymentsExist, setFundedPaymentsExist] = useState<boolean>();

  // only show funded tab if there are payments in FUNDED state for this account
  const initialTableQuery: TDynamoTableQueryParams = {
    url: '/payments/batches/funded',
    pageSize: 9,
  };

  const sb = useAlphaSnackbar();

  const checkIfFundedPaymentsExist = async () => {
    try {
      const data = await DynamoService.getTableDataAsync(initialTableQuery);
      if (data.items.length > 0) {
        setFundedPaymentsExist(true);
      } else {
        setFundedPaymentsExist(false);
      }
    } catch (e) {
      sb.trigger('Could not check for payments that require further action');
      setFundedPaymentsExist(false);
    }
  };

  useEffect(() => {
    checkIfFundedPaymentsExist();
  }, []);

  if (fundedPaymentsExist !== undefined) {
    return (
      <APMainLayout
        title="Payments"
        breadCrumbs={[{ text: 'Home', url: routes.home }]}
        pageTabs={<Tabs fundedPaymentsExist={fundedPaymentsExist} />}
        rightAlignedCta={(
          <Authorization requiredRoles={[[UserRole.PAYMENTS_INPUTTER]]}>
            <ActionButton
              size="large"
              testId="create-payment"
              disabled={false}
              onClick={() => setCreatePaymentsDrawerOpen(true)}
            >
              <span className="dd-privacy-allow">Create payments</span>
            </ActionButton>
          </Authorization>
        )}
      >
        <div className={Classes.container}>
          <Switch>
            <Route
              exact
              path={routes.payments.base}
              render={() => (
                fundedPaymentsExist
                  ? <Redirect to={routes.payments.funded} />
                  : <Redirect to={routes.payments.pending} />
              )}
            />
            <Route path={routes.payments.pending}>
              <PendingTable />
            </Route>
            <Route path={routes.payments.inProgress}>
              <InProgressTable />
            </Route>
            <Route path={routes.payments.complete}>
              <CompleteTableContainer />
            </Route>
            <Route path={routes.payments.funded}>
              <FundedTable />
            </Route>
            <Route path={routes.payments.draft}>
              <DraftedTableContainer />
            </Route>
          </Switch>
          <CreatePayment
            open={createPaymentsDrawerOpen}
            onClose={setCreatePaymentsDrawerOpen}
          />
        </div>
      </APMainLayout>
    );
  }
  return <FullPageLoader testId="payments-dashboard-loader" />;
};

export default Dashboard;
