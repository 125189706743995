import { useDispatch, useSelector } from 'react-redux';
import { VerificationMethod } from '@alpha/payments-dtos';
import AuthyService from '../services/Authy/Authy.service';
import { TStore } from '../store';
import * as authyActions from '../store/authy/actions';
import {
  actions, TAuthyState, AuthyPageStates, TAuthyPaymentsType, TAuthyUserManagementType,
} from '../store/authy/reducer';
import useAlphaSnackbar from './useAlphaSnackbar';

const useAuthy = () => {
  const snackbar = useAlphaSnackbar();
  const reduxState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const dispatch = useDispatch();

  const handlePaymentMfa = async (
    batchId: string,
    verificationMethod?: VerificationMethod,
    accountId?: string,
  ) => {
    const { message, approvalRequestId, method } = await AuthyService.getPaymentsPhoneNumber(
      batchId, verificationMethod, accountId,
    );
    dispatch(actions.updatePhoneNumber(message));
    dispatch(actions.updateApprovalRequestId(approvalRequestId));
    dispatch(actions.updateAuthyPageState(
      method === VerificationMethod.PUSH_NOTIFICATION ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
  };

  const handleApprovePaymentMfa = async (
    batchId: string,
    verificationMethod?: VerificationMethod,
    accountId?: string,
  ) => {
    const {
      message,
      approvalRequestId,
      method,
    } = await AuthyService.getPaymentsApprovalPhoneNumber(
      batchId, verificationMethod, accountId,
    );

    dispatch(actions.updatePhoneNumber(message));
    dispatch(actions.updateApprovalRequestId(approvalRequestId));
    dispatch(actions.updateAuthyPageState(
      method === VerificationMethod.PUSH_NOTIFICATION ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
  };

  const handleBeneficiaryMfa = async (mfaOptionChosen?: string, accountId?: string) => {
    if (mfaOptionChosen === 'OPTIONS') {
      dispatch(actions.updateAuthyPageState(AuthyPageStates.OPTIONS));
    } else {
      const { message, method } = await AuthyService.requestPhoneNumber(mfaOptionChosen, accountId);
      dispatch(actions.updatePhoneNumber(message));
      dispatch(actions.updateAuthyPageState(
        method === 'PUSH' ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
      ));
    }
  };

  const handleUserRoleRequestMfa = async (requestIds: string[], mfaOptionChosen?: string) => {
    if (mfaOptionChosen === 'OPTIONS') {
      dispatch(actions.updateAuthyPageState(AuthyPageStates.OPTIONS));
    } else {
      try {
        const { data } = await AuthyService.requestUserRoleTotpAsync(
          requestIds,
          mfaOptionChosen,
        );
        dispatch(actions.updatePhoneNumber(data?.message));
        if (!mfaOptionChosen) {
          dispatch(actions.updateAuthyPageState(AuthyPageStates.AUTHY));
        } else {
          dispatch(actions.updateAuthyPageState(
            mfaOptionChosen === 'call' ? AuthyPageStates.CALL : AuthyPageStates.SMS,
          ));
        }
      } catch (e) {
        throw Error(e.response?.data?.message || 'Error retrieving your token');
      }
    }
  };

  const triggerPaymentMfa = async (
    paymentAuthy: TAuthyPaymentsType, verificationMethod?: VerificationMethod,
  ): Promise<void> => {
    if (paymentAuthy.type === 'PAYMENTS') {
      await handlePaymentMfa(
        paymentAuthy.batchId, verificationMethod, paymentAuthy.accountId,
      );
    } else {
      await handleApprovePaymentMfa(
        paymentAuthy?.batchId, verificationMethod, paymentAuthy.accountId,
      );
    }
  };

  const triggerMfa = async (mfaOptionChosen?: string,
    verificationMethod?: VerificationMethod): Promise<void> => {
    const { type } = reduxState;
    try {
      if ((type?.type === 'PAYMENTS' || type?.type === 'PAYMENT_APPROVE') && type.batchId) {
        await triggerPaymentMfa(type as TAuthyPaymentsType, verificationMethod);
      } else if (type?.type === 'USER_ROLE_REQUEST') {
        const authyFields = type as TAuthyUserManagementType;
        if (authyFields?.requestId) {
          await handleUserRoleRequestMfa([authyFields?.requestId], mfaOptionChosen);
        } else if (authyFields?.requestIds) {
          await handleUserRoleRequestMfa(authyFields?.requestIds, mfaOptionChosen);
        }
      } else {
        await handleBeneficiaryMfa(mfaOptionChosen, type?.accountId);
      }
    } catch (e) {
      snackbar.trigger(e.response?.data?.error || e.message);
      clearAuthy();
    }
  };

  const clearAuthy = (): void => {
    dispatch(actions.reset());
  };

  const triggerSubmit = (code: number): void => {
    dispatch(authyActions.submit(code));
  };

  return {
    reduxState,
    phoneNumber: reduxState.phoneNumber,
    status: reduxState.status,
    clearAuthy,
    dispatch,
    triggerMfa,
    triggerSubmit,
  };
};

export default useAuthy;
