import React, { useState } from 'react';
import clsx from 'clsx';
import {
  faInfoCircle, faExclamationTriangle, faCheckCircle, IconDefinition, faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStyles from './Alert.styles';

interface IProps {
  text: JSX.Element | string;
  variant: 'error' | 'warning' | 'success' | 'info' | 'incomplete';
  cancel?: boolean;
  style?: any;
}

const Alert = (props: IProps) => {
  const {
    text, variant, cancel, style,
  } = props;
  const classes = useStyles();
  const [toggle, setToggle] = useState<boolean>(false);
  const iconMapping: Record<string, IconDefinition> = {
    error: faInfoCircle,
    warning: faExclamationTriangle,
    success: faCheckCircle,
    info: faInfoCircle,
    incomplete: faExclamationTriangle,
  };

  return (
    <div className={clsx(`${toggle ? classes.hide : classes.root} ${variant}`, 'dd-privacy-allow')} style={style}>
      <FontAwesomeIcon className="icon" icon={iconMapping[variant] || faInfoCircle} />
      <span className="text" data-testid="banner">{text}</span>
      <FontAwesomeIcon
        style={{ alignSelf: 'center' }}
        onClick={() => {
          setToggle(!toggle);
          localStorage.setItem('overdue-invoices-banner-hidden', 'true');
        }}
        size="lg"
        className={`${(cancel) ? 'cancel' : classes.hide}`}
        icon={faTimes}
      />
    </div>
  );
};

export default Alert;
