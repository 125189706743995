import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    bookFooter: {
      paddingTop: 40,
      backgroundColor: 'white',
      padding: '0px 6.25%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > button': {
        width: '27%',
        fontSize: '12px',
        fontWeight: typography.fontWeightSemiBold,
        '&:first-of-type': {
          marginRight: '8px',
        },
      },
    },
  }),
  { name: 'BookFxFooter' },
);

export default useStyles;
