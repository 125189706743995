import styled from '@mui/styled-engine';

const StyledFxPaymentsLineByLine = styled('div')({
  position: 'relative',
  minHeight: '350px',
  '& .emptyTableWrapper': {
    height: '341px',
    position: 'relative',
    marginBottom: '41px',
  },
});

export default StyledFxPaymentsLineByLine;
