import { ActionButton } from 'components/Buttons';
import React from 'react';

interface IApproveButtonProps {
  initiateApproveBeneficiary: (own: boolean, __batchId?: string) => void;
  own: boolean;
}
const ApproveButton: React.FC<IApproveButtonProps> = (props: IApproveButtonProps) => {
  const { initiateApproveBeneficiary, own } = props;
  return (
    <ActionButton
      testId="approve-btn"
      size="large"
      onClick={() => initiateApproveBeneficiary(own)}
    >
      Approve Beneficiary
    </ActionButton>
  );
};

export default ApproveButton;
