import React, { FC, forwardRef, memo } from 'react';
import { IBaseButtonProps } from '../BaseButton';

import { SecondaryButtonStyled } from './SecondaryButton.styles';

const SecondaryButton: FC<IBaseButtonProps> = memo(
  forwardRef((props, ref) => (
    <SecondaryButtonStyled
      ref={ref}
      {...props}
      variant="contained"
    />
  )),
);

export default SecondaryButton;
