import { Drawer, DrawerProps } from '@mui/material';
import styled from '@mui/styled-engine';

export interface ISimplifiedDrawerStyles extends DrawerProps {
  noPadding?: boolean;
}

const StyledDrawer = styled(Drawer)({
  '& > .MuiDrawer-paper': {
    width: '35%',
    minWidth: 600,
  },
});

const StyledHeader = styled('div')({
  borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
  padding: '116px 48px 17.5px 48px',
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  position: 'relative',
});

const StyledBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noPadding',
})<ISimplifiedDrawerStyles>(({ noPadding }) => ({
  padding: noPadding ? '0' : '45px 0',
  height: '100%',
  '& > div > *:not(.divider)': {
    padding: '0 48px',
  },
  '& > div > .noPadding': {
    padding: '0',
  },
}));

const StyledButton = styled('button')({
  display: 'inline-block',
  border: 0,
  backgroundColor: 'transparent',
  margin: '88px 24px 0',
  position: 'absolute',
  right: 0,
  top: 0,
  outline: 0,
  '&:hover': {
    cursor: 'pointer',
  },
  '& > svg': {
    color: '#C3C3C3',
    width: 22,
    height: 22,
  },
});

export {
  StyledHeader, StyledButton, StyledBody, StyledDrawer,
};
