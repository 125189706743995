const approvalConfig = () => (
  {
    appliesToFX: true,
    appliesToIAT: true,
    limitAmountCurrencyCode: 'EUR',
    requirements: [{
      approvalsRequired: [{
        level: 'A',
        requiredNumber: 2,
      }],
      limitAmount: 9999.99,
    },
    {
      approvalsRequired: [{
        level: 'A',
        requiredNumber: 1,
      }, {
        level: 'B',
        requiredNumber: 1,
      }],
      limitAmount: 99999.99,
    }],
  }
);

export default approvalConfig;
