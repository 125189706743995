import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  extraInformationWrapper: {
    marginTop: '16px',
    '& > div': {
      marginBottom: '24px',
    },
  },
}), { name: 'CreateBeneficiaryDrawer' });

export default useStyles;
