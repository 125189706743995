import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    headers: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      position: 'relative',
      '& > div p:last-child': {
        fontWeight: typography.fontWeightSemiBold,
      },
      '& > div:first-child': {
        paddingRight: '50px',
      },
      '& > div:last-child': {
        '& > p:first-child': {
          color: '#BA5252',
        },
      },
      '&:after': {
        content: '""',
        margin: '30px 0px 15px 0px',
        width: '100%',
        borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      },
    },
  }), { name: 'ErrorPaymentsHeaders' },
);

export default useStyles;
