import { Box, InputLabel } from '@mui/material';
import React, { memo, useEffect } from 'react';
import Dropdown from '../../../../../components/InterAccountTransfer/Dropdown/Dropdown';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import { TCurrencyAccounts } from '../../../../../models/currencyAccounts';
import { TIATAccountTypes } from '../../../../../models/interAccountTransfer';
import InterAccountTransferService from '../../../../../services/InterAccountTransfer/interAccountTransfer.service';
import useStyles from '../ActionAccounts.styles';
import useInterAccountTransferFormContext from '../../../useInterAccountTransferFormContext';
import { errorMessages } from '../../../FormValues';

interface IProps {
  type: TIATAccountTypes;
  currencyAccounts: TCurrencyAccounts;
  disabled: boolean;
}

const DropdownContainer: React.FC<IProps> = (props: IProps) => {
  const { currencyAccounts, type, disabled } = props;
  const styles = useStyles();
  const snackbar = useAlphaSnackbar();
  const { form } = useInterAccountTransferFormContext();

  useEffect(() => {
    if (
      form.values.debitingAccount.amount
      && form.values.debitingAccount.amount
      > +form.values.debitingAccount.currencyAccount!.availableBalance
    ) {
      form.setFieldError(
        'debitingAccount.amount',
        errorMessages.INSUFFICIENT_FUNDS,
      );
      return;
    }

    if (form.values.debitingAccount.amount && form.values.debitingAccount?.amount < 1) {
      form.setFieldError(
        'debitingAccount.amount',
        errorMessages.MINAMOUNT,
      );
      return;
    }

    if (form.values.creditingAccount.amount && form.values.creditingAccount.amount < 1) {
      form.setFieldError(
        'creditingAccount.amount',
        errorMessages.MINAMOUNT,
      );
    }
  }, [form.values.debitingAccount.amount,
    form.values.creditingAccount.amount,
    form.values.debitingAccount.currencyAccount,
    form.values.creditingAccount.currencyAccount,
    form.errors]);

  const { currencyAccount } = type === 'DEBITING'
    ? form.values.debitingAccount
    : form.values.creditingAccount;
  let filteredCurrencyAccounts: TCurrencyAccounts = [];
  let dropdownLabel: string;
  let dropdownSelectName: string;

  if (type === 'DEBITING') {
    dropdownLabel = 'Debiting account';
    dropdownSelectName = 'debitingAccount.currencyAccount';
    filteredCurrencyAccounts = InterAccountTransferService.removeCurrencyAccountById(
      currencyAccounts,
      form.values.creditingAccount.currencyAccount?.id,
    );
  } else {
    dropdownLabel = 'Crediting account';
    dropdownSelectName = 'creditingAccount.currencyAccount';
    filteredCurrencyAccounts = InterAccountTransferService.removeCurrencyAccountById(
      currencyAccounts,
      form.values.debitingAccount.currencyAccount?.id,
    );
  }

  const handleDropdownValueChange = (
    event: any,
    _currencyAccounts: TCurrencyAccounts,
  ) => {
    try {
      const selectedCurrencyAccount = _currencyAccounts.find(
        (ca) => ca.id === event.target.value,
      );
      form.setFieldValue(event.target.name, selectedCurrencyAccount);
      form.setFieldValue(
        'fixedSide', selectedCurrencyAccount?.currencyCode!,
      );
    } catch (e) {
      snackbar.trigger('There was an error selecting your currency account');
    }
  };
  return (
    <Box className={styles.selectAccount}>
      <InputLabel className="dd-privacy-allow" style={{ fontWeight: 'bold' }}>{dropdownLabel}</InputLabel>
      <Dropdown
        disabled={disabled}
        currencyAccounts={filteredCurrencyAccounts}
        helperText={
          form.touched.creditingAccount?.currencyAccount
          && type === 'CREDITING'
          && form.errors.creditingAccount?.currencyAccount
        }
        selectValue={currencyAccount?.id}
        selectName={dropdownSelectName}
        handleChange={(event: any) => handleDropdownValueChange(event, currencyAccounts)}
        handleBlur={form.handleBlur}
        testId={type === 'DEBITING' ? 'debitingDropdown' : 'creditingDropdown'}
      />
    </Box>
  );
};

export default memo(DropdownContainer);
