import UserManagementService from 'services/UserManagement/userManagement.service';
import { datadogRum } from '@datadog/browser-rum';
import { TUser } from 'models/user';
import AuthService from 'services/Auth/auth.service';

export const useActivateUser = () => {
  const activateUser = async (userInfo?: TUser) => {
    try {
      if (userInfo?.Username) {
        const { user } = await UserManagementService.getUser({
          id: userInfo?.Username,
          page: '1',
          items: '1',
          approvalConfig: 'false',
          contactDetails: 'false',
        });

        if (user.status === 'INACTIVE') {
          await checkActivateUser();
        }
      }
    } catch (e) {
      datadogRum.addError(e, {
        source: 'useActivateUser.ts',
        message: 'Error when trying to get user status to set to active',
        tags: ['getUserDetailsToActive'],
      });
    }
  };

  const checkActivateUser = async () => {
    await AuthService.setUserActive();
  };

  return { activateUser, checkActivateUser };
};
