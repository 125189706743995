import { IATDraftResponse } from '@alpha/iat-dtos';
import { Typography } from '@mui/material';
import React from 'react';
import useStyles from './styles';
import Transfer from './Transfer';
import EmptyTable from '../../../../components/Table/EmptyTable/EmptyTable';
import clipboardCross from '../../../../assets/clipboardCross.svg';
import DrawerBackdropLoader from '../../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';

interface IProps {
    pendingTransfers: IATDraftResponse[];
    loading: boolean;
    handleOpenIATDrawer: (iatDraftResponse: IATDraftResponse) => void;
    handleOpenIATRejectModal: (iatDraftResponse: IATDraftResponse) => void;
    handleDeleteIatClick: (iatId: string) => Promise<void>;
    getAllPendingTransfers?: () => Promise<void>;
    multiEntity?: boolean;
    inputterAccounts: string[],
}

const Transfers: React.FC<IProps> = ({
  pendingTransfers,
  loading,
  handleOpenIATDrawer,
  handleOpenIATRejectModal,
  handleDeleteIatClick,
  multiEntity,
  inputterAccounts,
  getAllPendingTransfers,
}: IProps) => {
  const styles = useStyles();

  if (loading) {
    return <DrawerBackdropLoader display={loading} text="Retrieving pending transactions" />;
  }

  if (pendingTransfers.length) {
    return (
      <div className={styles.container}>
        <div className={styles.pending}>
          <Typography className="dd-privacy-allow" variant="subtitle1">
            Pending (
            { pendingTransfers.length }
            )
          </Typography>
        </div>
        {pendingTransfers.map((iatDraftResponse) => (
          <Transfer
            multiEntity={multiEntity}
            iatDraftResponse={iatDraftResponse}
            handleOpenIATDrawer={handleOpenIATDrawer}
            handleOpenIATRejectModal={handleOpenIATRejectModal}
            handleDeleteIatClick={handleDeleteIatClick}
            inputterAccounts={inputterAccounts}
            getAllPendingTransfers={getAllPendingTransfers}
          />
        ))}
      </div>
    );
  }

  return (
    <EmptyTable title="No Pending Transfers" icon={clipboardCross} />
  );
};

export default Transfers;
