import { Box, Typography } from '@mui/material';
import React from 'react';
import { TCurrencyAccount } from '../../../../models/currencyAccounts';

import { useStyles } from './RenderValue.styles';
import FlagImage from 'components/Flag/FlagImage';

interface IProps {
  selectedCurrencyAccount: TCurrencyAccount;
}
const RenderValue: React.FC<IProps> = ({ selectedCurrencyAccount }: IProps) => {
  const styles = useStyles();

  const { friendlyName } = selectedCurrencyAccount;
  const currencyCode = selectedCurrencyAccount.currencyCode!;

  return (
    <Box className={styles.root}>
      <Box className={styles.imageHolder}>
        <FlagImage currencyCode={currencyCode} />
      </Box>
      <Typography variant="subtitle1" className={styles.friendlyName}>
        {friendlyName}
      </Typography>
    </Box>
  );
};

export default RenderValue;
