import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

export function useForm<T extends object>(
  initialValues: T,
  validation: object,
  submitFormCallback: Function,
  enableReinitialize: boolean = false,
  validateOnMount: boolean = false,
) {
  const formik: FormikProps<T> = useFormik({
    initialValues,
    validationSchema: yup.object(validation),
    enableReinitialize,
    validateOnMount,
    onSubmit: async (values: T) => {
      await submitFormCallback(values);
    },
  });

  return formik;
}

export default useForm;
