import React, { useCallback } from 'react';
import { useSnackbar as originUseSnackbar } from 'notistack';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { Snackbar } from './Snackbar';
import { ISnackbarOptions } from './Snackbar.interfaces';

const snackbarDefaultTitle = {
  default: '',
  info: 'Info',
  warning: 'Warning',
  success: '',
  error: 'Error',
};

const snackbarDefaultIcon = {
  default: faInfo,
  info: faInfo,
  warning: faInfo,
  success: faCheck,
  error: faInfo,
};

export const useSnackbar = () => {
  const { enqueueSnackbar: originalEnqueueSnackbar, closeSnackbar } = originUseSnackbar();
  const content = useCallback(
    ({ variant = 'info', icon, title }: ISnackbarOptions) => (key, message) => (
      <div>
        <Snackbar
          icon={icon || snackbarDefaultIcon[variant]}
          keyId={key}
          message={message}
          title={title || snackbarDefaultTitle[variant]}
          type={variant}
          closeSnackbar={closeSnackbar}
        />
      </div>
    ),
    [closeSnackbar],
  );
  const enqueueSnackbar = useCallback(
    (message: string, options: ISnackbarOptions = {}) => {
      originalEnqueueSnackbar(message, {
        autoHideDuration: 2000,
        content: content(options),
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        ...options,
      });
    },
    [originalEnqueueSnackbar, content],
  );

  return {
    enqueueSnackbar,
    closeSnackbar,
  };
};
