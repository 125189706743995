enum colours {
    '#1D1D1D',
    '#098375',
}

const getGroups = (total: number, includeEntityIds: boolean) => {
  const groups = new Array(total);
  for (let i = 0; i < total; i += 1) {
    const key = i % Object.keys(colours).length;
    groups.push({
      id: `12345678-1234-1234-1234-GGGGGGGG${(i).toString().padStart(4, '0')}`,
      name: `Group ${i + 1}`,
      colour: colours[key],
      entityIds: includeEntityIds && Array(200).map((num) => `12345678-1234-1234-1234-EEEEEEEE${(num.index).toString().padStart(4, '0')}`),
    });
  }
  return groups;
};

export default getGroups;
