import { PaymentRouteEnum } from './models/payments';

const routes = {
  payments: {
    multientity: {
      base: '/app/payments-multientity',
      pending: '/app/payments-multientity/pending',
      inProgress: '/app/payments-multientity/in-progress',
      complete: '/app/payments-multientity/complete',
      scheduled: '/app/payments-multientity/scheduled',
      draft: '/app/payments-multientity/draft',
      funded: '/app/payments-multientity/funded',
      actionNeeded: '/app/payments-multientity/action-needed',
      batchSummary: '/app/payments-multientity/batch-summary/:accountId/:batchId/:state',
      batchSummaryActionNeeded: '/app/payments-multientity/batch-summary/:accountId/:batchId/:state/:actionNeeded',
      funding: '/app/payments-multientity/:accountId/:batchId/funding',
      manual: {
        base: '/app/payments-multientity/manual',
        single: '/app/payments-multientity/manual/single',
        batch: '/app/payments-multientity/manual/batch',
      },
      batchSummaryRouteFunc: (accountId: string, batchId: string, state: PaymentRouteEnum) => {
        let url = `/app/payments-multientity/batch-summary/${accountId}/${batchId}`;

        switch (state) {
          case PaymentRouteEnum.COMPLETE:
            url = `${url}/complete`;
            break;
          case PaymentRouteEnum.SCHEDULED:
            url = `${url}/scheduled`;
            break;
          case PaymentRouteEnum.INPROGRESS:
            url = `${url}/inprogress`;
            break;
          case PaymentRouteEnum.PENDING:
            url = `${url}/pending`;
            break;
          case PaymentRouteEnum.FUNDING:
            url = `/app/payments-multientity/${accountId}/${batchId}/funding`;
            break;
          case PaymentRouteEnum.FUNDED:
            url = `${url}/funded`;
            break;
          case PaymentRouteEnum.ACTIONNEEDED:
            url = `${url}/inprogress/actionneeded`;
            break;
          case PaymentRouteEnum.DRAFTED:
          default:
            url = `${url}/drafted`;
            break;
        }
        return url;
      },
    },
    base: '/app/payments',
    pending: '/app/payments/pending',
    inProgress: '/app/payments/in-progress',
    complete: '/app/payments/complete',
    draft: '/app/payments/draft',
    funded: '/app/payments/funded',
    manual: {
      base: '/app/payments/manual',
      single: '/app/payments/manual/single',
      batch: '/app/payments/manual/batch',
    },
    batchSummary: '/app/payments/batch-summary/:batchId/:state',
    funding: '/app/payments/:batchId/funding',
    batchSummaryRouteFunc: (batchId: string, state: PaymentRouteEnum) => {
      let url = `/app/payments/batch-summary/${batchId}`;

      switch (state) {
        case PaymentRouteEnum.COMPLETE:
          url = `${url}/complete`;
          break;
        case PaymentRouteEnum.INPROGRESS:
          url = `${url}/inprogress`;
          break;
        case PaymentRouteEnum.PENDING:
          url = `${url}/pending`;
          break;
        case PaymentRouteEnum.FUNDING:
          url = `/app/payments/${batchId}/funding`;
          break;
        case PaymentRouteEnum.FUNDED:
          url = `${url}/funded`;
          break;
        case PaymentRouteEnum.DRAFTED:
        default:
          url = `${url}/drafted`;
          break;
      }
      return url;
    },
  },
  entityGroups: {
    base: '/app/entities',
    allUsers: '/app/entities/all-users',
    approvedEntites: '/app/entities/approved-entities',
    pendingEntites: '/app/entities/pending-entities',
  },
  beneficiaries: '/app/beneficiaries',
  createBeneficiary: '/app/beneficiaries/create-beneficiary',
  currencyAccounts: '/app/currency-accounts',
  noTransactions: '/app/transactions',
  transactions: '/app/transactions/:currencyAccountId',
  transactionsMultiEntity: '/app/transactions-multi-entity',
  transactionsMultiEntityWithCa: '/app/transactions-multi-entity/:accountId/:currencyAccountId',
  profile: '/app/profile',
  onboarding: '/app/onboarding',
  onboardingForm: {
    redirect: '/app/onboarding/form',
    base: '/app/onboarding/form/:formId/new',
    draft: '/app/onboarding/form/:id',
    auth: '/app/onboarding/auth',
    submissions: '/app/onboarding/submissions',
  },
  invoices: '/app/invoices',
  home: '/app',
  base: '/',
  auth: {
    base: '/auth',
    onboarding: {
      base: '/auth/onboarding',
      login: '/auth/onboarding/verify-email',
      newPassword: '/auth/onboarding/new-password',
      setupMfa: '/auth/onboarding/setup-mfa',
      verifyMfa: '/auth/onboarding/verify-mfa',
      welcome: '/auth/onboarding/welcome',
      mfaOptions: '/auth/onboarding/mfa-options',
    },
    login: '/auth/login',
    welcome: '/auth/welcome',
    totp: '/auth/totp',
    newPassword: '/auth/new-password',
    mfaOptions: '/auth/mfa-options',
    custom: '/auth/custom',
    passwordReset: {
      base: '/auth/password-reset',
      request: '/auth/password-reset/request',
      verifyMfa: '/auth/password-reset/verify-mfa',
      success: '/auth/password-reset/success',
    },
  },
  dashboard: {
    base: '/app/dashboard',
    outstanding: '/app/dashboard/outstanding',
  },
  forcedSignOut: '/logged-out',
  notFoundPage: '/app/404',
  userManagement: {
    base: '/app/user-management',
    allUsers: '/app/user-management/users',
    user: '/app/user-management/users/:userId',
    allEntities: '/app/user-management/entities',
    entity: '/app/user-management/entities/:entityId',
    userRoute: (userId: string) => `/app/user-management/users/${userId}`,
  },
};

export default routes;
