import React, {
  FC, memo, useCallback, useState,
} from 'react';
import { IPageProps } from './Page.interfaces';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { Footer } from '../Footer';
import useStyles from './Page.styles';
import useEntityManagementPermissions from '../../../hooks/useEntityManagementPermissions';

export const Page: FC<IPageProps> = memo((props) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEntityManagementPermissions();

  return (
    <>
      <Header isOpen={isOpen} toggle={toggle} />
      <Sidebar toggle={toggle} isOpen={isOpen} close={close} />
      <main className={classes.main}>{props.children}</main>
      <Footer />
    </>
  );
});

export default Page;
