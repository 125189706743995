import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DragAndDrop from 'domain/PaymentsMultiEntity/CreatePayment/ChoosePayment/DragAndDrop/DragAndDrop';
import useStyles from './styles';

type IProps = {
  localFilesToUpload: File[];
  setLocalFilesToUpload: (files: File[]) => void;
  maxFileSize: number;
}

const DragAndDropArea:FC<IProps> = ({
  localFilesToUpload, setLocalFilesToUpload, maxFileSize, 
}) => {
  const [isFileError, setisFileError] = useState<boolean>(false);
  const [errorString, setFileErrorString] = useState<string>('');
  const acceptedExtensions = ['.pdf', '.jpg', '.jpeg', '.png', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];
  const styles = useStyles();

  const isValidFile = (file: File) => {
    if (!acceptedExtensions.some((s) => file.name.toLocaleLowerCase().endsWith(s.toLocaleLowerCase()))) {
      setFileErrorString(`Unsupported file types`);
      setisFileError(true);
      return false;
    }
    
    if (file.size >= maxFileSize) {
      setFileErrorString('Some files exceeded the file size limit');
      setisFileError(true);
      return false;
    }
    return true;
  };

  const handleDropFiles = useCallback((files: FileList) => {
    setisFileError(false);
    const newFiles = Array.from(files)
    .filter((file) => isValidFile(file));

    setLocalFilesToUpload([
      ...localFilesToUpload,
      ...newFiles,
    ]);
  }, [localFilesToUpload, setLocalFilesToUpload]);

  const onDropFiles = useCallback((files: File | FileList) => (
    handleDropFiles(files as FileList)
  ), [handleDropFiles]);

  const handleFileInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target;

    if (input.files?.length) {
      handleDropFiles(input.files);
      input.value = '';
    }
  }, [handleDropFiles]);

  return (
    <DragAndDrop onDropHandler={onDropFiles} data-testid="supportingDocumentsDropArea" multiple>
      <div className={styles.dragNDropArea}>
        <Typography className={styles.dragNDropText}>
          <FontAwesomeIcon
            size="lg"
            icon={faCloudUpload}
          />
          &nbsp;
          Drag & Drop or&nbsp;
          <button type="button" className={styles.link}>choose</button>
          &nbsp;files to upload
        </Typography>
        <Typography className={styles.sizeNote}>
          Supported file types are:&nbsp;
          {acceptedExtensions.join(', ')}
          .
          <br />
          Maximum File Size &nbsp;
          {(maxFileSize / 1024 / 1024).toFixed(0)} MB &nbsp;
          <br />
          {isFileError && <div className={styles.limitWarning}>
             <p>
              {errorString}
            </p>
          </div>}
        </Typography>
        <input type="file" accept={acceptedExtensions.join(', ')} className={styles.uploadInput} multiple onChange={handleFileInputChange} />
      </div>
    </DragAndDrop>
  );
};

export default DragAndDropArea;
