import { Box, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActivateUser } from 'hooks/useActivateUser';
import useAuth from 'hooks/useAuth';
import routes from '../../../routes.path';
import history from '../../../services/history/browserHistory';
import { TStore } from '../../../store';
import useStyles from '../Auth.styles';

interface IProps {
  name: string,
}

const Welcome: FC<IProps> = (props: IProps) => {
  const { name } = props;
  const classes = useStyles();
  const { userInfo } = useAuth();
  const { activateUser } = useActivateUser();
  const redirectPath = useSelector<TStore, string | ''>((store) => store.initialPath);

  const redirectUserToDashboard = () => setInterval(() => {
    history.push(redirectPath || routes.home);
  }, 4000);

  useEffect(() => {
    activateUser(userInfo);
    const interval = redirectUserToDashboard();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box className={classes.headerContainer}>
      <Typography className={classes.header}>
        {`Welcome back, ${name}`}
      </Typography>
      <Typography className={classes.subHeader}>
        Thank you for verifying. Logging you in now.
      </Typography>
    </Box>
  );
};

export default Welcome;
