import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import React from 'react';
import DrawerBackdropLoader from '../../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import useStyles from './ViewEntityGroupsDrawer.styles';
import DisplayDrawer from './DrawerBody';
import DrawerHeader from './DrawerHeader/DrawerHeader';
import useEntityGroupDrawer from '../useEntityGroupDrawer';

interface IProps {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  setCanEditOrDeleteGroup: React.Dispatch<React.SetStateAction<boolean>>;
  canEditOrDeleteGroup: boolean;
}

const ViewEntityGroupsDrawer: React.FC<IProps> = (props: IProps) => {
  const {
    open, onClose, setCanEditOrDeleteGroup, canEditOrDeleteGroup,
  } = props;

  const {
    entityGroups,
    loading,
    toggleCreateGroupDrawer,
    entityAccounts,
  } = useEntityGroupDrawer();

  const classes = useStyles();

  const handleClose = () => {
    onClose(false);
  };

  const displayButton = entityAccounts.length > 0;

  return (
    <BaseDrawer.Drawer
      className={classes.drawerWrapper}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {
        !loading && entityGroups
          ? (
            <>
              <DrawerHeader
                displayButton={displayButton}
                numberOfGroups={entityGroups?.length}
                displayHeaderTitle="Group Manager"
                toggleCreateGroupDrawer={toggleCreateGroupDrawer}
              />

              <DisplayDrawer
                entityGroupsForUser={entityGroups}
                canEditOrDeleteGroup={canEditOrDeleteGroup}
                setCanEditOrDeleteGroup={setCanEditOrDeleteGroup}
              />
            </>
          )
          : (
            <DrawerBackdropLoader width="610px" display={loading || false} text="Loading groups..." />
          )

      }

    </BaseDrawer.Drawer>
  );
};

export default ViewEntityGroupsDrawer;
