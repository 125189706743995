import { Box } from '@mui/material';
import React from 'react';
import { ActionButton, OutlinedButton } from 'components/Buttons';
import { useStyles } from './index.styles';

interface IFooterProps {
    handleClickClear : ()=> void;
    submitSelectedOptions : ()=> void;
  }

const Footer: React.FC<IFooterProps> = (props: IFooterProps) => {
  const {
    handleClickClear,
    submitSelectedOptions,
  } = props;

  const classes = useStyles();
  const onClickApply = () => {
    submitSelectedOptions();
  };

  return (
    <Box className={classes.stickyFooter}>
      <OutlinedButton
        onClick={handleClickClear}
        rounded
        testId="footer-clear-button"
        size="small"
      >
        Clear
      </OutlinedButton>
      <ActionButton
        onClick={onClickApply}
        rounded
        testId="footer-apply-button"
        size="small"
      >
        Apply
      </ActionButton>
    </Box>
  );
};

export default Footer;
