import styled from '@mui/styled-engine';

export const DualFlagStyled = styled('div')(() => ({
  display: 'inline-block',
  '& .flagsWrapper': {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
