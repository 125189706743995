import { Box } from '@mui/material';
import { Loader } from 'components/Loader';
import { Typography } from '@mui/material';
import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import uploadIcon from '../../../../assets/upload_icon_large.svg';
import Badge from '../../../../components/Badge/Badge';
import LinearProgress from '../../../../components/LinearProgress/LinearProgress';
import useStyles from './UploadingPayment.styles';
import './UploadingPayment.scss';

interface IProps {
  batchStatus: string;
  totalRecords: number;
  totalValidatedPayments: number;
  totalInvalidPayments: number;
  totalRejectedPayments: number;
}

const UploadingPayment: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();
  const {
    batchStatus, totalRecords, totalValidatedPayments, totalInvalidPayments, totalRejectedPayments,
  } = props;
  const getLoadingMessage = (status: string) => {
    switch (true) {
      case status === 'REQUESTED':
        return 'Uploading your file';
      case status === 'UPLOADED':
        return 'Validating your file';
      default:
        return 'Loading';
    }
  };

  const totalProcessedPayments = totalValidatedPayments + totalInvalidPayments + totalRejectedPayments;
  const percentageOfProcessedPayments = (totalProcessedPayments / totalRecords) * 100;

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>
          <img src={uploadIcon} alt="upload-icon-large" />
        </div>
        <div className={styles.content}>
          {
            batchStatus === 'REQUESTED'
              ? (
                <Box height="206px">
                  <div className="typography">
                    <Typography variant="h5" className="loading" mt={2}>
                      {`${getLoadingMessage(batchStatus)}`}
                    </Typography>
                  </div>
                  <Loader testId="loader" className="loader" />
                </Box>
              )
              : (
                <>
                  <div className="typography">
                    <Typography variant="h5" className="loading" mt={2}>
                      {`${getLoadingMessage(batchStatus)}`}
                    </Typography>
                    <Typography variant="subtitle1" className="totalRecords">
                      Total payments:
                      {' '}
                      <b>{totalRecords}</b>
                    </Typography>
                  </div>
                  <LinearProgress variant="determinate" value={percentageOfProcessedPayments > 100 ? 100 : percentageOfProcessedPayments} />
                  <div className="badges">
                    <div className="wrapper">
                      <Badge image={<FontAwesomeIcon icon={faCheckCircle} />} text={totalValidatedPayments} />
                      <Badge image={<FontAwesomeIcon icon={faExclamationCircle} />} text={totalInvalidPayments} />
                      <Badge image={<FontAwesomeIcon icon={faTimesCircle} />} text={totalRejectedPayments} />
                    </div>
                  </div>
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default memo(UploadingPayment);
