import { User } from '@alpha/profile-dtos';
import instance, { instanceAccountId } from '../Axios/instance';

export class ProfileService {
  public static async getUserProfile(accountId?: string): Promise<User> {
    const response = accountId
      ? await instanceAccountId(accountId).get('/profiles/user-profile')
      : await instance.get('/profiles/user-profile');
    return response.data;
  }
}

export default ProfileService;
