import { Typography } from '@mui/material';
import React from 'react';
import { SameTradeResponse } from '@alpha/fx-dtos';
import { ActionButton } from 'components/Buttons';
import WarningIcon from '../../../assets/warning.svg';
import PaymentDetailsTable from './PaymentDetailsTable';

interface IProps {
  onClick: () => void,
  paymentDetails: SameTradeResponse[]
}
const SameTradeWarning: React.FC<IProps> = (props: IProps) => {
  const { onClick, paymentDetails } = props;
  return (
    <div
      style={{
        textAlign: 'center', color: '#b95656', width: '450px', fontWeight: 400,
      }}
      data-testid="same-trade-warning"
    >
      <img src={WarningIcon} alt="warning" style={{ marginBottom: '24px', width: '48px' }} />
      <Typography variant="h1" style={{ color: '#b95656' }}>Transaction Error</Typography>
      <Typography
        variant="h3"
        style={{
          fontWeight: 100, fontSize: '19px', lineHeight: 1.5, margin: '32px 0 56px 0',
        }}
      >
        Potential duplicate trades with similar details have recently been submitted.
        <div>
          <PaymentDetailsTable paymentDetails={paymentDetails} />
        </div>
        If you are confident that these trades aren&apos;t duplicates, you may continue.
        If not, please contact us.
      </Typography>
      <ActionButton testId="acknowledge-similar-trades" size="large" onClick={() => onClick()} fullWidth>acknowledge</ActionButton>
    </div>
  );
};

export default SameTradeWarning;
