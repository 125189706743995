import { ActionButton } from 'components/Buttons';
import { Box, Typography } from '@mui/material';
import { Input } from 'components/Inputs/Input';
import { FormikProps } from 'formik';
import React from 'react';
import useStyles from './ChangePasswordForm.styles';

interface IChangePasswordForm {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface IProps {
  formik: FormikProps<IChangePasswordForm>;
  loading: boolean;
}

const ChangePasswordForm: React.FC<IProps> = (props: IProps) => {
  const { formik, loading } = props;
  const classes = useStyles();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={classes.wrapper}>
        <Typography className={classes.header}>
          Change password
        </Typography>
        <Typography className={classes.marginBottom}>
          Current password
        </Typography>
        <Input
          className={classes.marginBottomLarge}
          testId="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          variant="filled"
          name="password"
          type="password"
          placeholder="Current password"
        />
        <Typography className={classes.marginBottom}>
          New password
        </Typography>
        <Input
          className={classes.marginBottomLarge}
          testId="new-password"
          variant="filled"
          placeholder="New password"
          name="newPassword"
          id="newPassword"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.newPassword}
          error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
        />
        <Typography className={classes.marginBottom}>
          Confirm new password
        </Typography>
        <Input
          className={classes.marginBottomLarge}
          testId="confirm-password"
          variant="filled"
          placeholder="New password"
          name="confirmNewPassword"
          id="confirmNewPassword"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.confirmNewPassword}
          error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
          helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
        />
        <Box width="147px">
          <ActionButton
            disabled={
              !formik.values.confirmNewPassword
              || !formik.values.newPassword
              || !formik.values.password
              || loading
            }
            loading={loading}
            size="large"
            type="submit"
            testId="submit-new-password"
          >
            save changes
          </ActionButton>
        </Box>
      </Box>
    </form>
  );
};

export default ChangePasswordForm;
