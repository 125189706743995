import React from 'react';
import { Box } from '@mui/material';
import { Flag } from 'components/Flag';
import useStyles from './DrawerHeaderFlag.styles';

interface IProps {
    countryCode: string
}

const DrawerHeaderFlag:React.FC<IProps> = ({ countryCode }: IProps) => {
  const styles = useStyles();
  return (
    <>
      <Box className={styles.imageHolder}>
        <Flag code={countryCode} size="sm" />
      </Box>
      {countryCode}
    </>
  );
};

export default DrawerHeaderFlag;
