import React, { memo } from 'react';
import { CircularProgress } from '@mui/material';
import { ActionButton, GhostButton } from 'components/Buttons';
import Authorization from '../../../../hocs/Authorization';
import { UserRole } from '../../../../models/user';
import { useStyles } from './BookFxFooter.styles';
import { testIds } from '../Funding';

interface IProps {
  disableBookFxBtn: boolean;
  handleLiveRateReset: () => void;
  handleFxBooking: () => void;
}

const BookFxFooter: React.FC<IProps> = (props: IProps) => {
  const {
    handleLiveRateReset, handleFxBooking, disableBookFxBtn,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.bookFooter}>
      <GhostButton
        disabled={disableBookFxBtn}
        colorVariant="warning"
        testId={testIds.cancelBookingBtn}
        size="large"
        onClick={() => handleLiveRateReset()}
      >
        Cancel
      </GhostButton>
      <Authorization
        requiredRoles={[
          [UserRole.FX_IAT],
        ]}
      >
        <ActionButton
          testId={testIds.bookFxBtn}
          size="large"
          onClick={() => handleFxBooking()}
          disabled={disableBookFxBtn}
        >
          {disableBookFxBtn
            ? <CircularProgress size={20} />
            : 'Book FX'}
        </ActionButton>
      </Authorization>
    </div>
  );
};

export default memo(BookFxFooter);
