import { PaymentBatchDraftDto, PaymentBatchDto } from '@alpha/payments-dtos';
import { Box, Button, Typography } from '@mui/material';
import { Stat } from 'components/Stat';
import { StyledDivider } from 'components/StyledDivider';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { memo } from 'react';
import { DualFlag, Flag } from 'components/Flag';
import TextBoldLight from '../../../../components/TextBoldLight/TextBoldLight';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../hooks/useReportsPolling';
import { flowToBatchStatusMapping } from '../../../../utils/batchStatus.helpers';
import { formatNumber } from '../../../../utils/currency.helpers';
import isPaymentBatchDraftDto from '../../../../utils/payments/isPaymentBatchDraftDto.helper';
import { IBatchCurrencyPairDecorated } from '../BatchSummary.interface';
import { useStyles } from './BatchDetails.styles';
import CutOffDetails from './CutOffDetails/CutOffDetails';
import Rate from './Rate/Rate';
import CurrencyAmount from './CurrencyAmount/CurrencyAmount';

export const testIds = {
  batchStatusStat: 'batch-status-stat',
  totalPaymentStat: 'total-payments-stat',
  noOfApprovers: 'number-of-approvers-stat',
  currencyPairIcons: 'currency-pair-icons',
  cutOffTime: 'cut-off-time',
};

interface IProps {
  batchDetails: PaymentBatchDraftDto | PaymentBatchDto;
  currencyPairTab: IBatchCurrencyPairDecorated;
}

const BatchDetails: React.FC<IProps> = (props: IProps) => {
  const { batchDetails, currencyPairTab } = props;
  const { batchStatus } = batchDetails;
  const {
    noOfPayments,
    cutOffDate,
    cutOffTime,
    cutOffexpired,
    totalPayments,
    tradeId,
  } = currencyPairTab;
  const classes = useStyles();
  const { executeReportGeneration } = useReportsPolling(PageType.Payments);

  const handleReportGeneration = async (type: FileType, downloadType: DownloadType) => {
    await executeReportGeneration(type, tradeId, undefined, undefined, downloadType);
  };

  return (
    <div className={classes.root}>
      <div className={classes.flex}>
        {currencyPairTab.sellCurrency === currencyPairTab.buyCurrency ? (
          <Flag code={currencyPairTab.sellCurrency} size={35} rounded showCodeLabel />
        )
          : (
            <DualFlag
              startFlag={{
                code: currencyPairTab.sellCurrency,
                rounded: true,
                size: 35,
                showCodeLabel: true,
                locked: currencyPairTab.sellCurrency === currencyPairTab.fixedSide,
              }}
              endFlag={{
                code: currencyPairTab.buyCurrency,
                rounded: true,
                size: 35,
                showCodeLabel: true,
                locked: currencyPairTab.buyCurrency === currencyPairTab.fixedSide,
              }}
              arrowHeight={9}
              arrowWidth={8}
            />
          )}
        <StyledDivider vertical />
        <Stat
          testId={testIds.totalPaymentStat}
          title="Total Payments"
          value={(
            <TextBoldLight
              boldText={`${noOfPayments || totalPayments} /`}
              lightText={` ${(batchDetails as PaymentBatchDraftDto).noOfValidatedPayments || batchDetails.noOfPayments}`}
            />
          )}
          alignRight
        />
        <StyledDivider vertical />
        {flowToBatchStatusMapping.approver.includes(batchStatus)
          || flowToBatchStatusMapping.uploader.includes(batchStatus) ? (
            <CutOffDetails
              cutOffExpired={cutOffexpired}
              cutOffDate={cutOffDate}
              cutOffTime={cutOffTime}
            />
          ) : (
            <></>
          )}

        {(batchStatus === 'COMPLETE' || batchStatus === 'PROCESSING')
          && !isPaymentBatchDraftDto(batchDetails)
          && (
            <>
              {currencyPairTab.fundingAccount !== currencyPairTab.debitingAccount
                && (
                  <Box marginRight="40px">
                    <Stat
                      testId={testIds.totalPaymentStat}
                      title="Funding Account"
                      value={(
                        <Typography variant="h3" className="account bold">
                          {currencyPairTab.fundingAccount}
                        </Typography>
                      )}
                      alignRight
                    />
                  </Box>
                )}
              <Stat
                testId={testIds.totalPaymentStat}
                title="Debiting Account"
                value={(
                  <Typography variant="h3" className="account bold">
                    {currencyPairTab.debitingAccount}
                  </Typography>
                )}
                alignRight
              />
            </>
          )}
      </div>
      <div className={classes.flex}>
        {(batchDetails.batchStatus === 'PROCESSING'
          || batchDetails.batchStatus === 'COMPLETE')
          && !isPaymentBatchDraftDto(batchDetails)
          && !batchDetails.isSameCurrencyBatch
          && (
            <>
              <Box marginRight="40px">
                <Stat
                  testId={testIds.totalPaymentStat}
                  title="Debit Amount"
                  value={(
                    <Typography variant="h3" className="debitAmount">
                      {`-${getSymbolFromCurrency(
                        currencyPairTab.sellCurrency,
                      )}${formatNumber(currencyPairTab.debitAmount, 2)}`}
                    </Typography>
                  )}
                />
              </Box>
              <Rate currencyPairTab={currencyPairTab} />
              <Box>
                <Stat
                  testId={testIds.totalPaymentStat}
                  title="Credit Amount"
                  value={(
                    <Typography variant="h3" className="creditAmount">
                      {`+${getSymbolFromCurrency(
                        currencyPairTab.buyCurrency,
                      )}${formatNumber(currencyPairTab.creditAmount, 2)}`}
                    </Typography>
                  )}
                  alignRight
                />
              </Box>
              <Box className={classes.buttonWrapper}>
                <Tooltip title="Download ticket">
                  <Button
                    color="secondary"
                    onClick={() => handleReportGeneration(FileType.PDF, DownloadType.Fx)}
                    className={classes.button}
                  >
                    <FontAwesomeIcon className={classes.icon} size="1x" icon={faDownload} />
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}
        <CurrencyAmount
          batchDetails={batchDetails}
          currencyPairTab={currencyPairTab}
        />
      </div>
    </div>
  );
};

export default memo(BatchDetails);
