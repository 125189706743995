import React from 'react';
import { IStyledTabsWrapperProps } from './StyledTabsWrapper.interfaces';
import { Tab, getAttrProps, TabsWrapper } from './components';

const StyledTabsWrapper = (props:IStyledTabsWrapperProps) => {
  const {
    tabTitles,
    tabIndex = 0,
    tabName = 'tab',
    handleChange,
    testId,
    iconTitles = false,
  } = props;

  return (
    <TabsWrapper data-testid={testId} className="" value={tabIndex} indicatorColor="primary">
      {(tabTitles as Array<string | JSX.Element>).map(
        (item: string | JSX.Element, index: number) => (
          <Tab
            key={index}
            data-testid={`${tabName}-${index}`}
            className=""
            onClick={() => handleChange(index)}
            {...getAttrProps(index, tabName)}
            label={(
              <div>
                {item}
                {iconTitles && index !== tabTitles.length - 1}
              </div>
            )}
          />
        ),
      )}
    </TabsWrapper>
  );
};

export default StyledTabsWrapper;
