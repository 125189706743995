import { TimeoutModal } from 'components/TimeoutModal';
import React, { memo, useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useAuth from '../../hooks/useAuth';

interface IInactivityTrackerProps {
  children: React.ReactNode;
}
const InactivityTracker: React.FC<IInactivityTrackerProps> = ({
  children,
}: IInactivityTrackerProps) => {
  const maxTime = 20 * 60;
  const modalMaxTime = 60;
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const { handleSignOut } = useAuth();

  const onPrompt = () => {
    setOpen(true);
    setTimeRemaining(modalMaxTime);
  };

  const onIdle = () => {
    setOpen(false);
    handleSignOut();
  };

  const onActive = () => {
    setOpen(false);
    reset();
  };

  const { reset, getRemainingTime } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    timeout: maxTime * 1000,
    promptTimeout: modalMaxTime * 1000,
    crossTab: true,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    startOnMount: true,
    startManually: false,
    syncTimers: 1,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeRemaining(Math.floor(getRemainingTime() / 1000));
    }, 1000);

    return () => clearTimeout(timeout);
  }, [timeRemaining]);

  return (
    <>
      <TimeoutModal
        timeRemainingInSeconds={timeRemaining}
        maxTimeInSeconds={modalMaxTime}
        logoutOnClick={handleSignOut}
        open={open}
        continueOnClick={onActive}
        testId="timeout-modal"
      />
      <div data-testid="children-wrapper">{children}</div>
    </>
  );
};

export default memo(InactivityTracker);
