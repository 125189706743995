import { Box, Typography } from '@mui/material';
import React from 'react';

import useStyles from './Reference.styles';
import formatIsoDate from '../../../../utils/formatIsoDate';

interface IProps {
  estimatedSettlementDate: string | undefined;
  reference: string;
}

const Reference: React.FC<IProps> = ({
  estimatedSettlementDate,
  reference,
}: IProps) => {
  const styles = useStyles();

  const formattedEstimatedSettlementDate = estimatedSettlementDate ? formatIsoDate(estimatedSettlementDate) : '-';

  return (
    <Box className={styles.root}>
      <Box className={styles.spaceBetween}>
        <Typography variant="subtitle1">Estimated settlement date:</Typography>
        <Typography variant="subtitle1" id="earliestSettlementDate">{formattedEstimatedSettlementDate}</Typography>
      </Box>
      <hr className={styles.hr} />
      <Box className={styles.spaceBetween}>
        <Typography variant="subtitle1">Reference</Typography>
        <Typography variant="subtitle1" id="reference">{reference}</Typography>
      </Box>
    </Box>
  );
};

export default Reference;
