import {
  Autocomplete, Box, TextField, Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { OutlinedButton } from 'components/Buttons';
import { TUserAccount } from '../../../../models/user';
import useStyles from './CreateEntityGroup.style';
import useListStyles from '../../../../components/AutocompleteDropDown/Listbox.styles';

interface IProps {
  accounts: TUserAccount[];
  selectedAccounts: TUserAccount[];
  addAccountToGroup: (account: TUserAccount) => void;
}

const AddAccountDropdownCard: React.FC<IProps> = (props: IProps) => {
  const [value, setValue] = useState<TUserAccount| null>(null);
  const [accountsInDropdown, setAccountsInDropdown] = useState<TUserAccount[]>([]);
  const {
    accounts, addAccountToGroup, selectedAccounts,
  } = props;
  const classes = useStyles();
  const { listBox } = useListStyles();

  const getFilteredAccounts = () => {
    let userAccountsFiltered : TUserAccount[] = [];
    if (accounts && accounts.length > 0) {
      userAccountsFiltered = accounts.filter((userAccount) => (
        !selectedAccounts?.some((entityAccount) => (
          userAccount.id === entityAccount.id
        ))
      ));
    }
    setAccountsInDropdown(userAccountsFiltered);
  };

  useEffect(() => {
    getFilteredAccounts();
  }, [selectedAccounts]);

  const handleAddAccountClick = () => {
    if (value && value?.id !== '') {
      addAccountToGroup(value);
      setValue(null);
    }
  };

  const accountDropdownRef = useRef<HTMLElement>(null);

  useEffect(() => {
    accountDropdownRef.current?.scrollIntoView({
      block: 'end',
      inline: 'nearest',
    });
  }, []);

  return (
    <Box className={classes.addAccountCardWrapper}>
      <Typography variant="subtitle1" className={classes.addAccountCardTitle}>
        Select Entity
      </Typography>
      <div style={{ display: 'flex' }}>
        <Autocomplete
          ref={accountDropdownRef}
          classes={{ listbox: listBox }}
          onChange={(event: any, newValue:TUserAccount|null) => { setValue(newValue); }}
          value={value}
          getOptionLabel={(option) => option.name}
          options={accountsInDropdown}
          renderInput={(params) => (
            <TextField
              className={classes.accountsDropdownContainer}
              variant="filled"
              {...params}
              inputProps={{ ...params.inputProps, className: classes.accountsDropdown }}
            />
          )}
        />
        <Box className={classes.addToGroupButton}>
          <OutlinedButton
            onClick={handleAddAccountClick}
            disabled={value === null}
            rounded
          >
            Add to Group
          </OutlinedButton>
        </Box>

      </div>
    </Box>
  );
};
export default AddAccountDropdownCard;
