import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { StyledAPPaginationButton } from './index.styles';

export interface IAPPagination {
  hasPrevious: boolean;
  hasNext: boolean;
  handlePrevious: () => void;
  handleNext: () => void;
}

const APPagination: React.FC<IAPPagination> = (props: IAPPagination) => {
  const {
    hasPrevious, hasNext, handleNext, handlePrevious,
  } = props;
  return (
    <div>
      <StyledAPPaginationButton
        aria-label="Previous"
        startIcon={<FontAwesomeIcon size="6x" icon={faCaretLeft} />}
        onClick={handlePrevious}
        disabled={!hasPrevious}
        data-testid="prev-page-button"
      >
        Prev
      </StyledAPPaginationButton>
      <StyledAPPaginationButton
        aria-label="Next"
        endIcon={<FontAwesomeIcon icon={faCaretRight} />}
        onClick={handleNext}
        disabled={!hasNext}
        data-testid="next-page-button"
      >
        Next
      </StyledAPPaginationButton>
    </div>
  );
};

export default APPagination;
