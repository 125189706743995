import { SameTradeResponse } from '@alpha/fx-dtos';
import React from 'react';
import DrawerBackdrop from '../../../../components/Drawer/DrawerBackdrop/DrawerBackdrop';
import DrawerBackdropLoader from '../../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import SameTradeWarning from '../../../../components/Molecules/SameTradeWarning';
import TransactionError from '../../../../components/Molecules/TransactionError';
import { TDrawerState } from '../useBookFx';

interface IProps {
  liveRateStatus: TDrawerState;
  handleResetFormStateAndCloseIATDrawer: () => void;
  handleAcknowledgeDuplicateTrade: ()=> void;
  paymentDetails: SameTradeResponse[]
}

const BackdropDrawer: React.FC<IProps> = ({
  liveRateStatus,
  handleResetFormStateAndCloseIATDrawer,
  paymentDetails,
  handleAcknowledgeDuplicateTrade,
}: IProps) => {
  switch (liveRateStatus) {
    case 'initiated':
    case 'failedBookTrade':
      return (
        <DrawerBackdrop display backgroundColor="rgba(255,255,255,0.95)">
          <TransactionError onClick={handleResetFormStateAndCloseIATDrawer} />
        </DrawerBackdrop>
      );
    case 'duplicateTrade':
      return (
        <DrawerBackdrop display backgroundColor="rgba(255,255,255,0.95)">
          <SameTradeWarning
            paymentDetails={paymentDetails}
            onClick={handleAcknowledgeDuplicateTrade}
          />
        </DrawerBackdrop>
      );
    case 'loading':
      return <DrawerBackdropLoader display text="Obtaining your quote" />;
    case 'initiatedBookTrade':
      return <DrawerBackdropLoader display text="Booking your trade" />;
    case 'success':
    default:
      return null;
  }
};

export default BackdropDrawer;
