import React, { useEffect } from 'react';
import { PaymentsContext, SinglePaymentContext } from '../../../../context/ManualPaymentContexts';
import useManualPayment from '../../../../hooks/useManualPayment';
import useStyles from './Body.styles';
import Footer from './Footer/Footer';
import FundingAccountContainer from './FundingAccount/FundingAccountContainer';
import PaymentType from './PaymentType/PaymentType';
import BackdropLoader from '../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';

const Body: React.FC = () => {
  const styles = useStyles();
  const {
    currentPayment,
    open,
    isValid,
    currentPayments,
    currencyAccounts,
    selectedDebitingAccount,
    editRow,
    location,
    authyStore,
    paymentsStore,
    loading,
    setLoading,
    setCurrencyAccounts,
    setSelectedDebitingAccount,
    setEditRow,
    setIsValid,
    setCurrentPayment,
    setCurrentPayments,
    handleSetCurrentPayments,
    handleBeneficiaryDrawerToggle,
    parseAuthyStatus,
    parseBatchStatusUpdates,
  } = useManualPayment();

  useEffect(() => {
    setCurrentPayments([]);
  }, [location.pathname]);

  useEffect(() => {
    parseBatchStatusUpdates();
  }, [paymentsStore.batchDetails?.batchStatus]);

  useEffect(() => {
    parseAuthyStatus();
  }, [authyStore.status]);

  return (
    <PaymentsContext.Provider
      value={{
        currentPayments,
        currencyAccounts,
        selectedDebitingAccount,
        handleSetCurrentPayments,
      }}
    >
      <SinglePaymentContext.Provider
        value={{
          editRow,
          open,
          currentPayment,
          isValid,
          setEditRow,
          setIsValid,
          setCurrentPayment,
          handleBeneficiaryDrawerToggle,
        }}
      >
        <div className={styles.root}>
          <FundingAccountContainer
            currencyAccounts={currencyAccounts}
            setCurrencyAccounts={setCurrencyAccounts}
            selectedDebitingAccount={selectedDebitingAccount}
            setSelectedDebitingAccount={setSelectedDebitingAccount}
          />
          {selectedDebitingAccount && (
            <PaymentType
              selectedDebitingAccount={selectedDebitingAccount}
            />
          )}
        </div>
        <Footer
          setLoading={setLoading}
          singleValidPayment={isValid && location.pathname.includes('app/payments/manual/single')}
          currentPayment={currentPayment}
          singleTab={location.pathname.includes('single')}
          currentPayments={currentPayments}
          selectedDebitingAccount={selectedDebitingAccount}
        />
      </SinglePaymentContext.Provider>

      {loading
        && <BackdropLoader testId="loading" withOverlay positionFixed />}
    </PaymentsContext.Provider>
  );
};

export default Body;
