import React, { FC } from 'react';
import { FormNoteStyled } from './styles';

type IProps = {
  text: string;
  onClick?: () => void;
  style?: any;
};

const FormNote: FC<IProps> = ({ text, onClick, style }) => (
  <FormNoteStyled onClick={onClick} style={style}>{ text }</FormNoteStyled>
);

export default FormNote;
