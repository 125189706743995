import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import {
  StyledActionButton,
  StyledBody,
  StyledButtonsContainer,
  StyledContainer,
  StyledHeader,
} from './index.styles';
import APModal from '../APModal/APModal';

export interface IInfoModalProps {
  open: boolean;
  title: string;
  content?: JSX.Element | string;
  handleClose?: () => void;
}

const InfoModal: React.FC<IInfoModalProps> = (props: IInfoModalProps) => {
  const {
    open,
    title,
    content,
    handleClose,
  } = props;

  return (
    <StyledContainer
      disableEscapeKeyDown
      open={open}
    >
      <APModal.Wrapper>
        <Box>
          <StyledHeader>
            <span>
              <Typography variant="h2">{title}</Typography>
            </span>
          </StyledHeader>
        </Box>
        <StyledBody>
          {content}
        </StyledBody>
        <StyledButtonsContainer>
          <StyledActionButton
            variant="contained"
            data-testid="modal-action-button"
            color="primary"
            disableElevation
            type="submit"
            onClick={handleClose}
          >
            Close
          </StyledActionButton>
        </StyledButtonsContainer>
      </APModal.Wrapper>
    </StyledContainer>
  );
};

export default InfoModal;
