import {
  TextField, Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import APDatePicker from 'components/APDatePicker/APDatePicker';
import { datadogRum } from '@datadog/browser-rum';
import useStyles from './PaymentDate.styles';
import useAlphaSnackbar from '../../../../../../hooks/useAlphaSnackbar';
import { TDate, TPayment } from '../../../../../../models/payments';
import PaymentsService from '../../../../../../services/Payments/payments.service';
import FormNote from '../../components/FormNote';

interface IProps {
  setCurrentPayment: React.Dispatch<React.SetStateAction<TPayment>>;
  setDatesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  currentPayment: TPayment;
  debitingCurrencyCode: string;
  standingOrderChecked: boolean;
}

const PaymentDate: React.FC<IProps> = ({
  currentPayment,
  debitingCurrencyCode,
  setDatesLoaded,
  setCurrentPayment,
  standingOrderChecked,
}: IProps) => {
  const sb = useAlphaSnackbar();
  const styles = useStyles();
  const [dates, setDates] = useState<TDate>();

  useEffect(() => {
    let isCancelled = false;
    getAvailableDates(isCancelled);

    return () => {
      isCancelled = true;
    };
  }, [currentPayment.beneficiary?.currencyCode]);

  const getAvailableDates = async (isCancelled: boolean) => {
    try {
      setDatesLoaded(false);
      if (!currentPayment.beneficiary) return;

      const newDates = await PaymentsService.getAvailablePaymentDate(
        currentPayment.beneficiary?.currencyCode,
        debitingCurrencyCode,
        currentPayment.beneficiary?.accountId,
      );

      if (!isCancelled) {
        handleDateChange(moment(newDates.earliestDate));
        setDates(newDates);
        setDatesLoaded(true);
      }
    } catch (e) {
      sb.trigger(
        'Something went wrong when retrieving all available dates for your payment',
      );
      datadogRum.addError(e, {
        source: 'PaymentsMultiEntity.ManualPayments.Body.ManualPayment.PaymentFields.PaymentDate.tsx',
        message: 'Error getting payment purpose',
        debitingCurrencyCode,
        currencyCode: currentPayment.beneficiary?.currencyCode,
        tags: ['getAvailableDates'],
      });
    }
  };

  useEffect(() => {
    if (!standingOrderChecked) {
      setCurrentPayment({
        ...currentPayment,
        date: moment().add(1, 'day').format('YYYY-MM-DD'),
      });
    }
  }, [standingOrderChecked]);

  const calculateDisableDates = (_date: Moment) => {
    if (dates) {
      const formattedDate = _date.format('YYYY-MM-DD');
      // TODO: temporary fix, will be reworked on BE later on
      const yearFromNow = moment().add(1, 'year').format('YYYY-MM-DD');
      const latestDate = standingOrderChecked ? yearFromNow : dates.latestDate;

      return (
        moment(formattedDate).isBefore(dates.earliestDate)
        || moment(formattedDate).isAfter(latestDate)
        || dates.holidays.some((holiday) => moment(holiday.date).format('YYYY-MM-DD') === formattedDate)
      );
    }
    return false;
  };

  const handleDateChange = (_date: Moment) => {
    if (_date) {
      setCurrentPayment({
        ...currentPayment,
        date: _date.format('YYYY-MM-DD'),
      });
    }
  };

  if (!dates) {
    return null;
  }

  return (
    <div>
      <Typography variant="subtitle1" className="label" fontSize={14}>
        When
      </Typography>
      <APDatePicker
        label=""
        className={styles.datePicker}
        onChange={handleDateChange}
        value={moment(currentPayment.date) || moment(dates.earliestDate)}
        format="DD/MM/YYYY"
        shouldDisableDate={calculateDisableDates}
        renderInput={(params) => <TextField {...params} />}
        fullWidth
      />
      <FormNote text="Date which the payment will be sent." />
    </div>
  );
};

export default PaymentDate;
