import { BatchQuoteDto } from '@alpha/payments-dtos';
import { Loader } from 'components/Loader';
import clsx from 'clsx';
import React from 'react';
import InverseFxRateTooltip from '../../../../../components/Molecules/InverseFxRateTooltip/InverseFxRateTooltip';
import { testIds } from '../../Funding';
import { IBatchCurrencyPairRateDecorated } from '../../Funding.interface';
import useStyles from './IndicativeLive.styles';

interface IProps {
  currencyPair: IBatchCurrencyPairRateDecorated;
  liveRates: BatchQuoteDto[];
  loadingLiveRate: boolean;
}

const IndicativeLive = (props: IProps) => {
  const {
    currencyPair,
    liveRates,
    loadingLiveRate,
  } = props;
  const classes = useStyles();
  return (
    <div className={
      clsx(
        liveRates.length > 0 ? classes.greenBackground : classes.greyBackground,
        classes.indicative,
      )
    }
    >
      <InverseFxRateTooltip displayInfoCircleIcon text={liveRates.length > 0 ? 'Live' : 'Indicative'} />
      {
        loadingLiveRate
          ? (
            <Loader
              testId={testIds.loadingLiveRate}
              className={classes.liveRateLoader}
            />
          )
          : (
            <div className={classes.rates}>
              <p>{currencyPair.rate}</p>
              {currencyPair.rate && <p>{(1 / currencyPair.rate).toFixed(4)}</p>}
            </div>
          )
      }
    </div>
  );
};

export default IndicativeLive;
