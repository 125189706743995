import {
  Box, Typography,
} from '@mui/material';
import React from 'react';
import clipboardCheck from '../../../assets/clipboardCheck.svg';
import {
  AccountsViewDropdownOptions,
  GroupViewDropdownOptions,
} from '../helpers';
import GroupsCheckboxes from './GroupsCheckboxes';
import useStyles from './index.styles';

interface IDropdownProps {
  groupMenuOptions: GroupViewDropdownOptions[];
  accountMenuOptions: AccountsViewDropdownOptions[];
  selectedOptions: string[];
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    accountId: string
  ) => void;
  handleClickGroup: (
    event: React.ChangeEvent<HTMLInputElement>,
    group: GroupViewDropdownOptions
  ) => void;
}
const GroupsDropdown: React.FC<IDropdownProps> = (props: IDropdownProps) => {
  const {
    accountMenuOptions,
    groupMenuOptions,
    handleChange,
    handleClickGroup,
    selectedOptions,
  } = props;
  const classes = useStyles();

  if (groupMenuOptions.length === 0) {
    return (
      <Box className={classes.noResults}>
        <Typography className={classes.noResultsText} variant="h5">
          No Entity Groups Found
        </Typography>

        <Box className={classes.noResultsIcon}>
          <img src={clipboardCheck} alt="empty-table" />
        </Box>
      </Box>
    );
  }
  return (
    <div>
      {groupMenuOptions.map((group) => (
        <GroupsCheckboxes
          key={group.id}
          selectedOptions={selectedOptions}
          groupMenuOptions={group}
          accountMenuOptions={accountMenuOptions}
          handleChange={handleChange}
          handleClickGroup={(event) => handleClickGroup(event, group)}
        />
      ))}
    </div>
  );
};

export default GroupsDropdown;
