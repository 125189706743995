import {
  AccountWithApprovalConfig,
  UserAccount,
  UserRoleRequest,
  Roles, ApproverLevel,
} from '@portal/user-types';
import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { FormikValues } from 'formik';
import { IWizardModalConfig } from '../../../components/Modals/Modal.interfaces';
import { WizardError } from '../../../models/userManagement';
import UserManagementService from '../../../services/UserManagement/userManagement.service';
import { validationSchema } from '../../../utils/formikValidation';
import {
  defaultRoles, isApproverLevelRequired, isValidApproverLevel, validateApproverLevel,
} from '../helpers/permissions';
import { INestedStep } from '../../../components/Modals/WizardModal/context/reducer';
import { IWizardParams, TUser } from '../context/index.interfaces';
import Permissions from '../components/Permissions';
import EntitiesSelector from '../components/EntitiesSelector';

interface IEntityAccessModalPermissions {
  [key: string]: {
    roles: Array<Roles>,
    approverLevel: ApproverLevel,
    approvedSignatory: boolean,
  }
}

export interface IEntityAccessModalValues {
  ids: Array<AccountWithApprovalConfig>;
  permissions: IEntityAccessModalPermissions;
}

const generateStepTitle = (title: string, num = 0, tot = 0) => {
  if (tot && tot > 0) {
    return `${title} (${num + 1} of ${tot + 1})`;
  }
  return title;
};

const createParams = async (
  user: TUser,
  values: IEntityAccessModalValues,
):Promise<UserRoleRequest[]> => {
  const { entities } = await UserManagementService.getUser({
    id: user.id, page: '0', approvalConfig: 'true',
  });
  const roles: UserRoleRequest[] = [];
  values.ids.forEach((selectedEntity) => {
    const currentPermission = values.permissions[selectedEntity.id];
    const oldRoles = entities.find((e) => e.id === selectedEntity.id);
    if (!_.isEmpty(_.xor(
      currentPermission.roles, (oldRoles?.roles as Roles[]),
    )) || currentPermission.approverLevel !== oldRoles?.approverLevel) {
      const requiredApproverLevel = isApproverLevelRequired(currentPermission.roles);
      const approvalLevel = requiredApproverLevel && currentPermission.approverLevel
        ? currentPermission.approverLevel
        : null;

      roles.push({
        userId: user?.id,
        entityId: selectedEntity.id,
        roles: Array.from(new Set(currentPermission.roles)),
        approvalLevel,
      });
    }
  });
  return roles;
};

const populatePermissions = async (params: {
  userId?: string,
  selectedIds: AccountWithApprovalConfig[];
  existingRoles: IEntityAccessModalPermissions;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) => {
  const {
    userId, selectedIds, existingRoles, setFieldValue,
  } = params;

  const currentRoles: IEntityAccessModalPermissions = {};
  const idsToPopulate = selectedIds.reduce((acc: Array<string>, { id }: { id: string }) => {
    if (id in existingRoles) {
      currentRoles[id] = existingRoles[id];
    } else {
      acc.push(id);
    }
    return acc;
  }, []);

  const newPermissions: Record<string, any> = {};
  if (idsToPopulate.length > 0 && userId) {
    const { entities } = await UserManagementService.getUser({
      id: userId, page: '0', approvalConfig: 'true',
    });
    const existingPermissions: Record<string, any> = {};
    entities.forEach((entity: UserAccount) => {
      existingPermissions[entity.id] = entity;
    });
    idsToPopulate.forEach((id: string) => {
      if (id in existingPermissions) {
        const {
          roles, isApprovedSignatory, approvalConfig, approverLevel,
        } = existingPermissions[id];
        newPermissions[id] = {
          roles,
          isApprovedSignatory,
          approverLevel: isValidApproverLevel(approvalConfig, approverLevel) ? approverLevel : '',
        };
      } else {
        newPermissions[id] = {
          roles: defaultRoles,
          isApprovedSignatory: false,
          approverLevel: '',
        };
      }
    });
  }
  setFieldValue('permissions', { ...currentRoles, ...newPermissions });
};

export const entityAccessModalConfig = (
  { user, entity, submitCallback = (callback: () => Promise<any>) => callback() }: IWizardParams,
): IWizardModalConfig<IEntityAccessModalValues> => ({
  name: `${user?.firstName} ${user?.lastName}`,
  title: <>{`Entity Access | ${user?.firstName} ${user?.lastName}`}</>,
  steps: [
    {
      title: () => generateStepTitle('Entities'),
      component: <EntitiesSelector />,
      validationSchema: validationSchema({
        ids: Yup.array().of(
          Yup.object({
            id: Yup.string().required(),
          }),
        ).min(1),
      }),
      handleNext: async ({
        values, setNestedStepsTotal, handleNext, setFieldValue, toggleLoading, handleError,
      }) => {
        if (values?.ids
          && values.ids.length > 0
          && setNestedStepsTotal
          && handleNext
          && setFieldValue
          && toggleLoading
          && handleError
        ) {
          toggleLoading();
          try {
            await populatePermissions({
              selectedIds: values.ids,
              existingRoles: values.permissions,
              userId: user?.id,
              setFieldValue,
            });
            handleNext?.();
            setNestedStepsTotal?.(values.ids.length - 1, 1);
          } catch (e) {
            const error = new WizardError({ message: 'Network Error', step: 0, nested: 0 });
            handleError(error);
          }
          toggleLoading();
        }
      },
    },
    {
      title: (num?: number, total?: number) => generateStepTitle('Permissions', num, total),
      nextButtonText: () => 'Continue to Entity',
      component: <Permissions />,
      validationSchema: validationSchema({}),
    },
  ],
  submitButtonText: 'Submit For Approval',
  initialValues: {
    ids: entity ? [entity] : [],
    permissions: {},
  },
  validate: (
    values: FormikValues, step: number, nestedSteps: INestedStep[],
  ) => validateApproverLevel(values, step, nestedSteps, 1),
  submitForm: async (values) => {
    if (user) {
      const userRoleRequest = await createParams(user, values);
      if (userRoleRequest.length > 0) {
        const request = () => UserManagementService.createUserRoleRequest({
          userRoleRequest,
        });

        await submitCallback(request);

        return { message: 'Request submitted for approval', type: 'success' };
      }
    }
    return { message: 'No changes detected', type: 'info' };
  },
});
