/* eslint-disable react/jsx-indent */
import { ReleasedPaymentBatchesResponse } from '@alpha/payments-types';
import { Box } from '@mui/material';
import Status from 'components/Status/Status';
import React from 'react';
import clsx from 'clsx';
import SearchTable from '../../../../components/Table/SearchTable';
import { TextEllipsis } from '../../../../components/TextEllipsis';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../utils/formatIsoDate';
import { SearchPaymentsTabProps } from '../../usePaymentBatchSearch';
import {
  getApprovedDate,
  getUploadedBy,
} from '../DashboardDataHelper/DashboardDataHelper';
import PaymentsType from '../PaymentsType/PaymentsType';
import useStyles from '../SearchPaymentsTable/SearchPaymentsTable.styles';
import ActionStatus from './ActionStatus';
import Tooltip from '../../../../components/Tooltip/Tooltip';

const InProgressTable: React.FC<SearchPaymentsTabProps> = (
  props: SearchPaymentsTabProps,
) => {
  const {
    items,
    hasNext,
    hasPrevious,
    handleNextPage,
    handlePreviousPage,
    loading,
  } = props;
  const classes = useStyles();
  const columns: ITableColumn[] = [
    {
      header: <Box paddingRight="80px">ID</Box>,
      accessor: 'id',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Entity</Box>,
      accessor: 'entity',
      className: clsx(classes.textAlignLeft, 'dd-privacy-allow'),
    },
    {
      header: <Box>Type</Box>,
      accessor: 'type',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Uploaded by</Box>,
      accessor: 'uploadedBy',
    },
    {
      header: <Box className={classes.textAlignLeft}>uploaded date</Box>,
      accessor: 'uploadedDate',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Approved date</Box>,
      accessor: 'approvedDate',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>payment Status</Box>,
      accessor: 'paymentStatus',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>batch status</Box>,
      accessor: 'batchStatus',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.action}>Action</Box>,
      accessor: 'action',
      className: 'dd-privacy-allow',
    },
  ];

  const actionNeededText = (
    <div>
      We require further information for one or more payments in this batch.
      <br />
      <br />
      View the batch to find out more.
    </div>
  );

  const mappedData = items?.records
    && (items.records as ReleasedPaymentBatchesResponse[]).map(
      (item) => {
        const container: any = {};
        container.id = <b>{item.friendlyId || ''}</b>;
        container.entity = (
          <TextEllipsis text={item.accountName} className={classes.spaceRight} />
        );
        container.type = <PaymentsType type={item.uploadType} />;
        container.uploadedBy = (
          <Box className={classes.textAlignLeft}>
           <span>{getUploadedBy(item)}</span>
          </Box>
        );
        container.uploadedDate = (
          <Box className={classes.textAlignLeft}>
            {
              formatIsoDate(item.uploadedDate)
            }
          </Box>
        );
        container.approvedDate = (
          <Box className={classes.textAlignLeft}>{getApprovedDate(item)}</Box>
        );

        container.paymentStatus = (
          <Box className={classes.textAlignLeft}>
            {item.status === 'UPLOADING' ? (
              <span className={classes.processing}>Processing...</span>
            ) : (
              <Status variant="default">
                {`${item.totalProcessedPayments}/${item.totalPayments}`}
              </Status>
            )}
          </Box>
        );

        container.batchStatus = (
          <Box className={classes.textAlignLeft}>
            {item?.paymentsAwaitingClientInput && item?.paymentsAwaitingClientInput > 0 ? (
                <Tooltip
                  title={actionNeededText}
                >
                  <span>
                    <Status variant="overdue">
                      Action Needed
                    </Status>
                  </span>
                </Tooltip>
            ) : <Status variant="warning">In Progress</Status>}
          </Box>
        );

        container.action = (
          <ActionStatus
            accountId={item.accountId}
            batchStatus={item.status}
            id={item.id}
            actionNeeded={item?.paymentsAwaitingClientInput && item?.paymentsAwaitingClientInput > 0}
          />
        );
        return container;
      },
    );

  return (
    <SearchTable
      table={{
        columns,
        data: mappedData || [],
      }}
      loading={loading}
      pagination={{
        handleNext: () => handleNextPage(),
        handlePrevious: () => handlePreviousPage(),
        hasNext: hasNext || false,
        hasPrevious: hasPrevious || false,
      }}
      emptyTable={{
        title: 'No In Progress Payments',
        subtitle: 'You currently do not have any in progress payment batches',
      }}
    />
  );
};

export default InProgressTable;
