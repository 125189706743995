import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ActionButton } from 'components/Buttons';
import { APInput } from 'components/APInput';
import FormTitle from '../../../components/Molecules/Auth/FormTitle';
import useFormStyles from '../form.styles';
import useLogin from '../../../hooks/useLogin';
import routes from '../../../routes.path';
import { useOnboardingContext } from '../Onboarding';

interface IProps {
    onboarding?: boolean;
}

const onboardingDescription = 'To get started, please enter your username and the temporary password below. You can find your temporary password in your welcome email we sent to you.';

const Login: React.FC<IProps> = ({ onboarding }: IProps) => {
  const formClasses = useFormStyles();
  const { formik, loading } = useLogin(onboarding);
  const { handleSetLoaderPercentage } = useOnboardingContext();

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      handleSetLoaderPercentage(25);
    }
  }, []);

  return (
    <div>
      <FormTitle
        number={onboarding ? 1 : undefined}
        title={onboarding ? 'Verify your email' : 'Sign In'}
        description={onboarding ? onboardingDescription : undefined}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className={formClasses.section}>
          <Typography className={formClasses.label}>Enter your username</Typography>
          <APInput
            autoComplete="off"
            autoFocus
            testid="username"
            id="username"
            name="username"
            placeholder="Enter your username"
            fullWidth
            variant="filled"
            type="text"
            inputProps={{
              autoComplete: 'off',
              form: {
                autoComplete: 'new-off',
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.username}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
        </div>
        <div className={formClasses.section}>
          <Typography className={formClasses.label}>
            Enter your
            {' '}
            {onboarding ? 'temporary password' : 'password'}
          </Typography>
          <APInput
            autoComplete="off"
            testid="password"
            id="password"
            name="password"
            type="password"
            placeholder="Enter your password"
            variant="filled"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </div>
        <ActionButton
          size="large"
          type="submit"
          testId="submit-login"
          loading={loading}
          disabled={loading || !formik.isValid}
          fullWidth
        >
          {onboarding ? 'SET UP ACCOUNT' : 'LOG IN'}
        </ActionButton>
        {!onboarding && (
          <Typography className={formClasses.formBacklink}>
            <span className={formClasses.white}>
              Forgot your password?
            </span>
            {' '}
            <NavLink to={routes.auth.passwordReset.base}>Reset password</NavLink>
          </Typography>
        )}
      </form>
    </div>
  );
};

export default Login;
