import React, { useEffect, FC, useRef } from 'react';
import { Flags } from 'react-feature-flags';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import useAuthorization from '../../../hooks/useAuthorization';
import useGetFeatureFlags from '../../../hooks/useGetFeatureFlags';
import useInvoicePermissions from '../../../hooks/useInvoicePermissions';
import useSwitchAccount from '../../../hooks/useSwitchAccount';
import { UserRole } from '../../../models/user';
import routes from '../../../routes.path';
import { TStore } from '../../../store';
import type { IAppMenuItemData, ISidebarProps } from './index.interfaces';
import { useStyles } from './index.styles';
import { AppMenu } from './components/AppMenu';

const SidebarBody: FC<ISidebarProps> = (props) => {
  const {
    items,
    isOpen,
    close,
  } = props;
  const rootRef = useRef<HTMLDivElement>(null);

  const classes = useStyles({ type: 'translate' });

  return (
    <>
      <aside
        ref={rootRef}
        className={cls(classes.root, 'dd-privacy-allow', { [classes.opened]: isOpen })}
      >
        <div className={classes.mainWrapper}>
          <div
            className={cls(
              classes.mainHolder, { [classes.opened]: isOpen },
            )}
          >
            <div className={classes.content}>
              <nav className={classes.nav}>
                <AppMenu items={items} showIcon={false} showLabel={false} sorted={false} />
              </nav>
            </div>
          </div>
        </div>
      </aside>
      <div className={cls(classes.overlay, isOpen ? classes.openOverlay : '')} onClick={close} role="presentation" />
    </>
  );
};

const Sidebar = ({ toggle, isOpen, close }: {
  toggle: () => void, isOpen: boolean, close: () => void
}) => {
  const invoiceAuthorization = useAuthorization([[UserRole.INVOICEMANAGER]]);
  const { isAuthorizedInvoiceManager, getUserInvoiceEntities } = useInvoicePermissions();

  const isAuthorisedToViewEntityManagement = useSelector<TStore, boolean | undefined>(
    (state) => state.userManagementPage.isAuthorizedToViewEntityManagement,
  );

  const { requestGetFeatureFlags } = useGetFeatureFlags();

  useEffect(() => {
    requestGetFeatureFlags();
    getUserInvoiceEntities();
  }, []);

  const { currentAccount } = useSwitchAccount();

  const paymentsRoute = {
    onClick: () => toggle(),
    url: `${routes.payments.base}`,
    label: 'Payments',
    name: 'payments',
  };

  const paymentsMultiEntityRoute = {
    onClick: () => toggle(),
    url: `${routes.payments.multientity.base}`,
    label: 'Payments',
    name: 'paymentsMe',
  };

  const beneficiaryRoute = {
    onClick: () => toggle(),
    url: `${routes.beneficiaries}`,
    label: 'Beneficiaries',
    name: 'beneficiaries',
  };

  const accountsRoute = {
    onClick: () => toggle(),
    url: `${routes.currencyAccounts}`,
    label: 'Accounts',
    name: 'accounts',
  };

  const transactionsRoute = {
    onClick: () => toggle(),
    url: `${routes.noTransactions}`,
    label: 'Transactions',
    name: 'transactions',
  };

  const dashboardRoute = {
    onClick: () => toggle(),
    url: `${routes.dashboard.base}`,
    label: 'Dashboard',
    name: 'actionsdashboard',
  };

  const invoiceRoute = {
    onClick: () => toggle(),
    url: `${routes.invoices}`,
    label: 'Invoices',
    name: 'invoices',
  };

  const transactionsMultiEntityRoute = {
    onClick: () => toggle(),
    url: `${routes.transactionsMultiEntity}`,
    label: 'Transactions',
    name: 'transactionsMe',
  };

  const userManagementRoute = {
    onClick: () => toggle(),
    url: `${routes.userManagement.base}`,
    label: 'Entity Management',
    name: 'userManagmenent',
  };

  const adaptedMenu = [
    accountsRoute,
    paymentsRoute,
    transactionsRoute,
    beneficiaryRoute,
  ];

  if (currentAccount.invoicesEnabled && invoiceAuthorization.authorized) {
    adaptedMenu.push(invoiceRoute);
  }

  const calculateMenuItemsBasedOnFeatureFlags = (
    authorizedFlags: [{ name: string; }],
  ): IAppMenuItemData[] => {
    let returnedMenu = [];

    const checkFlag = (flag:string) => authorizedFlags.findIndex(
      (authorizedFlag) => authorizedFlag.name === flag,
    ) > -1;

    // Dashboard
    if (checkFlag('outstandingDashboard')) {
      returnedMenu.push(dashboardRoute);
    }

    // Accounts
    returnedMenu = [...returnedMenu, accountsRoute];

    // Payment Page
    if (checkFlag('paymentPageMultiEntity')) {
      returnedMenu.push(paymentsMultiEntityRoute);
    } else {
      returnedMenu.push(paymentsRoute);
    }

    // Transactions Page
    if (checkFlag('transactionsPage')) {
      returnedMenu.push(transactionsMultiEntityRoute);
    } else {
      returnedMenu.push(transactionsRoute);
    }

    // Beneficiaries
    returnedMenu.push(beneficiaryRoute);

    // Invoices
    if (checkFlag('invoicePage') && isAuthorizedInvoiceManager) {
      returnedMenu.push(invoiceRoute);
    }

    // Entity Management
    if (checkFlag('entityManagementPage') && isAuthorisedToViewEntityManagement) {
      returnedMenu.push(userManagementRoute);
    }

    return returnedMenu;
  };

  return (
    <div data-testid="portal-sidebar">
      <Flags
        authorizedFlags={['outstandingDashboard', 'transactionsPage', 'paymentPageMultiEntity', 'invoicePage', 'entityManagementPage']}
        renderOn={(authorizedFlags: [{ name: string; }]) => (
          <SidebarBody
            items={calculateMenuItemsBasedOnFeatureFlags(authorizedFlags)}
            isOpen={isOpen}
            close={close}
          />
        )}
        renderOff={() => (
          <SidebarBody
            items={adaptedMenu}
            isOpen={isOpen}
            close={close}
          />
        )}
      />
    </div>
  );
};

export default Sidebar;
