import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Tooltip } from '@mui/material';

import { Flag } from 'components/Flag';
import { Icon } from './Icon/Icon';
import { TInputProps } from './Input.interfaces';
import {
  StyledInput,
} from './Input.styles';

export const Input: FC<TInputProps> = (props) => {
  const {
    className = '',
    testId,
    inputProps = {},
    InputProps,
    currencyType,
    variant,
    warning,
    currency,
    startAdornmentOnClick,
    ...rest
  } = props;

  const warningIcon = warning
    ? (<FontAwesomeIcon icon={faExclamationTriangle} className="warningIcon" />)
    : undefined;

  const component = (
    <StyledInput
      className={`UiInput ${className} StyledInput`}
      inputProps={{
        ...inputProps,
        'data-testid': testId,
      }}
      InputProps={{
        ...InputProps,
        endAdornment: InputProps?.endAdornment ? (
          <Icon
            wrapVariant={variant}
            wrap={variant === 'outlined' || variant === 'filled'}
            position="right"
          >
            {InputProps.endAdornment}
          </Icon>
        ) : warningIcon,
        startAdornment:
                    InputProps?.startAdornment || currency ? (
                      <Icon
                        onClick={startAdornmentOnClick || undefined}
                        wrapVariant={variant}
                        wrap={variant === 'outlined' || variant === 'filled'}
                        position="left"
                      >
                        {InputProps?.startAdornment}
                        {currency && (
                        <div className="currencyAdornment">
                          <span>{currency}</span>
                          <Flag code={currency} size="sm" />
                        </div>
                        )}
                      </Icon>
                    ) : undefined,
      }}
      currencyInput={currencyType}
      {...rest}
    />
  );

  if (warning) {
    return (
      <Tooltip title={warning} data-testid={`${testId}-tooltip`}>
        <div>{component}</div>
      </Tooltip>
    );
  }

  if (currency) {
    const label = `${currencyType} Amount`;
    return (
      <div>
        <p className="currencyLabel">{label}</p>
        <div>{component}</div>
      </div>
    );
  }

  return component;
};
