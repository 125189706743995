import _default, { makeStyles } from 'theme';

const { palette } = _default;

export const useStyles = makeStyles(
  () => ({
    container: {
      paddingTop: 16,
      paddingBottom: 16,
      display: 'flex',
    },
    circle: {
      background: '#0983751A',
      borderRadius: '50%',
      width: '82px',
      height: '82px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        color: palette.primary.main,
        fontSize: '30px',
        letterSpacing: -0.5,
      },
    },
    name: {
      fontSize: '18px',
      letterSpacing: -0.9,
      fontWeight: 600,
      padding: '0 200px 0 0',
    },
    info: {
      color: '#333333',
      fontSize: '14px',
      opacity: '80%',
    },
    infoList: {
      margin: '15px 0 0 20px',
    },
    icons: {
      color: '#808080',
      opacity: '78%',
      margin: '0 8px 0 0',
      height: '14px',
      width: '14px',
    },
    editIcon: {
      color: '#808080',
      opacity: '78%',
      margin: '0 8px 2px 20px',
      height: '14px',
      width: '14px',
    },
  }),
  { name: 'Success' },
);

export default useStyles;
