import styled from '@mui/styled-engine';
import Box from '@mui/material/Box';
import _default from 'theme';

const { typography } = _default;

type ITIconStyles = {
  wrapVariant: 'filled' | 'standard' | 'outlined' | undefined,
  positioning: 'right' | 'left',
  onClick?: () => void,
};

export default styled(Box, {
  shouldForwardProp: (prop) => (prop !== 'position' && prop !== 'wrapVariant' && prop !== 'onClick'),
})(({ positioning: position, wrapVariant, onClick }: ITIconStyles) => {
  const indent = wrapVariant === 'outlined' ? '-14px' : '-12px';
  const { fontWeightRegular } = typography;

  return {
    '.InputIconBox': {
      display: 'inline-flex',
      flex: '0 0 auto',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0.375rem 0.75rem',
      marginBottom: 0,
      fontSize: '0.84rem',
      fontWeight: fontWeightRegular,
      lineHeight: 1.5,
      color: '#495057',
      backgroundColor: wrapVariant === 'outlined' ? '#e9ecef' : '#F0F0F0',
      border: wrapVariant === 'outlined' ? '1px solid #ced4da' : 0,
      borderRadius: position === 'left' ? 'inherit 0 0 inherit' : 'inherit inherit 0 0',
      height: '100%',
      minWidth: wrapVariant === 'outlined' ? 0 : 50,
      marginLeft: position === 'left' ? indent : 0,
      marginRight: position === 'left' ? 0 : indent,
      borderTopRightRadius: position === 'right' ? 4 : 0,
      borderTopLeftRadius: position === 'left' ? 4 : 0,
      borderBottomLeftRadius: position === 'left' ? 4 : 0,
      borderBottomRightRadius: position === 'right' ? 4 : 0,
      '&:hover': {
        cursor: onClick ? 'pointer' : 'auto',
      },

      '& svg': {
        color: 'inherit',
        marginRight: 0,
      },
    },
  };
});
