import { Typography } from '@mui/material';
import { faCommentAltDots, faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { LoadingButton } from 'components/Buttons';
import { APInput } from 'components/APInput';
import FormTitle from '../../../components/Molecules/Auth/FormTitle';
import useCustomMultifactorAuth from '../../../hooks/useCustomMultifactorAuth';
import routes from '../../../routes.path';
import useFormStyles from '../form.styles';
import { IOnboarding, useOnboardingContext } from '../Onboarding';
import { IReturning } from '../Returning';

interface IProps extends IOnboarding, IReturning {
  mfaImage?: 'authy' | 'sms',
}

const onboardingDescription = (mfaImage?: 'authy' | 'sms', phoneNumber?: string): string | JSX.Element => {
  const description = 'Finally, please use your selected authentication method to verify your mobile device.';
  if (!mfaImage) return description;
  return (
    <>
      {description}
      <br />
      <div style={{ marginTop: '16px' }}>
        {
          mfaImage === 'sms' ? <FontAwesomeIcon icon={faCommentAltDots} style={{ marginRight: '8px' }} />
            : (
              <img
                src="/static/images/authyIcon.png"
                alt="Authy Icon"
                style={{
                  borderRadius: '50%',
                  width: '18px',
                  position: 'relative',
                  top: '4px',
                  marginRight: '8px',
                }}
              />
            )
        }
        Please enter the 2FA code sent to
        {' '}
        {phoneNumber}
      </div>
    </>
  );
};

const VerifyMfa: React.FC<IProps> = ({ onboarding, userObject, mfaImage }: IProps) => {
  const formClasses = useFormStyles();

  const { formik, loading } = useCustomMultifactorAuth(userObject, onboarding);
  const { handleSetLoaderPercentage } = useOnboardingContext();

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      handleSetLoaderPercentage(100);
    }
  }, []);
  return (
    <div>
      <FormTitle
        number={onboarding ? 4 : undefined}
        title="Please enter the code from your chosen verification method"
        description={onboarding && mfaImage
          ? onboardingDescription(mfaImage, userObject.challengeParam?.CODE_DELIVERY_DESTINATION)
          : undefined}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className={formClasses.section}>
          <Typography className={formClasses.label}>2FA Code</Typography>
          <APInput
            autoComplete="off"
            testid="mfa"
            autoFocus
            id="mfa"
            name="mfa"
            placeholder="Enter your 2FA code"
            variant="filled"
            type="text"
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.mfa}
            error={formik.touched.mfa && Boolean(formik.errors.mfa)}
            helperText={formik.touched.mfa && formik.errors.mfa}
            style={{ marginBottom: '0px' }}
          />
          <NavLink
            to={onboarding ? routes.auth.onboarding.mfaOptions : routes.auth.mfaOptions}
            className={formClasses.formBacklink}
          >
            Haven&rsquo;t received your code yet?
          </NavLink>
        </div>
        <LoadingButton
          type="submit"
          testId="submit-mfa"
          fullWidth
          loading={loading}
          disabled={loading}
          size="large"
          startIcon={<FontAwesomeIcon icon={faLockAlt} />}
        >
          AUTHORISE
        </LoadingButton>
        <div className={formClasses.formBacklink}>
          {
            !onboarding && <NavLink to={routes.auth.login}>Back to sign in</NavLink>
          }
        </div>
      </form>
    </div>
  );
};

export default VerifyMfa;
