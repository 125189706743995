import React, {
  FC,
  memo,
  useCallback,
  useEffect,
} from 'react';
import { Typography } from '@mui/material';
import FormNote from '../../../components/FormNote';
import {
  DividerStyled,
  StandingOrderStyled,
  StandingOrderSwitchBoxStyled,
  SwitchStyled,
} from './styles';
import RepeatDropdown from './RepeatDropdown';
import ContinueUntilDropdown from './ContinueUntilDropdown';
import Notes from './Notes';
import useSinglePaymentContext from '../../useSinglePaymentContext';

type IProps = {
  selectedDate: string;
  showStandingOrder: boolean;
  setShowStandingOrder: (value: boolean) => void;
  selectedRepeat: string | null;
  setSelectedRepeat: (value: string | null) => void;
  repeatError: boolean;
  setRepeatError: (value: boolean) => void;
}

const StandingOrder: FC<IProps> = (props) => {
  const {
    selectedDate,
    showStandingOrder,
    setShowStandingOrder,
    selectedRepeat,
    setSelectedRepeat,
    repeatError,
    setRepeatError,
  } = props;

  const { singlePaymentContext } = useSinglePaymentContext();

  const toggleShowStandingOrder = useCallback(() => {
    if (showStandingOrder) {
      singlePaymentContext?.setCurrentPayment({
        ...singlePaymentContext.currentPayment,
        numberOfPayments: undefined,
        endDate: undefined,
        frequency: undefined,
      });
    }
    setShowStandingOrder(!showStandingOrder);
  }, [showStandingOrder, setShowStandingOrder, singlePaymentContext]);

  useEffect(() => {
    if (!showStandingOrder) {
      setSelectedRepeat(null);
    }
  }, [showStandingOrder, setSelectedRepeat]);

  return (
    <StandingOrderStyled>
      <StandingOrderSwitchBoxStyled>
        <div>
          <Typography variant="subtitle1" className="label" fontSize={14}>
            Repeat payment
          </Typography>
          <FormNote
            text="Set up a Standing Order"
            onClick={toggleShowStandingOrder}
            style={{ cursor: 'pointer', marginTop: 0 }}
            data-testid="standingOrder-switchLabel"
          />
        </div>
        <SwitchStyled
          focusVisibleClassName=".Mui-focusVisible"
          disableRipple
          onChange={toggleShowStandingOrder}
          checked={showStandingOrder}
          data-testid="standingOrder-switch"
        />
      </StandingOrderSwitchBoxStyled>
      {
        showStandingOrder && (
          <>
            <RepeatDropdown
              selectedDate={selectedDate}
              selectedRepeat={selectedRepeat}
              setSelectedRepeat={setSelectedRepeat}
              repeatError={repeatError}
              setRepeatError={setRepeatError}
            />
            <ContinueUntilDropdown selectedDate={selectedDate} selectedRepeat={selectedRepeat} />
            <Notes />
            <DividerStyled />
          </>
        )
      }
    </StandingOrderStyled>
  );
};

export default memo(StandingOrder);
