import React from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import AppDownload from '../../../../assets/playStoreAppStore.svg';
import PhoneAuthy from '../../../../assets/phoneAuthy.svg';
import useStyles from './index.style';

interface IMfaImage {
  mfaImage: 'authy' | 'sms' | undefined,
}

const HelperAsset: React.FC<IMfaImage> = ({ mfaImage }: IMfaImage) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={clsx('assetWrapper', mfaImage === 'sms' ? classes.fullOpacity : classes.noOpacity)}>
        <img
          className="assetImage"
          src="/static/images/phoneSms.png"
          alt="Onboarding email being displayed on a laptop and a mobile phone"
        />
        <div className="assetContentWrapper">
          <Typography className="assetTitle">
            SMS
          </Typography>
          <Typography className="assetDescription">
            Two-factor authentication codes will be delivered via SMS to your registered device.
            This is safe and secure and you do not need to download anything. Please note,
            that your device will need to have active signal to be able to receive the codes
          </Typography>
        </div>
      </div>
      <div className={clsx('assetWrapper', mfaImage === 'authy' ? classes.fullOpacity : classes.noOpacity)}>
        <img
          className="assetImage"
          src={PhoneAuthy}
          alt="Onboarding email being displayed on a laptop and a mobile phone"
        />
        <div className="assetContentWrapper">
          <Typography className="assetTitle">
            Authy
          </Typography>
          <Typography className="assetDescription">
            The fastest and most secure way to authenticate yourself within our application.
          </Typography>
          <img src={AppDownload} alt="Playstore and appstore" className="assetFooter" />
        </div>
      </div>
    </div>
  );
};

export default HelperAsset;
