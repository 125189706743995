import { makeStyles } from 'theme';

const useStyles = makeStyles(
  {
    datePicker: {
      boxShadow: 'none',
      minHeight: '45px !important',
      display: 'block',

      '& .MuiOutlinedInput-notchedOutline': { border: 0 },
      '& .MuiFormLabel-root': {
        transform: 'none',
        fontSize: 14,
        marginTop: '0px',
      },
      '& .MuiOutlinedInput-root': {
        height: '45px',
        fontWeight: '400',
        minHeight: '50px',
        backgroundColor: '#f7f7f7',
        width: '100%',
      },
      '& svg': {
        width: '20px !important',
        left: '0px',
        opacity: '0',
        top: '12px',
      },
      '& legend': {
        display: 'none',
      },
    },
  },
  { name: 'paymentDate' },
);

export default useStyles;
