import { Button, Typography } from '@mui/material';
import { Stat } from 'components/Stat';
import React from 'react';
import clsx from 'clsx';
import { CurrencyHeader } from '../../../../../../components/International';
import { TCurrencyAccount } from '../../../../../../models/currencyAccounts';
import { TUserAccount } from '../../../../../../models/user';
import { formatCurrency } from '../../../../../../utils/currency.helpers';
import ClearDebitingModal from './ClearDebitingModal/ClearDebitingModal';
import useStyles from './FundingAccountSummary.styles';
import FormTitle from '../../components/FormTitle';
import usePaymentsContext from '../../ManualPayment/usePaymentsContext';
import useSinglePaymentContext from '../../ManualPayment/useSinglePaymentContext';

interface IProps {
  selectedFundingAccount: TCurrencyAccount;
  selectedEntity: TUserAccount;
  handleChangeFundingAccount: () => void;
  open: boolean;
  handleModalOpen: (open: boolean) => void;
  handleSubmitButton: () => void;
}

const FundingAccountSummary: React.FC<IProps> = (props: IProps) => {
  const {
    selectedFundingAccount,
    selectedEntity,
    handleChangeFundingAccount,
    open,
    handleModalOpen,
    handleSubmitButton,
  } = props;
  const styles = useStyles();
  const { paymentsContext } = usePaymentsContext();
  const { singlePaymentContext } = useSinglePaymentContext();

  const canShowChangeButton = !paymentsContext.isStandingOrder
    || (paymentsContext.isStandingOrder && singlePaymentContext?.editRow !== undefined);

  return (
    <>
      <div className={clsx(styles.root, 'dd-privacy-allow')}>
        <div className="currencyHeader">
          <FormTitle text="From" />
          <Typography className={styles.entityHeader}>
            {selectedEntity.name}
          </Typography>
          <div className="currencyHeader-inner">
            <div>
              <CurrencyHeader
                currencyCode={selectedFundingAccount.currencyCode || ''}
                currency={selectedFundingAccount.currency || ''}
                account={selectedFundingAccount.friendlyName}
              />
            </div>
            {
              canShowChangeButton && (
                <Button onClick={handleChangeFundingAccount} className={styles.changeButton} data-testid="change-entity-button">
                  Change
                </Button>
              )
            }
          </div>
        </div>
        <div className="stats">
          <Stat
            title="Cleared Balance"
            value={formatCurrency(
              selectedFundingAccount.currencyCode!,
              selectedFundingAccount.clearedBalance,
            )}
          />
          <div className="boldStat">
            <Stat
              title="Available Balance"
              value={formatCurrency(
                selectedFundingAccount.currencyCode!,
                selectedFundingAccount.availableBalance,
              )}
            />
          </div>
        </div>
      </div>
      <ClearDebitingModal
        open={open}
        handleModalOpen={handleModalOpen}
        handleSubmitButton={handleSubmitButton}
      />
    </>
  );
};

export default FundingAccountSummary;
