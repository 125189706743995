import theme, { makeStyles } from 'theme';

const { palette } = theme;

export const useStyles = makeStyles(
  () => ({
    container: {
      '& > div': {
        marginBottom: '24px',
      },
    },
    fieldLabel: {
      marginBottom: '6px',
    },
    requiredFieldLabel: {
      color: palette.error.main,
      marginBottom: '6px',
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& > div': {
        width: '48%',
      },
    },
    entityElement: {
      borderRadius: '16px',
      background: '#F7F7F7',
      fontWeight: 500,
      padding: '8px 16px',
      width: 'max-content',
      marginTop: '12px',
      fontSize: '16px',
    },
  }),
  { name: 'CreateBeneficiaryDrawer' },
);

export default useStyles;
