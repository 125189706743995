import React, { FC, useMemo } from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { ICheckboxProps } from './Checkbox.interfaces';
import StyledFormControlLabel from './Checkbox.styles';

export const Checkbox: FC<ICheckboxProps> = (props) => {
  const {
    label, labelPlacement, classes, fullWidth, testId, inputProps, ...rest
  } = props;
  const control = (
    <MuiCheckbox
      inputProps={useMemo(
        () => ({
          ...inputProps,
          'data-testid': testId,
        }),
        [inputProps, testId],
      )}
      color="primary"
      {...rest}
    />
  );

  return label ? (
    <StyledFormControlLabel
      control={control}
      label={label}
      labelPlacement={labelPlacement}
      classes={classes}
      fullWidth={fullWidth}
    />
  ) : (
    control
  );
};
