import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@mui/styled-engine';
import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    documentsCol: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    documentsBox: {
      background: '#f5f5f5',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 5px',
      minWidth: 58,
      boxSizing: 'border-box',
      fontSize: 11,
    },
    documentsIcon: {
      marginRight: 10,
    },
    viewButton: {
      border: '1px solid #d8e2ee',
    },
  }),
  { name: 'PaymentsTable' },
);

export default useStyles;

export const IconSOStyled = styled(FontAwesomeIcon)({
  fontSize: 12,
  marginRight: 5,
});
