import React, { FC, memo, useCallback } from 'react';
import { faFile, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import useStyles from './styles';

type IProps = {
  filesList: File[];
  removeFile: (index: number) => void;
};

const FilesList: FC<IProps> = ({ filesList, removeFile }) => {
  const styles = useStyles();

  const formatName = useCallback((fileName: string) => {
    const fileExtension = fileName.split('.').pop() || '';
    const fileNameWithoutExtension = fileName.replace(fileExtension, '');

    return fileNameWithoutExtension + fileExtension.toUpperCase();
  }, []);

  const getFileSize = useCallback((file: File) => {
    const sizeInKB = file.size / 1024;
    const showKB = sizeInKB < 1024;
    const sizeToShow = Math.ceil(showKB ? sizeInKB : sizeInKB / 1024);
    const sizeText = `${sizeToShow} ${sizeInKB < 1024 ? 'KB' : 'MB'}`;

    return sizeText;
  }, []);

  const openPreview = useCallback((file: File) => () => {
    const url = URL.createObjectURL(file);

    window.open(url, '_blank')?.focus();
  }, []);

  return (
    <>
      {
        filesList.map((file, index) => (
          <div key={index} className={styles.file}>
            <div className={styles.fileInfo}>
              <FontAwesomeIcon
                size="lg"
                icon={faFile}
                className={styles.fileIcon}
              />
              <div>
                <Typography className={styles.fileName} onClick={openPreview(file)}>
                  {
                    formatName(file.name)
                  }
                </Typography>
                <Typography className={styles.fileSize}>
                  {
                    getFileSize(file)
                  }
                </Typography>
              </div>
            </div>
            <Button className={styles.removeFile} onClick={() => removeFile(index)}>
              <FontAwesomeIcon
                size="lg"
                icon={faTrashAlt}
              />
            </Button>
          </div>
        ))
      }
    </>
  );
};

export default memo(FilesList);
