import { Table, TableRow } from '@mui/material';
import styled from '@mui/styled-engine';
import _default from 'theme';

const { typography, rebrandPalette, palette } = _default;

export const StyledTable = styled(Table)(({ rebrand, funding }: { props: any }) => ({
  '& th.MuiTableCell-root, td.MuiTableCell-root': rebrand
    ? {
        fontSize: '14px',
        padding: '16px 0',
        letterSpacing: '0px',
        color: rebrandPalette.secondary.main,
        fontFamily: typography.fontFamilyRebrand,
      }
    : {
        fontSize: '14px',
        height: '54px',
        padding: '16px 0',
      },
  '& th.MuiTableCell-head': rebrand
    ? {
        textTransform: 'capitalize',
        fontSize: '13px',
        fontFamily: typography.fontFamilyRebrand,
        padding: '16px 0',
        letterSpacing: '0px',
        color: '#999999',
        opacity: 1,
      }
    : {
        textTransform: 'uppercase',
        fontSize: '11px',
        fontWeight: typography.fontWeightSemiBold,
        padding: '14px 0',
        letterSpacing: '1.65px',
      },
  '& th.paymentTitle': {
    paddingRight: funding && '4%',
  },
  '& td.currencyAmount': {
    padding: '16px 0',
    '& > span': {
      display: 'flex',
      width: '70%',
      '& > span:first-of-type': {
        width: '32px',
        marginRight: '16px',
      },
      '& > span:last-of-type': {
        fontWeight: typography.fontWeightBold,
      },
    },
  },
  '& td.currencyFlag': {
    paddingLeft: '20px',
  },
  '& td.currencyPayments': {
    paddingRight: funding ? '4%' : '2%',
  },
  '& td.paymentAmount': {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: '12px',
    },
  },
  '& td.paymentDate': {
    '& > svg': {
      marginRight: '7px',
    },
  },
  color: rebrand ? rebrandPalette.primary.main : palette.primary.main,
  fontSize: '14px',
  marginBottom: '30px',
  keyValueRow: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '& .bold': {
      fontWeight: typography.fontWeightSemiBold,
    },
    '& > .MuiTableCell-root.MuiTableCell-body': {
      padding: '12px 0px',
      height: '100%',
      border: 'none',
      fontSize: '13px',
    },
  },
  buttons: {
    '& a': {
      display: 'inline-block',
    },
    '& > button:not(:last-child), & > a:not(:last-child)': {
      marginRight: 5,
    },
  },
  text: {},
}));

export const StyledCurrencyTableRow = styled(TableRow)(({ selected }: { selected: boolean }) => ({
  ...(selected && {
    backgroundColor: 'rgba(9, 131, 117, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(9, 131, 117, 0.25)',
    },
  }),
  cursor: 'pointer',
}));

export const StyledPaymentTableRow = styled(TableRow)(
  ({ rebrand, highlighted }: { rebrand: boolean; highlighted?: boolean }) => ({
    color: rebrand ? rebrandPalette.primary.main : palette.primary.main,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#FCFCFC',
    },
    ...(highlighted && { background: 'rgba(9, 131, 117, 0.08)' }),
    cursor: 'pointer',
  }),
);

export const StyledKeyValueTableRow = styled(TableRow)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
  '&:last-of-type': {
    borderBottom: 'none',
  },
  '& .bold': {
    fontWeight: typography.fontWeightSemiBold,
  },
  '& > .MuiTableCell-root.MuiTableCell-body': {
    padding: '12px 0px',
    height: '100%',
    border: 'none',
    fontSize: '13px',
  },
}));
