import { Typography } from '@mui/material';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const EmptyPaymentsTable = () => (
  <div className="emptyTable">
    <div className="wrapper">
      <div className="circle">
        <FontAwesomeIcon icon={faPlus} />
      </div>
      <Typography variant="h5">No payments in batch</Typography>
      <Typography variant="subtitle1">
        Create payments and add to batch above
      </Typography>
    </div>
  </div>
);

export default EmptyPaymentsTable;
