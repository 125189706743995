import styled from '@mui/styled-engine';

const StyledCustomSideBar = styled('div')({
  width: 135,
  display: 'block',
  backgroundColor: '#F7F7F7',
  padding: 15,
  '& .dateHeader': {
    color: 'rgba(34,34,34,0.5)',
    fontSize: 14,
    display: 'block',
  },
  '& .dateBody': {
    color: '#222222',
    fontSize: 22,
    fontWeight: 300,
  },
});
export default StyledCustomSideBar;
