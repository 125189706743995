import getSymbolFromCurrency from 'currency-symbol-map';

export const formatNumber = (value: number, decimalPlaces: number) => value.toLocaleString('un-US', {
  minimumFractionDigits: decimalPlaces,
  maximumFractionDigits: decimalPlaces,
});
export const formatCurrency = (currency: string | undefined, value: number | undefined) => {
  if (currency && (value || value === 0)) {
    return `${getSymbolFromCurrency(currency)}${formatNumber(value, getCurrencyDecimalPlaces(currency || ''))}`;
  } return '-';
};

export const getCurrencyDecimalPlaces = (currencyCode?: string): number => {
  if (!currencyCode) return 2;
  return zeroDecimalPlaceCurrencyCodes.includes(currencyCode) ? 0 : 2;
};

export const checkCurrencyDecimals = (currencyCode: string, value: string) => (
  zeroDecimalPlaceCurrencyCodes.includes(currencyCode) ? /^\d+$/.test(value) : /^\d+(\.\d{1,2})?$/.test(value)
);

export const getCurrencyPlaceholder = (currencyCode?: string) => {
  if (!currencyCode) return '0.00';
  return zeroDecimalPlaceCurrencyCodes.includes(currencyCode) ? '0' : '0.00';
};

// Our other services are not setup to support any more than two decimal places, so for
// currencies with more than 2d.p. (e.g. BHD) we only use 2 decimal places
const zeroDecimalPlaceCurrencyCodes = [
  'JPY',
  'BIF',
  'BYR',
  'CLP',
  'DJF',
  'GNF',
  'GWP',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];
