import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useBannerRefresh from 'hooks/useBannerRefresh';
import Alert from 'components/Alert/Alert';
import KycModal from 'domain/OutstandingActionsDashboard/KYCModal/KycModal';
import ProfileStatusModal from 'domain/OutstandingActionsDashboard/ProfileStatusModal/ProfileStatusModal';
import ProfileStatusAlert from 'domain/OutstandingActionsDashboard/ProfileStatusModal/ProfileStatusAlert';
import useStyles from './index.styles';

const KYCBannerComponent: React.FC = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [profileModalOpen, setProfileModalOpen] = useState<boolean>(false);

  const classes = useStyles();
  let text;

  const {
    getBannerVariant,
    frozen,
    overdue,
    due,
    variant,
    incomplete,
  } = useBannerRefresh();

  useEffect(() => {
    getBannerVariant();
  }, [frozen, overdue, due, incomplete, getBannerVariant]);

  const calculateBannerText = () => {
    switch (variant) {
      case 'error':
        text = 'One or more of your entities are frozen due to an overdue periodic KYC review. You are not able to make payments from these entities, please contact client services.';
        break;
      case 'warning':
        text = 'One or more of your entities are overdue for a periodic KYC review. These entities are at risk of being frozen, please contact client services.';
        break;
      case 'info':
        text = 'One or more of your entities is coming up to the deadline for a periodic KYC review. If you do not meet the deadline then these entities may be frozen, please contact client services.';
        break;
      default:
        text = '';
        break;
    }
    return text;
  };

  const bannerContent = (
    <Box className={classes.text}>
      <Typography className={classes.subtitle}>
        {calculateBannerText()}
      </Typography>
      <button
        type="button"
        data-testid="learn-more-button"
        className={classes.learnMore}
        onClick={() => setOpen(true)}
      >
        <Typography className={classes.learnMore}>
          Learn more
        </Typography>
      </button>
    </Box>
  );

  return (
    <>
      {
        (incomplete.length > 0) && (
          <>
            <ProfileStatusAlert setProfileModalOpen={setProfileModalOpen} />
            <ProfileStatusModal
              entities={incomplete}
              modalOpen={profileModalOpen}
              onClose={setProfileModalOpen}
            />
          </>
        )
      }
      {((frozen.length > 0 || overdue.length > 0 || due.length > 0) && variant)
      && (
      <>
        <Alert data-dd-privacy="allow" variant={variant} text={bannerContent} />
        <KycModal
          due={due}
          overdue={overdue}
          frozen={frozen}
          modalOpen={open}
          onClose={setOpen}
        />
      </>
      )}
    </>
  );
};

export default KYCBannerComponent;
