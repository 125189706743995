import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  root: {
    marginTop: '64px',

    '& > hr': {
      margin: '8px 0 16px 0',
    },
  },
  hr: {
    border: 'none',
    backgroundColor: '#70707026',
    height: '1px',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}), { name: 'ReferenceStyles' });

export default useStyles;
