import { BankingFieldWithValidation, ValidateBeneficiaryRequest } from '@alpha/bene-dtos';
import { StyledDivider } from 'components/StyledDivider';
import { StyledTabsWrapper } from 'components/Tabs';
import { Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { camelCase } from 'lodash';
import React, { ReactNode } from 'react';
import BackdropLoader from '../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import InputAndLabel from '../../../../../components/Inputs/Beneficiary/InputAndLabel';
import EmptyTable from '../../../../../components/Table/EmptyTable/EmptyTable';
import ExtraBankDetails from '../ExtraBankDetails';
import usePaymentMethods from './usePaymentMethods';
import formatBankCode from '../../../../../utils/formatBankCode';
import InfoModal from '../../../../../components/Modals/InfoModal';
import useToggle from '../../../../../hooks/useToggle';
import { useStyles } from './styles';

interface IProps {
  form: FormikProps<ValidateBeneficiaryRequest>;
  setFormValidation: React.Dispatch<React.SetStateAction<any>>;
  error?: string;
}

const PaymentMethods: React.FC<IProps> = ({ form, setFormValidation, error }: IProps) => {
  const {
    loading,
    availablePaymentMethods,
    tabTitles,
    tab,
    bankCountryCode,
    currencyCode,
    bothBankFieldsRequired,
    handleDynamicValidationTabChange,
  } = usePaymentMethods(form, setFormValidation);
  const swiftModal = useToggle(false);
  const styles = useStyles();

  const handleChange = (e: any) => {
    form.setFieldValue('nationalBankCode', e.target.value.replaceAll(/[-\s]/g, ''));
  };

  if (loading) {
    return (
      <BackdropLoader testId="loading" />
    );
  }

  if ((!availablePaymentMethods && !loading) || (!bankCountryCode || !currencyCode)) {
    return (
      <EmptyTable
        title="Please select a currency and bank country"
        subtitle="To view payment options for this beneficiary, please select a currency and bank country"
        width="80%"
      />
    );
  }

  if (!availablePaymentMethods) return <></>;

  const labelDescriptions: { [id:string]: ReactNode; } = {
    SWIFT: (
      <span className={styles.swiftHint}>
        Must be 8 - 11 Characters.
        {' '}
        <button
          type="button"
          className={styles.swiftLearnMoreButton}
          onClick={swiftModal.open}
        >
          Learn More
        </button>
      </span>
    ),
  };

  const labelOverrides: { [id:string]: string; } = {
    SWIFT: 'SWIFT / BIC',
    'National Bank Code': availablePaymentMethods.nationalBankCodeLabel,
  };

  return (
    <div>
      <StyledTabsWrapper
        testId="dynamicTabs"
        tabTitles={tabTitles}
        tabIndex={tab === 'international' ? 0 : 1}
        handleChange={handleDynamicValidationTabChange}
      />
      <StyledDivider />
      <Typography variant="subtitle1" style={{ marginBottom: '16px', marginTop: '16px', ...(bothBankFieldsRequired && { color: 'rgb(185, 86, 86)' }) }}>
        {bothBankFieldsRequired ? 'You have selected entities that have different jurisdictions. To create this beneficiary, you will require both international and local account details.' : `To send ${form.values.bankCountryCode} ${tab} you will need the following information: `}
      </Typography>
      {
        (availablePaymentMethods[tab] as BankingFieldWithValidation[]
        ).map((bfwv) => {
          const fieldNameToCamelCase = camelCase(bfwv.field);
          return (
            <div style={{ marginBottom: '20px' }}>
              <InputAndLabel
                label={(bfwv.field in labelOverrides) ? labelOverrides[bfwv.field] : bfwv.field}
                labelDescription={labelDescriptions[bfwv.field]}
                name={fieldNameToCamelCase}
                id={fieldNameToCamelCase}
                testId={fieldNameToCamelCase}
                onChange={bfwv.field === 'National Bank Code' ? handleChange : form.handleChange}
                onBlur={form.handleBlur}
                value={bfwv.field === 'National Bank Code' ? formatBankCode(form.values.nationalBankCode || '', form.values.bankCountryCode) : (form as any).values[fieldNameToCamelCase] as any}
                error={
                    (form as any).touched[fieldNameToCamelCase]
                    && Boolean((form as any).errors[fieldNameToCamelCase])
                  }
                helperText={
                    (form as any).touched[fieldNameToCamelCase]
                    && (form as any).errors[fieldNameToCamelCase]
                  }
              />
            </div>
          );
        })
      }
      <ExtraBankDetails form={form} error={error} />
      <InfoModal
        title="SWIFT / BIC"
        content={(
          <span>
            A SWIFT / BIC (SWIFT Business Identifier Code) identifies the
            <br />
            overseas bank you&apos;re sending payments to.
            <br />
            <br />
            To accurately send payments to the beneficiary bank, ensure
            <br />
            the correct SWIF / BIC is provided.
          </span>
        )}
        open={swiftModal.isOpen}
        handleClose={swiftModal.close}
      />
    </div>
  );
};

export default PaymentMethods;
