import React from 'react';
import { TCurrencyAccount } from '../../../../../models/currencyAccounts';
import { TPayment } from '../../../../../models/payments';
import BatchSubmissionFooter from '../../../BatchSummary/FooterContainer/BatchSubmissionFooter/BatchSubmissionFooter';
import useFooter from './useFooter';

export interface IFooterProps {
    singleValidPayment: boolean,
    currentPayment: TPayment,
    currentPayments: TPayment[],
    selectedDebitingAccount: TCurrencyAccount | undefined,
    singleTab: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const Footer: React.FC<IFooterProps> = ({
  singleValidPayment,
  currentPayment,
  currentPayments,
  selectedDebitingAccount,
  singleTab,
  setLoading,
}: IFooterProps) => {
  const { authorized, handleExitClick, handleSubmitPayment } = useFooter({
    currentPayment,
    singleValidPayment,
    selectedDebitingAccount,
    currentPayments,
    singleTab,
    setLoading,
  });

  const buttonText = singleTab ? 'Submit payment' : `Submit batch (${currentPayments.length})`;

  return (
    <BatchSubmissionFooter
      handleExitClick={handleExitClick}
      handleOnClick={handleSubmitPayment}
      buttonText={buttonText}
      disabled={Boolean((
        currentPayments.length === 0
                && !singleValidPayment)
                || !authorized)}
    />
  );
};

export default Footer;
