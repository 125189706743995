import React, { FC, memo, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBaseButtonProps } from '../BaseButton';
import StyledIconActionButton from './IconActionButton.styles';

export interface IIconActionButtonProps extends Omit<IBaseButtonProps, 'color'> {
    icon: any;
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'warning' | 'success';
}

const IconActionButton: FC<IIconActionButtonProps> = memo(
  forwardRef((props, ref) => {
    const {
      className, icon, color = 'primary', ...rest
    } = props;

    return (
      <StyledIconActionButton
        color={color}
        className={className}
        ref={ref}
        variant="outlined"
        {...rest}
      >
        <FontAwesomeIcon icon={icon} />
      </StyledIconActionButton>
    );
  }),
);

export default IconActionButton;
