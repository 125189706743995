import React, { FC } from 'react';
import { faRedoAlt } from '@fortawesome/pro-light-svg-icons';
import { TPayment } from 'models/payments';
import {
  DefinitionStyled,
  DrawerBodyStyled,
  DrawerSubtitleStyled,
  FrequencyIconStyled,
} from 'domain/PaymentsMultiEntity/Dashboard/ScheduledTableContainer/styles';
import Status from 'components/Status/Status';
import { PaymentBatchDraftStatus, PaymentBatchStatus } from '@alpha/payments-dtos';
import { flowToBatchStatusMapping } from 'utils/batchStatus.helpers';
import formatIsoDate from 'utils/formatIsoDate';

type IProps = {
  item: TPayment;
  formatFrequency: (frequency: string, date: string) => string | JSX.Element;
  fundingAccount: string;
  debitingAccount?: string;
  status?: PaymentBatchDraftStatus | PaymentBatchStatus;
}

const getClientFriendlyBatchStatus = (
  backendBatchStatus: PaymentBatchDraftStatus | PaymentBatchStatus,
) => {
  switch (true) {
    case backendBatchStatus === PaymentBatchDraftStatus.INPROGRESS:
    case backendBatchStatus === PaymentBatchStatus.PROCESSING:
      return 'In Progress';
    case backendBatchStatus === PaymentBatchDraftStatus.COMPLETE:
      return 'complete';
    case backendBatchStatus === PaymentBatchDraftStatus.APPROVED:
      return 'pending fx';
    case backendBatchStatus === PaymentBatchDraftStatus.REJECTED:
      return 'rejected';
    case flowToBatchStatusMapping.uploader.includes(backendBatchStatus):
      return 'Draft';
    default:
      return backendBatchStatus;
  }
};

const handleBatchStatusVariant = (
  backendBatchStatus: PaymentBatchDraftStatus | PaymentBatchStatus,
) => {
  switch (true) {
    case backendBatchStatus === PaymentBatchDraftStatus.APPROVED:
      return 'orange';
    case backendBatchStatus === PaymentBatchDraftStatus.REJECTED:
      return 'error';
    case backendBatchStatus === PaymentBatchDraftStatus.INPROGRESS:
    case backendBatchStatus === PaymentBatchStatus.PROCESSING:
      return 'warning';
    case backendBatchStatus === PaymentBatchDraftStatus.COMPLETE:
      return 'success';
    default:
      return 'default';
  }
};

const PaymentsDrawer: FC<IProps> = ({
  item,
  formatFrequency,
  fundingAccount,
  debitingAccount,
  status,
}) => {
  const batchStatus = status ? getClientFriendlyBatchStatus(status) : 'DRAFT';
  const batchStatusVariant = status ? handleBatchStatusVariant(status) : 'grey';
  const currency = item.fixedSide
    || item.beneficiary?.currencyCode
    || item.beneficiaryDraft?.currencyCode;

  return (
    <DrawerBodyStyled>
      <DrawerSubtitleStyled variant="subtitle1">Payment details</DrawerSubtitleStyled>
      <div data-testid="scheduled-drawer-paymentDetails" className="noPadding">
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-batch-status">Batch status</p>
          <span data-testid="drawer-batch-status-value">
            <Status variant={batchStatusVariant}>
              { batchStatus }
            </Status>
          </span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-payment-date">Payment date</p>
          <span data-testid="drawer-payment-date-value">{ formatIsoDate(item.paymentDate) }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-frequency">Frequency</p>
          <span data-testid="drawer-frequency-value">
            <FrequencyIconStyled icon={faRedoAlt} />
            { formatFrequency(item.frequency, item.startDate) }
          </span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-repeat-until">Repeat until</p>
          <span data-testid="drawer-repeat-until-value">{ item.endDate ? formatIsoDate(item.endDate) : 'Continuous' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-payment-ref">Payment reference</p>
          <span data-testid="drawer-payment-ref-value">{ item.reference || '-' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-purpose-of-payment">Purpose of payment</p>
          <span data-testid="drawer-purpose-of-payment-value">{ item.purposeOfPaymentCode || item.purpose || '-' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-currency">Currency</p>
          <span data-testid="drawer-currency-value">{ currency }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-ammount-sent">Amount sent</p>
          <span data-testid="drawer-ammount-sent-value">
            { Number(item.paymentAmount || item.amount).toFixed(2) }
            &nbsp;
            { currency }
          </span>
        </DefinitionStyled>
      </div>

      <DrawerSubtitleStyled variant="subtitle1">Beneficiary details</DrawerSubtitleStyled>
      <div data-testid="scheduled-drawer-beneDetails" className="noPadding">
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-bene-name">Beneficiary name</p>
          <span data-testid="drawer-bene-value">{ item.beneficiary?.accountName || item.beneficiaryDraft?.name || '-' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-bene-bank">Beneficiary bank</p>
          <span data-testid="drawer-bene-bank-value">{ item.beneficiary?.bankName || item.beneficiaryDraft?.bankName || '-' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-swift">SWIFT/BIC</p>
          <span data-testid="drawer-swift-value">{ item.beneficiary?.swift || item.beneficiaryDraft?.swift || '-' }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-iban">IBAN</p>
          <span data-testid="drawer-iban-value">{ item.beneficiary?.iban || item.beneficiaryDraft?.iban || '-' }</span>
        </DefinitionStyled>
      </div>

      <DrawerSubtitleStyled variant="subtitle1">Paid from</DrawerSubtitleStyled>
      <div data-testid="scheduled-drawer-paidFrom" className="noPadding">
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-funding-acc">Funding account</p>
          <span data-testid="drawer-funding-acc-value">{ fundingAccount }</span>
        </DefinitionStyled>
        <DefinitionStyled variant="body1">
          <p data-testid="drawer-debiting-acc">Debiting account</p>
          <span data-testid="drawer-debiting-acc-value">{ debitingAccount }</span>
        </DefinitionStyled>
      </div>
    </DrawerBodyStyled>
  );
};

export default PaymentsDrawer;
