/* eslint-disable max-lines-per-function */
import { useState, useEffect } from 'react';
import { PaymentBatchDraftDto } from '@alpha/payments-dtos';
import { IndicativeRateResponse, SameTradeResponse } from '@alpha/fx-dtos';
import { datadogRum } from '@datadog/browser-rum';
import AuthorizationService from 'services/Authorization/authorization.service';
import { Role } from '@alpha/auth-types';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import { PaymentRouteEnum } from '../../../models/payments';
import routes from '../../../routes.path';
import InterAccountTransferService from '../../../services/InterAccountTransfer/interAccountTransfer.service';
import PaymentsService from '../../../services/Payments/payments.service';
import FXTransferService from '../../../services/FXTransfer/fxTransfer.service';
import { formatNumber } from '../../../utils/currency.helpers';
import { BatchQuoteDtoDecorated, IBatchCurrencyPairRateDecorated } from './Funding.interface';
import history from '../../../services/history/browserHistory';
import useAuthorizationMultiEntity from '../../../hooks/useAuthorizationMultiEntity';
import { TUserAccount, UserRole } from '../../../models/user';
import navigateToStartOfJourney from '../../../utils/navigateToStartOfJourney';

interface IProps {
  batchId: string;
  accountId: string;
}

const useFunding = (props: IProps) => {
  const { batchId, accountId } = props;
  const snackbar = useAlphaSnackbar();
  const { hasRole, loading: profileLoading } = useAuthorizationMultiEntity(accountId);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
  const [errGettingIndiciativeRate, setErrGettingIndiciativeRate] = useState<boolean>(false);
  const [batchDetails, setBatchDetails] = useState<
    PaymentBatchDraftDto | undefined
  >();
  const [crossCurrencyPairs, setCrossCurrencyPairs] = useState<
    IBatchCurrencyPairRateDecorated[]
  >([]);
  const [liveRates, setLiveRates] = useState<BatchQuoteDtoDecorated[]>([]);
  const [bookFxErrored, setBookFxErrored] = useState<boolean>(false);
  const [disableLiveRateBtn, setDisableLiveRateBtn] = useState<boolean>(false);
  const [disableBookFxBtn, setDisableBookFxBtn] = useState<boolean>(false);
  const liveRateAvailableTime = 100; // timer to action live rates, represents 10 seconds
  const [timer, setTimer] = useState<number>(liveRateAvailableTime);
  const [displayPairs, setDisplayPairs] = useState<
    IBatchCurrencyPairRateDecorated[]
  >([]);
  const [duplicateTradesDetails, setDuplicateTradeDetails] = useState<SameTradeResponse[]>([]);
  const [checkedForDuplicates, setCheckedForDuplicates] = useState<boolean>(false);
  const [fxApprover, setFxApprover] = useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<TUserAccount>();
  const [lineByLineRates, setLineByLineRates] = useState<Record<string, number>>({});

  const getUserFxApprover = () => {
    setFxApprover(hasRole(UserRole.FX_IAT));
  };

  const getBatchDetails = async (): Promise<PaymentBatchDraftDto | void> => {
    try {
      return (await PaymentsService.getBatchDetails(
        batchId,
        PaymentRouteEnum.FUNDING,
        accountId,
      ) as PaymentBatchDraftDto);
    } catch (e) {
      return snackbar.trigger(
        `There was an error getting batch details (${batchId}). ${e.message}.`,
      );
    }
  };

  const getIndicativeRate = async (
    debitingCurrencyCode: string,
    creditingCurrencyCode: string,
    date?: string,
    fixedSide?: string,
    amount?: number,
  ): Promise<IndicativeRateResponse | void> => {
    try {
      return await InterAccountTransferService.getRate({
        debitingCurrencyCode,
        creditingCurrencyCode,
        amount,
        accountId,
        fixedSide,
        date,
        batchId,
      });
    } catch (error) {
      let errorMessage = 'There was an error getting indicative rate';
      setErrGettingIndiciativeRate(true);
      if (error?.response?.data?.error) {
        errorMessage = error?.response?.data?.error;
        if (errorMessage.toLowerCase().includes('please retry')) {
          setErrGettingIndiciativeRate(false);
        }
      }
      return snackbar.trigger(errorMessage);
    }
  };

  const requestLiveRates = async (): Promise<BatchQuoteDtoDecorated[] | void> => {
    try {
      return await FXTransferService.getLiveRates(batchId, accountId);
    } catch (error) {
      const errorMessage = error?.response?.data?.error || 'There was an error getting live rates';
      return snackbar.trigger(errorMessage);
    }
  };

  const handleNavigationToBatchSummary = (): void => {
    history.push(routes.payments.multientity.base);
  };

  const handleRequestLiveRates = async (): Promise<void> => {
    const liveRatesResponse = await requestLiveRates();
    if (liveRatesResponse) {
      setLiveRates(liveRatesResponse);
    }
    setDisableLiveRateBtn(false);
  };

  const handleCheckSameTrade = async (): Promise<void> => {
    setDisableLiveRateBtn(true);
    if (!checkedForDuplicates) {
      try {
        const duplicateTradeResponse = await FXTransferService.checkSameTrade(batchId, accountId);
        if (duplicateTradeResponse && duplicateTradeResponse.length > 0) {
          return setDuplicateTradeDetails(duplicateTradeResponse);
        }
      } catch (error) {
        snackbar.trigger(error?.response?.data?.error || 'There was an error checking for possible duplicate trades');
      }
    }
    return handleRequestLiveRates();
  };

  const handleBookNow = async () => {
    setLoadingScreen(true);
    const liveRatesResponse = await requestLiveRates();
    if (liveRatesResponse) {
      const newDisplayPairs = liveRatesResponse.map((liveRate) => {
        // eslint-disable-next-line arrow-body-style
        const crossCurrencyEquivalent = crossCurrencyPairs.filter(
          (crossCurrencyPair: any) => (
            crossCurrencyPair.sellCurrency === liveRate.sellCurrency
            && crossCurrencyPair.buyCurrency === liveRate.buyCurrency
            && crossCurrencyPair.fixedSide === liveRate.fixedSide
          ),
        )[0];
        const pairWithLiveRate = {
          ...crossCurrencyEquivalent,
          rate: formatNumber(liveRate.rate, 4) as unknown as number,
          instructionId: liveRate.instructionId,
        };
        return pairWithLiveRate;
      });
      await handleFxBooking(newDisplayPairs);
    }
  };

  const handleLiveRateReset = () => {
    setTimeout(() => setTimer(liveRateAvailableTime), 300);
    setLiveRates([]);
  };

  const handleFxBooking = async (pairs: IBatchCurrencyPairRateDecorated[]) => {
    const newDisplayPairs = pairs || displayPairs;

    try {
      setDisableBookFxBtn(true);
      const instructionIds = newDisplayPairs.map((pair) => pair.instructionId!);
      const response = await FXTransferService.postTrades(batchId, instructionIds, accountId);
      if (response.status === 201) {
        datadogRum.addAction('PAYMENT IS BEING FUNDED');
        snackbar.trigger(
          `Your batch (${batchDetails!.friendlyBatchId}) is being funded.`,
          'success',
        );
        setLoadingScreen(true);
        setTimeout(() => {
          navigateToStartOfJourney();
          setDisableBookFxBtn(false);
          setLoadingScreen(false);
          handleLiveRateReset();
        }, 3000);
      }
    } catch (e) {
      setBookFxErrored(true);
      handleLiveRateReset();
      setLoadingScreen(false);
      setDisableBookFxBtn(false);
      snackbar.trigger(
        `Something went wrong funding your batch (${batchId}).${e?.message ? e.message : ''}`,
      );
    }
  };

  const handleAcknowledgeDuplicate = () => {
    setCheckedForDuplicates(true);
    setDisableLiveRateBtn(false);
    setDuplicateTradeDetails([]);
  };

  const updateBatchDetails = async () => {
    if (batchId !== batchDetails?.batchId) {
      setLoading(true);
      const response = await getBatchDetails();
      if (response) {
        setBatchDetails(response);
      }
      setLoading(false);
    }
  };

  const updateCrossCurrencyPairsWithIndicativeRate = async () => {
    if (batchDetails?.batchCurrencyPairs) {
      setLoading(true);
      const crossCurrPairs = batchDetails.batchCurrencyPairs.filter(
        (currencyPair: any) => currencyPair.sellCurrency !== currencyPair.buyCurrency,
      );
      const decoratedCrossCurrencyPairsPromises = crossCurrPairs.map(
        async (currencyPair: IBatchCurrencyPairRateDecorated) => {
          try {
            let valueDate: string;
            if (batchDetails.currencyValueDates === undefined) {
              valueDate = 'undefined';
            } else {
              const key = `${currencyPair.sellCurrency}${currencyPair.buyCurrency}#${currencyPair.fixedSide}`;
              valueDate = batchDetails.currencyValueDates[key];
            }

            const indicativeRate = await getIndicativeRate(
              currencyPair.sellCurrency,
              currencyPair.buyCurrency,
              valueDate,
              currencyPair.fixedSide,
              currencyPair.amount,
            ) as IndicativeRateResponse;
            return {
              ...currencyPair,
              rate: formatNumber(indicativeRate.rate, 4) as unknown as number,
              valueDate: indicativeRate.valueDate,
              lineByLineRates: indicativeRate.lineByLineRates,
            };
          } catch {
            return {
              ...currencyPair,
              rate: 0,
              valueDate: undefined,
            };
          }
        },
      );
      const decoratedCrossCurrencyPairs = await Promise.all(
        decoratedCrossCurrencyPairsPromises,
      );
      const newLineByLineRates = decoratedCrossCurrencyPairs
        .map((v) => v.lineByLineRates)
        .reduce((result, current) => Object.assign(result, current), {});
      setLineByLineRates(newLineByLineRates);
      setCrossCurrencyPairs(decoratedCrossCurrencyPairs);
      setLoading(false);
    }
  };

  const updateDisplayPairsWithLiveRate = () => {
    if (liveRates.length > 0) {
      const newDisplayPairs = liveRates.map((liveRate) => {
        const crossCurrencyEquivalent = crossCurrencyPairs.filter(
          (crossCurrencyPair: any) => (
            crossCurrencyPair.sellCurrency === liveRate.sellCurrency
            && crossCurrencyPair.buyCurrency === liveRate.buyCurrency
            && crossCurrencyPair.fixedSide === liveRate.fixedSide
          ),
        )[0];
        const pairWithLiveRate = {
          ...crossCurrencyEquivalent,
          rate: formatNumber(liveRate.rate, 4) as unknown as number,
          instructionId: liveRate.instructionId,
        };
        return pairWithLiveRate;
      });
      setDisplayPairs(newDisplayPairs);
    } else {
      setDisplayPairs(crossCurrencyPairs);
    }
  };

  const updateTimer = () => {
    if (liveRates.length > 0) {
      if (timer >= 0) {
        if (!disableBookFxBtn) setTimeout(() => setTimer(timer - 2), 200);
      } else {
        // reset timer and clear live rates after 10s is up
        handleLiveRateReset();
      }
    }
  };

  useEffect(() => {
    setDisableLiveRateBtn((errGettingIndiciativeRate || loading));
  }, [loading, errGettingIndiciativeRate]);

  useEffect(() => {
    getUserFxApprover();
  }, [profileLoading]);

  useEffect(() => {
    updateBatchDetails();
  }, [batchId]);

  useEffect(() => {
    const getUserAccounts = async () => {
      const userAccounts = await AuthorizationService.getEntitiesForRole(Role.PAYMENTS_INPUTTER);
      const result = userAccounts.find((v) => v.id === accountId);
      if (result) {
        setSelectedEntity(result);
      }
    };

    getUserAccounts();
  }, [accountId]);

  useEffect(() => {
    updateCrossCurrencyPairsWithIndicativeRate();
  }, [batchDetails?.batchCurrencyPairs]);

  useEffect(() => {
    updateDisplayPairsWithLiveRate();
  }, [liveRates, crossCurrencyPairs]);

  useEffect(() => {
    updateTimer();
  }, [liveRates, timer]);

  return {
    loading,
    loadingScreen,
    batchDetails,
    setBatchDetails,
    displayPairs,
    liveRates,
    timer,
    disableLiveRateBtn,
    disableBookFxBtn,
    bookFxErrored,
    handleNavigationToBatchSummary,
    handleRequestLiveRates,
    handleBookNow,
    handleLiveRateReset,
    handleFxBooking,
    handleCheckSameTrade,
    duplicateTradesDetails,
    handleAcknowledgeDuplicate,
    getUserFxApprover,
    fxApprover,
    selectedEntity,
    lineByLineRates,
  };
};

export default useFunding;
