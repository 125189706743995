import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import { AccountBeneficiaryPortalStatus } from '@alpha/bene-types';
import { Typography, Button } from '@mui/material';
import { Stat } from 'components/Stat';
import { StyledDivider } from 'components/StyledDivider';
import { datadogRum } from '@datadog/browser-rum';
import { faShareAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../../../../components/Alert/Alert';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import { ModalType } from '../../Body';
import useStyles from './Header.styles';
import { TStore } from '../../../../../store';

interface IProps {
  selectedBeneficiary: BeneficiaryDto;
  canShareBeneficiary: boolean;
  handleBeneficiaryShareButtonClick: (beneAccountId: string, accountId: string) => void;
  setModalOpen: React.Dispatch<React.SetStateAction<ModalType | false>>
}

const Header: React.FC<IProps> = ({
  selectedBeneficiary,
  canShareBeneficiary,
  handleBeneficiaryShareButtonClick,
  setModalOpen,
}: IProps) => {
  const classes = useStyles();
  const text = 'Before authorising this beneficiary, please ensure that you are certain the details are correct and that the payment request is genuine.';

  const statusVariant = (): 'success' | 'error' | 'default' => {
    switch (selectedBeneficiary?.portalStatus) {
      case AccountBeneficiaryPortalStatus.APPROVED:
        return 'success';
      case AccountBeneficiaryPortalStatus.REJECTED:
        return 'error';
      case AccountBeneficiaryPortalStatus.PENDING:
      default:
        return 'default';
    }
  };

  const userId = useSelector<TStore, string | undefined>(
    (store) => store.user.user?.Username,
  );

  const canDelete = (selectedBeneficiary?.userCanApproveOwn
    && selectedBeneficiary?.createdById === userId)
    || (selectedBeneficiary?.userCanApprove && selectedBeneficiary?.createdById !== userId);

  return (
    <div className={classes.header}>
      <div className={classes.headerText} data-testid="info-bene">
        <b className={classes.title}>
          {selectedBeneficiary?.friendlyName || selectedBeneficiary.name}
        </b>
        <Typography className={classes.subtitle}>
          <span>
            {selectedBeneficiary.name || selectedBeneficiary?.friendlyName}
          </span>
        </Typography>
      </div>

      {selectedBeneficiary.portalStatus !== AccountBeneficiaryPortalStatus.APPROVED && (
        <Alert variant="info" text={text} cancel />
      )}

      {!selectedBeneficiary.beneIsPayable && <Alert variant="info" text="Please call us to activate the currency account required to make payments to this beneficiary" />}

      <div className={clsx(classes.headerInfo, 'dd-privacy-allow')} data-dd-privacy="allow" data-testid="status-bene">
        <div className={classes.headerStats}>
          <Stat
            title="Status"
            value={
              selectedBeneficiary.portalStatus?.toString() || AccountBeneficiaryPortalStatus.PENDING
            }
            variant="status"
            statusVariant={statusVariant()}
          />
          <StyledDivider vertical />
          <div className={classes.countryCurrency}>
            <Stat value={selectedBeneficiary?.bankCountryCode || '-'} title="country" />
            <Stat value={selectedBeneficiary?.currencyCode || '-'} title="currency" />
          </div>
        </div>
        <div>
          {canShareBeneficiary && (
            <Tooltip title="Share beneficiary with other entities">
              <Button
                color="secondary"
                aria-label="Share beneficiary with other entities"
                className={classes.button}
                onClick={() => {
                  datadogRum.addAction('SHARE BENEFICIARY');
                  handleBeneficiaryShareButtonClick(selectedBeneficiary?.accountBeneId || '', selectedBeneficiary?.accountId || '');
                }}
                data-testid="share-button-drawer"
              >
                <FontAwesomeIcon size="lg" icon={faShareAlt} />
              </Button>
            </Tooltip>
          )}
          {selectedBeneficiary?.portalStatus === AccountBeneficiaryPortalStatus.APPROVED
           && canDelete && (
           <Button
             color="secondary"
             aria-label="Delete beneficiary"
             data-testid="delete-beneficiary"
             onClick={() => setModalOpen('delete')}
             className={classes.button}
           >
             <FontAwesomeIcon size="lg" icon={faTrashAlt} />
           </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
