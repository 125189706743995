import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    selectBeneInput: {
      width: '350px',
      cursor: 'pointer',
    },
    selectBenePlaceHolder: {
      width: '350px',
      '& > div ': {
        '& > input':
        {
          color: '#34343473!important',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '17px',
          letterSpacing: 0,
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
  }), { name: 'SelectBeneficiaryDrawer' },
);

export default useStyles;
