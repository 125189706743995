import { LinearProgress } from '@mui/material';
import { Loader } from 'components/Loader';
import { APMainLayout } from 'components/Layout/MainLayout';
import { StyledTabsWrapper, TabContent } from 'components/Tabs';
import React from 'react';
import { useParams } from 'react-router-dom';
import DrawerBackdrop from '../../../components/Drawer/DrawerBackdrop/DrawerBackdrop';
import SameTradeWarning from '../../../components/Molecules/SameTradeWarning';
import TransactionError from '../../../components/Molecules/TransactionError';
import Title from '../../../components/Title/Title';
import useFunding from '../../../hooks/useFunding';
import routes from '../../../routes.path';
import browserHistory from '../../../services/history/browserHistory';
import BatchDetails from './BatchDetails/BatchDetails';
import BookFxFooter from './BookFxFooter/BookFxFooter';
import { IProps } from './Funding.interface';
import { useStyles } from './Funding.styles';
import FxPayments from './FxPayments/FxPayments';
import RequestLiveRateFooter from './RequestLiveRateFooter/RequestLiveRateFooter';

export const testIds = {
  funding: 'funding-screen',
  fundingTab: 'funding-tab',
  loading: 'loading',
  batchStatusStat: 'batch-status-stat',
  numberOfPaymentsStat: 'number-of-payments-stat',
  backToSummaryBtn: 'back-to-summary-btn',
  requestLiveRateBtn: 'request-live-rate-btn',
  cancelBookingBtn: 'cancel-booking-btn',
  bookFxBtn: 'book-fx-btn',
  loadingLiveRate: 'loading-live-rate',
};

const Funding: React.FC<IProps> = () => {
  const { batchId } = useParams<{ batchId: string }>();

  const {
    loading,
    batchDetails,
    displayPairs,
    liveRates,
    timer,
    disableLiveRateBtn,
    disableBookFxBtn,
    bookFxErrored,
    handleLiveRateReset,
    handleFxBooking,
    handleCheckSameTrade,
    duplicateTradesDetails,
    handleAcknowledgeDuplicate,
    loadingScreen,
  } = useFunding({ batchId });
  const classes = useStyles({ liveRates: liveRates.length > 0 });
  return (
    <APMainLayout
      data-testid={testIds.funding}
      title={(
        <Title
          title="Batch funding"
          batchFileName={(batchDetails && batchDetails.sourceName) || 'Loading..'}
          batchStatus="PENDING FX"
        />
      )}
      breadCrumbs={[
        { text: 'Home', url: routes.home },
        { text: 'Payments', url: routes.payments.base },
        { text: 'Batch Approval' },
      ]}
      pageTabs={(
        <StyledTabsWrapper
          testId={testIds.fundingTab}
          tabTitles={['']}
          tabIndex={0}
          handleChange={() => { }}
        />
      )}
    >
      <TabContent index={0} value={0}>
        <div className={classes.content}>
          <BatchDetails
            noOfPayments={batchDetails?.noOfValidatedPayments}
          />
          <FxPayments
            loading={loading}
            loadingLiveRate={disableLiveRateBtn}
            displayPairs={displayPairs}
            liveRates={liveRates}
          />
        </div>
      </TabContent>
      {liveRates.length > 0 && timer > 0 && <LinearProgress variant="determinate" value={timer} />}
      {liveRates.length > 0 && timer > 0
        ? (
          <BookFxFooter
            disableBookFxBtn={disableBookFxBtn}
            handleLiveRateReset={handleLiveRateReset}
            handleFxBooking={handleFxBooking}
          />
        )
        : (
          <RequestLiveRateFooter
            handleCheckSameTrade={handleCheckSameTrade}
            disableLiveRateBtn={disableLiveRateBtn}
          />
        )}
      <DrawerBackdrop display={bookFxErrored} width="100%" backgroundColor="rgba(255, 255, 255, 0.95)">
        <TransactionError onClick={() => browserHistory.push(routes.payments.base)} />
      </DrawerBackdrop>
      <DrawerBackdrop display={duplicateTradesDetails?.length > 0} width="100%" backgroundColor="rgba(255, 255, 255, 0.95)">
        <SameTradeWarning
          paymentDetails={duplicateTradesDetails}
          onClick={handleAcknowledgeDuplicate}
        />
      </DrawerBackdrop>
      <DrawerBackdrop display={loadingScreen} width="100%" backgroundColor="rgba(255, 255, 255, 0.95)">
        <Loader testId="payment-complete-loading" />
      </DrawerBackdrop>
    </APMainLayout>
  );
};

export default Funding;
