import routes from '../routes.path';
import browserHistory from '../services/history/browserHistory';

const navigateToStartOfJourney = (): void => {
  const navigatedFrom = sessionStorage.getItem('navigated-from');
  const newRoute = navigatedFrom || routes.dashboard.base;
  browserHistory.push(newRoute);
};

export default navigateToStartOfJourney;
