import { StyledDivider } from 'components/StyledDivider';
import React, { memo } from 'react';
import {
  TCurrencyAccount,
  TCurrencyAccounts,
} from '../../../../../models/currencyAccounts';
import { IFundingAccountContainerProps } from './FundingAccountContainer';
import FundingAccountDropdown from './FundingAccountDropdown/FundingAccountDropdown';
import FundingAccountSummaryContainer from './FundingAccountSummary/FundingAccountSummaryContainer';

export interface IFundingAccountProps extends IFundingAccountContainerProps {
  currencyAccounts: TCurrencyAccounts;
  handleSetSelectedDebitingAccount: (debitingAccount: TCurrencyAccount) => void;
}
const FundingAccount: React.FC<IFundingAccountProps> = ({
  ...props
}: IFundingAccountProps) => (
  <>
    {props.selectedDebitingAccount ? (
      <>
        <FundingAccountSummaryContainer
          selectedDebitingAccount={props.selectedDebitingAccount}
          setSelectedDebitingAccount={props.setSelectedDebitingAccount}
        />
      </>
    ) : (
      <FundingAccountDropdown {...props} />
    )}
    <StyledDivider />
  </>
);

export default memo(FundingAccount);
