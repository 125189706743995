import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingLeft: 20,
  },
  pointer: {
    cursor: 'pointer',
  },
  headerIcon: {
    opacity: 0.5,
    color: '#098375',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 7,
    fontSize: 16,
    fontWeight: 500,
  },
  subHeader: {
    marginTop: 5,
    marginBottom: 0,
    paddingBottom: 15,
    fontSize: 13,
    color: '#999999',
    fontWeight: 400,
  },
  arrowIcon: {
    fontSize: 16,
    cursor: 'pointer',
    position: 'absolute',
    top: 5,
    left: 0,
    color: '#48484850',
  },
}), { name: 'CollapsibleCard' });

export default useStyles;
