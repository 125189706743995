import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: 440,
      paddingBottom: 20,
    },
    header: {
      paddingTop: 16,
      fontWeight: typography.fontWeightSemiBold,
      fontSize: 18,
      letterSpacing: -0.5,
      marginBottom: 16,
    },
    marginBottom: {
      marginBottom: 8,
    },
    marginBottomLarge: {
      marginBottom: 16,
    },
  }),
  { name: 'Profiles' },
);

export default useStyles;
