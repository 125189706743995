import { Beneficiary, BeneficiaryDraft } from '@alpha/bene-dtos';
import React, { memo, useEffect, useState } from 'react';
import BeneficiaryDrawer from '../../../../../components/Drawer/BeneficiaryDrawer/BeneficiaryDrawer';
import BeneficiariesService from '../../../../../services/Beneficiaries/beneficiaries.service';
import { PaymentSummaryBeneficiary } from '../PaymentSummary';
import generateResidentAddress from '../../../../../utils/generateResidentAddress';

export type TBeneficiaryInfoDrawer = {} & Beneficiary;
interface IProps {
  paymentSummaryBeneficiary: PaymentSummaryBeneficiary | BeneficiaryDraft;
  setPaymentSummaryBeneficiary: React.Dispatch<
    React.SetStateAction<PaymentSummaryBeneficiary>
  >;
  onClose: Function;
  open: boolean;
}

const isBeneficiaryDraft = (
  param: BeneficiaryDraft | PaymentSummaryBeneficiary,
): param is BeneficiaryDraft => {
  if ((param as BeneficiaryDraft).batchLineNumber) {
    return true;
  }
  return false;
};

function mapPaymentSummaryBeneficiaryToBeneficiary(
  paymentSummaryBeneficiary: BeneficiaryDraft,
): Beneficiary {
  return {
    id: paymentSummaryBeneficiary.id,
    accountBeneId: '',
    beneficiaryId: paymentSummaryBeneficiary.id,
    accountNumber: paymentSummaryBeneficiary.accountNumber!,
    approvedBy: paymentSummaryBeneficiary.approvedBy!,
    approvedByDateTime: paymentSummaryBeneficiary.approvedByDateTime!,
    bankCountryCode: paymentSummaryBeneficiary.bankCountryCode,
    bankName: paymentSummaryBeneficiary.bankName!,
    beneficiaryCountryCode: paymentSummaryBeneficiary.countryCode,
    createdById: paymentSummaryBeneficiary.createdById,
    createdDate: paymentSummaryBeneficiary.createdDate,
    currencyCode: paymentSummaryBeneficiary.currencyCode,
    friendlyName: paymentSummaryBeneficiary.friendlyName!,
    iban: paymentSummaryBeneficiary.iban,
    name: paymentSummaryBeneficiary.name,
    nationalBankCode: paymentSummaryBeneficiary.nationalBankCode,
    residentAddress: generateResidentAddress(
      paymentSummaryBeneficiary.addressLine1,
      paymentSummaryBeneficiary.city,
      paymentSummaryBeneficiary.countryCode,
    ),
    swift: paymentSummaryBeneficiary.swift,
    uploadedBy: paymentSummaryBeneficiary.uploadedBy,
    uploadedByDateTime: paymentSummaryBeneficiary.uploadedByDateTime,
    nationalCodeType: '',
    correspondentAccountNumber: paymentSummaryBeneficiary.correspondentAccountNumber,
    correspondentSwift: paymentSummaryBeneficiary.correspondentSwift,
    furtherToAccountNumber: paymentSummaryBeneficiary.furtherToAccountNumber,
    furtherToSwift: paymentSummaryBeneficiary.furtherToSwift,
    furtherToName: '',
    correspondentBank: '',
    type: paymentSummaryBeneficiary.type,
    userIgnoresApplyFinancialErrors: paymentSummaryBeneficiary.userIgnoresApplyFinancialErrors,
  };
}

const BeneficiaryInfoDrawer: React.FC<IProps> = (props: IProps) => {
  const {
    paymentSummaryBeneficiary, setPaymentSummaryBeneficiary, onClose, open,
  } = props;
  const [beneficiary, setBeneficiary] = useState<Beneficiary>();
  const [loading, setLoading] = useState<boolean>();

  const handleClose = () => {
    setPaymentSummaryBeneficiary(undefined);
    onClose(false);
  };

  const getBeneficiaryFromId = async (accountBeneId: string): Promise<void> => {
    try {
      setLoading(true);
      const beneficiaryResponse = await BeneficiariesService.getIndividualBeneficiary(
        accountBeneId,
      );
      setBeneficiary(beneficiaryResponse);
    } catch (e) {
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!paymentSummaryBeneficiary || !paymentSummaryBeneficiary.id) {
      handleClose();
    } else if (
      paymentSummaryBeneficiary
      && isBeneficiaryDraft(paymentSummaryBeneficiary)
    ) {
      setBeneficiary(
        mapPaymentSummaryBeneficiaryToBeneficiary(paymentSummaryBeneficiary),
      );
    } else {
      getBeneficiaryFromId(paymentSummaryBeneficiary.accountBeneId);
    }
  }, [paymentSummaryBeneficiary, setBeneficiary]);

  if (!beneficiary) {
    return <></>;
  }

  return (
    <BeneficiaryDrawer
      loading={loading}
      beneficiary={beneficiary}
      open={open}
      handleClose={handleClose}
    />
  );
};

export default memo(BeneficiaryInfoDrawer);
