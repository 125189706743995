import React from 'react';
import InverseFxRateTooltip from '../../../../../../../components/Molecules/InverseFxRateTooltip/InverseFxRateTooltip';
import useStyles from './FxRate.styles';
import clsx from 'clsx';

interface IProps {
  fxRate: number;
}

const FxRate: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const {
    fxRate,
  } = props;
  return (
    <div className={clsx(classes.fxRate, 'dd-privacy-allow')}>
      <InverseFxRateTooltip
        displayInfoCircleIcon
        text="Fx Rate"
        placement="bottom-start"
      />
      <div>
        <b className={classes.divider}>{fxRate.toFixed(4)}</b>
        <span className={classes.counterRate}>
          {(1 / fxRate).toFixed(4)}
        </span>
      </div>
    </div>
  );
};

export default FxRate;
