export const openedWidth = 200;
const closedWidth = 75;
const linkBorderWidth = 5;
const linkHorizontalPadding = 5;

import _default, { makeStyles } from 'theme';
import shade from 'theme/utils';

const {
  palette: { primary, secondary, text },
  background,
  typography,
} = _default;

export const useStyles = makeStyles(
  () => ({
    root: (props: any) => ({
      width: props.type === 'static' ? closedWidth : openedWidth,
      height: props.type === 'static' ? '100%' : 'calc(100% - 58px)',
      float: 'left',
      color: secondary.contrastText,
      fontSize: 12,
      lineHeight: '17px',
      position: props.type === 'static' ? 'relative' : 'fixed',
      top: props.type === 'static' ? 0 : '58px',
      left: 0,
      fontFamily: typography.fontFamily,
      transition: 'width 0.3s, transform .2s ease-in-out',
      transform: props.type === 'static' ? 'none' : 'translateX(-100%)',
      zIndex: 1301,
    }),
    mainWrapper: {
      height: '100%',
      position: 'absolute',
    },
    mainHolder: (props: any) => ({
      width: props.type === 'static' ? closedWidth : openedWidth,
      height: '100%',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      backgroundColor: secondary.main,
      transition: 'width 0.3s',
    }),
    opened: (props: any) => ({
      width: openedWidth,
      transform: props.type === 'static' ? 'none' : 'translateX(0)',
    }),
    logo: (props: any) => ({
      height: 75,
      overflow: 'hidden',
      marginBottom: props.rebrand ? 50 : 25,
      textDecoration: 'none',
      position: 'relative',

      '& img': {
        height: 34,
        width: 'auto',
        flexShrink: 1,
        flexGrow: 0,
        margin: '0 auto',
      },
      '& h1': {
        margin: 0,
        fontSize: '12px',
        lineHeight: '17px',
        color: '#fafafa',
        fontWeight: typography.fontWeightRegular,
        letterSpacing: '1.8px',
        textTransform: 'uppercase',
      },
      '&:after': {
        content: '" "',
        position: 'absolute',
        left: 20,
        right: 20,
        height: 1,
        bottom: 0,
        backgroundColor: shade(text.primary, 8),
      },
    }),
    logoExpanded: {
      '& img': {
        height: 19,
      },
    },
    logoHolder: {
      position: 'absolute',
      height: '100%',
      top: 25,
      left: 26,
      right: 26,
      width: openedWidth,
    },
    content: {
      display: 'flex',
      overflowY: 'auto',
      flexGrow: 1,
      height: 1,
      overflow: 'hidden',
      flexDirection: 'column',
    },
    nav: {
      display: 'flex',
      flexDirection: 'column',
    },
    link: {
      color: '#a7b8c6',
      padding: `8px ${linkHorizontalPadding}px`,
      borderLeftWidth: linkBorderWidth,
      borderLeftStyle: 'solid',
      borderLeftColor: 'transparent',
      whiteSpace: 'nowrap',

      '&:hover': {
        borderLeftColor: primary.main,
        color: secondary.contrastText,
      },
    },
    toggleButton: {
      top: 64,
      right: -9,
      position: 'absolute',
      width: 18,
      height: 18,
      borderRadius: '50%',
      cursor: 'pointer',
      color: text.primary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 10,
      border: `1px solid ${shade(text.primary, 1)}`,
      backgroundColor: background.primary,
    },
    linkActive: {
      borderLeftColor: primary.main,
      color: secondary.contrastText,
    },
    linkIcon: {
      width: closedWidth - (linkBorderWidth + linkHorizontalPadding),
      display: 'inline-block',
      textAlign: 'center',
    },
    tools: {
      textAlign: 'center',
      marginTop: 'auto',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 56,
      textTransform: 'uppercase',
    },
    icon: {
      fontSize: 20,
      lineHeight: '25px',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      zIndex: 1000,
      opacity: 0,
      transform: 'scale(0)',
      transition: 'opacity .2s ease-in-out, transform .0s ease-in-out .2s',
    },
    openOverlay: {
      opacity: 1,
      transform: 'scale(1)',
      transition: 'opacity .2s ease-in-out, transform .0s ease-in-out .0s',
    },
    rootRebrand: (props: any) => ({
      width: '75px',
      height: '100%',
      float: 'left',
      color: secondary.contrastText,
      backgroundColor: '#F5F5F5',
      fontSize: 12,
      lineHeight: '17px',
      position: props.type === 'static' ? 'relative' : 'fixed',
      top: 0,
      left: 0,
      fontFamily: typography.fontFamilyRebrand,
      transition: 'width 0.3s, transform .2s ease-in-out',
      zIndex: 1301,
      borderStyle: 'none',
    }),
    contentRebrand: {
      display: 'flex',
      overflowY: 'auto',
      flexGrow: 1,
      height: '100%',
      overflow: 'hidden',
      flexDirection: 'column',
    },
  }),
  { name: 'Sidebar' },
);
