import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
    alignItems: 'center',
  },
  imageHolder: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '50%',
    marginRight: '8px',
    width: '20px',
    height: '20px',
    border: '2px solid rgb(224,218,218)',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    '& img': {
      width: '26px',
      height: '26px',
      position: 'absolute',
      display: 'block',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  friendlyName: {},
  verticalLine: {
    height: '19px',
    position: 'relative',
    margin: '0 8px',
    borderRight: '1px solid #70707026',
  },
  availableBalance: {
    color: '#34343473',
  },
}), { name: 'TransactionDropDownRenderValue' });
export default useStyles;
