import { faEye, faPenNib } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  CircularProgress,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { StyledPaymentTableRow, StyledTable } from 'components/Table/StyledTable/StyledTable.styles';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Submission } from '..';
import { listSubmissions } from '../api';

interface IProps {
  setSelectedSubmissionId: React.Dispatch<SetStateAction<string>>;
  setSelectedFormId: React.Dispatch<SetStateAction<string>>;
  filterState: 'submitted' | 'draft';
  title: string;
  formioUserId: any;
  formId: string;
  page: number;
  setPage: () => void;
  newSubmissionState: { success: boolean; error: boolean; loading: boolean; id: string | null };
  setNewSubmissionState: Dispatch<
    SetStateAction<{ loading: boolean; error: boolean; success: boolean; id: string | null }>
  >;
}

export default function SubmissionsTable({
  setSelectedSubmissionId,
  setSelectedFormId,
  filterState,
  title,
  formioUserId,
  formId,
  page,
  setPage,
  newSubmissionState,
  setNewSubmissionState,
}: IProps) {
  const [submissionsState, setSubmissionsState] = useState<{
    loading: boolean;
    error: boolean;
    data: Submission[];
  }>({
    loading: true,
    error: false,
    data: [],
  });

  const [totalItems, setTotalItems] = useState(0);
  const [shouldHighlightNewSubmission, setShouldHighlightNewSubmission] = useState(false);

  const getPage = async () => {
    const skip = (page * 10).toString();
    listSubmissions(formioUserId, skip, filterState, formId).then((response) => {
      const regex = /\/([^/]+)$/;
      const match = response?.headers['content-range'].match(regex) ?? [];

      setTotalItems(parseInt(match[1], 10));
      setSubmissionsState({
        error: false,
        loading: false,
        data: response?.data || [],
      });
    });
  };

  useEffect(() => {
    getPage();
  }, [page, newSubmissionState.success]);

  useEffect(() => {
    if (filterState !== 'draft' || newSubmissionState.id === null) return;
    setShouldHighlightNewSubmission(true);
    setTimeout(() => {
      setShouldHighlightNewSubmission(false);
      setNewSubmissionState({ ...newSubmissionState, id: null });
    }, 1500);
  }, [newSubmissionState.id]);

  return (
    <>
      <Box
        sx={{
          p: 2,
          width: '100%',
        }}
      >
        <h2>{title}</h2>
        <StyledTable props={{}}>
          <TableHead>
            {submissionsState.loading ? (
              <Box
                sx={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableRow>
                <TableCell>Entity Name</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Last Modified</TableCell>
                <TableCell>Submission ID</TableCell>
                <TableCell>{filterState === 'draft' ? 'edit' : 'view'}</TableCell>
              </TableRow>
            )}
          </TableHead>

          <TableBody>
            {!submissionsState.data.length && (
              <Box
                sx={{
                  p: 2,
                  textAlign: 'center',
                  width: '100vw',
                }}
              >
                <Typography
                  sx={{
                    margin: 'auto',
                  }}
                >
                  No submissions yet.
                </Typography>
              </Box>
            )}
            {submissionsState.data.map(({ _id, data: { entities }, created, modified }, index) => (
              <StyledPaymentTableRow
                key={_id}
                highlighted={_id === newSubmissionState.id && shouldHighlightNewSubmission}
              >
                <TableCell>
                  <span>{entities[0].generalQuestions.entityName}</span>
                </TableCell>
                <TableCell>
                  <span>{new Date(created).toDateString()}</span>
                </TableCell>
                <TableCell>
                  <span>{new Date(modified).toDateString()}</span>
                </TableCell>
                <TableCell>
                  <span>{_id}</span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    setSelectedSubmissionId(_id);
                    setSelectedFormId(formId);
                  }}
                >
                  <FontAwesomeIcon
                    data-testid={`edit-sub-${entities[0].generalQuestions.entityName}`}
                    icon={filterState === 'draft' ? faPenNib : faEye}
                  />
                </TableCell>
              </StyledPaymentTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TablePagination
              style={{ position: 'absolute', right: '3.5%' }}
              component="div"
              count={totalItems}
              page={page}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
              onPageChange={() =>
                setSubmissionsState({
                  error: false,
                  loading: true,
                  data: submissionsState.data,
                })
              }
              backIconButtonProps={{ onClick: () => setPage(page - 1) }}
              nextIconButtonProps={{ onClick: () => setPage(page + 1) }}
            />
          </TableFooter>
        </StyledTable>
      </Box>
    </>
  );
}
