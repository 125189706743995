import React, { useState, useEffect } from 'react';
import { theme } from 'theme/themes/default';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAltDots, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { originalUseSnackbar } from 'components/Snackbar';
import FormTitle from '../../../components/Molecules/Auth/FormTitle';
import { TMethodRequestTypes, TSignIn } from '../../../models/auth';
import AuthBackupMethodCard from '../../../components/AuthBackupMethodCard';
import AuthSetupModal from '../../../components/AuthSetupModal';
import { useOnboardingContext, IOnboarding } from '../Onboarding';
import routes from '../../../routes.path';
import useAuth from '../../../hooks/useAuth';
import AuthService from '../../../services/Auth/auth.service';
import browserHistory from '../../../services/history/browserHistory';
import { IReturning } from '../Returning';
import useFormStyles from '../form.styles';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface IProps extends IOnboarding, IReturning {
  setup?: boolean,
  setMfaImage?: React.Dispatch<React.SetStateAction<'authy' | 'sms' | undefined>>,
}

const onboardingDescription = 'Two-factor authentication must be enabled with your registered device in order to use the platform. Please select your preferred method.';
const onboardingTitle = 'Set up Two-factor Authentication';

const SetupMFA: React.FC<IProps> = ({
  onboarding, userObject, setup, setMfaImage,
}: IProps) => {
  const formClasses = useFormStyles();

  const [showAuthyModal, setShowAuthyModal] = useState<boolean>(false);
  const [loadingCard, setLoadingCard] = useState<TMethodRequestTypes | false>(false);
  const { challengeRedirect } = useAuth();
  const { enqueueSnackbar } = originalUseSnackbar();

  const { handleSetLoaderPercentage } = useOnboardingContext();

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      handleSetLoaderPercentage(75);
    }
  }, []);

  const handleTriggerMfa = async (methodRequest: TMethodRequestTypes): Promise<void> => {
    try {
      setLoadingCard(methodRequest);
      const res = await AuthService.sendCustomChallengeAnswer(userObject, methodRequest);
      challengeRedirect(res as TSignIn, enqueueSnackbar);
      browserHistory.push(onboarding ? routes.auth.onboarding.verifyMfa : routes.auth.totp);
    } catch (e) {
      enqueueSnackbar(e.response?.data?.error || e.message || 'There was an error with your request', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
        className: 'dd-privacy-allow',
      });
    } finally {
      setLoadingCard(false);
    }
  };
  return (
    <div>
      <FormTitle
        number={onboarding ? 3 : undefined}
        title={(onboarding && setup) ? onboardingTitle : 'Two-factor Authentication'}
        description={(onboarding && setup) ? onboardingDescription : 'Please select the method you would like.'}
      />
      <div
        onFocus={() => (setMfaImage && setMfaImage('authy'))}
        onMouseEnter={() => (setMfaImage && setMfaImage('authy'))}
      >
        <AuthBackupMethodCard
          loading={Boolean(loadingCard === TMethodRequestTypes.METHOD_AUTHY)}
          disabled={Boolean(loadingCard)}
          icon={{
            backgroundColor: '#EC1C24',
            element: <img src="/static/images/authyIcon.png" alt="Authy icon" />,
          }}
          content={{
            title: 'Authy',
            body: 'Download the Authy app for fast, secure, offline authentication.',
          }}
          handleOnClick={() => setShowAuthyModal(true)}
          displaySecureBadge
        />
      </div>
      <div style={{ padding: '16px 0' }} />
      <div
        onFocus={() => (setMfaImage && setMfaImage('sms'))}
        onMouseEnter={() => (setMfaImage && setMfaImage('sms'))}
      >
        <AuthBackupMethodCard
          loading={Boolean(loadingCard === TMethodRequestTypes.METHOD_SMS)}
          disabled={Boolean(loadingCard)}
          icon={{
            backgroundColor: theme.palette?.primary?.main as string,
            element: <FontAwesomeIcon icon={faCommentAltDots as IconDefinition} />,
          }}
          content={{
            title: 'SMS',
            body: 'Receive an SMS to your registered device. Available network required.',
          }}
          handleOnClick={() => handleTriggerMfa(TMethodRequestTypes.METHOD_SMS)}
        />
      </div>
      {
        (!onboarding || !setup)
        && (
          <>
            <div style={{ padding: '16px 0' }} />
            <AuthBackupMethodCard
              loading={Boolean(loadingCard === TMethodRequestTypes.METHOD_SMS)}
              disabled={Boolean(loadingCard)}
              icon={{
                backgroundColor: theme.palette?.primary?.main as string,
                element: <FontAwesomeIcon icon={faPhone as IconDefinition} />,
              }}
              content={{
                title: 'Call',
                body: 'Receive a phone call to your registered device. Available network required.',
              }}
              handleOnClick={() => handleTriggerMfa(TMethodRequestTypes.METHOD_CALL)}
            />
          </>
        )
      }
      <AuthSetupModal
        open={showAuthyModal}
        phoneNumber={userObject.challengeParam?.CODE_DELIVERY_DESTINATION || ''}
        handleModalClose={() => setShowAuthyModal(false)}
        handleSuccessButtonClick={() => handleTriggerMfa(TMethodRequestTypes.METHOD_AUTHY)}
      />
      {
        (!onboarding || !setup)
        && (
          <NavLink
            to={onboarding ? routes.auth.onboarding.verifyMfa : routes.auth.login}
            className={clsx(formClasses.formBacklink, formClasses.white)}
          >
            Go back
          </NavLink>
        )
      }
    </div>
  );
};

export default SetupMFA;
