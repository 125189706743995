import { Button } from '@mui/material';
import styled from '@mui/styled-engine';
import theme from 'theme';
import { IBaseButtonProps } from '.';

const { typography } = theme;

const StyledBaseButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'rounded',
})<IBaseButtonProps>(({ rounded }) => ({
  fontSize: 12,
  boxShadow: 'inherit',
  borderRadius: rounded ? 4 : 0,
  letterSpacing: 1.8,
  fontFamily: typography.fontFamily,
  textTransform: 'uppercase',
  lineHeight: 1.41,
  '& .loader': {
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    position: 'absolute',
  },
  '&.MuiButton-sizeLarge': {
    minHeight: 50,
  },
}));

export default StyledBaseButton;
