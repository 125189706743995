import { makeStyles } from 'theme';
import { Typography } from '@mui/material';
import React from 'react';

interface IProps {
    image: JSX.Element,
    text: string | number;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .element': {
      marginBottom: '12px',
    },
    '& .text': {
      textAlign: 'center',
    },
    '& h6': {
      fontWeight: 600,
    },
  },
}), { name: 'Badge' });

const Badge: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className="element">
        {props.image}
      </div>
      <div className="text">
        <Typography variant="subtitle1">
          {props.text}
        </Typography>
      </div>
    </div>
  );
};

export default Badge;
