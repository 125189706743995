import React, { FC, memo, useCallback } from 'react';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { IAutocompleteSelectMultiOption, IMultiAutoCompleteSelectProps } from './MultiAutoCompleteSelect.interfaces';

const defaultGetOptionLabel = (option: IAutocompleteSelectMultiOption) => option.label;

export const MultiAutoCompleteSelect: FC<IMultiAutoCompleteSelectProps> = memo(
  ({
    label,
    placeholder,
    options = [],
    getOptionLabel = defaultGetOptionLabel,
    onChange,
    ...rest
  }) => {
    const renderInput = useCallback(
      (params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
        />
      ),
      [placeholder, label],
    );

    return (
      <Autocomplete
        multiple
        options={options}
        onChange={onChange}
        renderInput={renderInput}
        {...rest}
      />
    );
  },
);
