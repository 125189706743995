import { APMainLayout } from 'components/Layout/MainLayout';
import React, {
  createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Role } from '@portal/user-types';
import routes from '../../../routes.path';
import browserHistory from '../../../services/history/browserHistory';
import { TStore } from '../../../store';
import { userManagementState } from '../../../store/userManagement/userManagement.reducer';
import UserManagementTabs from '../components/Tabs';
import userManagementConfig from '../configs/userManagement.config';
import useToggle from '../../../hooks/useToggle';
import WizardModal from '../../../components/Modals/WizardModal';
import UserManagementService from '../../../services/UserManagement/userManagement.service';
import { isRoleDisabled } from '../helpers/permissions';
import createNewUserModalConfig from '../configs/createNewUserModal.config';
import {
  IWizardParams, ModalTypeEnum, TRole, TRoles, UserManagementContextType, UserTables,
} from './index.interfaces';
import { entityAccessModalConfig } from '../configs/entityAccessModal.config';

const UserManagementContextState = {
  title: '',
  setTitle: () => { },
  openModal: () => { },
  rolesMap: {},
  roles: [],
  refreshDataTable: null,
  setRefreshDataTable: () => { },
  countryCode: undefined,
};

const UserManagementContext = createContext<UserManagementContextType>(UserManagementContextState);

export const useUserManagementContext = () => useContext(UserManagementContext);

const UserManagementProvider = ({ children }: { children: ReactNode }) => {
  const { path } = useRouteMatch();

  const {
    text, breadcrumb, tabs, defaultTabIndex, rightAlignedCta,
  } = userManagementConfig[path];

  const { isOpen, open, close } = useToggle(false);

  const [countryCode, setCountryCode] = useState();
  const [title, setTitle] = React.useState<string>('');
  const [rolesMap, setRolesMap] = React.useState<any>();
  const [roles, setRoles] = React.useState<TRole[]>([]);
  const [refreshDataTable, setRefreshDataTable] = useState<UserTables | null>(null);
  const [wizardParams, setWizardParams] = useState<IWizardParams>({});

  const openModalType = (params: IWizardParams) => ({
    CREATE_USER: createNewUserModalConfig(params),
    ENTITY_ACCESS: entityAccessModalConfig(params),
  });

  const openModal = useCallback((
    {
      user, entity, modalType, submitCallback,
    }: Pick<IWizardParams, 'user' | 'entity' | 'submitCallback'> & { modalType: ModalTypeEnum },
  ) => {
    setWizardParams({
      user, entity, modalType, submitCallback,
    });
    open();
  }, [open]);

  const { modalType, ...modalParams } = wizardParams;

  const {
    isAuthorizedToViewEntityManagement,
  } = useSelector<TStore, userManagementState>(
    (state) => state.userManagementPage,
  );

  useEffect(() => {
    if (isAuthorizedToViewEntityManagement === false) {
      browserHistory.push(routes.base);
    }
  }, [isAuthorizedToViewEntityManagement]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const code = await response.json();
        setCountryCode('country_code' in code ? code.country_code : undefined);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to get country_code from https://ipapi.co/json/', error);
      }
    };

    const fetchRoles = async () => {
      const { data } = await UserManagementService.getAllRoles();
      setRolesMap(data.reduce(
        (accumulator: TRoles, role: Role) => ({
          ...accumulator,
          [role.id]: {
            ...role,
            isDisabled: isRoleDisabled(role.id),
          },
        }), {},
      ));
      setRoles(data.map((role) => ({
        ...role,
        isDisabled: isRoleDisabled(role.id),
      })));
    };

    fetchRoles();
    fetchCountry();
  }, []);

  const value = useMemo(() => (
    {
      title,
      setTitle,
      openModal,
      roles,
      rolesMap,
      setRefreshDataTable,
      refreshDataTable,
      countryCode,
    }),
  [
    title,
    setTitle,
    openModal,
    roles,
    rolesMap,
    setRefreshDataTable,
    refreshDataTable,
    countryCode,
  ]);

  return (
    <UserManagementContext.Provider value={value}>
      <APMainLayout
        title={text || title}
        breadCrumbs={breadcrumb}
        pageTabs={<UserManagementTabs defaultTabIndex={defaultTabIndex} tabs={tabs} />}
        rightAlignedCta={<>{rightAlignedCta}</>}
        bodyBackgroundColor="#FAFAFA"
      >
        {children}
      </APMainLayout>
      {modalType && (
        <WizardModal
          handleCloseModal={() => close()}
          open={isOpen}
          modalConfig={openModalType((modalParams))[modalType]}
        />
      )}
    </UserManagementContext.Provider>
  );
};

export default UserManagementProvider;
