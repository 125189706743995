import { FormControlLabel, FormGroup } from '@mui/material';
import clsx from 'clsx';
import { Field } from 'formik';
import React from 'react';
import useStyles from './SelectGroup.styles';
import { TGroupedRoles } from '../../domain/UserManagement/components/Permissions';

interface ISelectGroup {
    roles: TGroupedRoles[],
    isSubmitting: boolean,
    fieldName: string,
    row?: boolean,
}

export const SelectGroup = (props: ISelectGroup) => {
  const {
    roles, isSubmitting, fieldName, row = true,
  } = props;
  const classes = useStyles();

  return (
    <FormGroup row={row} className={classes.root} key={fieldName}>
      {roles.map(({
        id, type, isDisabled, level,
      }) => (
        <FormControlLabel
          key={id}
          className={clsx(classes.baseSelect, type === 'checkbox' ? classes.checkbox : classes.radio)}
          disabled={isSubmitting || isDisabled}
          control={(
            <>
              <Field
                disabled={isSubmitting || isDisabled}
                type={type}
                name={fieldName}
                value={id}
              />
              <div />
            </>
          )}
          label={level}
        />
      ))}
    </FormGroup>
  );
};

export default SelectGroup;
