import { BatchApprover } from '@alpha/payments-dtos';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import DropDownWrapper from './DropDownWrapper/DropDownWrapper';

interface IProps {
  id: string,
  currentOptions: BatchApprover[],
  loading: boolean;
  approvers: BatchApprover[],
  displayMultiSelect: boolean,
  menuCloseCount: number,
  setMenuCloseCount: React.Dispatch<React.SetStateAction<number>>
  setOpen: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  setCurrentOptions: React.Dispatch<React.SetStateAction<BatchApprover[]>>,
  setTextValue: React.Dispatch<React.SetStateAction<string>>,
  setDisplayMultiSelect: (val: boolean) => void,
  submitApproverEmails: (id: string) => Promise<boolean>,
}

const MultiSelectSection = ({
  id,
  approvers,
  currentOptions,
  loading,
  displayMultiSelect,
  menuCloseCount,
  setMenuCloseCount,
  setOpen,
  setCurrentOptions,
  setTextValue,
  setDisplayMultiSelect,
  submitApproverEmails,
}: IProps) => {
  const [awaitingSubmit, setAwaitingSubmit] = useState<boolean>(false);

  const handleApprovers = (): BatchApprover[] => approvers.filter(
    (approver: BatchApprover) => approver.eligible,
  );

  const handleSubmit = async (): Promise<void> => {
    setAwaitingSubmit(true);
    const success = await submitApproverEmails(id);
    if (success) {
      setDisplayMultiSelect(false);
      setOpen(null);
    }
    setAwaitingSubmit(false);
    setCurrentOptions([]);
  };

  return (
    <>
      {
        !displayMultiSelect
          ? (
            <Typography>
              Send email reminder to approvers
            </Typography>
          )
          : (
            <DropDownWrapper
              setMenuCloseCount={setMenuCloseCount}
              menuCloseCount={menuCloseCount}
              loading={loading}
              currentOptions={currentOptions}
              awaitingSubmit={awaitingSubmit}
              handleSubmit={handleSubmit}
              handleApprovers={handleApprovers}
              setTextValue={setTextValue}
              setCurrentOptions={setCurrentOptions}
            />
          )
      }

    </>
  );
};

export default MultiSelectSection;
