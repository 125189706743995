import { FormikHelpers } from 'formik';
import _, { once } from 'lodash';
import React, {
  createContext, useCallback, useContext, useMemo, useReducer,
} from 'react';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import { IWizardModalConfig } from '../../Modal.interfaces';
import {
  Actions, createInitialState, IState, reducer,
} from './reducer';

interface IWizardModal<T> {
  state: IState;
  isFinalStep: boolean;
  handleSubmit: (
    values: T,
    actions: FormikHelpers<T>
  ) => void;
  handlePrevious: () => void;
  setNestedStepsTotal: (total: number, nStep: number) => void;
}

export const WizardModalContext = once(<T, >() => createContext({} as IWizardModal<T>));

interface IWizardModalProviderProps<T> {
  config: IWizardModalConfig<T>;
  handleCloseModal: () => void;
  children: React.ReactNode;
}

export const WizardModalProvider = <T, >({
  children, config, handleCloseModal,
}: IWizardModalProviderProps<T>) => {
  const StateContext = WizardModalContext<T>();
  const [state, dispatch] = useReducer(reducer, createInitialState(config));
  const { step, nestedSteps, error } = state;
  const currentStep = config.steps[step];
  const currentNestedSteps = nestedSteps[step];
  const isFinalStep = (config.steps.length - 1) === step;

  const sb = useAlphaSnackbar();

  const handlePrevious = useCallback(() => {
    if (currentNestedSteps.current > 0) {
      dispatch({ type: Actions.DECREMENT_NESTED_STEP });
    } else {
      dispatch({ type: Actions.DECREMENT_STEP });
    }
  }, [currentNestedSteps]);

  const setNestedStepsTotal = useCallback((total: number, nStep: number) => {
    dispatch({ type: Actions.SET_NESTED_STEP, total, step: nStep });
  }, []);

  const setName = useCallback((name: string) => {
    dispatch({ type: Actions.SET_NAME, name });
  }, []);

  const toggleLoading = () => {
    dispatch({ type: Actions.TOGGLE_LOADING });
  };

  const handleError = useCallback((e:any) => {
    if (e.response && e.response.data && e.response.data.error) {
      e.response.data.error.map((err: any) => sb.trigger(err.message));
    }
    if (e.step !== undefined) {
      sb.trigger(e.message);
      dispatch({ type: Actions.SET_ERROR, error: e.step });
      dispatch({ type: Actions.SET_STEPS, step: e.step, nestedSteps: e.nested });
    }
  }, [sb]);

  const handleNext = useCallback(() => {
    if (currentNestedSteps.current < currentNestedSteps.total) {
      dispatch({ type: Actions.INCREMENT_NESTED_STEP });
    } else {
      dispatch({ type: Actions.INCREMENT_STEP });
    }
    if (error !== false) { dispatch({ type: Actions.SET_ERROR, error: false }); }
  }, [currentNestedSteps, error]);

  const handleSubmit = useCallback(async (
    values: typeof config.initialValues,
    actions: FormikHelpers<T>,
  ) => {
    const isReadyForSubmission = isFinalStep
      && currentNestedSteps.current === currentNestedSteps.total;

    if (isReadyForSubmission) {
      try {
        const { message, type } = await config.submitForm(values, actions);
        sb.trigger(message, type);
        handleCloseModal();
      } catch (e: any) {
        handleError(e);
      }
    } else if (currentStep.handleNext) {
      currentStep.handleNext?.({
        values,
        setNestedStepsTotal,
        handleNext,
        setName,
        setFieldValue: actions.setFieldValue,
        toggleLoading,
        handleError,
      });
    } else {
      handleNext();
    }
    actions.setTouched({});
    actions.setSubmitting(false);
  }, [config,
    isFinalStep,
    currentNestedSteps,
    currentStep,
    sb,
    handleCloseModal,
    handleError,
    setNestedStepsTotal,
    handleNext,
  ]);

  const value = useMemo(() => ({
    state,
    handlePrevious,
    isFinalStep,
    setNestedStepsTotal,
    handleSubmit,
  }), [
    state,
    handlePrevious,
    isFinalStep,
    setNestedStepsTotal,
    handleSubmit,
  ]);

  return (
    <StateContext.Provider value={value}>
      {children}
    </StateContext.Provider>
  );
};

export const useWizardModal = () => {
  const wizardModal = useContext(WizardModalContext());
  if (_.isEmpty(wizardModal)) {
    throw new Error('useWizardModal must be used within a WizardModalProvider');
  }
  return wizardModal;
};
