/* eslint-disable max-lines-per-function */
import { useState, useEffect } from 'react';
import { PaymentBatchDraftDto, BatchQuoteDto } from '@alpha/payments-dtos';
import { IndicativeRateResponse, SameTradeResponse } from '@alpha/fx-dtos';
import { datadogRum } from '@datadog/browser-rum';
import { IBatchCurrencyPairRateDecorated } from '../domain/Payments/Funding/Funding.interface';
import useAlphaSnackbar from './useAlphaSnackbar';
import PaymentsService from '../services/Payments/payments.service';
import InterAccountTransferService from '../services/InterAccountTransfer/interAccountTransfer.service';
import FxTransferService from '../services/FXTransfer/fxTransfer.service';
import history from '../services/history/browserHistory';
import routes from '../routes.path';
import { PaymentRouteEnum } from '../models/payments';
import { formatNumber } from '../utils/currency.helpers';

interface IProps {
  batchId: string;
}

const useFunding = (props: IProps) => {
  const { batchId } = props;
  const snackbar = useAlphaSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
  const [batchDetails, setBatchDetails] = useState<
    PaymentBatchDraftDto | undefined
  >();
  const [crossCurrencyPairs, setCrossCurrencyPairs] = useState<
    IBatchCurrencyPairRateDecorated[]
  >([]);
  const [liveRates, setLiveRates] = useState<BatchQuoteDto[]>([]);
  const [bookFxErrored, setBookFxErrored] = useState<boolean>(false);
  const [disableLiveRateBtn, setDisableLiveRateBtn] = useState<boolean>(false);
  const [disableBookFxBtn, setDisableBookFxBtn] = useState<boolean>(false);
  const liveRateAvailableTime = 100; // timer to action live rates, represents 10 seconds
  const [timer, setTimer] = useState<number>(liveRateAvailableTime);
  const [displayPairs, setDisplayPairs] = useState<
    IBatchCurrencyPairRateDecorated[]
  >([]);
  const [duplicateTradesDetails, setDuplicateTradeDetails] = useState<SameTradeResponse[]>([]);
  const [checkedForDuplicates, setCheckedForDuplicates] = useState<boolean>(false);

  const getBatchDetails = async (): Promise<PaymentBatchDraftDto | void> => {
    try {
      return (await PaymentsService.getBatchDetails(
        batchId,
        PaymentRouteEnum.FUNDING,
      ) as PaymentBatchDraftDto);
    } catch (e) {
      return snackbar.trigger(
        `There was an error getting batch details (${batchId}). ${e.message}.`,
      );
    }
  };

  const getIndicativeRate = async (
    debitingCurrencyCode: string,
    creditingCurrencyCode: string,
    date: string,
  ): Promise<IndicativeRateResponse | void> => {
    try {
      return await InterAccountTransferService.getRate({
        debitingCurrencyCode,
        creditingCurrencyCode,
        date,
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.error || 'There was an error getting indicative rate';
      return snackbar.trigger(errorMessage);
    }
  };

  const requestLiveRates = async (): Promise<BatchQuoteDto[] | void> => {
    try {
      return await FxTransferService.getLiveRates(batchId);
    } catch (error) {
      const errorMessage = error?.response?.data?.error || 'There was an error getting live rates';
      return snackbar.trigger(errorMessage);
    }
  };

  const handleNavigationToBatchSummary = (): void => {
    history.push(routes.payments.base);
  };

  const handleRequestLiveRates = async (): Promise<void> => {
    const liveRatesResponse = await requestLiveRates();
    if (liveRatesResponse) {
      setLiveRates(liveRatesResponse);
    }
    setDisableLiveRateBtn(false);
  };

  const handleCheckSameTrade = async (): Promise<void> => {
    setDisableLiveRateBtn(true);
    if (!checkedForDuplicates) {
      try {
        const duplicateTradeResponse = await FxTransferService.checkSameTrade(batchId);
        if (duplicateTradeResponse && duplicateTradeResponse.length > 0) {
          return setDuplicateTradeDetails(duplicateTradeResponse);
        }
      } catch (error) {
        snackbar.trigger(error?.response?.data?.error || 'There was an error checking for possible duplicate trades');
      }
    }
    return handleRequestLiveRates();
  };

  const handleLiveRateReset = () => {
    setTimeout(() => setTimer(liveRateAvailableTime), 300);
    setLiveRates([]);
  };

  const handleFxBooking = async () => {
    try {
      setDisableBookFxBtn(true);
      const instructionIds = displayPairs.map((pair) => pair.instructionId!);
      const response = await FxTransferService.postTrades(batchId, instructionIds);
      if (response.status === 201) {
        datadogRum.addAction('PAYMENT IS BEING FUNDED');
        snackbar.trigger(
          `Your batch (${batchDetails!.friendlyBatchId}) is being funded.`,
          'success',
        );
        setLoadingScreen(true);
        setTimeout(() => {
          history.push(routes.payments.base);
          setDisableBookFxBtn(false);
          setLoadingScreen(false);
          handleLiveRateReset();
        }, 3000);
      }
    } catch (e) {
      setBookFxErrored(true);
      handleLiveRateReset();
      setLoadingScreen(false);
      setDisableBookFxBtn(false);
      snackbar.trigger(
        `Something went wrong funding your batch (${batchId}).${e?.message ? e.message : ''}`,
      );
    }
  };

  const handleAcknowledgeDuplicate = () => {
    setCheckedForDuplicates(true);
    setDisableLiveRateBtn(false);
    setDuplicateTradeDetails([]);
  };

  const updateBatchDetails = async () => {
    if (batchId !== batchDetails?.batchId) {
      setLoading(true);
      const response = await getBatchDetails();
      if (response) {
        setBatchDetails(response);
      }
      setLoading(false);
    }
  };

  const updateCrossCurrencyPairsWithIndicativeRate = async () => {
    if (batchDetails?.batchCurrencyPairs) {
      setLoading(true);
      const crossCurrPairs = batchDetails.batchCurrencyPairs.filter(
        (currencyPair: any) => currencyPair.sellCurrency !== currencyPair.buyCurrency,
      );
      const decoratedCrossCurrencyPairsPromises = crossCurrPairs.map(
        async (currencyPair: IBatchCurrencyPairRateDecorated) => {
          let valueDate: string;
          if (batchDetails.currencyValueDates === undefined) {
            valueDate = 'undefined';
          } else {
            const key = `${currencyPair.sellCurrency}${currencyPair.buyCurrency}#${currencyPair.fixedSide}`;
            valueDate = batchDetails.currencyValueDates[key];
          }
          const indicativeRate = await getIndicativeRate(
            currencyPair.sellCurrency,
            currencyPair.buyCurrency,
            valueDate,
          ) as IndicativeRateResponse;
          return {
            ...currencyPair,
            rate: formatNumber(indicativeRate.rate, 4) as unknown as number,
            valueDate: indicativeRate.valueDate,
          };
        },
      );
      const decoratedCrossCurrencyPairs = await Promise.all(
        decoratedCrossCurrencyPairsPromises,
      );
      setCrossCurrencyPairs(decoratedCrossCurrencyPairs);
      setLoading(false);
    }
  };

  const updateDisplayPairsWithLiveRate = () => {
    if (liveRates.length > 0) {
      const newDisplayPairs = liveRates.map((liveRate) => {
        // eslint-disable-next-line arrow-body-style
        const crossCurrencyEquivalent = crossCurrencyPairs.filter(
          (crossCurrencyPair: any) => (
            crossCurrencyPair.sellCurrency === liveRate.sellCurrency
            && crossCurrencyPair.buyCurrency === liveRate.buyCurrency
            && crossCurrencyPair.fixedSide === liveRate.fixedSide
          ),
        )[0];
        const pairWithLiveRate = {
          ...crossCurrencyEquivalent,
          rate: formatNumber(liveRate.rate, 4) as unknown as number,
          instructionId: liveRate.instructionId,
        };
        return pairWithLiveRate;
      });
      setDisplayPairs(newDisplayPairs);
    } else {
      setDisplayPairs(crossCurrencyPairs);
    }
  };

  const updateTimer = () => {
    if (liveRates.length > 0) {
      if (timer >= 0) {
        if (!disableBookFxBtn) setTimeout(() => setTimer(timer - 2), 200);
      } else {
        // reset timer and clear live rates after 10s is up
        handleLiveRateReset();
      }
    }
  };

  useEffect(() => {
    updateBatchDetails();
  }, [batchId]);

  useEffect(() => {
    updateCrossCurrencyPairsWithIndicativeRate();
  }, [batchDetails?.batchCurrencyPairs]);

  useEffect(() => {
    updateDisplayPairsWithLiveRate();
  }, [liveRates, crossCurrencyPairs]);

  useEffect(() => {
    updateTimer();
  }, [liveRates, timer]);

  return {
    loading,
    loadingScreen,
    batchDetails,
    setBatchDetails,
    displayPairs,
    liveRates,
    timer,
    disableLiveRateBtn,
    disableBookFxBtn,
    bookFxErrored,
    handleNavigationToBatchSummary,
    handleRequestLiveRates,
    handleLiveRateReset,
    handleFxBooking,
    handleCheckSameTrade,
    duplicateTradesDetails,
    handleAcknowledgeDuplicate,
  };
};

export default useFunding;
