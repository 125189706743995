import React, { useEffect, useState } from 'react';
import Dropdown from '../../../components/InterAccountTransfer/Dropdown/Dropdown';
import { TCurrencyResponse } from '../../../models/currencyAccounts';
import history from '../../../services/history/browserHistory';
import TransactionsService from '../../../services/Transactions/transactions.service';

export interface IProps {
  currencyAccountId?: string;
}
// eslint-disable-next-line max-lines-per-function
const TransactionsSelect: React.FC<IProps> = (props: IProps) => {
  const { currencyAccountId } = props;

  const [options, setOptions] = useState<TCurrencyResponse>();

  useEffect(() => {
    (async () => {
      setOptions(await TransactionsService.getTransactionAccounts());
    })();
  }, []);

  return (
    <div style={{ maxWidth: 'fit-content' }}>
      <Dropdown
        currencyAccounts={options?.currencyAccounts || []}
        handleChange={(e) => history.push(`/app/transactions/${e.target.value}`)}
        selectValue={currencyAccountId}
        hideBalance
      />
    </div>
  );
};

export default TransactionsSelect;
