import { IATDraftResponse } from '@alpha/iat-dtos';
import Status from 'components/Status/Status';
import { Typography } from '@mui/material';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';
import { formatNumber } from '../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import Buttons from './Buttons';
import useStyles from './styles';

interface IProps {
  iatDraftResponse: IATDraftResponse;
  handleOpenIATDrawer: (iatDraftResponse: IATDraftResponse) => void;
  handleOpenIATRejectModal: (iatDraftResponse: IATDraftResponse) => void;
  handleDeleteIatClick: (iatId: string) => Promise<void>;
  getAllPendingTransfers?: () => Promise<void>;
  inputterAccounts: string[],
  multiEntity?: boolean;
}

const Transfer: React.FC<IProps> = ({
  iatDraftResponse,
  handleOpenIATDrawer,
  handleOpenIATRejectModal,
  handleDeleteIatClick,
  multiEntity,
  inputterAccounts,
  getAllPendingTransfers,
}: IProps) => {
  const styles = useStyles();
  const transferAmount = iatDraftResponse.creditAmount
    ? `${formatNumber(iatDraftResponse.creditAmount, 2)} ${iatDraftResponse.creditCurrencyAccount.currencyCode}`
    : `${formatNumber(iatDraftResponse.debitAmount, 2)} ${iatDraftResponse.debitCurrencyAccount.currencyCode}`;

  return (
    <div className={styles.container} data-testid="pending-transfer">
      <div className={clsx('referenceAndAmount')}>
        <div className="reference dd-privacy-allow" data-testid="pending-transfer-reference">
          <Typography className={styles.lightText} variant="subtitle1">Reference</Typography>
          <Typography className={styles.mainText} variant="subtitle1">{iatDraftResponse.reference || '-'}</Typography>
        </div>
        <Typography className={styles.mainText} variant="subtitle1" data-testid="pending-transfer-amount">
          {transferAmount}
        </Typography>
      </div>
      <div className="transferAccounts">
        <div className="account" data-testId="pending-transfer-debit">
          <Typography className={styles.mainText} variant="subtitle1">{iatDraftResponse.debitCurrencyAccount.friendlyName}</Typography>
          <Typography className={styles.lightText} variant="subtitle1">{iatDraftResponse.debitCurrencyAccount.iban || iatDraftResponse.debitCurrencyAccount.swift}</Typography>
        </div>
        <FontAwesomeIcon icon={faArrowRight} />
        <div className="account" data-testId="pending-transfer-credit">
          <Typography className={styles.mainText} variant="subtitle1">{iatDraftResponse.creditCurrencyAccount.friendlyName}</Typography>
          <Typography className={styles.lightText} variant="subtitle1">{iatDraftResponse.creditCurrencyAccount.iban || iatDraftResponse.creditCurrencyAccount.swift}</Typography>
        </div>
      </div>
      <div className={clsx('approvalInfoAndButtons', styles.borderBottom)} data-testid="pending-transfer-approvalInfo-buttons">
        <div className="approvalInfo">
          <div className="info dd-privacy-allow">
            <Typography className={styles.lightText} variant="subtitle1">{formatIsoDate(iatDraftResponse.submittedByDateTime)}</Typography>
            <Typography className={styles.lightText} variant="subtitle1">
              <span>
                {iatDraftResponse.submittedBy || ''}
              </span>
            </Typography>
          </div>
          <div>
            <Status variant={iatDraftResponse.status === 'APPROVED' ? 'info' : 'default'}>
              {`${iatDraftResponse.approvals.length}/${iatDraftResponse.approvalsRequired}`}
            </Status>
          </div>
        </div>
        <div id={`buttons-${iatDraftResponse.id}`} className="buttons dd-privacy-allow">
          <Buttons
            handleOpenIATDrawer={handleOpenIATDrawer}
            iatDraftResponse={iatDraftResponse}
            handleOpenIATRejectModal={handleOpenIATRejectModal}
            handleDeleteIatClick={handleDeleteIatClick}
            multiEntity={multiEntity}
            inputterAccounts={inputterAccounts}
            getAllPendingTransfers={getAllPendingTransfers}
          />
        </div>
      </div>
    </div>
  );
};

export default Transfer;
