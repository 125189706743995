import { PaymentDraftDto } from '@alpha/payments-dtos';
import { Box } from '@mui/material';
import React from 'react';
import BackdropLoader from '../../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import Table from '../../../../../../components/Table/Table';
import ErrorRow from './ErrorRow/ErrorRow';
import useStyles from './TableContainer.styles';

interface IProps {
  tableData: any[] | undefined,
  batchErrors: string[] | undefined,
  loading: boolean,
}

const TableContainer: React.FC<IProps> = (props: IProps) => {
  const {
    tableData, batchErrors, loading,
  } = props;
  const classes = useStyles();

  if (tableData === undefined || loading) {
    return (
      <Box className={classes.loaderWrapper}>
        <BackdropLoader testId="backdrop-loader" />
      </Box>
    );
  }

  const mappedBatchData = batchErrors
    && batchErrors.length > 0
    && batchErrors.map((error: string, index: number) => ({
      row: index + 1,
      description: <ErrorRow
        row={index}
        batchError={error}
        paymentIndex={index}
      />,
    }));

  const mappedTableData = tableData.map((data: PaymentDraftDto, index: number) => ({
    row: +data.batchLineNumber + 1,
    description: <ErrorRow
      row={+data.batchLineNumber + 1}
      paymentErrors={data.errors!}
      paymentIndex={index}
    />,
  }));

  const setColumnHeader = (): string => {
    if (batchErrors && batchErrors.length > 0) {
      return 'error';
    } return 'row';
  };

  const columns = [
    {
      header: setColumnHeader(),
      accessor: 'row',
    },
    {
      header: 'description',
      accessor: 'description',
    },
  ];

  return (
    <Table columns={columns} data={mappedBatchData || mappedTableData} />
  );
};

export default TableContainer;
