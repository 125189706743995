import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import {
  AmountStyled,
  DebitTitleStyled,
  DeleteButtonStyled,
  EntityStyled,
  ScheduledTableHeadStyled,
} from './styles';

type IProps = {
  item: any;
  deleteButtonAction: () => void;
}

const ScheduledTableDrawerHead: FC<IProps> = ({ item, deleteButtonAction }) => {
  if (!item) return null;

  return (
    <ScheduledTableHeadStyled>
      <div>
        <DebitTitleStyled variant="body1">Debit</DebitTitleStyled>
        <AmountStyled variant="body1">
          { item.amount }
          &nbsp;
          <span>{ item.fixedSide || item.debitingCurrency }</span>
        </AmountStyled>
        <EntityStyled variant="body1">{ item.accountName }</EntityStyled>
      </div>
      <DeleteButtonStyled onClick={deleteButtonAction} data-testid="scheduled-drawer-deleteBtn">
        <FontAwesomeIcon className="icon" icon={faTrashAlt} />
      </DeleteButtonStyled>
    </ScheduledTableHeadStyled>
  );
};

export default ScheduledTableDrawerHead;
