import React, { useEffect } from 'react';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import {
  TCurrencyAccount,
  TCurrencyAccounts,
} from '../../../../../models/currencyAccounts';
import routes from '../../../../../routes.path';
import history from '../../../../../services/history/browserHistory';
import TransactionsService from '../../../../../services/Transactions/transactions.service';
import FundingAccount from './FundingAccount';

export interface IFundingAccountContainerProps {
  selectedDebitingAccount: TCurrencyAccount | undefined;
  setSelectedDebitingAccount: React.Dispatch<
  React.SetStateAction<TCurrencyAccount | undefined>
>;
}

interface IProps extends IFundingAccountContainerProps {
  currencyAccounts: TCurrencyAccounts;
  setCurrencyAccounts?: React.Dispatch<React.SetStateAction<TCurrencyAccounts>>;
}

const FundingAccountContainer: React.FC<IProps> = ({
  selectedDebitingAccount,
  setSelectedDebitingAccount,
  currencyAccounts,
  setCurrencyAccounts,
}: IProps) => {
  const sb = useAlphaSnackbar();

  useEffect(() => {
    let isCancelled = false;
    getCurrencyAccount(isCancelled);
    return () => {
      isCancelled = true;
    };
  }, []);

  const getCurrencyAccount = async (isCancelled: boolean): Promise<void> => {
    try {
      const fetchedCurrencyAccounts = await TransactionsService.getTransactionAccounts();
      if (!isCancelled && setCurrencyAccounts) {
        setCurrencyAccounts(fetchedCurrencyAccounts.currencyAccounts);
      }
    } catch (e) {
      sb.trigger(
        'There was an error retrieving your debiting accounts at this time.',
      );
      history.push(routes.payments.base);
    }
  };

  const findCurrencyAccountById = (caId: string) => currencyAccounts.find((ca: TCurrencyAccount) => ca.id === caId);

  const handleSetSelectedDebitingAccount = (event: any): void => {
    const newSelectedCurrencyAccountId: string | undefined = event.target?.value;

    if (newSelectedCurrencyAccountId) {
      const newSelectedCurrencyAccount:
        | TCurrencyAccount
        | undefined = findCurrencyAccountById(newSelectedCurrencyAccountId);
      setSelectedDebitingAccount(newSelectedCurrencyAccount);
    }
  };

  return (
    <FundingAccount
      selectedDebitingAccount={selectedDebitingAccount}
      setSelectedDebitingAccount={setSelectedDebitingAccount}
      handleSetSelectedDebitingAccount={handleSetSelectedDebitingAccount}
      currencyAccounts={currencyAccounts}
    />
  );
};

export default FundingAccountContainer;
