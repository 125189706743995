import { faAngleRight, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { MenuItemProps } from '@mui/material';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction } from 'react';
import { TText } from 'types/index.interfaces';
import { StyledMenuItem, StyledBox } from './MenuDropdownItem.styles';

export interface IMenuItemProps {
  style: {
    fontSize: number;
    fontWeight: number;
  };
}

interface IMenuDropdownItem extends MenuItemProps {
    children: IMenuItem;
    dropDownIconEndProps?: FontAwesomeIconProps;
    dropdownIconStartProps?: FontAwesomeIconProps;
    menuItemProps?: IMenuItemProps;
    setOpen: Dispatch<SetStateAction<HTMLElement | null>>;
}

export interface IMenuItem {
    id: string;
    underline?: boolean;
    disableHoverEffect?: boolean;
    content: TText | JSX.Element;
    icon?: IconDefinition;
    arrow?: boolean;
    onClick?: () => void;
    disableClick?: boolean;
}

const MenuDropdownItem: React.FC<IMenuDropdownItem> = React.forwardRef(
  (props: IMenuDropdownItem, ref) => {
    const { children, setOpen } = props;

    const handleMenuItemClick = () => {
      if (children.onClick) children.onClick();
      if (!children.disableClick) setOpen(null);
    };

    return (
      <StyledMenuItem
        data-testid="menu-dropdown-item"
        className={clsx(
          children.disableHoverEffect && 'menuItemHover',
          children.underline && 'underline',
        )}
        onClick={handleMenuItemClick}
        {...props.menuItemProps}
        ref={ref}
      >
        <FontAwesomeIcon
          style={{ marginRight: 10 }}
          color="#707070"
          size="1x"
          icon={children.icon!}
          {...props.dropdownIconStartProps}
        />
        {children.content}
        {children.arrow && (
        <StyledBox>
          <FontAwesomeIcon
            color="rgba(51, 51, 51, 0.5)"
            size="lg"
            icon={faAngleRight}
            {...props.dropDownIconEndProps}
          />
        </StyledBox>
        )}
      </StyledMenuItem>
    );
  },
);

export default MenuDropdownItem;
