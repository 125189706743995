import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 16,
    },
    icon: {
      marginRight: 13,
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginRight: 15,
    },
  }),
  { name: 'ActionDropDown' },
);

export default useStyles;
