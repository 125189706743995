import { AxiosResponse } from 'axios';
import instance, { instanceNoAccountId } from '../Axios/instance';
import { encodeData } from '../../utils/encodeData';

export type TDynamoTableItem = any;
export type TDynamoTableResponse = {
  items: TDynamoTableItem[],
  hasMore: boolean,
  nextStartFrom?: string
}
export type TDynamoTableQueryParams = {
  url: string,
  pageSize?: number,
  startFrom?: string,
  startDate?: string,
  endDate?: string,
  rerenderTable?: boolean,
  componentTestId?: string;
}
export interface IDynamoTableReturnedData {
  accountId: string;
  uploadedDate: string;
}

class DynamoService {
  public static getTableDataAsync = async (
    params: TDynamoTableQueryParams,
    accountId?: string,
  ): Promise<TDynamoTableResponse> => {
    try {
      const response = await DynamoService.getTableData(params, accountId);
      if (response.status === 200) {
        return response.data;
      }
      throw Error(`Error getting table data: ${response.status} - ${response.statusText}`);
    } catch (e) {
      //  Snackbar goes here
      throw Error(e.response?.data?.error || e.message || 'Error something went wrong with loading table data');
    }
  }

  private static getTableData = async (
    params: TDynamoTableQueryParams,
    accountId?: string,
  ): Promise<AxiosResponse<TDynamoTableResponse>> => {
    let {
      url, startFrom, pageSize = 10, startDate, endDate,
    } = params;
    const queryData = {
      // queryKey: queryValue
      startfrom: startFrom,
      pagesize: pageSize,
      startdate: startDate,
      enddate: endDate,
    };

    const containsQueryString = Boolean(url.indexOf('?') >= 0);

    url = `${url}${containsQueryString ? '&' : '?'}${encodeData(queryData)}`;
    return accountId ? instanceNoAccountId.get(url, {
      headers: {
        'account-id': accountId,
      },
    }) : instance.get(url);
  }
}

export default DynamoService;
