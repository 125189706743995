import React, { FC } from 'react';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import {
  NoteStyled,
  NoteIconStyled,
  NotesStyled,
  NotesHeadingStyled,
} from './styles';

const Notes: FC = () => (
  <NotesStyled data-testid="standingOrder-notes">
    <NotesHeadingStyled variant="subtitle1" className="label" fontSize={14}>
      <NoteIconStyled className="icon" icon={faExclamationTriangle} />
      Things to know
    </NotesHeadingStyled>
    <NoteStyled>
      <span>&bull;</span>
      The live FX rate for payments that require foreign exchange will be automatically
      accepted on the date the payment is scheduled to be sent.
    </NoteStyled>
    <NoteStyled>
      <span>&bull;</span>
      Payments scheduled to be sent on a weekend or national holiday will be sent the next
      banking day.
    </NoteStyled>
    <NoteStyled>
      <span>&bull;</span>
      In months with dates that don&apos;t occur every month, such as the 31st, the payment
      will be made on the last day of the month.
    </NoteStyled>
  </NotesStyled>
);

export default Notes;
