
import _default, { makeStyles } from 'theme';

const { palette } = _default;

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '32px',
    position: 'relative',
    '& > .wrapper': {
      position: 'relative',
      '& > .title': {
        display: 'inline-block',
        '& span': {
          fontWeight: '100',
        },
      },
      '& .green': {
        color: palette.primary.main,
        marginRight: '8px',
        fontSize: 16,
      },
      '& .white': {
        color: 'white',
        fontSize: 20,
      },
      '& .description h6': {
        lineHeight: '20px',
        opacity: '70%',
      },
    },
  },
}), { name: 'FormTitle' });

export default useStyles;
