import { Button } from '@mui/material';
import { faDownload, faFileAlt, faRandom } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import useAuthorization from '../../../../../hooks/useAuthorization';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../hooks/useReportsPolling';
import { UserRole } from '../../../../../models/user';
import useStyles from './HeaderButtons.styles';

interface IProps {
  currencyAccountId: string | undefined;
  currencyAccountType: string | undefined;
  handleOpenDrawer: (drawer: 'IAT' | 'AccountDrawer' | false) => void
}

const HeaderButtons: React.FC<IProps> = (
  {
    currencyAccountId, currencyAccountType, handleOpenDrawer,
  }: IProps,
) => {
  const classes = useStyles();
  const { authorized } = useAuthorization([[UserRole.IAT_INPUTTER]]);
  const isVirtualAccount = currencyAccountType?.toUpperCase() === 'VIRTUAL ACCOUNT';
  const { executeReportGeneration } = useReportsPolling(PageType.CurrencyAccount);

  const handleReportGeneration = async (type: FileType, downloadType: DownloadType) => {
    if (currencyAccountId) {
      await executeReportGeneration(type, currencyAccountId, undefined, undefined, downloadType);
    }
  };

  return (
    <div className="dd-privacy-allow">
      {authorized
        && (
          <Tooltip title="Balance transfer">
            <Button color="secondary" className={clsx(classes.button, 'dd-privacy-allow')} onClick={() => handleOpenDrawer('IAT')}>
              <FontAwesomeIcon icon={faRandom} />
            </Button>
          </Tooltip>
        )}
      <Link to={`/app/transactions/${currencyAccountId}`} data-testid="currency-account-transactions-link">
        <Tooltip title="Transactions">
          <Button color="secondary" className={classes.button}>
            <FontAwesomeIcon icon={faFileAlt} />
          </Button>
        </Tooltip>
      </Link>
      {isVirtualAccount
        && (
          <Tooltip title="Download account letter">
            <Button
              onClick={() => handleReportGeneration(FileType.PDF, DownloadType.AccountLetter)}
              className={classes.button}
              color="secondary"
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </Tooltip>
        )}
    </div>
  );
};

export default HeaderButtons;
