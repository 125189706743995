import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../routes.path';
import useStyles from './BannerContent.styles';
import formatIsoDate from '../../../utils/formatIsoDate';

interface IProps {
    oldestOverdueInvoice: any;
    }

const BannerContent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    oldestOverdueInvoice,
  } = props;

  const classes = useStyles();

  const text = `Please be aware that you have an overdue invoice on your account due on ${formatIsoDate(oldestOverdueInvoice?.valueDate)}. Click `;
  const endText = ' to access your invoices';

  return (
    <Box className={classes.text}>
      <Typography className={classes.subtitle}>
        {text}
      </Typography>
      <Link to={routes.invoices}>
        <Typography className={classes.linkText}>
          here
        </Typography>
      </Link>
      <Typography className={classes.subtitle}>
        {endText}
      </Typography>
    </Box>
  );
};

export default BannerContent;
