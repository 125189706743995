import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 0,
      padding: 16,
      '& svg': {
        color: '#212529',
      },
      '&:hover': {
        background: '#2125291A',
      },
    },
  }),
  { name: 'Buttons' },
);

export default useStyles;
