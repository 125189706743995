import {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react';
import { IATDraftResponse } from '@alpha/iat-dtos';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import { ISelectedAccount } from './AccountInfoDrawer';
import InterAccountTransferService from '../../../services/InterAccountTransfer/interAccountTransfer.service';

const useAccountInfoDrawer = (open: boolean,
  selectedAccount: ISelectedAccount | undefined,
  setSelectedDraftIAT: Dispatch<SetStateAction<IATDraftResponse | undefined>>,
  handleOpenDrawer: (drawer: 'IAT' | 'AccountDrawer' | 'PendingTransfers'| false) => void,
  tabIndex?: number,
  multiEntity?: boolean) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [pendingTransfers, setPendingTransfers] = useState<IATDraftResponse[]>([]);
  const [IATRejectModalOpen, setIATRejectModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const sb = useAlphaSnackbar();

  useEffect(() => {
    setSelectedTabIndex(tabIndex || 0);
    if (open) {
      if (multiEntity) {
        getAllPendingTransfers(
          selectedAccount?.account.id,
          selectedAccount?.account.accountId,
        );
      } else {
        getAllPendingTransfers(
          selectedAccount?.account.id,
        );
      }
    }
  }, [open, selectedAccount]);

  const getAllPendingTransfers = async (
    _currencyAccountId: string | undefined,
    accountId?: string,
  ) => {
    try {
      setLoading(true);
      if (!_currencyAccountId) throw Error('Can not find currency account');
      const iatDraftResponse = await InterAccountTransferService.getTransactions(
        _currencyAccountId,
        accountId,
      );
      setPendingTransfers(iatDraftResponse);
    } catch (e) {
      sb.trigger(e.response?.data.error
        || e.message
        || 'There was an error retrieving transfers for this account');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenIATDrawer = (iatDraftResponse: IATDraftResponse): void => {
    try {
      setSelectedDraftIAT(iatDraftResponse);
      handleOpenDrawer('IAT');
    } catch {
      sb.trigger('There was an error loading your chosen transfer');
    }
  };

  const handleOpenIATRejectModal = (iatDraftResponse: IATDraftResponse): void => {
    setSelectedDraftIAT(iatDraftResponse);
    setIATRejectModalOpen(true);
  };

  const handleDeleteIatClick = async (
    iatId: string,
  ): Promise<void> => {
    try {
      await InterAccountTransferService.deletePendingIatById(
        iatId, selectedAccount?.account.accountId,
      );
      sb.trigger('Successfully deleted this IAT', 'success');
    } catch {
      sb.trigger('There was an error while deleting this IAT', 'error');
    }
  };

  return {
    setSelectedTabIndex,
    selectedTabIndex,
    pendingTransfers,
    IATRejectModalOpen,
    loading,
    handleChange: (newValue: number) => setSelectedTabIndex(newValue),
    handleIATRejectModalClose: () => setIATRejectModalOpen(false),
    handleOpenIATDrawer,
    handleOpenIATRejectModal,
    getAllPendingTransfers,
    handleDeleteIatClick,
  };
};

export default useAccountInfoDrawer;
