import _default, { makeStyles } from 'theme';

const {
  palette: { primary, secondary },
  typography,
} = _default;

export const useStyles = makeStyles(
  () => ({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '58px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: secondary.main,
      color: '#efefef',
      padding: '0 0 0 24px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.26)',
      zIndex: 1400,
    },
    menuButton: {
      width: '27px',
      height: '34px',
      textAlign: 'center',
      position: 'relative',
      transform: 'rotate(0deg)',
      transition: '.5s ease-in-out',
      cursor: 'pointer',

      '& > span': {
        display: 'block',
        position: 'absolute',
        height: '2px',
        width: '16px',
        background: '#fff',
        borderRadius: '9px',
        opacity: 1,
        left: '7px',
        transform: 'rotate(0deg)',
        transition: '.25s ease-in-out',

        '&:nth-child(1)': {
          top: '10px',
        },
        '&:nth-child(2)': {
          top: '15px',
        },
        '&:nth-child(3)': {
          top: '20px',
        },
      },

      '&.open > span': {
        '&:nth-child(1)': {
          top: '16px',
          transform: 'rotate(135deg)',
        },
        '&:nth-child(2)': {
          opacity: 0,
          left: '-10px',
        },
        '&:nth-child(3)': {
          top: '16px',
          transform: 'rotate(-135deg)',
        },
      },
    },
    menuButtonText: {
      fontSize: '10px',
      position: 'absolute',
      top: '25px',
      left: '4px',
    },
    logoParent: {
      backgroundColor: primary.main,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 24px',
      marginLeft: '26px',
      width: '150px',
    },
    logo: {
      display: 'block',
      maxHeight: '16px',
    },
    disabledButton: {
      fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontSize: '12px',
      letterSpacing: 1.5,
      backgroundColor: secondary.main,
      maxHeight: '100%',
      color: '#ffffff',
      padding: '13px 20px',
      borderRadius: 0,
      fontWeight: 500,
      pointerEvents: 'none',
      '& > span': {
        color: '#d3d3d3',
      },
    },
    button: {
      fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontSize: '12px',
      letterSpacing: 1.5,
      backgroundColor: secondary.main,
      color: '#ffffff',
      padding: '13px 20px',
      fontWeight: 500,
      borderRadius: 0,
      maxHeight: '100%',
      height: '58px',
      '&:hover': {
        '& > span': {
          color: '#d3d3d3',
        },
      },
      display: 'flex',
      gap: 8,
    },
    separator: {
      height: '36px',
      width: '1px',
      backgroundColor: '#ffffff',
    },
    helpButton: {
      borderRadius: '5px',
      border: '1px solid #ffffff',
      marginRight: '20px',
      textTransform: 'capitalize',
      fontWeight: typography.fontWeightRegular,
      '& > .MuiButton-label': {
        letterSpacing: 0,
      },
    },
    wrapperLeft: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    wrapperRight: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    wrapperSpread: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tabsAndCta: {
      transform: 'translateY(-7px)',
      display: 'flex',
      justifyContent: 'space-between',
    },
    spacing: {
      transform: 'translateY(-7px)',
    },
    apPageTabs: { flexGrow: 1 },
    apRightAlignedCta: {
      alignContent: 'right',
      display: 'flex',
      alignItems: 'flex-end',
    },
  }),
  { name: 'Header' },
);

export default useStyles;
