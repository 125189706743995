import { BeneficiaryDto } from '@alpha/bene-dtos/lib/responses/BeneficiaryDto';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { TUserAccounts, UserRole } from '../models/user';
import AuthService from '../services/Auth/auth.service';

const useShareBeneficiaryDrawer = (
  setSelectedBeneficiary: Dispatch<SetStateAction<BeneficiaryDto | undefined>>,
) => {
  const [accountBeneId, setAccountBeneId] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');
  const [availableAccounts, setAvailableAccounts] = useState<TUserAccounts>([]);

  useEffect(() => {
    getAvailableAccountsToShareBeneficiary();
  }, []);

  const getAvailableAccountsToShareBeneficiary = async (_accountId?: string) => {
    const beneInputterAccounts = AuthService
      .getEntitiesUserHasRoleFor(UserRole.BENEFICIARY_INPUTTER);
    const beneApproverOwnAccounts = AuthService
      .getEntitiesUserHasRoleFor(UserRole.BENEFICIARY_APPROVER_OWN);

    Promise.all([beneInputterAccounts, beneApproverOwnAccounts]).then((accounts) => {
      let tempAvailableAccounts;
      const tempBeneApproverOwnAccounts = accounts[1];
      const tempBeneInputterAccounts = accounts[0];
      if (_accountId) {
        tempAvailableAccounts = tempBeneApproverOwnAccounts
          .filter((account) => account.id !== _accountId)
          .filter((account) => tempBeneInputterAccounts
            .some((account2) => account.id === account2.id));
      } else {
        tempAvailableAccounts = tempBeneApproverOwnAccounts
          .filter((account) => tempBeneInputterAccounts
            .some((account2) => account.id === account2.id));
      }
      setAvailableAccounts(tempAvailableAccounts);
    });
  };

  const handleBeneficiaryShareButtonClick = (_accountBeneId?: string, _accountId?: string) => {
    if (typeof _accountBeneId === 'string') {
      setAccountBeneId(_accountBeneId);
    } else {
      setAccountBeneId('');
    }
    if (_accountId) {
      setAccountId(_accountId);
    } else {
      setAccountId('');
    }
    setSelectedBeneficiary(undefined);
  };

  return {
    availableAccounts,
    accountBeneId,
    accountId,
    handleBeneficiaryShareButtonClick,
    getAvailableAccountsToShareBeneficiary,
  };
};

export default useShareBeneficiaryDrawer;
