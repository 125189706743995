import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import styled from '@mui/styled-engine';
import { BaseButton } from 'components/Buttons';

export const DeleteButtonStyled = styled(BaseButton)({
  padding: 8,
  minWidth: 'inherit',
  width: 30,
  height: 30,
  boxShadow: 'none',
  backgroundColor: '#fff',
  border: '1px solid #e3eaf2',
  borderRadius: 4,
  fontSize: 14,
  color: '#c21917',
});

export const ViewButtonStyled = styled(BaseButton)({
  padding: 8,
  minWidth: 'inherit',
  height: 30,
  boxShadow: 'none',
  backgroundColor: '#fff',
  border: '1px solid #e3eaf2',
  borderRadius: 4,
  fontSize: 14,
  marginRight: 10,
  color: '#000',
  textTransform: 'none',
  letterSpacing: '0',
});

export const ScheduledTableHeadStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& button': {
    position: 'absolute',
    right: 40,
    width: '46px',
    height: '43px',

    '& svg': {
      color: '#c21917',
      margin: 0,
      top: 0,
    },
  },
});

export const DebitTitleStyled = styled(Typography)({
  color: '#1E8777',
  textTransform: 'uppercase',
  fontSize: '11px',
  fontWight: 'bold',
});

export const AmountStyled = styled(Typography)({
  fontSize: '24px',
  lineHeight: '36px',

  '& span': {
    color: '#737373',
  },
});

export const EntityStyled = styled(Typography)({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#909090',
});

export const DrawerBodyStyled = styled('div')({
  padding: '0 40px 40px',
});

export const DrawerSubtitleStyled = styled(Typography)({
  fontWeight: 'bold',
  marginTop: '55px',
  marginBottom: '20px',
  padding: '0 !important',

  '&:first-child': {
    marginTop: 0,
  },
});

export const DefinitionStyled = styled(Typography)({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #C9C9C9',
  alignItems: 'center',
  padding: '0 !important',

  '& > span': {
    color: '#909090',
  },
});

export const FrequencyIconStyled = styled(FontAwesomeIcon)({
  marginRight: '5px',
});
