import React, { memo, useState } from 'react';
import { faExclamationTriangle, faTrash } from '@fortawesome/pro-light-svg-icons';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import { IconActionButton } from 'components/Buttons';
import useStyles from '../EntityGroupCard.style';

interface IProps {
  canEditOrDeleteGroup?: boolean;
  deleteGroup: () => Promise<void>;
}

const EntityGroupCardButtons: React.FC<IProps> = (props: IProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { canEditOrDeleteGroup, deleteGroup } = props;
  const classes = useStyles();
  const handleDeleteGroupClick = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <div>
      {canEditOrDeleteGroup && (
        <>
          <IconActionButton
            onClick={handleDeleteGroupClick}
            testId="testId"
            color="secondary"
            icon={faTrash}
            rounded
            variant="outlined"
            className={classes.actionButtons}
          />
          <DecisionModal
            open={modalOpen}
            title="Delete Group?"
            subtitle=""
            actionButtonText="DELETE"
            handleActionButton={deleteGroup}
            closeButtonText="CANCEL"
            handleClose={handleClose}
            icon={faExclamationTriangle}
          />
        </>
      )}
    </div>
  );
};

export default memo(EntityGroupCardButtons);
