import { Beneficiary } from '@alpha/bene-dtos';
import React, { useState } from 'react';
import BeneficiaryDrawer, {
  TBeneficiaryDrawerBeneficiary,
} from '../../../../../../components/Drawer/BeneficiaryDrawer/BeneficiaryDrawer';
import { TPayment } from '../../../../../../models/payments';
import usePaymentsContext from '../../ManualPayment/usePaymentsContext';
import useSinglePaymentContext from '../../ManualPayment/useSinglePaymentContext';

import PaymentsTable from '../PaymentsTable/PaymentsTable';
import EmptyPaymentsTable from './EmptyPaymentsTable';
import useStyles from './PaymentsList.styles';
import PaymentTotalStat from './PaymentTotalStat';

const PaymentsList: React.FC = () => {
  const styles = useStyles();
  const { paymentsContext } = usePaymentsContext();
  const { singlePaymentContext } = useSinglePaymentContext();
  const [
    selectedBeneficiary,
    setSelectedBeneficiary,
  ] = useState<TBeneficiaryDrawerBeneficiary>();

  const handleClose = () => {
    setSelectedBeneficiary(undefined);
  };

  const createBeneficiaryObject = async (
    beneficiary: Beneficiary | undefined,
  ): Promise<TBeneficiaryDrawerBeneficiary | undefined> => {
    if (!beneficiary) {
      setSelectedBeneficiary(undefined);
      return;
    }
    setSelectedBeneficiary(beneficiary);
  };

  const handleSetSelectedBeneficiary = async (
    beneficiary: Beneficiary | undefined,
  ) => {
    await createBeneficiaryObject(beneficiary);
  };

  const isFxBatch = (): boolean => paymentsContext.fundingAccount?.currencyCode
!== paymentsContext?.currentPayments[0].beneficiary?.currencyCode;

  const handleSetCurrentPayment = (currentPayment: TPayment, index: number) => {
    singlePaymentContext!.setCurrentPayment(currentPayment);
    singlePaymentContext!.setEditRow(index);
  };

  return (
    <div className={styles.root}>
      <PaymentTotalStat paymentTotal={paymentsContext.currentPayments.length} />
      {paymentsContext.currentPayments.length ? (
        <PaymentsTable
          fxBatch={isFxBatch()}
          fundingAccount={paymentsContext.fundingAccount!}
          payments={paymentsContext.currentPayments}
          handleSetCurrentPayments={paymentsContext.handleSetCurrentPayments}
          handleSetCurrentPayment={handleSetCurrentPayment}
          handleSetSelectedBeneficiary={handleSetSelectedBeneficiary}
        />
      ) : (
        <EmptyPaymentsTable />
      )}
      <BeneficiaryDrawer
        beneficiary={selectedBeneficiary}
        open={Boolean(selectedBeneficiary)}
        handleClose={handleClose}
      />
    </div>
  );
};

export default PaymentsList;
