import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    infoPrimary: {
      display: 'block',
      fontSize: 18,
    },
    infoSecondary: {
      color: 'rgb(144, 144, 144)',
      fontSize: 14,
    },
    entity: {
      paddingRight: 20,
    },
    friendlyNameNoButton: {
      maxWidth: 400,
      paddingRight: 20,
    },
    friendlyName: {
      maxWidth: 400,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& .information': {
        width: 'max-content',
        flex: 1,
        overflow: 'hidden',
        paddingRight: 20,
      },
      '& .bellContainer': {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        width: 170,
        borderLeft: '1px solid rgba(112, 112, 112, 0.1)',
        paddingLeft: 20,
        alignItems: 'center',
        display: 'flex',
        '& .bellStatus': {
          fontWeight: 'bold',
          '& svg': {
            marginRight: 6,
            transform: 'translate(-2px, 1px)',
          },
        },
      },
    },
  }),
  { name: 'AccountsTable' },
);

export default useStyles;
