import { combineReducers } from '@reduxjs/toolkit';

import { userSlice } from './user/user.reducer';
import { paymentsSlice } from './payments/payments.reducer';
import { requestsSlice } from './requests/requests.reducer';
import { notificationsSlice } from './notifications/notifications.reducer';
import { currenciesSlice } from './currencies/currencies.reducer';
import { authySlice } from './authy/reducer';
import { generatePdfSlice } from './generateReport/reducer';
import { accountSlice } from './accounts/accounts.reducer';
import { slice as initialPathSlice } from './initialPath/reducer';
import { entityGroupsSlice } from './entityGroups/reducer';
import { featureFlagsSlice } from './featureFlags/reducer';
import { outstandingDashboardTablesSlice } from './outstandingTables/outstandingTables.reducer';
import { transactionsPageSlice } from './transactions/transactionsPage.reducer';
import { userManagementSlice } from './userManagement/userManagement.reducer';

export default combineReducers({
  [userSlice.name]: userSlice.reducer,
  [paymentsSlice.name]: paymentsSlice.reducer,
  [requestsSlice.name]: requestsSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [currenciesSlice.name]: currenciesSlice.reducer,
  [authySlice.name]: authySlice.reducer,
  [generatePdfSlice.name]: generatePdfSlice.reducer,
  [initialPathSlice.name]: initialPathSlice.reducer,
  [entityGroupsSlice.name]: entityGroupsSlice.reducer,
  [accountSlice.name]: accountSlice.reducer,
  [outstandingDashboardTablesSlice.name]: outstandingDashboardTablesSlice.reducer,
  [featureFlagsSlice.name]: featureFlagsSlice.reducer,
  [transactionsPageSlice.name]: transactionsPageSlice.reducer,
  [userManagementSlice.name]: userManagementSlice.reducer,
});
