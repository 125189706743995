import { makeStyles } from 'theme';

const useStyles = makeStyles(
  {
    root: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      overflow: 'hidden',
      top: '0',
      left: '0',
      '& > div': {
        position: 'relative',
      },
    },
  },
  { name: 'BackdropLoader' },
);

export default useStyles;
