import React, { FC, useCallback, memo } from 'react';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VariantType } from 'notistack';
import { TSnackbarProps } from './Snackbar.interfaces';
import StyledSnack from './Snackbar.styles';

export const Snackbar: FC<TSnackbarProps> = memo(({
  icon, keyId, message, title, type, closeSnackbar,
}) => {
  const _type: VariantType = type === 'error' ? 'warning' : type;
  const closeHandler = useCallback(() => {
    typeof closeSnackbar === 'function' && closeSnackbar(keyId);
  }, [keyId, closeSnackbar]);

  return (
    <StyledSnack type={_type} bgColor="orange" data-testid={keyId}>
      <Box className="iconWrapper">
        <FontAwesomeIcon icon={icon} />
      </Box>

      <Box className="content">
        <Box className="header">
          {title && <Box className="title">{title}</Box>}
          <Box className="message">{message}</Box>
        </Box>
        <Box className="close" onClick={closeHandler}>
          ×
        </Box>
      </Box>
    </StyledSnack>
  );
});
