import { StyledDivider } from 'components/StyledDivider';
import React, { useEffect, useState } from 'react';
import { TCurrencyAccount } from '../../../../../../models/currencyAccounts';
import Beneficiary from './Beneficiary/Beneficiary';
import useStyles from './ManualPayment.styles';
import PaymentFields from './PaymentFields/PaymentFields';
import useSinglePaymentContext from './useSinglePaymentContext';

export interface IManualPaymentProps {
  selectedDebitingAccount?: TCurrencyAccount;
}
const ManualPayment: React.FC<IManualPaymentProps> = ({
  selectedDebitingAccount,
}: IManualPaymentProps) => {
  const styles = useStyles();
  const { singlePaymentContext } = useSinglePaymentContext();
  const [inputValue, setInputValue] = useState<string>();

  useEffect(() => {
    setInputValue(singlePaymentContext?.currentPayment?.beneficiary?.name);
  }, [singlePaymentContext?.currentPayment?.beneficiary]);

  if (!singlePaymentContext) return <></>;

  const handleBeneficiaryDrawerToggle = (_open: boolean) => {
    singlePaymentContext.handleBeneficiaryDrawerToggle(_open);
    singlePaymentContext.setCurrentPayment({
      ...singlePaymentContext.currentPayment,
      beneficiary: undefined,
    });
    singlePaymentContext.setEditRow(undefined);
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <Beneficiary
            selectedDebitingAccount={selectedDebitingAccount}
            open={singlePaymentContext.open}
            handleBeneficiaryDrawerToggle={handleBeneficiaryDrawerToggle}
            value={inputValue}
          />

          <PaymentFields />
        </div>
      </div>
      <StyledDivider />
    </>
  );
};

export default ManualPayment;
