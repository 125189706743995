import { Typography, styled } from '@mui/material';

export const FormTitleStyled = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '10px',
  marginTop: '30px',
  textTransform: 'uppercase',
  '&:first-child': {
    marginTop: '0',
  },
}));

export const FormNoteStyled = styled('div')(() => ({
  color: '#34343473',
  marginTop: '5px',
}));
