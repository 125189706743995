import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initiateUserRoleRequestApproval } from 'store/authy/actions';
import { Typography } from '@mui/material';
import { UserRoleRequestResponse } from '@portal/user-types';
import AcknowledgeModal from 'components/Modals/AcknowledgeModal';
import { useUserManagementContext } from 'domain/UserManagement/context';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import UserManagementService from 'services/UserManagement/userManagement.service';
import { UserTables } from 'domain/UserManagement/context/index.interfaces';
import { StyledApproveButton, StyledRejectButton } from './index.styles';

interface IProps {
  selectedRequests: UserRoleRequestResponse[];
}

const BatchRequestsControls: React.FC<IProps> = (props: IProps) => {
  const { selectedRequests } = props;

  const [buttonsCounts, setButtonsCounts] = useState<
    {
      canRejectCount: number, canApproveCount: number,
      cantRejectCount: number, cantApproveCount: number
    }>({
      canRejectCount: 0,
      canApproveCount: 0,
      cantRejectCount: 0,
      cantApproveCount: 0,
    });
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<{open: boolean, mode: 'reject' | 'approve' }>({
    open: false,
    mode: 'approve',
  });
  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();
  const { setRefreshDataTable } = useUserManagementContext();

  useEffect(() => {
    const counts = selectedRequests.reduce(
      ({
        canRejectCount, canApproveCount, cantRejectCount, cantApproveCount,
      }: {
        canRejectCount: number,
        canApproveCount: number,
        cantRejectCount: number,
        cantApproveCount: number,
      }, currentValue: UserRoleRequestResponse) => ({
        canRejectCount: currentValue.isRejectable ? canRejectCount + 1 : canRejectCount,
        canApproveCount: currentValue.isApprovable ? canApproveCount + 1 : canApproveCount,
        cantRejectCount: !currentValue.isRejectable ? cantRejectCount + 1 : cantRejectCount,
        cantApproveCount: !currentValue.isApprovable ? cantApproveCount + 1 : cantApproveCount,
      }), {
        canRejectCount: 0,
        canApproveCount: 0,
        cantRejectCount: 0,
        cantApproveCount: 0,
      },
    );
    setButtonsCounts(counts);
  }, [selectedRequests]);

  const handleApproveUserRoleRequest = async () => {
    try {
      const canApproveIds = selectedRequests.reduce(
        (accumulator: string[], current: UserRoleRequestResponse) => {
          if (current.isApprovable) {
            return [
              ...accumulator,
              current.id,
            ];
          }
          return accumulator;
        }, [],
      );

      dispatch(
        initiateUserRoleRequestApproval({
          type: 'USER_ROLE_REQUEST',
          requestIds: canApproveIds,
        }),
      );
      setOpenModal({ open: false, mode: openModal.mode });
    } catch {
      throw Error('There was an error approving your request');
    }
  };

  const handleBatchRejectRequest = async (): Promise<void> => {
    setLoading(true);
    try {
      const canRejectIds = selectedRequests.reduce(
        (accumulator: string[], current: UserRoleRequestResponse) => {
          if (current.isRejectable) {
            return [
              ...accumulator,
              current.id,
            ];
          }
          return accumulator;
        }, [],
      );
      await UserManagementService.rejectUserRequestsBatch(
        canRejectIds,
      );
      setOpenModal({ open: false, mode: openModal.mode });
      setRefreshDataTable(UserTables.PENDING_APPROVAL);
      sb.trigger('Successfully rejected permission change request', 'success');
    } catch {
      sb.trigger('There was an error rejecting this request', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography
        variant="h6"
      >
        (
        {selectedRequests.length}
        )
        {' '}
        {selectedRequests.length > 1 ? 'requests ' : 'request ' }
        within the pending approval table
        {' '}
        {selectedRequests.length > 1 ? 'are' : 'is' }
        {' '}
        selected

        {buttonsCounts.cantApproveCount > 0
          && (
            <span style={{ margin: '2px' }}>
              , You don’t have approval permission for (
              {buttonsCounts.cantApproveCount}
              )
              {' '}
              {buttonsCounts.cantApproveCount > 1 ? 'requests' : 'request' }
            </span>
          )}

        {buttonsCounts.cantRejectCount > 0
          && (
            <span style={{ margin: '2px' }}>
              , You don’t have reject permission for (
              {buttonsCounts.cantRejectCount}
              )
              {' '}
              {buttonsCounts.cantRejectCount > 1 ? 'requests.' : 'request.' }
            </span>
          )}
      </Typography>
      <div>
        <StyledRejectButton
          variant="contained"
          disableElevation
          type="button"
          onClick={() => {
            setOpenModal({ open: true, mode: 'reject' });
          }}
          disabled={buttonsCounts.canRejectCount === 0}
        >
          Reject Requests
          {' '}
          (
          {buttonsCounts.canRejectCount}
          )
        </StyledRejectButton>

        <StyledApproveButton
          variant="contained"
          disableElevation
          type="button"
          onClick={() => {
            setOpenModal({ open: true, mode: 'approve' });
          }}
          disabled={buttonsCounts.canApproveCount === 0}
        >
          Approve requests
          {' '}
          (
          {buttonsCounts.canApproveCount}
          )
        </StyledApproveButton>

        <AcknowledgeModal
          open={openModal.open}
          content={
            openModal.mode === 'approve'
              ? (
                <Typography variant="h5">
                  Are you sure you would like to approve all the selected request?
                </Typography>
              )
              : <Typography variant="h5">Are you sure you would like to reject all the selected requests?</Typography>
        }
          actionButtonText="Cancel"
          handleActionButton={() => setOpenModal({ open: false, mode: openModal.mode })}
          handleActionButtonSecond={() => {
            if (openModal.mode === 'reject') {
              handleBatchRejectRequest();
            } else {
              handleApproveUserRoleRequest();
            }
          }}
          actionButtonColour="inherit"
          actionButtonSecondColor={openModal.mode === 'approve' ? 'primary' : 'error'}
          actionButtonSecondText="Confirm"
          loading={loading}
        />
      </div>
    </>
  );
};
export default BatchRequestsControls;
