import {
  styled,
  Button,
  Typography,
  Divider,
  FormControlLabel,
} from '@mui/material';
import { Form } from 'formik';

const CentredDiv = styled('div')({
  width: '800px',
});

const ChoiceDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
  borderRadius: 7,
  outline: 'none',
  marginTop: 20,

  '> form': {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  div: {
    background: 'none',
  },
});

const StyledDivider = styled(Divider)({
  color: 'white',
  borderColor: 'white',
  textTransform: 'uppercase',
  '&::before, &::after': {
    borderTop: 'thin solid #ffffff2b',
  },
  '&.MuiDivider-vertical': {
    alignSelf: 'center',
    height: 70,
    borderColor: '#ffffff2b',
  },
});

const UnAuthorisedPage = styled('div')({
  background: "url('/static/images/login.jpg') bottom center",
  backgroundRepeat: 'repeat-y',
  backgroundAttachment: 'scroll',
  backgroundSize: 'cover',
  minHeight: '100vh',
  minWidth: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledAuthHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 20,

  '& .MuiTypography-body1': {
    color: 'white',
    fontSize: '42px',
    fontWeight: 400,
  },

  '> img': {
    display: 'block',
    maxWidth: 95,
    maxHeight: 95,
    width: 'auto',
    height: 'auto',
    paddingBottom: 10,
  },

});

const StyledSubtitle = styled(Typography)({
  color: 'white',
  fontSize: '20px',
  fontWeight: 400,
});

const StyledLabel = styled(Typography)({
  color: 'white',
  fontSize: '14px',
  marginBottom: '8px',
});

const StyledFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  marginLeft: 0,
});

const StyledInputField = styled('input')({
  width: '100%',
  color: 'white',
  fontSize: 13,
  minHeight: 50,
  paddingLeft: 20,
  border: 'none',
  backgroundColor: 'rgb(247, 247, 247, 0.13)',
  fontFamily: '\'Source Sans Pro\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
  '&:-internal-autofill-selected': {
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: '#ffffff',
    transition: 'background-color 5000s ease-in-out 0s',
    boxShadow: 'inset 0 0 40px 100px rgb(247, 247, 247, 0.13)',
  },
  '&:focus-visible': {
    outline: 'none',
  },
});

const StyledFieldError = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  color: '#C64545',
  marginTop: 2,
  marginBottom: 10,
});

const StyledLoginButton = styled(Button)({
  textTransform: 'uppercase',
  minHeight: 50,
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: 1.8,
  padding: '8px 22px',
  borderRadius: 1,
  color: 'white',
  backgroundColor: '#23232380',
  '&:hover': {
    color: '#098375',
  },
});

const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

export {
  StyledFieldError,
  StyledLabel,
  CentredDiv,
  ChoiceDiv,
  UnAuthorisedPage,
  StyledSubtitle,
  StyledDivider,
  StyledLoginButton,
  StyledInputField,
  StyledFormControlLabel,
  StyledAuthHeader,
  StyledForm,
};
