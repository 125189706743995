import { makeStyles } from 'theme';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    opacity: 1,
    '& .assetWrapper': {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      opacity: 1,
      transition: 'opacity 0.5s linear',
    },
    '& .assetContentWrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textAlign: 'left',
      marginLeft: '32px',
    },
    '& .assetImage': {
      opacity: '1',
      transition: 'opacity 0.5s linear',
      width: '245px',
      height: '500px',
    },
    '& .assetTitle': {
      color: 'white',
      fontWeight: 100,
      fontSize: '20px',
      marginBottom: '16px',
    },
    '& .assetDescription': {
      color: 'white',
      fontWeight: 400,
      fontSize: '16px',
      opacity: '70%',
    },
    '& .assetFooter': {
      marginTop: '16px',
    },
  },
  fullOpacity: {
    opacity: '1 !important',
  },
  noOpacity: {
    opacity: '0 !important',
  },
}), { name: 'SetupHelperText' });

export default useStyles;
