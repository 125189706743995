import theme, { makeStyles } from 'theme';

const useStyles = makeStyles(
  {
    root: {
      padding: '32px 0 16px 0',
    },
    wrapper: {
      width: '50%',
      minWidth: '380px',

      '& .beneficiary': {
        '& .beneficiaryDrawerTriggerButton': {
          backgroundColor: 'transparent',
          border: 'none',
          '&:hover': {
            cursor: 'pointer',
          },
        },
        '& .beneficiaryDrawerTrigger': {
          color: theme.palette?.primary?.main,
          textDecoration: 'underline',
          marginTop: '8px',
        },
        '& .title': {
          marginBottom: '40px',
          '& h2': {
            fontWeight: 600,
          },
        },
      },
      '& .label': {
        marginBottom: 7,
      },
      '& .paymentFields': {
        position: 'relative',
        '& .label': {
          marginTop: '17px',
          marginBottom: 0,
        },
        '& .MuiFilledInput-root': {
          height: '53px',
        },
        '& .MuiFormControl-root': {
          width: '100%',
        },
        '& .column.longInput': {
          width: '500px',
        },
        '& .column.buttonColumn': {
          margin: '0',
          marginBottom: '-10px',
        },
        '& .column > div': {
          minHeight: '83px',
          marginBottom: '16px',
        },
        '& .backdropLoader': {
          height: '100%',
        },
        '& .paymentAmount > div > p': {
          display: 'none',
        },
      },
      '& .datePicker': {
        width: '89px',
        '& .MuiTextField-root .MuiInputBase-input': {
          height: '53px',
        },
        '& .MuiTextField-root .MuiInputLabel-root': {
          fontSize: '18px',
          fontWeight: 400,
          marginBottom: '7px',
          color: '#212529',
          fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
        },
        '& label + .MuiInput-formControl': {
          marginTop: '23px',
        },
        '& svg': {
          display: 'none',
        },
        '& > div': {
          width: '100%',
        },
      },
      '& .addToBatch': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
        '& button': {
          height: '53px',
        },
      },
      '& .placeholder': {
        color: '#34343473',
      },
      '& .paymentAmount': {
        '& input': {
          width: '100%',
        },
      },
      '& .paymentOptionDrawerTriggerButton': {
        backgroundColor: 'transparent',
        border: 'none',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      '& .paymentOptionDrawerTrigger': {
        color: theme.palette?.primary?.main,
        textDecoration: 'underline',
        marginTop: '8px',
      },
      '& .submitButton-holder': {
        marginTop: '40px',
        '& button': {
          width: 'auto',
          textTransform: 'none',
          letterSpacing: '0',
          fontWeight: 'normal',
        },
      },
      '& .paymentOptionLink': {
        marginBottom: '20px',
        '& button': {
          padding: 0,
        },
      },
    },
  },
  { name: 'manualPayment' },
);

export default useStyles;
