import { Box, styled } from '@mui/material';
import BaseModal from '../BaseModal';

const StyledBaseModal = styled(BaseModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledBox = styled(Box)({
  backgroundColor: 'white',
  padding: '24px 0',
});

export { StyledBaseModal, StyledBox };
