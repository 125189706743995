import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  drawer: {
    '& .MuiDrawer-paper': {
      width: '80%',
      maxWidth: '1200px',
      '& > .MuiBox-root': {
        paddingTop: '60px',
      },
    },
  },
  alertMessage: {
    width: '91%',
    margin: '0 auto',
  },
  lineBreakMargin: {
    margin: '10px 0',
  },
  body: {
    height: '100%',
    '& > .MuiBox-root': {
      height: '100%',
    },
  },
  footer: {
    margin: '32px 0',
  },
}), { name: 'CreateBeneficiaryDrawer' });

export default useStyles;
