import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AccountsState {
  userAccountsLoading?: boolean;
  selectedAccounts: iSelectedAccounts[],
  allowedAccounts: iAllowedAccounts[],
  placeholderText: string,
  KYCOpen: boolean,
}
export interface iAllowedAccounts {
  id: string,
  name: string,
  kycReviewDate: string,
  reviewDue: boolean,
  reviewOverDue: boolean,
  accountFrozen: boolean,
  isMicroEnterprise: boolean,
  dynamicFxSpreadEnabled: boolean,
}
export interface iSelectedAccounts {
  id: string,
  name: string,
  kycReviewDate: string,
  reviewDue: boolean,
  reviewOverDue: boolean,
}

const initialState = {
  selectedAccounts: [],
  allowedAccounts: [],
  placeholderText: 'Viewing All Entities',
  KYCOpen: false,
} as AccountsState;

export const accountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    updateLoadingState: (state, action :PayloadAction<{
      loading: boolean
    }>) :AccountsState => ({
      ...state,
      userAccountsLoading: action.payload.loading,
    }),
    updateSelectedAccounts: (state,
      action: PayloadAction<iSelectedAccounts[]>): AccountsState => ({
      ...state,
      selectedAccounts: action.payload,
    }),
    updateAllowedAccounts: (state,
      action: PayloadAction<iAllowedAccounts[]>): AccountsState => ({
      ...state,
      allowedAccounts: action.payload,
    }),
    updatePlaceholderText: (state,
      action: PayloadAction<string>): AccountsState => ({
      ...state,
      placeholderText: action.payload,
    }),
    toggleKYCModal: (state, action: PayloadAction<boolean>):AccountsState => ({
      ...state,
      KYCOpen: action.payload,
    }),
  },
});

export const { actions } = accountSlice;
