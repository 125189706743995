import { LedgerDto } from '@alpha/currency-accounts-dtos';
import { Button } from '@mui/material';
import React from 'react';
import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import useDynamoTable from '../../../hooks/useDynamoTable';
import { formatNumber } from '../../../utils/currency.helpers';
import formatIsoDate from '../../../utils/formatIsoDate';
import DisplayTable from './DisplayTable/DisplayTable';
import useStyles from './TransactionsTable.styles';

export const componentTestIds = {
  DisplayTable: 'display-loader-component',
};
export interface ITransactionTable {
  startDate: string,
  endDate: string,
  componentTestId?: string,
  currencyAccountId?: string,
  handleSetTransaction: (clickedTableRow: LedgerDto) => Promise<void>
}

const TransactionsTable: React.FC<ITransactionTable> = (
  props: ITransactionTable,
) => {
  const {
    startDate,
    endDate,
    componentTestId,
    currencyAccountId,
    handleSetTransaction,
  } = props;
  const classes = useStyles();
  const pageSize = 15;

  const table = useDynamoTable({
    url: `/ca/currency-accounts/${currencyAccountId}/ledgers`,
    pageSize,
    startDate,
    endDate,
  });

  const handleIdRendering = (friendlyId: string): JSX.Element | string => {
    if (friendlyId.match('L0')) {
      return friendlyId;
    } return (
      <Button
        disableRipple
        className={classes.button}
      >
        {friendlyId}
      </Button>
    );
  };
  const tableData = () => table.data!.map((column: LedgerDto) => ({
    ...column,
    friendlyId: column.friendlyId ? (
      handleIdRendering(column.friendlyId)
    ) : '-',
    creditAmount: column.creditAmount ? (
      <span className={classes.creditAmount}>
        {formatNumber(column.creditAmount, 2)}
      </span>
    ) : (
      '-'
    ),
    debitAmount: column.debitAmount ? (
      <span className={classes.debitAmount}>
        {formatNumber(column.debitAmount, 2)}
      </span>
    ) : (
      '-'
    ),
    runningBalance: column.runningBalance ? (
      <span className={classes.runningBalance}>
        {formatNumber(column.runningBalance, 2)}
      </span>
    ) : (
      '-'
    ),
    date: formatIsoDate(column.date),
  }));
  if (table.loading) {
    return (
      <BackdropLoader testId="loader" />
    );
  }

  return (
    <div data-testid={componentTestId} className={classes.tableWrapper}>
      <DisplayTable
        componentTestId={componentTestIds.DisplayTable}
        table={table}
        currencyAccountId={currencyAccountId}
        tableData={tableData}
        handleSetTransaction={handleSetTransaction}
      />
    </div>
  );
};

export default TransactionsTable;
