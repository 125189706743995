import React, { memo } from 'react';
import { Box, Button } from '@mui/material';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import { ActionButton, GhostButton } from 'components/Buttons';
import { Flags } from 'react-feature-flags';
import BeneFraudAcknowledgeModal from 'domain/Beneficiaries/BeneFraudAcknowledgeModal';
import FooterWrapper from '../../../../../../components/Payments/FooterWrapper/FooterWrapper';
import navigateToStartOfJourney from '../../../../../../utils/navigateToStartOfJourney';
import useStyles from './ApproveRejectFooter.styles';

interface IProps {
  buttonText: string;
  batchName: string;
  modalOpen: boolean;
  loading: boolean;
  canReject?: boolean;
  canApprove: boolean;
  handleClose: () => void;
  handleRejectBatchButton: () => void;
  handleApprovePayment: () => Promise<void>;
  handleRejectPayment: () => Promise<void>;
  handleModalConfirmation: () => void;
  acknowledgeModalOpen: boolean;
  setAckowledgdeModalOpen: (ackowledgeModalOpen: boolean) => void;
  paymentDraftsLoading: boolean;
}

const ApproveRejectFooter: React.FC<IProps> = (props: IProps) => {
  const {
    buttonText,
    batchName,
    modalOpen,
    loading,
    handleClose,
    handleApprovePayment,
    handleRejectBatchButton,
    handleRejectPayment,
    canReject,
    canApprove,
    handleModalConfirmation,
    acknowledgeModalOpen,
    setAckowledgdeModalOpen,
    paymentDraftsLoading,
  } = props;

  const handleClick = () => {
    navigateToStartOfJourney();
  };

  const styles = useStyles();

  return (
    <>
      <FooterWrapper>
        <Button
          color="secondary"
          onClick={handleClick}
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          Exit
        </Button>
        <Box>
          {canReject && (
            <GhostButton
              className={styles.errorButton}
              onClick={handleRejectBatchButton}
              colorVariant="warning"
              data-testid="reject-batch-button"
            >
              Reject Batch
            </GhostButton>
          )}
          {canApprove && (
            <ActionButton
              disabled={paymentDraftsLoading}
              data-testid="approve-batch-button"
              size="large"
              onClick={handleApprovePayment}
            >
              {buttonText}
            </ActionButton>
          )}
          {acknowledgeModalOpen && (
            <Flags authorizedFlags={['fraudModalEnabled']}>
              <BeneFraudAcknowledgeModal
                handleConfirmation={handleModalConfirmation}
                handleClose={() => setAckowledgdeModalOpen(false)}
                acknowledgeModalOpen={acknowledgeModalOpen}
              />
            </Flags>
          )}
        </Box>
      </FooterWrapper>
      <DecisionModal
        open={modalOpen}
        title={`Reject ${batchName.length > 35 ? ' Batch' : `${batchName}`}`}
        subtitle="You're about to reject this batch.
        The payment will not be processed."
        actionButtonText="YES, REJECT BATCH"
        handleActionButton={handleRejectPayment}
        closeButtonText="CANCEL"
        handleClose={handleClose}
        icon={faExclamationTriangle}
        loading={loading}
      />
    </>
  );
};

export default memo(ApproveRejectFooter);
