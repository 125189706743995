import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState, Dispatch, SetStateAction } from 'react';
import { originalUseSnackbar } from 'components/Snackbar';
import AuthService from '../services/Auth/auth.service';
import history from '../services/history/browserHistory';
import routes from '../routes.path';

const initialValueSendEmail = {
  username: '',
};

const initialValueResetPassword = {
  verificationCode: '',
  password: '',
  confirmPassword: '',
};

const validationSendEmail = yup.object({
  username: yup.string().required('Username is required'),
});

const validationSubmit = yup.object({
  verificationCode: yup.string().required('Verfication code is required'),
  password: yup.string().required('Password is required'),
});

const usePasswordReset = (
  username?: string,
  setUsername?: Dispatch<SetStateAction<string | undefined>>,
  setEmail?: Dispatch<SetStateAction<string | undefined>>,
) => {
  const snackbar = originalUseSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const handleResetPasswordEmail = async (submittedUsername: string, callback: Function) => {
    try {
      setLoading(true);
      if (!setUsername || !setEmail) throw Error();
      const response = await AuthService.forgotPassword(submittedUsername);
      callback(`Verification code sent to ${response.CodeDeliveryDetails.Destination}`, {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
      setUsername(submittedUsername);
      setEmail(response.CodeDeliveryDetails.Destination);
      history.push({
        pathname: routes.auth.passwordReset.verifyMfa,
        state: { submittedUsername },
      });
    } catch (e) {
      snackbar.enqueueSnackbar('There was an error resetting password', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
        className: 'dd-privacy-allow',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResetPasswordSubmit = async (verificationCode: string,
    submittedUsername: string, password: string) => {
    try {
      setLoading(true);
      await AuthService.forgotPasswordSubmit(submittedUsername, verificationCode, password);
      history.push(routes.auth.passwordReset.success);
    } catch (e) {
      snackbar.enqueueSnackbar('Error submitting your reset password request', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
        className: 'dd-privacy-allow',
      });
    } finally {
      setLoading(false);
    }
  };

  const sendEmailFormik = useFormik({
    initialValues: initialValueSendEmail,
    onSubmit: async (values) => {
      try {
        await handleResetPasswordEmail(values.username.trim(), snackbar.enqueueSnackbar);
      } catch (e) {
        let errorMessage: string = e.message;
        if (errorMessage.indexOf('validation') > -1) {
          errorMessage = 'Invalid username.';
        }
        snackbar.enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
          className: 'dd-privacy-allow',
        });
      }
    },
    validationSchema: validationSendEmail,
  });

  const submitNewPassword = useFormik({
    initialValues: initialValueResetPassword,
    onSubmit: async (values) => {
      try {
        await handleResetPasswordSubmit(values.verificationCode, username!, values.password);
      } catch (e) {
        snackbar.enqueueSnackbar(e.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
          className: 'dd-privacy-allow',
        });
      }
    },
    validationSchema: validationSubmit,
  });

  return {
    sendEmailFormik,
    submitNewPassword,
    loading,
  };
};

export default usePasswordReset;
