import theme, { makeStyles } from 'theme';

const { typography } = theme;

const useStyles = makeStyles(
  () => ({
    header: {
      padding: '70px 35px 35px 50px',
      marginBottom: 25,
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    pretitle: {
      fontSize: 11,
      textTransform: 'uppercase',
      fontWeight: typography.fontWeightSemiBold,
      color: '#098375',
      letterSpacing: 1.5,
      paddingBottom: '8px',
    },
    title: {
      fontSize: 22,
      fontWeight: typography.fontWeightSemiBold,
      paddingBottom: '8px',
      letterSpacing: '-0.5px',
    },
    subtitle: {
      fontSize: 12,
      fontWeight: typography.fontWeightRegular,
      color: 'rgba(52, 52, 52, 0.5)',
    },
    headerText: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputWrapper: {
      paddingTop: 30,
      '& .MuiInputBase-root': {
        '& > div': {
          backgroundColor: '#F7F7F7',
          paddingRight: 0,
        },
        '& input': {
          paddingLeft: 0,
        },
      },
    },
    icon: {
      color: '#098375 !important',
      fontSize: '14px !important',
    },
  }),
  { name: 'BeneShareDrawerHeader' },
);

export default useStyles;
