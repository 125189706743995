import theme, { makeStyles } from 'theme';

const { typography, palette } = theme;

const useStyles = makeStyles(
  () => ({
    subtitle: {
      fontSize: 14,
      lineHeight: '19px',
      fontWeight: typography.fontWeightRegular,
      color: palette.secondary.dark,
      padding: '0 2px',
    },
    text: {
      fontSize: 14,
      display: 'flex',
      flexDirection: 'row',
    },
    linkText: {
      fontSize: 14,
      lineHeight: '19px',
      fontWeight: typography.fontWeightRegular,
      color: palette.secondary.dark,
      padding: '0 2px',
      textDecoration: 'underline',
    },
  }),
  { name: 'CurrencyAccounts' },
);

export default useStyles;
