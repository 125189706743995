import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentBatchesResponse } from '@alpha/payments-types';
import clsx from 'clsx';
import DashboardTable from '../../../components/Table/DashboardTable';
import { TSearchBaseResponse } from '../../../hooks/useSearch';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../utils/formatIsoDate';
import PaymentsType from '../../Payments/Dashboard/PaymentsType/PaymentsType';
import ApprovalStatus from '../../Payments/Dashboard/PendingTable/DisplayTable/ApprovalStatus/ApprovalStatus';
import {
  DashaboardTablesSearchUrls,
  DashboardTabs,
  IPendingTableProps,
} from '../Common/helpers';
import usePendingTable from '../Common/usePendingTable';
import ActionDropDown from './ActionDropDown';
import useStyles from './index.styles';
import { TStore } from '../../../store';
import { actions } from '../../../store/outstandingTables/outstandingTables.reducer';
import NoOfApprovers from '../Common/NoOfApprovers';
import { TextEllipsis } from '../../../components/TextEllipsis';

const AwaitingFx: React.FC<IPendingTableProps> = (props: IPendingTableProps) => {
  const {
    enablePagination, enableSearch, searchPlaceholder, entityClassName,
  } = props;
  const classes = useStyles();
  const {
    searchParams,
    tableSearch,
    handleInputChange,
  } = usePendingTable(DashaboardTablesSearchUrls.AWAITING_FX);
  const dispatch = useDispatch();

  const selectedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const allowedAccounts = useSelector<TStore, Record<string, string>[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const tableSizeRedux = useSelector<TStore, Record<string, number>>(
    (state) => state.outstandingDashboardTables.tablesSize,
  );
  const userAccountsLoading = useSelector<TStore, boolean | undefined>(
    (state) => state.accounts.userAccountsLoading,
  );

  const [initialSearchCalled, setInitialSearchCalled] = useState<boolean>(false);

  const columns: ITableColumn[] = [
    {
      header: (
        <Box paddingRight="80px" fontWeight="bold">
          ID
        </Box>
      ),
      accessor: 'id',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box>Entity</Box>,
      accessor: 'entity',
      className: clsx(entityClassName, 'dd-privacy-allow'),
    },
    {
      header: <Box>Type</Box>,
      accessor: 'type',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignCenter}>Quantity</Box>,
      accessor: 'quantity',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignCenter}>uploaded date</Box>,
      accessor: 'uploadedDate',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.textAlignLeft}>Uploaded by</Box>,
      accessor: 'uploadedBy',
    },
    {
      header: <Box className={classes.textAlignLeft}>Approval Status</Box>,
      accessor: 'noOfApprovers',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.approval}>Batch status</Box>,
      accessor: 'approvalStatus',
      className: 'dd-privacy-allow',
    },
    {
      header: <Box className={classes.action}>Actions</Box>,
      accessor: 'action',
      className: 'dd-privacy-allow',
    },
  ];

  useEffect(() => {
    if (
      !userAccountsLoading
      && (selectedAccounts.length > 0 || allowedAccounts.length > 0)
    ) {
      tableSearch.handleInitialSearch(searchParams, true);
      setInitialSearchCalled(true);
    }
  }, [selectedAccounts, allowedAccounts, userAccountsLoading]);

  useEffect(() => {
    if (tableSearch.items?.items?.total) {
      dispatch(
        actions.updateOutstandingTablesSize({
          ...tableSizeRedux,
          [DashboardTabs.AWAITING_FX]: tableSearch.items?.items.total,
        }),
      );
    }
  }, [tableSearch.items?.items?.total]);

  const generateTableData = (tableData: TSearchBaseResponse | undefined) => (
    tableData?.records as PaymentBatchesResponse[])?.map((payment) => ({
    id: <b>{payment.friendlyBatchId || ''}</b>,
    entity: <TextEllipsis text={payment?.accountName} />,
    type: <PaymentsType type={payment.type} />,
    quantity: (
      <Typography className={classes.textAlignCenter}>
        {payment?.noOfPayments || '-'}
      </Typography>
    ),
    uploadedBy: (
      <Box className={classes.textAlignLeft} maxWidth="100px">
        <div>{payment.uploadedBy || ''}</div>
      </Box>
    ),
    uploadedDate: (
      <Box className={classes.textAlignCenter}>
        {payment.uploadedDate ? formatIsoDate(payment.uploadedDate) : ''}
      </Box>
    ),
    noOfApprovers: (
      <NoOfApprovers
        uploadedBy={payment.uploadedBy}
        uploadedDate={payment.uploadedDate}
        noOfApprovals={payment.approvalRequirement?.approvals?.length}
        approvedBy={payment.approvalRequirement?.approvals || []}
        approvalsRequired={payment.approvalRequirement?.approvalsRequired?.reduce(
          (sum, current) => sum + current.number, 0,
        )}
      />
    ),
    approvalStatus: (
      <ApprovalStatus
        requiresFx={payment.requiresFx || false}
        batchStatus={payment.batchStatus}
      />
    ),
    action: (
      <ActionDropDown
        payment={payment}
      />
    ),
  }));

  return (
    <DashboardTable
      columns={columns}
      data={generateTableData(tableSearch.items?.items) || []}
      loading={tableSearch.loading || !initialSearchCalled}
      searchProps={{
        searchEnabled: enableSearch,
        searchValue: tableSearch.searchText,
        totalItems: tableSearch.items?.items.total || 0,
        searchPlaceholder,
        onSearchTermChange: handleInputChange,
      }}
      paginationProps={{
        paginationEnabled: enablePagination,
        pagination: {
          hasPrevious: tableSearch.items?.hasPrevious || false,
          hasNext: tableSearch.items?.hasNext || false,
          handlePrevious: () => tableSearch.handlePreviousPage(searchParams, 10, true),
          handleNext: () => tableSearch.handleNextPage(searchParams, 10, true),
        },
      }}
      emptyTable={{
        title: 'No Payments Pending FX ',
        subtitle: 'You currently do not have any Payments pending FX',
      }}
    />
  );
};

export default AwaitingFx;
