import { IATDraftResponse } from '@alpha/iat-dtos';
import React, { useEffect, useState } from 'react';
import clipboardCross from '../../../assets/clipboardCross.svg';
import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable from '../../../components/Table/EmptyTable/EmptyTable';
import { TCurrencyTab } from '../../../models/currencyAccounts';
import InterAccountTransfer from '../../InterAccountTransfer/InterAccountTransfer';
import AccountInfoDrawer from '../AccountInfoDrawer/AccountInfoDrawer';
import useStyles from '../CurrencyAccounts.styles';
import CurrencyStats from '../CurrencyStats';
import useBody from './useBody';
import useCurrencyStats from '../CurrencyStats/useCurrencyStats';

interface IProps {
  tabs: TCurrencyTab[];
  loading: boolean;
  selectedTabIndex: number;
  selectedUserAccounts: Record<string, string>[];
  inputterAccounts: string[];
  multiEntity?: boolean;
}

const Body: React.FC<IProps> = (props: IProps) => {
  const {
    tabs, loading, selectedTabIndex, selectedUserAccounts, multiEntity, inputterAccounts,
  } = props;

  const classes = useStyles();
  const [
    selectedDraftIAT,
    setSelectedDraftIAT,
  ] = useState<IATDraftResponse>();

  const {
    drawer, selectedAccount, handleAccountTableRowClick, handleOpenDrawer,
  } = useBody(selectedTabIndex, tabs);

  const {
    caSearch,
    searchParams,
    loading: tableLoading,
  } = useCurrencyStats(tabs[selectedTabIndex] ? tabs[selectedTabIndex].currencyCode : 'ALL', multiEntity);

  useEffect(() => {
    if (tabs.length !== 0) { caSearch.handleInitialSearch(searchParams); }
  }, [tabs[selectedTabIndex]?.currencyCode, selectedUserAccounts]);

  const reloadData = () => {
    caSearch.handleUpdateTableItems(searchParams);
  };

  if (loading) {
    return (
      <BackdropLoader testId="backdrop-loader" />
    );
  }
  if (tabs.length === 0) {
    return (
      <EmptyTable title="No Accounts found" icon={clipboardCross} />
    );
  }

  return (
    <div className={classes.container}>
      <CurrencyStats
        multiEntity={multiEntity}
        currencyCode={tabs[selectedTabIndex].currencyCode}
        currency={tabs[selectedTabIndex].currency}
        handleAccountTableRowClick={handleAccountTableRowClick}
        handleOpenDrawer={handleOpenDrawer}
        inputterAccounts={inputterAccounts}
        caSearch={caSearch}
        searchParams={searchParams}
        loading={tableLoading}
      />
      <AccountInfoDrawer
        open={drawer === 'PendingTransfers' || drawer === 'AccountDrawer'}
        tabIndex={drawer === 'AccountDrawer' ? 0 : 1}
        handleOpenDrawer={handleOpenDrawer}
        selectedAccount={selectedAccount}
        setSelectedDraftIAT={setSelectedDraftIAT}
        selectedDraftIAT={selectedDraftIAT}
        multiEntity={multiEntity}
        inputterAccounts={inputterAccounts}
      />
      <InterAccountTransfer
        open={drawer === 'IAT'}
        caDrawer={drawer}
        handleCloseIATDrawer={() => handleOpenDrawer(false)}
        selectedAccount={selectedAccount}
        selectedDraftIAT={selectedDraftIAT}
        setSelectedDraftIAT={setSelectedDraftIAT}
        multiEntity={multiEntity}
        reloadData={reloadData}
      />
    </div>
  );
};

export default Body;
