import { useDispatch } from 'react-redux';
import * as actions from '../store/authy/actions';
import { TAuthyBeneficiaryType } from '../store/authy/reducer';
import useAlphaSnackbar from './useAlphaSnackbar';

const useBeneficiary = () => {
  const snackbar = useAlphaSnackbar();
  const dispatch = useDispatch();

  const approveBeneficiary = (payload: TAuthyBeneficiaryType) => {
    dispatch(actions.initiateBeneficiary({ ...payload }));
  };

  return {
    snackbar,
    approveBeneficiary,
  };
};

export default useBeneficiary;
