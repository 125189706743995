import React, { FC, forwardRef } from 'react';

import { IBaseButtonProps } from '../BaseButton';
import { StyledGhostButton } from './GhostButton.styles';

export interface IGhostButtonProps extends IBaseButtonProps {
  colorVariant?: 'default' | 'warning';
}

const GhostButton: FC<IGhostButtonProps> = forwardRef((props, ref) => {
  const { colorVariant = 'default', ...rest } = props;
  return <StyledGhostButton colorVariant={colorVariant} ref={ref} {...rest} />;
});

export default GhostButton;
