import clsx from 'clsx';
import React from 'react';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import useStyles from './ApproverContent.styles';

interface IProps {
  uploadedBy: string | undefined;
  uploadedDate: string | undefined;
  approvedBy: Record<string, string>[];
}

const ApproverContent: React.FC<IProps> = (props: IProps) => {
  const { uploadedBy, uploadedDate, approvedBy } = props;
  const classes = useStyles();
  return (
    <div className={classes.contentWrapper}>
      <div className={classes.spaceBetween}>
        <b>Uploaded by</b>
        <span>{uploadedBy}</span>
      </div>
      <div className={
        clsx(classes.topSection,
          classes.spaceBetween)
      }
      >
        <b>Uploaded date</b>
        <span>{uploadedDate && formatIsoDate(uploadedDate, 'HH:mm:ss dd/MM/yyyy')}</span>
      </div>

      <div className={classes.column}>
        {
          approvedBy && approvedBy.length > 0
            ? <b>Approved by</b>
            : <span>No approvers yet</span>
        }
        {
          approvedBy?.map((approver: any) => (
            <div className={classes.spaceBetween}>
              <span>
                {approver.userId}
              </span>
              <div>
                <span>
                  {formatIsoDate(approver.date, 'HH:mm:ss dd/MM/yyyy')}
                </span>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default ApproverContent;
