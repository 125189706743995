import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { ISelectedAccount } from '../../AccountInfoDrawer';
import useStyles from './HeaderText.styles';
import FlagImage from 'components/Flag/FlagImage';

interface IHeaderTextProps {
  selectedAccount: ISelectedAccount | undefined;
  currencyCode?: string;
}

const HeaderText: React.FC<IHeaderTextProps> = ({ selectedAccount, currencyCode = 'ALL' }: IHeaderTextProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.image}>
        <FlagImage currencyCode={currencyCode} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography className={clsx(classes.title, classes.SemiBold)}>
          <span>
            {selectedAccount?.account.friendlyName || '-'}
          </span>
        </Typography>
        <Typography className={clsx(classes.subtitle, classes.SemiBold, 'dd-privacy-allow')}>
          <span data-dd-privacy="allow">
            {selectedAccount?.account.currency || '-'}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default HeaderText;
