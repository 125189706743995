import {
  call, put, putResolve, select, takeLatest,
} from 'redux-saga/effects';
import { Guid } from 'guid-typescript';
import { TUserAccount, TUserAccounts } from '../../models/user';
import AuthorizationService from '../../services/Authorization/authorization.service';
import { actions } from './accounts.reducer';
import { actions as notificationsActions } from '../notifications/notifications.reducer';
import { TStore } from '..';

async function getUserAccounts() :Promise<TUserAccounts> {
  const result = await AuthorizationService.getUserAccounts();
  return result;
}

export function* watchGetUserAccountsSaga() {
  yield takeLatest('GET_USER_ACCOUNTS_REQUEST', getUserAccountsSaga);
}

export function* getUserAccountsSaga() {
  try {
    yield put(actions.updateLoadingState({ loading: true }));
    const response:TUserAccount[] = yield call(getUserAccounts);
    const userAccounts = response.map((account) => ({
      id: account.id,
      name: account.name,
      kycReviewDate: account.kycRefreshDate,
      reviewDue: account.reviewDue,
      reviewOverDue: account.reviewOverDue,
      accountFrozen: account.accountFrozen,
      isMicroEnterprise: account.isMicroEnterprise,
      dynamicFxSpreadEnabled: account.dynamicFxSpreadEnabled,
    }));
    const currentAllowedAccounts:Record<string, string>[] = yield select(
      (state: TStore) => state.accounts.allowedAccounts,
    );
    const difference = response
      .filter((account1) => !currentAllowedAccounts
        .some((account2) => account1.id === account2.id));
    if (difference.length > 0) {
      yield putResolve(actions.updateAllowedAccounts(userAccounts));
    }
  } catch (error) {
    yield put(
      notificationsActions.enqueueSnackbar({
        variant: 'error',
        className: 'dd-privacy-allow',
        key: Guid.create().toString(),
        message: error.response?.data?.error || 'Could not get user accounts',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }),
    );
  } finally {
    yield put(actions.updateLoadingState({ loading: false }));
  }
}
