import { makeStyles } from 'theme';
import { theme } from 'theme/themes/default';

const useStyles = makeStyles(
  {
    root: {
      marginTop: '48px',
      '& .paymentStat': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '40px',
        '& > h2': {
          fontWeight: 'bold',
        },
      },
      '& .emptyTable': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        '& .wrapper': {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          '& .circle': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: 'rgba(98, 98, 98, 0.1)',
          },
          '& h2': {
            fontWeight: 'bold',
          },
          '& h6': {
            fontWeight: 300,
          },
        },
      },
    },
  },
  { name: 'paymentsList' },
);

export default useStyles;
