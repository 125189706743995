import theme, { makeStyles } from 'theme';

const { typography } = theme;

export default makeStyles(
  () => ({
    transactionCurrencySummary: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '35px 0',
      '& > div': {
        display: 'flex',
      },
    },
    transactionCurrencySummaryRight: {
      '& > div': {
        '& > p:first-of-type': {
          textAlign: 'right',
        },
        '&:not(:last-of-type)': {
          marginRight: '50px',
        },
        '&:last-of-type': {
          '& > p:last-of-type': {
            fontWeight: typography.fontWeightBold,
          },
        },
      },
    },
    transactionCurrencySummaryLeft: {
      '& > div': {
        '& > p:first-of-type': {
          textAlign: 'left',
        },
      },
    },
  }),
  { name: 'TransactionsTable' },
);
