import { makeStyles } from 'theme';

export const useStyles = makeStyles(
  () => ({
    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '32px',
    },
  }),
  { name: 'Funding' },
);

export default useStyles;
