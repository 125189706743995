import React, { useState } from 'react';
import { BatchApprover, PaymentBatchDraftStatus } from '@alpha/payments-dtos';
import { PaymentBatchesResponse } from '@alpha/payments-types';
import MenuDropdownItem, {
  IMenuItem,
} from 'components/MenuDropDownItem/MenuDropdownItem';
import { Box, Typography } from '@mui/material';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelope, faEye, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import DecisionModal from 'components/Modals/DecisionModal/DecisionModal';
import Menu from 'components/Menu/Menu';
import useActionDropdown from '../../../../../../hooks/useActionDropDown';
import useAlphaSnackbar from '../../../../../../hooks/useAlphaSnackbar';
import { PaymentRouteEnum } from '../../../../../../models/payments';
import routes from '../../../../../../routes.path';
import history from '../../../../../../services/history/browserHistory';
import PaymentsService from '../../../../../../services/Payments/payments.service';
import saveCurrentPageRoute from '../../../../../../utils/saveCurrentPageRoute';
import useStyles from './ActionDropDown.styles';
import MultiSelectSection from './MultiSelectSection/MultiSelectSection';

interface IProps {
  payment: PaymentBatchesResponse;
  status: PaymentBatchDraftStatus;
  userCanApprove: boolean;
  batchId: string;
  accountId: string;
  refreshTable: () => void;
  userIsInputter: boolean;
}

const ActionDropDown: React.FC<IProps> = ({
  payment,
  status,
  userCanApprove,
  batchId,
  accountId,
  refreshTable,
  userIsInputter,
}: IProps) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [currentOptions, setCurrentOptions] = useState<BatchApprover[]>([]);

  const classes = useStyles();
  const {
    open,
    setOpen,
    approvers,
    handleRenderDropdown,
    displayMultiSelect,
    setDisplayMultiSelect,
    submitApproverEmails,
    loading,
  } = useActionDropdown({
    currentOptions,
  });

  const sb = useAlphaSnackbar();
  const notFullyApproved =
    payment.approvalRequirement?.approvals?.length !== undefined &&
    payment.approvalRequirement?.approvalsRequired[0].number !== undefined &&
    payment.approvalRequirement?.approvals?.length <
      payment.approvalRequirement?.approvalsRequired[0].number;

  // Reimplement when user able to book FX is stamped on batch
  // const menuText = () : string => {
  //   if (status === PaymentBatchDraftStatus.APPROVED) {
  //     return 'Book FX / Reject Batch';
  //   }
  //   if (userCanApprove) {
  //     return 'Approve / Reject Batch';
  //   }
  //   return 'View / Action Batch';
  // };

  const handleRedirectToBatchSummary = () => {
    saveCurrentPageRoute();
    history.push(
      `${routes.payments.multientity.batchSummaryRouteFunc(
        payment.accountId,
        payment.batchId,
        PaymentRouteEnum.PENDING,
      )}`,
    );
  };

  const handleDeleteBatchClick = async () => {
    try {
      await PaymentsService.deletePayment(batchId, accountId);
      setTimeout(() => refreshTable(), 900);
      sb.trigger('Batch Deleted Successfully', 'success');
    } catch (error) {
      sb.trigger('Could not delete batch at this time');
    } finally {
      setOpenDeleteModal(false);
    }
  };

  const getMenuItems = (): IMenuItem[] => {
    let menuItems: IMenuItem[];
    const viewBatch = {
      id: 'action-pending-payment-view-batch',
      content: (
        <Typography data-testid={`payment-action-option-${payment.batchId}`}>
          View / Action Batch
        </Typography>
      ),
      icon: faEye,
      onClick: () => {
        handleRedirectToBatchSummary();
      },
      underline: false,
    };

    const deleteBatch = {
      id: 'action-pending-payment-delete-batch',
      content: (
        <Typography data-testid={`payment-action-option-${payment.batchId}`}>
          Delete Batch
        </Typography>
      ),
      icon: faTrash,
      onClick: () => {
        setOpenDeleteModal(true);
      },
      underline: false,
    };

    const emailApprovers = {
      id: 'action-pending-payment-email-approvers',
      content: (
        <MultiSelectSection
          currentOptions={currentOptions}
          setCurrentOptions={setCurrentOptions}
          setOpen={setOpen}
          displayMultiSelect={displayMultiSelect}
          loading={loading}
          id={batchId}
          accountId={payment.accountId}
          approvers={approvers}
          setDisplayMultiSelect={setDisplayMultiSelect}
          submitApproverEmails={submitApproverEmails}
        />
      ),
      onClick: () => handleRenderDropdown(batchId, accountId),
      disableClick: true,
      icon: !displayMultiSelect ? faEnvelope : undefined,
      disableHoverEffect: displayMultiSelect,
      underline: false,
    };
    menuItems = [viewBatch];
    if (userIsInputter && !!notFullyApproved) {
      menuItems = [
        {
          ...viewBatch,
          underline: true,
        },
        deleteBatch,
      ];
    }
    if (status === PaymentBatchDraftStatus.SUBMITTED && !userCanApprove) {
      if (notFullyApproved) {
        menuItems = [
          {
            ...viewBatch,
            underline: true,
          },
          {
            ...deleteBatch,
            underline: true,
          },
          emailApprovers,
        ];
      } else {
        menuItems = [
          {
            ...viewBatch,
            underline: true,
          },
          emailApprovers,
        ];
      }
    }
    return menuItems;
  };

  return (
    <Box data-testid={`action-${payment.batchId}`} className={classes.wrapper}>
      <Menu
        open={Boolean(open)}
        setOpen={setOpen}
        anchorEl={open}
        disableBackdropClick={false}
        disableHoverEffect={false}
        buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
        buttonTitle=""
        icon={faEllipsisH}
      >
        {getMenuItems().map((item) => (
          <MenuDropdownItem key={item.id} setOpen={setOpen}>
            {item}
          </MenuDropdownItem>
        ))}
      </Menu>
      <DecisionModal
        title="Are you sure you want to delete this batch?"
        open={openDeleteModal}
        subtitle="This is irreversible and the batch will be permanently deleted."
        actionButtonText="Delete"
        handleActionButton={handleDeleteBatchClick}
        handleClose={() => setOpenDeleteModal(false)}
        icon={faExclamationTriangle}
        closeButtonText="Cancel"
      />
    </Box>
  );
};

export default ActionDropDown;
