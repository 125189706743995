import { SubmitBatchForApprovalRequest } from '@alpha/payments-dtos';
import { datadogRum } from '@datadog/browser-rum';
import { PayloadAction } from '@reduxjs/toolkit';
import { Guid } from 'guid-typescript';
import {
  call, delay, put, select, takeLatest,
} from 'redux-saga/effects';
import { TStore } from '..';
import instance, { instanceAccountId } from '../../services/Axios/instance';
import BeneficiariesService from '../../services/Beneficiaries/beneficiaries.service';
import PaymentsService from '../../services/Payments/payments.service';
import UserManagementService from '../../services/UserManagement/userManagement.service';
import { actions as notificationsActions } from '../notifications/notifications.reducer';
import { Actions } from './actions';
import {
  actions,
  AuthyPageStates,
  TAuthyBeneficiaryBatchType,
  TAuthyBeneficiaryType,
  TAuthyPaymentsType,
  TAuthyUserManagementType,
} from './reducer';

type TSubmitBeneficiary = {
  type: TAuthyBeneficiaryType;
  totp: number;
  softToken?: boolean;
};

type TSubmitBeneficiaryBatch = {
  type: TAuthyBeneficiaryBatchType;
  totp: number;
  softToken?: boolean;
  fraudWarningAcknowledged?: boolean;
}

type TSubmitUserRequestRole = {
  requestId: string;
  totp: number;
  softToken?: boolean;
}

type TSubmitUserRequestBatches = {
  requestIds: string[];
  totp: number;
  softToken?: boolean;
}

type TAuthyType =
  TAuthyBeneficiaryType |
  TAuthyPaymentsType |
  TAuthyBeneficiaryBatchType |
  TAuthyUserManagementType

function* initiate({
  payload,
}: PayloadAction<TAuthyType>) {
  yield put(actions.initiate(payload));
}

const getAuthyType = (
  state: TStore,
) => state.authy.type;

const getMfaCodeRequestType = (
  state: TStore,
) => state.authy.pageState;

const approveBeneficiaryAsync = async ({
  batchId,
  accountBeneficiaryIds,
  code,
  accountId,
  fraudWarningAcknowledged,
  softToken,
}: TAuthyBeneficiaryType & { code: number } & { softToken?: boolean }) => {
  const response = accountId
    ? await instanceAccountId(accountId).post(`/bene/v3/${batchId}/approve`, {
      totp: code,
      accountBeneficiaryIds,
      softToken,
      fraudWarningAcknowledged,
    })
    : await instance.post(
      `/bene/v3/${batchId}/approve`,
      {
        totp: code,
        accountBeneficiaryIds,
        softToken,
        fraudWarningAcknowledged,
      },
    );

  if (response.status !== 200) {
    throw Error(`${response.data.error}`);
  }
};

async function submitBeneficiary(params: TSubmitBeneficiary) {
  await approveBeneficiaryAsync({ ...params.type, code: params.totp, softToken: params.softToken });
}

async function approveBeneficiaryBatch(params: TSubmitBeneficiaryBatch) {
  await BeneficiariesService
    .copyBeneficiary(params.type.accountBeneId, params.type.accountIds, params.totp,
      params.type.accountId, params.softToken, params.type.fraudWarningAcknowledged);
}

type TSubmitPayment = SubmitBatchForApprovalRequest & {
  canApproveOwn: boolean;
  accountId?: string;
  fraudWarningAcknowledged?: boolean;
};
async function submitPayment(params: TSubmitPayment) {
  await PaymentsService.submitAndApprovePayment(
    {
      batchId: params.batchId,
      totp: params.totp,
      fraudWarningAcknowledged: params.fraudWarningAcknowledged,
    },
    params.canApproveOwn,
    params.accountId,
  );
}
async function submitApproveUserRoleRequest({
  requestId,
  totp,
  softToken,
}: TSubmitUserRequestRole) {
  await UserManagementService.approveUserRequest({ requestId, totp, softToken });
}

async function submitApproveUserRoleRequestBatch({
  requestIds,
  totp,
  softToken,
}: TSubmitUserRequestBatches) {
  await UserManagementService.approveUserRequestsBatch({ requestIds, totp, softToken });
}

async function submitApprovePayment(params: TSubmitPayment) {
  await PaymentsService.approvePayment(
    {
      batchId: params.batchId,
      totp: params.totp,
      fraudWarningAcknowledged: params.fraudWarningAcknowledged,
    },
    params.canApproveOwn,
    params.accountId,
  );
}

function* submit({ payload }: PayloadAction<number>) {
  try {
    yield put(actions.updateStatus('CODE SUBMITTED'));

    const authyType: TAuthyType | undefined = yield select(getAuthyType);
    const mfaCodeRequestType: AuthyPageStates | undefined = yield select(getMfaCodeRequestType);

    const softToken = mfaCodeRequestType
      ? (mfaCodeRequestType === (AuthyPageStates.AUTHY)) : undefined;

    if (!authyType || (authyType.type !== 'USER_ROLE_REQUEST' && !authyType.batchId)) {
      throw Error('Unable to find the batch you are trying to submit');
    }

    let successMessage = '';
    let snackbarVariant = 'success';

    if (authyType?.type === 'PAYMENTS') {
      try {
        const {
          approverOwn,
          accountId,
          fraudWarningAcknowledged,
        } = authyType as TAuthyPaymentsType;
        yield call(submitPayment, {
          batchId: authyType.batchId,
          canApproveOwn: Boolean(approverOwn),
          totp: payload.toString(),
          accountId,
          fraudWarningAcknowledged,
        });

        successMessage = `Payment successfully submitted ${approverOwn ? 'and approved' : ''
          }`;
      } catch (e) {
        if (e.message === 'Error approving payment') {
          successMessage = 'There was an error approving your payment, but your payment was submitted';
          snackbarVariant = 'info';
        } else {
          throw Error();
        }
      }
    } else if (authyType?.type === 'PAYMENT_APPROVE') {
      const { approverOwn, accountId, fraudWarningAcknowledged } = authyType as TAuthyPaymentsType;
      yield call(submitApprovePayment, {
        batchId: authyType.batchId,
        totp: payload.toString(),
        canApproveOwn: Boolean(approverOwn),
        accountId,
        fraudWarningAcknowledged,
      });
      successMessage = 'Successfully approved payment';
    } else if (authyType?.type === 'USER_ROLE_REQUEST') {
      const authyFields = authyType as TAuthyUserManagementType;
      if (authyFields?.requestId) {
        yield call(submitApproveUserRoleRequest, {
          requestId: authyFields.requestId,
          totp: payload,
          softToken,
        });
        successMessage = 'Successfully approved user role request';
      } else if (authyFields?.requestIds) {
        yield call(submitApproveUserRoleRequestBatch, {
          requestIds: authyFields.requestIds,
          totp: payload,
          softToken,
        });
        successMessage = 'Successfully approved user role requests';
      }
    } else if (authyType?.type === 'BENEFICIARY') {
      yield call(submitBeneficiary, {
        type: authyType as TAuthyBeneficiaryType,
        totp: payload,
        softToken,
      });
      datadogRum.addAction('BENEFICIARY SUBMITTED');
      successMessage = 'Successfully submitted beneficiary';
    } else if (authyType?.type === 'BENEFICIARY_BATCH') {
      yield call(approveBeneficiaryBatch, {
        type: authyType as TAuthyBeneficiaryBatchType,
        totp: payload,
        softToken,
      });
      datadogRum.addAction('SUCCESSFULLY SHARED SELECTED BENEFICIARIES');
      successMessage = 'Successfully Shared Selected Beneficiaries';
    }

    yield put(actions.updateStatus('SUCCESS'));
    yield put(
      notificationsActions.enqueueSnackbar({
        variant: snackbarVariant as any,
        key: Guid.create().toString(),
        className: 'dd-privacy-allow',
        message: successMessage,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }),
    );
    yield delay(5000);
    yield put(actions.reset());
  } catch (e) {
    if (e.response?.data?.error) {
      yield put(actions.updateStatus('INITIATED'));
      yield put(
        notificationsActions.enqueueSnackbar({
          variant: 'error',
          key: Guid.create().toString(),
          className: 'dd-privacy-allow',
          message: e.response?.data?.error[0].message || 'Invalid authy code entered',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        }),
      );
    } else {
      yield put(actions.updateStatus('ERROR'));
      yield put(
        notificationsActions.enqueueSnackbar({
          variant: 'error',
          key: Guid.create().toString(),
          className: 'dd-privacy-allow',
          message: e.message || e.error || 'An error occurred please try again later',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        }),
      );
      yield delay(3000);
      yield put(actions.reset());
    }
  }
}

export function* authySaga() {
  yield takeLatest(Actions.INITIATE, initiate);
  yield takeLatest(Actions.SUBMIT, submit);
}

export default authySaga;
