import {
  Card, CardContent, CardHeader, Typography,
} from '@mui/material';
import { Loader } from 'components/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  iAllowedAccounts,
  iSelectedAccounts,
} from 'store/accounts/accounts.reducer';
import { BaseButton } from 'components/Buttons';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import { TCurrencyTab } from '../../../models/currencyAccounts';
import routes from '../../../routes.path';
import CurrencyAccountsService from '../../../services/CurrencyAccounts/currencyAccounts.service';
import history from '../../../services/history/browserHistory';
import { TStore } from '../../../store';
import CurrencyAccountsTable from './CurrencyAccountsTable';
import useStyles from './CurrencyAccountsWidget.styles';

const clickViewAll = () => history.push(routes.currencyAccounts);

const CurrencyAccountsWidget: React.FC<{}> = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TCurrencyTab[]>();
  const selectedAccounts = useSelector<TStore, iSelectedAccounts[]>(
    (state) => state.accounts.selectedAccounts,
  );
  const allowedAccounts = useSelector<TStore, iAllowedAccounts[]>(
    (state) => state.accounts.allowedAccounts,
  );
  const sb = useRef(useAlphaSnackbar());

  const populateTableData = async () => {
    setLoading(true);
    try {
      const tableData: TCurrencyTab[] = [];
      const response = await CurrencyAccountsService.getCurrencyTabs(true);
      for (let i = 0; i < response.length; i += 1) {
        tableData.push(response[i]);
      }
      setData(tableData);
    } catch (e) {
      sb.current.trigger('There was an error loading your accounts');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateTableData();
  }, [selectedAccounts, allowedAccounts]);
  return (
    <Card className={clsx(classes.boxShadow)}>
      <CardHeader
        className={classes.cardHeader}
        title={(
          <Typography
            className={clsx(classes.cardTitle, 'dd-privacy-allow')}
            variant="h6"
          >
            Currency Accounts
          </Typography>
        )}
        action={(
          <BaseButton
            className={clsx(classes.cardActionButton, 'dd-privacy-allow')}
            variant="outlined"
            onClick={clickViewAll}
            rounded
            color="secondary"
          >
            View All
          </BaseButton>
        )}
      />
      <CardContent className={classes.cardContent}>
        {loading ? (
          <Loader testId="caWidgetLoader" />
        ) : (
          <CurrencyAccountsTable accountCurrencyStats={data || []} />
        )}
      </CardContent>
    </Card>
  );
};
export default CurrencyAccountsWidget;
