import theme, { makeStyles } from 'theme';

const { typography } = theme;

export const useStyles = makeStyles(
  () => ({
    requestFooter: {
      paddingTop: 40,
      borderTop: '1px solid rgba(34, 34, 34, 0.1)',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > button': {
        fontSize: '12px',
        fontWeight: typography.fontWeightSemiBold,
        lineHeight: '17px',
        letterSpacing: '1.8px',
      },
      '& > p': {
        fontSize: '12px',
        color: '#000000',
        lineHeight: '17px',
        '& > span.bold': {
          fontWeight: typography.fontWeightBold,
        },
      },
    },
  }),
  { name: 'RequestLiveRateFooter' },
);

export default useStyles;
