import _default, { makeStyles } from 'theme';

const { palette, typography } = _default;

const useStyles = makeStyles(
  () => ({
    entityHeader: {
      fontSize: '18px',
      fontWeight: typography.fontWeightRegular,
      padding: '4px 12px 12px 0px',
      color: palette.primary.main,
    },
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '40px',
      '& .currencyHeader': {
        width: '50%',
        '& .currencyHeader-inner': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          '& > div > div': {
            borderRight: 'none',
            marginRight: 0,
          },
        },

        '& > button': {
          height: '50px',
        },
        '& svg': {
          color: '#808080',
          fontSize: '18px',
        },
      },
      '& .stats': {
        display: 'flex',
        '& > div:first-of-type': {
          marginRight: '64px',
        },
        '& .boldStat': {
          '& p': {
            fontWeight: 'bold',
          },
        },
      },
    },
    changeButton: {
      textTransform: 'none',
    },
  }),
  { name: 'fundingAccountSummary' },
);

export default useStyles;
