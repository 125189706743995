import { TStatusVariants } from 'components/Status';
import Status from 'components/Status/Status';
import React from 'react';

const getStatusVariant = (text: string, className?: string) => {
  let variant: TStatusVariants | undefined;
  switch (text.toUpperCase()) {
    case 'ACTIVE':
      variant = 'success';
      break;
    case 'PENDING':
      variant = 'outstanding';
      break;
    case 'IN REVIEW':
      variant = 'orange';
      break;
    case 'REJECTED':
      variant = 'default';
      break;
    case 'INACTIVE':
      variant = 'default';
      break;
    case 'PENDING PERMISSION':
      variant = 'default';
      break;
    default:
      variant = 'default';
  }
  return (
    <Status testId="status" className={className} variant={variant}>
      {text}
    </Status>
  );
};

export default getStatusVariant;
