import { debounce } from 'lodash';
import {
  ChangeEvent, useCallback, useEffect, useMemo, useState,
} from 'react';

const useDebouncedSearch = () => {
  const [search, setSearch] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.trim());
  };

  const debouncedSearch = useMemo(() => debounce(handleChange, 500), []);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  useEffect(() => () => {
    debouncedSearch.cancel();
  });

  return {
    search,
    setSearch: debouncedSearch,
    clearSearch,
  };
};

export default useDebouncedSearch;
