import React, {
  useEffect,
} from 'react';
import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import { Beneficiary } from '@alpha/bene-dtos';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { IDrawerProps } from 'components/Drawer/BaseDrawer/BaseDrawer.interfaces';
import useStyles from './SelectBeneficiaryDrawer.styles';
import DrawerBackdropLoader from '../../../../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import useSelectBeneDrawer from './useSelectBeneDrawer';
import SelectBeneDrawerHeader from './SelectBeneficiaryDrawerHeader/SelectBeneficiaryDrawerHeader';
import { TCurrencyAccount } from '../../../../../../models/currencyAccounts';
import SelectBeneficiaryDrawerBody from './SelectBeneficiaryDrawerBody/SelectBeneficiaryDrawerBody';

interface ISelectBeneDrawerProps extends IDrawerProps {
  open?: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFundingAccount: TCurrencyAccount;
  accountId: string;
}

const SelectBeneDrawer:
  React.FC<ISelectBeneDrawerProps> = (
    {
      open, onClose, selectedFundingAccount, accountId,
    }: ISelectBeneDrawerProps,
  ) => {
    const styles = useStyles();
    const handleClose = () => {
      onClose(false);
    };

    const {
      handleInputChange,
      handleNext,
      handlePrevious,
      getBeneficiaries,
      loading,
      items,
    } = useSelectBeneDrawer(accountId, selectedFundingAccount);

    useEffect(() => {
      if (open) getBeneficiaries();
    }, [open]);

    return (
      <BaseDrawer.Drawer data-testid="bene-drawer" open={open} onClose={handleClose} anchor="right" className={styles.drawerWrapper}>
        <SelectBeneDrawerHeader data-testid="bene-drawer-header" handleInputChange={handleInputChange} />
        <Box data-testid="bene-drawer-body-container">
          {
            items?.items && !loading && (
            <>
              <Typography className={clsx(styles.beneficiaryCount, 'dd-privacy-allow')}>
                {'Number of beneficiaries: '}
                <span>{items?.items?.total}</span>
              </Typography>
              <SelectBeneficiaryDrawerBody
                beneficiaries={items?.items.records as Beneficiary[]}
                onClose={handleClose}
                pagination={{
                  handleNext,
                  handlePrevious,
                  hasNext: Boolean(items?.hasNext),
                  hasPrevious: Boolean(items?.hasPrevious),
                }}
              />
            </>
            )
          }
          <DrawerBackdropLoader display={loading} text="Loading beneficiaries..." />
        </Box>
      </BaseDrawer.Drawer>
    );
  };
export default SelectBeneDrawer;
