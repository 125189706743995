import { Typography } from '@mui/material';
import React from 'react';
import Dropdown from '../../../../../../components/InterAccountTransfer/Dropdown/Dropdown';
import { IFundingAccountProps } from '../FundingAccount';
import useStyles from './FundingAccountDropdown.styles';

const FundingAccountDropdown: React.FC<IFundingAccountProps> = ({
  selectedDebitingAccount,
  handleSetSelectedDebitingAccount,
  currencyAccounts,
}: IFundingAccountProps) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div>
        <Typography variant="h5" className="title">Please select the debiting account</Typography>
      </div>
      <div>
        <Typography variant="subtitle1" className="label">Funding Account</Typography>
        <Dropdown
          currencyAccounts={currencyAccounts}
          handleBlur={() => { }}
          handleChange={handleSetSelectedDebitingAccount}
          helperText=""
          placeholderText="Please select funding account"
          selectName="funding-account"
          data-testid="funding-account"
          selectValue={selectedDebitingAccount?.id}
        />
      </div>
    </div>
  );
};

export default FundingAccountDropdown;
