import { PaymentBatchesResponse } from '@alpha/payments-types';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import SearchTable from '../../../../components/Table/SearchTable';
import { TextEllipsis } from '../../../../components/TextEllipsis';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import PaymentsService from '../../../../services/Payments/payments.service';
import formatIsoDate from '../../../../utils/formatIsoDate';
import { SearchPaymentsTabProps } from '../../usePaymentBatchSearch';
import PaymentsType from '../PaymentsType/PaymentsType';
import useStyles from '../SearchPaymentsTable/SearchPaymentsTable.styles';
import ActionDropDown from './ActionDropDown/ActionDropDown';

export interface IDraftedTableData {
  sourceName: string;
  type: JSX.Element;
  noOfPayments: number;
  createdBy: string;
  createdDate: string;
  action: JSX.Element;
}

const DraftedTableContainer: React.FC<SearchPaymentsTabProps> = (props: SearchPaymentsTabProps) => {
  const {
    items,
    hasNext,
    hasPrevious,
    handleNextPage,
    handlePreviousPage,
    loading,
    handleNewSearch,
  } = props;

  const classes = useStyles();

  const columns: ITableColumn[] = [
    {
      header: 'File Name',
      accessor: 'sourceName',
      className: 'dd-privacy-allow',
    },
    {
      header: 'Type',
      accessor: 'type',
      className: 'dd-privacy-allow',

    },
    {
      header: 'No of payments',
      accessor: 'noOfPayments',
      className: 'dd-privacy-allow',

    },
    {
      header: 'Created By',
      accessor: 'createdBy',
    },
    {
      header: 'Created Date',
      accessor: 'createdDate',
      className: 'dd-privacy-allow',

    },
    {
      header: 'Action',
      accessor: 'action',
      className: 'dd-privacy-allow',

    },
  ];
  const sb = useAlphaSnackbar();

  const handleDeleteClick = async (batchId: string, accountId: string): Promise<void> => {
    try {
      await PaymentsService.deletePayment(batchId, accountId);
      setTimeout(() => handleNewSearch(), 500);
      sb.trigger('Successfully deleted your payment', 'success');
    } catch {
      sb.trigger('There was an error deleting your payment');
    }
  };
  const mappedData = items?.records
    && (items.records as PaymentBatchesResponse[]).map((item) => {
      const container: any = {};
      container.sourceName = item.sourceName;
      container.entity = <TextEllipsis text={item.accountName} className={classes.spaceRight} />;
      container.type = (
        <PaymentsType
          type={item.type}
        />
      );
      container.noOfPayments = (
        <Box className={classes.textAlignLeft}>
          {item.noOfPayments}
        </Box>
      );
      container.createdBy = (
        <Box>
          <span>{item.uploadedBy}</span>
        </Box>
      );
      container.createdDate = (
        <Box>
          {formatIsoDate(item.uploadedDate, 'HH:mm:ss dd/MM/yyyy')}
        </Box>
      );
      container.action = (
        <ActionDropDown
          handleDeleteClick={handleDeleteClick}
          batchStatus={item.batchStatus}
          batchId={item.batchId}
          accountId={item.accountId}
        />
      );
      return container;
    });

  return (
    <SearchTable
      table={{
        columns,
        data: mappedData || [],
      }}
      loading={loading}
      pagination={{
        handleNext: () => handleNextPage(),
        handlePrevious: () => handlePreviousPage(),
        hasNext: hasNext || false,
        hasPrevious: hasPrevious || false,
      }}
      emptyTable={{
        title: 'No Drafted Payments',
        subtitle: 'You currently do not have any draft payment batches',
      }}
    />
  );
};

export default memo(DraftedTableContainer);
