import React, { memo, useEffect } from 'react';
import useSearch, { TSearchParams } from 'hooks/useSearch';
import { PaymentDraftDto } from '@alpha/payments-dtos';
import EmptyTable from 'components/Table/EmptyTable/EmptyTable';
import BackdropLoader from 'components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import clipboardCross from '../../../../assets/clipboardCross.svg';
import Table from './Table/Table';
import StyledFxPaymentsLineByLine from './FxPaymentsLineByLine.styles';
import { BatchQuoteDtoDecorated } from '../Funding.interface';

interface IProps {
  batchId: string;
  accountId: string;
  lineByLineRatesMap: Record<string, number>;
  liveRates: BatchQuoteDtoDecorated[];
}

const FxPaymentsLineByLine: React.FC<IProps> = (props: IProps) => {
  const {
    batchId,
    accountId,
    lineByLineRatesMap,
    liveRates,
  } = props;

  const {
    items,
    loading,
    searchText,
    skip,
    handleInitialSearch,
    handleNextPage,
    handlePreviousPage,
  } = useSearch(accountId);

  const searchParams: TSearchParams = {
    baseUrl: `/payments/batches/${batchId}/payments/search`,
    queryParams: {
      skip,
      take: 10,
    },
  };

  useEffect(() => {
    handleInitialSearch(searchParams, true);
  }, []);

  return (
    <StyledFxPaymentsLineByLine>
      {
        !items?.items.records.length && !loading
          ? (
            <div className="emptyTableWrapper">
              <EmptyTable
                icon={clipboardCross}
                title="No payments"
                subtitle={
                  !searchText ? 'We were unable to retrieve your payments'
                    : 'No results found for this search term'
                }
              />
            </div>
          ) : (
            <Table
              data={(items?.items.records as PaymentDraftDto[]) || []}
              lineByLineRatesMap={lineByLineRatesMap}
              handleNextPage={() => handleNextPage(searchParams, 10, true)}
              handlePreviousPage={() => handlePreviousPage(searchParams, 10, true)}
              hasNext={items?.hasNext ?? false}
              hasPrevious={items?.hasPrevious ?? false}
              liveRates={liveRates}
            />
          )
      }
      {
        loading
        && <BackdropLoader testId="loader" />
      }
    </StyledFxPaymentsLineByLine>
  );
};

export default memo(FxPaymentsLineByLine);
