import Status from 'components/Status/Status';
import { Typography } from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import useStyles from './Title.styles';

const Title: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={clsx(styles.root, 'dd-privacy-allow')}>
      <Typography variant="h1">Create Payments</Typography>
      <Status variant="default">
        Draft
      </Status>
    </div>
  );
};
export default Title;
