import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TBatchId } from '../../models/payments';
import { TCurrency, TCurrencyPair } from '../../models/currencies';

export type TCurrenciesState = {
  data: TCurrency[];
  loaded: boolean;
  currencyPairs: TCurrencyPair[];
};

const initialState: TCurrenciesState = {
  data: [],
  loaded: false,
  currencyPairs: [],
};

export const currenciesSlice = createSlice({
  initialState,
  name: 'currencies',
  reducers: {
    load: (state): TCurrenciesState => state,
    loaded: (state, action: PayloadAction<TCurrency[]>): TCurrenciesState => ({
      ...state,
      loaded: true,
      data: action.payload,
    }),
    // Currency Pairs
    loadPairs: (state, action: PayloadAction<TBatchId>): TCurrenciesState => ({ ...state, loaded: false }),
    loadedPairs: (state, action: PayloadAction<TCurrencyPair[]>): TCurrenciesState => ({
      ...state,
      loaded: true,
      currencyPairs: action.payload,
    }),
  },
});

export const { actions } = currenciesSlice;
