import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    icon: {
      color: '#197FAB',
      marginLeft: 5,
    },
    key: {
      display: 'flex',
      alignItems: 'center',
    },
    tooltipText: {
      width: 150,
    },
  }),
  { name: 'InverseFxRate' },
);

export default useStyles;
