import bg from '../../assets/404.jpg';

import _default, { makeStyles } from 'theme';

const {
  palette: { primary, secondary },
  typography,
} = _default;

export const useStyles = makeStyles(
  () => ({
    root: {
      height: '100%',
      width: '100%',
      position: 'fixed',
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      color: primary.contrastText,
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: typography.fontWeightLight,
      '& h2': {
        fontSize: 50,
        lineHeight: '68px',
        color: primary.main,
        margin: ' 0 0 8px',
      },
      '& h3': {
        fontSize: 28,
        lineHeight: '38px',
        margin: '0 0 8px',
        fontWeight: typography.fontWeightLight,
      },
      '& p': {
        margin: '0 0 36px',
      },
      '& a': {
        height: 48,
        width: 197,
        fontSize: 14,
        marginLeft: 10,
        lineHeight: 19,
        textDecoration: 'none',
        display: 'inline-flex',
        textTransform: 'uppercase',
        justifyContent: 'center',
        alignItems: 'center',
        letterSpacing: 2,
        color: primary.contrastText,
        border: '1px solid #C2C2C2',
        boxShadow: '0px 3px 6px #00000029',
        fontWeight: typography.fontWeightSemiBold,

        '&:first-child': {
          marginLeft: 0,
        },
      },
    },
    holder: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: '10%',
      backgroundColor: secondary.main,
      opacity: 0.8,
    },
  }),
  { name: '404' },
);

export default useStyles;
