import { makeStyles } from 'theme';

const useStyles = makeStyles(
  {
    root: {
      paddingRight: '18px',
      width: '400px',
      marginBottom: '56px',
      '& .title': {
        marginBottom: '32px',
        letterSpacing: '-0.5px',
        fontWeight: 600,
      },
    },
    accountMenuOption: {
      position: 'relative',
      width: '100% !important',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between !important',
    },
    frozenStatus: {
      position: 'absolute',
      right: 0,
      minWidth: 75,
      marginRight: 10,
      cursor: 'pointer',
    },
    questionIcon: {
      marginLeft: 3,
    },
    hoverBox: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100% !important',
    },
    dropdown: {
      flexDirection: 'space-between',
      boxShadow: 'none',
      backgroundColor: '#f7f7f7',
      height: 50,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
      marginBottom: '0px',
      justifyContent: 'center',
      '& .MuiOutlinedInput-notchedOutline': { border: 0 },
      '& .MuiOutlinedInput-root': {
        background: '#f7f7f7',
      },
      '& .MuiAutocomplete-input': {
        fontWeight: 400,
        height: '1.475em',
      },
    },
    menuItem: {
      padding: '0',
      margin: '0',
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    placeholder: {
      color: '#34343473',
      fontWeight: 400,
    },
    red: {
      color: '#b95656 !important',
    },
    error: {
      position: 'absolute',
      fontSize: '12px !important',
    },
  },
  { name: 'entityDropdown' },
);

export default useStyles;
