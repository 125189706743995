import _default, { makeStyles } from 'theme';

const { palette } = _default;

const useStyles = makeStyles(
  () => ({
    dialog: {
      '& [role="dialog"]': {
        overflow: 'hidden',
      },
    },
    header: {
      paddingTop: 40,
      textAlign: 'center',
      borderBottom: `1px solid ${palette.grey[200]}`,
      '& h3': {
        maxWidth: 400,
        margin: '0 auto',
        textAlign: 'center',
        marginBottom: 0,
        fontSize: '1.2rem',
        '& span': {
          color: palette.grey[500],
          fontWeight: 'normal',
        },
      },
      '& .Mui-active': {
        fontWeight: 600,
      },
      '& .Mui-completed, & .Mui-active': {
        color: palette.primary.main,
      },
      '& .MuiStepper-horizontal': {
        padding: '1.5rem 0',
      },
    },
    content: {
      padding: '2rem 3rem',
      width: 600,
      maxHeight: '56vh',
      overflow: 'auto',
      position: 'relative',
    },
    footer: ({ isFirstStep }: { isFirstStep?: boolean }) => ({
      display: 'flex',
      justifyContent: isFirstStep ? 'flex-end' : 'space-between',
      padding: '2rem 3rem',
      borderTop: `1px solid ${palette.grey[200]}`,
      '& button': {
        fontSize: '1rem',
        textTransform: 'capitalize',
        fontWeight: 600,
        '&:not(.MuiButton-containedPrimary):not(.Mui-disabled)': {
          backgroundColor: palette.grey[200],
          color: palette.grey[900],
        },
      },
    }),
    icon: {
      fontSize: '12px !important',
    },
    closeButton: {
      position: 'absolute',
      right: 20,
      top: 20,
      color: palette.grey[400],
      cursor: 'pointer',
    },
  }),
  { name: 'WizardModal' },
);

export default useStyles;
