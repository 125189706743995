import { IATDraftResponse } from '@alpha/iat-dtos';
import { BaseDrawer } from 'components/Drawer/BaseDrawer';
import { TabContent } from 'components/Tabs';
import React from 'react';
import { TCurrencyAccount, TCurrencyTab } from '../../../models/currencyAccounts';
import RejectIATModal from '../RejectIATModal';
import useStyles from './AccountInfoDrawer.styles';
import CurrencyAccountInfo from './CurrencyAccountInfo';
import Header from './Header';
import Transfers from './Transfers';
import useAccountInfoDrawer from './useAccountInfoDrawer';

interface IAccountInfoDrawerProps {
  tabIndex?: number,
  selectedAccount: ISelectedAccount | undefined;
  open: boolean;
  selectedDraftIAT?: IATDraftResponse;
  viewOnly?: boolean;
  handleOpenDrawer: (drawer: 'IAT' | 'AccountDrawer' | 'PendingTransfers' | false) => void
  setSelectedDraftIAT:
  React.Dispatch<React.SetStateAction<IATDraftResponse | undefined>>;
  inputterAccounts: string[],
  multiEntity?: boolean;
  testId?: string;
}

export interface ISelectedAccount {
  account: TCurrencyAccount & { accountId?: string };
  tabs: TCurrencyTab;
  testId?: string;
}

const AccountInfoDrawer: React.FC<IAccountInfoDrawerProps> = (
  props: IAccountInfoDrawerProps,
) => {
  const {
    open,
    selectedAccount,
    selectedDraftIAT,
    viewOnly,
    tabIndex,
    setSelectedDraftIAT,
    handleOpenDrawer,
    multiEntity,
    inputterAccounts,
    testId,
  } = props;
  const styles = useStyles();

  const {
    selectedTabIndex,
    pendingTransfers,
    IATRejectModalOpen,
    loading,
    handleChange,
    handleIATRejectModalClose,
    handleOpenIATDrawer,
    handleOpenIATRejectModal,
    getAllPendingTransfers,
    handleDeleteIatClick,
  } = useAccountInfoDrawer(
    open,
    selectedAccount,
    setSelectedDraftIAT,
    handleOpenDrawer,
    tabIndex,
    multiEntity,
  );

  return (
    <>
      <BaseDrawer.Drawer
        open={open}
        onClose={() => handleOpenDrawer(false)}
        anchor="right"
      >
        <Header
          selectedAccount={selectedAccount}
          selectedTabIndex={selectedTabIndex}
          transfersCount={pendingTransfers.length}
          viewOnly={viewOnly}
          handleChange={handleChange}
          handleOpenDrawer={handleOpenDrawer}
          multiEntity={multiEntity}
          inputterAccounts={inputterAccounts}
          data-testid={testId}
        />
        {selectedAccount
          && (
            <div className={styles.drawerBody}>
              <TabContent index={0} value={selectedTabIndex}>
                {selectedAccount && <CurrencyAccountInfo selectedAccount={selectedAccount} />}
              </TabContent>
              <TabContent index={1} value={selectedTabIndex}>
                <BaseDrawer.Body className={styles.drawerBody}>
                  <Transfers
                    handleOpenIATRejectModal={handleOpenIATRejectModal}
                    handleOpenIATDrawer={handleOpenIATDrawer}
                    pendingTransfers={pendingTransfers}
                    loading={loading}
                    handleDeleteIatClick={handleDeleteIatClick}
                    getAllPendingTransfers={() => getAllPendingTransfers(
                      selectedAccount?.account.id,
                      selectedAccount?.account.accountId,
                    )}
                    multiEntity={multiEntity}
                    inputterAccounts={inputterAccounts}
                  />
                </BaseDrawer.Body>
              </TabContent>
            </div>
          )}
      </BaseDrawer.Drawer>
      <RejectIATModal
        selectedDraftIATId={selectedDraftIAT?.id}
        selectedDraftIATSubmittedById={selectedDraftIAT?.submittedById}
        modalOpen={IATRejectModalOpen}
        handleCloseModal={handleIATRejectModalClose}
        getAllPendingTransfers={() => getAllPendingTransfers(
          selectedAccount?.account.id,
          selectedAccount?.account.accountId,
        )}
        accountId={selectedAccount?.account.accountId}
      />
    </>
  );
};

export default AccountInfoDrawer;
