import React, { useMemo } from 'react';
import {
  Alert,
  FormGroup,
} from '@mui/material';
import { Roles, UserAccount } from '@portal/user-types';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import _ from 'lodash';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useWizardModal } from '../../../../components/Modals/WizardModal/context';
import SelectGroup from '../../../../components/SelectGroup/SelectGroup';
import useStyles from './index.styles';
import ApproverLevelSelector from './ApproverLevelSelector';
import { groupRolesByGroup, isApproverLevelRequired } from '../../helpers/permissions';
import { TRole } from '../../context/index.interfaces';
import { useUserManagementContext } from '../../context';

export type TGroupedRoles = TRole & {
  type: 'checkbox' | 'radio',
}

const Permissions = () => {
  const {
    values, isSubmitting,
  } = useFormikContext<{
    ids: Array<UserAccount>,
    permissions: { [key: string]: {
      roles: Array<Roles>,
      approverLevel: string,
      isApprovedSignatory: boolean,
    }}
  }>();

  const styles = useStyles();
  const { state } = useWizardModal();
  const { roles } = useUserManagementContext();
  const { step, nestedSteps, name } = state;

  const currentNestedStep = nestedSteps[step].current;

  const entityId = useMemo(
    () => values?.ids[currentNestedStep]?.id, [currentNestedStep, values.ids],
  );

  const currentSelection = values.ids[currentNestedStep];

  const hasApprovalConfig = !_.isEmpty(currentSelection?.approvalConfig);

  const noConfigMessage = 'The approval configuration for this entity was not set. Please contact customer support. ';
  const alertMessage = `Please note that this request will require review and may require additional 
    documentation before being enabled. Until then ${name} will be provided read only/inputter access for ${currentSelection?.name}.`;
  const currentRoles = values?.permissions[entityId];
  const showApproverLevel = useMemo(
    () => currentRoles && isApproverLevelRequired(currentRoles.roles),
    [currentRoles],
  );

  const groupedRoles = useMemo(() => {
    const filteredRoles = roles.filter((
      role,
    ) => role.isRequestable && (hasApprovalConfig || !role.isApproverRole));
    return groupRolesByGroup(filteredRoles);
  }, [hasApprovalConfig, roles]);

  if ((values.ids as []).length === 0) return null;

  return (
    <>
      <div className={styles.header}>
        <h3>{`Setting permissions for ${currentSelection.name}`}</h3>
        <p>Please select what permissions this user will have on the platform.</p>
      </div>
      <FormGroup key={entityId} className={styles.content}>
        <>
          {!hasApprovalConfig && (
            <Alert icon={<FontAwesomeIcon color="#665216" icon={faExclamationTriangle as IconDefinition} size="xs" className={styles.icon} />} severity="warning" className={styles.alert}>
              {noConfigMessage}
            </Alert>
          )}
          {Object.keys(groupedRoles).map((role) => (
            <div key={role}>
              <h4>{role}</h4>
              <SelectGroup
                roles={groupedRoles[role]}
                isSubmitting={isSubmitting}
                fieldName={`permissions.[${entityId}].roles`}
              />
            </div>
          ))}
          {!currentRoles.isApprovedSignatory && showApproverLevel && (
            <Alert icon={<FontAwesomeIcon color="#1c3c4a" icon={faInfoCircle as IconDefinition} size="xs" className={styles.icon} />} severity="info" className={styles.alert}>
              {alertMessage}
            </Alert>
          )}
          {currentSelection.approvalConfig?.requirements && showApproverLevel && (
            <ApproverLevelSelector
              entity={currentSelection.name}
              fieldName={`permissions.[${entityId}].approverLevel`}
              approvalConfigRequirements={currentSelection.approvalConfig.requirements}
            />
          )}
        </>
      </FormGroup>
    </>
  );
};

export default Permissions;
