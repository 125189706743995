import { faFilePdf, faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import Menu from 'components/Menu/Menu';
import useReportsPolling, { FileType, PageType } from '../../../hooks/useReportsPolling';

interface IExportMenuProps {
  startDate: string;
  endDate: string;
  currencyAccounts: string[]
  disabled?: boolean;
}
const ExportMenu: React.FC<IExportMenuProps> = (props: IExportMenuProps) => {
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const {
    startDate, endDate, disabled, currencyAccounts,
  } = props;
  const { executeReportGeneration } = useReportsPolling(PageType.TransactionsMultiEntity);
  const handleReportGeneration = async (fileType: FileType, transformedCas: string[]) => {
    await executeReportGeneration(
      fileType,
      transformedCas[0],
      startDate,
      endDate,
      undefined,
      fileType,
      transformedCas,
    );
  };
  const menuItems = [
    {
      id: 'action-transactions-export-pdf',
      content: 'Export PDF',
      icon: faFilePdf,
      onClick: async () => {
        handleReportGeneration(FileType.PDF, currencyAccounts);
      },
      underline: true,
    },
    {
      id: 'action-transactions-export-excel',
      content: 'Export Excel',
      icon: faFileExcel,
      onClick: () => {
        handleReportGeneration(FileType.EXCEL, currencyAccounts);
      },
    },
  ];
  return (
    <Menu
      open={Boolean(open)}
      setOpen={setOpen}
      anchorEl={open}
      buttonProps={{
        disabled,
        style: {
          height: 50,
        },
      }}
      menuIconProps={{ icon: faAngleDown, style: { fontSize: 16, height: '100%', marginLeft: 18 } }}
      buttonTitle="Export"
      data-dd-privacy="allow"
    >
      {menuItems.map((item) => (
        <MenuDropdownItem key={item.id} setOpen={setOpen}>{item}</MenuDropdownItem>
      ))}
    </Menu>
  );
};

export default ExportMenu;
