import React from 'react';
import NumberFormat from 'react-number-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import { Input, InputLabel } from '@mui/material';
import clsx from 'clsx';
import { Flag } from 'components/Flag';
import { getCurrencyDecimalPlaces, getCurrencyPlaceholder } from 'utils/currency.helpers';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useStyles } from './FundingInput.styles';
import { IBatchCurrencyPairRateDecorated } from '../../../domain/Payments/Funding/Funding.interface';

export enum InputType {
  Debit = 'debit',
  Credit = 'credit',
}

interface IProps {
  type: InputType;
  currencyPair: IBatchCurrencyPairRateDecorated;
}

const FundingInput: React.FC<IProps> = (props: IProps) => {
  const { type, currencyPair } = props;
  const classes = useStyles({ type });
  const currencyCode = type === InputType.Debit
    ? currencyPair.sellCurrency
    : currencyPair.buyCurrency;
  const startAdornment = getSymbolFromCurrency(currencyCode);
  const decimalScale = getCurrencyDecimalPlaces(currencyCode);
  const placeholder = getCurrencyPlaceholder(currencyCode);
  return (
    <div className={clsx(classes.input, { [classes.credit]: type === 'credit' })}>
      <InputLabel
        htmlFor={`funding-${type}`}
      >
        <span>
          I will
          {' '}
          {type === InputType.Debit ? 'fund' : 'send'}
        </span>
        {' '}
        <Flag
          code={type === InputType.Debit ? currencyPair.sellCurrency : currencyPair.buyCurrency}
          size={15}
          rounded
        />
        {' '}
        {(type === InputType.Debit
          ? currencyPair.sellCurrency === currencyPair.fixedSide
          : currencyPair.buyCurrency === currencyPair.fixedSide)
        && (
        <FontAwesomeIcon
          icon={faLockAlt as IconDefinition}
          className="lock-icon"
          data-testid="lock-icon"
        />
        )}
      </InputLabel>
      <NumberFormat
        name={`funding-${type}`}
        value={
          // eslint-disable-next-line no-nested-ternary
          (type === InputType.Debit
            ? currencyPair.sellCurrency === currencyPair.fixedSide
            : currencyPair.buyCurrency === currencyPair.fixedSide
          )
            ? currencyPair.amount
            : (type === InputType.Debit
              ? currencyPair.amount / currencyPair.rate!
              : currencyPair.amount * currencyPair.rate!
            )
        }
        decimalScale={decimalScale}
        fixedDecimalScale
        customInput={Input}
        prefix={startAdornment}
        type="text"
        thousandSeparator=","
        disabled
        placeholder={placeholder}
        inputProps={{
          'data-testid': `funding-${type}-input`,
        }}
      />
    </div>
  );
};

export default FundingInput;
