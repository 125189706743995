import React from 'react';
import { Box } from '@mui/material';
import { TPayment } from 'models/payments';
import { Drawer } from '../../../../../../components/Drawer';
import PaymentRailDropdown from './PaymentRailDropdown/PaymentRailDropdown';
import useSinglePaymentContext from '../useSinglePaymentContext';

interface IPaymentOptionsDrawer {
  isOpen: boolean;
  closeDrawer: () => void;
  currentPayment: TPayment;
}

export const PaymentOptionsDrawer: React.FC<IPaymentOptionsDrawer> = ({
  isOpen,
  closeDrawer,
  currentPayment,
}: IPaymentOptionsDrawer) => {
  const { singlePaymentContext } = useSinglePaymentContext();
  const handlePaymentRailChange = (event: any): void => {
    singlePaymentContext?.setCurrentPayment({
      ...singlePaymentContext.currentPayment,
      paymentRail: event.target.value,
    });
  };

  return (
    <>
      <Drawer
        noPadding
        testId="payment-options-drawer"
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        headerTitle="Additional payment options"
      >
        <Box>
          <PaymentRailDropdown
            currentPayment={currentPayment}
            handlePaymentRailChange={handlePaymentRailChange}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default PaymentOptionsDrawer;
