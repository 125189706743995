import React, { FC } from 'react';
import {
  GridCallbackDetails,
  GridColDef,
  gridPageCountSelector,
  gridPageSelector,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridSelectionModel,
  GridValidRowModel,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import {
  Box, ListItemIcon, Pagination, PaginationItem,
} from '@mui/material';
import useStyles, { StyledDataGrid } from './DataTable.styles';

export interface IDataTable {
  columns: GridColDef[];
  data: GridValidRowModel[];
  page: number;
  setPage: any;
  isLoading: boolean;
  totalRowCount: number;
  pageSize?: number;
  getRowId?: (row: any) => any;
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
  noRowsLabel: string,
  disableVirtualization?: boolean,
  checkboxSelection?: boolean,
  onSelectionModelChange?: ((selectionModel: GridSelectionModel,
     details: GridCallbackDetails<any>) => void) | undefined
}

const CustomPagination = (page: number): JSX.Element => {
  const apiRef = useGridApiContext();
  const pageSelector = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      data-testid="pagination"
      color="primary"
      size="small"
      count={pageCount}
      page={page + 1}
      onChange={(event: any, value: number): void => apiRef.current.setPage(value - 1)}
      siblingCount={1}
      boundaryCount={1}
      renderItem={(item): JSX.Element => (
        <PaginationItem
          components={{
            next: (): JSX.Element => (
              <ListItemIcon onClick={(): void => apiRef.current.setPage(pageSelector + 1)}>
                Next
              </ListItemIcon>
            ),
            previous: (): JSX.Element => (
              <ListItemIcon onClick={(): void => apiRef.current.setPage(pageSelector - 1)}>
                Prev
              </ListItemIcon>
            ),
          }}
          {...item}
        />
      )}
    />
  );
};

export const DataTable: FC<IDataTable> = (props) => {
  const {
    data,
    page,
    setPage,
    isLoading,
    totalRowCount,
    columns,
    pageSize = 10,
    getRowId,
    getRowHeight,
    noRowsLabel,
    disableVirtualization,
    checkboxSelection,
    onSelectionModelChange,
  } = props;

  const styles = useStyles();

  const NoDataLabel = () => <p className={styles.noDataLabel}>{noRowsLabel}</p>;

  return (
    <Box sx={{ width: '100%' }}>
      <StyledDataGrid
        autoHeight
        rows={data || []}
        columns={columns}
        loading={isLoading}
        page={page}
        rowCount={totalRowCount}
        pageSize={pageSize}
        hideFooterPagination={totalRowCount <= pageSize}
        onPageChange={(newPage): void => setPage(newPage)}
        pagination
        paginationMode="server"
        components={{
          Pagination: () => CustomPagination(page),
          NoRowsOverlay: NoDataLabel,
          NoResultsOverlay: NoDataLabel,
        }}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        disableVirtualization={disableVirtualization}
        {...getRowId && { getRowId }}
        {...getRowHeight && { getRowHeight }}
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={onSelectionModelChange}
      />
    </Box>
  );
};

export default DataTable;
