import styled from '@mui/styled-engine';
import _default from 'theme';
import Box from '@mui/material/Box';

const { typography } = _default;
type ITimeoutModalStyles = {
  isOpen: boolean;
};

export const StyledTimeoutModal = styled(Box, {
  shouldForwardProp: (prop) => (prop !== 'isOpen'),
})(({ isOpen }: ITimeoutModalStyles) => {
  const { fontWeightLight, fontWeightBold } = typography;

  return {
    zIndex: 1350,
    color: '#FFFFFF',
    backgroundColor: 'rgba(0,0,0,0.87)',
    height: '100vh',
    width: '100%',
    fontWeight: fontWeightLight,
    display: isOpen ? 'flex' : 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    '& .title': {
      marginBottom: '40px',
      color: '#FFFFFF',
    },
    '& .progressWrapper': {
      position: 'relative',
      display: 'inline-flex',
    },
    '& .timeWrapper': {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .time': {
      color: '#FFFFFF',
    },
    '& .detail': {
      fontWeight: fontWeightLight,
      '&:nth-of-type(1)': {
        marginTop: '30px',
        marginBottom: '55px',
      },
      '&:nth-of-type(2)': {
        marginBottom: '50px',
      },
    },
    '& .logoutBtn': {
      border: '1px solid #FFFFFF',
      marginRight: '16px',
    },
    '& .loader': {
      height: '140px !important',
      width: '140px !important',
      transform: 'rotate(-90deg) !important',
    },
    '& .strong': {
      fontWeight: fontWeightBold,
    },
  };
});
