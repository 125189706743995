import { Box, Typography } from '@mui/material';
import React, { memo, MouseEvent, useEffect } from 'react';
import CardButtons from './CardButtons/EntityGroupCardButtons';
import useStyles from './EntityGroupCard.style';
import useEntityGroupDrawer from '../../useEntityGroupDrawer';
import { TUserAccount } from '../../../../../models/user';

type IProps = {
  colour: string;
  groupName: string;
  numberOfEntities: number;
  accounts: TUserAccount[];
  groupId: string;
  setCanEditOrDeleteGroup: React.Dispatch<React.SetStateAction<boolean>>;
  canEditOrDeleteGroup: boolean;
};

const EntityGroupCard: React.FC<IProps> = (props: IProps) => {
  const {
    colour,
    groupName,
    groupId,
    numberOfEntities,
    accounts,
    setCanEditOrDeleteGroup,
    canEditOrDeleteGroup,
  } = props;
  const { setSelectedEntityGroup, entityAccounts, deleteGroup } = useEntityGroupDrawer();

  const openGroupInfoDrawer = (event: MouseEvent) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    setSelectedEntityGroup({
      id: groupId,
      name: groupName,
      colour,
      accounts,
    });
  };

  const ePermissionsForAllAccounts = (): boolean => {
    const groupAccountIds = accounts.map((account) => account.id);
    const userAccountIds = entityAccounts.map((account) => account.id);
    return groupAccountIds.every((accountId) => userAccountIds.includes(accountId));
  };
  useEffect(() => {
    setCanEditOrDeleteGroup(ePermissionsForAllAccounts());
  }, []);
  const handleDeleteGroup = async () => {
    await deleteGroup(groupId);
  };

  const classes = useStyles();
  return (
    <Box
      className={classes.header}
      onClick={openGroupInfoDrawer}
    >
      <Box className={classes.headerLabel}>
        <Typography className={classes.entityGroupNameLabel}>
          {groupName}
        </Typography>
        <Box>
          <Typography
            variant="subtitle1"
            className={classes.entityGroupNoOfEntitiesLabel}
          >
            Number Of Entities:
            {' '}
            {numberOfEntities}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.headerItem}>
        <CardButtons
          deleteGroup={handleDeleteGroup}
          canEditOrDeleteGroup={canEditOrDeleteGroup}
        />
      </Box>
    </Box>
  );
};

export default memo(EntityGroupCard);
