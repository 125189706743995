import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import { Box } from '@mui/material';
import {
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import Menu from 'components/Menu/Menu';
import useActionDropdown from '../../../../hooks/useActionDropDown';
import useStyles from './index.styles';

interface IProps {
  menuItems: {
    id: string;
    content: JSX.Element;
    icon: IconDefinition;
    onClick: () => void;
    underline: boolean;
  }[];
  decisionModal?: JSX.Element;
}

const ActionDropDown: React.FC<IProps> = (props: IProps) => {
  const { menuItems, decisionModal } = props;
  const classes = useStyles();
  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    setOpen,
  } = useActionDropdown();

  const handleClose = (): void => {
    setMenuCloseCount(0);
  };

  return (
    <Box
      className={classes.wrapper}
    >
      <Menu
        open={Boolean(open)}
        setOpen={setOpen}
        anchorEl={open}
        disableBackdropClick={menuCloseCount === 1}
        disableHoverEffect={false}
        onClose={handleClose}
        buttonProps={{
          style: {
            display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, width: 30, minWidth: 0,
          },
          disabled: menuItems.length === 0,
          disableRipple: true,
        }}
        buttonTitle=""
        icon={faEllipsisH}
      >
        {menuItems.map((item) => (
          <MenuDropdownItem key={item.id} setOpen={setOpen}>{item}</MenuDropdownItem>
        ))}
      </Menu>
      {decisionModal && decisionModal}
    </Box>
  );
};

export default ActionDropDown;
