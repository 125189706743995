import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import OnboardingForm from 'domain/Onboarding/OnboardingForm/Form';
import TokenRefresher from 'domain/Onboarding/TokenRefresher';
import environmentVars from 'env.variables';
import React, { useEffect, useState } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import routes from 'routes.path';
import AlphaWhiteLogo from '../../../../assets/alpha-full-logo.png';
import { StyledNavBar } from '../Form/index.styles';
import { verifyFormAccess } from './api';
import SubmissionsTable from './components/SubmissionsTable';
import { SubmissionButton } from './components/SubmissionButton';

const NEW_ENTITY_ONBOARDING_FORM_DEFINITION_URL = `${environmentVars.FORMIO_FORM_URL}${environmentVars.FORMIO_ONBOARDING_FORM_API_NAME}`;
const REUSABLE_KYC_ONBOARDING_FORM_DEFINITION_URL = `${environmentVars.FORMIO_FORM_URL}${environmentVars.FORMIO_REUASABLE_KYC_ONBOARDING_FORM_API_NAME}`;

const NEW_ENTITY_ONBOARDING_FORM_ID = environmentVars.FORMIO_SECURE_REDUCED_FORM_ID;
const REUSABLE_KYC_ONBOARDING_FORM_ID = environmentVars.FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID;

export type Submission = {
  _id: string;
  data: { entities: { generalQuestions: { entityName: string } }[] };
  created: string;
  modified: string;
  state: 'submitted' | 'draft';
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SubmissionsScreen() {
  const [newSubmissionState, setNewSubmissionState] = useState<{
    loading: boolean;
    error: boolean;
    success: boolean;
    id: string | null;
  }>({
    loading: false,
    error: false,
    success: false,
    id: null,
  });

  const [selectedSubmissionId, setSelectedSubmissionId] = useState('');
  const [selectedFormId, setSelectedFormId] = useState('');
  const [email, setEmail] = useState('');
  const [notAuthed, setNotAuthed] = useState(false);
  const [formioUserId, setFormioUserId] = useState('');
  const [hasReusableKYCAccess, setHasReusableKYCAccess] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [draftPage, setDraftPage] = useState(0);
  const [submittedPage, setSubmittedPage] = useState(0);

  const init = async () => {
    const { data } = JSON.parse(localStorage.getItem('formioUser') || JSON.stringify({}));

    if (!data) {
      setNotAuthed(true);
      return;
    }

    const { _id: userId, email: userEmail } = data;
    setEmail(userEmail);
    setFormioUserId(userId);
    setHasReusableKYCAccess(await verifyFormAccess(userId, REUSABLE_KYC_ONBOARDING_FORM_ID));
  };

  useEffect(() => {
    init();
  }, []);

  if (notAuthed) {
    return <Redirect to={{ pathname: routes.onboardingForm.auth }} />;
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <StyledNavBar>
        <div>Onboarding</div>
        <div>
          <NavLink to="/">
            <img alt="logo" src={AlphaWhiteLogo} />
          </NavLink>
        </div>
      </StyledNavBar>
      <TokenRefresher />
      {!selectedSubmissionId ? (
        <>
          <SubmissionButton
            setNewSubmissionState={setNewSubmissionState}
            newSubmissionState={newSubmissionState}
            setPage={setDraftPage}
            setTab={setTabValue}
            init={init}
            hasReusableKYCAccess={hasReusableKYCAccess}
          />
          {hasReusableKYCAccess ? (
            <Box sx={{ width: '100%', marginY: '3em', marginX: '2em' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                  <Tooltip title="Onboarding Submissions for Completely New Entities">
                    <Tab label="New Entity Onboarding" {...a11yProps(0)} />
                  </Tooltip>
                  <Tooltip title="Onboarding Submissions Related to a Previously Submitted Entity">
                    <Tab label="Reusable KYC Onboarding" {...a11yProps(1)} />
                  </Tooltip>
                </Tabs>
              </Box>
              <CustomTabPanel value={tabValue} index={0}>
                <SubmissionsTable
                  title="New Entity Onboarding Form Drafts"
                  setNewSubmissionState={setNewSubmissionState}
                  newSubmissionState={newSubmissionState}
                  init={init}
                  setSelectedSubmissionId={setSelectedSubmissionId}
                  setSelectedFormId={setSelectedFormId}
                  filterState="draft"
                  formioUserId={formioUserId}
                  formId={NEW_ENTITY_ONBOARDING_FORM_ID}
                  page={draftPage}
                  setPage={setDraftPage}
                  newSubmissionState={newSubmissionState}
                />
                <SubmissionsTable
                  title="New Entity Onboarding Form Submitted"
                  setNewSubmissionState={setNewSubmissionState}
                  newSubmissionState={newSubmissionState}
                  init={init}
                  setSelectedSubmissionId={setSelectedSubmissionId}
                  setSelectedFormId={setSelectedFormId}
                  filterState="submitted"
                  formioUserId={formioUserId}
                  formId={NEW_ENTITY_ONBOARDING_FORM_ID}
                  page={submittedPage}
                  setPage={setSubmittedPage}
                  newSubmissionState={newSubmissionState}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <SubmissionsTable
                  title="Reusable KYC Onboarding Form Drafts"
                  setNewSubmissionState={setNewSubmissionState}
                  newSubmissionState={newSubmissionState}
                  init={init}
                  setSelectedSubmissionId={setSelectedSubmissionId}
                  setSelectedFormId={setSelectedFormId}
                  filterState="draft"
                  formioUserId={formioUserId}
                  formId={REUSABLE_KYC_ONBOARDING_FORM_ID}
                  page={draftPage}
                  setPage={setDraftPage}
                  newSubmissionState={newSubmissionState}
                />
                <SubmissionsTable
                  title="Reusable KYC Onboarding Form Submitted"
                  setNewSubmissionState={setNewSubmissionState}
                  newSubmissionState={newSubmissionState}
                  init={init}
                  setSelectedSubmissionId={setSelectedSubmissionId}
                  setSelectedFormId={setSelectedFormId}
                  filterState="submitted"
                  formioUserId={formioUserId}
                  formId={REUSABLE_KYC_ONBOARDING_FORM_ID}
                  page={submittedPage}
                  setPage={setSubmittedPage}
                  newSubmissionState={newSubmissionState}
                />
              </CustomTabPanel>
            </Box>
          ) : (
            <>
              <SubmissionsTable
                title="New Entity Onboarding Form Drafts"
                setNewSubmissionState={setNewSubmissionState}
                newSubmissionState={newSubmissionState}
                init={init}
                setSelectedSubmissionId={setSelectedSubmissionId}
                setSelectedFormId={setSelectedFormId}
                filterState="draft"
                formioUserId={formioUserId}
                formId={NEW_ENTITY_ONBOARDING_FORM_ID}
                page={draftPage}
                setPage={setDraftPage}
              />
              <SubmissionsTable
                title="New Entity Onboarding Form Submitted"
                setNewSubmissionState={setNewSubmissionState}
                newSubmissionState={newSubmissionState}
                init={init}
                setSelectedSubmissionId={setSelectedSubmissionId}
                setSelectedFormId={setSelectedFormId}
                filterState="submitted"
                formioUserId={formioUserId}
                formId={NEW_ENTITY_ONBOARDING_FORM_ID}
                page={submittedPage}
                setPage={setSubmittedPage}
              />
            </>
          )}
        </>
      ) : (
        <Box
          sx={{
            mt: '60px',
          }}
        >
          <Stack spacing={2} p={2}>
            <Breadcrumbs separator={<FontAwesomeIcon icon={faAngleRight as IconDefinition} />} aria-label="breadcrumb">
              <Typography
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                    color: 'primary.main',
                    cursor: 'pointer',
                  },
                }}
                onClick={() => window.location.reload()}
              >
                Submissions
              </Typography>
              <Typography>{selectedSubmissionId}</Typography>
            </Breadcrumbs>
          </Stack>
          <OnboardingForm
            ownerEmail={email}
            formUrl={
              selectedFormId === REUSABLE_KYC_ONBOARDING_FORM_ID
                ? REUSABLE_KYC_ONBOARDING_FORM_DEFINITION_URL
                : NEW_ENTITY_ONBOARDING_FORM_DEFINITION_URL
            }
            formType="draft"
            submissionId={selectedSubmissionId}
            formId={selectedFormId}
          />
        </Box>
      )}
    </>
  );
}
