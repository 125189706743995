import _default, { makeStyles } from 'theme';

const { palette, typography } = _default;

export const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    manual: {
      position: 'relative',
      flexGrow: 1.5,
      padding: '0px 40px 50px 40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& > div.manualContent': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > div.text': {
          width: '65%',
          '& > h6:nth-of-type(2)': {
            fontFamily: typography.fontFamilySecondary,
          },
        },
        '& > div.caretRight': {
          border: '1px solid rgba(112, 112, 112, 0.3)',
          height: '30px',
          width: '30px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      '& > div.orDivider': {
        border: '1px solid #E0E0E0',
        height: '50px',
        width: '50px',
        borderRadius: '50%',
        position: 'absolute',
        left: '53px',
        bottom: '-25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        '& > span': {
          textTransform: 'uppercase',
        },
      },
    },
    file: {
      flexGrow: 2,
      backgroundColor: '#EAEAEA',
      padding: '0px 40px',
      '& > div.fileContent': {
        marginTop: '65px',
        '& > h6:nth-of-type(2)': {
          fontFamily: typography.fontFamilySecondary,
        },
      },
    },
    title: {
      color: 'black',
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: typography.fontWeightSemiBold,
      letterSpacing: '-0.5px',
      margin: 0,
    },
    subtitle: {
      lineHeight: '19px',
      fontWeight: typography.fontWeightRegular,
      color: palette.secondary.dark,
    },
    button: {
      height: '60px',
      width: '60px',
      padding: '20px 20px',
    },
    buttonIcon: {
      color: palette.secondary.dark,
    },
    chooseFileIsDragEnter: {
      backgroundColor: '#CDE6E3',
      color: palette.primary.main,
      '& *': {
        pointerEvent: 'none',
      },
      '& $chooseFileContent': {
        borderColor: 'white',
      },
    },
    dragnDropBox: {
      height: 250,
      marginTop: '32px',
      border: '1px dashed rgba(112, 112, 112, 0.35)',
      borderRadius: 10,
      backgroundColor: '#D6D6D6',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      paddingTop: '42px',
      '& > div.middleContent': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      '& svg': {
        fontSize: 30,
        color: '#AFAFAF',
        marginBottom: '12px',
      },
      '& h6': {
        fontSize: '22px',
        lineHeight: '30px',
        fontWeight: typography.fontWeightLight,
      },
      '& p': {
        color: '#000000',
        opacity: 1,
        fontSize: 14,
        margin: '0',
        '& span': {
          color: palette.primary.main,
        },
      },
    },
    browseButton: {
      padding: 'inherit',
      border: 'inherit',
      background: 'inherit',
      fontWeigth: 'inherit',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      cursor: 'pointer',
      color: palette.primary.main,
    },
    endContent: {
      marginTop: '28px',
    },
    fileTypeTitle: {
      fontSize: '14px',
      opacity: 0.5,
      color: '#343434',
      flex: '0 0 auto',
      marginRight: '15px',
    },
    fileType: {
      color: palette.primary.main,
      fontWeight: typography.fontWeightSemiBold,
      flex: '0 0 auto',
      marginLeft: '5px',
      '&:first-of-type': {
        marginLeft: '15px',
      },
    },
    download: {
      textAlign: 'center',
      textDecoration: 'underline',
      marginBottom: '6px',
    },
  }),
  { name: 'ChoosePayment' },
);

export default useStyles;
