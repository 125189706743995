import _default, { makeStyles } from 'theme';

const { typography } = _default;

const useStyles = makeStyles(
  () => ({
    subtitle: {
      fontSize: 14,
      fontWeight: typography.fontWeightRegular,
      padding: '0 2px',
    },
    text: {
      display: 'flex',
      flexDirection: 'row',
    },
    linkText: {
      fontWeight: typography.fontWeightRegular,
      padding: '0 2px',
      textDecoration: 'underline',
    },
    learnMore: {
      fontSize: 14,
      color: 'inherit',
      border: 0,
      backgroundColor: 'inherit',
      padding: '0 2px',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  }),
  { name: 'KYCBannerComponent' },
);

export default useStyles;
