import React from 'react';
import { GhostButton } from 'components/Buttons';
import { ModalType } from '../../../../Body';

interface IRejectBeneficiaryProps {
  setModalOpen: React.Dispatch<React.SetStateAction<ModalType | false>>;
}

const RejectBeneficiary: React.FC<IRejectBeneficiaryProps> = (props: IRejectBeneficiaryProps) => {
  const { setModalOpen } = props;
  return (
    <GhostButton
      colorVariant="warning"
      size="large"
      testId="reject-bene-btn"
      onClick={() => setModalOpen('reject')}
    >
      Reject Beneficiary
    </GhostButton>
  );
};

export default RejectBeneficiary;
