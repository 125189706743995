import React, { useState } from 'react';

import BookFxFooter from './BookFxFooter/BookFxFooter';
import history from '../../../../../services/history/browserHistory';
import routes from '../../../../../routes.path';
import { PaymentRouteEnum } from '../../../../../models/payments';
import PaymentsService from '../../../../../services/Payments/payments.service';
import { datadogRum } from '@datadog/browser-rum';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import { useSelector } from 'react-redux';
import { TStore } from '../../../../../store';

interface IProps {
  batchId: string;
  batchName: string;
  uploadedById?: string;
}

const BookFxFooterContainer: React.FC<IProps> = ({
  batchId, batchName, uploadedById }: IProps) => {
  const handleBookFxClick = () => {
    history.push(routes.payments.batchSummaryRouteFunc(batchId, PaymentRouteEnum.FUNDING));
  };
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const userId = useSelector<TStore, string | undefined>(
    (store) => store.user.user?.Username,
  );

  const handleClick = () => {
    history.goBack();
  };

  const handleRejectBatchButton = () => setModalOpen(true);

  const handleRejectPayment = async () => {
    try {
      await PaymentsService.postRejectPayment(batchId, userId, uploadedById);
      datadogRum.addAction('SUCCESSFULLY REJECTED PAYMENT');
      sb.trigger('Successfully rejected your payment', 'info');
      history.push(routes.payments.base);
    } catch {
      sb.trigger(
        'Something went wrong rejecting your payment, please try again',
      );
    }
  };

  return (
    <BookFxFooter
      batchName={batchName}
      modalOpen={modalOpen}
      handleClose={() => (modalOpen ? setModalOpen(false) : null)}
      handleRejectBatchButton={handleRejectBatchButton}
      handleRejectPayment={handleRejectPayment}
      handleBookFxClick={handleBookFxClick}
      handleClick={handleClick}
    />
  );
};

export default BookFxFooterContainer;
