import React from 'react';
import { Typography } from '@mui/material';

import _default, { makeStyles } from 'theme';

const {
  palette: { primary },
} = _default;

const useStyles = makeStyles(
  () => ({
    container: {
      marginBottom: '25px',
    },
    hr: {
      width: '65px',
      height: '3px',
      color: primary.main,
      borderColor: primary.main,
      backgroundColor: primary.main,
      margin: '30px auto 25px auto',
    },
  }),
  { name: 'Success' },
);

interface IProps {
  headerText: string;
  authySymbol: boolean;
  subHeaderText: JSX.Element | string;
  classes: Record<'header' | 'mfaInputFields' | 'authContainer' | 'info' | 'cta', string>;
}

const Header: React.FC<IProps> = ({ headerText, authySymbol, subHeaderText, classes }: IProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <img
        src={`/static/images/${authySymbol ? 'authyIcon.png' : 'lock.png'}`}
        alt={authySymbol ? 'Authy Icon' : 'Lock sign'}
        style={{ borderRadius: authySymbol ? '50%' : '0%' }}
      />
      <h2 className={classes.header}>{headerText}</h2>
      <hr className={styles.hr} />
      <Typography variant="h2" className={classes.info}>
        {subHeaderText}
      </Typography>
    </div>
  );
};

export default Header;
