import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    imageHolder: {
      marginRight: '8px',
      display: 'inline-block',
      '& img': {
        height: '17px',
        width: '17px',
      },
    },
  }),
  { name: 'DrawerHeaderFlag' },
);

export default useStyles;
