import { Box } from '@mui/material';
import React from 'react';
import useDynamoTable from '../../../../hooks/useDynamoTable';
import { TDynamoTableQueryParams } from '../../../../services/DatabaseServices/DynamoService';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import DisplayTable from './DisplayTable/DisplayTable';
import useStyles from './PendingTable.styles';

const initialTableQuery: TDynamoTableQueryParams = {
  url: '/payments/batches/pending',
  pageSize: 9,
};
const PendingTable: React.FC = () => {
  const classes = useStyles();
  const columns: ITableColumn[] = [
    {
      header: (
        <Box paddingRight="80px" fontWeight="bold">
          ID
        </Box>
      ),
      accessor: 'id',
    },
    {
      header: <Box>Type</Box>,
      accessor: 'type',
    },
    {
      header: <Box className={classes.payments}>No. of Payments</Box>,
      accessor: 'noOfPayments',
    },
    {
      header: <Box className={classes.textAlignLeft}>Uploaded by</Box>,
      accessor: 'uploadedBy',
    },
    {
      header: <Box className={classes.textAlignLeft}>uploaded date</Box>,
      accessor: 'uploadedDate',
    },
    {
      header: <Box className={classes.textAlignLeft}>no. of approvers</Box>,
      accessor: 'noOfApprovers',
    },
    {
      header: <Box className={classes.approval}>approval status</Box>,
      accessor: 'approvalStatus',
    },
    {
      header: <Box className={classes.action}>Action</Box>,
      accessor: 'action',
    },
  ];
  const table = useDynamoTable(initialTableQuery);

  return (
    <DisplayTable
      table={table}
      columns={columns}
    />
  );
};

export default PendingTable;
