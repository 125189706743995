import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 'auto',
  },
  hr: {
    height: '1px',
    border: 0,
    backgroundColor: '#707070',
  },
}), { name: 'InterAccountTransferFooter' });
export default useStyles;
