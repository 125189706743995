import React, { useState, ReactText, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { originalUseSnackbar } from 'components/Snackbar';
import SnackMessage from '../components/SnackMessage/SnackMessage';
import {
  TReportGenerationPayload,
} from '../models/currencyAccounts';
import CurrencyAccountsService from '../services/CurrencyAccounts/currencyAccounts.service';
import PaymentsService from '../services/Payments/payments.service';
import { initiatePolling } from '../store/generateReport/actions';
import { TStore } from '../store';
import { TDownloadQueue } from '../store/generateReport/reducer';
import TransactionsService from '../services/Transactions/transactions.service';

export enum PageType {
  Payments = 'payments',
  Transactions = 'transactions',
  CurrencyAccount = 'currencyAccount',
  TransactionsMultiEntity = 'transactionsMultiEntity'
}

export enum DownloadType {
  Batch = 'batch',
  Single = 'single',
  PaymentBatchErrors = 'payment batch errors',
  Fx = 'fx',
  AccountLetter = 'accountLetter',
}

export enum FileType {
  PDF = 'pdf',
  EXCEL = 'excel',
}

const useReportsPolling = (pageType: PageType) => {
  const dispatch = useDispatch();

  const [download, setDownload] = useState<{
    id: string,
    executionArn: string,
    key: ReactText,
    accountId?: string,
  }>();
  const downloadQueue = useSelector<TStore, TDownloadQueue>(
    (store) => store.generatePdf.downloadQueue,
  );
  const { enqueueSnackbar } = originalUseSnackbar();

  useEffect(() => {
    if (download && downloadQueue.findIndex((dl) => dl.id === download.key) === -1) {
      dispatch(
        initiatePolling(
          download.id, download.executionArn, download.key, pageType, download.accountId,
        ),
      );
    }
  }, [download?.key]);

  const createTransactionsPayload = (
    type: FileType,
    startDate: string | undefined,
    endDate: string | undefined,
    id: string,
  ): TReportGenerationPayload => ({
    type,
    startDate,
    endDate,
    id,
  });

  const handleReportMessage = (
    downloadType: DownloadType | undefined,
    fileType: FileType | undefined,
  ): string => {
    if (pageType === PageType.Payments) {
      if (downloadType === DownloadType.Batch) {
        return 'Batch Summary';
      }
      if (downloadType === DownloadType.Single) {
        return 'Payment Confirmation';
      }
      if (downloadType === DownloadType.Fx) {
        return 'FX Trade';
      }
    }
    if (pageType === PageType.Transactions) {
      if (fileType === FileType.PDF) {
        return 'PDF Statement';
      }
      if (fileType === FileType.EXCEL) {
        return 'Excel Statement';
      }
    }
    if (pageType === PageType.TransactionsMultiEntity) {
      return 'Thank you for your request. This can take up to a few minutes, we will let you know when your files are ready to download.';
    }
    return 'PDF';
  };

  const handleReportEndpoint = async (
    type: FileType,
    id: string,
    startDate?: string,
    endDate?: string,
    downloadType?: DownloadType,
    accountId?: string,
    currencyAccounts?: string[],
  ): Promise<string> => {
    let encodedExecutionArn: string = '';
    if (pageType === PageType.TransactionsMultiEntity && currencyAccounts) {
      encodedExecutionArn = await CurrencyAccountsService.postBulkReportGeneration(
        {
          currencyAccounts,
          type,
          startDate,
          endDate,
        },
      );
    }
    if (pageType === PageType.Transactions) {
      const payload = createTransactionsPayload(
        type,
        startDate,
        endDate,
        id,
      );
      encodedExecutionArn = await CurrencyAccountsService.postReportGeneration(
        payload,
      );
    }
    if (pageType === PageType.Payments) {
      if (downloadType === DownloadType.Batch) {
        encodedExecutionArn = await PaymentsService.postReportBatchGeneration(
          type, id, accountId,
        );
      }
      if (downloadType === DownloadType.Single) {
        encodedExecutionArn = await PaymentsService.postReportSingleGeneration(
          type, id, accountId,
        );
      }
      if (downloadType === DownloadType.Fx) {
        encodedExecutionArn = await PaymentsService.postReportFxGeneration(
          type, id, accountId,
        );
      }
    }
    if (pageType === PageType.CurrencyAccount) {
      encodedExecutionArn = await CurrencyAccountsService.postAccountLetterReportGeneration({
        type, id,
      }, accountId);
    }
    return encodedExecutionArn;
  };

  const handleReportGenerationForMultipleCAs = async (
    type: FileType,
    id: string,
    startDate: string,
    endDate: string,
    currencyAccounts: string[],
    downloadType?: DownloadType,
    accountId?: string,
  ): Promise<void> => {
    const response = await TransactionsService.validateTransactionsLimit(
      startDate, endDate, currencyAccounts,
    );
    if (response.message === 'success') {
      const executionArn = await handleReportEndpoint(
        type, id, startDate, endDate, downloadType, accountId, response.currencyAccounts,
      );
      enqueueSnackbar(null, {
        autoHideDuration: 60000,
        className: 'dd-privacy-allow',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        content: (key) => {
          setDownload({
            id, executionArn, key, accountId,
          });
          return (
            <SnackMessage
              reportType={type}
              reportMessage={handleReportMessage(downloadType, type)}
              id={key}
              subHeader="Please do not log out, or your request will be lost"
              takeCompleteReportMessage
              completedMessage="Your statements are now ready to download"
            />
          );
        },
      });
    } else {
      enqueueSnackbar(null, {
        autoHideDuration: 60000,
        className: 'dd-privacy-allow',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        content: (key) => (
          <SnackMessage
            reportType={type}
            reportMessage={response.message}
            id={key}
            error
          />
        ),
      });
    }
  };

  const executeReportGeneration = async (
    type: FileType,
    id: string,
    startDate?: string,
    endDate?: string,
    downloadType?: DownloadType,
    accountId?: string,
    currencyAccounts?: string[],
  ): Promise<void> => {
    try {
      if (currencyAccounts && startDate && endDate) {
        await handleReportGenerationForMultipleCAs(
          type,
          id,
          startDate,
          endDate,
          currencyAccounts,
          downloadType,
        );
      } else {
        const executionArn = await handleReportEndpoint(
          type, id, startDate, endDate, downloadType, accountId,
        );
        enqueueSnackbar(null, {
          autoHideDuration: 60000,
          className: 'dd-privacy-allow',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          content: (key) => {
            setDownload({
              id, executionArn, key, accountId,
            });
            return (
              <SnackMessage
                reportType={type}
                reportMessage={handleReportMessage(downloadType, type)}
                id={key}
              />
            );
          },
        });
      }
    } catch (e) {
      enqueueSnackbar(null, {
        autoHideDuration: 3000,
        className: 'dd-privacy-allow',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        content: (key) => (
          <SnackMessage
            reportType={type}
            reportMessage={handleReportMessage(downloadType, type)}
            id={key}
            error
          />
        ),
      });
    }
  };

  return {
    executeReportGeneration,
  };
};

export default useReportsPolling;
