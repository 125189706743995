import React, { ChangeEvent } from 'react';
import { Box } from '@mui/material';
import { IAPPagination } from 'components/APPagination';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';
import BackdropLoader from '../../Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable, { IEmptyTable } from '../EmptyTable/EmptyTable';
import clipboardCheck from '../../../assets/clipboardCheck.svg';
import Search from '../../Organisms/Search';
import useStyles from './index.styles';
import TableWithPagination from './TableWithPagination';

interface IProps {
  columns: ITableColumn[];
  data: any[] | undefined;
  loading: boolean;
  emptyTable: IEmptyTable;
  searchProps?: SearchIProps;
  paginationProps?: PaginationIProps;
  handlePendingTableRowClick?: (e: any) => void;
}

interface SearchIProps {
  searchEnabled: boolean;
  searchValue: string;
  totalItems: number;
  searchPlaceholder?: string;
  onSearchTermChange: (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>, multiEntity :boolean) => Promise<void>
}

export interface PaginationIProps {
  pagination: IAPPagination;
  paginationEnabled: boolean;
}

const DashboardTable: React.FC<IProps> = (props: IProps) => {
  const {
    columns, data, handlePendingTableRowClick, loading, emptyTable, searchProps, paginationProps,
  } = props;
  const styles = useStyles();

  return (
    <>
      {searchProps && searchProps.searchEnabled && (
        <div className={styles.searchBar}>
          <Search
            testId="search-input"
            value={searchProps.searchValue}
            totalItems={searchProps.totalItems}
            placeholder={searchProps.searchPlaceholder}
            onChange={(e) => {
              searchProps.onSearchTermChange(e, true);
            }}
          />
        </div>
      )}
      { data && data?.length > 0 && (
      <TableWithPagination
        columns={columns}
        data={data}
        paginationProps={paginationProps}
        handlePendingTableRowClick={handlePendingTableRowClick}
      />
      )}
      { loading && (
      <Box><BackdropLoader testId="backdrop-loader" /></Box>
      )}
      {!data?.length && !loading && (
      <>
        <EmptyTable
          {...emptyTable}
          icon={clipboardCheck}
        />
      </>
      )}
    </>
  );
};

export default DashboardTable;
