import {
  PaymentBatchDraftDto,
  PaymentBatchDraftStatus,
  PaymentBatchDto,
  PaymentBatchStatus,
} from '@alpha/payments-dtos';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import Title from '../../../../components/Title/Title';
import { flowToBatchStatusMapping } from '../../../../utils/batchStatus.helpers';
import isPaymentBatchDraftDto from '../../../../utils/payments/isPaymentBatchDraftDto.helper';
import useStyles from './TitleContainer.styles';
import TitleStatus from './TitleStatus/TitleStatus';

interface IProps {
  batchDetails: PaymentBatchDraftDto | PaymentBatchDto;
  isCrossCurrency: boolean;
}

const TitleContainer: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const { batchDetails, isCrossCurrency } = props;
  const { actionNeeded } = useParams<{
      actionNeeded: string;
  }>();

  const getTitleProps = (
    backendBatchStatus: PaymentBatchDraftStatus | PaymentBatchStatus,
  ): { title: string; check?: boolean } => {
    switch (true) {
      case batchDetails.batchStatus === PaymentBatchStatus.COMPLETE:
      case batchDetails.batchStatus === PaymentBatchDraftStatus.COMPLETE:
        return {
          title: 'Payments completed',
          check: true,
        };
      case flowToBatchStatusMapping.approver.includes(backendBatchStatus):
        return {
          title: 'Batch summary',
          check: false,
        };
      case flowToBatchStatusMapping.postCompletion.includes(backendBatchStatus):
        return isCrossCurrency
          ? {
            title: 'FX Booked. Your payments are being processed',
            check: true,
          }
          : {
            title: 'Your payments are being processed',
            check: true,
          };
      default:
        return {
          title: 'Batch summary',
        };
    }
  };

  if (isPaymentBatchDraftDto(batchDetails)) {
    return (
      <Box className={clsx(classes.titleContent, 'dd-privacy-allow')}>
        <Title
          {...getTitleProps(batchDetails.batchStatus)}
          batchFileName={batchDetails.sourceName!}
        />
        <TitleStatus
          requiresFx={batchDetails.requiresFx}
          batchStatus={batchDetails.batchStatus}
          noOfApprovals={batchDetails.noOfApprovals}
          approvalsRequired={batchDetails.approvalsRequired}
        />
      </Box>
    );
  }

  const batchStatus = actionNeeded ? PaymentBatchStatus.ACTIONNEEDED : batchDetails.batchStatus;

  return (
    <Box className={clsx(classes.titleContent, 'dd-privacy-allow')}>
      <Title
        {...getTitleProps(batchDetails.batchStatus)}
        batchFileName={batchDetails.sourceName!}
      />
      <TitleStatus
        requiresFx={false}
        batchStatus={batchStatus}
        noOfApprovals={undefined}
        approvalsRequired={undefined}
      />
    </Box>
  );
};

export default TitleContainer;
