import { Collapse } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  faAngleDown, faAngleRight,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '../Card/Card';
import useStyles from './CollapsibleCard.styles';

type TCollapsibleCardProps = {
  children: JSX.Element,
  open?: boolean;
  title: string | JSX.Element;
  subHeader?: string;
  icon?: IconDefinition;
}

const CollapsibleCard: React.FC<TCollapsibleCardProps> = (props) => {
  const {
    children, open = true, title, subHeader, icon,
  } = props;
  const styles = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(open);
  const collapseRef = useRef<HTMLElement>(null);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (!isCollapsed && collapseRef.current) {
      collapseRef.current.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
    }
  }, [isCollapsed]);

  return (
    <Card>
      <div className={styles.root}>
        <FontAwesomeIcon
          size="lg"
          className={styles.arrowIcon}
          icon={isCollapsed ? faAngleDown : faAngleRight}
          onClick={toggleCollapsed}
        />
        <div data-testid="collapsible-card-header" className={styles.pointer} onClick={toggleCollapsed} role="presentation">
          <div className={styles.header}>
            {icon && <FontAwesomeIcon className={styles.headerIcon} size="xs" icon={icon} title="collapsible-card-icon" /> }
            <span>{title}</span>
          </div>
          <p className={styles.subHeader}>{subHeader}</p>
        </div>
        <Collapse
          in={isCollapsed}
          timeout="auto"
          ref={collapseRef}
          data-testid="collapsible-content"
        >
          {children}
        </Collapse>
      </div>
    </Card>
  );
};
export default CollapsibleCard;
