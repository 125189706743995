import styled from '@mui/styled-engine';
import BaseButton from '../BaseButton';

const StyledIconActionButton = styled(BaseButton)({
  padding: 8,
  minWidth: 'inherit',
  width: 42,
  height: 42,
  boxShadow: 'none',
  backgroundColor: '#F0F0F0',
  fontSize: 16,
  color: '#7B7B7B',

});

export default StyledIconActionButton;
