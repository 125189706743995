import { makeStyles } from 'theme';

const useStyles = makeStyles(
  () => ({
    main: {
      padding: '58px 0 0px',
      flex: '1 1 auto',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    expired: {
      filter: 'blur(4px)',
    },
  }),
  { name: 'Page' },
);

export default useStyles;
