import { makeStyles } from 'theme';

export const useStyles = makeStyles(() => ({
  dropdownContainer: {
    position: 'relative',
  },
  dropdown: {
    height: '53px',
    boxShadow: 'none',
    backgroundColor: '#f7f7f7',
    outline: 'none',
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
    '& .MuiInputBase-input': {
      height: '100%',
      borderRadius: '5px',
      padding: '0 16px',
      paddingRight: '40px',
      marginBottom: '0px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiSvgIcon-root': {
      right: '3px',
    },
  },
  dropdownList: {
    '& .MuiList-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
      maxHeight: '350px',
      '& > li:first-of-type': { // Using first of type here as the placeholder will always be positioned as the first item
        display: 'none',
      },
      '& > *': {
        height: '49px',
      },
    },
  },
  placeholder: {
    color: '#34343473',
    fontWeight: 400,
  },
  red: {
    color: '#b95656 !important',
  },
  error: {
    position: 'absolute',
    fontSize: '12px !important',
  },
  stickyFooter: {
    display: 'flex',
    backgroundColor: '#F8F8F8',
    justifyContent: 'flex-end',
    gap: 7,
    padding: 10,
    width: '100%',
    zIndex: 2,
    position: 'sticky',
    bottom: 0,
    flexShrink: 0,
    top: '300px',
  },
  sticky: {
    paddingLeft: '34px',
    backgroundColor: '#F8F8F8',
    '& h6': {
      color: '#A0A0A0',
    },
  },
  root: {
    width: '100%',
    padding: '0',
    margin: '0',
    height: '100%',
    position: 'relative',
    paddingLeft: '25px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}), { name: 'TransactionDropDown' });
export default useStyles;
