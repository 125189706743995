import React, { useEffect, useState } from 'react';

interface FlagImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  currencyCode: string;
}

const FlagImage: React.FC<FlagImageProps> = ({ currencyCode, ...rest }) => {
  const fallbackUrl = '/static/flags/all.svg';

  const [src, setSrc] = useState<string>('');

  useEffect(() => {
    const countryCode = currencyCode?.toUpperCase() === 'ALL' ? 'all' : currencyCode?.toLowerCase().substring(0, 2);

    setSrc(`/static/flags/${countryCode}.svg`);
  }, [currencyCode]);

  if (!currencyCode) return null;

  const handleError = () => setSrc(fallbackUrl);

  return <img src={src || fallbackUrl} onError={handleError} alt={`Flag of ${currencyCode}`} {...rest} />;
};

export default FlagImage;
