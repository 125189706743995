import { alpha, styled } from '@mui/material';

import bg from '../../assets/404.jpg';

const StyledRoot = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const StyledContent = styled('div')({
  height: '100%',
  position: 'relative',
  backgroundImage: `url(${bg})`,
  backgroundSize: 'cover',
  color: '#fff',
  fontSize: 18,
  lineHeight: '24px',
  fontWeight: 300,

  '& h2': {
    fontSize: 50,
    lineHeight: '68px',
    color: '#098375',
    margin: ' 0 0 8px',
  },
  '& h3': {
    fontSize: 28,
    lineHeight: '38px',
    margin: ' 0 0 8px',
    fontWeight: 300,
  },
  '& p': {
    margin: '0 0 36px',
  },

});

const StyledContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: '10%',
  backgroundColor: alpha('#222222', 0.8),
});

const StyledButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,

  '& a, & button': {
    height: 48,
    width: 197,
    fontSize: 14,
    display: 'inline-flex',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: '2.1px',
    color: '#fff',
    border: '1px solid #C2C2C2',
    boxShadow: '0px 3px 6px #00000029',
    fontWeight: 600,
  },
});

export {
  StyledRoot,
  StyledContent,
  StyledContainer,
  StyledButtonContainer,
};
