import { Button } from '@mui/material';
import { faDownload, faFileAlt, faRandom } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../hooks/useReportsPolling';
import useStyles from './HeaderButtons.styles';

interface IProps {
  currencyAccountId: string | undefined;
  currencyAccountType: string | undefined;
  handleOpenDrawer: (drawer: 'IAT' | 'AccountDrawer' | false) => void
  entity: string;
  inputter:boolean;
}

const HeaderButtonsMultiEntity: React.FC<IProps> = (
  {
    currencyAccountId, currencyAccountType, handleOpenDrawer, entity, inputter,
  }: IProps,
) => {
  const classes = useStyles();
  const isVirtualAccount = currencyAccountType?.toUpperCase() === 'VIRTUAL ACCOUNT';
  const { executeReportGeneration } = useReportsPolling(PageType.CurrencyAccount);

  const handleReportGeneration = async (type: FileType, downloadType: DownloadType) => {
    if (currencyAccountId) {
      await executeReportGeneration(
        type, currencyAccountId, undefined, undefined, downloadType, entity,
      );
    }
  };

  return (
    <div className="dd-privacy-allow">
      {inputter
        && (
          <Tooltip title="Balance transfer">
            <Button color="secondary" className={classes.button} onClick={() => handleOpenDrawer('IAT')}>
              <FontAwesomeIcon icon={faRandom} />
            </Button>
          </Tooltip>
        )}
      <Link to={`/app/transactions-multi-entity/${entity}/${currencyAccountId}`} data-testid="currency-account-transactions-link">
        <Tooltip title="Transactions">
          <Button color="secondary" className={classes.button}>
            <FontAwesomeIcon icon={faFileAlt} />
          </Button>
        </Tooltip>
      </Link>
      {isVirtualAccount
        && (
          <Tooltip title="Download account letter">
            <Button
              color="secondary"
              onClick={() => handleReportGeneration(FileType.PDF, DownloadType.AccountLetter)}
              className={classes.button}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </Tooltip>
        )}
    </div>
  );
};

export default HeaderButtonsMultiEntity;
